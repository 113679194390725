export default () => ({
    captcha: {},
    expiredpwd: false,
    refreshToken: null,
    userId: null,
    user: null,
    managerId: null,
	userRoles: null,
	userPermissions: null,
    userForbiddens: null,
    accessToken: null,
    logoutTimer: null,
    accessTimer: null,
    expiresAccess: 300,
    expiresRefresh: 604800,
    refreshing: false,
    cookie: null,
    providerAccessType: null,

    showReauthenticationModal: false,
    privileges_requested: [],
    privileges_granted: [],
    retry_data: null,
})