/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'devices': {
    width: 38,
    height: 57,
    viewBox: '0 0 38 57',
    data: '<defs><linearGradient x1="0%" y1="50%" x2="100%" y2="50%" id="svgicon_devices_a"><stop stop-color="#3AAB96" offset="0%"/><stop stop-color="#0178AA" offset="100%"/></linearGradient></defs><path pid="0" d="M77.853 437.201c-1.186 0-2.147-.997-2.147-2.226v-28.069c0-2.455-1.926-4.453-4.294-4.453H50.588c-2.368 0-4.294 1.998-4.294 4.453v39.188c0 2.455 1.926 4.453 4.294 4.453h20.824c2.368 0 4.294-1.998 4.294-4.453 0-1.23.961-2.227 2.147-2.227s2.147.997 2.147 2.227c0 4.91-3.852 8.906-8.588 8.906H50.588c-4.736 0-8.588-3.995-8.588-8.906v-39.188c0-4.91 3.852-8.906 8.588-8.906h20.824c4.736 0 8.588 3.995 8.588 8.906v28.069c0 1.23-.961 2.226-2.147 2.226zM61 447.11c-1.186 0-2.147-.997-2.147-2.226 0-1.23.961-2.227 2.147-2.227s2.147.997 2.147 2.227-.961 2.226-2.147 2.226zm4.294-38.965c0 1.23-.961 2.227-2.147 2.227h-4.294c-1.186 0-2.147-.997-2.147-2.227s.961-2.226 2.147-2.226h4.294c1.186 0 2.147.997 2.147 2.226z" transform="translate(-42 -398)" _fill="url(#svgicon_devices_a)" fill-rule="nonzero"/>'
  }
})
