<template>
	<b-card no-body>
		<template v-slot:header>
			<b-container :style="{'margin-bottom':!enableRefresh ? '15px' :''}"  fuild style="max-width: 2560px;" >
				<b-row no-gutters align-h="between">
					<b-col md="12" lg="8" xl="9" class="pt-2" v-text="getLocalizedText(help_text)" />
					<slot />
				</b-row>
			</b-container>
		</template>
		<template v-if="enableRefresh" v-slot:footer>
			<div class="hr-grey mb-2"/>
			<div v-if="!updating" style="cursor: pointer;display: inline;" @click="refresh_changes" >
				<i class="now-ui-icons arrows-1_refresh-69 ml-4" />
					{{ t('Update now') }}
			</div>
			<div v-else>
				<i class="now-ui-icons loader_refresh spin ml-4"/>
				{{ t('Updating...') }}
			</div>
		</template>

		<b-modal id="confirm-refresh" @ok="refresh(global)" @hidden='cancel' :title="t('Are you sure?')">
			{{ t('All changes will be lost') }}
        </b-modal>
	</b-card>
</template>

<script>
import { mapMutations, mapState } from 'vuex';

export default 
{
	props: {
		help_text: {
			type: String,
			default: ''
		},
		global: {
			type: Boolean,
			default: false
		},
		enableRefresh:{
			type:Boolean,
			default: () => true
		}
	},
	data() {
		return {
			updating: false
		}
	},
	computed: {
		...mapState('ispServices/services', ['default_configurations', 'advanced_configurations'])
	},
	methods: {
		...mapMutations('ispServices/services', ['refresh']),

		refresh_changes() {
			if(JSON.stringify(this.default_configurations) != JSON.stringify(this.advanced_configurations)) {
				this.updating = ! this.updating;
				this.$bvModal.show('confirm-refresh');
			}
		},

		cancel() {
			this.updating = ! this.updating;
		},
		getLocalizedText(text) {
			return this.t(text);
		}
	},
}
</script>