import jsonschema from 'jsonschema';
import { mapActions, mapMutations, mapState, mapGetters } from 'vuex';
import i18n from "../i18n";

export default {
    data() {
        return {
            schema_name: null,
            error_message: null,
            validator: new jsonschema.Validator(),
            validationErrors: {}
        }
    },
    computed: {
        ...mapState('jsonschema', ['schemas', 'validation_errors']),
        ...mapGetters('jsonschema', ['schema']),
    },
    methods: {
        ...mapActions('jsonschema', ['setSchema']),
        ...mapMutations('jsonschema', ['set_error', 'reset_error', 'reset_errors']),

        choseSchema(schema) {
            this.schema_name = schema;
            this.setSchema(schema);
        },

        validation(object, schema, field_name) {
            const validation = this.validator.validate(object, schema);

            this.reset_error(field_name);
            this.error_message = null;

            this.validationErrors = {}
            validation.errors.forEach(error => {
                this.validationErrors[error.path[0]] = error.message
                if (error.path[0] === field_name) {
                    this.error_message = field_name.includes('url') ? i18n.t('The url field is not a valid URL') : error.message.match(/(\d+)/) ? i18n.t(error.message.slice(0,(error.message.indexOf(error.message.match(/(\d+)/)[0]) - 1))) + " " + error.message.match(/(\d+)/)[0] : i18n.t(error.message);
                    this.set_error(field_name);
                }
            });
        }
    },
}