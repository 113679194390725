import axios from "axios";

export default {
    getHostStatus({ commit, state, dispatch }, payload = {}) {
      return new Promise((resolve, reject) => {
        axios.get(`/subsystems/ns/host/${payload.hostname}`)
          .then(res => {
            const data = res.data;
            commit('setHostStatus', data);
            resolve(data);
          })
          .catch(err => {
            commit('setError', err, { root: true });
            reject(err);
          });
      });
    },
    getHostAndProcessStatus({ commit, state, dispatch }, payload = {}) {
      return new Promise((resolve, reject) => {
        axios.post(`/subsystems/ns/module/status`, payload)
          .then(res => {
            const data = res.data;
            commit('setHostAndProcessStatus', data);
            resolve(data);
          })
          .catch(err => {
            commit('setError', err, { root: true });
            reject(err);
          });
      });
    },
    getHostAndProcessPerformance({ commit, state, dispatch }, payload = {}) {
      return new Promise((resolve, reject) => {
        axios.post(`/subsystems/ns/module/checkperformance`, payload)
          .then(res => {
            const data = res.data;
            commit('setHostAndProcessPerformance', data);
            resolve(data);
          })
          .catch(err => {
            commit('setError', err, { root: true });
            reject(err);
          });
      });
    },
    updateHostAndProcessPerformance({ commit, state, dispatch }, payload = {}) {
      return new Promise((resolve, reject) => {
        axios.put(`/subsystems/ns/module/checkperformance`, payload)
          .then(res => {
            resolve(res);
          })
          .catch(err => {
            commit('setError', err, { root: true });
            reject(err);
          });
      });
    },
    executeHostAndProcessAction({ commit, state, dispatch }, payload = {}) {
      return new Promise((resolve, reject) => {
        axios.post(`/subsystems/ns/module/action`, payload)
          .then(res => {
            resolve(res);
          })
          .catch(err => {
            commit('setError', err, {root: true});
            reject(err);
          });
      });
    },
}