export default () => ({
    current_service_name: null,
    params_self_query: {
        allow: null,
        block: null,
    },
    params_query: {
        allow: {
            sort: 'asc',
            limit: 10
        },
        block: {
            sort: 'asc',
            limit: 10
        }
    },
    forbiddenAllowedList: {
        black_list: [],
        white_list: [],
    },
    global_configurations: {},
    default_global_configurations: {},
    global_advanced_configurations: {},
    default_global_advanced_configurations: {},
    advanced_configurations: {},
    default_configurations: {},
    filter_by_service: false,
    filter_by_product: false
})