import DashboardLayout from "@/layout/dashboard/DashboardLayout.vue";

const AntiBotnet = () => import(
  /* webpackChunkName: "ns-services" */
  "@/pages/NetworkSecure/Antibotnet/Antibotnet.vue"
);
const AntiBotnetEdit = () => import(
  /* webpackChunkName: "ns-services" */
    "@/pages/NetworkSecure/Antibotnet/AntibotnetEdit.vue"
  );

const AntiBotnetAdvancedConfiguration = () => import(
  /* webpackChunkName: "ns-services" */
  "@/pages/NetworkSecure/Antibotnet/AdvancedConfiguration/AdvancedConfiguration.vue"
);

export default {
  path: '/ns/AntiBotnet',
  component: DashboardLayout,
  name: 'Network Secure / AntiBotnet',
  children: [
    {
      path: "Policies/Create",
      name: "Network Secure / AntiBotnet / Filtering Policies / Create",
      component: AntiBotnetEdit
    },
    {
      path: "Policies/Edit/*",
      name: "Network Secure / AntiBotnet / Filtering Policies / Edit",
      component: AntiBotnetEdit
    },
    {
      path: 'Policies*',
      name: 'Network Secure / AntiBotnet / Filtering Policies',
      component: AntiBotnet
    },
    {
      path: 'AdvancedConfiguration',
      name: 'Network Secure / AntiBotnet / Advanced Configuration',
      component: AntiBotnetAdvancedConfiguration
    }
  ]
};
