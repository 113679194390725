const createPasswordPolicy = (type, getters) => {
  const sessionsManagement = `${type}SessionsManagement`
  return {
    maxSessions: getters[sessionsManagement].max_sessions,
    expirationTime: Math.round(getters[sessionsManagement].refresh_token_timeout / 60) || 0,
    maxIncorrectAttempts: getters[sessionsManagement]
      .password_policy.max_incorrect_password_attempts,
    lockTime: getters[sessionsManagement]
      .password_policy.lock_time,
    historyCheck: getters[sessionsManagement]
      .password_rotation.history_check_depth,
    minPassPeriod: getters[sessionsManagement]
      .password_rotation.minimum_elapsed_time_to_change,
    maxPassPeriod: getters[sessionsManagement]
      .password_rotation.maximum_elapsed_time_without_change,
    mustChange: getters[sessionsManagement]
      .password_rotation.rotate_on_first_login,
  }
}

export const ispSessionsManagement = (state) => {
  return state.isp.sessions_management;
}

export const entSessionsManagement = (state) => {
  return state.ent.sessions_management;
}

export const resSessionsManagement = (state) => {
  return state.res.sessions_management;
}

export const ispPasswordPolicy = (state, getters) => {
  return createPasswordPolicy("isp", getters)
}

export const entPasswordPolicy = (state, getters) => {
  return createPasswordPolicy("ent", getters)
}

export const resPasswordPolicy = (state, getters) => {
  return createPasswordPolicy("res", getters)
}
