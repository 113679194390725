<template functional>
    <span>
        <template v-if="props.row[props.column.prop].tooltip">
            <el-tooltip :content="props.row[props.column.prop].tooltip" :open-delay="250" >
                <i v-if="props.row[props.column.prop].icon" :class="props.row[props.column.prop].icon"></i> 
                <span :style="{ whiteSpace: 'nowrap', color: `${props.row[props.column.prop].textColor}` }">
                  {{ props.row[props.column.prop].name }}
                </span>
                <svgicon v-if="props.row[props.column.prop].svgicon" 
                    :original="props.row[props.column.prop].svgiconoriginal" 
                    class="icon" :icon="props.row[props.column.prop].svgicon" 
                    :color="props.row[props.column.prop].iconColor" 
                    :width="props.row[props.column.prop].size || '46'"
                    :height="props.row[props.column.prop].size || '46'" />
            </el-tooltip>
        </template>
        <template v-else>
            <i v-if="props.row[props.column.prop].icon" :class="props.row[props.column.prop].icon"></i> 
            <svgicon v-if="props.row[props.column.prop].svgicon" 
                :original="props.row[props.column.prop].svgiconoriginal" 
                class="icon" :icon="props.row[props.column.prop].svgicon" 
                :color="props.row[props.column.prop].iconColor" 
                :width="props.row[props.column.prop].size || '46'"
                :height="props.row[props.column.prop].size || '46'" />
            <span :style="{ whiteSpace: 'nowrap', color: `${props.row[props.column.prop].textColor}` }">
              {{ props.row[props.column.prop].name }}
            </span>
        </template>
    </span>
</template>

<script>
export default {
    name: 'namewithicon-formatter',
}
</script>
