<template>
    <div>
        <slot name="header">
            <h4>{{ title }} </h4>
        </slot>
        <div ref="chart" class="svg-container"></div>
    </div>
</template>

<script>
const d3 = require('d3');

export default {
  name: 'pie-chart2',
  props: {
    title:{
        type: String,
        default: ''
    },
    width : {
        type: Number,
        default: 300,
        validator: value => {
            return value > 0;
        }
    },
    height : {
        type: Number,
        default: 450,
        validator: value => {
            return value > 0;
        }
    },
    data : {
        type: Array,
        default: function() {
                return [];
        },
    }
  },
  data() {
    return {
        data2: [ {name: "Malware",    value:  34},
            {name: "Virus",    value:  18},
            {name: "Botnets",     value: 4},
            {name: "Spyware",     value: 11},
            {name: "Phishing",     value: 2}
        ]
    };
  },
  computed: {
        realwidth(){
            if ( this.isMobile())
                return Math.min(this.width, this.height);
            else
                return Math.max(this.width, this.height);
        },
        realheight(){
            if ( this.isMobile())
                return Math.max(this.width, this.height);
            else
                return Math.min(this.width, this.height);
        },
        dataset() {
            if ( this.$store.getters.isDummy && ( !this.data || this.data.length == 0))
                return this.data2;
            else
                return this.data;
        },
        total() {
            if (!this.dataset || this.dataset.length == 0)
                return 0;
            let tot = 0;
            this.dataset.forEach( (value, key) => {
                    tot += value.value;
            });
            return tot;
        }
  },
  methods: {
    _changeChartSize: function(isData){
        if ( !isData)
            this.$refs.chart.style.paddingBottom = "100px";
        //else
        //    this.$refs.chart.style.paddingBottom = "100%";
    },
    draw(dataset){
        var noData = !dataset || dataset.length == 0 || 
                (dataset.length == 1 && dataset[0].name== 'No Data');
        this._changeChartSize(!noData);

        if (!dataset || dataset.length == 0)
                return;

        //var color = d3.scaleOrdinal(d3.schemePaired);
        var color = d3.scaleOrdinal().range(
            ["#13a1cb", "#6dc8bf","#f78d1e", "#ed1d24","#e23d80","#8b8d94",
                "#ff7f00", "#feda00", "#111217", "#01648d"]
        )

        var legendRectSize = 18;
        var legendSpacing = 8;
        var mobile = this.mobile;
        var margin = {
            top: 20,
            right: mobile ? 20 : this.realwidth/2,
            bottom: mobile ? 30 + Math.ceil((legendRectSize+legendSpacing)*(1+color.range().length/2)) : 30,
            left: 20,
        };
        var width = this.realwidth - margin.left - margin.right;
        var height = this.realheight - margin.top - margin.bottom;
        var radius = Math.max(width, height) / 2;
        var donutWidth = radius / 2;

        
        dataset.forEach(function(d) {
                d.enabled = true;
        });
                
        if ( noData){
                var svg = d3.select(this.$refs.chart)
                    .append("svg")
                    .attr("preserveAspectRatio", "xMinYMin meet")
                    .attr("viewBox", "0 0 " + this.realwidth + " " + this.realheight)
                    .attr("class", "svg-content-responsive")
                    .append("g")
                    .attr("transform", "translate(" + margin.left + "," + margin.top + ")");
                        
                var pie = d3.pie()
                    .value(function(d) { return d.value; })
                    .sort(null);                            
                        
                svg.selectAll(".bar")
                    .data(pie(dataset))
                    .enter()
                    .append("g")
                    .append("text")
                    .attr("class", "noData")
                    .attr("x", width/2)
                    .attr("y", 35)
                    .text(dataset[0].name);
                return;
        }               

        var svg = d3.select(this.$refs.chart)
            .append("svg")
            .attr("preserveAspectRatio", "xMinYMin meet")
            .attr("viewBox", "0 0 " + this.realwidth + " " + this.realheight)
            .attr("class", "svg-content-responsive")                  
            .append('g')
            .attr('transform', 'translate(' + 
                        ((width / 2) + margin.left) + ',' + ((height / 2) + margin.top) + ')');

        var arc = d3.arc()
            .innerRadius(radius - donutWidth)
            .outerRadius( function(d,i){ return radius- d.index*donutWidth/10});

        var pie = d3.pie()
            .value(function(d) { return d.value; })
            .padAngle(.01)
            .sort(null);

        var tooltip = d3.select(this.$refs.chart)
            .append('div')
            .attr('class', 'pietooltip');

        tooltip.append('div').attr('class', 'label');
        tooltip.append('div').attr('class', 'count');
        tooltip.append('div').attr('class', 'percent');

        var pieds = pie(dataset)
        const pieds0 = JSON.parse(JSON.stringify(pieds))
        pieds0.forEach(function(d) {
                d.endAngle = d.startAngle;
        });

        var path = svg.selectAll('path')
            .data(pieds0)
            .enter()
            .append('path')
            .attr('d', arc)
            .attr('fill', function(d, i) {
                return color(d.data.name);
            })
            .each(function(d) { this._current = d; });

        path = path.data(pieds)

        path.transition()                                       
            .duration(2000)                                        
            .attrTween('d', function(d) {                         
                var i = d3.interpolate(this._current, d); 
                this._current = i(1);     
                return function(t) {                                
                    return arc(i(t));                       
                }; 
            });

        path.enter()
            .append('path')
            .attr('d', arc)
            .attr('fill', function(d, i) {
                return color(d.data.name);
            })
            .each(function(d) { this._current = d; });

        path.on('mouseover', function(d) {
            var total = d3.sum(dataset.map(function(d) {
                return (d.enabled) ? d.value : 0;                       
            }));
            var percent = Math.round(1000 * d.data.value / total) / 10;
            tooltip.select('.label').html(d.data.name);
            tooltip.select('.count').html(d.data.value);
            tooltip.select('.percent').html(percent + '%');
            tooltip.style('display', 'block');
        });

        path.on('mouseout', function() {
            tooltip.style('display', 'none');
        });

        var legend = svg.selectAll('.legend')
            .data(color.domain())
            .enter()
            .append('g')
            .attr('class', 'legend')
            /*.attr('transform', function(d, i) {
                var height = legendRectSize + legendSpacing;
                var offset =  1+ (height * color.domain().length / 2);
                var horz = -2 * legendRectSize;
                var vert = i * height - offset;
                return 'translate(' + horz + ',' + vert + ')';
            })*/
            .attr('transform', function(d, i) {
                if ( mobile){
                    var horz = i % 2 ? 40 : 40 - width / 2;
                    var vert = Math.floor(i/2) * (legendRectSize + legendSpacing) + height/2 + 25 ;
                }
                else{
                    var offset = (height - height - (legendRectSize + legendSpacing) * color.domain().length)/2;
                    var horz = width*0.9 + 5;
                    var vert = offset + i * (legendRectSize + legendSpacing);      
                }
                return 'translate(' + horz + ',' + vert + ')';
            });

        var totalsum = svg.selectAll('.total')
            .data([this.total]);

        legend.append('rect')
            .attr('width', legendRectSize)
            .attr('height', legendRectSize)
            .style('fill', color)
            .style('stroke', color)                                   
            .on('click', function(label) {                            
                var rect = d3.select(this);                             
                var enabled = true;                                     
                var totalEnabled = d3.sum(dataset.map(function(d) {     
                    return (d.enabled) ? 1 : 0;                           
                }));                                                    

                if (rect.attr('class') === 'disabled') {                
                    rect.attr('class', '');                               
                } else {                                                
                    if (totalEnabled < 2) return;                         
                    rect.attr('class', 'disabled');                       
                    enabled = false;                                      
                }                                                       

                pie.value(function(d) {                                 
                    if (d.name === label) d.enabled = enabled;           
                    return (d.enabled) ? d.value : 0;                     
                });                                                     

                path = path.data(pie(dataset));                         

                path.transition()                                       
                    .duration(750)                                        
                    .attrTween('d', function(d) {                         
                        var interpolate = d3.interpolate(this._current, d); 
                        this._current = interpolate(0);                     
                        return function(t) {                                
                           return arc(interpolate(t));                       
                        }; 
                    });

                var tot = d3.sum(dataset.map(function(d) {
                            return (d.enabled) ? d.value : 0; }))
                console.log("tot = " + tot);
                totalsum = totalsum.data([tot]);
                let name_class= 'legend_total'+ (Math.log(tot) * Math.LOG10E + 1 | 0);
                totalsum.attr('class', name_class)
                    .transition()                                       
                    .duration(750)                                        
                    .tween('text', function(d) { 
                        var i = d3.interpolate(0, d); 
                        return function(t) {   
                           return d3.select(this).text(Math.floor(i(t)));                             
                        }; 
                    });
            });

        legend.append('text')
            .attr('class', 'legend')
            .attr("alignment-baseline", "central")
            .attr('x', legendRectSize + legendSpacing)
            .attr('y', legendRectSize - legendSpacing)
            .text(function(d) { return d; });
        
        let vm=this;
        let name_class= 'legend_total'+ (Math.log(this.total) * Math.LOG10E + 1 | 0);
        totalsum = totalsum.enter()
            .append('g')
            .append('text')
            .attr('class', name_class)
            .attr("alignment-baseline", "central")
            .attr('transform', function(d, i) {
                return 'translate(' + "0" + ',' + "0" + ')';
            })
            .attr('x', 0)
            .attr('y', 0)
            .text(function(d) { return d; });

    },
    reset: function(){
        this.$refs.chart.innerHTML = "";
    },
    resize: function() {
        this.mobile = (screen.width < 540)
    },
    reload: function(){
        this.resize();
        this.reset();
        this.draw(this.dataset);
    },
    _calcDigits(num){
        let n=1;
        let p=1;
        while ( p*10-1 < num){
            n +=1;
            p *= 10;
        }
        return n;
    },
    isMobile() {
      return screen.width < 768;
    }
  },
  created() {
    window.addEventListener('resize', this.resize);
  },
  destroyed() {
    window.removeEventListener('resize', this.resize);
  },
  mounted() {
      this.reload();
  },
  watch: {
    data: function(newValue, oldValue) {
        if (newValue != oldValue){
            this.reload();
        }
    },
    data2: function(newValue, oldValue) {
        if (newValue != oldValue){
            this.reload();
        }
    },
  }
};
</script>
<style >

    .pietooltip {
        background: #eee;
        box-shadow: 0 0 5px #999999;
        color: #333;
        display: none;
        font-size: 1.25em;
        left: 130px;
        padding: 10px;
        position: absolute;
        text-align: center;
        top: 95px;
        width: 120px;
        z-index: 10;
    }
    
    rect {
        cursor: pointer;
        stroke-width: 2;
    }
    rect.disabled {
        fill: transparent !important;
    }

    .legend_total0, .legend_total1, .legend_total2, .legend_total3 {
        font-size: 4em;
        font-weight: 500;
        fill: #8b8d94;
        text-anchor: middle;
    }
    .legend_total4, .legend_total5 {
        font-size: 3em;
        font-weight: 500;
        fill: #8b8d94;
        text-anchor: middle;
    }
    .legend_total6, .legend_total7, .legend_total8, .legend_total9, .legend_total10, .legend_total11, .legend_total12 {
        font-size: 2em;
        font-weight: 500;
        fill: #8b8d94;
        text-anchor: middle;
    }


    .legend{
        font-size: 1em;
        font-weight: 100;
        fill: #111217;
        font-family: "Museo Sans";
    }

</style>
