/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icon-check': {
    width: 19,
    height: 14,
    viewBox: '0 0 19 14',
    data: '<path pid="0" d="M6.5 11.17L2.33 7 .91 8.41 6.5 14l12-12L17.09.59 6.5 11.17z" _fill="#000"/>'
  }
})
