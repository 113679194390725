/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'item-platforms': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M19.4 17.3L12.9 21c-.3.4-.9.4-1.3.1l-7-3.6c-.4-.3-.4-.9-.1-1.3l6.5-3.7c.3-.4.9-.4 1.3-.1l7 3.6c.4.3.5.9.1 1.3z" _fill="#fff" _stroke="#383132" stroke-width="2" stroke-miterlimit="10"/><path pid="1" d="M19.4 12.7l-6.5 3.7c-.3.4-.9.4-1.3.1l-7-3.6c-.4-.3-.4-.9-.1-1.3L11 7.9c.3-.4.9-.4 1.3-.1l7 3.6c.4.3.5.9.1 1.3z" _fill="#fff" _stroke="#383132" stroke-width="2" stroke-miterlimit="10"/><path pid="2" d="M19.4 8.1l-6.5 3.7c-.3.4-.9.4-1.3.1l-7-3.6c-.3-.3-.4-.9 0-1.3l6.5-3.7c.3-.4.9-.4 1.3-.1l7 3.6c.3.3.4.9 0 1.3z" _fill="#fff" _stroke="#383132" stroke-width="2" stroke-miterlimit="10"/>'
  }
})
