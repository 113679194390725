<template>
  <b-modal
    id="confirmation-modal"
    @ok="confirmChanges"
    @cancel="discardChanges"
    :title="title" :ok-title="ok_title" :cancel-title="cancel_title">
    {{ message }}
  </b-modal>
</template>

<script>
import Modal from "@/components/Modal";

export default {
  name: "ConfirmationModal",
  data() {
    return {
      showModal: false,
      resolve: null,
      title: this.$t('confirmationTitle'),
      message: this.$t('confirmationMessage'),
      ok_title: this.$t('accept'),
      cancel_title: this.$t('cancel')
    };
  },
  methods: {
    open(payload = {}) {
      this.title = payload.title || this.title;
      this.message = payload.message || this.message;
      this.ok_title = payload.ok_title || this.ok_title;
      this.cancel_title = payload.cancel_title || this.cancel_title;
      this.$bvModal.show('confirmation-modal');

      return new Promise((resolve) => {
        this.resolve = resolve;
      });
    },
    confirmChanges() {
      this.resolve(true);
    },
    discardChanges() {
      this.resolve(false);
    },
  }
}
</script>
