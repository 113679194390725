import { 
  getSrcOrDest, getSchedule, 
  setSrcOrDest, setSchedule,
  getAllowedForbidden, setAllowedForbidden
} from "./strategyUtil";

import i18n from '../i18n'

/**
 * Generate array from Back-end to use it in Front-end
 * Function to generate the firewall rules array from the account strategy
 * @param {Object} strategy attribute strategy of an account
 */
export const prepareAutoNoticesData = strategy => {
  let rules = [];
  for (const ruleName in strategy.rules) {
    if (strategy.rules.hasOwnProperty(ruleName)) {
      const rule = strategy.rules[ruleName];
      let log_activity = false;
      let actionType;
      const profile = rule.profile

      const categories = getProfileCategory(profile, strategy.profiles)             
      const frequency = getProfileFrequency(profile, strategy.profiles)

      const included_urls = getProfileUrls(profile, strategy.profiles, true)
      const excluded_urls = getProfileUrls(profile, strategy.profiles, false)
      if (rule.actions) {
      for (const action of rule.actions) {
        if (action.op === "log") log_activity = true;
        else if (action.op) actionType = {type: action.op, value: action.autonotice_list_id}
        else actionType = { type: 'Any' };
      }
    }
      rules.push({
        rule_name: ruleName,
        id: ruleName,
        status: rule.active === "yes" ? true : false,
        log_activity: log_activity,
        action: actionType,
        order: rule.order,
        source: getSrcOrDest(rule, strategy.source_and_destinations, "origin"),
        category: categories,
        system: !!rule.system,
        profile_services: getProfileServices(profile, strategy.profiles),
        allowed_forbidden: getAllowedForbidden(rule, strategy.profiles),
        frequency_text: getFrequencyText(frequency),
        frequency: frequency,
        defined_urls: getUrls(included_urls, excluded_urls),
        schedule: getSchedule(rule, strategy.schedules)
      });
    }
  }
  return rules;
};


const getProfileServices = (profile, profiles) => {
  if (profile === "all") return [];
  if (profiles[profile] && profiles[profile].service)
    return profiles[profile].service
  else
    return null
};

const getProfileUrls = (profile, profiles, allowed) => {
  if (profile === "all") return [];
  const field = allowed ? 'included_url' : 'excluded_url'
  let data = profiles[profile] 
  if (data && data.hasOwnProperty(field)){
    if ( data[field] && data[field].items)
      return data[field].items
  }
  return []
};


const getProfileCategory = (profile, profiles) => {
  if (profile === "all") return [];
  let data = profiles[profile]
  if (data && data.category && !!data.category.items)
    return data.category.items
  else
    return []
};

const getProfileFrequency = (profile, profiles) => {
  if (profile === "all") return [];
  let data = profiles[profile]
  if (data)
    return data.frequency
  else
    return null
};


/**
 * Generate object from Front-end to send it to Back-end
 * Function to generate the strategy of an account from an array of firewall rules
 * @param autoNoticesRules array of rules
 */
export const prepareStrategyData = autoNoticesRules => {
  let strategy = {
    rules: {}
  };
  for (let i = 0; i < autoNoticesRules.length; i++) {
    // Get source items
    const source = setSrcOrDest(autoNoticesRules[i], 'source');
    if (source.hasOwnProperty('value')) {
      if (!strategy.source_and_destinations) strategy.source_and_destinations = {};
      strategy.source_and_destinations[source.origin] = source.value;
    }

    // Get profile and service items
    const profiles = setProfilesAndServices(autoNoticesRules[i]);
    if (profiles.hasOwnProperty('profiles')) {
      if (!strategy.profiles) strategy.profiles = {};
      strategy.profiles[profiles.profileName] = profiles.profiles;
    }

    // Get schedule items
    const schedules = setSchedule(autoNoticesRules[i]);
    if (schedules.hasOwnProperty('schedule')) {
      if (!strategy.schedules) strategy.schedules = {};
      strategy.schedules[schedules.scheduleName] = schedules.schedule;
    }

    strategy.rules[`${autoNoticesRules[i].rule_name}`] = {
      active: autoNoticesRules[i].status ? "yes" : "no",
      order: autoNoticesRules[i].order,
      ns_services: ["autonotices"],
      origin: source.origin,
      // target: destination.target,
      profile: profiles.profileName,
      when: schedules.scheduleName,
      actions: setAutoNoticesActions(autoNoticesRules[i])
    };
  }
  return strategy;
};

export const prepareStrategyStatusData = rule => {
  let strategy = {
    rules: {}
  };
  strategy.rules[`${rule.rule_name}`] = {
    active: rule.status ? "yes" : "no",
    ns_services: ["autonotices"]
  };
  return strategy;
};

/**
 * Function to generate the profiles and services of the rule if necessary
 * @param {Object} rule object with the description of the rule
 * @returns an object with the profile key (and the profile and service object if necessary)
 */
const setProfilesAndServices = rule => {

  let profiles = {
    ns_services: ["autonotices"]
  };
  if ( rule.profile_services)
    profiles['service'] = rule.profile_services

  if ( !!rule.defined_urls){
    let included_urls = rule.defined_urls.filter( item => !item.startsWith("not:"))
    let excluded_urls = rule.defined_urls.filter( item => item.startsWith("not:")).map( item => (item.slice(4)))
    if ( !!included_urls && included_urls.length > 0){
      profiles['included_url'] = {
        necessary: true,
        items: included_urls
      }
    }
    if ( !!excluded_urls && excluded_urls.length > 0){
      profiles['excluded_url'] = {
        necessary: true,
        items: excluded_urls
      }
    }
  }

  const allowedForbidden = setAllowedForbidden(rule);
  profiles = {
    ...profiles,
    ...allowedForbidden
  }
  if ( rule.category && rule.category.length > 0  ){
    profiles['category'] = { items: rule.category, necessary: true}
  }
  if ( rule.frequency){
    profiles['frequency'] = {...rule.frequency, necessary: true}
  }

  return {
    profileName: `${rule.rule_name}_profile`,
    profiles
  };
};


/**
 * Function to generate the rule actions
 * @param {Object} rule object with the information of the rule
 * @returns an array with the actions of the rule
 */
const setAutoNoticesActions = rule => {
  let actions = []
  if (rule.action.type){
    if ( rule.action.type === 'allow')
      actions.push({op: 'allow'})
    else
      actions.push({op: rule.action.type , autonotice_list_id: rule.action.value})
  }
  if ( rule.log_activity)
    actions.push({op: 'log'})

  return actions;
};



/**
 * Function to obtain the urls
 * @param {Array} allowed allowed urls
 * @param {Array} excluded excluded urls
 */
const getUrls = (allowed, excluded) => {
  let compose_urls = []
  compose_urls = [...allowed]
  if (!!excluded) {
    excluded.forEach(element => { 
      compose_urls.push(`not:${element}`) 
    })
  }
  return compose_urls
};

export const DISPLAY_OPTIONS = {
  0: "always",
  1: "1 min",
  5: "5 min",
  15: "15 min",
  30: "30 min",
  60: "1 hour",
  120: "2 hours",
  360: "6 hours",
  720: "12 hours",
  1440: "1 day",
  2880: "2 days",
  10080: "7 days",
  20160: "14 days",
  43200: "30 days",
  100000: "once"
};

export const DELAY_OPTIONS = {
  0: "none",
  5: "5 min",
  60: "1 hour",
  360: "6 hours",
  720: "12 hours",
  1440: "1 day",
  2880: "2 days",
  10080: "7 days",
  20160: "14 days",
  43200: "30 days"
};


/**
 * Function to obtain the frequency from the services if necessary
 */
const getFrequencyText = (frequency) => {
    let new_frequency = []
    if (frequency && frequency.display) {
        if (frequency.display.when === 'once' || frequency.display.when === 'always') {
        new_frequency.push(i18n.t(frequency.display.when))
      } else {
        let display = DISPLAY_OPTIONS[frequency.display.seconds/60]
        if ( !!display)
          new_frequency.push(`${i18n.t(frequency.display.when)} ${i18n.t(display)}`)
        else{
          display = Object.keys(DISPLAY_OPTIONS)[Object.keys(DISPLAY_OPTIONS).length - 1]
          Object.keys(DISPLAY_OPTIONS).some( item => {
            if (item > frequency.display.seconds/60){
              display = item
              return true
            }
          });
          if ( DISPLAY_OPTIONS[display] == 'once')
            new_frequency.push(`${i18n.t('once')}*`)
          else
            new_frequency.push(`${i18n.t(frequency.display.when)} ${i18n.t(DISPLAY_OPTIONS[display])}*`)
        }
      }
      if (frequency.delay && frequency.delay > 0) {
        let delay = DELAY_OPTIONS[frequency.delay/60]
        if ( !!delay)
          new_frequency.push(`${i18n.t('Delay')} ${i18n.t(delay)}`)
        else{
          delay = Object.keys(DELAY_OPTIONS)[Object.keys(DELAY_OPTIONS).length - 1]
          Object.keys(DELAY_OPTIONS).some( item => {
            if (item > frequency.delay/60){
              delay = item
              return true
            }
          });
          new_frequency.push(`${i18n.t(frequency.display.when)} ${i18n.t(DELAY_OPTIONS[delay])}*`)
        }
      }
    } else {
      new_frequency.push(`${i18n.t('always')}`);
    }
    return (new_frequency)
};


