<template>
  <div class="sidebar"
       :data="backgroundColor">

    <div class="logo">
      <a class="simple-text logo-mini">
        <div class="logo-image">
          <img :src="logo">
        </div>
      </a>

      <div class="simple-text logo-normal">
        {{title}}
      </div>

      <div class="navbar-minimize">
        <button
          id="minimizeSidebar"
          class="btn btn-simple btn-icon btn-neutral btn-round"
          style="border: none; box-shadow: none;"
          @click="minimizeSidebar">
          <!--<i class="now-ui-icons text_align-center visible-on-sidebar-regular"></i>
          <i class="now-ui-icons design_bullet-list-67 visible-on-sidebar-mini"></i>-->
          <svgicon
            original
            icon="menu"
            width="24"
            height="24"
          />
        </button>
      </div>

      <div v-if="licenseAlert" class="container-fluid" >         
        <div>    
          <i><svgicon class="icon" icon="license-alert" width="17" height="17" /></i>
          {{t('License Alert')}}
        </div>
      </div>
    </div>

    <div class="sidebar-wrapper" ref="sidebarScrollArea">
      <slot></slot>
      <ul class="nav">
        <slot name="links">
          <nested-sidebar v-for="(link, index) in sidebarLinks"
                        :key="link.name + index"
                        :link="link">

            <nested-sidebar v-for="(subLink, index) in link.children"
                          :key="subLink.name + index"
                          :link="subLink">
            </nested-sidebar>
          </nested-sidebar>
        </slot>

      </ul>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'

export default {
  name: 'nested-sidebar',
  computed:
    mapState('generalManagement/license',['licenseAlert']),
  props: {
    title: {
      type: String,
      default: 'Secure'
    },
    backgroundColor: {
      type: String,
      default: 'isp',
      validator: value => {
        let acceptedValues = [
          '',
          'blue',
          'azure',
          'green',
          'orange',
          'red',
          'purple',
          'black',
          'isp'
        ];
        return acceptedValues.indexOf(value) !== -1;
      }
    },
    logo: {
      type: String,
      default: 'img/logo.png'
    },
    sidebarLinks: {
      type: Array,
      default: () => []
    },
    autoClose: {
      type: Boolean,
      default: true
    },  
    web: {
      type: String,
      default: ''
    }  
  },
  provide() {
    return {
      autoClose: this.autoClose
    };
  },  
  methods: {
    minimizeSidebar() {
      if (this.$sidebar) {
        this.$sidebar.toggleMinimize();
      }
    },    
  },
  beforeDestroy() {    
    if (this.$sidebar.showSidebar) {      
      this.$sidebar.showSidebar = false;      
    }   
  }, 
};
</script>
<style>
@media (min-width: 992px) {
  .navbar-search-form-mobile,
  .nav-mobile-menu {
    display: none;
  }
}
</style>
