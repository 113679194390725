<template>
    <b-card >
        <slot name="header" v-if="! no_header">
            <h5 slot="header" class="title">{{title}}</h5>
        </slot>

        <b-container fluid style="padding: 0px;" >
            <b-row no-gutters :class="{ 'justify-content-around': content_around }" >
                <b-col :sm="cols ? cols: 6" :md="cols? cols : 6" :xl="cols ? cols: 4" v-for="(value, name) in list" :key="name">
                    <b-container fluid style="padding: 0px;">
                        <b-row no-gutters :class="{ 'justify-content-between': content_between }">
                            <b-col :cols="colsNumber" class="form-group has-label pt-2" align-self="center">
                                <el-tooltip :disabled="hideTooltip(value)" :content="value.description ? value.description : value.name" :open-delay="250">
                                    <h5 class="mx-auto my-auto" style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis">{{ value.name ? value.name : name }}</h5>
                                </el-tooltip>
                            </b-col>
                            <b-col cols="2" align-self="center">
                                <n-switch :disabled="disabled" v-model="value.enabled" :service="name"  @input="toggle($event, name)" :visible_for_permissions='visible_for_permissions'/>
                            </b-col> 
                        </b-row>
                    </b-container>
                </b-col>
            </b-row>
        </b-container>

        <slot name="footer"></slot>

    </b-card>
</template>

<script>
import { Switch } from '@/components';
import { mapState, mapMutations } from 'vuex';
import General from "@/mixins/General";

export default {
    name: 'toggle-list',
    components: {
        [Switch.name]: Switch,
    },
    mixins:[General],
    props: {
        content_between: {
            type: Boolean,
            default: false
        },
        content_around: {
            type: Boolean,
            default: false
        },
        no_header: {
            type: Boolean,
            default: false
        },
        value: {
            type: Object,
            default: () => {}
        },
        title: {
            type: String
        },
        oneCol: {
            type: Boolean,
            default: false
        },
        visible_for_permissions: { 
            type: Array, 
            default: function () { return [] }
        },
        disabled: {
            type: Boolean,
            default: false
        },
        twoCols: {
            type: Boolean,
            default: false
        },
        colsNumber:{
            type: Number,
            default: 8
        }
    },
    data() {
        return {
            list: {}
        }
    },
    watch: {
        value(newValue, oldValue) {
            this.list = newValue;
        },
    },
    mounted () {
        if(!!this.value) {
            this.list = this.value;
        }
    },
    computed: {
        ...mapState('settings',['checkedProducts']),

        cols() {
            return this.oneCol ? 12 :
            this.twoCols ? 6 : false;
        },
       
    },
    methods: {
        ...mapMutations('settings',['setCheckedProduct']),

        toggle(event, name) {
            this.list[name].enabled = event;
            this.$emit('input', this.list);

            if(Object.keys(this.checkedProducts).includes(name)) {
                this.setCheckedProduct({ name, event })
            }
        },
        hideTooltip(value){
            return value.description ? false  : value.name ? false : true;
        }
    }
}
</script>
