export const ADVANCED_CONFIGURATION = {
	"reset_safe_mode_min_time_between_actions_sec": 120,
	"reset_reboot_loop_min_time_between_actions_sec": 120,
	"set_agent_package_min_time_between_actions_sec": 120,
	"router_command_max_job_size": 10,
	"router_command_max_bulk_size": 1000,
	"router_command_bulk_min_interval_sec": 5,
	"poor_telnet_credentials_weight": 0.05,
	"open_port_weight": 0.01,
	"weak_wifi_weight": 0.1,
	"routersecure_health_check_interval_sec": 5,
	"routersecure_inactive_max_time_sec": 300,
	"default_account_operational_mode": "security_mode"
	}

export const DEBUG_OPTIONS = {
	router_model_options: [{ value: 'ROption1', label: 'ROption1'}, { value: 'ROption2', label: 'ROption2'}, { value: 'ROption3', label: 'ROption3'}, { value: 'ROption4', label: 'ROption4'}, { value: 'ROption5', label: 'ROption5'}],
		router_firmware_options: [{ value: 'FOption1', label: 'FOption1'}, { value: 'FOption2', label: 'FOption2'}, { value: 'FOption3', label: 'FOption3'}, { value: 'FOption4', label: 'FOption4'}, { value: 'FOption5', label: 'FOption5'}],
		active_version_options: [{ value: 'AOption1', label: 'AOption1'}, { value: 'AOption2', label: 'AOption2'}, { value: 'AOption3', label: 'AOption3'}, { value: 'AOption4', label: 'AOption4'}, { value: 'AOption5', label: 'AOption5'}],
		target_version_options: [{ value: 'TOption1', label: 'TOption1'}, { value: 'TOption2', label: 'TOption2'}, { value: 'TOption3', label: 'TOption3'}, { value: 'TOption4', label: 'TOption4'}, { value: 'TOption5', label: 'TOption5'}]
	}

export default () => ({
  _routers: [],
  router_model_options: [],
	router_firmware_options: [],
	active_version_options: [],
	target_version_options: [],
	attribute_options: [],
	job_id: "-1",
	advanced_configuration: ADVANCED_CONFIGURATION,
	agent_packages: []
})

