/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'item-checkurls': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M3.1 13.9H19c.4 0 .8-.3.8-.8V8.2c0-.4-.3-.8-.8-.8H3.1c-.4 0-.8.3-.8.8v4.9c0 .5.4.8.8.8z" _fill="none" _stroke="#383132" stroke-width="2" stroke-miterlimit="10"/><circle pid="1" cx="19.8" cy="14.2" r="3.7" _fill="#383132"/><path pid="2" _fill="#fff" d="M18.2 13.6l-.7.7 1.9 1.9 3-3-.7-.7-2.3 2.2z"/><g _fill="#383132"><path pid="3" d="M4.5 9.2h.6v1c.1-.2.3-.3.6-.3.4 0 .7.2.7.7v1.2h-.7v-1.1c0-.2-.1-.3-.2-.3-.2 0-.3.1-.4.3v1h-.6V9.2zM6.9 10.5h-.2V10H7v-.6h.6v.6H8v.5h-.4v.6c0 .2.2.2.3.2H8v.6h-.2c-.3 0-.9-.1-.9-.7v-.7zM8.5 10.5h-.3V10h.3v-.6h.6v.6h.4v.5h-.4v.6c0 .2.2.2.3.2h.1v.6h-.2c-.3 0-.9-.1-.9-.7v-.7zM9.9 9.9h.6v.2s.2-.3.6-.3c.5 0 .9.4.9 1s-.4 1-.9 1c-.3 0-.5-.2-.5-.2V12.5h-.7V9.9zm1 1.5c.2 0 .4-.2.4-.5s-.2-.5-.4-.5-.4.1-.4.5c.1.2.2.5.4.5zM12.3 9.9h.6v.6h-.6v-.6zm0 1.4h.6v.6h-.6v-.6zM14.2 9.1h.5l-1 3h-.5l1-3zM15.8 9.1h.5l-1 3h-.5l1-3z"/></g>'
  }
})
