<template>
    <div>
        <slot name="header">
            <h4>{{ title }} </h4>
        </slot>
        <div ref="chart" class="svg-container"></div>
    </div>
</template>

<script>
const d3 = require('d3');

export default {
  name: 'bar-chart',
  props: {
    title:{
        type: String,
        default: ''
    },
    width : {
        type: Number,
        default: 300,
        validator: value => {
            return value > 0;
        }
    },
    height : {
        type: Number,
        default: 450,
        validator: value => {
            return value > 0;
        }
    },
    data : {
        type: Array,
        default: function() {
                return [];
        },
    }
  },
  data() {
    return {
        mobile: false,
        data2: [ {name: "Porn",    value:  34},
            {name: "Gambling",    value:  18},
            {name: "Hackers",     value: 54},
            {name: "Shopping",   value: 6},
            {name: "Society", value: 2},
            {name: "Press",     value: 21}]
    };
  },
  computed: {
        dataset() {
            if ( this.$store.getters.isDummy && ( !this.data || this.data.length == 0))
                return this.data2;
            else
                return this.data;
        },
  },
  methods: {
    _calcDigits(num){
        let n=1;
        let p=1;
        while ( p*10-1 < num){
            n +=1;
            p *= 10;
        }
        return n;
    },
    _changeChartSize: function(isData){
        if ( !isData)
            this.$refs.chart.style.paddingBottom = "100px";
        //else{
        //    var ratio = this.width > 0 ? Math.floor(this.height *100 / this.width) : 0;
        //    this.$refs.chart.style.paddingBottom = "" + ratio + "%";
        //}
    },
    draw(dataset, mobile){
        var noData = !dataset || dataset.length == 0 || 
                (dataset.length == 1 && dataset[0].name== 'No Data');
        this._changeChartSize(!noData);

        if (!dataset || dataset.length == 0)
            return;

        //sort bars based on value
        var data = dataset.sort(function (a, b) {
            return d3.ascending(a.value, b.value);
        })

        //set up svg using margin conventions - we'll need plenty of room on the left for labels
        var margin = {
            top: 20,
            right: 15 + 7*this._calcDigits(
                    d3.max(data, function (d) {return d.value;})),
            bottom: 20,
            left: this.mobile ? 15 : 25 + (this.width/2.25)
        };
                
        var width = this.width - margin.left - margin.right;
        var height = this.height - margin.top - margin.bottom;
        //var color = d3.scaleOrdinal(d3.schemePaired);
        var color = d3.scaleOrdinal().range(
            ["#13a1cb", "#6dc8bf","#f78d1e", "#ed1d24","#e23d80","#8b8d94",
                "#ff7f00", "#feda00", "#111217", "#01648d"]
        )

        if ( noData){
            var svg = d3.select(this.$refs.chart)
                    .append("svg")
                    .attr("preserveAspectRatio", "xMinYMin meet")
                    .attr("viewBox", "0 0 " + this.width + " " + this.height)
                    .attr("class", "svg-content-responsive")
                    .append("g");
                    
            svg.selectAll(".bar")
                    .data(data)
                    .enter()
                    .append("g")
                    .append("text")
                    .attr("class", "noData")
                    .attr("x", this.width/2)
                    .attr("y", 35)
                    .text("No data to display");
            return;
        }               

        var svg = d3.select(this.$refs.chart)
                .append("svg")
                .attr("preserveAspectRatio", "xMinYMin meet")
                .attr("viewBox", "0 0 " + this.width + " " + this.height)
                .attr("class", "svg-content-responsive")
                .append("g")
                .attr("transform", "translate(" + margin.left + "," + margin.top + ")")

        var x = d3.scaleLinear()
            .range([0, width])
            .domain([0, d3.max(data, function (d) {
                return d.value;
            })]);      
        var y = d3.scaleBand()
            .rangeRound([height, 0], .1)
            .domain(data.map(function (d) {
                return d.name;
            }));       
        var bars = svg.selectAll(".bar")
            .data(data)
            .enter()
            .append("g");


        //append rects
        bars.append("rect")
            .attr("class", "bar")
            .attr("y", function (d) {
                return y(d.name);
            })
            .attr("x", 0)
            .attr("width", function (d) {
                //initial 0, see transition
                return 0;
            })
            .attr('fill', function(d, i) {
                return color(d.name);
            });

        bars.selectAll('rect')
            .transition().duration(2500)
            .attr('width', function(d) { return x(d.value)});


        //add a value label to the right of each bar
        bars.append("text")
            .attr("class", "labelx")
            .attr("x", function (d) {
                return x(d.value) + 3;
            })
            .text(function (d) {
                return 0;
            })
        bars.selectAll("text")
            .transition()
            .delay(250)
            .duration(2000)
            .tween("text", function(d) { 
                var i = d3.interpolate(0, d.value);
                return function(t){
                    d3.select(this).text(Math.floor(i(t)));
                }
            })

        if ( mobile){
            svg.selectAll('rect')
                    .attr("height", y.bandwidth() *0.6);
            bars.selectAll('text')
                    .attr("y", function (d) {
                            return y(d.name) + y.bandwidth() *0.45 -2;
                    });
            bars.append("text")
                    .attr("class", "axis")
                    .attr("y", function (d) {
                            return y(d.name) - 2;
                    })
                    .attr("x", function (d) {
                            return 1;
                    })
                    .text(function (d) {
                            return d.name;
                    });                        
        }
        else{
            svg.selectAll('rect')
                    .attr("height", y.bandwidth() - 2)
            bars.selectAll('text')
                    .attr("y", function (d) {
                            return y(d.name) + y.bandwidth() /2 + 4;
                    });
            //make y axis to show bar names
            var yAxis = d3.axisLeft()
                .scale(y)
                //no tick marks
                .tickSize(0)
                    
            var gy = svg.append("g")
                .attr("class", "y axis")
                .call(yAxis)
        }               
    },
    reset: function(){
        this.$refs.chart.innerHTML = "";
    },
    resize: function() {
        this.mobile = (screen.width < 540)
    },
    reload: function(){
        this.reset();
        this.draw(this.dataset, this.mobile);
    }
  },
  created() {
    window.addEventListener('resize', this.resize);
  },
  destroyed() {
    window.removeEventListener('resize', this.resize);
  },
  mounted() {
      this.resize();
      console.log(this.dataset)
      this.draw(this.dataset, this.mobile);
  },
  watch: {
    data: function(newValue, oldValue) {
        if (newValue != oldValue)
            this.reload();
    },
    mobile: function(newValue, oldValue){
        this.reset();
        this.draw(this.dataset, newValue);
    }
  }
};
</script>
<style>
</style>
