/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'groups': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path pid="0" d="M32 27.6a1.6 1.6 0 11-3.2 0c0-2.646-2.154-4.8-4.8-4.8a4.775 4.775 0 00-2.97 1.038c.87 1.594 1.37 3.421 1.37 5.362a1.6 1.6 0 11-3.2 0c0-4.411-3.589-8-8-8s-8 3.589-8 8a1.6 1.6 0 11-3.2 0C0 23.024 5.026 18 11.2 18c3.083 0 5.877 1.253 7.904 3.274A7.99 7.99 0 0124 19.6c4.411 0 8 3.589 8 8m-8-16c.883 0 1.6.718 1.6 1.6 0 .882-.717 1.6-1.6 1.6-.883 0-1.6-.718-1.6-1.6 0-.882.717-1.6 1.6-1.6m0 6.4c2.646 0 4.8-2.154 4.8-4.8S26.646 8.4 24 8.4a4.806 4.806 0 00-4.8 4.8c0 2.646 2.154 4.8 4.8 4.8M11.2 5.2c1.765 0 3.2 1.435 3.2 3.2 0 1.765-1.435 3.2-3.2 3.2A3.203 3.203 0 018 8.4c0-1.765 1.435-3.2 3.2-3.2m0 9.6c3.53 0 6.4-2.87 6.4-6.4 0-3.53-2.87-6.4-6.4-6.4-3.53 0-6.4 2.87-6.4 6.4 0 3.53 2.87 6.4 6.4 6.4" _fill="#000" fill-rule="nonzero"/>'
  }
})
