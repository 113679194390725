/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'reporter': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path pid="0" d="M15.682 0a1.746 1.746 0 011.775 1.746v12.797h12.797A1.745 1.745 0 0132 16.318c-.175 8.71-7.286 15.678-15.998 15.677h-1.454v-.117h-.45C5.751 30.88-.398 23.594.02 15.2.437 6.806 7.28.166 15.682 0zm-1.134 2.982a13.089 13.089 0 00-.407 25.973 13.089 13.089 0 0014.95-11.503H16.293a1.745 1.745 0 01-1.745-1.745zM19.645 1.1a1.745 1.745 0 011.578-.228 15.997 15.997 0 019.904 9.904 1.745 1.745 0 01-1.643 2.312h-8.828a1.745 1.745 0 01-1.745-1.745V2.516c.002-.562.276-1.09.734-1.415zm2.175 3.16v5.92h5.918a13.249 13.249 0 00-5.918-5.92z" _fill="#000" fill-rule="evenodd"/>'
  }
})
