<template>
  <b-row no-gutters>
    <b-card>
      <slot name="header">
        <h5 class="title py-2"><i class="now-ui-icons files_box mr-2" />{{t('Service Status')}}</h5>
        {{ t(help_text) }}
      </slot>
      <b-container class="mt-2 p-4" fluid>
        <b-row no-gutters>
          <b-col :sm="6" :md="6" :xl="4" v-if="is_service(service)">
            <b-container fluid class="p-0">
              <b-row no-gutters class="mb-1">
                <b-col cols="8" class="form-group has-label pt-2" align-self="center">
                  <h5 class="mx-auto my-auto text-capitalize text-nowrap">
                    {{ t(serviceName) }}
                  </h5>
                  <span v-if="is_disabled(serviceName)" class="isp-global">{{t('Overwriten by ISP global')}}</span>
                </b-col>
                <b-col cols="2" align-self="center">
                  <n-switch v-model="service.enabled" :service="serviceName" @input="toggle($event, serviceName)" :disabled="is_disabled(serviceName)" color="allot" :visible_for_permissions="visible_for_permissions"/>
                </b-col>
              </b-row>
              <div v-if="is_disabled(serviceName)" class="disabled" />
            </b-container>
          </b-col>
          <b-col cols="6" md="4" lg="3" xl="2" class="ml-auto">
            <n-button type="primary" size="md" round block :disabled="validation_errors.length > 0" v-text="getLocalizedText('Save')" v-b-modal.confirm-update :visible_for_permissions="visible_for_permissions"/>
          </b-col>
        </b-row>
      </b-container>
    </b-card>
  </b-row>
</template>

<script>
import {mapState, mapGetters, mapMutations, mapActions} from "vuex";
import { ToggleList, Switch }                 from "@/components";
import   GeneralMixin                         from "./GeneralMixin";
import   schemaValidation                     from "@/mixins/schemaValidation";
import _ from "lodash";

export default {
  components: {
    [ToggleList.name]: ToggleList,
    [Switch.name]: Switch,
  },
  mixins: [GeneralMixin, schemaValidation],
  props: {
    store_module: {
      type: String,
      default: "",
    },
    global: {
      type: Boolean,
      default: false,
    },
    serviceName: {
      type: String,
    },
    visible_for_permissions: { 
      type: Array, 
      default: function () { return [] }
    }
  },
  data() {
    return {
      help_text:
        "Enable or disable the service that will be globally enabled for the product:",
    };
  },
  computed: {
    ...mapGetters("ispServices/services", ["global_configurations", "advanced_configurations",]),
    ...mapState("settings",["subsystems", "subsystemsDict"]),

    configurations() {
      return this.global ? this.global_configurations : this.advanced_configurations;
    },
    service() {
      const configurations = _.cloneDeep(this.configurations);
      return configurations[this.serviceName];
    }
  },
  methods: {
    ...mapMutations("ispServices/services", ["setConfig", "setGlobalConfig"]),
    ...mapActions('settings', ['setIsFormDirty']),
    is_service(service) {
      return _.has(service, "enabled");
    },

    toggle(value, key) {
      this.global ? this.setGlobalConfig({ key, value }) : this.setConfig({ key, value });
     	this.setIsFormDirty(true);
    },

    is_disabled(serviceName) {
      return !_.get(this.global_configurations, `${this.subsystemsDict[serviceName]}.enabled`, true);
    },
    getLocalizedText(text) {
      return this.t(text);
    }
  }
};
</script>

<style lang="scss" scoped>
.isp-global {
  position: absolute;
  font-size: 11px;
  color: red;
  margin-top: -3px;
}
.disabled {
  top: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: #ffffff73;
  cursor: not-allowed;
}
</style>
