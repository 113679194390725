export default () => ({
    defaultLang: null,
    selectedLang: 'en-US',
    defaultsNotifications: {
      allConfigurations: {},
      platforms: {},
    },
    notifications: {
      allConfigurations: {},
      platforms: {},
    },
    gatewayState: {
      sms: false,
      email: false,
    },
    defaultAdvancedConfiguration: {},
    advancedConfiguration: {}
  })