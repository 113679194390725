import axios from "axios";
import i18n from "../../../i18n";
import _ from "lodash";

export default {
    getAgentPackages({ commit, state, rootState, dispatch }, {router_model:router_model, firmware_version : firmware_version} ) {
        if( !rootState.settings.debug ) {
            return new Promise((resolve, reject) => {                   
                axios.get('/subsystems/rs/router-agent-packages')
                .then(res => {                                            
                    if (res && res.data  && res.data.items ) {                        
                        let agents = []
                        res.data.items.forEach(item => {
                            let firmwares = (item.firmware_versions ? item.firmware_versions : [])                            
                            if( item.router_model == router_model && (!firmware_version || firmwares.indexOf( firmware_version ) >= 0 || firmwares.length == 0 ) ){                                
                                agents.push({
                                    key : item.agent_package_id,
                                    value : item.agent_version_id,
                                    time : item.upload_time,
                                    default : false,
                                    firmware_versions: item.firmware_versions
                                })
                            }                            
                        }) 
                        axios.get('/subsystems/rs/router-default-agent-packages')
                        .then(res => {                                            
                            if (res && res.data  && res.data.items ) {                                  
                                let eqfw = false
                                agents.forEach(agent => {
                                    let adefault = res.data.items.filter(item => {
                                        return item.agent_package_id === agent.key && item.firmware_version == firmware_version
                                    })
                                    if( adefault.length > 0 ){
                                        agent.default = true;
                                        eqfw = true
                                    }
                                    else {
                                        if( !!!eqfw ){
                                            adefault = res.data.items.filter(item => {
                                                return item.agent_package_id === agent.key && item.firmware_version == "*" 
                                            })
                                            if( adefault.length > 0  )                                   
                                                agent.default = true;
                                        }
                                    }
                                })                                                        
                            }                                                                                                               
                            resolve(agents)                         
                        }).catch(error => {
                            commit('setError', error, { root: true });
                            reject(error)
                        })
                    }
                    else
                        reject()
                }) .catch(error => {
                    commit('setError', error, { root: true });
                    reject(error)
                })
            })
        } else {           
            return new Promise((resolve, reject) => {
                let temp = [                    
                    {   
                        key : "1234",                                                    
                        value : "agent-300.bin" ,
                        time :  "2020-06-12T18:30:00Z",
                        default: false                      
                    },
                    {   
                        key : "8910",                                                    
                        value : "agent-400.bin",
                        time : "2021-06-12T18:30:00Z",
                        default: false
                    },
                    {   
                        key : "456" ,                        
                        value : "agent-200.bin",
                        time : "2022-06-12T18:30:00Z",
                        default: true                   
                    }
                ];                
                resolve(temp)
            });
        }
    },
  
    resetRouterDevice({ commit, state, rootState, dispatch }, {account: account, device: device, mode:mode}) {  
        let raction = {};

        if( mode == "safe" )      
            raction["reset_safe_mode"] = true            
        else
            raction["reset_unstable"] = true
       
        return new Promise((resolve, reject) => {
            axios.post('/accounts/' + encodeURIComponent(account) + '/devices/' + encodeURIComponent(device) + '/reset-state', raction)            
            .then(res => {
                resolve(res)
            })
            .catch(error => {
                try{
                    if (error.response.data.type == 'RSCLIENT.REPEATED_ACTION') {
                        commit('setError', i18n.t('RSCLIENT.REPEATED_ACTION'), {root: true});
                    }else {
                        commit('setError', error, { root: true });
                    }
                }
                catch(e){
                    commit('setError', error, { root: true });
                }
                reject(error)
            })
        });
    },
  
    getRouterProtectionCaps({ commit, state, rootState, dispatch }) {
        if( rootState.settings.debug ) {
            return new Promise((resolve, reject) => {
                resolve(false)
            })
        } else {
            return new Promise((resolve, reject) => {            
                axios.get('/subsystems/rs/router-protection')
                .then(res => {
                    if (res && res.data)
                        resolve(res.data.enabled)
                    reject()
                }).catch(error => {
                    commit('setError', error, { root: true });
                    reject()
                })
            });
        }
    },

    getDeviceTargetAgent({ commit, state, rootState, dispatch }, device) {        
        return new Promise((resolve, reject) => {    
            const config = {
                filter : {
                    router_id : [
                        device
                    ],                         
                },
                limit : 1                    
            }

            axios.post('/subsystems/rs/routers/search', config)
            .then(res => {                   
                if (res && res.data && res.data.items.length > 0 )                        
                    resolve(res.data.items[0].target_agent_package_id)
                else{
                    commit('setError', i18n.t("RSCLIENT.Router not found"), { root: true });
                    reject()
                }
            }).catch(error => {
                commit('setError', error, { root: true });
                reject()
            })
        });        
    },
    
    getFeatures({ commit, state, rootState, dispatch },{account: account, device: device}) {
        if ( rootState.settings.debug ) {
            return new Promise((resolve, reject) => {
                let features = {
                    allowed_values : ["0", "1"],
                    flags : [
                    {
                        "name" : "subnet_pump",
                        "description" : "Periodic CPE IP addresses query",
                        "default_value" : "0"
                    },
                    {
                        "name" : "traffic_report",
                        "description" : "Periodic report upload",
                        "default_value" : "1"
                    },
                    {
                        "name" : "periodic_web",
                        "description" : "Periodic website probabilistic filters download",
                        "default_value" : "0"
                    },
                    {
                        "name" : "periodic_download",
                        "description" : "Periodic download a list of white list domain",
                        "default_value" : "1"
                    },
                    ]
                }              
                resolve(features)
            })
        } else {
            return new Promise((resolve, reject) => {          
                axios.get('/accounts/' + encodeURIComponent(account) + '/devices/' + encodeURIComponent(device) + '/feature-flags')
                .then(res => {
                    if (res && res.data) {                 
                        let features = {
                            allowed_values : res.data.default.allowed_values,
                            flags : []
                        }
                        res.data.default.flags.forEach(item => {
                            features.flags.push({
                                name : item.name,
                                description : item.description,
                                default_value : item.default_value,
                                custom_value : res.data.custom.hasOwnProperty(item.name) ? res.data.custom[item.name] : item.default_value,
                                isCustom : res.data.custom.hasOwnProperty(item.name) ? true : false
                            })
                        });                  
                        resolve(features)
                    }
                    reject()
                }).catch(error => {
                    if (error.response && error.response.data && error.response.data.type === 'RSCLIENT.AgentPackageOfRouterNotFoundException')
                        commit('setError', i18n.t('RSCLIENT.AgentPackageOfRouterNotFoundException_features'), { root: true });
                    else
                        commit('setError', error, { root: true });
                    reject()
                })
            });
        }
    },
  
    getLogs({ commit, state, rootState, dispatch },{account: account, device: device}) {
        if ( rootState.settings.debug ) {
            return new Promise((resolve, reject) => {
                let logs = {
                    allowed_values : ["DEBUG", "VERBOSE", "INFO", "WARN", "ERROR"],
                    flags : [
                        {
                            "name" : "logger_component_csp_ops",
                            "description" : "Logger Component CSP OPS",
                            "default_value" : "WARN"
                        },
                        {
                            "name" : "LOGGER_COMPONENT_CSP_OPS",
                            "description" : "Logger Component Infra http",
                            "default_value" : "INFO"
                        },
                        {
                            "name" : "LOGGER_COMPONENT_INFRA_IO",
                            "description" : "Logger component infra http",
                            "default_value" : "VERBOSE"
                        },
                        {
                            "name" : "LOGGER_COMPONENT_INFRA_JSMN",
                            "description" : "Logger component infra jsmn",
                            "default_value" : "ERROR"
                        },
                    ]
                }                
                resolve(logs)
            })
        } else {
            return new Promise((resolve, reject) => {
                axios.get('/accounts/' + encodeURIComponent(account) + '/devices/' + encodeURIComponent(device) + '/log-flags')
                .then(res => {
                    if (res && res.data) {    
                        let logs = {
                            allowed_values : res.data.default.allowed_values,
                            flags : []
                        }
                        res.data.default.flags.forEach(item => {
                            logs.flags.push({
                            name : item.name,
                            description : item.description,
                            default_value : item.default_value,
                            custom_value : res.data.custom.hasOwnProperty(item.name) ? res.data.custom[item.name] : item.default_value,
                            isCustom : res.data.custom.hasOwnProperty(item.name) ? true : false
                            })
                        });                        
                        resolve(logs)
                    }
                    reject()
                }).catch(error => {
                    try{
                        if (error.response && error.response.data && error.response.data.type === 'RSCLIENT.AgentPackageOfRouterNotFoundException')
                            commit('setError', i18n.t('RSCLIENT.AgentPackageOfRouterNotFoundException_logs'), { root: true });
                        else
                            commit('setError', error, { root: true });
                    }
                    catch(err){
                        commit('setError', error, { root: true });
                    }
                    reject()
                })
            });
        }
    },

    setFeatures({ commit, state, dispatch },{account: account, device: device, data: data}) {  
        return new Promise((resolve, reject) => {                
            axios.put('/accounts/' + encodeURIComponent(account) + '/devices/' + encodeURIComponent(device) + '/feature-flags', data)
            .then(res => {   
                resolve(res)                             
            }).catch(error => {
                commit('setError', error, { root: true });     
                reject()
            })     
        })
    },    

    setLogs({ commit, state, dispatch },{account: account, device: device, data: data}) {                
        return new Promise((resolve, reject) => {
            axios.put('/accounts/' + encodeURIComponent(account) + '/devices/' + encodeURIComponent(device) + '/log-flags', data)
            .then(res => {                                
                resolve(res)
            }).catch(error => {
                commit('setError', error, { root: true });     
                reject()
            })     
        })
    },
    
    resetFlags({ commit, state, rootState, dispatch },{account: account, device: device, mode: mode}) {
        return new Promise((resolve, reject) => {
            axios.delete('/accounts/' + encodeURIComponent(account) +
                        '/devices/' + encodeURIComponent(device) + (mode == "Features" ? '/feature-flags' : '/log-flags'))
            .then( () => {
                resolve()
            }).catch(error => {
                commit('setError', error, { root: true });
                reject()
            })
        });
    },

    setDeviceTargetAgent({ commit, state, rootState, dispatch },{account: account, device: device, agent: agent}) {  
        let p = {
            target_agent_package_id : agent
        }
        return new Promise((resolve, reject) => {
            axios.post('/accounts/' + encodeURIComponent(account) + '/devices/' + encodeURIComponent(device) + '/set-agent-package', p)
            .then(()  => {
                resolve()
            }).catch(error => {
                const errorType = _.get(error, "response.data.type");
                const typeBasedErrors = [
                  "RSCLIENT.DEVICE_NOT_COMPATIBLE",
                  "RSCLIENT.REPEATED_ACTION"
                ];
                if (typeBasedErrors.includes(errorType)) {
                  error = i18n.t(error.response.data.type);
                }
                commit('setError', error, { root: true });
                reject();
            })
        })
    },
    resetAttrRSNetworkDevice({ commit, state, rootState, dispatch },{account: account, device: device, mode: mode}) {                    
        let config = { product_data : { RouterSecure :  { data : {configuration : { device_identification : {}}}}}};
        config.product_data.RouterSecure.data.configuration.device_identification["device_" + mode + "_mode"] = "auto";

        return new Promise((resolve, reject) => {  
            axios.patch('/accounts/' + encodeURIComponent(account) + '/devices/' + encodeURIComponent(device), config)
            .then(res => {
                
                axios.get('/accounts/' + encodeURIComponent(account) + '/devices/' + encodeURIComponent(device))
                .then(res => {
                    if(res && res.data)
                        resolve(res.data[mode])
                    reject()
                }).catch(error => {
                    reject()
                    commit('setError', error, { root: true });            
                })    


            }).catch(error => {
                reject()
                commit('setError', error, { root: true });            
            })                    
        })

    }


}