/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'upload': {
    width: 50,
    height: 43,
    viewBox: '0 0 50 43',
    data: '<path pid="0" d="M0 25h5v12.5h40V25h5v12.5c0 2.775-2.225 5-5 5H5a5 5 0 01-5-5V25zm25 7.5l13.875-13.65-3.55-3.525-7.825 7.8V0h-5v23.125l-7.8-7.8-3.55 3.55L25 32.5z" _fill="#DADDE2"/>'
  }
})
