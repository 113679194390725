import axios from "axios";
import i18n from '../../../i18n';

export default {
    getDefaultAgents({ commit, state, rootGetters }) {
        if( !rootGetters['settings/getDebug'] ) {
            return new Promise((resolve, reject) => {                
                axios.get('/subsystems/rs/router-agent-packages')
                    .then(res => {
                        if (res && res.data  && res.data.items ) {
                            let tmpAgentPackage = {};

                            res.data.items.forEach(item => {
                                tmpAgentPackage[item.agent_package_id] = item.agent_version_id
                            });

                                                                          
                                axios.get('/subsystems/rs/router-default-agent-packages')
                                    .then(res => {
                                        if (res && res.data  && res.data.items ) {                                           
                                            state.agents_default_list.clear()
                                            res.data.items.forEach(item => {
                                                let agent = {
                                                    id: item.default_mapping_id,
                                                    data: {
                                                        default_mapping_id : item.default_mapping_id,
                                                        router_model : item.router_model,
                                                        firmware_version : i18n.t(item.firmware_version),
                                                        agent_package_id : item.agent_package_id,
                                                        agent_version_id : tmpAgentPackage[item.agent_package_id]
                                                        
                                                    }
                                                }
                                                commit('addDefaultAgent', agent)                                
                                            })
                                            let ret = state.agents_default_list                                            
                                            resolve(ret)
                                        } else
                                            reject()
                                    })
                                    .catch(error => {
                                        commit('setError', error, { root: true });
                                        reject(error)
                                    })
                            
                        }
                        else {
                            reject()
                        }
                    })
                    .catch(error => {
                        commit('setError', error, { root: true });
                        reject(error)
                    })
                })
           
        }
        else {        
            return new Promise((resolve, reject) => {
                let temp = [                    
                    {   
                        "default_mapping_id" : "1234",                            
                        "router_model" : "Sarcomm SHG3000",
                        "firmware_version" : "4.2.4.5.1",
                        "agent_package_id" : "1234",                        
                        "agent_version_id" : "agent-300.bin"
                    },
                    {   
                        "default_mapping_id" : "6789",                            
                        "router_model" : "Sarcomm SHG3000",
                        "firmware_version" : "4.2.5.3",
                        "agent_package_id" : "1234",        
                        "agent_version_id" : "agent-300.bin"                
                    },
                    {   
                        "default_mapping_id" : "95123",                                                    
                        "router_model" : "TP-link 45",
                        "firmware_version" : "1.0.0.15",                        
                        "agent_package_id" : "456" ,
                        "agent_version_id" : "agent-200.bin"                  
                    }
                ];
                if (state.agents_default_list.size <= 0)
                    temp.forEach(item => {
                        let agent = {
                            id: item.default_mapping_id,
                            data: {
                                default_mapping_id : item.default_mapping_id,                                
                                router_model : item.router_model,
                                firmware_version : i18n.t(item.firmware_version),
                                agent_package_id : item.agent_package_id,
                                agent_version_id : item.agent_version_id
                            }
                        }
                        commit('addDefaultAgent', agent)
                    })
                else
                    state.agents_default_list.forEach((value, key) => {
                        value.firmware_version = i18n.t(value.firmware_version)
                    })
                    
                let ret = state.agents_default_list 
                resolve(ret)
            });
        }
    },

    getDefaultAgent({ commit, state, rootGetters }, default_mapping_id) { 
        if( !rootGetters['settings/getDebug'] ) {
            return new Promise((resolve, reject) => {                   
                axios.get('/subsystems/rs/router-default-agent-packages/' + encodeURIComponent(default_mapping_id))
                .then(res => {
                    if( res && res.data)
                        resolve(res.data)
                    else
                        reject()
                })
                .catch(error => {
                    commit('setError', error, { root: true });
                    reject(error)
                })                                        
            })
        } else {
            return new Promise((resolve, reject) => {              
                let ret = state.agents_default_list.get(default_mapping_id);
                if( ret.firmware_version == i18n.t("*") ) ret.firmware_version = "*"
                resolve(ret)
            });
        }
    },

    getAgentsByRouterModel({ commit, state, rootGetters }, dAgent) {
            return new Promise((resolve, reject) => {  
                axios.get('/subsystems/rs/router-agent-packages')
                    .then(res => {
                        if (res && res.data  && res.data.items ) {                            
                            let allAgents = res.data.items.filter(item => 
                                item.router_model == dAgent.router_model && 
                                    ( !!!item.firmware_versions || item.firmware_versions.length == 0 ||
                                    (item.firmware_versions.includes(dAgent.firmware_version) || dAgent.firmware_version == "*") )
                            )

                            axios.get('/subsystems/rs/router-default-agent-packages')
                            .then(res => {                                            
                                if (res && res.data  && res.data.items ) {
                                    state.agents_list.clear()

                                    let defaultAgents = res.data.items.filter(item => item.router_model == dAgent.router_model && item.firmware_version == dAgent.firmware_version )
                                    
                                    allAgents.forEach(agentItem => {

                                        let r = defaultAgents.find(defaultItem => {
                                            return defaultItem.agent_package_id == agentItem.agent_package_id
                                        });

                                        if( r == undefined || r.agent_package_id == dAgent.agent_package_id ){
                                            
                                            let agents = state.agents_list.get(dAgent.router_model);
                                            if(!agents) agents = new Map();

                                            let nfv = []
                                            agentItem.firmware_versions.forEach(fv => {
                                                nfv.push({key : fv, value: fv});
                                            })
                                            if (nfv.length === 0){
                                                nfv.push({key : "*", value: i18n.t("*")});
                                                if (!!r && r.firmware_version !== '*')
                                                    nfv.push({key : r.firmware_version, value: r.firmware_version});
                                            }

                                            let ndata = {
                                                router_model: dAgent.router_model,
                                                agent_package_id: agentItem.agent_package_id,
                                                agent_version_id: agentItem.agent_version_id,
                                                firmware_versions: nfv,
                                                upload_time: agentItem.upload_time
                                            }                    
                                            
                                            agents.set( agentItem.agent_package_id, ndata );
                                            agents = new Map(agents);
                                            
                                            let nagent = {
                                                id : dAgent.router_model,
                                                data : agents
                                            }
                                            commit('addAgentByRouter', nagent)
                                        }
                                        
                                    })
                                        
                                    let ret = state.agents_list                                     
                                    resolve(ret)
                                }
                                else
                                    reject()
                            }) .catch(error => {
                                commit('setError', error, { root: true });
                                reject(error)
                            })
                        }
                    }).catch(error => {
                        commit('setError', error, { root: true });
                        reject(error)
                    })
            })
       
    },

    getAgentsByRouter({ commit, state, rootGetters }) {
        if( !rootGetters['settings/getDebug'] ) {
            return new Promise((resolve, reject) => {  
                axios.get('/subsystems/rs/router-default-agent-packages')
                    .then(res => {
                        if (res && res.data  && res.data.items ) {
                            let defaultAgents = _.cloneDeep(res.data.items)
                            
                            axios.get('/subsystems/rs/router-agent-packages')
                            .then(res => {                                            
                                if (res && res.data  && res.data.items ) {
                                    state.agents_list.clear()
                                    res.data.items.forEach(item => {

                                        let nfv = [];
                                        if ( !!!item.firmware_versions || item.firmware_versions.length === 0)
                                            item.firmware_versions = ["*"]
                                        item.firmware_versions.forEach( fv => {
                                            let r = defaultAgents.find( def_agent => 
                                                       ( def_agent.router_model === item.router_model &&
                                                         //def_agent.agent_package_id === item.agent_package_id &&
                                                         def_agent.firmware_version === fv )
                                                    )

                                            if( r === undefined ) nfv.push({key : fv, value: fv==="*" ? i18n.t(fv) : fv});
                                        })

                                        if( nfv.length > 0  ){
                                            let agents = state.agents_list.get(item.router_model);
                                            if(!agents) agents = new Map();
                                            
                                            let ndata = {
                                                router_model: item.router_model,
                                                agent_package_id: item.agent_package_id,
                                                agent_version_id: item.agent_version_id,
                                                firmware_versions: nfv,
                                                upload_time: item.upload_time
                                            }                    
                                            
                                            agents.set( item.agent_package_id, ndata );
                                            agents = new Map(agents);
                                            
                                            let nagent = {
                                                id : item.router_model,
                                                data : agents
                                            }
                                            commit('addAgentByRouter', nagent)
                                        }
                                    })
                                    let ret = state.agents_list 
                                    resolve(ret)
                                }
                                else
                                    reject()
                            }) .catch(error => {
                                commit('setError', error, { root: true });
                                reject(error)
                            })
                        }
                    }).catch(error => {
                        commit('setError', error, { root: true });
                        reject(error)
                    })
            })
        } else {           
            return new Promise((resolve, reject) => {
                let temp = [                    
                    {   
                        "agent_package_id" : "1234",                            
                        "router_model" : "Sarcomm SHG3000",
                        "firmware_versions" : ["4.2.4.5.1","4.2.5.3"],                    
                        "agent_version_id" : "agent-300.bin",
                        "upload_time" : "2019-12-31T15:53:00Z",
                    },
                    {   
                        "agent_package_id" : "8910",                            
                        "router_model" : "Sarcomm SHG3000",
                        "firmware_versions" : ["4.2.4.5.1", "4.2.4.6.6","4.2.6.3"],                    
                        "agent_version_id" : "agent-400.bin",
                        "upload_time" : "2020-05-22T15:53:00Z"                
                    },
                    {   
                        "agent_package_id" : "456" ,
                        "router_model" : "TP-link 45",
                        "firmware_versions" : ["1.0.0.15",  "1.0.0.16"],                   
                        "agent_version_id" : "agent-200.bin",
                        "upload_time" : "2018-07-15T15:53:00Z"                                      
                    }
                ];
                if (state.agents_list.size <= 0)
                    temp.forEach(item => {
                        let agents = state.agents_list.get(item.router_model);
                        if(!agents) agents = new Map();
                        
                        let ndata = {
                            router_model: item.router_model,
                            agent_package_id: item.agent_package_id,
                            agent_version_id: item.agent_version_id,
                            firmware_versions: item.firmware_versions,
                            upload_time: item.upload_time
                        }                    
                        
                        agents.set( item.agent_package_id, ndata );
                        agents = new Map(agents);
                        
                        let nagent ={
                            id : item.router_model,
                            data : agents
                        }
                        commit('addAgentByRouter', nagent)
                    })
                let ret = state.agents_list 
                resolve(ret)
            });
        }
    },

    deleteDefaultAgent({ commit, state, rootGetters }, id) {
        if( !rootGetters['settings/getDebug'] ) {
            return new Promise((resolve, reject) => {                
                axios.delete('/subsystems/rs/router-default-agent-packages/' + encodeURIComponent(id))
                    .then(res => {
                        if (res) {
                            commit('removeDefaultAgent', id);
                            resolve(state.agents_default_list)
                        }
                    })
                    .catch(error => {
                        commit('setError', error, { root: true });
                        if (error.response && error.response.data) {
                            reject(error.response.data);
                        }
                        reject(error)
                    })
            });
        }
        else {
            return new Promise((resolve, reject) => {
                commit('removeDefaultAgent', id)
                resolve(state.agents_default_list)
            });            
        }
    },

    updateDefaultAgent({ commit, state, rootGetters }, data) {    
        if( !rootGetters['settings/getDebug'] ){            
            let ap = {
                agent_package_id : data.agent_package_id,
                router_model : data.router_model,
                firmware_version : data.firmware_version 
            }
            axios.put('/subsystems/rs/router-default-agent-packages/' + encodeURIComponent(data.default_mapping_id), ap)
            .then({})
            .catch(error => {
                commit('setError', error, { root: true });                 
            })
            
        } else {            
            return new Promise((resolve, reject) => {
                let ap = {
                    router_model : data.router_model,
                    agent_package_id : data.agent_package_id,
                    agent_version_id : data.agent_version_id,
                    firmware_version : i18n.t(data.firmware_version),
                    default_mapping_id : data.default_mapping_id
                }                   
                let nap = {
                    id : data.default_mapping_id,
                    data : ap
                }

                commit('addDefaultAgent', nap)
                resolve(state.agents_default_list)
            })
            
        }
    },

    addDefaultAgent({ commit, state, rootGetters },data){          
        if( !rootGetters['settings/getDebug'] ) { 
            let ap = {
                router_model : data.router_model,
                agent_package_id : data.agent_package_id,
                firmware_version : data.firmware_version
            }   
                        
            axios.post('/subsystems/rs/router-default-agent-packages', ap ).then({})
            .catch(error => {
                commit('setError', error, { root: true });                        
            })
            
        } else {                        
            data.firmware_versions.forEach(item => {
                let id = parseInt(Math.random()*1000).toString();
                let ap = {
                    router_model : data.router_model,
                    agent_package_id : data.agent_package_id,
                    agent_version_id : data.agent_version_id,
                    firmware_version : i18n.t(item),
                    default_mapping_id : id
                }   
                
                let nap = {
                    id : id,
                    data : ap
                }
                            
                commit('addDefaultAgent', nap)           
            })                                
        }
    }
    
}