import _ from 'lodash';
import { DEFAULT_BLOCKING_PAGE_TEXTS } from "@/util/languageUtils";

export default {
    setCustomization(state, customization){
        const id = customization.gui_id ? customization.gui_id : 'acc'
        state[id].app_name = customization.app_name
        state[id].company_name = !!customization.company_name ? customization.company_name : 'Allot'
        state[id].company_url = !!customization.company_url ? customization.company_url : 'http://www.allot.com'
        state[id].image_background = !!customization.image_background ? customization.image_background : null
        state[id].image_footer = !!customization.image_footer ? customization.image_footer : null
        state[id].image_logo = !!customization.image_logo ? customization.image_logo : null
        state[id].image_favicon = !!customization.image_favicon ? customization.image_favicon : null
        state[id].show_footer_text = !!customization.show_footer_text ? customization.show_footer_text : true
        state[id].show_footer_image = !!customization.show_footer_image ? customization.show_footer_image : true
        const acc_show_add_users_button = _.get(customization, 'env_options.visibility.acc_show_add_users_button', true)
        _.set(state[id], 'env_options.visibility.acc_show_add_users_button', acc_show_add_users_button)

        if(id === 'acc') {
            const primary_color = _.get(customization, 'env_options.colors.primary')
            if (primary_color) _.set(state[id], 'env_options.colors.primary', primary_color)

            const secondary_color = _.get(customization, 'env_options.colors.secondary')
            if (secondary_color) _.set(state[id], 'env_options.colors.secondary', secondary_color)

            const graphs = _.get(customization, 'env_options.colors.graphs')
            if (graphs) _.set(state[id], 'env_options.colors.graphs', graphs)

            const navigation = _.get(customization, 'env_options.navigation', 'dark')
            if (navigation) _.set(state[id], 'env_options.navigation', navigation)

            const fonts = _.get(customization, 'env_options.fonts')
            if (fonts) _.set(state[id], 'env_options.fonts', fonts)

            state[id].languages = customization.languages

            const blocking_pages = _.get(customization, 'env_options.blocking_pages', DEFAULT_BLOCKING_PAGE_TEXTS)
              _.set(state[id], 'env_options.blocking_pages', blocking_pages)
        } else {
          const image_footer = _.get(customization, 'image_footer', null);
          _.set(state[id], 'image_footer', image_footer);

          const show_footer_image = _.get(customization, 'show_footer_image', true);
          _.set(state[id], 'show_footer_image', show_footer_image);
        }
    },
    setFonts(state, fonts) {
      state.fonts = fonts;
    },
    updateCustomization(state, customization){
        const id = customization.id;
        if (customization.data.app_name) state[id].app_name = customization.data.app_name
        if (customization.data.company_name) state[id].company_name = customization.data.company_name
        if (customization.data.company_url) state[id].company_url = customization.data.company_url
        if (customization.data.image_background) state[id].image_background = customization.data.image_background
        if (customization.data.image_footer) state[id].image_footer = customization.data.image_footer
        if (customization.data.image_logo) state[id].image_logo = customization.data.image_logo
        if (customization.data.image_favicon) state[id].image_favicon = customization.data.image_favicon
        if (customization.data.show_footer_text) state[id].show_footer_text = customization.data.show_footer_text
        if (customization.data.show_footer_image) state[id].show_footer_image = customization.data.show_footer_image
        const acc_show_add_users_button = _.get(customization, 'env_options.visibility.acc_show_add_users_button')
        if (acc_show_add_users_button) _.set(state[id], 'env_options.visibility.acc_show_add_users_button', acc_show_add_users_button)

        if(id === 'acc') {
            const primary_color = _.get(customization, 'data.env_options.colors.primary')
            if (primary_color) _.set(state[id], 'env_options.colors.primary', primary_color)

            const secondary_color = _.get(customization, 'data.env_options.colors.secondary')
            if (secondary_color) _.set(state[id], 'env_options.colors.secondary', secondary_color)

            const graphs = _.get(customization, 'data.env_options.colors.graphs')
            if (graphs) _.set(state[id], 'env_options.colors.graphs', graphs)

            const navigation = _.get(customization, 'data.env_options.navigation')
            if (navigation) _.set(state[id], 'env_options.navigation', navigation)

            const fonts = _.get(customization, 'data.env_options.fonts')
            if (fonts) _.set(state[id], 'env_options.fonts', fonts)

            const languages = _.get(customization, 'data.languages')
            if (languages) {
              state[id] = {
                ...state[id],
                languages
              }
            }
        } else {
          const image_footer = _.get(customization, 'data.image_footer');
          if (image_footer) _.set(state[id], 'image_footer', image_footer);

          const show_footer_image = _.get(customization, 'data.show_footer_image');
          if (show_footer_image) _.set(state[id], 'show_footer_image', show_footer_image);
        }
    },
    setDefaultAccCustomization(state, customization){
        state.defaultAccCustomization = customization
    },
    setUploadedFont(state, font){
      state.uploadedFont = font
    }
};
