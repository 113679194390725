import _ from 'lodash';

export default {
    getFirewall: (state) => () => {
        return state.firewallStrategy !== undefined ? state.firewallStrategy : {};
    },
    getFirewallRules(state) {
        return state.rules;
    },
    getChartData(state) {
        return state.firewallActivity;
    },
    isDebug(state, getters, rootState, rootGetters) {
        return rootGetters['settings/getDebug']
    },
    // getFirewall(state, getters, rootState, rootGetters) {
    //   return getters.getFirewall;
    // },
    getRuleById: (state, getters) => (ruleId) => {
        let rule = state.rules.filter(rule => "" + rule.rule_name === "" + ruleId);
        if (rule && rule.length > 0)
            return _.cloneDeep(rule[0]);
        else
            return null;
    },
};