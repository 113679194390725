<template>
    <b-card no-body>
        <template v-slot:header>
            <b-container fuild style="max-width: 2560px;" >
            <b-row no-gutters align-h="between">
                <b-col md="12" lg="8" xl="9" class="pt-2">
                </b-col>
                <b-col sm=""></b-col>
                <b-col cols="6" md="4" lg="3" xl="2" class="ml-auto">
                    <n-button type="primary"  @click.native="showBP" size="md" round block  :visible_for_permissions="visible_for_permissions">
                        <i><svgicon class="icon" icon="icon-new"/></i>
                       {{ t('New') }}
                    </n-button>
                </b-col>
            </b-row>
            </b-container>
        </template>

        <b-card-body style="padding: 0px;">
            <paginated-table :rows='bplist'
                            :columns='columns'
                            :search="['name']"
                            :actions="actions"
                            :updating="updating"
                            :serverpagination="null"
                            @removebp="removeBP"
                            @editbp="editBP" />
        </b-card-body>


        <b-modal id="modal-new" ref="modal" :title="t('New Blocking Page')" @ok="newBPok" @show="resetBP" :ok-title="t('accept')" :cancel-title="t('cancel')">
            <form class="form-group" @submit.prevent="newBP">
                <b-row>
                    <b-col xl="12" class="mb-2">
                        <fg-input :disabled="disabled" v-model="model.name"
                                    v-validate="{ required: true, regex: /^([a-zA-Z0-9\_\-])+$/, sysname : true, max:64}"
                                    name="Blocking Page Name"
                                    :error="getError('Blocking Page Name')"
                                    class="no-border no-height form-control-lg"
                                    :placeholder="t('Blocking Page Name')"
                                    addon-left-icon="now-ui-icons business_badge"
                                    autocapitalize="none"
                                    style="font-size:1rem;"
                                     />
                    </b-col>
                </b-row>

                <b-row>
                    <b-col xl="12" class="mb-2">
                        <div class="mt-1"><label>{{t('Blocking Page URL: (domain/IP + path)')}}</label></div>
                        <fg-input v-model="model.blocking_page_url"
                                    v-validate="{required: true, max:2048, url: {require_protocol: true }}"
                                    name="Blocking Page URL"
                                    :error="getError('Blocking Page URL')"
                                    class="no-border no-height form-control-lg"
                                    :placeholder="t('Blocking Page URL')"
                                    addon-left-icon="now-ui-icons business_badge"
                                    autocapitalize="none"
                                    style="font-size:1rem;"
                                     />
                    </b-col>
                </b-row>


            </form>
        </b-modal>
    </b-card>
</template>

<script>
import { PaginatedTable } from '@/components';
import { YesNoListFormatter } from '@/components/Tables/Formatters'


export default {
    name: 'blocking-pages-list',
    components: {
        PaginatedTable,
    },
    props: {
        value: {
            type: Array,
            default: () => { return [] }
        },
        service: {
            type: String,
            default: () => { return '' }
        },
        sysnames : {
            type: Object,
            default: () => { return {} }
        },
        visible_for_permissions: {
            type: Array,
            default: function () { return [] }
        }
    },
    data() {
        return {
            model: {
                name: '',
                blocking_page_url: '',
            },
            max_external_pages : 20,
            disabled: false,
            updating: false,
            bplist : [],
            columns: [
                {
                    prop: 'name',
                    label: this.$t("name"),
                    minWidth: 130
                },
                {
                    prop: 'blocking_page_url',
                    label: this.$t('blocking_page_url'),
                    minWidth: 350,
                }
            ],
            actions: {
                minWidth: 100,
                label: this.t('Actions'),
                fixed: 'right',
                items: [
                    {   type: 'warning', icon: 'now-ui-icons business_badge', event: 'editbp', action: this.t('Edit')},
                    {   type: 'danger', size: 'sm', icon: 'now-ui-icons ui-1_simple-remove', event: 'removebp', action: this.t('Remove'), confirm: false }
                ]
            }
        }
    },
    mounted() {
        this.refresh(0)
    },
    methods: {
        getError(fieldName) {
            return this.errors.first(fieldName);
        },
        resetBP() {
            this.model.name = ''
            this.model.blocking_page_url = ''
            this.disabled = false
            this.$validator.reset()
        },
        showBP() {
            if( this.bplist.length >= this.max_external_pages ){
                this.$store.commit("setError", this.t(`Maximum number of external pages has be reached`));
                return;
            }
            this.$refs.modal.show()
        },
        editBP(data){
            this.$refs.modal.show()
            this.disabled = true
            this.model.name = data.row.name
            this.model.blocking_page_url = data.row.blocking_page_url
        },
        newBPok( bvModalEvt ) {
            bvModalEvt.preventDefault()
            this.newBP()
        },
        async newBP() {
            let isValidForm = await this.$validator.validateAll();
            if (!isValidForm) return

            if( this.bplist.find( item => item.name === this.model.name ) && !!!this.disabled ){
                this.$store.commit("setError", this.t(`External page name already exists`));
                return;
            }

            if( this.bplist.length >= this.max_external_pages ){
                this.$store.commit("setError", this.t(`Maximum number of external pages has be reached`));
                return;
            }

            let _vm = this
            let bplisttmp = this.bplist.filter(function (item) {
                return item.name != _vm.model.name;
            });

            let bpitem = {
                name : this.model.name,
                blocking_page_url : this.model.blocking_page_url,
            }

            bplisttmp.push(bpitem)

            this.$store.dispatch('blockingpages/updateBlockingPageList', { "bplist": bplisttmp, "service" : this.service } ).then( res => {
                if(!!res)
                    this.bplist = res
            })
            .catch( err => {
                //this.refresh(0);
             } )

            this.$nextTick(() => {
                this.$refs.modal.hide()
            })
        },
        removeBP(data){
            this.$bvModal.msgBoxConfirm(this.$t('Are you sure you want to delete this url?'), {
                    okTitle: this.$t('accept'),
                    cancelTitle: this.$t('cancel')
                })
            .then(confirmed => {
                if(confirmed) {

                    let bplisttmp = this.bplist.filter(function (item) {
                        return item.name != data.row.name;
                    });

                    this.$store.dispatch('blockingpages/updateBlockingPageList', { "bplist": bplisttmp, "service" : this.service } ).then( res => {
                        if(!!res)
                            this.bplist = res
                    })
                    .catch( err => {
                        //console.log("error")
                    } )
                }
            })
            .catch(() => {
            })
        },
        async refresh(wait){
            this.bplist = this.value
            this.$store.dispatch('blockingpages/getMaxExternalPages').then( res => {
                this.max_external_pages = res
            })
        }
    },
    watch: {
        value(newValue){
            if(newValue)
                this.bplist = newValue
        },
        bplist(newValue, oldValue){
            if( _.isEqual(newValue, oldValue) === false )
                this.$emit('input', newValue);
        }
    },
    created(){
        this.$validator.extend(
            'sysname',{
                getMessage: field =>  field + ' ' + this.$t('is an invalid name') + '.',
                validate: (value) => {
                    return !(Object.values(this.sysnames).includes(value))
                }
        });
    }
}
</script>


