export default {
	addLicense(state, license){
		Object.assign(state.license, license);		
	},
	
	updateLicenseAlert(state, newstate){
		state.licenseAlert = newstate;
	},
	
	clearLicenseInterval(state){		
		if( state.licenseInterval != undefined )
			clearInterval(state.licenseInterval)
		state.licenseInterval = undefined;
	},

	updateLicenseInterval(state, newstate){
		state.licenseInterval = newstate
	}
};