export default {
    getDnscf: (state) => () => {
        return state.dnscfStrategy !== undefined ? state.dnscfStrategy : {};
    },
    getDnscfRules(state) {
        return state.rules;
    },    
    isDebug(state, getters, rootState, rootGetters) {       
        return rootGetters['settings/getDebug']
    },
    getDnscfStrategy(state, getters, rootState, rootGetters) {
        return getters.getDnscf;
    }, 
    getRuleById: (state, getters) => (ruleId) => {
        let rule = state.rules.filter(rule => "" + rule.rule_name === "" + ruleId);
        if (rule && rule.length > 0)
            return _.cloneDeep(rule[0]);
        else
            return null;
    }
};