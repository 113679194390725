export default {
    setBlockingPages(state, blockingpages) {
        Object.assign(state.blockingpages, blockingpages);
    },
    setExtBlockingPages(state, extblockingpages) {
        Object.assign(state.extblockingpages, extblockingpages);
    },
    setMaxExternalPages(state, maxnum){
        Object.assign(state.max_blocking_pages, maxnum);
    }
}