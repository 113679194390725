<template>
  <b-card no-body id="page-title-card">
    <b-container fuild class="p-4" style="max-width: 2560px;" >
      <b-row align-h="between" align-v="center">
        <b-col sm="12" md="8" class="ml-4">
          <b-row>
            <p class="page-title">{{t(pageTitle)}}</p>
          </b-row>
          <b-row v-if="hasDescription">
            <p>{{t(pageDescription)}}</p>
          </b-row>
        </b-col>
        <b-col v-if="hasButtonSlot" sm="12" md="2" class="ml-auto" style="min-width: 160px">
          <slot name="button" />
        </b-col>
      </b-row>
    </b-container>
    <slot name="refresh" />
  </b-card>
</template>

<script>
export default {
  name: "page-title-card",
  props: {
    pageTitle: {
      type: String,
      required: true
    },
    pageDescription: {
      type: String,
      default: ''
    }
  },
  computed: {
    hasButtonSlot() {
      return !!this.$slots.button;
    },
    hasDescription() {
      return this.pageDescription.length;
    },
  }
}
</script>

<style lang="scss" scoped>
  .page-title {
    font-size: 1.6rem;
  }
</style>
