<template>   
    <div class="weekdaysgroupcheck">
        <div style="display: flex">
            <datetime v-model="start_time" type="time" format="HH:mm" :minute-step="5" 
                                                    auto input-class="input-time" class="theme-datetime" /> <!--zone="UTC"-->
            <span class="ml-2 mr-4 my-auto" style="font-size: 2em;">-</span>
            <datetime v-model="end_time" type="time" format="HH:mm" :minute-step="5"  
                                                    auto input-class="input-time" class="theme-datetime" /> <!--zone="UTC"-->
        </div>
        <div style="display: inline-flex">
            <letter-checkbox class="lettercheckbox" v-for="item in days_of_week" :key="item.name" :ref="item.name"
                    :title="t(item.title)" v-model="item.value" @input="update()" :size="size" color='#fa7a50' bgcolor='#d3d3d3'/>
        </div>
    </div>
</template>

<script>
import {
  LetterCheckbox,
} from '@/components';
import { Datetime } from 'vue-datetime';
import 'vue-datetime/dist/vue-datetime.css'
import { DateTime as objDateTime} from 'luxon'


export default {
    components: {
        [LetterCheckbox.name] : LetterCheckbox,
        Datetime,
        objDateTime
    },
    name: 'weekdays-groupcheck',
    model: {
        prop: 'value'
    },
    props: {
        value: { type: Object, 
            default: () => { return { days_of_week: ['monday'], start_time: '00:00', end_time: '00:00', action: 'block'}}
        },
    },
    data() {
        return {
            days_of_week : [],
            start_time: null,
            end_time: null,
            action: 'block'
        }
    },
    watch: {
        start_time: function(newValue, oldValue) {
            let ret = null
            try{
                if ( newValue <= this.end_time)
                    ret = {days_of_week: this.getdaysvalue(this.days_of_week), start_time: this.stringToISOTime(newValue), 
                                        end_time: this.stringToISOTime(this.end_time),  action: 'block'}
                else
                    ret = {days_of_week: this.getdaysvalue(this.days_of_week), end_time: this.stringToISOTime(newValue), 
                                        start_time: this.stringToISOTime(this.end_time),  action: 'block'}
                
                if ( newValue > this.end_time){
                    let tmp = newValue
                    this.start_time = this.end_time
                    this.end_time = newValue
                }
                else if (oldValue)
                    this.$emit('input', ret);
            }
            catch(e){
                console.error(e)
            }
        },
        end_time: function(newValue, oldValue) {
            let ret = null
            try{
                if ( newValue >= this.start_time)
                    ret = {days_of_week: this.getdaysvalue(this.days_of_week), start_time: this.stringToISOTime(this.start_time), 
                                        end_time: this.stringToISOTime(newValue), action: 'block'}
                else
                    ret = {days_of_week: this.getdaysvalue(this.days_of_week), end_time: this.stringToISOTime(this.start_time), 
                                        start_time: this.stringToISOTime(newValue), action: 'block'}

                if ( newValue < this.start_time){
                    let tmp = newValue
                    this.end_time = this.start_time
                    this.start_time = newValue
                }
                else if (oldValue)
                    this.$emit('input', ret);
            }
            catch(e){
                console.error(e)
            }
        },
    },
    computed: {
        size: function(){
            return this.isMobile() ? 32 : 48;
        }
    },
    methods: {
        isMobile() {
            return screen.width < 540;
        },
        isIsoDate(str) {
            if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(str)) return false;
            var d = new Date(str); 
            return d.toISOString()===str;
        },
        datetimeFromISO (string) {
            const datetime = objDateTime.fromISO(string) //.toUTC()
            return datetime.isValid ? datetime : null
        },
        stringToISODate(string){
            return this.datetimeFromISO(string).toISO()
        },
        stringToISOTime(string){
            return this.datetimeFromISO(string).toLocaleString(objDateTime.TIME_24_SIMPLE)
            //return this.datetimeFromISO(string).toUTC(); //.toLocaleString(objDateTime.TIME_24_SIMPLE)
            //return this.datetimeFromISO(string).toLocaleString(objDateTime.TIME_24_SIMPLE)
        },
        getdays(days_data){
            let ret = [];
            let vm = this
            var days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']
            days.forEach( item => {
                let found = false
                if (!!days_data){
                    days_data.forEach( day => {
                        if ( day.toLowerCase() === item)
                            found = true
                    })
                }
                ret.push({ name: item, title: item, value: found})
            })
            return ret
        },
        getdaysvalue(data){
            let checks = []
            var days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']
            days.forEach( day => {
                data.forEach( item => {
                    if (item['name'] == day && !!item['value'])
                        checks.push(day)
                })
            })
            return checks
        },
        update(){
            let ret = { days_of_week: this.getdaysvalue(this.days_of_week), start_time: this.stringToISOTime(this.start_time), 
                            end_time: this.stringToISOTime(this.end_time), action: 'block' }
            console.log('weekdays-groupcheck update: ' + JSON.stringify(ret, null, 2))
            this.$emit('input', ret)
        },
    },
    mounted() {
        try{
            this.days_of_week = this.getdays(this.value.days_of_week)
        }catch(e){}
        try{
            this.start_time = this.value.start_time
        }catch(e){
        }
        try{    
            this.end_time = this.value.end_time
         }catch(e){}
    }
};
</script>
<style lang="scss">
  .weekdaysgroupcheck {
    display: block;
    .lettercheckbox{
      margin: 3px;
    }
    .theme-datetime .vdatetime-popup__header{
      background: #fa7a50;
    }
    .theme-datetime .vdatetime-time-picker__item--selected,
    .theme-datetime .vdatetime-popup__actions__button {
      color: #fa7a50;
    }
  }
</style>
