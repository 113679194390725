import axios from "axios";

export default {
    async get_black_list({ commit, getters, state }, { query }) {
        delete query.field_name
        if (query.field_value || query.field_value === '') {
            query.url_value = query.field_value
            delete query.field_value
        }
        if (query.field_operator) {
            query.url_operator = query.field_operator
            delete query.field_operator
        }
        commit('setBlackList', []);
        try {
            const list = await axios.get(`/isp-internal-categories/${state.currentInternalCategoryId}/excluded`, { params: query })
            const black_list = list.data.items;
            commit({ type: 'set_self_query', self_query: query, action: "block" });
            commit({ type: 'set_query', query: list.data.query, action: "block" });
            commit('setBlackList', black_list);
        }
        catch (error) {
            commit('setErrorAll', error.response.data.detail, { root: true })

        }
    },
    async get_white_list({ commit, getters, state }, { query }) {
        delete query.field_name
        if (query.field_value || query.field_value === '') {
            query.url_value = query.field_value
            delete query.field_value
        }
        if (query.field_operator) {
            query.url_operator = query.field_operator
            delete query.field_operator
        }
        commit('setWhiteList', []);
        try {
            const list = await axios.get(`/isp-internal-categories/${state.currentInternalCategoryId}/included`, { params: query })
            const white_list = list.data.items;
            commit({ type: 'set_self_query', self_query: query, action: "allow" });
            commit({ type: 'set_query', query: list.data.query, action: "allow" });
            commit('setWhiteList', white_list);
            // // ! delete
            // const white_list = debug_allowed_list
            // commit('set_self_query', white_list.self);
            // commit('setWhiteList', white_list.items);
            // // 
        }
        catch (error) {
            commit('setErrorAll', error.response.data.detail, { root: true })

        }
    },
    async update_black_list({ commit, dispatch, state }, { action, item, query }) {
        try {
            await axios.post(`/isp-internal-categories/${state.currentInternalCategoryId}/excluded/modify`, { [action]: [item] })
            await dispatch("get_black_list", { query });
        }
        catch (error) {
            commit('setErrorAll', error.response.data.description, { root: true })
        }
    },
    async update_white_list({ commit, dispatch, state }, { action, item, query }) {
        try {
            await axios.post(`/isp-internal-categories/${state.currentInternalCategoryId}/included/modify`, { [action]: [item] })
            await dispatch("get_white_list", { query });
        }
        catch (error) {
            commit('setErrorAll', error.response.data.description, { root: true })
        }
    },
}