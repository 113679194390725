export default () => { return [
    { autonotice_list_id: 'smartbanner1',
      mode: 'random', 
      items: [
        { SmartBannerType: 'SmartBannerIcon',
          show: 'https://www.iconUrl.com'
        }
      ]
    },
    { autonotice_list_id: 'smartbanner2',
      mode: 'sequential', 
      items: [
        { SmartBannerType: 'SmartBannerHTML',
          show: {
            file_name: 'fileName.html',
            file_content: 'base_64_representation_of_the_banner'
          },
          detail: {position_horizontal: 'center', position_vertical: 'top', width_unit: 'percentage', width: 30, 
                  height_unit: 'percentage', height: 20,	margin: 15, close: false,	scroll: true, display_time: 10, 
                  transparency: 53, animation: false, animation_horizontal: 'left', animation_vertical: 'center', 
                  animation_speed: 'normal'}
        },
        { SmartBannerType: 'SmartBannerImage',
          show: 'https://www.iconUrl.com',
          final_url: 'www.final_URL.com',
          detail: {position_horizontal: 'right', position_vertical: 'bottom', width_unit: 'pixels', width: 20, height_unit: 'pixels', height: 10,	margin: 12, close: true,	scroll: true, display_time: 5, transparency: 14, animation: true, animation_horizontal: 'center', animation_vertical: 'bottom', animation_speed: 'fast'}
        },
        { SmartBannerType: 'SmartBannerImage',
          show: 'https://www.iconUrl.com',
          final_url: 'www.final_URL.com',
          detail: {position_horizontal: 'right', position_vertical: 'bottom', width_unit: 'pixels', width: 20, height_unit: 'pixels', height: 10,	margin: 12, close: true,	scroll: true, display_time: 5, transparency: 14, animation: true, animation_horizontal: 'center', animation_vertical: 'bottom', animation_speed: 'fast'}
        }
      ]
    },
    { autonotice_list_id: 'smartbanner3',
      mode: 'random_no_repeat', 
      items: [
        { SmartBannerType: 'SmartBannerImage',
          show: 'https://www.iconUrl.com',
          final_url: 'www.final_URL.com',
          detail: {position_horizontal: 'right', position_vertical: 'bottom', width_unit: 'pixels', width: 20, height_unit: 'pixels', height: 10,	margin: 12, close: true,	scroll: true, display_time: 5, transparency: 14, animation: true, animation_horizontal: 'center', animation_vertical: 'bottom', animation_speed: 'fast'}
        },
        { SmartBannerType: 'SmartBannerHTML',
          show: {
            file_name: 'fileName.html',
            file_content: 'base_64_representation_of_the_banner'
          },
          detail: {position_horizontal: 'center', position_vertical: 'top', width_unit: 'percentage', width: 30, height_unit: 'percentage', height: 20,	margin: 15, close: false,	scroll: true, display_time: 10, transparency: 53, animation: false, animation_horizontal: 'left', animation_vertical: 'center', animation_speed: 'normal'}
        }
      ]
    }
  ]
}