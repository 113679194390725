/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'item-sso': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path pid="0" d="M9.993 20.803h3.204v6.407h16.015V4.788H13.197v6.408H9.993v-9.61h22.42v28.827H9.993v-9.61zm-9.58-6.405h19.303l-4.06-4.062 2.265-2.264 7.926 7.927-7.926 7.928-2.266-2.266 4.061-4.06H.413v-3.203z" _fill="#000" fill-rule="evenodd"/>'
  }
})
