import _ from 'lodash';

export default {
    isDebug(rootGetters) {
        return rootGetters['settings/getDebug']
    },
    getAdvancedConfiguration(state) {
        return state.advancedConfiguration !== undefined 
            ? { 
                page_mode: state.advancedConfiguration.malware_blocking_page_type,
                custom_blocking_page_url: state.advancedConfiguration.malware_blocking_page_url
            } : {};
    },
    getDefaultBlockingUrl(state) {
        return state.defaultBlockingUrl;
    }
}