/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'data-export': {
    width: 32,
    height: 32,
    viewBox: '0 0 24 24',
    data: '<defs><path pid="0" d="M19 13c-2.17 0-4.07 1.16-5.12 2.89-.6.07-1.23.11-1.88.11-4.42 0-8-1.79-8-4V9c0 2.21 3.58 4 8 4s8-1.79 8-4v3c0 .36-.1.71-.28 1.05C19.5 13 19.24 13 19 13m-7-2c4.42 0 8-1.79 8-4s-3.58-4-8-4-8 1.79-8 4 3.58 4 8 4m1.1 6.96c-.36.04-.73.04-1.1.04-4.42 0-8-1.79-8-4v3c0 2.21 3.58 4 8 4 .46 0 .9 0 1.33-.06A5.94 5.94 0 0113 19c0-.36.04-.7.1-1.04M20 20v-4h-2v4h-2l3 3 3-3h-2z" id="svgicon_data-export_a"/></defs><use _fill="#000" fill-rule="nonzero" xlink:href="#svgicon_data-export_a"/>'
  }
})
