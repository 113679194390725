import DashboardLayout from "@/layout/dashboard/DashboardLayout.vue";

const DNSContentFiltering = () => import(
  "@/pages/DNSSecure/ContentFiltering/ContentFiltering.vue"
);

const DNSContentFilteringEdit = () => import(
  "@/pages/DNSSecure/ContentFiltering/ContentFilteringEdit.vue"
);

const DNSStatus = () => import(
  "@/pages/DNSSecure/General/Status.vue"
);

const DNSCFAdvancedConfiguration = () => import(
  "@/pages/DNSSecure/ContentFiltering/Advanced/Filtering.vue"
);

export default {
  path: '/ds',
  component: DashboardLayout,
  name: 'DNSecure',
  children: [
    

   
        {
          path: 'general/Status',
          name: 'DNSecure / General / Status',
          component: DNSStatus
        },
     
      
        {
          path: 'cf/Policies/Create',
          name: 'DNSecure / Content Filter / Filtering Policies / Create',
          component: DNSContentFilteringEdit
        },
        {
          path: "cf/Policies/Edit/*",
          name: 'DNSecure / Content Filter / Filtering Policies / Edit',
          component: DNSContentFilteringEdit
        },
        {
          path: 'cf/Policies*',
          name: 'DNSecure / Content Filter / Filtering Policies',
          component: DNSContentFiltering
        },
        {
          path: 'cf/AdvancedConfiguration',
          name: 'DNSecure / Content Filter / Advanced Configuration',
          component: DNSCFAdvancedConfiguration
        }
      ]
    
};