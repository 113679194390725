<template>
    <b-card class="mb-2">
        <b-col md="12" class="row-eq-height">
            <el-select class="select-primary d-flex" style="max-width: 175px;" size="large" placeholder="Search by" v-model="service">
                <el-option class="select-primary" :value="false" :label="t('All services')" />
                <el-option v-for="option in services" class="select-primary text-capitalize" :value="option" :label="t(beautify_string(option))" :key="option" />
            </el-select>
            <el-select v-if="global" class="select-primary d-flex ml-4" style="max-width: 175px;" size="large" placeholder="Search by" v-model="product">
                <el-option class="select-primary" :value="false" :label="t('All products')" />
                <el-option v-for="option in products" class="select-primary text-capitalize" :value="option" :label="t(beautify_string(option))" :key="option" />
            </el-select>
    		<div v-if="filter_by_service || filter_by_product" @click="refresh()" class="d-flex" style="cursor: pointer; align-self: center;">
				<i class="now-ui-icons arrows-1_refresh-69 ml-4" />
			</div>
        </b-col>
    </b-card>
</template>

<script>
import { mapState, mapGetters, mapMutations }   from 'vuex';
import GeneralMixin from './general/GeneralMixin'

export default 
{
    mixins: [GeneralMixin],
    props: {
        services: {
            type: Array,
            default: []
        },
		global: {
			type: Boolean,
			default: false
		}
    },
    computed: {
        ...mapState('settings',['checkedProducts']),
        ...mapGetters('ispServices/services', ['filter_by_service', 'filter_by_product']),

        service: {
            get() {
                return this.filter_by_service;
            },
            set(value) {
                this.setFilterByService(value);
                this.setFilterByProduct(false);
            }
        },
        product: {
            get() {
                return this.filter_by_product;
            },
            set(value) {
                this.setFilterByService(false);
                this.setFilterByProduct(value);
            }
        },
        products() {
            return Object.keys(this.checkedProducts);
        }
    },
    methods: {
        ...mapMutations('ispServices/services', ['setFilterByService', 'setFilterByProduct']),

        refresh() {
            this.setFilterByService(false);
            this.setFilterByProduct(false);
        }
    },
    beforeDestroy() {
        this.refresh();
    },
}
</script>