import axios from "axios";
import dummyRouterJobs from "../../../util/dummy/routerJobs.json";
import dummyAccountJobs from "../../../util/dummy/accountJobs.json";

export default {
    getRouterJobs({commit, rootState}, { wait: wait, query: query }) {
        if (rootState.settings.debug) { 
            return new Promise((resolve, reject) => {
                let ret = { items: dummyRouterJobs, query: query, self: query}
                resolve(ret)
            }) 
        } else {
            let config = { params: query }
            if (wait)
                config.headers = { 'x-wait-on-loading': wait }

            return new Promise((resolve, reject) => {
                axios.get('/subsystems/rs/routers/jobs', config)
                    .then(res => {
                        if (res && res.data) { 
                            let ret = { items: res.data.items, query: res.data.query, self: query }
                            resolve(ret)
                        } else {
                            reject()
                        }
                    }).catch(error => {
                        commit('setError', error, {root: true});
                        reject()
                    })
    
            });
        }
    },
    getAccountJobs({commit, rootState}, { wait: wait, query: query }) {
        if (rootState.settings.debug) { 
            return new Promise((resolve, reject) => {
                let ret = { items: dummyAccountJobs, query: query, self: query}
                resolve(ret)
            }) 
        } else {
            let config = { params: query }
            if (wait)
                config.headers = { 'x-wait-on-loading': wait }

            return new Promise((resolve, reject) => {
                axios.get('/jobs/accounts', config)
                    .then(res => {
                        if (res && res.data) { 
                            let ret = { items: res.data.items, query: res.data.query, self: query }
                            resolve(ret)
                        } else {
                            reject()
                        }
                    }).catch(error => {
                        commit('setError', error, {root: true});
                        reject()
                    })
    
            });
        }
    },
}