import axios from 'axios';
import i18n from "../../../i18n";

export default {
    // * --------------------- isp check url  ---------------------
    askForRevision({ commit }, value) {
        return new Promise((resolve, reject) => {
            axios.post('/isp-url-contribution', value).then(() => {
                commit('setSuccess', `${i18n.t('url_sent')}`, { root: true });
                resolve();
            }).catch(error => {
                let firstErr = error
                try{
                    commit('setError', i18n.t(error.response.data.type), {root:true});
                }
                catch( err){
                    commit('setError', firstErr, {root:true})
                }
                reject()
            })
        })
    },
    urlInspection({ commit }, url) {
        return axios.post('/isp-url-inspection', { url }).then(response => {
            const internal_categories = response.data.isp_internal_categories.map(category => {
                                    return { value: category["category name"], type: category.category_type } });
            const global_categories = response.data.isp_global_lists.map(category => {
                                        return { value: category.service_id, type: category.list_type } });
            commit('inspectedUrl', response.data.url);
            commit('inspectedInternalCategories', internal_categories);
            commit('inspectedGlobalCategories', global_categories);
        }).catch((err) => {
            commit('inspectedInternalCategories', []);
            commit('inspectedGlobalCategories', []);
            try{
                commit('setError', i18n.t(err.response.data.type), {root:true});
            }
            catch(error){
                commit('setError', i18n.t('NSADAPTOR.CATEGORIZERS_NOT_READY'), {root:true});
            }
            throw err
        });
    },

    async remove_from_global_list({ commit }, { list_type, item, service }) {
        try {
            let path = null
            switch(list_type){
                case 'allowed':
                    path = 'isp-allowed-lists'
                    break;
                case 'forbidden':
                    path = 'isp-forbidden-lists'
                    break;
                default:
                    commit('setErrorAll', i18n.t('Unknown global list type'), { root: true })
                    return
            }
            await axios.post(`/${path}/${service}/modify`, { ['del']: [item] })
            commit('removeInspectedGlobalCategories', { service: service, type: list_type})
        }
        catch (error) {
            commit('setErrorAll', error.response.data.detail, { root: true })
        }
    },
    async remove_from_internal_category({ commit, state}, { category, type, item }) {
        try {
            const isInternal = state.categories.some( cat => (cat.category_type == 'internal' && cat.isp_internal_category_id == category))
            const isCustom = state.categories.some( cat => (cat.category_type == 'custom' && cat.isp_internal_category_id == category))
            if ( isCustom || type == "custom"){
                await axios.post(`/isp-internal-categories/${category}/included/modify`, { ['del']: [item] })
            }
            else if (isInternal){
                await axios.post(`/isp-internal-categories/${category}/excluded/modify`, { ['add']: [item] })
            }
            else{
                commit('setErrorAll', i18n.t('Unknown category type'), { root: true })
                return;
            }
            commit('removeInspectedInternalCategories', { removedCategories: [category]})
        }
        catch (error) {
            commit('setErrorAll', error.response.data.detail, { root: true })
        }
    },

    // * --------------------- isp categories  ---------------------

    async getIspCategories({ commit }) {
        commit('updating', true);
        const categories = await axios.get('/isp-categories').catch(error => {
            error = error.response.data;
            return error.detail ? error.detail : error.description;
        });

        if ([200, 204].includes(categories.status) || categories.statusText == "OK") {
            commit('setIspCategories', categories.data.items);
        }
        else {
            commit('setError', categories, { root: true });
        }

        commit('updating', false);
    },

    async setStatus({ commit }, value) {
        value.sm_categories && value.sm_categories.length === 0 && delete value.sm_categories;
        const { id } = value;
        const response = await axios.patch(`/isp-categories/${id}`, value).catch(error => {
            error = error.response.data;
            //return error.detail ? error.detail : error.description;
            return error.type;
        });

        if ([200, 204].includes(response.status) || response.statusText == "OK") {
            commit('setStatus', id);
            commit('setSuccess', `${id} ${i18n.t('success_update')}`, { root: true });
            commit('settings/setIsFormDirty', false, { root: true });
        }
        else {
            commit('setError', i18n.t(response), { root: true });
        }
    },
    async deleteIspCategory({ commit }, id) {
        const response = await axios.delete(`/isp-categories/${id}`).catch(error => {
            error = error.response.data;
            return error.detail ? error.detail : error.description;
        });

        if ([200, 204].includes(response.status) || response.statusText == "OK") {
            commit('deleteIspCategory', id);
            commit('setSuccess', `${id} ${i18n.t('success_remove')}`, { root: true });
        }
        else {
            commit('setError', response, { root: true })
        }
    },

    createIspCategory({ commit }, { id, language }) {
        const new_category = {
            "isp_category_id": id,
            "isp_category_i18n": [language],
            "enabled": false
        }
        axios.post('/isp-categories', new_category).then(() => {
            commit('setSuccess', `${i18n.t('new_isp_category')} ${id} ${i18n.t('success_create')}`, { root: true });
            commit('createIspCategory', new_category);
        }).catch(error => {
            commit('setError', error, { root: true });
        });
    },
    updateIspCategory({ commit }, category) {
        category.isp_category_i18n.forEach(language => {
            if (language.new) {
                delete language.new;
                delete language.language_name;
            }
        })
        if (category.sm_categories && !category.sm_categories.length) delete category.sm_categories
        axios.put(`/isp-categories/${category.isp_category_id}`, category).then(() => {
            commit('setSuccess', `${category.isp_category_id} ${i18n.t('success_update')}`, { root: true });
            if (!category.sm_categories) category.sm_categories = []
            commit('updateIspCategory', category);
            commit('settings/setIsFormDirty', false, { root: true });
        }).catch(error => {
            commit('setError', error, { root: true });
        });
    },

    // * --------------------- isp internal categories ---------------------

    async getCategories({ commit }) {
        commit('updating', true);
        // // ! debug delete
        // commit('setCategories', debug_internal_categories.items);
        // //
        const categories = await axios.get('/isp-internal-categories').catch(error => {
            error = error.response.data;
            return error.detail ? error.detail : error.description;
        });

        if ([200, 204].includes(categories.status) || categories.statusText == "OK") {
            commit('setCategories', categories.data.items);
        }
        else {
            commit('setError', categories, { root: true });
        }

        commit('updating', false);
    },
    createCustomCategory({ commit }, category) {
        const new_category = {
            "isp_internal_category_id": category,
            "category_type": "custom"
        }

        axios.post('/isp-internal-categories', new_category).then(() => {
            commit('setSuccess', `${i18n.t('new_custom_category')} ${category} ${i18n.t('success_create')}`, { root: true });
            commit('createCategory', new_category);
        }).catch(error => {
            if (error.response && error.response.data) {
                if (error.response.data.type && error.response.data.type === 'CATEGORY.LIMIT_EXCEEDED') {
                    commit('setError', i18n.t('MAX_ISP_CATEGORIES_ALLOWED'), {root: true});
                }
                else {
                    commit('setError', error, { root: true });
                }
            }
        });
    },
    async deleteInternalCategory({ commit }, id) {
        const response = await axios.delete(`/isp-internal-categories/${id}`).catch(error => {
            error = error.response.data;
            return error.detail ? error.detail : error.description;
        });

        if ([200, 204].includes(response.status) || response.statusText == "OK") {
            commit('setSuccess', `${id} ${i18n.t('success_remove')}`, { root: true });
            commit('deleteInternalCategory', id);
        }
        else {
            commit('setError', response, { root: true })
        }
    },
    setInspectedUrl({ commit }, value) {
        commit('inspectedUrl', value);    
    }


}
