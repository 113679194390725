
<template>
  <div class="tags-container">
    <template v-if="Array.isArray(row[column.prop])">
      <template v-if="row[column.prop].length === 0">
        <div>-</div>
      </template>
      <template v-for="(tag, index) in row[column.prop]">
        <div :key="tag.hasOwnProperty('value') ? tag.value : tag"
          v-if="index < 3"
        >
          <div style="white-space: nowrap;">
            <i class="now-ui-icons arrows-1_minimal-right icon icon-primary"/>
            {{ tag.hasOwnProperty('value') ? tag.value : tag }}
          </div>
        </div>
      </template>
      <template v-if="row[column.prop].length > 3">
        <el-tooltip
          class="item" effect="dark" placement="top"
          :content="row[column.prop].map(tag => tag.hasOwnProperty('value') ? tag.value : tag).join(', ')"
        >
          <el-button class="more-tags">...</el-button>
        </el-tooltip>
      </template>
    </template>
    <el-tag class="default-tag" v-else>
      {{row[column.prop]}}
    </el-tag>
  </div>
</template>

<script>
  export default {
    name: 'up-to-three-list-formatter',
    props: {
      row: {
        type: Object,
        default: () => {}
      },
      column: {
        type: Object,
        default: () => {}
      }
    }
  }
</script>

<style scoped lang=scss>
  .more-tags {
    padding: 0.2em 0.5em;
  }
  .tags-container {
    text-align: center;
  }
  .default-tag {
    background-color: #cfcfcf;
    border-color: #a7a7a8;
    color: #444444;
    white-space: normal;
    overflow-wrap: anywhere;
    height: auto;
  }
</style>