<template>
  <div class="autocomplete-tag-input">
    <div class="input-border-autocomplete">
      <el-tag
        :class='getTagClass(tag)'
        :key="tag.value ? tag.value : tag"
        v-for="tag in value"
        :closable="!disabled"
        :disable-transitions="false"
        @close="handleClose(tag)">
        {{isWithLabel ? tag.label : tag.value ? tag.value : t(tag)}}
      </el-tag>

      <el-autocomplete v-if="showdropdown"
        :disabled="disabled"
        :debounce="500"
        class="autocomplete-input inline-input no-border-autocomplete"
        :placeholder="placeholder"
        v-model='inputValue'
        :fetch-suggestions="querySearch"
        ref="saveTagInput"
        popper-class="autocomplete-dropdown"
        :highlight-first-item="true"
        @select="handleSelect"
        @blur="handleBlur"
        @keyup.enter.native="validateInput"
        @keydown.tab.native="validateInput"
      >
        <template slot-scope="{item}">
          <div v-if="isWithLabel" :class="`${item.type}-tag`">{{item.label}}</div>
          <div v-else :class="`${item.type}-tag`">{{item.value}}</div>
        </template>
        <el-button slot="append"
          class="check-button"
          icon="el-icon-check"
          tabindex="-1"
          @click="validateInput">
        </el-button>
      </el-autocomplete>

      <div class="item-alignment" v-else>
        <el-input
          :disabled="disabled"
          class="autocomplete-input inline-input"
          :placeholder="placeholder"
          v-model='inputValue'
          ref="saveTagInput"
          @select="handleSelect"
          @blur="handleBlur"
          @keyup.enter.native="validateInput"
          @keydown.tab.native="validateInput"
        >
          <template slot-scope="{item}">
            <div v-if="isWithLabel" :class="`${item.type}-tag`">{{item.label}}</div>
            <div v-else :class="`${item.type}-tag`">{{item.value}}</div>
          </template>
          <el-button slot="append"
            class="check-button"
            icon="el-icon-check"
            tabindex="-1"
            @click="validateInput">
          </el-button>
        </el-input>
      </div>
    </div>
    <p v-if='errorMessage' class="errorMessage">{{t(errorMessage)}}</p>
  </div>
</template>

<script>
  import { Tag, Button, Autocomplete } from 'element-ui';
  import ipRegex from 'ip-regex';
  import {
    ipv4RegExp,
    ipv6RegExp,
    checkDomain,
    validateIpv4WithHost,
    validateIpv6WithHost,
    checkDomainOnly,
    checkCertificateName,
    checkRouterFirmware,
    validateMacAddressRegex,
    ipv4v6RegExp
  } from "../../util/regexConsts";
  import permissions from '@/mixins/permissions'
  export default {
    name: 'autocomplete-tag-input',
    mixins: [permissions],
    components: {
      'el-tag': Tag,
      'el-button': Button,
      'el-autocomplete': Autocomplete
    },
    data() {
      return {
        inputValue: '',
        errorMessage: undefined,
        MACRegExp: new RegExp(/^[a-fA-F0-9:]{17}|[a-fA-F0-9]{12}$/),
        emailRegExp: new RegExp(/^\w+[\w\.\-]*@\w+([\.-]?\w+)*(\.[\w\-]{2,8})+$/),
        mailDomainRegExp: new RegExp(/^(((ht|f)tps?:\/\/)?[a-zA-Z]+((\.[a-zA-Z])?[0-9\-]*[a-zA-Z0-9]+)*(:\d{1,5})?[^\s\b\t\?\=\&]*(#\w+)?(\?([^\s\b\t\?\=\&#]+\=[^\s\b\t\?\=\&#]*\&?)*)?)$/),
        tcpUpdRegExp: new RegExp('^(((tcp)|(udp)|(tcp\/udp)):)( )?([1-9]|[1-5]?[0-9]{2,4}|6[0-4][0-9]{3}|65[0-4][0-9]{2}|655[0-2][0-9]|6553[0-5])(-([1-9]|[1-5]?[0-9]{2,4}|6[1-4][0-9]{3}|65[1-4][0-9]{2}|655[1-2][0-9]|6553[1-5]))?$')
      };
    },
    props: {
      inputType: {
        type: String,
        default: 'Add tag'
      },
      value: {
        type: Array,
        default: () => []
      },
      options: {
        type: Array,
        default: () => []
      },
      disabled: {
        type: Boolean,
        default: false
      },
      protocol: {
        type: String,
        default: ''
      },
      checks: {
        type: Array,
        default: () => ['ip', 'mac']
      },
      placeholder: {
        type: String,
        default: 'Add tag'
      },
      showdropdown: {
        type: Boolean,
        default: true
      },
      isWithLabel: {
        type: Boolean,
        default: false
      },
      tagClass: {
        type: String,
        default: ''
      },
      localClose:{
        type: Boolean,
        default: false
      }
    },
    methods: {
      handleClose(tag) {                
        if( !this.localClose ){
          this.errorMessage = undefined;
          let newValues = [...this.value];
          newValues = newValues.filter(value =>
            (value.value ? value.value : value)
            !== (tag.value ? tag.value : tag)
          );
          this.$emit('change', newValues);
        } else
          this.$emit('close', tag);        
      },
      defaultEnterHandler() {
        if (this.inputValue !== '')
          this.errorMessage = 'please, search an option by typing and select it from the list';
      },
      checkIP(ip) {
        let result = false;
        if (!(ipv4RegExp.test(ip) || ipv6RegExp.test(ip) || ipv4v6RegExp.test(ip) )) return false;
        else {
          if (ipRegex.v4().test(ip)) return this.checkV4Mask(ip);
          if (ipRegex.v6().test(ip)) return this.checkV6Mask(ip);
        }
      },
      checkV4Mask(ip) {
        let splittedIp = ip.split('/');
        if (splittedIp.length === 1) return true;
        let binary = '';
        const mask = +splittedIp[1];
        splittedIp[0].split('.').forEach(number => binary += (+number).toString(2).padStart(8, '0'));
        if (binary.slice(mask, 32) === ''.padStart((32 - mask), '0')) return true;
        return false;
      },
      checkV6Mask(ip) {
        let splittedIp = ip.split('/');
        if (splittedIp.length === 1) return true;
        let binary = '';
        const mask = +splittedIp[1];
        splittedIp = splittedIp[0].split(':');
        for (let i = 0; i <= 7; i++) {
          const hex = splittedIp[i] || '0';
          binary += (parseInt(hex, 16)).toString(2).padStart(16, '0');
        }
        if (binary.slice(mask, 128) === ''.padStart((128 - mask), '0')) return true;
        return false;
      },
      checkPortsOrder(protocol) {
        if (protocol.includes(': ') || protocol.includes(':')) {
          let splitted = protocol.split(': ')[1] == undefined ? protocol.split(':') : protocol.split(': ');
          let ports;
          if (splitted[0].trim().includes('-') || splitted[1] != undefined && splitted[1].trim().includes('-')) {
            ports = splitted[1] == undefined ? splitted[0].split(':')[1].split('-') : splitted[1].split('-');
            return parseInt(ports[0]) <= parseInt(ports[1]);
          } else {
            return true;
          }
        }
        return true;
      },
      handleSelect(data) {
        this.errorMessage = undefined;
        const duplicated = this.value.filter(item =>
          (item && item.value ? item.value : item) === data.value
        ).length === 0;
        if (data && duplicated) {
          if (this.inputType.toLowerCase() !== 'source' && this.inputType.toLowerCase() !== 'destination' && this.inputType.toLowerCase() !== 'advanced')
            data = data.value;
          this.$emit('change', [...this.value, data]);
          this.inputValue = '';
        }
      },
      handleDomainInput() {
        this.errorMessage = undefined;
        let value = this.value;
        let inputValue = this.inputValue.toLowerCase();
        if ((checkDomain(inputValue) || validateIpv6WithHost(inputValue) || validateIpv4WithHost(inputValue)) && this.value.indexOf(inputValue) < 0) {
          this.$emit('change', [...value, inputValue]);
          this.inputValue = '';
        }
        else if (inputValue.length > 0) this.errorMessage = "please, introduce a valid domain and don't repeat entries.";
      },
      handleDomainOnlyInput() {
        this.errorMessage = undefined;
        let value = this.value;
        let inputValue = this.inputValue.toLowerCase();
        if (checkDomainOnly(inputValue) && this.value.indexOf(inputValue) < 0) {
          this.$emit('change', [...value, inputValue]);
          this.inputValue = '';
        }
        else if (inputValue.length > 0) this.errorMessage = "please, introduce a valid domain and don't repeat entries.";
      },
      handleCertificateNameInput() {
        this.errorMessage = undefined;
        let value = this.value;
        let inputValue = this.inputValue.toLowerCase();
        if (checkCertificateName(inputValue) && this.value.indexOf(inputValue) < 0) {
          this.$emit('change', [...value, inputValue]);
          this.inputValue = '';
        }
        else{
          if (inputValue.length > 0) 
            this.errorMessage = "please, introduce a valid hostname and don't repeat entries.";
        }
      },
      handleRouterFirmware() {
        this.errorMessage = undefined;
        
        let value = this.value;
        let inputValue = this.inputValue;

        if (inputValue.length > 0 && checkRouterFirmware(inputValue) && this.value.indexOf(inputValue) < 0) {
          this.$emit('change', [...value, inputValue]);
          this.inputValue = '';
        }
        else if (inputValue.length > 0) this.errorMessage = "please, introduce a valid router firmware and don't repeat entries.";
      },
      handleMACInput() {
        this.errorMessage = undefined;
        let value = this.value;
        let inputValue = this.inputValue.toUpperCase().trim();
        if (validateMacAddressRegex(inputValue) && this.value.indexOf(inputValue) < 0) {
          this.$emit('change', [...value, inputValue]);
          this.inputValue = '';
        }
        else if (inputValue.length > 0) this.errorMessage = "please, introduce a valid mac address and don't repeat entries";
      },
      handleAttributeInput() {
        this.errorMessage = undefined;
        let value = this.value;
        let inputValue = this.inputValue.trim();
        let indexOf = this.value.findIndex(item => item.toLowerCase() === inputValue.toLowerCase())

        if (inputValue.length<=256 && inputValue.length>0 && indexOf<0 ) {
          this.$emit('change', [...value, inputValue]);
          this.inputValue = '';
        }
        else if (inputValue.length > 0) this.errorMessage = "please, introduce a valid value and don't repeat entries";
      },
      handleUrlsInput() {
        this.errorMessage = null;
        let newValue;
        const isNewValue =
          this.value.every((value) => {
            return value.type
              ? value.value !== this.inputValue
              : value !== this.inputValue;
          });

        if (isNewValue) {
          let acceptedInput = true;
          if (this.inputType === "Urls") {
            const checkIfExluded = this.inputValue.slice(0, 4).toLowerCase();
            let type, value;
            if (checkIfExluded === "not:"){
              const checkIfExludedRegex = this.inputValue.slice(4, 8).toLowerCase();
              if (checkIfExludedRegex === "reg:"){
                type = "excluded_regex";
                value = this.inputValue.substring(8);
              } else {
                if (checkDomain(this.inputValue.substring(4))) {
                  type = "excluded";
                  value = this.inputValue.substring(4);
                } else acceptedInput = false;
              }
            }
            else if (checkIfExluded === "reg:") {
              type = "regex";
              value = this.inputValue.substring(4);
            }
            else {
              if (checkDomain(this.inputValue) || validateIpv6WithHost(this.inputValue) || validateIpv4WithHost(this.inputValue)) {
                type = "included";
                value = this.inputValue;
              } else acceptedInput = false;
            }
            newValue = { type, value };
          } else newValue = this.inputValue;

          if (acceptedInput) {
            this.$emit("change", [...this.value, newValue]);
            this.inputValue = "";
          } else if (this.inputValue.length > 0) {
            this.errorMessage = this.t('please_valid_domain_or_regex');
          }
        } else if (this.inputValue.length > 0)
          this.errorMessage = this.t('please_valid_domain_or_regex');
      },
      handleAdvancedInput() {
        this.errorMessage = null;
        if (this.inputValue.length > 0) {
          this.errorMessage = this.t('please_valid_advanced_option');
        }
      },
      handleIPProtocolInput() {
        this.errorMessage = undefined;
        let errorMessage = this.t('please_valid_protocol');
        let value = this.value;
        let inputValue = this.inputValue.toLowerCase();
        if (inputValue !== '' && !isNaN(inputValue) && +inputValue >= 0 && +inputValue <= 255 && this.value.indexOf(inputValue) < 0) {
          inputValue = this.t('otherIpProtocol') + ':' + inputValue;
          this.$emit('change', [...value, inputValue]);
          this.inputValue = '';
        }
        else if (this.tcpUpdRegExp.test(inputValue)) {
          if (this.checkPortsOrder(inputValue)) {
            if (
              (this.value.indexOf(inputValue) < 0)
              && (this.value.indexOf(inputValue.replace(' ', '')) < 0)
              && (this.value.indexOf(inputValue.replace(':', ': ')) < 0)
            ) {
              this.$emit('change', [...value, inputValue]);
              this.inputValue = '';
            }
            else this.errorMessage = errorMessage;
          } else {
            this.errorMessage = this.t('invalidRange');
          }
        } else if (inputValue.length > 0) this.errorMessage = errorMessage;
      },
      querySearch(queryString, cb) {
        if (['source', 'destination'].includes(this.inputType.toLowerCase()) &&
          !!!( this.checks.length == 1 && (this.checks.includes("ip") || this.checks.includes("mac") ) )  &&
          !!!( this.checks.length == 2 && this.checks.includes("ip") && this.checks.includes("mac") )
          )
          this.$emit('search-suggestions', { queryString, done: () => {
            this.fillOptions(queryString, cb);
          }});
        else this.fillOptions(queryString, cb);
      },
      fillOptions(queryString, cb) {
        setTimeout(() => {
          let options = this.options;
          const results = queryString ? options.filter(this.createFilter(queryString)) : options;
          if (results.length) this.errorMessage = undefined;
          // call callback function to return suggestions
          cb(results);
        }, 100);
      },
      createFilter(queryString) {
        return (item) => {
          return (item.value.toLowerCase().includes(queryString.toLowerCase()));
        };
      },
      getTagClass(tag) {
        if(this.tagClass != "") return this.tagClass
        
        let classTags = [ 'account', 'device', 'domain', 'email', 'mac', 'service', 'autonotice_package' ];
        if (tag.hasOwnProperty('option')) return 'default-tag';
        if (classTags.includes(tag.type)) return `${tag.type}-tag`;
        if (tag.type === 'ip') {
          if (ipRegex.v4().test(tag.value)) return 'ip4-tag';
          if (ipRegex.v6().test(tag.value)) return 'ip6-tag';
        }
        if (tag.type === "included") return "included-tag";
        if (tag.type === "excluded") return "excluded-tag";
        if (tag.type === "regex") return "regex-tag";
        if (tag.type === "excluded_regex") return "excluded_regex-tag";
        if (tag.includes('.')) {
          return tag.includes('not:')
            ? 'deny-url'
            : 'allow-url';
        }
        return 'default-tag';
      },
      handleBlur() {
        this.validateInput();
      },
      handleSrcDstInput(inputType) {
        this.errorMessage = undefined;
        let value = this.value;
        let inputValue = this.inputValue.toLowerCase();
        const isDuplicate = value.find(item => item.value === inputValue.toString())
        if(isDuplicate){
          this.errorMessage = inputType == 'source' ? this.t("duplicated_source") : this.t("duplicated_destination");
          return
        }
        // It could be an IP or a MAC
        const ip = this.checks.includes('ip') && this.checkIP(inputValue);
        const mac = this.checks.includes('mac') && this.MACRegExp.test(inputValue);
        const email = this.checks.includes('mail') && this.emailRegExp.test(inputValue);
        const domain = this.checks.includes('mail-domain') && this.mailDomainRegExp.test(inputValue);
        const type = this.getInputType(ip, mac, email, domain);
        if ( this.value.indexOf(inputValue) < 0 && (ip || mac || (this.protocol.toLowerCase() === 'mail' && (email || domain)))) {
          this.$emit('change', [...value, {
            type: type,
            value: inputValue
          }]);
          this.inputValue = '';
        }
        else if (inputValue.length > 0) {
          this.errorMessage = this.t('please_valid')
           + this.checks.slice(0, -1).join(', ')
           + ' or ' + this.checks.slice(-1);
        }
      },
      getInputType(ip, mac, email, domain) {
        return ip ? 'ip' : mac ? 'mac' : email ? 'email' : domain ? 'domain' : undefined;
      },
      validateInput(event) {
        let notDefault = [
          'source', 'destination', 'ip protocol', 'certificatename',
          'hosts', 'url', 'urls', 'advanced', 'domains', 'onlydomain', 'routerfirmware', 'mac', 'attribute'
        ]
        if (this.inputType.toLowerCase() === 'source' || this.inputType.toLowerCase() === 'destination')
          this.handleSrcDstInput(this.inputType.toLowerCase())
        if (this.inputType.toLowerCase() === 'ip protocol')
          this.handleIPProtocolInput()
        if (this.inputType.toLowerCase() === 'url' || this.inputType.toLowerCase() === 'domains')
          this.handleDomainInput()
        if (this.inputType.toLowerCase() === 'onlydomain' || this.inputType.toLowerCase() === 'hosts')
          this.handleDomainOnlyInput()
        if (this.inputType.toLowerCase() === 'certificatename')
          this.handleCertificateNameInput()
        if (this.inputType.toLowerCase() === 'urls')
          this.handleUrlsInput()
        if (this.inputType.toLowerCase() === 'advanced')
          this.handleAdvancedInput()
        if (this.inputType.toLowerCase() === 'routerfirmware')
          this.handleRouterFirmware()
        if (this.inputType.toLowerCase() === 'mac')
          this.handleMACInput()
        if (this.inputType.toLowerCase() === 'attribute')
          this.handleAttributeInput()
        if (!notDefault.includes(this.inputType.toLowerCase()) )
          this.defaultEnterHandler()
      }
    },
    watch: {
      disabled: function(newDisabled, oldDisabled) {
        if (this.inputType.toLowerCase() === 'hosts' && newDisabled) this.$emit('change', []);
      }
    },
    created() {
      if (!this.permission_granted) {
        this.disabled = true;
      }
    }
  }
</script>

<style lang="scss">
  .input-border-autocomplete { border: 1px solid #DCDFE6; border-radius: 3px; padding-top: 5px;}
  .input-border-autocomplete:hover { border: 1px solid #C0C4CC; border-radius: 3px; }
  .input-border-autocomplete:focus-within { border: 1px solid #409EFF; border-radius: 3px; }
  .no-border-autocomplete {
    width: 100%;
    div { width: 100%; input { border: none; width: 100%; } }
  }
  .autocomplete-tag-input,
  .autocomplete-dropdown {
    .mac-tag, .allow-url {
      color: #51a427 !important;
      background-color: #dceed2 !important;
      border: 1px solid #a1c191 !important;
      i { color: black !important; }
      i:hover { background-color: #f96332 !important; }
    }
    .device-tag, .account-tag {
      color: #2c6fb4 !important;
      background-color: #cee5ff !important;
      border: 1px solid #85b6e7 !important;
      i { color: black !important; }
      i:hover { background-color: #f96332 !important; }
    }
    .ip4-tag, .deny-url {
      color: #c15050 !important;
      background-color: #f0d9d9 !important;
      border: 1px solid #d38181 !important;
      i { color: black !important; }
      i:hover { background-color: #f96332 !important; }
    }
    .ip6-tag {
      color: #c7892c !important;
      background-color: #fae6c8 !important;
      border: 1px solid #d1b080 !important;
      i { color: black !important; }
      i:hover { background-color: #f96332 !important; }
    }
    .service-tag {
      color: #5b136d !important;
      background-color: #d3aedb !important;
      border: 1px solid #7e4a8b !important;
      i { color: black !important; }
      i:hover { background-color: #f96332 !important; }
    }
    .autonotice_package-tag {
      color: #5b136d !important;
      background-color: #d3aedb !important;
      border: 1px solid #7e4a8b !important;
      i { color: black !important; }
      i:hover { background-color: #f96332 !important; }
    }
    .domain-tag {
      color: #7c2e00 !important;
      background-color: #9c7d6b !important;
      border: 1px solid #a76641 !important;
      i { color: black !important; }
      i:hover { background-color: #f96332 !important; }
    }
    .email-tag {
      color: #0cacdd !important;
      background-color: #c3e1eb !important;
      border: 1px solid #428ea5 !important;
      i { color: black !important; }
      i:hover { background-color: #f96332 !important; }
    }
    .included-tag{
      color: #51a427 !important;
      background-color: #dceed2 !important;
      border: 1px solid #a1c191 !important;
      i { color: black !important; }
      i:hover { background-color: #f96332 !important; }
    }
    .excluded-tag{
      color: #c15050 !important;
      background-color: #f0d9d9 !important;
      border: 1px solid #d38181 !important;
      i { color: black !important; }
      i:hover { background-color: #f96332 !important; }
    }
    .excluded_regex-tag{
      color: #8f2ca8 !important;
      background-color: #d3aedb !important;
      border: 1px solid #7e4a8b !important;
      i { color: black !important; }
      i:hover { background-color: #f96332 !important; }
    }
    .regex-tag{
      color: #2c6fb4 !important;
      background-color: #cee5ff !important;
      border: 1px solid #85b6e7 !important;
      i { color: black !important; }
      i:hover { background-color: #f96332 !important; }
    }
    .default-tag {
      color: #444444 !important;
      background-color: #cfcfcf !important;
      border: 1px solid #a7a7a8 !important;
      i { color: black !important; }
      i:hover { background-color: #f96332 !important; }
    }
  }
  .autocomplete-input {
    .el-input {
      .el-input-group__append {
          width: 3em !important;
      }
    }
    .el-input__inner{
      border-color: transparent!important;
      color: #222a42;
    }
  }
  .autocomplete-dropdown {
    div.el-scrollbar {
      div.el-scrollbar__bar {
        opacity: 1;
      }
    }
    li {
      div {
        padding-left: 0.5em;
      }
    }
    li.highlighted {
      background-color: #d9d9d9;

      div {
        padding-left: 1em;
        font-weight: bold;
      }
    }
  }
</style>
