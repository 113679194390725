export const mockAccount = {
    "account_id": "allot_secure_demo",
    "external_data": {},
    "products": {
        "EndpointSecure": {
            "product_id": "EndpointSecure",
            "provisioned": false
        },
        "HomeSecure": {
            "product_id": "HomeSecure",
            "provisioned": false
        },
        "NetworkSecure": {
            "delegated_admin": true,
            "product_id": "NetworkSecure",
            "provisioned": true
        }
    },
    "profiles": {
        "profile_id_default": "kid",
        "profiles_definition": [
            "kid",
            "teenager",
            "adult"
        ]
    },
    "services_provisioned": {
        "ads_free": {
            "provisioned": true
        },
        "parental_control": {
            "provisioned": true
        },
        "threat_protection": {
            "provisioned": true
        }
    }
}

export const mockAccount2 = {
    "account_id": "ejemplo1",
    "account_type": "residential",
    "external_data": {},
    "products": {
        "EndpointSecure": {
            "product_id": "EndpointSecure",
            "provisioned": false
        },
        "HomeSecure": {
            "product_id": "HomeSecure",
            "provisioned": false
        },
        "NetworkSecure": {
            "delegated_admin": true,
            "product_id": "NetworkSecure",
            "provisioned": false
        }
    },
    "profiles": {
        "profile_id_default": "kid",
        "profiles_definition": [
            "kid",
            "teenager",
            "adult"
        ],
    },
    "services_provisioned": {
        "ads_free": {
            "provisioned": true
        },
        "parental_control": {
            "provisioned": true
        },
        "threat_protection": {
            "provisioned": true
        },
    },
    "service_configuration": {
        "parental_control": {
            "blocking_pages": {
                "blocking_page": { page_mode: "default", external_blocking_page_name: "", custom_blocking_page_url: "" },
                "blocking_page_password_override": { page_mode: "custom", external_blocking_page_name: "", custom_blocking_page_url: "www.nada.com" }
            }
        },
        "threat_protection": {
            "blocking_pages": {
                "antimalware_antiphishing_blocking_page": { page_mode: "external", external_blocking_page_name: "urltp1", custom_blocking_page_url: "" },
                "antibotnet_blocking_page": { page_mode: "external", external_blocking_page_name: "urltp2", custom_blocking_page_url: "" },
                "antivirus_blocking_page": { page_mode: "default", external_blocking_page_name: "", custom_blocking_page_url: "" }
            }
        }
    }
}

export const createAdmin = (item) => {
    ['EndpointSecure', 'HomeSecure', 'NetworkSecure'].forEach(product => {
        if (!item.products[product]) {
            item.products[product] = {
                product_id: product,
                provisioned: false
            };
        }
        else {
            item.products[product] = item.products[product];
        }
    });
    if (!item.profiles)
        item.profiles = { profile_id_default: '', profiles_definition: [] };
    if (!item.services_provisioned) {
        item.services_provisioned = {};
    }
    ['ads_free', 'parental_control', 'threat_protection'].forEach(service => {
        if (!item.services_provisioned[service]) {
            item.services_provisioned[service] = {
                provisioned: false
            };
        }
        else {
            item.services_provisioned[service] = item.services_provisioned[service];
        }
    });

    const admin = {
        id: item.account_id,
        data: item
    };

    return admin
}