import AuthLayout from '@/layout/dashboard/AuthLayout.vue';

const ForgotMyPassword = () => import(
  /* webpackChunkName: "auth" */
  '@/pages/Login/ForgotMyPassword.vue'
);

const ForgotMyUsername = () => import(
  /* webpackChunkName: "auth" */
  '@/pages/Login/ForgotMyUsername.vue'
);

const RecoverPassword = () => import(
  /* webpackChunkName: "auth" */
  '@/pages/Login/RecoverPassword.vue'
);

const SMSCode = () => import(
  /* webpackChunkName: "auth" */
  '@/pages/Login/SMSCode.vue'
);

const ChangePassword = () => import(
  /* webpackChunkName: "auth" */
  '@/pages/Login/ChangePassword.vue'
);

const MagicLink = () => import(
  /* webpackChunkName: "auth" */
  '@/pages/Login/MagicLink.vue'
);

const ResetPassword404 = () => import(
  /* webpackChunkName: "auth" */
  '@/pages/Login/ResetPassword404.vue'
);

const ForceChangePassword = () => import(
  /* webpackChunkName: "auth" */
  '@/pages/Login/ForceChangePassword.vue'
);

const ExpiredPassword = () => import(
  /* webpackChunkName: "auth" */
  '@/pages/Login/ExpiredPassword.vue'
);

const Login = () => import(
  /* webpackChunkName: "auth" */
  '@/pages/Login/Login.vue'
);

export default {
  path: '/',
  component: AuthLayout,
  name: 'Authentication',
  children: [
    {
      path: 'login',
      name: 'Login',
      component: Login
    },
    {
      path: 'forgotmypassword',
      name: 'ForgotMyPassword',
      component: ForgotMyPassword
    },
    {
      path: 'forgotmyusername',
      name: 'ForgotMyUsername',
      component: ForgotMyUsername
    },
    {
      path: 'smscode',
      name: 'SMSCode',
      component: SMSCode
    }, 
    {
      path: 'recoverpassword',
      name: 'RecoverPassword',
      component: RecoverPassword
    },
    {
      path: 'changepassword',
      name: 'ChangePassword',
      component: ChangePassword
    },
    {
      path: 'reset/email/:dst/:magiclink',
      name: 'MagicLink',
      component: MagicLink
    },        
    {
      path: 'reset404',
      name: 'ResetPassword404',
      component: ResetPassword404
    },
    {
      path: 'forcechangepassword',
      name: 'ForceChangePassword',
      component: ForceChangePassword
    },     
    {
      path: '/expiredpassword',
      name: 'ExpiredPassword',
      component: ExpiredPassword
    },
  ]
};
