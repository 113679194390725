import axios from 'axios';

export async function getEndpointSecureSettings() {
  const { data } = await axios.get('/subsystems/endpoint-secure/settings');

  return data;
}

export async function updateEndpointSecureSettings(settings) {
  const { data } = await axios
    .patch('/subsystems/endpoint-secure/settings', settings);

  return data;
}
