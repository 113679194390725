import axios from 'axios'
import _ from 'lodash';
import i18n from '../../../i18n';
import { addFontToDocument } from "@/util/addFontToDocument";

export default {
    getCustomization({ commit, state, rootGetters }, id) {
        if (rootGetters['settings/getDebug']) {
            return new Promise((resolve, reject) => {
                commit('setCustomization', { app_name: rootGetters['settings/appName'] }, id)
                resolve(state.customization)
            })
        }
        else {
            return new Promise((resolve, reject) => {
                axios.get('/customization/' + encodeURIComponent(id))
                    .then(res => {
                        if (res && res.data) {
                            let customization = res.data
                            if (!!!customization.app_name) {
                                customization.app_name = rootGetters['settings/appName']
                            }
                            commit('setCustomization', customization, id)
                            resolve(customization)
                        }
                        else {
                            reject()
                        }
                    })
                    .catch(error => {
                        commit('setError', error, { root: true });
                        reject()
                    })
            });
        }
    },
    updateCustomization({ commit, state, rootGetters }, update) {
        let prev = _.cloneDeep(state)
        commit('updateCustomization', { data: update.data, id: update.id });

        return new Promise((resolve, reject) => {
            axios.patch('/customization/' + encodeURIComponent(update.id), update.data)
                .then(res => {
                    resolve()
                })
                .catch(error => {
                    //commit('setError', error, { root: true });
                    commit('setErrorAll', i18n.t('It was not possible to process your request, please try again'), { root: true });
                    commit('setCustomization', prev);
                    reject()
                })
        });
    },
    uploadFont({ commit }, data) {
        return new Promise((resolve, reject) => {
            axios.post('/customization/acc/fonts', JSON.stringify({ data }), {
              headers: {
                'Content-Type': 'application/json',
              }})
                .then((res) => {
                    commit('setUploadedFont', res.data)
                    resolve()
                })
                .catch(() => {
                    commit('setErrorAll', i18n.t('It was not possible to process your request, please try again'), { root: true });
                    reject()
                })
        });
    },
    getAccDefaultCustomization({ commit }) {
        return new Promise((resolve, reject) => {
            axios.get('/customization/acc/defaults')
            .then((res) => {
                const env_options = _.get(res, 'data.env_options', {})
                commit('setDefaultAccCustomization', env_options)
                resolve();
            })
            .catch((error) => {
                commit('setError', error, { root: true });
                reject();
            })
        });
    },
    getFontsList({commit}) {
      return new Promise((resolve, reject) => {
        axios.get('/customization/acc/fonts')
          .then((res) => {
            commit('setFonts', _.get(res, 'data.items', []))
            resolve();
          })
          .catch((error) => {
            commit('setError', error, { root: true });
            reject();
          })
      });
    },
    clearUploadedFile({commit}) {
      commit('setUploadedFont', null)
    },
  getCss({commit}) {
    return new Promise((resolve, reject) => {
      axios.get(`/customization/acc/css`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          commit('setError', error, { root: true });
          reject();
        })
    });
  },
  getFont({commit}, font) {
    axios.get(`/customization/acc/fonts/${font.font_id}`, {
      responseType: 'arraybuffer'
    })
      .then((res) => {
        addFontToDocument(font.font_family, res.data)
      })
      .catch((error) => {
        commit('setError', error, { root: true });
      })
  }
}
