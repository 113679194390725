import { external_servers } from '../../stubs/externalservers';
import axios from "axios";

export default {
    getExternalServers({ commit, state, rootGetters }) {
        if (!rootGetters['settings/getDebug']) {
            return new Promise((resolve, reject) => {
                axios.get('/subsystems/ns/external-servers')
                    .then(res => {
                        if (res && res.data && res.data.items) {
                            state.external_servers.clear()
                            res.data.items.forEach(item => {
                                let cluster = {
                                    id: item.name,
                                    data: {
                                        name: item.name,
                                        type: item.type,
                                        default: item.default,
                                        servers: new Map()
                                    }
                                }
                                commit('addCluster', cluster)

                                item.servers.forEach(server => {
                                    let srv = {
                                        id: server.name,
                                        data: server
                                    }
                                    commit('addServer', { cluster_name: item.name, data: srv })
                                })
                            })
                            let ret = { items: state.external_servers }
                            resolve(ret)
                        }
                        else {
                            reject()
                        }
                    })
                    .catch(error => {
                        commit('setError', error, { root: true });
                        reject(error)
                    })
            })
        }
        else {
            return new Promise((resolve, reject) => {
                let temp = state.external_servers.size <= 0 ? external_servers.items : state.external_servers;
                temp.forEach(item => {
                    let cluster = {
                        id: item.name,
                        data: {
                            name: item.name,
                            type: item.type,
                            default: item.default,
                            servers: new Map()
                        }
                    }
                    commit('addCluster', cluster)

                    item.servers.forEach(server => {
                        let srv = {
                            id: server.name,
                            data: server
                        }
                        commit('addServer', { cluster_name: item.name, data: srv })
                    })
                })
                let ret = { items: state.external_servers }
                resolve(ret)
            });
        }
    },

    createCluster({ commit, state, rootGetters }, cluster) {

        if (!rootGetters['settings/getDebug']) {
            return new Promise((resolve, reject) => {
                let clustern = {
                    type: cluster.type,
                    name: cluster.name,
                    default: cluster.default,
                    community: cluster.community,
                    servers: []
                }
                axios.post('/subsystems/ns/external-servers', clustern)
                    .then(res => {
                        if (res && res.data) {
                            let item = res.data

                            let cluster = {
                                id: item.name,
                                data: {
                                    type: item.type,
                                    name: item.name,
                                    default: item.default,
                                    community: item.community,
                                    servers: new Map()
                                }
                            }
                            commit('addCluster', cluster)
                            resolve(state.external_servers)
                        }
                    })
                    .catch(error => {
                        commit('setError', error, { root: true });
                        reject(error)
                    })
            });
        } else {
            return new Promise((resolve, reject) => {
                let clustern = {
                    id: cluster.name,
                    data: cluster
                }
                commit('addCluster', clustern)
                resolve(state.external_servers)
            })
        }
    },

    deleteCluster({ commit, state, rootGetters }, id) {
        if (rootGetters['settings/getDebug']) {
            return new Promise((resolve, reject) => {
                commit('removeCluster', id)
                resolve(state.external_servers)
            });
        }
        else {
            return new Promise((resolve, reject) => {
                axios.delete('/subsystems/ns/external-servers/' + encodeURIComponent(id))
                    .then(res => {
                        commit('removeCluster', id);
                        resolve(state.external_servers)
                    })
                    .catch(error => {
                        commit('setError', error, { root: true });
                        reject(error)
                    })
            });
        }
    },

    getServer({ state, commit }, { cluster_name, server_name }) {
        return new Promise((resolve, reject) => {
            let cluster = state.external_servers.get(cluster_name);

            let server;
            if (cluster)
                server = cluster.servers.get(server_name);

            if (!server) {
                server = {
                    name: "",
                    type: "",
                    status: true,
                    location: "",
                    mode: "primary",
                    port: "",
                    user: "",
                    password: ""
                }
            }

            let res = server;
            commit('setServer', res);
            resolve(res);
        });
    },

    createServer({ commit, state, rootGetters }, { cluster_name, data }) {
        if (rootGetters['settings/getDebug']) {
            return new Promise((resolve, reject) => {
                let srv = {
                    id: data.name,
                    data: data
                }
                commit('addServer', { cluster_name: cluster_name, data: srv })
                resolve(data)
            })
        } else {
            return new Promise((resolve, reject) => {
                let ndata = {
                    name: data.name,
                    port: parseInt(data.port),
                    location: data.location,
                    mode: data.mode,
                    status: data.status,
                }
                if (data.user !== "") ndata["user"] = data.user
                if (data.password !== "") ndata["password"] = data.password
                axios.post('/subsystems/ns/external-servers/' + encodeURIComponent(cluster_name), ndata)
                    .then(res => {
                        if (res && res.data) {
                            let srv = {
                                id: res.data.name,
                                data: res.data
                            }
                            commit('addServer', { cluster_name: cluster_name, data: srv })
                            resolve(res.data)
                        }
                    })
                    .catch(error => {
                        commit('setError', error, { root: true });
                        reject(error)
                    })
            })
        }
    },

    updateServer({ commit, state, rootGetters }, { cluster_name, server_name, data }) {
        if (rootGetters['settings/getDebug']) {
            return new Promise((resolve, reject) => {
                let srv = {
                    id: data.name,
                    data: data
                }
                commit('addServer', { cluster_name: cluster_name, data: srv })
                resolve(data)
            })
        } else {
            return new Promise((resolve, reject) => {
                let ndata = {
                    name: data.name,
                    port: parseInt(data.port),
                    location: data.location,
                    mode: data.mode,
                    status: data.status
                }
                if (data.user !== "") ndata["user"] = data.user
                if (data.password !== "") ndata["password"] = data.password
                axios.patch('/subsystems/ns/external-servers/' + encodeURIComponent(cluster_name) + '/' + encodeURIComponent(server_name), ndata)
                    .then(res => {
                        /*if ( res && res.data){						
                            let srv = {
                                id: res.data.name,
                                data: res.data
                            }*/
                        commit('addServer', { cluster_name: cluster_name, data: ndata })
                        resolve(ndata)
                        //}
                    })
                    .catch(error => {
                        commit('setError', error, { root: true });
                        reject(error)
                    })
            })
        }
    },

    deleteServer({ commit, state, rootGetters }, { cluster_name, server_name }) {
        if (rootGetters['settings/getDebug']) {
            return new Promise((resolve, reject) => {
                commit('removeServer', { cluster_name: cluster_name, server_name: server_name })
                resolve(state.external_servers)
            });
        }
        else {
            return new Promise((resolve, reject) => {
                axios.delete('/subsystems/ns/external-servers/' + encodeURIComponent(cluster_name) + '/' + encodeURIComponent(server_name))
                    .then(res => {
                        commit('removeServer', { cluster_name: cluster_name, server_name: server_name });
                        resolve(state.external_servers)
                    })
                    .catch(error => {
                        commit('setError', error, { root: true });
                        reject(error)
                    })
            });
        }
    },
};