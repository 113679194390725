import moment from 'moment-timezone';

moment.tz.setDefault('UTC');

function decapitalizeFirstLetter(str) {
  return `${str.charAt(0).toLowerCase()}${str.slice(1)}`;
}

function camelPad(str){
  return str
    .replace(/([A-Z]+)([A-Z][a-z])/g, ' $1 $2')
    .replace(/([a-z\d])([A-Z])/g, '$1 $2')
    .replace(/([a-zA-Z])(\d)/g, '$1 $2')
    .replace(/^./, (str) => str.toUpperCase())
    .trim();
};

function cutLabels(labels) {
  return labels.map((item) => {
    if (item.length && item.length > 15) {
      return `${item.slice(0, 15)}...`;
    }

    return item;
  });
}

function getCriteriaLabel(criteria) {
  switch (criteria) {
    case 'ClientId':
      return 'Account';
    case 'UserGroups':
      return 'Group';
    case 'UserName':
      return 'Device';
    case 'PolicyName':
      return 'Rule';
    default:
      return camelPad(criteria);
  }
}

export const adminReportList = (state) => state.adminReportList;

export const adminReportDetails = (state) => state.adminReportDetails;


function checkReportVisibility(rootGetters, type, guimode){
  switch(type){
    case 'custom':
      return true;
    case 'monitoring':
      return rootGetters['settings/getProductState']('NetworkSecure');
    case 'global':
    case 'firewall':
      return !rootGetters["networksecure/status/networkSecureSnifferMode"] && rootGetters['settings/getServiceState']('firewall');
    case 'autonotices':
      return !rootGetters["networksecure/status/networkSecureSnifferMode"] && rootGetters['settings/getServiceStateNS']('Activation'); 
    case 'antibotnet':
    case 'antiphishing':
      if (guimode.startsWith('Web '))
        return rootGetters['settings/getServiceStateNS'](guimode.split(' ')[1])
      else
        return false
    case 'antivirus':
      return (!rootGetters["networksecure/status/networkSecureSnifferMode"] || rootGetters['settings/EShasAV']) && rootGetters['settings/checkSubService']('AntiVirus');
    case 'contentfilter':
      if(guimode === "CBAppUsage"){
        return rootGetters['settings/getProductState']('CBEndpoint') && rootGetters['settings/checkSubService']('AppUsage');
      }
      return rootGetters['settings/checkSubService']('ParentalControl') || 
                rootGetters['settings/checkSubService']('ContentFilter') || 
                rootGetters['settings/checkSubService']('ContentFiltering')
    case 'adsfree':
      return false; // currently ads_free not supported until 17.2
    case 'location':
      return rootGetters['settings/getProductState']('CBEndpoint') && rootGetters['settings/checkSubService']('GeoFence');
    case 'bullying':
      return rootGetters['settings/getProductState']('CBEndpoint') && rootGetters['settings/checkSubService']('AntiBullying');
    case 'lanprotection':
    case 'routerprotection':       
      return rootGetters['settings/getProductState']('RouterSecure')   
    default:
      return rootGetters['settings/getServiceState'](type);
  }
}

function checkReportVisibilityFW(rootGetters, type, admin_report_id){
  if(type == "firewall" || type == "global")
    return !rootGetters["networksecure/status/networkSecureSnifferMode"] && rootGetters['settings/getServiceState']('firewall') &&   
           (rootGetters['settings/isIOT'] || admin_report_id == "Firewall_Blocks_by_policy_name_last_month");
  else if(type == "monitoring")
    return (!rootGetters['settings/isIOT'] || rootGetters['settings/isIOT'] && admin_report_id != 'Monitoring_Reports_WebFilter')
  else
    return true;  
}

function checkReportFromVisibility(rootGetters, guimodeskey){
  switch( guimodeskey){
    case 'Internal':
      return checkReportVisibility(rootGetters, 'monitoring');
    case 'AdsFree':
    case 'AutoNotices':
    case 'Firewall':
      return checkReportVisibility(rootGetters, guimodeskey.toLowerCase());
    case 'CBSocialMedia':
      return checkReportVisibility(rootGetters, "bullying");     
    case 'CBGeoFenceStatistics':
      return checkReportVisibility(rootGetters, "location");         
    case 'CBAppUsage':
      return checkReportVisibility(rootGetters, "contentfilter", guimodeskey.toLowerCase());        
    case 'Web AntiBotnet':
    case 'Web AntiPhishing':
    case 'Web AntiVirus':
    case 'Web ContentFilter':
      return checkReportVisibility(rootGetters, guimodeskey.split(' ')[1].toLowerCase(), guimodeskey )
    case 'Web LanProtection':      
    case 'Web RouterProtection':      
      return checkReportVisibility(rootGetters, guimodeskey.split(' ')[1].toLowerCase());

    default:
      return checkReportVisibility(rootGetters, guimodeskey);
  }
}

export const reporterTemplates = (state, getters , rootState, rootGetters) => (lang) => {
  lang == 'es' ? lang = 'es-ES' : lang = 'en-US';
  return getters.adminReportList.reduce((accumulator, currentItem) => {
    let localeReport = currentItem.admin_report_i18n.find(obj => {
      return obj.language_id === lang
    })
    if ( checkReportVisibility(rootGetters, currentItem.type, currentItem.guimode_id) && checkReportVisibilityFW(rootGetters, currentItem.type, currentItem.admin_report_id)) {
      return [...accumulator, {
        id: currentItem.admin_report_id,
        name: typeof localeReport !== 'undefined' ? localeReport.admin_report_name : currentItem.name,
        type: currentItem.type,
        programmed: currentItem.programmed,
        description: typeof localeReport !== 'undefined' ? localeReport.admin_report_description : currentItem.description,
        predefined: currentItem.predefined,
        query: currentItem.query,
        GUIModeId: currentItem.guimode_id}];
      } else {
        return accumulator
      }
  }, []);
}

export const reporterTemplateDetails = (state, getters) => (lang) => {
  lang == 'es' ? lang = 'es-ES' : lang = 'en-US';
  const { adminReportDetails } = getters;
  const reportOptions = getters.reportOptions
    .find((options) => options.original === adminReportDetails.guimode_id) || {
      options: {
        selecting: [],
      },
    };
  const localeReport = adminReportDetails.admin_report_i18n.find(obj => {
    return obj.language_id === lang
  })
  return {
    name: typeof localeReport !== 'undefined' ? localeReport.admin_report_name : adminReportDetails.name,
    reportFrom: adminReportDetails.guimode_id,
    groupedBy: adminReportDetails.query.group[0],
    recordsToShow: adminReportDetails.query.limit,
    classifyDescending: adminReportDetails.query.order,
    type: adminReportDetails.query.type,
    subgroupedBy: adminReportDetails.query.group[1],
    timeFrame: typeof adminReportDetails.query.date_interval === 'string'
      ? adminReportDetails.query.date_interval
      : 'custom',
    start: typeof adminReportDetails.query.date_interval !== 'string'
      ? adminReportDetails.query.date_interval.start_date
      : moment()
        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toISOString(),
    end: typeof adminReportDetails.query.date_interval !== 'string'
      ? adminReportDetails.query.date_interval.end_date
      : moment()
        .set({ hour: 23, minute: 55, second: 0, millisecond: 0 }).toISOString(),
    description: typeof localeReport !== 'undefined' ? localeReport.admin_report_description : adminReportDetails.description,
    format: adminReportDetails.query.style || 'bar',
    selectionCriteria: reportOptions.options.selecting.map((item) => {
      const where = adminReportDetails.query.where || [];
      const criteria = where.find((query) => query.field === item.original);

      if (criteria) {
        if (item.original === 'CategoryList' || item.original === 'Module') {
          return {
            fieldName: item.original,
            label: item.label,
            value: criteria.value,
            notContains: criteria.operator !== 'contains',
          }
        } else {
          return {
            fieldName: item.original,
            label: item.label,
            value: criteria.value,
            notIn: criteria.operator !== 'in',
          }
       }
      }

      return {
        fieldName: item.original,
        label: item.label,
        value: '',
        notIn: false,
      }
    }),
  };
}

export const isReporterTemplateScheduled = (state, getters) => {
  return getters.adminReportDetails.programmed;
}

export const reporterTemplateSchedule = (state, getters) => {
  const {
    first_execution,
    frequency,
    week_day,
    month_day,
    send_info_mail,
    send_info,
  } = getters.adminReportDetails.programming;

  return {
    ...(first_execution && { firstExecution: first_execution }),
    ...(frequency && { frequency }),
    ...(week_day && { weekDay: week_day }),
    ...(month_day && { monthDay: month_day }),
    ...(send_info_mail.sender && { sender: send_info_mail.sender }),
    ...(send_info_mail.addresses.length 
      && { addresses: send_info_mail.addresses }),
    ...(send_info && { sendInfo: send_info }),
  };
}

export const reportOptions = (state , getters, rootState, rootGetters) => {
  return state.reportConfig.map((item) => {
    const original = Object.keys(item)[0];    
    if ( !checkReportFromVisibility(rootGetters, original))
      return
        
    const label = original;
    const value = decapitalizeFirstLetter(original);
    const options = {
      grouping: item[original].GroupingCriteria.map((criteria) => ({
        original: criteria,
        label: getCriteriaLabel(criteria),
        value: criteria,
      })),
      monitorSelecting: item[original].MonitorSelectingCriteria.map((criteria) => ({
        original: criteria,
        label: getCriteriaLabel(criteria),
        value: criteria,
      })),
      selecting: item[original].SelectingCriteria.map((criteria) => ({
        original: criteria,
        label: getCriteriaLabel(criteria),
        value: criteria,
      })),
      showing: item[original].ShowingCriteria.map((criteria) => ({
        original: criteria,
        label: getCriteriaLabel(criteria),
        value: criteria,
      })),
    }    
    return {
      original,
      label, 
      value, 
      options, 
    };
  }).filter(Boolean);
}

export const reportView = (state) => state.reportView;

export const chartData = (state, getters) => {
  if (getters.reportView.report_type === 'aggregated_1_level') {
    return {
      labels: getters.reportView.data.map((item) => item.name),
      chartLabels: cutLabels(getters.reportView.data.map((item) => item.name)),
      datasets: [{ data: getters.reportView.data.map((item) => item.value) }],
    }
  }

  return getters.reportView.data.reduce((accumulator, currentItem, index) => {
    let datasets = accumulator.datasets.map((dataSetItem) => dataSetItem);
    
    currentItem.value.forEach((item) => {
      const matchingIndex = datasets.findIndex((dataSetItem) => {
        return dataSetItem.label === item.name;
      });

      const data = matchingIndex > -1
        ? datasets[matchingIndex].data
        : [];

      for (let i = 0; i <= index; i += 1) {
        if (!data[i] && i < index) {
          data[i] = 0;
        } else if (!data[i] && i === index) {
          data[i] = item.value;
        }
      }

      if (matchingIndex > -1) {
        datasets.splice(matchingIndex, 1, { label: item.name, data });
      } else {
        datasets.push({ label: item.name, data });
      }
    });

    datasets = datasets.map((dataSetItem) => {
      const { data } = dataSetItem;

      if (!data[index]) {
        data[index] = 0;
      }

      return Object.assign(dataSetItem, { data });
    });

    return {
      labels: [...accumulator.labels, currentItem.name],
      chartLabels: cutLabels([...accumulator.labels, currentItem.name]),
      datasets,
    };
  }, { labels: [], chartLabels: [], datasets: [] });
}

export const adminReportExecutions = (state) => {
  return state.adminReportExecutions;
}

export const adminReportExecutionResults = (state) => {
  return state.adminReportExecutionResults;
}

export const previewedReportDetails = (state) => {
  return state.previewedReportDetails;
}
