export default {
    setContentFilter(state, contentFilter) {
      state.contentFilterStrategy = contentFilter;
    },
    setContentFilterRules(state, rules) {
      rules = rules.sort((a, b) => {
        return a.order > b.order ? 1 : -1;
      });
      state.rules = rules;
    },
    addContentFilterRule(state, newRule) {
      state.rules.push(newRule);
    },
    setAdvancedConfiguration(state, config) {
      state.advancedConfiguration = config;
    },
    setDefaultBlockingUrl(state, url) {
      state.defaultBlockingUrl = url;
    },
    setServiceConfiguration(state, serviceConfiguration) {
      Object.assign(state, { serviceConfiguration });
    },
    setBlockingPages(state, blockingPages) {
      Object.assign(state, { blockingPages });
    },
  };