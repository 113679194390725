export const mockProfiles = [
    {
        id: 'kid',
        profile_i18n: [
            { language_id: 'en', profile_name: 'kid', profile_description: 'This profile is suitable for children from 2 to 5 years old' },
            { language_id: 'es', profile_name: 'niño', profile_description: 'Este perfil es adecuado para niños entre 2 y 5 años' },
        ]
    },
    {
        id: 'teen',
        profile_i18n: [
            { language_id: 'en', profile_name: 'teen', profile_description: 'This profile is suitable for kids from 5 to 15 years old' },
            { language_id: 'es', profile_name: 'adolescente', profile_description: 'Este perfil es adecuado para niños entre 5 y 15 años' },
        ]
    },
    {
        id: 'adult',
        profile_i18n: [
            { language_id: 'en', profile_name: 'adult', profile_description: 'This profile is suitable for adults' },
            { language_id: 'es', profile_name: 'adulto', profile_description: 'Este perfil es adecuado para adultos' },
        ],
    }
]