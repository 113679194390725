
export const mockRouter = (data = {account_id: "test" }) => ([{
  router_id: '381d24b6dea84a5a8eafb4ab96cf47db',
  router_model: 'TP-Link100',
  account: 'test',
  agent_version: 'agent-100.bin',
  target_version: 'agent-100A.bin',
  status: true,
  state: 'reboot',
  router_firmware:"4.1.2.3.4.5"
},
{
  router_id: 'Router 2',
  router_model: 'TP-Link45',
  account: data.account_id,
  agent_version: 'agent-100.bin',
  target_version: '-',
  status: true,
  state: 'safe',

  router_firmware: "6.1.2.3.4.5"
},
{
  router_id: 'Router 3',
  router_model: 'ZTE-MF287',
  account: data.account_id,
  agent_version: 'agent-200.bin',
  target_version: 'agent-200Y.bin',
  status: false,
  state: '-',
  router_firmware: "1.2.3.4.5"
},
{
  router_id: 'Router 7',
  router_model: 'ZTE-MF287',
  account: data.account_id,
  agent_version: 'agent-200.bin',
  target_version: 'agent-200Y.bin',
  status: false,
  state: '-',
  router_firmware: "1.2.3.4.5"
},
{
  router_id: 'Router 8',
  router_model: 'ZTE-MF287',
  account: data.account_id,
  agent_version: 'agent-200.bin',
  target_version: 'agent-200Y.bin',
  status: false,
  state: '-',
  router_firmware: "1.2.3.4.5"
},
{
  router_id: 'Router 4',
  router_model: 'ZTE-MF287',
  account: data.account_id,
  agent_version: 'agent-200.bin',
  target_version: 'agent-200Y.bin',
  status: false,
  state: '-',
  router_firmware: "1.2.3.4.5"
},
{
  router_id: 'Router 5',
  router_model: 'ZTE-MF287',
  account: data.account_id,
  agent_version: 'agent-200.bin',
  target_version: 'agent-200Y.bin',
  status: false,
  state: ['reboot','safe'],
  router_firmware: "1.2.3.4.5"
},
{
  router_id: 'Router 6',
  router_model: 'ZTE-MF287',
  account: data.account_id,
  agent_version: 'agent-200.bin',
  target_version: 'agent-200Y.bin',
  status: false,
  state: '-',
  router_firmware: "1.2.3.4.5"
}, {
  router_id: 'Router 10',
  router_model: 'ZTE-MF287',
  account: data.account_id,
  agent_version: 'agent-200.bin',
  target_version: 'agent-200Y.bin',
  status: false,
  state: '-',
  router_firmware: "1.2.3.4.5"
},
 {
  router_id: 'Router 11',
  router_model: 'ZTE-MF287',
  account: data.account_id,
  agent_version: 'agent-200.bin',
  target_version: 'agent-200Y.bin',
  status: false,
  state: '-',
  router_firmware: "1.2.3.4.5"
},
 {
  router_id: 'Router 12',
  router_model: 'ZTE-MF287',
  account: data.account_id,
  agent_version: 'agent-200.bin',
  target_version: 'agent-200Y.bin',
  status: false,
  state: '-',
  router_firmware: "1.2.3.4.5"
},
]);

