export default {
    setServer(state, server) {
        Object.assign(state.server, server);
    },
    setSmsServer(state, server) {
      Object.assign(state.sms_server, server);
    },
    setEmailServer(state, server) {
      Object.assign(state.email_server, server);
    },
    resetLdapServer(state) {
      state.server = {};
    },
    resetEmailServer(state) {
      state.email_server = {};
    },
    resetSmsServer(state) {
      state.sms_server = {};
    },
    addCluster(state, cluster) {
        state.external_servers.set(cluster.id, cluster.data);
        state.external_servers = new Map(state.external_servers);
    },
    addSmsServer(state, server) {
        state.sms_servers = {...state.sms_servers, [server.kannel_id]: server}
    },
    addEmailServer(state, server) {
      state.email_servers = {...state.email_servers, [server.mta_id]: server}
    },
    removeCluster(state, id) {
        if (id) {
            state.external_servers.delete(id);
            state.external_servers = new Map(state.external_servers);
        }
    },
    addServer(state, { cluster_name, data }) {
        let cluster = state.external_servers.get(cluster_name);
        if (cluster) {
            if (!cluster.servers) cluster.servers = [];
            cluster.servers.push(data.data);
        }
        state.external_servers = new Map(state.external_servers);
    },
    removeServer(state, { cluster_name, server_name }) {
        let cluster = state.external_servers.get(cluster_name);
        if (cluster) cluster.servers = cluster.servers.filter(s => { return s.name !== server_name });
        let externalServers = new Map(state.external_servers);
        externalServers.set(cluster_name, cluster);
        state.external_servers = externalServers;
    },
    removeSmsServer(state, serverId) {
      if (state.sms_servers[serverId]) {
        const smsServers = {...state.sms_servers};
        delete smsServers[serverId];
        state.sms_servers = smsServers;
      }
    },
    removeEmailServer(state, serverId) {
      if (state.email_servers[serverId]) {
        const emailServers = {...state.email_servers};
        delete emailServers[serverId];
        state.email_servers = emailServers;
      }
    },
    addQueryGroups(state, data) {
        if (!state.queryGroups[data.cluster] || state.queryGroups[data.cluster].length < data.values.length)
            state.queryGroups = {
                ...state.queryGroups,
                [data.cluster]: data.values
            }
    },
    setMoreGroups(state, value) {
        state.isMoreGroups = value
    }
}
