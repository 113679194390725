<template>
  <ul class="pagination" :class="paginationClass">
    <li class="page-item prev-page position-relative" :class="{ disabled: !prev }">
      <a class="page-link" aria-label="Previous" @click="prevPage" v-text="'«'" />
    </li>
    <li class="page-item" v-if="prev && !updating">
      <a class="page-link" @click="prevPage">{{value - 1}}</a>
    </li>
    <li class="page-item active">
      <a class="page-link">{{ value }}</a>
    </li>
    <li class="page-item" v-if="next && !updating">
      <a class="page-link" @click="nextPage">{{value + 1}}</a>
    </li>
    <li class="page-item page-pre next-page position-relative" :class="{ disabled: !next }">
      <a class="page-link" aria-label="Next" @click="nextPage" v-text="'»'" />
    </li>
  </ul>
</template>

<script>
export default {
  name: 'n-pagination-server',
  props: {
    type: {
      type: String,
      default: 'primary',
      validator: value => {
        return [
          'default',
          'primary',
          'danger',
          'success',
          'warning',
          'info'
        ].includes(value);
      }
    },
    perPage: {
      type: Number,
      default: null
    },
    prev: {
      type: Boolean,
      default: false
    },
    next: {
      type: Boolean,
      default: false
    },
    value: {
      type: Number,
      default: 1
    },
    updating: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    paginationClass() {
      return `pagination-${this.type}`;
    },
  },
  methods: {
    nextPage() {
      if (this.next) {
        this.$emit('input', this.value + 1);
      }
    },
    prevPage() {
      if (this.prev) {
        this.$emit('input', this.value - 1);
      }
    }
  },
  watch: {
    perPage(newValue) {
      this.$emit('input', 1);
    }
  }
};
</script>
