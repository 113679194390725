import _ from 'lodash';

export default {
    getAutoNotices: (state) => () => {
        return state.autoNoticeStrategy !== undefined ? state.autoNoticeStrategy : {};
    },
    getAutoNoticesRules(state) {
        return state.rules;
    },
    getChartData(state) {
        return state.firewallActivity;
    },
    isDebug(state, getters, rootState, rootGetters) {
        return rootGetters['settings/getDebug']
    },
    getAdvancedConfiguration(state) {
        return state.configuration
    },
    getCurrentConfiguration(state) {
        return state.currentConfiguration
    },
    getConfigurationNames(state, rootState) {
        let result = {
            redirections: [],
            banners: [],
            smartbanners: []
        }

        if (!!state.configuration.redirections)
            result.redirections = state.configuration.redirections.map(element => (element.autonotice_list_id))
        if (!!state.configuration.banners)
            result.banners = state.configuration.banners.map(element => (element.autonotice_list_id))
        if (!!state.configuration.smartbanners)
            result.smartbanners = state.configuration.smartbanners.map(element => (element.autonotice_list_id))
        return result
    },
    getAllowed(state) {
        return state.allowed
    },
    getRuleById: (state, getters) => (ruleId) => {
        let rule = state.rules.filter(rule => "" + rule.rule_name === "" + ruleId);
        if (rule && rule.length > 0)
            return _.cloneDeep(rule[0]);
        else
            return null;
    },
}