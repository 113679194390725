import DashboardLayout from "@/layout/dashboard/DashboardLayout.vue";

const AntiPhishing = () => import(
  /* webpackChunkName: "ns-services" */
  "@/pages/NetworkSecure/AntiPhishing/AntiPhishing.vue"
);

const AntiPhishingEdit = () => import(
  /* webpackChunkName: "ns-services" */
  "@/pages/NetworkSecure/AntiPhishing/AntiPhishingEdit.vue"
);

const AdvancedConfiguration = () => import(
  /* webpackChunkName: "ns-services" */
  "@/pages/NetworkSecure/AntiPhishing/AdvancedConfiguration/AdvancedConfiguration.vue"
);

export default {
  path: '/ns/Malware&Phishing',
  component: DashboardLayout,
  name: 'Network Secure / Malware & Phishing',
  children: [
    {
      path: "Policies/Create",
      name: 'Network Secure / Malware & Phishing / Filtering Policies / Create',
      component: AntiPhishingEdit
    },
    {
      path: "Policies/Edit/*",
      name: 'Network Secure / Malware & Phishing / Filtering Policies / Edit',
      component: AntiPhishingEdit
    },
    {
      path: 'Policies*',
      name: 'Network Secure / Malware & Phishing / Filtering Policies',
      component: AntiPhishing
    },
    {
      path: 'Advanced',
      name: 'Network Secure / Malware & Phishing / Advanced',
      component: AdvancedConfiguration
    }
  ]
};
