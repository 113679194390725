/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'item-antivirus': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path pid="0" d="M12.596 0a3.2 3.2 0 006.398 0h3.198a6.377 6.377 0 01-1.75 4.397 9.632 9.632 0 014.404 5.2h2.145a1.599 1.599 0 110 3.198h-1.6v3.199h1.6a1.599 1.599 0 110 3.198h-1.6v3.2h1.6c.884 0 1.599.714 1.599 1.599 0 .884-.715 1.599-1.6 1.599h-2.144c-1.761 4.998-7.24 7.624-12.24 5.862a9.59 9.59 0 01-5.862-5.862H4.6a1.599 1.599 0 110-3.199h1.6v-3.199h-1.6a1.599 1.599 0 110-3.198h1.6v-3.2h-1.6a1.6 1.6 0 010-3.198h2.145a9.632 9.632 0 014.403-5.2A6.377 6.377 0 019.397 0h3.2zm3.199 6.397a6.398 6.398 0 00-6.398 6.398v9.596a6.398 6.398 0 0012.795 0v-9.596a6.398 6.398 0 00-6.397-6.398zm1.6 12.795c.884 0 1.599.715 1.599 1.6 0 .884-.715 1.6-1.6 1.6h-3.198a1.599 1.599 0 110-3.2zm0-6.397a1.599 1.599 0 110 3.199h-3.2a1.6 1.6 0 010-3.2h3.2z" _fill="#000" fill-rule="evenodd"/>'
  }
})
