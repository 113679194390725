<template>    
    <div>
    <div class="mt-1"><label>{{vtitle}}</label></div>    
    <b-container class="form-group">
        <b-row >
            <b-col xs="12" sm="12" md="12" lg="3" xl="3" style="margin-left:0px;text-align:right">
                <el-select                         
                    class="select-primary"
                    size="large"
                    placeholder=""
                    :disabled="!permission_granted"
                    @change="onTypeChange"
                    v-model="page_type"
                    value="default"
                >
                    <el-option v-for="option in bptypes"
                        class="select-primary"
                        :value="option.key"
                        :label="t(option.label)"
                        :key="option.key"                        
                        :disabled="vurlslist.length==0 && option.key=='external'">
                    </el-option>
                </el-select>
            </b-col>

            <b-col xs="12" sm="12" md="12" lg="9" xl="9">
                <el-select v-if="page_type == 'external'" class="select-primary"
                    size="large"
                    placeholder=""
                    :disabled="!permission_granted"
                    v-model="page_url_ext"                                        
                >
                    <template v-for="option in vurlslist" >
                        <el-option 
                            :key="option.name" 
                            class="select-primary"
                            :value="option.name"
                            :label="option.name + ' - ' + option.blocking_page_url"
                            >
                        </el-option>
                    </template>
                </el-select>

                <fg-input v-if="page_type == 'custom'" v-model="page_url" ref="purl" 
                    @focus="onFocus"
                    @blur="onBlur"
                    name="URL"                    
                    v-validate="{url: {require_protocol: true }, required: true}"
                    :error="getError('URL')"                                    
                    class="no-border no-height"                            
                    addon-left-icon="now-ui-icons users_single-02"
                    autocapitalize="none"
                    style="font-size:1rem;" />  
                    
                    
                <fg-input v-if="page_type == 'default'" v-model="page_url_def" disabled 
                    name="URLDefault"                                                            
                    class="no-border no-height"                            
                    addon-left-icon="now-ui-icons users_single-02"
                    autocapitalize="none"
                    style="font-size:1rem;" />  
            </b-col>
        </b-row>
    </b-container>    
    </div>    
</template>

<script>
import { Select, Option } from 'element-ui';
import permissions from '@/mixins/permissions'
import GeneralMixin from "@/components/AsmConfigurations/general/GeneralMixin";

export default {
    name: 'blocking-page-item', 
    mixins: [permissions, GeneralMixin], 
    components: {             
        [Option.name]: Option,
        [Select.name]: Select,        
    },
    props: {
        value: {
            type: Object,
            default: () => { return {} }
        },               
        vtitle: {
            type: String,
            default: () => { return '' }
        }, 
        vdefault :   {
            type: Object,
            default: () => { return {} }
        }, 
        vsysdefaultname :   {
            type: String,
            default: () => { return "" }
        }, 
        vsysdefaulturl :   {
            type: String,
            default: () => { return "" }
        }, 
        vurlslist : {
            type: Array,
            default: () => { return [] }
        },
        vservice : {
            type: String,
            default: () => { return "" }
        },              
    },
    data() {
        return {            
            bptypes : [],            
            page_type : '',
            page_url : '',
            page_url_ext : '',           
            page_url_def : '',
            hasFocus: false,
            isValueChanged: false,
            inputPayload: {}
        }
    },

    watch: {     
        hasFocus(newValue){
            if(!newValue && this.isValueChanged){
                this.$emit('input', this.inputPayload);
                this.isValueChanged = false
            }
        }, 
        page_url_def (newValue) {             
            if( newValue != "" ){             
                let tmp = {
                    page_mode : this.page_type,                  
                }             
                this.$emit('input', tmp);
            }            
        },
        async page_url (newValue, oldValue) { 
            let rules = {url: {require_protocol: true }, required: true}
            const { valid } = await this.$validator.verify(newValue, rules)
            if(valid && newValue !== oldValue){
                this.isValueChanged = true      
                this.inputPayload = {
                    page_mode : this.page_type,                 
                    custom_blocking_page_url : newValue
                }                      
            }    
        },
        page_url_ext (newValue) {             
            if( newValue != "" ){
                let tmp = {
                    page_mode : this.page_type,
                    external_blocking_page_name : newValue,                  
                }
                this.$emit('input', tmp);            
            }
        },
        value(newValue) {            
            this.updateValue(newValue)
        },
        vservice(newValue){            
            this.bptypes =  [        
                { 'label' : this.$t("Default"),  'key' : 'default' },                    
                { 'label' : this.$t("External"), 'key' : 'external' }
            ]   

            if( newValue == "-" )
                this.bptypes.push({ 'label' : this.$t("Custom"),   'key' : 'custom' })                    
            
        }       
    },
    methods: {
        isError(){
            return this.errors && this.errors.items && this.errors.items.length > 0
        },
        getError(fieldName) {                  
            return this.errors.first(fieldName);
        },
        getURLDefault(){        
            if( this.vdefault.page_mode == "default" ){                
                return this.vsysdefaultname + " - " + this.vsysdefaulturl
            } 

            if( this.vdefault.page_mode == "custom" ){                
                return this.vdefault.custom_blocking_page_url    
            }
                
            if( this.vdefault.page_mode == "external" ){ 
                if( this.vservice == "-"  ){
                    let _vm = this               
                    var valURL = this.vurlslist.filter(function(elem){
                        if(elem.name == _vm.vdefault.external_blocking_page_name ) return elem.blocking_page_url;
                    });               
                    return this.vdefault.external_blocking_page_name + " - " + valURL[0].blocking_page_url;
                } else
                    return this.vsysdefaultname + " - " + this.vsysdefaulturl
            }
        },
        updateValue(newValue){
            if ( !!newValue){ 
            
                this.page_url_def = ""
                this.page_url_ext = "" 
                this.page_url = ""

                this.page_type = newValue.page_mode               
                if( this.page_type == "default" )
                    this.page_url_def = this.getURLDefault()
                else if( this.page_type == "external" )
                    this.page_url_ext =  newValue.external_blocking_page_name
                else
                    this.page_url = newValue.custom_blocking_page_url;                
            }
        },
        async onTypeChange(){
            this.errors.clear();

            this.page_url_def = ""
            this.page_url_ext = "" 
            this.page_url = ""

            if( this.page_type == "default" ){                                        
                this.page_url_def = this.getURLDefault()
            }else if( this.page_type == "external" ){                
                if( this.vurlslist.length > 0 )            
                    this.page_url_ext = this.vurlslist[0].name                
            }

            await this.$nextTick(() => {                
                let form = this.$validator.validateAll()               
            });
        },
        onFocus() {
            this.hasFocus = true;
        },
        onBlur() {
            this.hasFocus = false;
        },        
    },          
    mounted() {        
        let _vm = this;
        this.$nextTick(function(){              
            if ( !_vm.value ) {                  
                _vm.page_type = 'default'                
                _vm.page_url_def = this.getURLDefault()
            }            
        })           
        
        this.bptypes =  [        
            { 'label' : this.$t("Default"),  'key' : 'default' },
            { 'label' : this.$t("External"), 'key' : 'external' },
            { 'label' : this.$t("Custom"),   'key' : 'custom' },            
        ]         
    }
}

</script>