    <template>
    <b-card class="px-4" style="height: 100%;">
        <template v-slot:header>
            <h5 class="title text-capitalize "><i class="now-ui-icons files_box mr-2"/>{{ t(beautify_string(title)) }}</h5>
            <product-attention-message
                v-if="ProductAttention"
                :title="ProductAttention.title"
                :showOnlyIfProductsExist="ProductAttention.products"
                :operator="ProductAttention.operator"
                />
        </template>
        <div class="row">
            <template v-for="(value, name, idx) in settings">                
                <setting-field v-if="name !== 'enabled' && ! is_array(value) && name!='blocking_pages'" :disable="disable" :configurationType="configurationType" :setting_group="settingGroup" :global="global" :setting="{ value, name }" :key="idx" :visible_for_permissions="visible_for_permissions"/>
            </template>
        </div>
    </b-card>
</template>

<script>
import GeneralMixin from './GeneralMixin';
import SettingField from './SettingField';
import schemaValidation from "@/mixins/schemaValidation";
import ProductAttentionMessage from "@/components/ProductAttentionMessage.vue";
import _ from 'lodash';

export default
{
    components: {
        SettingField,
        ProductAttentionMessage
    },
    mixins: [GeneralMixin,schemaValidation],
    props: {
        disable: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            default: ''
        },
        configurationType: {
            type: String,
            default: ''
        },
        settingGroup: {
            type: String,
            default: ''
        },
        settings: {
            type: Object,
            default: () => {}
        },
        jsonschemaName: {
            type: String,
            default: () => {}
        },
		global: {
			type: Boolean,
			default: false
        },
        ProductAttention: {
         type: [Object, null],
        },
        visible_for_permissions: { 
            type: Array, 
            default: function () { return [] }
        }        
    },
    created(){
        this.choseSchema(this.jsonschemaName);
        this.reset_errors();
    },
    methods: {
        is_array(value) {
            return _.isArray(value)
        }
    }
}
</script>