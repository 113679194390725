/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icon-triggers': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<defs><path pid="0" d="M8.176 17.163h7.46a1.454 1.454 0 011.446 1.61l-.653 6.037 6.668-9.974h-7.46a1.454 1.454 0 01-1.447-1.61l.654-6.035-6.668 9.972zM14.343 32a1.452 1.452 0 01-1.446-1.61l1.119-10.317H5.454a1.455 1.455 0 01-1.208-2.264L15.72.647c.37-.559 1.075-.788 1.704-.559.63.229 1.023.859.95 1.523l-1.118 10.316h8.561a1.457 1.457 0 011.209 2.263L15.552 31.354a1.452 1.452 0 01-1.209.646z" id="svgicon_icon-triggers_a"/></defs><use _fill="#000" fill-rule="nonzero" xlink:href="#svgicon_icon-triggers_a"/>'
  }
})
