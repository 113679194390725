/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'item-services': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M19.5 15.3h-2c0-1.2-.8-2.3-1.9-2.7l-6.2-2.3h-8v11h6v-1.4l7 1.9 8-2.5v-1c.1-1.7-1.2-3-2.9-3zm-16 3.9v-7h2v7h-2zm11 .5l-7-1.9v-5.5h1.6l5.8 2.2c.3.1.6.5.6.8 0 0-2-.1-2.3-.1l-2.4-.8-.6 1.9 2.4.8c.5.2 1 .3 1.6.3h5.4c.4 0 .7.2.9.6l-6 1.7z" _fill="#383132"/><g _fill="#383132"><path pid="1" d="M21.2 7.5h-3.1v3.1h-1V7.5H14v-1h3.1V3.4h1v3.1h3.1v1z"/><path pid="2" d="M18.6 11.1h-2V8h-3.1V6h3.1V2.9h2V6h3.1v2h-3.1v3.1zm-1-1V7h3.1-3.1V3.9 7h-3.1 3.1v3.1z"/></g>'
  }
})
