import administrators from './administrators';
import customization from './customization';
import externalServers from './externalServers';
import license from './license';
import jobs from './jobs';
import dataExport from './dataExport';

export default {
    namespaced: true,
    modules: {
        administrators,
        customization,
        externalServers,
        license,
        jobs,
        dataExport
    },
    state: {

    },
    getters: {

    },
    mutations: {

    },
    accounts: {

    }
}