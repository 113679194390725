export const IDENTITY = 'identity';

export const SESSION = 'session';

export const CONFIGURATION = 'configuration';

export const REPORTS = 'reports';

export const GUI_MODES = 'gui-modes';

export const SUBSYSTEMS = 'subsystems';

export const NS = 'ns';

export const ADMIN_REPORTS = 'admin-reports';

export const HISTORIC = 'historic';

export const PROVIDERS = 'providers';

export const ACTIVE_PROVIDERS = 'providers-active';

export const ENTERPRISE = 'enterprise';
