/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'item-profile': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 5.4c1.2 0 2.2 1 2.2 2.2s-1 2.2-2.2 2.2-2.2-1-2.2-2.2 1-2.2 2.2-2.2m0 11.3c3 0 6.5 1.5 6.7 2.2H5.3c.2-.8 3.7-2.2 6.7-2.2m0-13.5c-2.5 0-4.5 2-4.5 4.5s2 4.5 4.5 4.5 4.5-2 4.5-4.5-2-4.5-4.5-4.5zm0 11.2c-3 0-9 1.5-9 4.5v2.2h18v-2.2c0-3-6-4.5-9-4.5z" _fill="#383132"/>'
  }
})
