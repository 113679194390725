/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'location-button': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<defs><path pid="0" d="M15.8 14.4a2.403 2.403 0 01-2.4-2.4c0-1.324 1.077-2.4 2.4-2.4s2.4 1.076 2.4 2.4c0 1.322-1.077 2.4-2.4 2.4m0-8a5.606 5.606 0 00-5.6 5.6c0 3.087 2.512 5.6 5.6 5.6 3.088 0 5.6-2.513 5.6-5.6 0-3.089-2.512-5.6-5.6-5.6m0 21.834c-2.68-2.535-9.6-9.65-9.6-15.559 0-5.224 4.306-9.475 9.6-9.475s9.6 4.251 9.6 9.475c0 5.909-6.92 13.024-9.6 15.559M15.8 0C8.742 0 3 5.685 3 12.675c0 8.76 11.278 18.527 11.758 18.938a1.597 1.597 0 002.084 0c.48-.411 11.758-10.178 11.758-18.938C28.6 5.685 22.858 0 15.8 0" id="svgicon_location-button_a"/></defs><use _fill="#000" fill-rule="nonzero" xlink:href="#svgicon_location-button_a"/>'
  }
})
