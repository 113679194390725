export const mockServer = {
    base: "DC=Company,DC=com",
    dn: "CN=Administrator,CN=Users,DC=Company,DC=com",
    encryption: "none",
    groups: { members: "(member=%dn%)", name: "cn", nested: 0, objects: "(objectCategory=group)" },
    ldap: "w2k",
    mode: "primary",
    name: "AD_Zone1",
    port: 389,
    status: true,
    type: "ldap",
    users: {
      alias: "proxyAddresses",
      email: "mail",
      filter: "",
      get: "-",
      ha1: "",
      members: "",
      name: "sAMAccountName",
      objects: "(objectCategory=person)"
    }
  };

export const mockSmsServer = {
  kannel_id: "string",
  host: "string",
  port: 13013,
  ssl: false,
  path: "/cgi-bin/sendsms",
  from: "+34555888444",
  username: "string",
  authorization_header: "ESB Token=\"XXXXXXXXXXXXXXXX\"",
  mode: "GET",
  enabled: false,
  order: 0,
  additional_fixed_param_values: "O=NameOfClient&SampleParam=SampleValue"
}

export const mockEmailServer = {
  mta_id: "string",
  host: "string",
  port: 25,
  from: "string",
  username: "string",
  password: "string",
  starttls: false,
  ssl: false,
  enabled: false,
  order: 0
}
