import DashboardLayout from "@/layout/dashboard/DashboardLayout.vue";
import { store } from "@/store";

const Firewall = () => import(
  /* webpackChunkName: "ns-services" */
  "@/pages/NetworkSecure/Firewall/Firewall.vue"
);
const FirewallEdit = () => import(
    /* webpackChunkName: "ns-services" */
    "@/pages/NetworkSecure/Firewall/FirewallEdit.vue"
  );

const FirewallAdvancedConfiguration = () => import(
   /* webpackChunkName: "ns-services" */
  "@/pages/NetworkSecure/Firewall/AdvancedConfiguration/AdvancedConfiguration.vue"
);


import { networksecurepath } from '../utils';

const Frame = () => import(
  /* webpackChunkName: "common" */
  "@/components/ReverseProxyFrame.vue"
);


export default {
  path: "/ns/Firewall",
  component: DashboardLayout,
  name: 'Network Secure / Firewall',
  children: [
    {
      path: 'Policies',
      name: 'Network Secure / Firewall / Policies',
      component: Firewall,
    },
    {
      path: 'AdvancedConfiguration',
      name: 'Network Secure / Firewall / Advanced Configuration',
      component: FirewallAdvancedConfiguration,
    },
    {
      path: "Policies/Create",
      name: "Network Secure / Firewall / Policies / Create",
      component: FirewallEdit,
    },
    {
      path: "Policies/Edit/*",
      name: "Network Secure / Firewall / Policies / Edit",
      component: FirewallEdit,
    },
  ]
};