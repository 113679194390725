<template>
  <footer class="footer">
    <div class="container-fluid">
      <div class="copyright" id="div_version"> 
        v{{version}} &copy; {{year}}, {{t('Designed by')}}
        <a :href="web">{{companyName}}</a>. {{ t('All rights Reserved')}}.
        <img v-if="footerLoginImage" height="38" class="ml-2" src="img/footer.png" style="max-width:200px">
      </div>
    </div>
  </footer>
</template>
<script>
  export default {
    data() {
      return {
        version: '',
        year: new Date().getFullYear()
      }
    },
    computed: {
      companyName() {
        return this.$store.getters['customProvider/companyName'];
      },
      web() {
        return this.$store.getters['customProvider/web'];
      },
       footerLoginImage() {
      return this.$store.getters['customProvider/footerLoginImage'];
    },
    },
    mounted(){
      if (process.env.VUE_APP_VERSION)
          this.version = process.env.VUE_APP_VERSION
    },
  };
</script>
<style>
</style>
