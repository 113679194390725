<template>
  <toggle-list  oneCol v-model="filteringConfigurationOptions"
    :visible_for_permissions='[{actions: ["PATCH"], path: "/subsystems/ns/service-configuration"}]'>
    <div slot="header">
      <h5 class="title" style="display: flex; align-items: center;">
        <svgicon class="icon mr-2" icon="item-configuration" width="20" height="20" />
        <span style="margin-top: 4px;">{{t('filteringConfiguration')}}</span>
      </h5>
    </div>
  </toggle-list>
</template>

<script>
import { ToggleList } from '@/components';

export default {
  name: "webFilterFilteringConfiguration",
  props: {
    filteringConfiguration: {
      type: Object,
      required: true,
    },
  },
  components: {
    [ToggleList.name] : ToggleList,
  },
  computed: {
    filteringConfigurationOptions: {
      get() {
        const options = {};

        Object.keys(this.filteringConfiguration).forEach(key => {
          options[this.t(key)] = { enabled: this.filteringConfiguration[key] };
        });

        return options;
      },
      set(val) {
        Object.keys(this.filteringConfiguration).forEach((key) => {
          this.filteringConfiguration[key] = val[this.t(key)].enabled;
        });
      },
    },
  },
}
</script>
