import Vue from 'vue';
import { DateTime as objDateTime} from 'luxon'


Vue.mixin({
    // computed: {
    //     mobileview() {
    //         return this.$store.state.mobile
    //     }
    // },
    methods: {
        t(string){
            let search = string
            if ( !!!search)
                return ret
            if ( this.$options.name)
                search = this.$options.name + '.' + string
            let ret = this.$t(search)
            if ( ret == search)
                return this.$t(string)
            else
                return ret
        },
        tc(string, number){
            let search = string
            if ( !!!search)
                return ret
            if ( this.$options.name)
                search = this.$options.name + '.' + string
            return this.$tc(search, number)
        },
        isMobile() {
            return screen.width < 768;
        },
        capitalize(string){
            if (!string)
                return ''

            return string.charAt(0).toUpperCase() + string.slice(1).replace(/_/g, ' ');
        },
        capitalizeAll(string){
            if (!string)
                return ''

            return string.toLowerCase().replace(/_/g, ' ')
                    .split(' ')
                    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
                    .join(' ');
        },
        datetimeFromISO (string) {
            const datetime = objDateTime.fromISO(string).toUTC()
            return datetime.isValid ? datetime : null
        },
        toLocaleString(dt){
            if ( dt)
                return dt.toLocal().toLocaleString(objDateTime.DATETIME_MED)
            else
                return ' - ' 
        }

    },

})
  