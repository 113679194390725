import Vue from 'vue';

export default {
  setRouterAllowed(state, items) {
    state.routerList = items;
  },
  setDNSAllowed(state, items) {
    state.dnsList = items;
  },

}