/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'device-other': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path pid="0" d="M25.364 10.182a4.364 4.364 0 00-2.91-4.102V4.364A4.364 4.364 0 0018.092 0h-5.818a4.364 4.364 0 00-4.364 4.364V6.08A4.364 4.364 0 005 10.182v11.636a4.364 4.364 0 002.91 4.102v1.716A4.364 4.364 0 0012.272 32h5.818a4.364 4.364 0 004.364-4.364V25.92a4.364 4.364 0 002.909-4.102V16c.803 0 1.454-.651 1.454-1.455v-2.909c0-.803-.651-1.454-1.454-1.454zM12.273 2.909h5.818c.803 0 1.454.651 1.454 1.455v1.454h-8.727V4.364c0-.804.651-1.455 1.455-1.455zm5.818 26.182h-5.818a1.455 1.455 0 01-1.455-1.455v-1.454h8.727v1.454c0 .804-.65 1.455-1.454 1.455zm4.364-7.273c0 .804-.652 1.455-1.455 1.455H9.364a1.455 1.455 0 01-1.455-1.455V10.182c0-.804.651-1.455 1.455-1.455H21c.803 0 1.455.651 1.455 1.455v11.636z" _fill="#0E1317" fill-rule="nonzero"/>'
  }
})
