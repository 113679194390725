<template>
  <b-container fluid :id="containerId" class="mobile-no-padding mobile-fixed-height p-0">
    <page-title-card
      :page-title="t(`${serviceName} Configuration`)"
    >
      <template #button>
        <n-button
          @click.native="handleNew"
          type="primary"
          :visible_for_permissions='createButtonPermissions'
          size="md"
          round
          block
        >
          <i slot="label"><svgicon class="icon" icon="icon-new" /></i>
          {{t('New')}}
        </n-button>
      </template>
    </page-title-card>
    <b-card no-body>
      <b-card-body style="padding: 0px;">
        <paginated-table :rows='rows'
                          :columns='visibleCols'
                          :actions='actions'
                          :search="['rule_name']"
                          :editingRow='editingRow'
                          :draggable="permission_granted"
                          ref="paginatedTable"
                          @item-edit='handleEdit'
                          @status-change='handleStatusChange'
                          @item-delete='handleDelete'
                          @edit-save='handleChange($event)'
                          @edit-cancel='handleCancel'
                          @handle-move='handleMove'
                          @handle-while-move='handleWhileMove'
                          @handle-dragged-to-first-in-page='handleFirstInPage'
                          @handle-dragged-to-last-in-page='handleLastInPage'
                          @handle-start-drag='handleStartDrag'
        />
      </b-card-body>

      <template v-slot:footer>
          <div class="hr-grey mb-2"/>
          <div @click="getRowsData" v-if="!updating" style="cursor: pointer;display: inline;">
              <i class="now-ui-icons arrows-1_refresh-69" />
              {{ t('Update now') }}
          </div>
          <div v-else>
              <i class="now-ui-icons loader_refresh spin"/>
              {{ t('Updating') }}
          </div>
      </template>

    </b-card>
  </b-container>
</template>

<script>
  import PaginatedTable from '../Tables/PaginatedTable';
  import _ from 'lodash';
  import permissions from '@/mixins/permissions'
  import PageTitleCard from "../AsmConfigurations/PageTitleCard";

  export default {
    name: 'service-policies',
    mixins: [permissions],
    components: {
      PaginatedTable,
      PageTitleCard
    },
    data() {
      return {
        query: { sort: 'asc'},
        actions: {
          minWidth: 170,
          label: this.t('actions'),
          fixed: 'right',
          items: [
            {
              type: 'warning', icon: 'now-ui-icons business_badge',
              event: 'item-edit', action: this.t('edit'), visible_for_permissions: [{actions: ["PATCH"], path: "/subsystems/ns/isp-strategy"}],
              visibleIf: (row) => !row['system']
            },
            {
              type: 'danger', size: 'sm', icon: 'now-ui-icons ui-1_simple-remove',
              action: this.t('delete'), confirm: true, event: 'item-delete', visible_for_permissions: [{actions: ["DELETE"], path: "/subsystems/ns/isp-strategy"}],
              visibleIf: (row) => !row['system']
            },
          ],
          editItems: [
            {
              type: 'success', icon: 'now-ui-icons business_badge',
              event: 'edit-save', action: this.t('save')
            },
            {
              type: 'danger', size: 'sm', icon: 'now-ui-icons ui-1_simple-remove',
              action: this.t('cancel'), event: 'edit-cancel'
            },
          ],
        },
        editingRow: null,
        updating: false,
        oldIndex: -1,
        newIndex: -1,
        currentDragPage: 0
      }
    },
    props: {
      iconName: {
        type: String,
        default: '',
        required: true
      },
      iconType: {
        type: String,
        default: 'svgicon',
      },
      containerId: {
        type: String,
        default: '',
        required: true
      },
      columns: {
        type: Array,
        default: () => [],
        required: true
      },
      storeModuleName: {
        type: String,
        default: '',
        required: true
      },
      serviceName: {
        type: String,
        default: '',
        required: true
      },
      // In case service name and service path are different (Ex: AutoNotices - Activation)
      servicePath: {
        type: String
      },
      createButtonPermissions: {
        type: Array,
        default: () => []
      },
      editionPath: {
        type: String,
        default: () => '/ns'
      },
      storePath: {
        type: String,
        default: () => 'networksecure'
      }
    },
    computed: {
      rows: {
        get() {
          // dispatch getAdsFreeRulesData / getAntiBotnetRulesData / getAntiPhishingRulesData / getAntiVirusRulesData etc
          this.$store.dispatch(`${this.storePath}/${this.storeModuleName}/get${this.capitalizedModuleName}RulesData`);
          const rows =  _.cloneDeep(this.$store.getters[`${this.storePath}/${this.storeModuleName}/get${this.capitalizedModuleName}Rules`]);
          if (this.capitalizedModuleName == 'ContentFilter') {
            this.renderContentFilterRows(rows);
          };
          return rows;
        },
        set() {}
      },
      visibleCols: {
        get() {
          let dataByCol = this.getVisibilityColsObject(this.getDataByCols())
          let filteredColumns = [];
          for (const column of this.columns)
            if (!dataByCol[column.prop]) filteredColumns.push(column);

          return filteredColumns;
        }
      },
      capitalizedModuleName: {
        get() {
          return this.storeModuleName.charAt(0).toUpperCase() + this.storeModuleName.slice(1);
        }
      },
      servicePathComputed() {
        return this.servicePath || this.serviceName;
      }
    },
    methods: {
      handleDelete(data) {
        this.$store.dispatch(`${this.storePath}/${this.storeModuleName}/deleteRule`, data.row);
      },
      handleNew() {
        this.$router.push({path: `${this.editionPath}/${this.servicePathComputed}/Policies/Create/`});
      },
      handleEdit(data) {
        /* Uncomment this line for allow editable row in table */
        // this.editingRow = data;
        this.$router.push({path: `${this.editionPath}/${this.servicePathComputed}/Policies/Edit/${data.row.rule_name}`});
      },
      handleCancel(data) {
        this.editingRow = null;
      },
      async getRowsData() {
        try {
          this.updating = true;
          await this.$store.dispatch(`${this.storePath}/${this.storeModuleName}/get${this.capitalizedModuleName}`)
        }
        catch(e) {
          console.error('Error getting rows data--> ', e)
        }
        this.updating = false
      },
      handleChange(updatedRow) {
        if (this.editingRow.row.rule_name === updatedRow.rule_name) {
          this.$store.dispatch(`${this.storePath}/${this.storeModuleName}/update${this.capitalizedModuleName}Rule`, updatedRow);
          this.editingRow = null;
        }
      },
      handleMove(data) {
        if (!data.search && this.oldIndex !== this.newIndex) {
          const startDragPage = 0;
          let updatedRule = _.cloneDeep(this.rows[this.oldIndex]);

          if (data.newIndex > (this.rows.length - 1)) {
            data.newIndex = this.rows.length - 1
          }

          const afterRule = this.rows[data.newIndex];
          updatedRule.order = this.oldIndex > data.newIndex ? afterRule.order : afterRule.order + 1;

          let arr = this.move_rows(this.rows, this.oldIndex, data.newIndex);
          arr.map((el, i) => el.order = i+1);
          this.rows = arr;

          this.$store.dispatch(`${this.storePath}/${this.storeModuleName}/update${this.capitalizedModuleName}Rule`, updatedRule);
        }
        if (data.reset) {
          this.oldIndex = -1;
          this.newIndex = -1;
          this.currentDragPage = 0;
        } else {
          this.oldIndex = data.newIndex
        }
      },
      move_rows(arr, old_index, new_index) {
        if (new_index >= arr.length) {
            var k = new_index - arr.length + 1;
            while (k--) {
                arr.push(undefined);
            }
        }
        arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
        return arr;
      },
      handleWhileMove(data) {
        if (!data.search) {
          // console.log("Handle_WHILE_MOVE \n old: ", data.oldIndex + " - new: " + data.newIndex);
          if (this.oldIndex == -1 ) {
            this.oldIndex = data.oldIndex;
          }
          this.newIndex = data.newIndex;
        }
      },
      handleFirstInPage(data) {
        if (data.hasOwnProperty('lastAndUnique') && data.lastAndUnique) {
          /* When try to drag the last element and it is unique on the page, the previous page is loaded automatically,
            so we don't need to wait for delay and load page manually, just move the element to previous page to handle it */
          let oldIndexInNewPage = this.oldIndex - data.perPage;
          // Move element to new page to avoid weird visual behaviour
          this.handleMove({ newIndex: oldIndexInNewPage, oldIndex: this.oldIndex, search: null, reset: false })
        } else {
          let tempNewIndex = this.newIndex;
          let tempOldIndex = this.oldIndex;
          let delayInMilliseconds = 2000;
          setTimeout(() => {
            // console.log("handleFirstInPage old: ", tempOldIndex + " - " + this.oldIndex);
            // console.log("handleFirstInPage new: ", tempNewIndex + " - " + this.newIndex);
            if (tempNewIndex == this.newIndex && tempOldIndex == this.oldIndex) {
              this.$refs.paginatedTable.loadPrevPage();

              // move currentDragPage
              this.currentDragPage -= 1;

              // Move element to new page to avoid weird visual behaviour
              let oldIndexInNewPage = this.oldIndex - data.perPage;
              this.handleMove({ newIndex: oldIndexInNewPage, oldIndex: this.oldIndex, search: null, reset: false })
            }
          }, delayInMilliseconds);
        }
      },
      handleLastInPage(data) {
        let tempNewIndex = this.newIndex;
        let tempOldIndex = this.oldIndex;
        let delayInMilliseconds = 2000;
        setTimeout(() => {
          // console.log("handleLastInPage old: ", tempOldIndex + " - " + this.oldIndex);
          // console.log("handleLastInPage new: ", tempNewIndex + " - " + this.newIndex);
          if (tempNewIndex == this.newIndex && tempOldIndex == this.oldIndex) {
            this.$refs.paginatedTable.loadNextPage();

            // move currentDragPage
            this.currentDragPage += 1;

            // Move element to new page to avoid weird visual behaviour
            let oldIndexInNewPage = this.oldIndex + data.perPage;
            this.handleMove({ newIndex: oldIndexInNewPage, oldIndex: this.oldIndex, search: null, reset: false })
          }
        }, delayInMilliseconds);
      },
      handleStartDrag(data) {
        // console.log("StartDrag", data);
        this.oldIndex = data.oldIndex;
        if ((!data.search) &&
          (data.oldIndex + 1) == this.rows.length &&
          (data.oldIndex + 1) > data.perPage &&
          (data.oldIndex + 1) % data.perPage == 1) {
            this.oldIndex = data.oldIndex;
            this.$refs.paginatedTable.loadPrevPage();
        }
      },
      handleStatusChange(updatedRow) {
        this.$store.dispatch(`${this.storePath}/${this.storeModuleName}/update${this.capitalizedModuleName}RuleStatus`, updatedRow);
      },
      getDataByCols() {
        const cols = this.uniqueKeys(this.rows);
        let dataByCol = {};
        cols.map(col => {
          this.rows.map(row => {
            if (dataByCol[col]) dataByCol[col].push(row[col])
            else dataByCol[col] = [row[col]];
          });
        });

        return dataByCol;
      },
      getVisibilityColsObject(dataByCol) {
        for (const colName in dataByCol) {
          if (colName === 'schedule')
            dataByCol[colName] = dataByCol['schedule'].every(s => s === 'always');
          else if (dataByCol.hasOwnProperty(colName))
            dataByCol[colName] = dataByCol[colName].flat().length === 0;
        }

        return dataByCol;
      },
      uniqueKeys(object) {
        if (object.length === 0) return [];
        const clonedObject = _.cloneDeep(object)
        return Object.keys(clonedObject.reduce((result, obj) => {
          return Object.assign(result, obj);
        }));
      },
      renderContentFilterRows(rows) {
        rows.forEach(row => {
          row.advanced = row.advanced.map(advancedOption => ({
            type: advancedOption.type,
            value: this.t(`contentFilter-edit.${advancedOption.value}`)
          }));
        });
      }
    },
    created() {
      this.getRowsData();
    }

  }
</script>

<style scoped>
.container {
  margin-top: 3em;
}
</style>
