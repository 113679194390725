import { DEFAULT_BLOCKING_PAGE_TEXTS } from "@/util/languageUtils";

export default () => ({
    ent: {
        app_name: 'IoTSecure',
        company_name: 'Allot',
        company_url: 'http://www.allot.com',
        image_background: null,
        image_footer: null,
        image_logo: null,
        image_favicon: null,
        show_footer_text: true,
        show_footer_image: true,
        env_options: {
            visibility: {
                acc_show_add_users_button: true,
            }
        }
    },
    acc: {
        app_name: 'AllotSecure',
        company_name: 'Allot',
        company_url: 'http://www.allot.com',
        image_background: null,
        image_logo: null,
        image_favicon: null,
        show_footer_text: true,
        env_options: {
            fonts: {
                headlines: null,
                body: null,
            },
            colors: {
                primary: '#F18A13',
                secondary: '#775ED4',
                graphs: {
                    color1: '#0077AA',
                    color2: '#9A489B',
                    color3: '#FEDA00',
                    color4: '#E30613',
                    color5: '#3AAB96',
                    color6: '#FF7F00',
                },
            },
            navigation: 'dark',
            visibility: {
                acc_show_add_users_button: true,
            },
            blocking_pages: DEFAULT_BLOCKING_PAGE_TEXTS
        },
    },
    isp: {
        app_name: 'AllotSecure',
        company_name: 'Allot',
        company_url: 'http://www.allot.com',
        image_background: null,
        image_footer: null,
        image_logo: null,
        image_favicon: null,
        show_footer_text: true,
        show_footer_image: true,
        env_options: {
            visibility: {
                acc_show_add_users_button: true,
            }
        }
    },
    defaultAccCustomization: {
        colors: {
            primary: '#F18A13',
            secondary: '#775ED4',
            graphs: {
                color1: '#0077AA',
                color2: '#9A489B',
                color3: '#FEDA00',
                color4: '#E30613',
                color5: '#3AAB96',
                color6: '#FF7F00',
            },
        },
        fonts: {
            headlines: {
              font_family: 'AmpleSoft Pro Regular',
              font_id: 'AmpleSoft Pro Regular'
            },
            body: {
              font_family: 'Museo Sans 700',
              font_id: 'Museo Sans 700',
            },
        },
    },
    fonts: [],
    uploadedFont: null,
})
