import * as queries from './queries';

const commitSessionManagement = (commit, type, sessions_management) => {
  const types = {isp: "isp", ent: "ent", res: "res"}
  if(types[type]){
    commit(`SET_${type.toUpperCase()}_SESSIONS_MANAGEMENT`,sessions_management)
  } else {
    throw new Error(`Unknown gateway: ${type}`);
  }
}

export async function getSessionsManagement({ dispatch, commit, rootGetters }, type) {
  if (!rootGetters['settings/getDebug']) {
    try {
      const { sessions_management } = await queries.getConfiguration(type);

      commitSessionManagement(commit, type, sessions_management)
    } catch (err) {
      dispatch('setError', err, { root: true });
    }
  }
}

export async function setSessionsManagement(
  { dispatch, commit, rootGetters },
  { type, payload },
) {

  const isPasswordRotation = !!(
    payload.historyCheck
    || payload.maxPassPeriod
    || payload.minPassPeriod
    || payload.mustChange
  );

  const sessions_management = {
    max_sessions: payload.maxSessions,
    refresh_token_timeout: payload.expirationTime,
    access_token_timeout: 60,
    password_policy: {
      max_incorrect_password_attempts: payload.maxIncorrectAttempts,
      lock_time: payload.lockTime,
    },
    ...(isPasswordRotation && {
      password_rotation: {
        history_check_depth: payload.historyCheck,
        maximum_elapsed_time_without_change: payload.maxPassPeriod,
        minimum_elapsed_time_to_change: payload.minPassPeriod,
        rotate_on_first_login: payload.mustChange,
      }
    }),
  };

  if (!rootGetters['settings/getDebug']) {
    try {
      await queries.setConfiguration(type, {sessions_management});
      commitSessionManagement(commit, type, sessions_management)
    } catch (err) {
      dispatch('setError', err, { root: true });
    }
  } else {
    commitSessionManagement(commit, type, sessions_management)
  }
}
