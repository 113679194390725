import _ from 'lodash';
import axios from "axios";
import i18n from '../../../i18n';
import { createManager, mockManager, mockManager2 } from './mockData';

export default {
  removeAccountManager({ commit, state, rootState, dispatch }, data) {
    if (rootState.settings.debug) {
      return new Promise((resolve, reject) => {
        commit('removeAccountManager', data.id)
        resolve([])
      });
    }
    else {
      return new Promise((resolve, reject) => {
        axios.delete('/accounts/' + encodeURIComponent(data.account_id) + '/managers/' + encodeURIComponent(data.id))
          .then(res => {
            commit('removeAccountManager', data.id);
            resolve(state.account_managers)
          })
          .catch(error => {
            commit('setError', error, { root: true });
            reject()
          })
      });
    }
  },
  getSSOAccountManager({ commit, state, dispatch }, { entity, id, type }) {
    if (!['ent', 'res'].includes(type)) {
      if (type == 'enterprise_groups')
        type = 'ent'
      else if (type == 'residential' || type == 'business')
        type = 'res'
      else {
        return new Promise((resolve, reject) => {
          commit('setError', `${i18n.t('incorrect_account_type')}: ${type}`, { root: true })
          reject()
        });
      }
    }
    return new Promise((resolve, reject) => {
      axios.get('/customer-support/log-as/sso/jwt-link/' + entity + '/' + encodeURIComponent(id), { params: { type: type } })
        .then(res => {
          try {
            resolve(res.data.sso_cs_url)
          } catch (err) {
            reject()
          }
        })
        .catch(error => {
          commit('setError', error, { root: true });
          reject()
        })
    });
  },
  getAccountManagers({ commit, state, rootState, dispatch }, id) {
    if (rootState.settings.debug) {
      return new Promise((resolve, reject) => {
        let accounts = [mockManager(id)]
        resolve(accounts)
      })
    }
    else {
      return new Promise((resolve, reject) => {
        axios.get('/accounts/' + encodeURIComponent(id) + '/managers')
          .then(res => {
            if (res && res.data && res.data.items) {
              state.account_managers.clear()
              res.data.items.forEach(item => {
                const manager = createManager(item)
                if (manager) {
                  commit('addAccountManager', manager);
                } else {
                  console.log("user_id not found for manager " + item.manager_id);
                  commit('setError', i18n.t('manager_not_found'), { root: true });
                }
              });
              resolve(state.account_managers)
            }
            else {
              reject()
            }
          })
          .catch(error => {
            commit('setError', error, { root: true });
            reject()
          })
      });

    }
  },
  getAccountManager({ commit, state, rootState, dispatch }, data) {
    if (rootState.settings.debug) {
      return new Promise((resolve, reject) => {
        const manager = mockManager2(data)
        commit('addAccountManager', { id: admin.manager_id, data: admin })
        resolve(manager)
      })
    }
    else {
      return new Promise((resolve, reject) => {
        axios.get('/accounts/' + encodeURIComponent(data.account_id) + '/managers/' + encodeURIComponent(data.manager_id))
          .then(res => {
            if (res && res.data) {
              let item = res.data
              const manager = createManager(item)
              if (manager) {
                commit('addAccountManager', manager)
                resolve(manager.data)
              } else {
                console.log("user_id not found for manager " + data.manager_id)
                commit('setError', i18n.t('manager_not_found'), { root: true });
              }
            }
            else {
              reject()
            }
          })
          .catch(error => {
            commit('setError', error, { root: true });
            reject()
          })
      });
    }
  },
  updateAccountManager({ commit, state, dispatch }, update) {
    let manager_data = _.cloneDeep(update.data)
    if (manager_data.identity) {
      if (manager_data.identity.identity_instances) {
        manager_data.identity.identity_instances = [manager_data.identity.identity_instances]
      }
    }
    return new Promise((resolve, reject) => {
      axios.patch('/accounts/' + encodeURIComponent(update.account_id) + '/managers/' + encodeURIComponent(update.id), manager_data)
        .then(res => {
          commit('setAccountManager', { id: update.id, data: update.data });
          resolve(res)
        })
        .catch(error => {
          reject(error)
        })
    });
  },
  removeManager({ commit, state, rootState, dispatch }, data) {
    if (rootState.settings.debug) {
      return new Promise((resolve, reject) => {
        commit('removeManager', data.id)
        resolve([])
      });
    }
    else {
      return new Promise((resolve, reject) => {
        axios.delete('/accounts/' + encodeURIComponent(data.account_id) + '/managers/' + encodeURIComponent(data.id))
          .then(res => {
            commit('removeManager', data.id);
            resolve(state.managers)
          })
          .catch(error => {
            commit('setError', error, { root: true });
            reject()
          })
      });
    }
  },
  createAccountManager({ commit, state, dispatch }, data) {
    return new Promise((resolve, reject) => {
      data.data.account_id = data.account_id
      axios.post('/accounts/' + encodeURIComponent(data.account_id) + '/managers', data.data)
        .then(res => {
          if (res && res.data) {
            let item = res.data
            const manager = createManager(item)
            commit('addAccountManager', manager)
            commit('setSuccess', i18n.t('save_success'), { root: true })
            resolve(item.manager_id)
          }
        })
        .catch(error => {
          reject(error.response && error.response.data
            ? error.response.data
            : error);
        })
    });
  },
  getManagers({ commit, state, rootState, dispatch }, { wait: wait, query: query, type: type }) {
    if (rootState.settings.debug) {
      return new Promise((resolve, reject) => {
        let accounts = [mockManager("mockid")]
        resolve(accounts)
      })
    }
    else {
      let config = { params: query }
      config.params.type = type
      if (wait)
        config.headers = { 'x-wait-on-loading': wait }

      return new Promise((resolve, reject) => {
        axios.get('/managers', config)
          .then(res => {
            if (res && res.data && res.data.items) {
              state.managers.clear()
              res.data.items.forEach(item => {
                const manager = createManager(item)
                if(manager){
                  commit('addManager', manager)
                } else {
                  console.error("user_id not found for manager " + item.manager_id)
                  commit('setError', i18n.t('manager_not_found'), { root: true });
                }
              });
              let ret = { items: state.managers, query: res.data.query, self: query }
              resolve(ret)
            }
            else {
              reject()
            }
          })
          .catch(error => {
            commit('setError', error, { root: true });
            reject()
          })
      });

    }
  },
}