<template>
  <div class="form-check"
       :class="[{disabled: !permission_granted || disabled}, inlineClass]">
    <label :for="cbId" class="form-check-label">
      <span v-if="title" :class="{'disabled-title': disabled}">{{title}}</span>
      <input :id="cbId"
             class="form-check-input"
             type="checkbox"
             :disabled="!permission_granted || disabled"
             v-model="model"/>
      <span class="form-check-sign"></span>
      <slot>
        <span v-if="inline">&nbsp;</span>
      </slot>
    </label>
  </div>
</template>
<script>
import permissions from '@/mixins/permissions'
export default {
  name: 'n-checkbox',
  mixins: [permissions],
  model: {
    prop: 'checked'
  },
  props: {
    checked: [Array, Boolean],
    disabled: Boolean,
    inline: Boolean,
    hasError: Boolean,
    title: ""
  },
  data() {
    return {
      cbId: '',
      touched: false
    };
  },
  computed: {
    model: {
      get() {
        return this.checked;
      },
      set(check) {
        if (!this.touched) {
          this.touched = true;
        }
        this.$emit('input', check);
      }
    },
    inlineClass() {
      if (this.inline) {
        return `form-check-inline`;
      }
    }
  },
  created() {
    this.cbId = Math.random()
      .toString(16)
      .slice(2);
  }
};
</script>
<style scoped lang=scss>
.form-check-label input:checked ~ .form-check-sign {
  background-color: #f96332;
}
.form-check-label input:disabled ~ .form-check-sign {
  background-color: #D3D5DA;
}
.disabled-title {
  color:#BFC2C2;
}
</style>
