/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icon-filteringlogconfig': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path pid="0" d="M25.56 13.664a7.735 7.735 0 00-3.786-1.38 10.9 10.9 0 01-7.736 11.055 7.785 7.785 0 1011.522-9.667v-.008zM6.426 19.269a7.748 7.748 0 003.787 1.378 10.897 10.897 0 017.735-11.053A7.783 7.783 0 007.602 5.831a7.783 7.783 0 00-1.176 13.43v.008zM4.64 21.823a10.846 10.846 0 006.228 1.972c2.075 5.65 8.338 8.548 13.988 6.474 5.65-2.075 8.55-8.337 6.474-13.988A10.9 10.9 0 0021.13 9.14C19.05 3.491 12.788.595 7.138 2.673 1.49 4.753-1.405 11.017.673 16.666a10.899 10.899 0 003.966 5.157z" _fill="#000" fill-rule="evenodd"/>'
  }
})
