export default {
    black_list(state) {
        return state.forbiddenAllowedList.black_list;
    },
    white_list(state) {
        return state.forbiddenAllowedList.white_list;
    },
    params_query: (state) => (action) => {
        return state.params_query[action]
    },
    params_self_query: (state) => (action) => {
        return state.params_self_query[action]
    },
}