/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'license-alert': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<defs><path pid="0" d="M16 20.91a1.455 1.455 0 11-.001 2.91 1.455 1.455 0 010-2.91zm0-10.182c.803 0 1.454.651 1.454 1.454V18a1.455 1.455 0 01-2.909 0v-5.818c0-.803.652-1.454 1.455-1.454zm12.894 15.059c-.333.589-.982.94-1.735.94H4.839c-.753 0-1.402-.351-1.733-.94a1.413 1.413 0 01.025-1.48L14.289 5.808c.679-1.126 2.742-1.126 3.42 0l11.16 18.5c.387.64.182 1.202.025 1.479m2.467-2.982l-11.16-18.5C19.332 2.86 17.76 2 16 2c-1.76 0-3.33.861-4.2 2.304l-11.16 18.5a4.325 4.325 0 00-.067 4.413c.842 1.494 2.479 2.42 4.268 2.42h22.319c1.79 0 3.425-.926 4.269-2.42.786-1.39.76-3.04-.067-4.412" id="svgicon_license-alert_a"/></defs><use _fill="#000" fill-rule="nonzero" xlink:href="#svgicon_license-alert_a"/>'
  }
})
