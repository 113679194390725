import _ from 'lodash';
import axios from "axios";
import i18n from '../../../i18n';
import { createDevice, mockDevice, mockDeviceRS } from './mockData';

export default {
  getAccountDevices({ commit, state, rootState, dispatch }, {id: id, query: query}) {
    let config = { params: query }
    if (rootState.settings.debug) {
      return new Promise((resolve, reject) => {
        let device = mockDevice()        
        resolve([device])
      })
    }
    else {
      return new Promise((resolve, reject) => {
        axios.get('/accounts/' + encodeURIComponent(id) + '/devices', config)
          .then(res => {
            if (res && res.data && res.data.items) {
              state.account_devices.clear()
              res.data.items.forEach(item => {
                const device = createDevice(item)

                commit('addAccountDevice', device)
              });
              resolve({ items: state.account_devices, query: res.data.query, self: query })
            }
            else {
              reject()
            }
          })
          .catch(error => {
            commit('setError', error, { root: true });
            reject()
          })
      });

    }
  },
  removeAccountDevice({ commit, state, rootState, dispatch }, data) {
    if (rootState.settings.debug) {
      return new Promise((resolve, reject) => {
        commit('removeAccountDevice', data.id)
        resolve([])
      });
    }
    else {
      return new Promise((resolve, reject) => {
        axios.delete('/accounts/' + encodeURIComponent(data.account_id) + '/devices/' + encodeURIComponent(data.id))
          .then(res => {
            commit('removeAccountDevice', data.id);
            resolve(state.account_devices)
          })
          .catch(error => {
            commit('setError', error, { root: true });
            reject()
          })
      });
    }
  },
  getAccountUserDevices({ commit, state, rootState, dispatch }, data) {
    if (rootState.settings.debug) {
      return new Promise((resolve, reject) => {
        const device = mockDevice(data)
        resolve([device])
      })
    }
    else {
      return new Promise((resolve, reject) => {
        axios.get('/accounts/' + encodeURIComponent(data.account_id) + '/devices', { params: { limit: 50 } })
          .then(res => {
            if (res && res.data && res.data.items) {
              state.account_devices.clear()
              res.data.items.forEach(item => {
                const device = createDevice(item)

                commit('addAccountDevice', device)
              });
              let userDevices = [...state.account_devices].filter((item, key) => item[1].user_id == data.user_id);
              resolve(new Map(userDevices))
            }
            else {
              reject()
            }
          })
          .catch(error => {
            commit('setError', error, { root: true });
            reject()
          })
      });

    }
  },
  removeAccountDeviceUser({ commit, state, rootState, dispatch }, data) {
    if (rootState.settings.debug) {
      return new Promise((resolve, reject) => {
        commit('removeAccountDevice', data.id)
        resolve([])
      });
    }
    else {
      return new Promise((resolve, reject) => {
        axios.delete('/accounts/' + encodeURIComponent(data.account_id) + '/devices/' + encodeURIComponent(data.id))
          .then(res => {
            commit('removeAccountDevice', data.id);
            let userDevices = [...state.account_devices].filter((item, key) => item[1].user_id == data.user_id);
            resolve(new Map(userDevices))
          })
          .catch(error => {
            commit('setError', error, { root: true });
            reject()
          })
      });
    }
  },
  getDevices({ commit, state, rootState, dispatch }, { wait: wait, query: query }) {
    if (rootState.settings.debug) {
      return new Promise((resolve, reject) => {
        const device = mockDevice()
        state._devices.clear()
        commit('addDevice', device)
        let ret = { items: state._devices, query: query, self: query }
        resolve(ret)
      })
    }
    else {
      let config = { params: query }
      if (wait)
        config.headers = { 'x-wait-on-loading': wait }

      return new Promise((resolve, reject) => {
        axios.get('/devices', config)
          .then(res => {
            if (res && res.data && res.data.items) {
              state._devices.clear()
              res.data.items.forEach(item => {
                if (!item.product_data)
                  item.product_data = {};
                ['EndpointSecure', 'HomeSecure', 'NetworkSecure', 'DNSecure'].forEach(product => {
                  if (!item.product_data[product]) {
                    item.product_data[product] = {
                      provisioned: false
                    }
                  }
                });
                let device = {
                  id: item.device_id,
                  data: item
                };

                commit('addDevice', device)
              });
              let ret = { items: state._devices, query: res.data.query, self: query }
              resolve(ret)
            }
            else {
              reject()
            }
          })
          .catch(error => {
            commit('setError', error, { root: true });
            reject()
          })
      });

    }
  },
  removeDevice({ commit, state, rootState, dispatch }, data) {
    if (rootState.settings.debug) {
      return new Promise((resolve, reject) => {
        commit('removeDevice', data.id)
        resolve([])
      });
    }
    else {
      return new Promise((resolve, reject) => {
        axios.delete('/accounts/' + encodeURIComponent(data.account_id) + '/devices/' + encodeURIComponent(data.id))
          .then(res => {
            commit('removeDevice', data.id);
            resolve(state._devices)
          })
          .catch(error => {
            commit('setError', error, { root: true });
            reject()
          })
      });
    }
  },
  updateAccountDevice({ commit, state, dispatch }, update) {
    let prev = null
    let device = state.account_devices.get(update.id)
    if (device) {
      prev = _.cloneDeep(device)
    }
    commit('setAccountDevice', {id: update.id, data: update.data});
    if ( update.data.device_id)
      delete update.data.device_id
    if (update.data.product_data && update.data.product_data.EndpointSecure) {
      update.data.product_data.EndpointSecure.av_scan !== undefined && delete update.data.product_data.EndpointSecure.av_scan;
      update.data.product_data.EndpointSecure.av_scan_last_result !== undefined && delete update.data.product_data.EndpointSecure.av_scan_last_result;
      update.data.product_data.EndpointSecure.device_connectivity !== undefined && delete update.data.product_data.EndpointSecure.device_connectivity
      update.data.product_data.EndpointSecure.installed_apps !== undefined && delete update.data.product_data.EndpointSecure.installed_apps
    }
    if (update.data.product_data && update.data.product_data.CBEndpoint) {
      update.data.product_data.CBEndpoint = {
        provisioned : update.data.product_data.CBEndpoint.provisioned
      }    
    }
    return new Promise((resolve, reject) => {
      axios.patch('/accounts/' + encodeURIComponent(update.account_id) + '/devices/' + encodeURIComponent(update.id), update.data)
        .then(() => {
          //commit("setSuccess", `Device ${update.id} updated!`, { root: true });
          //commit('setSuccess', i18n.t('save_success'), { root: true });
          resolve();
        })
        .catch(error => {
          const errorType = _.get(error, "response.data.type");
          const typeBasedErrors = [
            "ACCOUNT.IPS_ALREADY_EXISTS",
            "DEVICE.ALREADY_EXISTS",
			"DEVICE.MISSING_SUBSCRIBER_INFO"
          ];
          if(typeBasedErrors.includes(errorType)){
            error = i18n.t(error.response.data.type)
          }
          else if (errorType === "DEVICE.INVALID_INPUT") {
            error = i18n.t(error.response.data.title);
          }
          commit('setError', error, { root: true });
          commit('setAccountDevice', { id: update.id, data: prev });
          reject();
        })
    });
  },
  partialUpdateAccountDevice({ commit, state }, update) {
    let device = _.merge(state.account_devices.get(update.device_id), update);

    axios.patch('/accounts/' + encodeURIComponent(device.account_id) + '/devices/' + encodeURIComponent(device.device_id), update)
      .then(() => {
        commit('setAccountDevice', { id: update.device_id, data: device });
        commit("setSuccess", `Device ${device.device_id} updated!`, { root: true });
        commit('setSuccess', i18n.t('save_success'), { root: true });
      })
      .catch(error => {
        const errorResponse = _.get(error, "response.data.type")
        if( errorResponse === "ACCOUNT.IPS_ALREADY_EXISTS" || errorResponse === "DEVICE.ALREADY_EXISTS" || errorResponse === "DEVICE.MISSING_SUBSCRIBER_INFO"){
          error = i18n.t(error.response.data.type)
        }
        commit('setError', error, { root: true });
      })
  },
  getAccountDevice({ commit, state, rootState, dispatch }, data) {
    if (rootState.settings.debug) {
      return new Promise((resolve, reject) => {
        let device = mockDevice (data)
        resolve(device)
      })
    }
    else {
      return new Promise((resolve, reject) => {
        axios.get('/accounts/' + encodeURIComponent(data.account_id) + '/devices/' + encodeURIComponent(data.device_id))
          .then(res => {
            if (res && res.data) {
              let item = res.data
              //if (!item.product_data)
              //  item.product_data = {};
              //['EndpointSecure', 'HomeSecure', 'NetworkSecure', 'DNSecure'].forEach(product => {
              //  if (!item.product_data[product]) {
              //    item.product_data[product] = {
              //      provisioned: false
              //    }
              //  }
              //});                            

              let device = {
                id: item.device_id,
                data: item
              };

              commit('addAccountDevice', device)
              resolve(device.data)
            }
            else {
              reject()
            }
          })
          .catch(error => {
            commit('setError', error, { root: true });
            reject()
          })
      });
    }
  },
  createAccountDevice({ commit, state, dispatch }, data) {
    return new Promise((resolve, reject) => {
      data.data.account_id = data.account_id
      
      axios.post('/accounts/' + encodeURIComponent(data.account_id) + '/devices', data.data)
        .then(res => {
          if (res && res.data) {
            let item = res.data
            const device = createDevice(item)

            commit('addAccountDevice', device)
            commit('setSuccess', i18n.t('save_success'), { root: true })
            commit('setSuccess', i18n.t('save_success'), { root: true })
            resolve(item.device_id)
          }
        })
        .catch(error => {
          if (error.response.data.type){
            switch(error.response.data.type){
              case 'ACCOUNTS.EXCEEDED_MAX_ROUTERS_PER_ACCOUNT':
              case 'DEVICE.LIMIT_EXCEEDED':
                commit('setError', i18n.t(error.response.data.type), { root: true });
                break;
              case 'DEVICE.INVALID_INPUT':
                commit('setError', i18n.t(error.response.data.type), { root: true });
                break;
              case 'DEVICE.MAC_ALREADY_EXISTS':
                commit('setError', i18n.t(error.response.data.type), { root: true });
                break;
			  case 'DEVICE.MISSING_SUBSCRIBER_INFO':
                commit('setError', i18n.t(error.response.data.type), { root: true });
                break;
              default:
                commit('setError', error, { root: true });
            }
          }
          reject()
        })
    });
  } 
}
