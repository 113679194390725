/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'device-iphone': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path pid="0" d="M22 0a4.364 4.364 0 014.364 4.364v23.272A4.364 4.364 0 0122 32H10.364A4.364 4.364 0 016 27.636V4.364A4.364 4.364 0 0110.364 0zm0 2.91H10.364c-.804 0-1.455.65-1.455 1.454v23.272c0 .804.651 1.455 1.455 1.455H22c.803 0 1.455-.651 1.455-1.455V4.364c0-.804-.652-1.455-1.455-1.455zm-5.818 20.16a1.455 1.455 0 110 2.908 1.455 1.455 0 010-2.909z" _fill="#0E1317" fill-rule="nonzero"/>'
  }
})
