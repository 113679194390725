/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'new-alert': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path pid="0" d="M9.208 7.8a10.223 10.223 0 018.075-2.498c5.2.769 9.024 5.274 8.938 10.53v7.638a7.04 7.04 0 014.045 6.28 1.753 1.753 0 01-1.738 1.767H2.853a1.738 1.738 0 01-1.855-1.694 7.01 7.01 0 014.776-6.602v-7.696A10.223 10.223 0 019.208 7.8zM23.3 25.676H8.082a4.09 4.09 0 00-3.929 2.921h23.075a4.104 4.104 0 00-3.928-2.92zm-2.684-15.88a7.302 7.302 0 00-6.079-1.5 7.478 7.478 0 00-5.842 7.463v6.996H23.3v-7.302c0-2.193-.985-4.27-2.684-5.658zm9.592-.55a1.46 1.46 0 01.76 2.818l-1.461.38a1.46 1.46 0 01-.76-2.818zm-30.159.934a1.46 1.46 0 011.782-1.036l1.46.38a1.46 1.46 0 01-.76 2.818l-1.46-.38A1.46 1.46 0 01.05 10.18zm4.528-8.172c.389 0 .762.156 1.036.432L6.65 3.476a1.46 1.46 0 11-2.073 2.06L3.54 4.497a1.46 1.46 0 011.037-2.491zm21.483 0a1.46 1.46 0 011.037 2.491L26.06 5.535a1.46 1.46 0 11-2.074-2.06l1.037-1.036a1.46 1.46 0 011.037-.432zM15.997 0c.807 0 1.46.654 1.46 1.46v1.46a1.46 1.46 0 11-2.92 0V1.46c0-.806.654-1.46 1.46-1.46z" _fill="#0E1317" fill-rule="evenodd"/>'
  }
})
