import DashboardLayout from "@/layout/dashboard/DashboardLayout.vue";

const AccountUserEdit = () => import(
  /* webpackChunkName: "account-management" */
  "@/pages/Users/AccountUserEdit.vue"
);

export default {
  path: "/account_management/accounts/edit/:account_id/users",
  name: "Account Management / Accounts / Users",
  component: DashboardLayout,
  children: [
    {
      path: "new",
      name: "Account Management / Accounts / User / New",
      component: AccountUserEdit,
      props: true
    },
    {
      path: 'edit/:id',
      name: 'Account Management / Accounts / User / Edit',
      component: AccountUserEdit,
      props: true
    },
  ]
};
