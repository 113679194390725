import axios from "axios";
import i18n from '../../../i18n'

export default {
    getNetworkSecureHosts({ commit, state, dispatch, getters }, { wait: wait, query: query }) {
      let config = { params: query }
      if (wait)
        config.headers = { 'x-wait-on-loading': wait }
  
      return new Promise((resolve, reject) => {
        axios.get('/subsystems/ns/general-status', config)
          .then(res => {
            if (res && res.data && res.data.hosts) {
              commit('setNetworkSecureHosts', res.data.hosts);
              resolve()
            } else {
              reject()
            }
          })
          .catch(error => {
            commit('setError', error, { root: true });
            reject()
          })
      })
    },
    getServiceStatus({ commit, state }) {
      return new Promise((resolve, reject) => {
        axios.get('/subsystems/ns/service-status')
          .then(res => {
            if (res && res.data && res.data.items) {
              commit('setServiceStatus', res.data.items);
              resolve()
            } else {
              reject()
            }
          })
          .catch(error => {
            commit('setError', error, { root: true });
            reject()
          })
      })
    },
    setServiceStatus({ commit, state, dispatch }, payload = {}) {
      return new Promise((resolve, reject) => {
        axios.put(`/subsystems/ns/service-status/${payload.subsystem_service_id}`, payload)
          .then(res => {
            resolve(res);
          })
          .catch(err => {
            commit('setError', err, { root: true });
            reject(err);
          });
      });
    },
    updateServiceLocation({ commit, state, dispatch }, update) {
      return new Promise((resolve, reject) => {
        axios.put('/subsystems/ns/host-locations/' + encodeURIComponent(update.id), update.data)
          .then(res => {
            resolve()
          })
          .catch(error => {
            try{
              commit('setError', i18n.t(error.response.data.type), {root:true});
            }
            catch( err){
                commit('setError', error, {root:true})
            }
            reject()
          })
      });
    },
    getNetworkSecureSnifferMode({ commit, state }) {
      return new Promise((resolve, reject) => {
        axios.get('/subsystems/ns/general-status')
          .then(res => {
            if (res && res.data && typeof res.data.operating_mode_all_sniffer !== 'undefined') {
              commit('setNetworkSecureSnifferMode', res.data.operating_mode_all_sniffer);
              resolve()
            } else {
              commit('setError', i18n.t('cant_obtain_general_status'), {root: true});
              resolve()
            }
          })
          .catch(error => {
            commit('setError', error, { root: true });
            reject()
          })
      })
    },
    setLocalServiceStatus({ commit }, data) {
      commit('setLocalServiceStatus', data)
    }
  };