
import ipRegex from "ip-regex";

export const MACRegExp = new RegExp("^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$")
export const ipv4RegExp = new RegExp("^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$")
export const ipv6RegExp = new RegExp("^s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3}))|:)))(%.+)?s*(/([0-9]|[1-9][0-9]|1[0-1][0-9]|12[0-8]))?$")
export const ipv6InBracketsRegExp = new RegExp("^[\[]+((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3}))|:)))(%.+)?s*(/([0-9]|[1-9][0-9]|1[0-1][0-9]|12[0-8]))?\]$")
export const ipv4v6RegExp = new RegExp("^((?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?))|s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3}))|:)))(%.+)?s*(\/(48|52|56|60|64))?$")
export const tcpUpdRegExp = new RegExp("^(((tcp)|(udp)|(tcp/udp)):)( )?([1-9]|[1-5]?[0-9]{2,4}|6[0-4][0-9]{3}|65[0-4][0-9]{2}|655[0-2][0-9]|6553[0-5])(-([1-9]|[1-5]?[0-9]{2,4}|6[1-4][0-9]{3}|65[1-4][0-9]{2}|655[1-2][0-9]|6553[1-5]))?$")
export const domainRegExp = new RegExp("(https?://(?:www\\.|(?!www))[a-zA-Z0-9ñáéíóúü][a-zA-Z0-9-ñáéíóúü]+[a-zA-Z0-9ñáéíóúü]\\.[^s]{2,}|www\\.[a-zA-Z0-9ñáéíóúü][a-zA-Z0-9-ñáéíóúü]+[a-zA-Z0-9ñáéíóúü]\\.[^s]{2,}|https?://(?:www\\.|(?!www))[a-zA-Z0-9ñáéíóúü]+\\.[^s]{2,}|www\\.[a-zA-Z0-9ñáéíóúü]+\\.[^s]{2,})")
export const urlRegExp = new RegExp("^[a-zA-Z0-9ñáéíóúü][a-zA-Z0-9-ñáéíóúü]{1,61}[a-zA-Z0-9ñáéíóúü](?:\\.[a-zA-Zñáéíóúü]{2,})+")
export const urlWithIpv4HostRegExp = new RegExp("^([0-9]{1,3}\\.){3}[0-9]{1,3}(\\/([0-9]|[1-2][0-9]|3[0-2]))?(:\\d{1,5})?(\\/.*)?$")
export const urlWithIpv6HostRegExp = new RegExp("^\\[((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3}))|:)))(%.+)?(\\/([0-9]|[1-9][0-9]|1[0-1][0-9]|12[0-8]))?\\](:\\d{1,5})?(\\/.*)?$")
export const urlWithProtocolDomainHostRegExp = new RegExp("^(https?://)([\\w\\d]+(-[\\w\\d]+)*\\.)+[\\w]{2,}(:\\d{1,5})?(\\/.*)?$");
export const urlWithProtocolIpv4HostRegExp = new RegExp("^(https?://)([0-9]{1,3}\\.){3}[0-9]{1,3}(\\/([0-9]|[1-2][0-9]|3[0-2]))?(:\\d{1,5})?(\\/.*)?$");
export const urlWithProtocolIpv6HostRegExp = new RegExp("^(https?://)\\[((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3}))|:)))(%.+)?(\\/([0-9]|[1-9][0-9]|1[0-1][0-9]|12[0-8]))?\\](:\\d{1,5})?(\\/.*)?$");
// don't use global (g) switch with regex.test, as regex will use lastIndex to match subsequent strings, giving "random" results!
export const domainOnly = new RegExp("^([\\p{L}\\d]+(-[\\p{L}\\d]+)*\\.)+[\\p{L}]{2,}$", "u")
export const certificateName = new RegExp("^([\\*\\p{L}\\d]+(-[\\*\\p{L}\\d]+)*\\.)+[\\p{L}]{2,}$", "u")
export const routerFirmware = new RegExp("^(([^\\s].*[^\\s])|([^\\s])|())$")
export const routerModelRegEx = /^(([^\s].*[^\s])|([^\s])|())$/
export const emailRegExp = new RegExp("(?:[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*|\"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])*\")@(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?|\\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-zA-Z0-9-]*[a-zA-Z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\\])")
export const phoneRegExp = new RegExp("^[+|#]?[0-9|#|*]*$")
export const noSymbolsRegExp = new RegExp("^[a-zA-Z0-9\\-\\._@]+$");

export const validateMacAddressRegex = (domain) => {
    return MACRegExp.test(domain);
}
export const checkDomain = (domain) => {
    return  urlRegExp.test(domain) || domainRegExp.test(domain)
}
export const checkDomainOnly = (domain) => {
    return domainOnly.test(domain);
}
export const checkCertificateName = (domain) => {
    return certificateName.test(domain);
}
export const checkRouterFirmware = (firmware) => {
    return firmware.length <= 128 && routerFirmware.test(firmware);
}
export const validateIpv4Regex = (ip) => {
    return ipv4RegExp.test(ip);
}
export const validateIpv6Regex = (ip) => {
    return ipv6RegExp.test(ip);
}
export const validateIpv6InBracketsRegex = (ip) => {
    return ipv6InBracketsRegExp.test(ip);
}
export const validateTcpUpdRegExp = (protocol) => {
    return tcpUpdRegExp.test(protocol);
}

export const checkIP = (ip) => {
    if (!(validateIpv4Regex(ip) || validateIpv6Regex(ip))) return false;
    else {
        if (ipRegex.v4().test(ip)) return checkV4Mask(ip);
        if (ipRegex.v6().test(ip)) return checkV6Mask(ip);
    }
}
export const checkV4Mask = (ip) => {
    let splittedIp = ip.split("/");
    if (splittedIp.length === 1) return true;
    let binary = "";
    const mask = +splittedIp[1];
    splittedIp[0]
        .split(".")
        .forEach(
            (number) => (binary += (+number).toString(2).padStart(8, "0"))
        );
    if (binary.slice(mask, 32) === "".padStart(32 - mask, "0")) return true;
    return false;
}
export const checkV6Mask = (ip) => {
    let splittedIp = ip.split("/");
    if (splittedIp.length === 1) return true;
    let binary = "";
    const mask = +splittedIp[1];
    splittedIp = splittedIp[0].split(":");
    for (let i = 0; i <= 7; i++) {
        const hex = splittedIp[i] || "0";
        binary += parseInt(hex, 16).toString(2).padStart(16, "0");
    }
    if (binary.slice(mask, 128) === "".padStart(128 - mask, "0")) return true;
    return false;
}
export const validateUrlWithHost = (url) => {
    return  urlRegExp.test(url) || urlWithIpv4HostRegExp.test(url) || urlWithIpv6HostRegExp.test(url);
}
export const validateIpv4WithHost = (url) => {
    return  urlWithIpv4HostRegExp.test(url);
}
export const validateIpv6WithHost = (url) => {
    return  urlWithIpv6HostRegExp.test(url);
}

// ! this func causes http://172.28.128.3:10591/#/ns/ContentFilter/FilteringPolicies/Policies/Create to not be renderd
// export const checkPortsOrder = (protocol) => {
//     if (protocol.includes(": ") || protocol.includes(":")) {
//         let splitted =
//             protocol.split(": ")[1] == null
//                 ? protocol.split(":")
//                 : protocol.split(": ");
//         let ports;
//         if (
//             splitted[0].trim().includes("-") ||
//             (splitted[1] != null && splitted[1].trim().includes("-"))
//         ) {
//             ports =
//                 splitted[1] == null
//                     ? splitted[0].split(":")[1].split("-")
//                     : splitted[1].split("-");
//             return parseInt(ports[0]) <= parseInt(ports[1]);
//         } else {
//             return true;
//         }
//     }
//     return true;
// },
