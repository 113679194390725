export default {
    setAlerts(state, alerts) {
        state.alerts = alerts;
    },
    setEvents(state, events) {
        state.events = events;
    },
    setDefaultEvents(state, defaultEvents) {
        state.defaultEvents = defaultEvents;
    },
    removeAlert(state, index) {
        state.alerts.splice(index, 1);
    },
    addAlert(state, alert) {
        state.alerts.push(alert)
    },
    // Only for testing
    setAlertDummyDetails(state, data) {
        state.alertsDetailsDummy = state.alertsDetailsDummy.map(alert => alert.alert_id === data.alert_id ? data : alert)
    },
    setAlertDummy(state, data) {
        state.alertsDummy = state.alertsDummy.map(alert => alert.alert_id === data.alert_id ? data : alert)
    },
    addAlertDummyDetails(state, data) {
        state.alertsDetailsDummy.push(data)
    },
    addAlertDummy(state, data) {
        state.alertsDummy.push(data)
    },
    setDefaultEventsDummy(state, data) {
        state.defaultEventsDummy = data
    },
}