import _ from 'lodash';
import { DateTime as objDateTime} from 'luxon';

export default {
    removeAccountManager(state, id){
        if (id){
            state.account_managers.delete(id);
            state.account_managers = new Map(state.account_managers);
        }
    }, 
    addManager(state, user){
        state.managers.set(user.id, user.data);
        state.managers = new Map(state.managers);
    },  
    addAccountManager(state, manager){
        state.account_managers.set(manager.id, manager.data);
        state.account_managers = new Map(state.account_managers);
    },    
    removeManager(state, id){
        if (id){
          state.managers.delete(id);
          state.managers = new Map(state.managers);
        }
    },   
    setAccountManager(state, manager){
        let usr = state.account_managers.get(manager.id)
        _.merge(usr, manager.data)
        if ( !usr.identity)
          usr.identity = {}
        usr.identity.updated_at = objDateTime.utc().toISO()
        state.account_managers = new Map(state.account_managers);
    },                    
}