export default {
    addCluster(state, cluster) {
        state.external_servers.set(cluster.id, cluster.data);
        state.external_servers = new Map(state.external_servers);
    },

    removeCluster(state, id) {
        if (id) {
            state.external_servers.delete(id);
            state.external_servers = new Map(state.external_servers);
        }
    },

    setServer(state, server) {
        Object.assign(state.server, server);
    },

    addServer(state, { cluster_name, data }) {
        let cluster = state.external_servers.get(cluster_name);

        if (cluster)
            cluster.servers.set(data.id, data.data);

        state.external_servers = new Map(state.external_servers);
    },

    removeServer(state, { cluster_name, server_name }) {
        let cluster = state.external_servers.get(cluster_name);

        if (cluster)
            cluster.servers.delete(server_name);

        state.external_servers = new Map(state.external_servers);
    },
};