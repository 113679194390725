/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'item-ios': {
    width: 16,
    height: 16,
    viewBox: '0 0 40 24',
    data: '<path pid="0" d="M.57 21.36h3.252V7.586H.57zM2.1 5.865c1.148 0 1.913-.957 1.913-1.913S3.248 2.23 2.1 2.23c-.956 0-1.721.765-1.721 1.722S1.144 5.866 2.1 5.866zM14.725 2.23c-5.547 0-8.99 3.826-8.99 9.756s3.443 9.755 8.99 9.755 8.99-3.826 8.99-9.755-3.634-9.756-8.99-9.756zm0 2.87c3.443 0 5.547 2.677 5.547 6.886s-2.104 6.886-5.547 6.886-5.547-2.678-5.547-6.886S11.282 5.1 14.725 5.1zm10.138 11.094c.19 3.443 3.06 5.547 7.268 5.547 4.591 0 7.46-2.295 7.46-5.739 0-2.869-1.53-4.399-5.356-5.164l-2.104-.765c-2.295-.574-3.252-1.34-3.252-2.487 0-1.53 1.53-2.678 3.635-2.678s3.634 1.148 3.825 2.87h3.252c0-3.252-2.869-5.548-6.886-5.548s-7.077 2.296-7.077 5.547c0 2.678 1.721 4.4 5.164 5.165l2.487.574c2.295.574 3.252 1.339 3.252 2.678 0 1.53-1.53 2.678-3.826 2.678s-4.017-1.148-4.208-2.87z" _fill="#383132"/>'
  }
})
