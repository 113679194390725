import _ from 'lodash';
import axios from "axios";

import { prepareDnscfData, prepareStrategyData } from  "@/util/dns/dnscfUtils";
import { items as dummyDnscf } from '@/util/dummy/dnscfRules.json';
import i18n from "../../../i18n";

export default {
    async getDnscf({ commit, state, rootState }) {
        if (rootState.settings.debug) {
            if (!state.dnscfStrategy || state.dnscfStrategy.length === 0){
            commit("setDnscf", dummyDnscf);
            }
        } else {
            try {
            const dnscfStrategy = await axios.get('/subsystems/ds/content-policy-rules');
            commit("setDnscf", dnscfStrategy.data);
            } catch (err) { console.error('Error getting dns strategy: ', err) }
        }
    },
  getDnscfRulesData({ state, commit, getters }) {
    if (!getters.isDebug || state.rules.length === 0) {

      const ispStrategy = getters.getDnscfStrategy();
      const rules = prepareDnscfData(ispStrategy);
      const sortedRules = rules.sort((a, b) => {
        return a.order > b.order ? 1 : -1;
      });
      commit("setDnscfRules", sortedRules);
    }
  },
  async createDnscfRule({ state, commit, getters, dispatch }, createdRule) {
    createdRule.order = state.rules.length > 0
      ? state.rules[state.rules.length - 1].order + 1
      : 0;

    if (getters.isDebug ) {
      commit("addDnscfRule", prepareStrategyData(createdRule));
    } else {
      // Patch to backend
      const newdnsrule = prepareStrategyData(createdRule);
      try {
        await axios.post('/subsystems/ds/content-policy-rules', { ...newdnsrule });
        await dispatch("getDnscf");
        commit("setDnscfRules", prepareDnscfData(getters.getDnscfStrategy()));
      } catch (error) {
        if (error.response && error.response.data) {
          if (error.response.data.type && error.response.data.type) {
            commit('setError', i18n.t(error.response.data.type), {root: true});
          }
          else {
            commit('setError', error, { root: true });
          }
        }
        throw new Error(error);
      }
    }
  },
  async deleteRule({ state, commit, getters, dispatch }, ruleToDelete) {
    if (getters.isDebug) {
      const deletedOrder = ruleToDelete.order;
      let rules = _.cloneDeep(state.rules);
      const updatedRules = rules.filter(rule => {
        if (rule.rule_name !== ruleToDelete.rule_name) {
          if (rule.order > deletedOrder) rule.order--;
          return rule;
        }
      })
      commit("setDnscfRules", updatedRules);
    } else {
      // Patch to backend
      try {
        await axios.delete('/subsystems/ds/content-policy-rules/' + ruleToDelete.rule_name);
        await dispatch("getDnscf");
        commit("setDnscfRules", prepareDnscfData(getters.getDnscfStrategy()));
      } catch (err) {
        console.error('Error deleting dns strategy rule: ', err);
        commit('setError', err, { root: true });
      }
    }
  },
  async updateDnscfRule({ commit, getters, state, dispatch }, updatedRule) {
    if (getters.isDebug) {
      const updatedRules = state.rules.map(rule =>
        rule.rule_name === updatedRule.rule_name ? updatedRule : rule
      );
      commit("setDnscfRules", updatedRules);
    } else {
      const newdnsrule = prepareStrategyData(updatedRule);
      try {
        await axios.put('/subsystems/ds/content-policy-rules/'+updatedRule.rule_name, { ...newdnsrule });
        await dispatch("getDnscf");
        commit("setDnscfRules", prepareDnscfData(getters.getDnscfStrategy()));
      } catch (err) {
        console.error('Error updating dns rule: ', err);
        commit('setError', err, { root: true });
      }
    }
  },
  async updateDnscfRuleStatus({ commit, getters, state, dispatch }, updatedRule) {
    if (getters.isDebug) {
      const updatedRules = state.rules.map(rule =>
        rule.rule_name === updatedRule.rule_name ? updatedRule : rule
      );
      commit("setDnscfRules", updatedRules);
    } else {
      const newdnsrule = prepareStrategyData(updatedRule);
      try {
        await axios.put('/subsystems/ds/content-policy-rules/'+updatedRule.rule_name, { ...newdnsrule });
        await dispatch("getDnscf");
        commit("setDnscfRules", prepareDnscfData(getters.getDnscfStrategy()));
      } catch (err) {
        console.error('Error updating dns rule: ', err);
        commit('setError', err, { root: true });
      }
    }
  }

}
