export default {
    customization: (state) => (id) => {
        return state[id]
    },
    envOptions: (state) => {
        return state.acc.env_options
    },
    fonts: (state) => {
        return state.fonts
    },
    defaultFonts: (state) => {
        return state.defaultAccCustomization.fonts
    },
    selectedFonts: (state) => {
        return state.acc.env_options.fonts
    },
    uploadedFontConfig: (state) => {
      return state.uploadedFont
    },
    primaryColor: (state) => {
        return state.acc.env_options.colors.primary
    },
    secondaryColor: (state) => {
        return state.acc.env_options.colors.secondary
    },
    graphsColors: (state) => {
        return state.acc.env_options.colors.graphs
    },
    navigation: (state) => {
        return state.acc.env_options.navigation
    },
    accDefaultCustomization: (state) => {
        return state.defaultAccCustomization
    },
    defaultPrimaryColor: (state) => {
        return state.defaultAccCustomization.colors.primary
    },
    defaultSecondaryColor: (state) => {
        return state.defaultAccCustomization.colors.secondary
    },
    defaultGraphsColors: (state) => {
        return state.defaultAccCustomization.colors.graphs
    },
    accLanguages: (state) => {
        return state.acc.languages || []
    }
}
