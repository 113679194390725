import { initialState } from './state';

export function SET_ISP_SESSIONS_MANAGEMENT(state, sessionsManagement) {
  Object.assign(state.isp.sessions_management, sessionsManagement);
}

export function SET_ENT_SESSIONS_MANAGEMENT(state, sessionsManagement) {
  Object.assign(state.ent.sessions_management, sessionsManagement);
}

export function SET_RES_SESSIONS_MANAGEMENT(state, sessionsManagement) {
  Object.assign(state.res.sessions_management, sessionsManagement);
}

export function SET_DEFAULT(state) {
  Object.assign(state, initialState());
}
