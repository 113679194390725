export default () => ({
    alerts: [],
    events: [],
    defaultEvents: {},
    defaultEventsDummy: {
        default_actions: {
            Critical: { system_log: true, internal_log: false, SNMP: false, mail: false },
            Error: { system_log: false, internal_log: true, SNMP: false, mail: false },
            Warning: { system_log: false, internal_log: false, SNMP: true, mail: false },
            Information: { system_log: false, internal_log: false, SNMP: false, mail: false },
        },
        mail_action_config: { subject: 'New alert in NS', sender: 'john.doe@example.com', addresses: ['john.doe@example.com'] },
        excluded_events: [
            1
        ]
    },
    alertsDummy: [
        {
            "alert_id": "6660f1ee6c544b0190e6d701748f0777",
            "alert_event_type_list": [
                "Critical"
            ],
            "alert_action_list": [
                "Mail"
            ]
        },
        {
            "alert_id": "6660f1ee6c544b0190e6d701748f07772",
            "alert_event_type_list": [
                "Error"
            ],
            "alert_action_list": [
                "Mail", "SNMP"
            ]
        }
    ],
    alertsDetailsDummy: [{
        "alert_id": '6660f1ee6c544b0190e6d701748f0777',
        "actions": {
            "system_log": false,
            "internal_log": false,
            "SNMP": false,
            "mail": true
        },
        "mail_action_config": {
            "subject": "new alert in NS",
            "sender": "john.doe@example.com",
            "addresses": [
                "john.doe@example.com"
            ]
        },
        "events": [
            1
        ]
    },
    {
        "alert_id": '6660f1ee6c544b0190e6d701748f07772',
        "actions": {
            "system_log": false,
            "internal_log": false,
            "SNMP": true,
            "mail": true
        },
        "mail_action_config": {
            "subject": "new alert in NS",
            "sender": "john.doe@example.com",
            "addresses": [
                "john.doe@example.com"
            ]
        },
        "events": [
            3
        ]
    }
    ],
})