import _ from 'lodash';
import axios from "axios";
import { mockRouter } from './mockData';
import {searchBackDataToGUI, transformFilterToBackQuery} from '../../../util/selecTableRSUtil';
import { DEBUG_OPTIONS, ADVANCED_CONFIGURATION } from './state';

export default {
  //  Add "!" in rootState.settings.debug to debug
  getRouters({commit, state, rootState, dispatch}, {wait: wait, query: query}) {
    if (rootState.settings.debug) { 
      return new Promise((resolve, reject) => {
        commit('clear')
        const router = mockRouter()
          commit('addRouter', router)
        
        let ret = { items: state._routers, query: query, self: query}
        resolve(ret) 
      })
    } else {
      const filter = query && query.filter ? transformFilterToBackQuery(query.filter) : {}
      let config = {filter: filter , limit: query.limit, sort: query.sort, next_id:query.next_id, prev_id: query.prev_id}
      if (wait) config.headers =  { 'x-wait-on-loading': wait }
      return new Promise((resolve, reject) => {
        
        axios.post('/subsystems/rs/routers/search', config)
        .then(res => {
          if (res && res.data && res.data.items) { 
            commit('clear')
            let result = [];
            res.data.items.forEach(item => {
              result.push(searchBackDataToGUI(item))
            });
            commit('addRouter', result)
            let ret = { items: state._routers, query: res.data.query, self: query, next_id: res.data.next_id, prev_id: res.data.prev_id, query_count: res.data.query_count} 
            resolve(ret)
          } else {
            reject()
          }
        })
        .catch(error => {
          commit('setError', error, {root: true});
          reject()
        })

      });

    }
  },
  getRoutersFiltered({commit, state, rootState, dispatch}, {wait: wait, query: query}) {
    if (rootState.settings.debug) { 
      return new Promise((resolve, reject) => {
        commit('clear')
        const router = mockRouter()
          commit('addRouter', router)
        
        let ret = { items: state._routers, query: query, self: query}
        resolve(ret) 
      })
    } else {
      let config = {filter: query, limit: 0}
      if (wait) config.headers =  { 'x-wait-on-loading': wait }
      return new Promise((resolve, reject) => {
        
        axios.post('/subsystems/rs/routers/search', config)
        .then(res => {
          if (res && res.data ) { 
            resolve(res.data.query_count)
          } else {
            reject()
          }
        })
        .catch(error => {
          commit('setError', error, {root: true});
          reject()
        })

      });

    }
  },
  removeRouter({ commit, state, rootState, dispatch }, data) {

    if (rootState.settings.debug) {
      return new Promise((resolve, reject) => {
        commit('removeRouter', data.router_id)
        resolve(state._routers)
      });
    }
    else {
      return new Promise((resolve, reject) => {
        axios.delete('/rs/general/routers/' + encodeURIComponent(data.name))
          .then(res => {
            commit('removeRouter', data);
            resolve(state._routers)
          })
          .catch(error => {
            commit('setError', error, { root: true });
            reject()
          })
      });
    }
  },
  getFields({ commit, state, rootState, dispatch }, name) {

    if (rootState.settings.debug) {
      commit('saveDebugFieldOptions', DEBUG_OPTIONS)
    }
    else {
        axios.get('/subsystems/rs/routers/fields/' + name)
          .then(res => {
            if (res && res.data && res.data.items)
              commit('saveFieldOptions', {name: name, res: res.data.items});
          })
          .catch(error => {
            commit('setError', error, { root: true });
          })
    }
  },
  getAttributes({ commit, state, rootState, dispatch }, name) {

    if (rootState.settings.debug) {
     // commit('saveDebugFieldOptions', DEBUG_OPTIONS)
    }
    else {
      return new Promise((resolve, reject) => {
        axios.get('/subsystems/rs/routers/attributes')
          .then(res => {
            if (res && res.data && res.data.items)
              commit('saveAttributeOptions', res.data.items);
            resolve(res)
          })
          .catch(error => {
            commit('setError', error, { root: true });
            reject()
          })
      });
    }
  },

  resetStateStart({commit, state, rootState, dispatch}, routersInfo) {
    if (rootState.settings.debug) { 
      return new Promise((resolve, reject) => {
        resolve(25)
      })
    } else {
      return new Promise((resolve, reject) => {
        
        axios.post('/subsystems/rs/routers/reset-state', routersInfo)
        .then(res => {
          if (res && res.data) { 
           commit('setJob', res.data.job_id)
           resolve(res.data.job_id)  
          } else {
            reject()
          }
        })
        .catch(error => {
          commit('setError', error, {root: true});
          reject()
        })

      });

    }
  },

  setAgentPackageStart({commit, state, rootState, dispatch}, routersInfo) {
    if (rootState.settings.debug) { 
      return new Promise((resolve, reject) => {
        resolve(25)
      })
    }
    else{
      return new Promise((resolve, reject) => {
        axios.post('/subsystems/rs/routers/set-agent-package', routersInfo)
        .then(res => {
          if (res && res.data) { 
            commit('setJob', res.data.job_id)
            resolve(res.data.job_id) 
          } else {
            reject()
          }
        })
        .catch(error => {
          commit('setError', error, {root: true});
          reject()
        })

      });
    }

  },

  getAdvancedConfiguration({commit, state, rootState, dispatch}) {
    if (rootState.settings.debug) { 
      return new Promise((resolve, reject) => {
        commit('addAdvancedConfiguration', ADVANCED_CONFIGURATION)
        resolve(ADVANCED_CONFIGURATION)
      })
    } else {
      return new Promise((resolve, reject) => {
        axios.get('/subsystems/rs/advanced-configuration')
        .then(res => {
          if (res && res.data) { 
            commit('setAdvancedConfiguration', res.data)
            resolve(res.data)
          } else {
            reject()
          }
        })
        .catch(error => {
          commit('setError', error, {root: true});
          reject()
        })

      });

    }
  },

  getAgentPackages({commit, state, rootState, dispatch}, query) {
    if (rootState.settings.debug) { 
      return new Promise((resolve, reject) => {
        const mockData = {
          "items": [
            {"agent_version_id": "1.0.0", "router_model": "tp-100", "firmware_versions": ["1.0", "1.1"], "is_deleted": false, "upload_time": "2021-11-12T10:47:46Z", "size": 1261, "agent_package_id": "Uno2mQNi"}, 
            {"agent_version_id": "1.0.1", "router_model": "tp-100", "firmware_versions": ["1.0"], "is_deleted": false, "upload_time": "2021-11-12T10:48:09Z", "size": 1256, "agent_package_id": "KMmSdNZO"}, 
            {"agent_version_id": "1.0.0", "router_model": "tp-200", "firmware_versions": ["2.0.0", "2.1"], "is_deleted": false, "upload_time": "2021-11-12T10:48:22Z", "size": 1263, "agent_package_id": "dGFKG85C"},
            {"agent_version_id": "1.0.1", "router_model": "tp-200", "firmware_versions": [], "is_deleted": false, "upload_time": "2021-11-12T10:48:36Z", "size": 1249, "agent_package_id": "3O8zKrm7"}, 
            {"agent_version_id": "2.0.0", "router_model": "tp-300", "firmware_versions": [], "is_deleted": false, "upload_time": "2021-11-12T10:48:46Z", "size": 1245, "agent_package_id": "7M8keUxW"}, 
            {"agent_version_id": "1.0.0", "router_model": "model1", "firmware_versions": ["1.0", "1.1"], "is_deleted": false, "upload_time": "2021-11-12T11:00:14Z", "size": 1264, "agent_package_id": "lF2Rvhwy"}
          ]
        }
        commit('addAgentPackages', mockData.items)
      })
    } else {
      return new Promise((resolve, reject) => {
        axios.get('/subsystems/rs/router-agent-packages')
        .then(res => {
          if (res && res.data) { 
            commit('addAgentPackages', res.data.items)
          } else {
            reject()
          }
        })
        .catch(error => {
          commit('setError', error, {root: true});
          reject()
        })

      });

    }
  },


  getJob({commit, state, rootState, dispatch}, job_id) {
    if (rootState.settings.debug) { 
      return new Promise((resolve, reject) => {
        resolve({
          status: 'running',
          total: 40669,
			    processed: 10,
			    jobinfo: `2021-10-01T09:04:02 - Job e5d99b45201c4556baaa898a86059629 created, action set_agent_package_id \n` +
							 `2021-10-01T09:04:02 - Action limited up to 50000 routers \n` +
								`2021-10-01T09:04:03 - Job e5d99b45201c4556baaa898a86059629 started \n` +
								`2021-10-01T09:04:04 - 40669 routers found for searching criteria`
      
        })
      }) 
    } else {
      return new Promise((resolve, reject) => {
        axios.get(`/subsystems/rs/routers/jobs/${job_id}`)
        .then(res => {
          if (res && res.data && res.data.execution) { 
           const result = res.data.execution
           let logs = ''
           result.logs && result.logs.map(element => {
             logs = logs + `${element} \n`
           })
           resolve({status: result.status, total: result.total, updated: result.updated, action: result.action, processed: result.processed, logs: logs })
          } else {
            reject()
          }
        })
        .catch(error => {
          commit('setError', error, {root: true});
          reject()
        })

      });
    }
  },

  stopJob({commit, state, rootState, dispatch}, job_id) {
    if (rootState.settings.debug) { 
      return new Promise((resolve, reject) => {
      
      })
    } else {
      return new Promise((resolve, reject) => {
        axios.post(`/subsystems/rs/routers/jobs/${job_id}/stop`)
        .catch(error => {
          commit('setError', error, {root: true});
          reject()
        })

      });

    }
  },

}