<template>
	<configurations :help_text="help_text" :global="true" class="ml-4" />
</template>

<script>
import Configurations   from '@/components/AsmConfigurations/Configurations';

import { mapActions }   from 'vuex';
import _ from 'lodash';

export default
{
	components: {
		Configurations
	},
	data() {
		return {
			help_text: 'ISP Global Service Configurations'
		}
	},
	beforeMount() {
		this.getGlobalConfigurations();
	},
	methods: {
		...mapActions('ispServices/services', ['getGlobalConfigurations'])
	}
}
</script>
