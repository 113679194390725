import axios from "axios";
import i18n from '../../../i18n';

import { prepareContentFilterData, prepareStrategyData, prepareStrategyStatusData } from "@/util/contentFilterUtils";

import { getPartialStrategyFromRule, setDeletedElementsToNull } from "@/util/strategyUtil";
import { contentFilter as dummyContentFilter } from '@/util/dummy/contentFilterRules.json';
import nsServiceConfig from '@/util/dummy/nsServiceConfig.json';
import ispServiceConfig from '@/util/dummy/ispServiceConfig.json';
import { filterStrategyByService } from "@/util/strategyUtil";

export default {
  async getContentFilter({ commit, state, rootState }) {
    if (rootState.settings.debug) {
      if (!state.contentFilterStrategy || state.contentFilterStrategy.rules.length === 0)
        commit("setContentFilter", dummyContentFilter)
    } else {
      // Get anti botnet from backend
      try {
        const contentFilterStrategy = await axios.get('isp-strategy');
        commit("setContentFilter", filterStrategyByService(contentFilterStrategy.data, 'content_filter'));
      } catch (err) { console.error('Error getting isp strategy: ', err) }
    }
  },
  getContentFilterRulesData({ state, commit, getters }) {
    if (!getters.isDebug || state.rules.length === 0) {
      let contentFilter = getters.getContentFilterStrategy();
      contentFilter = prepareContentFilterData(contentFilter);
      const sortedRules = contentFilter.sort((a, b) => {
        return a.order > b.order ? 1 : -1;
      });

      commit("setContentFilterRules", sortedRules);
    }
  },
  async createContentFilterRule({ state, commit, getters, dispatch }, createdRule) {
    createdRule.order = 0;
    createdRule.id = createdRule.rule_name;

    if (getters.isDebug) {
      commit("addContentFilterRule", createdRule);
    } else {
      const partialStrategy = prepareStrategyData([createdRule]);
      try {
        await axios.post('/subsystems/ns/isp-strategy/provision', { ...partialStrategy });
        await dispatch("getContentFilter");
        commit("setContentFilterRules", prepareContentFilterData(getters.getContentFilterStrategy()));
      } catch (err) {
        console.error('Error updating strategy: ', err);
        if (err.response) {
          switch(err.response.status){
            //
              case 409:
                  if (err.response.data.type && err.response.data.type === 'ISP_STRATEGY.LIMIT_EXCEEDED') {
                    commit('setError', i18n.t('MAX_POLICIES_ALLOWED'), {root: true})
                  } else {
                    commit('setError', i18n.t(err.response.data.type), {root:true});
                  }
                  break;
              default:
                  commit('setError', err, {root:true})
          }
      }
        throw new Error(err);
      }
    }
  },
  async deleteRule({ state, commit, getters, dispatch }, ruleToDelete) {
    if (getters.isDebug) {
      const deletedOrder = ruleToDelete.order;
      let rules = _.cloneDeep(state.rules);
      const updatedRules = rules.filter(rule => {
        if (rule.rule_name !== ruleToDelete.rule_name) {
          if (rule.order > deletedOrder) rule.order--;
          return rule;
        }
      })
      commit("setContentFilterRules", updatedRules);
    } else {
      // Patch to backend
      try {
        await axios.patch("/subsystems/ns/isp-strategy?cascade_delete=true", { rules: { [ruleToDelete.rule_name]: null } });
        await dispatch("getContentFilter");
        commit("setContentFilterRules", prepareContentFilterData(getters.getContentFilterStrategy()));
      } catch (err) {
        console.error('Error deleting isp strategy rule: ', err);
        commit('setError', err, { root: true });
      }
    }
  },
  async updateContentFilterRule({ commit, getters, state, dispatch }, updatedRule) {
    if (getters.isDebug) {
      const updatedRules = state.rules.map(rule =>
        rule.rule_name === updatedRule.rule_name ? updatedRule : rule
      );
      commit("setContentFilterRules", updatedRules);
    } else {
      // Patch to backend
      let newPartialStrategy = prepareStrategyData([updatedRule]);
      let oldPartialStrategy = getPartialStrategyFromRule(updatedRule, getters.getContentFilterStrategy())
      newPartialStrategy = setDeletedElementsToNull(oldPartialStrategy, newPartialStrategy);
      try {
        await axios.patch('/subsystems/ns/isp-strategy', { ...newPartialStrategy });
        await dispatch("getContentFilter");
        commit("setContentFilterRules", prepareContentFilterData(getters.getContentFilterStrategy()));
      } catch (err) {
        console.error('Error updating rule: ', err);
        commit('setError', err, { root: true });
        throw err
      }
    }
  },
  async updateContentFilterRuleStatus({ commit, getters, state, dispatch }, updatedRule) {
      if (getters.isDebug) {
              const updatedRules = state.rules.map(rule => {
                  if (rule.rule_name === updatedRule.rule_name) {
                      rule.status = updatedRule.status;
                      return rule;
                  } else {
                      return rule;
                  }
              }
          );
          commit("setContentFilterRules", updatedRules);
      } else {
          let newPartialStrategy = prepareStrategyStatusData(updatedRule);
          try {
              await axios.patch('/subsystems/ns/isp-strategy', { ...newPartialStrategy });
          } catch (err) {
              console.error('Error updating rule: ', err);
              commit('setError', err, { root: true });
              throw err
          }
      }
  },

  async getServiceConfiguration({ dispatch, commit, getters }) {
    try {
      if (getters.isDebug) {
        commit('setServiceConfiguration', nsServiceConfig);
      } else {
        const response = await axios.get('/subsystems/ns/service-configuration');
        if (response.data) {
          commit('setServiceConfiguration', response.data);
        } else {
          commit('setServiceConfiguration', {});
        }
      }
    } catch (err) {
      dispatch('setError', err, { root: true });
    }
  },

  async updateServiceConfiguration({ dispatch }, config) {
    try {
      await axios.patch('/subsystems/ns/service-configuration', config);
      dispatch('getServiceConfiguration');
      const message = i18n.t('save_success')
      dispatch('setSuccess', message, { root: true });
    } catch (err) {
      dispatch('setError', err, { root: true });
      const { response } = err;
      if (response.status === 409) {
        const { title } = response.data;
        let fieldType = title.split(' ')[0].toLowerCase();

        switch (fieldType) {
          case 'html':
            fieldType = 'HTML';
            break;
          case 'url':
            fieldType = 'Url';
            break;
          case 'static':
            fieldType = 'Static';
            break;
          default:
            fieldType = '';
        }

        const message = fieldType ? `${fieldType} ${i18n.t('wasSetButWasNotDefined')}` : err;

        dispatch('setError', message, { root: true });
      } else {
        dispatch('setError', err, { root: true });
      }
    }
  },

  async getBlockingPages({ dispatch, commit, getters }) {
    try {
      let data;
      if (getters.isDebug) {
        data = ispServiceConfig;
      } else {
        data = await axios.get('/isp-service-configuration');
        data = data.data ? data.data : {};
      }
      //const { data } = await axios.get('/isp-service-configuration');
      const { blocking_pages } = data.parental_control;
      if (blocking_pages) {
        commit('setBlockingPages', blocking_pages);
      }
    } catch (err) {
      dispatch('setError', err, { root: true });
    }
  }
}
