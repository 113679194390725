/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'item-apple': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M17.2 12.7c0 3.4 3 4.5 3 4.5 0 .1-.5 1.6-1.5 3.2-.9 1.4-1.9 2.7-3.4 2.7s-2-.9-3.7-.9c-1.7 0-2.2.9-3.7.9-1.5.1-2.6-1.5-3.5-2.8C2.5 17.5 1 12.4 3 9c1-1.7 2.7-2.8 4.6-2.8 1.4 0 2.8 1 3.7 1 .9 0 2.5-1.2 4.3-1 .7 0 2.8.3 4.1 2.2-.2.1-2.5 1.5-2.5 4.3m-2.8-8.3c.8-.9 1.3-2.3 1.2-3.6-1.1 0-2.5.8-3.3 1.7-.7.8-1.4 2.2-1.2 3.5 1.2.1 2.5-.7 3.3-1.6" _fill="#383132"/>'
  }
})
