/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'item-filteringlogconfig': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path pid="0" d="M9.488 11.19a4.8 4.8 0 00-2.707-4.32V1.59a1.6 1.6 0 10-3.2 0v4.96a4.8 4.8 0 000 9.28v14.56a1.6 1.6 0 103.2 0V15.51a4.8 4.8 0 002.707-4.32zm-3.155 0a1.6 1.6 0 11-3.2 0 1.6 1.6 0 013.2 0zm14.406 11.224a4.8 4.8 0 00-2.758-4.344V1.59a1.6 1.6 0 10-3.2 0v16.16a4.8 4.8 0 000 9.296v3.344a1.6 1.6 0 103.2 0v-3.2a2.048 2.048 0 000-.432 4.8 4.8 0 002.758-4.344zm-3.206-.024a1.6 1.6 0 11-3.2 0 1.6 1.6 0 013.2 0zm14.457-9.6a4.8 4.8 0 00-2.81-4.368 2.048 2.048 0 000-.432v-6.4a1.6 1.6 0 10-3.2 0v6.544a4.8 4.8 0 000 9.312V30.39a1.6 1.6 0 103.2 0v-12.8a2.048 2.048 0 000-.432 4.8 4.8 0 002.81-4.368zm-3.257 0a1.6 1.6 0 11-3.2 0 1.6 1.6 0 013.2 0z" _fill="#000" fill-rule="evenodd"/>'
  }
})
