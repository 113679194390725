/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'download': {
    width: 213.92,
    height: 213.833,
    viewBox: '0 0 56.6 56.577',
    data: '<path pid="0" d="M23.924 56.336a38.928 38.928 0 00-2.342-.494c-1.472-.267-3.66-1.113-6.711-2.594-3.095-1.502-7.576-5.415-9.543-8.333-1.404-2.084-2.703-4.322-2.703-4.658 0-.188-.292-.917-.65-1.622C.762 36.237.196 33.497.05 29.315c-.115-3.332-.052-4.251.452-6.608C1.698 17.118 4.126 12.565 8.05 8.555 12.209 4.3 17.096 1.648 22.908.488c2.39-.476 3.486-.55 6.577-.447 2.04.068 3.945.248 4.233.4.288.151 1.21.402 2.05.556 3.606.665 9.064 3.846 12.519 7.3 2.216 2.214 5.132 6.331 5.691 8.036.177.538.535 1.396.796 1.905.443.865.844 2.371 1.538 5.775.158.775.288 2.76.288 4.411 0 5.097-1.08 9.054-3.897 14.288-1.322 2.457-5.414 7.004-7.425 8.252-.394.244-.955.643-1.246.887-.96.802-5.128 2.85-7.167 3.52-2.504.825-5.492 1.232-8.84 1.204-1.82-.014-3.665-.122-4.101-.239zm8.202-5.962c1.455-.231 3.003-.581 3.44-.778a47.423 47.423 0 012.146-.87c2.676-1.012 6.4-4.123 8.95-7.477 1.463-1.923 3.048-5.704 3.603-8.591.622-3.239.325-9.155-.557-11.113-.131-.29-.49-1.22-.799-2.065-1.294-3.546-5.274-8.08-9.279-10.57-1.11-.69-2.164-1.255-2.34-1.255-.177 0-.697-.17-1.155-.38-1.562-.708-5.038-1.293-7.713-1.296-2.647-.003-6.393.5-6.866.922-.139.123-.47.224-.735.224-.428 0-4.097 1.752-4.438 2.12-.073.078-.49.368-.926.645-1.196.758-4.043 3.547-5.145 5.04C6.94 19.503 5.374 25.29 6.05 30.685c.712 5.69 2.568 9.477 6.61 13.483 2.455 2.434 6.209 5.027 7.278 5.027.242 0 .762.163 1.155.361.393.2 1.726.516 2.963.704 1.237.188 2.725.426 3.307.529.582.103 1.297.146 1.588.096.29-.05 1.72-.28 3.175-.51zM16.912 42.58v-3.175h23.019v6.35H16.912zm5.955-9.788l-5.284-5.29 1.973-1.986c1.085-1.093 2.092-1.986 2.237-1.986.146 0 1.012.74 1.925 1.646l1.66 1.646V11.094H31.2v15.728l1.662-1.646c.913-.906 1.78-1.646 1.924-1.646.146 0 1.152.893 2.238 1.986l1.973 1.986-5.285 5.29c-2.906 2.91-5.346 5.29-5.422 5.29-.075 0-2.515-2.38-5.422-5.29z"/>'
  }
})
