import { license } from '../../stubs/license';
import axios from "axios";

export default {
    getLicense({ commit, state, rootGetters }) {
        if (!rootGetters['settings/getDebug']) {
            return new Promise((resolve, reject) => {
                axios({
                    url: '/subsystems/ns/license',
                    method: 'get',
                    headers: { 'x-wait-on-loading': 300 },
                    validateStatus: function (status) { return ![401, 403].includes(status) }
                })
                    .then(res => {
                        if (res) {
                            if (res && res.status && res.status == 200) {
                                if (res && res.data) {
                                    commit('addLicense', res.data)
                                    resolve(res.data)
                                }
                                else
                                    reject();
                            } else if (res && res.status && res.status == 422 || res.status == 409) {

                                let errtags = ["ERROR_SUSPLIC", "ERROR_LICENSE", "ERROR_LCHECK", "WARNING_DATE", "WARNING_LICENSE", "WARNING_INSTALL", "WARNING_SERVICES", "SOAP_ERROR"];

                                let error = res.data.type.split(".");
                                let err = error[error.length - 1];

                                if (errtags.includes(err)) {
                                    let license = {
                                        license_warnings: [err]
                                    }
                                    commit('addLicense', license)
                                    resolve(license)
                                } else {
                                    if (res && res.data && res.data.title)
                                        throw (res.data.title);
                                    else
                                        throw ('Error license')
                                }
                            } /*else {						
                          if( res && res.data && res.data.title )
                              throw (res.data.title);						
                          else
                              throw ('Error license')
                      }*/
                        } //else
                        //throw ('Error license')
                    })
                    .catch(error => {
                        commit('setError', error, { root: true });
                        reject()
                    })
            })
        }
        else {
            return new Promise((resolve, reject) => {
                commit('addLicense', license.data)
                let ret = license.data
                resolve(ret)
            });
        }
    },

    updateLicense({ commit, state, rootGetters }, license) {
        if (rootGetters['settings/getDebug']) {
            return new Promise((resolve, reject) => {
                commit('addLicense', license)
                resolve(license)
            })
        } else {
            return new Promise((resolve, reject) => {
                let data = {
                    license_code: license
                }
                axios({
                    method: "put",
                    url: '/subsystems/ns/license',
                    headers: {
                        'Authorization': `Bearer ${rootGetters['auth/accessToken']}`,
                    },
                    data: data,
                    validateStatus: function (status) { return ![401, 403].includes(status) }
                })
                    .then(res => {
                        if (res) {
                            if (res.status == "204") {
                                let license = {
                                    license_code: data.license_code
                                }
                                resolve(license);
                            } else if (res && res.status && res.status == 422 || res.status == 409) {
                                let errtags = ["ERROR_SUSPLIC", "ERROR_LICENSE", "ERROR_LCHECK", "WARNING_DATE", "WARNING_LICENSE", "WARNING_INSTALL", "WARNING_SERVICES", "SOAP_ERROR"];
                                let error = res.data.type.split(".");
                                let err = error[error.length - 1];

                                if (errtags.includes(err)) {
                                    let license = {
                                        license_code: "",
                                        license_error: err
                                    }
                                    resolve(license)
                                } else {
                                    if (res && res.data && res.data.title)
                                        throw (res.data.title);
                                    else
                                        throw ('Error license')
                                }
                            }
                        } else
                            throw ('Error license')
                    })
                    .catch(error => {
                        commit('setError', error, { root: true });
                        reject()
                    })
            })
        }
    },

    getLicenseAlert({ commit, dispatch, state, rootGetters }) {
        let la
        dispatch('getLicense')
            .then(res => {
                if (res)
                    la = (res.license_warnings.length > 0 ? true : false);
                else
                    la = false;

                commit('clearLicenseInterval');
                let li = setInterval(() => {
                    dispatch('getLicenseAlert')
                }, 300000);
                commit('updateLicenseInterval', li);
                commit('updateLicenseAlert', la);
            })
            .catch(err => {
                commit('updateLicenseAlert', false);
            });
    },
}