<template>
  <b-card>
    <h5 class="title" style="display: flex; align-items: center;">
      <svgicon class="icon mr-2" icon="item-blockingconfiguration" width="20" height="20" />
      <span style="margin-top: 4px;">{{t('DNSBlockingConfiguration')}}</span>
    </h5>
    <b-card-text class='pl-4 pr-4'>
      <h4>{{t('DNSBlocks')}}</h4>
    </b-card-text>
    <b-card-text class='pl-4 pr-4'>
      <fg-input v-model="dnsBlockingConfiguration.domainResponse"
        id="domain_response"
        :label="t('DNSDomainResponse')"
        name="domain_response"
        placeholder="URL"
        autocapitalize="yes"
        v-validate="'domain|max:255'"
        :visible_for_permissions='[{actions: ["PATCH"], path: "/subsystems/ns/service-configuration"}]'
        :error="getError('domain_response')">
      </fg-input>
    </b-card-text>
    <b-card-text class='pl-4 pr-4'>
      <fg-input v-model="dnsBlockingConfiguration.ipv4Response"
        id="ipv4_response"
        :label="t('ipv4Response')"
        name="ipv4_response"
        placeholder="IPv4"
        autocapitalize="yes"
        v-validate="'ipv4|max:15'"
        :visible_for_permissions='[{actions: ["PATCH"], path: "/subsystems/ns/service-configuration"}]'
        :error="getError('ipv4_response')">
      </fg-input>
    </b-card-text>
    <b-card-text class='pl-4 pr-4'>
      <fg-input v-model="dnsBlockingConfiguration.ipv6Response"
        id="ipv6_response"
        :label="t('ipv6Response')"
        name="ipv6_response"
        placeholder="IPv6"
        autocapitalize="yes"
        v-validate="'ipv6|max:45'"
        :visible_for_permissions='[{actions: ["PATCH"], path: "/subsystems/ns/service-configuration"}]'
        :error="getError('ipv6_response')">
      </fg-input>
    </b-card-text>
  </b-card>
</template>

<script>
import {
  validateIpv6Regex,
  validateIpv4Regex,
  checkDomain,
  checkIP,
} from "@/util/regexConsts";

export default {
  name: "webFilterDnsBlockingConfiguration",
  props: {
    dnsBlockingConfiguration: {
      type: Object,
      required: true,
    },
  },
  methods: {
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
  },
  created(){
    this.$validator.extend(
      'domain',{
        getMessage: field =>  `${field} ${this.t('domainValidationMessage')}`,
        validate: (value) => {
          if (!checkDomain(value)) return false;
          return true;
        }
    });

    this.$validator.extend(
      'ipv4',{
        getMessage: field =>  `${field} ${this.t('ipv4ValidationMessage')}`,
        validate: (value) => {
          if (!validateIpv4Regex(value)) return false;
          return true;
        }
    });

    this.$validator.extend(
      'ipv6',{
        getMessage: field =>  `${field} ${this.t('ipv6ValidationMessage')}`,
        validate: (value) => {
          if (!validateIpv6Regex(value)) return false;
          return true;
        }
    });
  }
}
</script>
