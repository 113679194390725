import axios from "axios";

export default {
    getNetworkSecureBackups({ commit, state, dispatch, getters }, { wait: wait, query: query }) {
      let config = { params: query }
      if (wait)
        config.headers = { 'x-wait-on-loading': wait }
      return new Promise((resolve, reject) => {
        axios.get('/subsystems/ns/backups', config)
          .then(res => {
            if (res && res.data && res.data.items) {
              commit('setNetworkSecureBackups', res.data.items);
              resolve({ items: state.backups, query: res.data.query, self: query })
            } else {
              reject()
            }
          })
          .catch(error => {
            commit('setError', error, { root: true });
            reject()
          })
      })
    },
    startNetworkBackup({ commit }) {
      return new Promise((resolve, reject) => {
        axios.post('/subsystems/ns/backups/start/Configuration')
          .then(() => {
            resolve()
          })
          .catch(error => {
            commit('setError', error, { root: true });
            reject()
          })
      });
    },
    removeNetworkBackup({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios.delete('/subsystems/ns/backups/' + id)
          .then(() => {
            resolve()
          })
          .catch(error => {
            commit('setError', error, { root: true });
            reject()
          })
      });
    },
    getNetworkBackup({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios.get('/subsystems/ns/backups/' + id)
          .then(res => {
            if (res && res.data && res.data.items) {
              resolve(res.data)
            } else {
              reject()
            }
          })
          .catch(error => {
            commit('setError', error, { root: true });
            reject()
          })
      });
    },
};