export default {
    serviceStatus(state) {
      return state.service_status;
    },
    networkSecureHosts(state) {
      return state.hosts;
    },
    networkSecureSnifferMode(state) {
      return state.sniffer_mode;
    }
  };