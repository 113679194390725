import Vue from 'vue'
import Vuex from 'vuex'

import accountManagement from './accountManagement';
import auth from './auth';
import blockingpages from "./blockingpages";
import customProvider from './customProvider';
import endpointsecure from './endpointsecure';
import errors from './errors';
import generalManagement from './generalManagement'
import groups from './groups';
import ispServices from './ispServices';
import jsonschema from './jsonschema'
import networksecure from "./networksecure";
import routerSecure from "./routerSecure";
import providers from './providers';
import reporter from './reporter';
import resetPassword from './resetPassword';
import servicepackages from './servicepackages';
import sessions from './sessions';
import settings from './settings';
import dnssecure from "./dnssecure";

Vue.use(Vuex)

// All modules namespased except errors. Errors on root level!
export const store = new Vuex.Store({
  strict: true,
  modules: {
    accountManagement,
    auth,
    blockingpages,
    customProvider,
    dnssecure,
    endpointsecure,
    errors,
    generalManagement,
    groups,
    ispServices,
    jsonschema,
    networksecure,
    routerSecure,
    providers,
    reporter,
    resetPassword,
    servicepackages,
    sessions,
    settings,
  },
  state: {
  },
  mutations: {
    reset(state, data) {
      state.settings.debug = false
      state.settings.services = []
      state.settings.services_ns = []
      state.settings.products = []
      state.generalManagement.administrators.administrators.clear()
      state.generalManagement.administrators.roles.clear()
      state.generalManagement.administrators.rolesNS.clear()
      state.accountManagement.accounts.accounts.clear()
      state.accountManagement.profiles.profiles.clear()
      state.accountManagement.managers.account_managers.clear()
      state.accountManagement.users.account_users.clear()
      state.accountManagement.devices.account_devices.clear()
      state.accountManagement.users.users.clear()
      state.accountManagement.managers.managers.clear()
      state.accountManagement.devices._devices.clear()
    },
  },
  actions: {
  },

  getters: {
  }
})
