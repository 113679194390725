import Vue from 'vue';

export default {
    setNetworkSecureHosts(state, network_secure_hosts) {
      state.hosts = network_secure_hosts;
    },
    setServiceStatus(state, service_status) {
      state.service_status = service_status;
    },
    setLocalServiceStatus(state, { index, status }) {
      let val = Object.assign({}, state.service_status[index])
      val.status = status;
      Vue.set(state.service_status, index, val)
    },
    setNetworkSecureSnifferMode(state, sniffer_mode) {
      state.sniffer_mode = sniffer_mode;
    },
  }