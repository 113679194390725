import { service_packages } from '../stubs/servicepackages';
import axios from "axios";
import i18n from '../../i18n';

function makeid(length) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

export default {
    getServices({ commit, state, rootGetters }) {
        if (!rootGetters['settings/getDebug']) {
            return new Promise((resolve, reject) => {
                axios.get('/isp-autonotices-packages')
                    .then(res => {
                        if (res && res.data) {
                            res.data.items.forEach(item => {
                                let data = {
                                    id: item.isp_notice_id,
                                    data: item
                                }
                                commit('addService', data)
                            })
                        }
                        let ret = { items: state.services_packages }
                        resolve(ret)
                    })
                    .catch(error => {
                        commit('setError', error, { root: true });
                        reject()
                    })
            });
        }
        else {
            return new Promise((resolve, reject) => {
                if (state.services_packages.size <= 0) {
                    service_packages.items.forEach(item => {
                        let data = {
                            id: item.isp_notice_id,
                            data: item
                        }
                        commit('addService', data)
                    })
                }
                let ret = { items: state.services_packages }
                resolve(ret)
            });
        }
    },

    createService({ commit, state, rootGetters }, data) {
        if (!rootGetters['settings/getDebug']) {
            return new Promise((resolve, reject) => {
                axios.post('/isp-autonotices-packages', data)
                    .then(res => {
                        let datan = {
                            id: res.data.isp_notice_id,
                            data: res.data
                        }
                        commit('addService', datan)
                        resolve(state.services_packages)
                    })
                    .catch(error => {
                        if (error.response) {
                            switch(error.response.status){
                                case 409:
                                    commit('setError', i18n.t(error.response.data.type), {root:true});
                                    break;
                                default:
                                    commit('setError', error, {root:true})
                            }
                        }
                        reject()
                    })
            });
        } else {
            return new Promise((resolve, reject) => {
                let datan = {
                    id: res.data.isp_notice_id,
                    data: res.data
                }
                commit('addService', datan)
                resolve(state.services_packages)
            })
        }
    },

    updateService({ commit, state, rootGetters }, item) {
        let prev = _.cloneDeep(state.services_packages)
        let datan = {
            id: item.isp_notice_id,
            data: item
        }
        commit('addService', datan)

        if (!rootGetters['settings/getDebug']) {
            return new Promise((resolve, reject) => {
                axios.put('/isp-autonotices-packages/' + encodeURIComponent(datan.id), datan.data)
                    .then(res => {
                        resolve(state.services_packages)
                    })
                    .catch(error => {
                        commit('setError', error, { root: true });
                        commit('resetServices', prev)
                        reject()
                    })
            });
        } else {
            return new Promise((resolve, reject) => {
                resolve(state.services_packages)
            })
        }
    },

    deleteService({ commit, state, rootGetters }, id) {
        let prev = _.cloneDeep(state.services_packages)
        commit('removeService', id)

        if (rootGetters['settings/getDebug']) {
            return new Promise((resolve, reject) => {
                resolve(state.services_packages)
            });
        }
        else {
            return new Promise((resolve, reject) => {
                axios.delete('/isp-autonotices-packages/' + encodeURIComponent(id))
                    .then(res => {
                        resolve(state.services_packages)
                    })
                    .catch(error => {
                        if (error.response) {
                            switch(error.response.status){
                                case 404:
                                    commit('setError', i18n.t(error.response.data.type), {root:true});
                                    break;
                                default:
                                    commit('setError', error, {root:true})
                            }
                        }
    
                        commit('resetServices', prev)
                        reject()
                    })
            });
        }
    },
}