export default {
    resetServices(state, data) {
        if (!data) {
            state.service_packages = new Map()
        }
        else {
            state.service_packages = new Map(data)
        }
    },
    addService(state, data) {
        state.services_packages.set(data.id, data.data);
        state.services_packages = new Map(state.services_packages);
    },

    removeService(state, id) {
        if (id) {
            state.services_packages.delete(id);
            state.services_packages = new Map(state.services_packages);
        }
    },
};