import _ from 'lodash';
import axios from 'axios';
import { debug_allowed_list, debug_blocked_list } from '@/store/debugData';
import i18n from "../../../i18n";

export default {
    async getAdvancedConfigurations({ commit, dispatch, state }) {
        if (_.isEmpty(state.advanced_configurations)) {
            const advanced = await axios.get('subsystems/cb/advanced-configuration').catch(error => error.response.data.detail);
            dispatch('getGlobalConfigurations');

            if (advanced.status == 200) {
                commit('setAdvancedConfigurations', advanced.data);
            }
            else {
                commit('setErrorAll', advanced, { root: true })
            }
        }
    },
    async getGlobalConfigurations({ commit, state }) {
        if (_.isEmpty(state.global_configurations)) {
            const global = await axios.get('/isp-service-configuration').catch(error => error.response.data.detail);
            if (global.status == 200) {
                const globalConf = _.omit(global.data, ["parental_control.app_blocking_max_schedules", "parental_control.quiet_time_max_schedules"])
                commit('setGlobalConfigurations', globalConf);
            }
            else {
                commit('setErrorAll', global, { root: true })
            }
        }
    },
    async getGlobalAdvancedConfigurations({ commit, state }) {
        if (_.isEmpty(state.global_advanced_configurations)) {
            const globalAdvanced = await axios.get('/isp-service-advanced-configuration').catch(error => error.response.data.detail);
            if (globalAdvanced.status == 200) {
                commit('setGlobalAdvancedConfigurations', globalAdvanced.data);
            } else { commit('setErrorAll', globalAdvanced, { root: true }) }
        }
    },
    async setAdvancedConfigurations({ commit }, value) {

        const advanced = await axios.put('subsystems/cb/advanced-configuration', value).catch(error => error.response.data.detail);

        if ([200, 204].includes(advanced.status)) {
            commit('setAdvancedConfigurations', value);
            commit('setSuccess', i18n.t('Changes have been saved'), { root: true });
        }
        else {
            commit('setErrorAll', advanced, { root: true })
        }
    },
    async setGlobalConfigurations({ commit }, value) {
        const global = await axios.patch('/isp-service-configuration', value).catch(error => error.response.data.detail);

        if ([200, 204].includes(global.status)) {
            commit('setSuccess', i18n.t('Changes have been saved'), { root: true });
            const globalConf = _.omit(global.data, ["parental_control.app_blocking_max_schedules", "parental_control.quiet_time_max_schedules"])
            commit('setGlobalConfigurations', globalConf);
        }
        else {
            commit('setErrorAll', global, { root: true });
        }
    },
    async setGlobalAdvancedConfigurations({ commit }, value) {
        const global = await axios.put('/isp-service-advanced-configuration', value).catch(error => error.response.data.detail);

        if ([200, 204].includes(global.status)) {
            commit('setSuccess', i18n.t('Changes have been saved'), { root: true });
            commit('setGlobalAdvancedConfigurations', global.data);
        }
        else {
            commit('setErrorAll', global, { root: true });
        }
    },
    async get_black_list({ commit, getters, state }, { query }) {
        delete query.field_name
        if (query.field_value || query.field_value === '') {
            query.url_value = query.field_value
            delete query.field_value
        }
        if (query.field_operator) {
            query.url_operator = query.field_operator
            delete query.field_operator
        }
        try {
            const list = await axios.get(`/isp-forbidden-lists/${state.current_service_name}`, { params: query })
            const black_list = list.data.items;
            commit({ type: 'set_self_query', self_query: query, action: "block" });
            commit({ type: 'set_query', query: list.data.query, action: "block" });
            commit('setBlackList', black_list);
        }
        catch (error) {
            commit('setErrorAll', error.response.data.detail, { root: true })

        }
    },
    async get_white_list({ commit, getters, state }, { query }) {
        delete query.field_name
        if (query.field_value || query.field_value === '') {
            query.url_value = query.field_value
            delete query.field_value
        }
        if (query.field_operator) {
            query.url_operator = query.field_operator
            delete query.field_operator
        }
        try {
            const list = await axios.get(`/isp-allowed-lists/${state.current_service_name}`, { params: query })
            const white_list = list.data.items;
            commit({ type: 'set_self_query', self_query: query, action: "allow" });
            commit({ type: 'set_query', query: list.data.query, action: "allow" });
            commit('setWhiteList', white_list);
            // // ! delete
            // const white_list = debug_allowed_list
            // commit('set_self_query', white_list.self);
            // commit('setWhiteList', white_list.items);
            // //
        }
        catch (error) {
            commit('setErrorAll', error.response.data.detail, { root: true })

        }
    },
    async update_black_list({ commit, dispatch, state }, { action, item, query }) {
      try {
        await axios.post(`/isp-forbidden-lists/${state.current_service_name}/modify`, { [action]: [item] })
        await dispatch("get_black_list", { query });
      }
      catch (error) {
        if (error.response && error.response.data) {
          if (error.response.data.type && error.response.data.type === 'GLOBAL_LIST.LIMIT_IS_REACHED') {
            commit('setErrorAll', i18n.t(error.response.data.description), {root: true});           
          } else {
            let err = error.response.data.detail.includes('is not valid under any of the given schemas') ? 'The url field is not a valid URL' : error.response.data.detail;
            commit('setErrorAll', i18n.t(err), {root: true})
          }
        }
      }
    },
    async update_white_list({ commit, dispatch, state }, { action, item, query }) {
      try {
        await axios.post(`/isp-allowed-lists/${state.current_service_name}/modify`, { [action]: [item] })
        await dispatch("get_white_list", { query });
      }
      catch (error) {
        if (error.response && error.response.data) {
          if (error.response.data.type && error.response.data.type === 'GLOBAL_LIST.LIMIT_IS_REACHED') {
            commit('setErrorAll', i18n.t(error.response.data.description), {root: true});            
          } else {
            let err = error.response.data.detail.includes('is not valid under any of the given schemas') ? 'The url field is not a valid URL' : error.response.data.detail;
            commit('setErrorAll', i18n.t(err), {root: true})
          }
        }
      }
    },
}
