import { DEFAULT_PROFILE } from '@/configs/defaults'
import _ from 'lodash';

export default {
    setProfiles(state, { profiles }) {
        state.profiles = profiles
    },
    setProfile(state, { profile, isSetDefault }) {
        if (isSetDefault) state.profile = _.cloneDeep(DEFAULT_PROFILE)
        else state.profile = profile
    },
    updateProfileProp(state, { propName, value }) {
        state.profile[propName] = value
    },
    setServiceAvailability(state, { serviceName, isEnabled }) {
        let service = state.profile.services_configuration[serviceName]
        if (!service) service = {}

        service.enabled = isEnabled
        state.profile.services_configuration = { ...state.profile.services_configuration, [serviceName]: service }

        const serviceHasSendNotificationsProp = service.hasOwnProperty('send_notifications');
        if (!isEnabled && serviceHasSendNotificationsProp) {
            state.profile.services_configuration[serviceName].send_notifications = false
        }
    },
    updateProfileServiceProp(state, { serviceName, propName, value }) {
        let service = state.profile.services_configuration[serviceName]
        if ( !service){
            state.profile.services_configuration[serviceName] = {}
            service = state.profile.services_configuration[serviceName]
        }
        service[propName] = value
        state.profile.services_configuration = { ...state.profile.services_configuration, [serviceName]: service }
    },
    setIspCategories(state, { categories, defaultLanguage }) {
        const blockedCategories = state.profile.services_configuration.parental_control.blocked_categories
        if (categories.items.length) {
            state.ispCategories = categories.items.map(category => {
                const [defaultLangCategory] = category.isp_category_i18n.filter(obj => obj.language_id === defaultLanguage)
                const categoryName = defaultLangCategory ? defaultLangCategory.isp_category_name : category.isp_category_id
                return { name: categoryName, isSelected: blockedCategories.includes(category.isp_category_id), isp_category_id: category.isp_category_id, isp_category_i18n: category.isp_category_i18n }
            })
        }
    },
    addIspAutoNoticePackagesToProfile(state, { autoNoticePackages }) {
        const packages = state.profile.services_configuration.auto_notice.notices || []
        const notices = _.cloneDeep(autoNoticePackages.items.filter( item => item.enabled))
        state.profile.services_configuration.auto_notice.notices = notices.map(p => {
            let idx = packages.findIndex(item => item.isp_notice_id === p.isp_notice_id)
            if (idx > -1) p.enabled = packages[idx].enabled
            else p.enabled = false
            return p
        });
    },
}
