import { 
  getSrcOrDest, getSchedule,
  setSrcOrDest, setSchedule, setActions
} from "./strategyUtil";

/**
 * Generate array from Back-end to use it in Front-end
 * Function to generate the antivirus rules array from the account strategy
 * @param {Object} strategy attribute strategy of an account
 */
export const prepareAntivirusData = strategy => {
  let rules = [];
  for (const ruleName in strategy.rules) {
    if (strategy.rules.hasOwnProperty(ruleName)) {
      const rule = strategy.rules[ruleName];
      let log_activity = false;
      let actionType;
      for (const action of rule.actions) {
        if (action.op === "log") log_activity = true;
        else actionType = { type: action.op };
      }
      rules.push({
        rule_name: ruleName,
        id: ruleName,
        system: !!rule.system,
        status: rule.active === "yes" ? true : false,
        log_activity: log_activity,
        action: actionType,
        order: rule.order,
        source: getSrcOrDest(rule, strategy.source_and_destinations, "origin"),
        destination: getSrcOrDest(rule, strategy.source_and_destinations, "target"),
        schedule: getSchedule(rule, strategy.schedules),
        advanced: getAdvanced(rule, strategy.profiles),
        threats: getThreats(rule, strategy.profiles)
      });
    }
  }
  return rules;
};

const getThreats = (rule, profiles) => {
  if (rule.profile === "all") return 'infected';
  if (profiles[rule.profile].hasOwnProperty('infected') && profiles[rule.profile].infected)
    return 'infected'
  else return 'none';
};
/**
 * Function to generate the advanced input from the rule,
 * profiles and services
 * @param {Object} rule object with the description of the rule
 * @param {Object} profiles object with the profiles of the strategy account
 */
const getAdvanced = (rule, profiles) => {
  if (rule.profile === "all") return 'none';
  if (profiles[rule.profile].hasOwnProperty('password_compressed') && profiles[rule.profile].password_compressed)
    return 'password_compressed'
  else if (profiles[rule.profile].hasOwnProperty('allowed_list') && profiles[rule.profile].allowed_list)
    return 'allowed_list'
  else return 'none';
};

/**
 * Generate object from Front-end to send it to Back-end
 * Function to generate the strategy of an account from an array of rules
 * @param rules array of rules
 */
export const prepareStrategyData = rules => {
  let strategy = {
    rules: {}
  };
  for (let i = 0; i < rules.length; i++) {
    // Get source items
    const source = setSrcOrDest(rules[i], 'source');
    if (source.hasOwnProperty('value')) {
      if (!strategy.source_and_destinations) strategy.source_and_destinations = {};
      strategy.source_and_destinations[source.origin] = source.value;
    }

    // Get destination items
    const destination = setSrcOrDest(rules[i], 'destination');
    if (destination.hasOwnProperty('value')) {
      if (!strategy.source_and_destinations) strategy.source_and_destinations = {};
      strategy.source_and_destinations[destination.target] = destination.value;
    }

    // Get schedule items
    const schedules = setSchedule(rules[i]);
    if (schedules.hasOwnProperty('schedule')) {
      if (!strategy.schedules) strategy.schedules = {};
      strategy.schedules[schedules.scheduleName] = schedules.schedule;
    }

    // Get profile items
    const profiles = setProfile(rules[i]);
    if (profiles.hasOwnProperty('profiles')) {
      if (!strategy.profiles) strategy.profiles = {};
      strategy.profiles[profiles.profileName] = profiles.profiles;
    }

    strategy.rules[`${rules[i].rule_name}`] = {
      active: rules[i].status ? "yes" : "no",
      order: rules[i].order,
      ns_services: ["anti_virus"],
      origin: source.origin,
      target: destination.target,
      profile: profiles.profileName,
      when: schedules.scheduleName,
      actions: setActions(rules[i])
    };
  }
  return strategy;
};


export const prepareStrategyStatusData = rule => {
  let strategy = {
    rules: {}
  };
  strategy.rules[`${rule.rule_name}`] = {
    active: rule.status ? "yes" : "no",
    ns_services: ["anti_virus"]
  };
  return strategy;
};
/**
 * Function to generate the rule profile
 * @param {Object} rule object with the information of the rule
 * @returns an object with the profile key (and the profile object if necessary)
 */
const setProfile = rule => {
  let profiles = {
    ns_services: ["anti_virus"]
  };
  if (rule.threats === 'infected'){
    profiles.infected = { necessary: true, inverse: false }
  }
  if (rule.advanced === 'password_compressed') {
    profiles.password_compressed = { necessary: true, inverse: false }
  } else if (rule.advanced === 'allowed_list') {
    profiles.allowed_list = { necessary: true, inverse: false }
  }
  
  return {
    profileName: `${rule.rule_name}_profile`,
    profiles
  };
};