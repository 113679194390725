/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'alerts-list': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path pid="0" d="M13.09 22.4c0-.885.653-1.6 1.455-1.6h8.728c.804 0 1.454.715 1.454 1.6 0 .883-.65 1.6-1.454 1.6h-8.728c-.802 0-1.454-.717-1.454-1.6zM7.274 24h2.909v-3.2h-2.91V24zm5.818-8c0-.883.652-1.6 1.454-1.6h8.728c.804 0 1.454.717 1.454 1.6 0 .883-.65 1.6-1.454 1.6h-8.728c-.802 0-1.454-.717-1.454-1.6zm-5.818 1.6h2.909v-3.2h-2.91v3.2zM14.545 8c-.802 0-1.454.717-1.454 1.6 0 .883.652 1.6 1.454 1.6h8.728c.804 0 1.454-.717 1.454-1.6 0-.883-.65-1.6-1.454-1.6h-8.728zm-7.272 3.2h2.909V8h-2.91v3.2zM4.363 0C1.954 0 0 2.149 0 4.8v22.4C0 29.85 1.953 32 4.364 32h23.272C30.046 32 32 29.85 32 27.2V4.8C32 2.149 30.047 0 27.636 0H4.364zm23.273 3.2H4.364c-.803 0-1.455.717-1.455 1.6v22.4c0 .883.652 1.6 1.455 1.6h23.272c.805 0 1.455-.717 1.455-1.6V4.8c0-.883-.65-1.6-1.455-1.6z" _fill="#000" fill-rule="evenodd"/>'
  }
})
