<template>
  <b-card class="px-1">
    <template v-slot:header>
      <h4 class="title mt-4 mb-0">
        <svgicon
          style="margin-right: 10px"
          original
          class="icon"
          :icon="`${action}-list-icon`"
          width="44"
          height="44"
        />
        <span>{{ t(title) }}</span>
      </h4>
    </template>
    <b-row class="px-1">
      <b-col cols="12">
        <h4 class="my-2">{{ t(subtitle) }}</h4>
        <b-row class="align-items-baseline">
          <b-col cols="9">
            <template v-if="isDNS">
              <fg-input
                v-model="new_allowed"
                name="new_allowed"
                :placeholder="t('Enter a valid IPv4*')"
                v-validate="'required|ipv4'"
                :visible_for_permissions="permissions"
                :error="getError('new_allowed')"
              />
            </template>
            <template v-else>
              <fg-input
                v-model="new_allowed"
                name="new_allowed"
                :placeholder="t('Enter a valid IPv4 or IPv6*')"
                v-validate="'required|router'"
                :visible_for_permissions="permissions"
                :error="getError('new_allowed')"
              />
            </template>  
          </b-col>
          <b-col cols="3" class="ml-auto">
            <n-button
              type="primary"
              size="md"
              :disabled="!!getError('new_allowed')"
              :visible_for_permissions="permissions"
              round
              block
              @click.native="add_new_allowed(new_allowed)"
            >
              <span class="text-capitalize">{{ t("Add") }}</span>
            </n-button>
          </b-col>
        </b-row>
      </b-col>
      <b-col
        cols="12"
        class="p-2 mt-4"
        style="background-color: rgb(245 246 250 / 55%); border-radius: 5px"
      >
        <paginated-table
          :showHeader="false"
          :itemsPerPage="query.limit"
          :rows="rows"
          :columns="columns"
          :actions="actions"
          :search="['value']"
          @item-remove="remove_from_list"
          v-model="search_field_value"
          :updating="updating"
          :serverpagination="search_pagination"
          @prev="handlePrevPage"
          @next="handleNextPage"
          @pagination="handlePagination"
          :searchText="isDNS ? 'ipv4SearchText' :'ipv4ipv6SearchText'"
        />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { PaginatedTable } from "@/components";
import GeneralMixin from "@/components/AsmConfigurations/general/GeneralMixin";
import ServerSidePaginatedTable from "@/mixins/ServerSidePaginatedTable";
import {validateIpv4Regex, validateIpv6Regex} from "@/util/regexConsts";

export default {
  mixins: [GeneralMixin, ServerSidePaginatedTable],
  name: "server-paginated-list",
  components: {
    PaginatedTable,
  },
  props: {
    title: String,
    subtitle: String,
    action: String,
    isDNS:{
      type: Boolean,
      default: false
    },
    value: {
      type: Array,
      default: () => [],
    },
    querys: {
      type: Object,
      required: true
    },
    permissions: {
      type: Array,
      default: () => [],
    },
    columns: {
      type: Array,
      required: true
    },
    search_field: {
      type: String,
      default: () => 'value'
    }
  },
  data() {
    return {
      new_allowed: "",
      actions: {
        minWidth: 30,
        fixed: "right",
        items: [
          {
            type: "danger",
            size: "sm",
            icon: "now-ui-icons ui-1_simple-remove",
            event: "item-remove",
            action: this.t("Remove"),
            confirm: false,
            visible_for_permissions: []
          },
        ],
      },
      currentPage: 1,
      nextPage: 0,
      changingPage: false,
      updating: false,
      query: {
        sort: "asc",
        limit: 10,
      },
      self_query: {}
    };
  },
  created() {
    this.refresh()
    this.actions.items.forEach(item => item.visible_for_permissions = this.permissions);

    this.$validator.extend(
    'ipv4',{
        getMessage: () =>  `${this.t('ipValidationMessage')}`,
        validate: (value) => {
          if (!validateIpv4Regex(value)) return false;
          return true;
        }
    });

     this.$validator.extend(
    'router',{
        getMessage: () =>  `${this.t('ipipv6ValidationMessage')}`,
        validate: (value) => {
          // TODO: Domains (Phase 2)
          if (!validateIpv6Regex(value) && !validateIpv4Regex(value)) return false;
          return true;
        }
    });
  },
  methods: {
    refresh() {
      this.updating = true;     
      this.$emit("get-list", { query: this.query });
      
      this.$nextTick(() => {
        // will run after $emit is done
        if (this.changingPage) {
          this.currentPage += this.nextPage;
        }
       // this.updating = false;
      })
    },
    add_new_allowed(item) {
      if (item && !this.value.includes(item)) {
        this.$emit("update_list", { action: "add", item, query: this.self_query });
      }
      this.$nextTick( () => {
        this.new_allowed = "";
        this.$validator.reset();
      })
      this.currentPage = 1;
      this.nextPage = 0;
      this.reload();
    },
    remove_from_list(item) {
      this.$emit("update_list", { action: "del", item: item.row.value, query: this.self_query });
       this.$nextTick( () => {
        this.new_allowed = "";
        this.$validator.reset();
      })
      this.currentPage = 1;
      this.nextPage = 0;
      this.refresh();
    },
    handlePrevPage() {
      if (this.query) {        
        this.updating = true;
        ["next_id", "next_name"].forEach((item) => {
          if (this.query[item]) delete this.query[item];
        });
        if (this.query.prev_id) {
          this.nextPage = -1;
          this.changingPage = true;
          this.refresh();
        }
      }
    },
    handleNextPage() {
      if (this.query) {        
        this.updating = true;
        ["prev_id", "prev_name"].forEach((item) => {
          if (this.query[item]) delete this.query[item];
        });
        
        if (this.query.next_id) {
          this.nextPage = 1;
          this.changingPage = true;
          this.refresh();
        }
      }
    },
    handlePagination(value) {
      this.currentPage = 1;
      this.changingPage = false;
      this.self_query =  {
        limit: value,
        ip_operator: "sw",
        sort: "asc"
      } 
      this.reload();
    },
    reload() {
      if (this.self_query) {
        this.changingPage = false;
        this.query = this.self_query;
        this.refresh();
      }
    },
  },
  computed: {
    rows() {
      let index = 0;

      return this.value.map((item) => {
        index++;
        return Object.create(
          {},
          { value: { value: item }, index: { value: index } }
        );
      });
    },
    search_field_value: {
      get(){
        if( this.query && this.query.field_value)
          return { field: this.search_field, value: this.query.field_value}
        else
          return  { field: this.search_field, value: ''}
      },
      set(newValue){
          this.handleSearch(newValue)
      }
    },
    search_pagination: {
      get() {
        return {
          prev: !!this.query && !!this.query.prev_id,
          next: !!this.query && !!this.query.next_id,
          currentPage: this.currentPage,
        };
      },
    },
  },
  watch: {
    querys(newValue) {      
      this.query = _.cloneDeep(newValue.query);      
      this.updating = false
    }
  }
};
</script>

<style lang="scss" scoped>
.disabled {
  top: 0;
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: transparent;
  cursor: not-allowed;
}
.title-list-icon {
  width: 48px;
  margin-right: 8px;
}
</style>
