<template>
  <b-container fuild style="max-width: 2560px;">
    <b-row>
      <b-col lg="12" class="row-eq-height">
        <filtering-title 
          @save='updateServiceConfiguration'
          @refresh='refresh'
          :disableSubmit='disableSubmit'
          :loading='loading'
          :updating='updating' />
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="12" xl="6" class="entranceFromLeft row-eq-height" style="flex-direction: column;">
        <filtering-configuration 
          :filtering-configuration='filteringConfiguration'
          />
        <dns-blocking-configuration 
          ref='dns-blocking-configuration'
          :dns-blocking-configuration='dnsBlockingConfiguration'
        />
      </b-col>
      <b-col lg="12" xl="6" class="entranceFromRight row-eq-height">
        <web-blocking-configuration 
          ref='web-blocking-configuration'
          :web-blocking-configuration='webBlockingConfiguration'
          :blocking-pages='blockingPages'
        />
      </b-col>
    </b-row>
    <confirmation-modal ref='confirmation-modal'/>
  </b-container>
</template>

<script>
import FilteringTitle from './FilteringTitle.vue';
import FilteringConfiguration from './FilteringConfiguration.vue';
import DnsBlockingConfiguration from './DnsBlockingConfiguration.vue';
import WebBlockingConfiguration from './WebBlockingConfiguration.vue';
import ConfirmationModal from "@/components/Modals/ConfirmationModal.vue";

export default {
  name: "webFilterAdvancedFiltering",
  components: {
    FilteringTitle,
    FilteringConfiguration,
    DnsBlockingConfiguration,
    WebBlockingConfiguration,
    ConfirmationModal,
  },
  data() {
    return {
      filteringConfiguration: {
        enableSafeSearch: false,
        uploadUncategorizedHosts: false,
      },
      dnsBlockingConfiguration: {
        domainResponse: '',
        ipv4Response: '',
        ipv6Response: '',
      },
      webBlockingConfiguration: {
        http: {
          responseCode: '200',
          pageContent: 'url',
          staticText: '',
          htmlCode: '',
        },
        https: {
          responseCode: '200',
        },
        blockingPage: {
          pageMode: 'default',
          customBlockingPageUrl: '',
        },
        blockingPasswordOverridePage: {
          pageMode: 'default',
          customBlockingPageUrl: '',
        },
      },
      webBlockingConfigurationString: '',
      blockingPages: {
        defaultBlockingPage: {
          name: '',
          url: ''
        },
        defaultPasswordBlockingPage: {
          name: '',
          url: ''
        },
      },
      loading: false,
      updating: false,
    };
  },
  computed: {
    disableSubmit() {
      return !!this.errors.items.length || this.loading;
    },
  },
  methods: {
    webBlockingConfigurationHasChanges() {
      return this.webBlockingConfigurationString !== JSON.stringify(this.webBlockingConfiguration);
    },
    async setDefaultBlockingPages() {
      await this.$store.dispatch('networksecure/contentFilter/getBlockingPages');

      const blockingPages = await this.$store.getters['networksecure/contentFilter/blockingPages'];
      const blockingPageName = blockingPages.default_blocking_page;
      const passwordBlockingPageName = blockingPages.default_password_blocking_page;

      let blockingPageUrl = '';
      let passwordBlockingPageUrl = '';

      if (blockingPageName) {
        if (blockingPageName === 'SystemBlockingPage' && blockingPages.blocking_pages_info) {
          blockingPageUrl = blockingPages.blocking_pages_info.system_blocking_page_url;
        } else {
          const blockingPage = blockingPages.blocking_pages_info.external_blocking_pages
          .find(page => page.name === blockingPageName);

          blockingPageUrl = blockingPage ? blockingPage.blocking_page_url : '';
        }

        Object.assign(this.blockingPages.defaultBlockingPage, {
          name: blockingPageName,
          url: blockingPageUrl
        });
      }

      if (passwordBlockingPageName) {
        if (passwordBlockingPageName === 'SystemPasswordBlockingPage' && blockingPages.blocking_pages_info) {
          passwordBlockingPageUrl = blockingPages.blocking_pages_info.system_password_blocking_page_url;
        } else {
          const passwordBlockingPage = blockingPages.blocking_pages_info.external_blocking_pages
            .find(page => page.name === passwordBlockingPageName);

          passwordBlockingPageUrl = passwordBlockingPage ? passwordBlockingPage.blocking_page_url : '';
        }

        Object.assign(this.blockingPages.defaultPasswordBlockingPage, {
          name: passwordBlockingPageName,
          url: passwordBlockingPageUrl
        });
      }
    },
    async refresh() {
      const isConfirmed = await this.$refs['confirmation-modal'].open({
        title: this.t('refreshTitle'),
        message: this.t('refreshAlert'),
      });

      if (isConfirmed) { 
        this.setServiceConfig();
      }
    },
    async updateServiceConfiguration() {
      const isValidForm =
        (await this.$refs['dns-blocking-configuration'].$validator.validateAll()) &&
        (await this.$refs['web-blocking-configuration'].$validator.validateAll());

      if (!isValidForm) return false;

      if (this.webBlockingConfigurationHasChanges()) {
        const isConfirmed = await this.$refs['confirmation-modal'].open({
          title: this.t('saveChanges'),
          message: this.t('confirmWebBlockingConfigurationChanges'),
        });

        if (!isConfirmed) { return; }
      }

      this.loading = true;

      const webfilter = {
        filtering_configuration: {
          enable_safe_search: this.filteringConfiguration.enableSafeSearch,
          upload_uncategorized_hosts: this.filteringConfiguration.uploadUncategorizedHosts,
        },
        dns_blocking_configuration: {
          ...(this.dnsBlockingConfiguration.domainResponse && {
            domain_response: this.dnsBlockingConfiguration.domainResponse,
          }),
          ...(this.dnsBlockingConfiguration.ipv4Response && {
            ipv4_response: this.dnsBlockingConfiguration.ipv4Response,
          }),
          ...(this.dnsBlockingConfiguration.ipv6Response && {
            ipv6_response: this.dnsBlockingConfiguration.ipv6Response,
          }),
        },
        web_blocking_configuration: {
          http: {
            response_code: this.webBlockingConfiguration.http.responseCode,
            page_content: this.webBlockingConfiguration.http.pageContent,
            ...(this.webBlockingConfiguration.http.staticText && this.webBlockingConfiguration.http.pageContent === "static" && {
              static_text:this.webBlockingConfiguration.http.staticText,
            }),
            ...(this.webBlockingConfiguration.http.htmlCode && this.webBlockingConfiguration.http.pageContent === "html" && {
              html_code: this.webBlockingConfiguration.http.htmlCode,
            }),
          },
          https: {
            response_code: this.webBlockingConfiguration.https.responseCode,
          },
          blocking_page: {
            page_mode: this.webBlockingConfiguration.blockingPage.pageMode,
            ...(this.webBlockingConfiguration.blockingPage.customBlockingPageUrl && {
              custom_blocking_page_url: this.webBlockingConfiguration.blockingPage.customBlockingPageUrl,
            }),
          },
          blocking_password_override_page: {
            page_mode: this.webBlockingConfiguration.blockingPasswordOverridePage.pageMode,
            ...(this.webBlockingConfiguration.blockingPasswordOverridePage.customBlockingPageUrl && {
              custom_blocking_page_url: this.webBlockingConfiguration.blockingPasswordOverridePage.customBlockingPageUrl,
            }),
          },
        },
      };

      await this.$store.dispatch('networksecure/contentFilter/updateServiceConfiguration', { webfilter });

      this.webBlockingConfigurationString = JSON.stringify(this.webBlockingConfiguration);

      this.loading = false;
    },
    async setServiceConfig() {
      this.updating = true;

      await this.$store.dispatch('networksecure/contentFilter/getServiceConfiguration');

      const { webfilter } = await this.$store.getters['networksecure/contentFilter/serviceConfiguration'];

      this.setDefaultBlockingPages();

      this.updating = false;

      if (!webfilter) { return; }

      const filteringConfiguration = webfilter.filtering_configuration;
      const dnsBlockingConfiguration = webfilter.dns_blocking_configuration;
      const webBlockingConfiguration = webfilter.web_blocking_configuration;

      Object.assign(this.filteringConfiguration, {
        ...(filteringConfiguration && {
          enableSafeSearch: filteringConfiguration.enable_safe_search,
        }),
        ...(filteringConfiguration && {
          uploadUncategorizedHosts: filteringConfiguration.upload_uncategorized_hosts,
        }),
      });

      Object.assign(this.dnsBlockingConfiguration, {
        ...(dnsBlockingConfiguration && {
          domainResponse: dnsBlockingConfiguration.domain_response,
        }),
        ...(dnsBlockingConfiguration && {
          ipv4Response: dnsBlockingConfiguration.ipv4_response,
        }),
        ...(dnsBlockingConfiguration && {
          ipv6Response: dnsBlockingConfiguration.ipv6_response,
        }),
      });

      Object.assign(this.webBlockingConfiguration.http, {
        ...(webBlockingConfiguration && webBlockingConfiguration.http && {
          responseCode: webBlockingConfiguration.http.response_code
        }),
        ...(webBlockingConfiguration && webBlockingConfiguration.http && {
          pageContent: webBlockingConfiguration.http.page_content
        }),
        ...(webBlockingConfiguration && webBlockingConfiguration.http && {
          staticText: webBlockingConfiguration.http.static_text
        }),
        ...(webBlockingConfiguration && webBlockingConfiguration.http && {
          htmlCode: webBlockingConfiguration.http.html_code
        }),
      });

      Object.assign(this.webBlockingConfiguration.https, {
        ...(webBlockingConfiguration && webBlockingConfiguration.https && {
          responseCode: webBlockingConfiguration.https.response_code
        }),
      });

      Object.assign(this.webBlockingConfiguration.blockingPage, {
        ...(webBlockingConfiguration && webBlockingConfiguration.blocking_page && {
          pageMode: webBlockingConfiguration.blocking_page.page_mode
        }),
        ...(webBlockingConfiguration && webBlockingConfiguration.blocking_page && {
          customBlockingPageUrl: webBlockingConfiguration.blocking_page.custom_blocking_page_url
        }),
      });

      Object.assign(this.webBlockingConfiguration.blockingPasswordOverridePage, {
        ...(webBlockingConfiguration && webBlockingConfiguration.blocking_password_override_page && {
          pageMode: webBlockingConfiguration.blocking_password_override_page.page_mode
        }),
        ...(webBlockingConfiguration && webBlockingConfiguration.blocking_page && {
          customBlockingPageUrl: webBlockingConfiguration.blocking_password_override_page.custom_blocking_page_url
        }),
      });

      this.webBlockingConfigurationString = JSON.stringify(this.webBlockingConfiguration);
    }
  },
  created() {
    this.setServiceConfig();
  },
}
</script>
