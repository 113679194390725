/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'device-printer': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path pid="0" d="M6.4 0v6.4H0v19.2h6.4V32h19.202v-6.4H32V6.4h-6.398V0H6.4zm3.202 6.4h12.8V3.2h-12.8v3.2zM3.2 9.6h25.6v12.798h-3.198V19.2H6.4v3.198H3.2V9.6zm6.402 19.2h12.8v-6.402h-12.8V28.8zM6.4 16h3.202v-3.2H6.4V16z" _fill="#000" fill-rule="evenodd"/>'
  }
})
