<template>
  <nested-sidebar-item
    class='svg-icon-24'
    :link="{
      name: 'ISP Services',
      query: $route.query,
      path: '/isp_services',
      svgicon: 'item-ispservices',
    }"
    menu
    id="IspServices"
    :visible_for_permissions="[
      { actions: ['GET'], path: '/isp-categories' },
      { actions: ['GET'], path: '/notifications/advanced-configuration' },
      { actions: ['GET'], path: '/isp-service-advanced-configuration' },
      { actions: ['GET'], path: '/isp-forbidden-lists/.*' },
      { actions: ['GET'], path: '/isp-allowed-lists/.*' },
    ]"
    operator="OR"
    v-if="!$store.getters['settings/isIOT']"
  >
    <nested-sidebar-item
      :link="{
        name: 'Configuration',
        query: $route.query,
        path: '/isp_services/configuration',
        svgicon: 'item-configuration',
      }"
      menu
      id="menu_Configuration"
      :visible_for_permissions="[
        { actions: ['GET'], path: '/isp-categories' },
        { actions: ['GET'], path: '/notifications/advanced-configuration' },
      ]"
      operator="OR"
    >
      <nested-sidebar-item
        v-for="(link, key) in configurations"
        :link="link"
        :key="key"
        :visible_for_permissions="link.visible_for_permissions"
        v-if="(link.name != 'Notifications' && link.name != 'Category Management') ? true : !$store.getters['settings/isIOT'] ? true : false"
      />
    </nested-sidebar-item>
    <nested-sidebar-item
      :link="{
        name: 'Services',
        query: $route.query,
        path: '/isp_services/services',
        svgicon: 'item-services',
      }"
      menu
      id="menu_Services"
      :visible_for_permissions="[
        { actions: ['GET'], path: '/isp-service-advanced-configuration' },
        { actions: ['GET'], path: '/isp-forbidden-lists/.*' },
        { actions: ['GET'], path: '/isp-allowed-lists/.*' },
      ]"
      operator="OR"
      v-if="!$store.getters['settings/isIOT']"
    >
      <nested-sidebar-item
        v-for="(service, key) in services"
        :link="{
          name: service.name,
          query: $route.query,
          path: '/isp_services/services',
          svgicon: service.svgicon,
        }"
        menu
        :key="key"
        :visible_for_services="service.visible_for_services"
        :visible_for_permissions="service.visible_for_permissions"
        :operator="service.operator"
      >
        <nested-sidebar-item
          :link="{ ...service, name: 'Service Configuration', svgicon: 'item-configuration' }"
        />
        <nested-sidebar-item v-if="service.child" :link="service.child" />
      </nested-sidebar-item>
    </nested-sidebar-item>
  </nested-sidebar-item>
</template>

<script>
export default {
  name: "IspServicesNavbar",
  data() {
    return {
      configurations: [
        {
          name: "Profiles",
          query: this.$route.query,
          path: "/isp_services/configuration/profiles",
          visible_for_permissions: [{ actions: ["GET"], path: "/profiles" }],
          operator: "",
          svgicon: 'item-profile',
        },
        {
          name: "Category Management",
          query: this.$route.query,
          path: "/isp_services/configuration/category-management",
          svgicon: 'item-management',
        },
        {
          name: "Notifications",
          query: this.$route.query,
          path: "/isp_services/configuration/notifications",
          svgicon: 'item-notification',
        },
      ],
      services: [
        {
          name: "Content Filtering",
          query: this.$route.query,
          path: "/isp_services/services/parental_control",
          visible_for_services: "parental_control",
          visible_for_permissions: [
            { actions: ["GET"], path: "/isp-service-advanced-configuration" },
            { actions: ["GET"], path: "/isp-forbidden-lists/.*" },
          ],
          operator: "OR",
          child: {
            name: "Allowed & Forbidden Lists",
            query: this.$route.query,
            path:
              "/isp_services/services/forbidden_allowed_lists/parental_control",
            svgicon: 'icon-listasblancas',
          },
          svgicon: 'item-contentfilter',
        },
        {
          name: "Threat Protection",
          query: this.$route.query,
          path: "/isp_services/services/threat_protection",
          visible_for_services: "threat_protection",
          visible_for_permissions: [
            { actions: ["GET"], path: "/isp-service-advanced-configuration" },
            { actions: ["GET"], path: "/isp-forbidden-lists/.*" },
          ],
          operator: "OR",
          child: {
            name: "Allowed & Forbidden Lists",
            query: this.$route.query,
            path:
              "/isp_services/services/forbidden_allowed_lists/threat_protection",
            svgicon: 'icon-listasblancas',
          },
          svgicon: 'item-threatprotection',
        },
        {
          name: "Ads Free",
          query: this.$route.query,
          path: "/isp_services/services/ads_free",
          visible_for_services: "ads_free",
          visible_for_permissions: [
            { actions: ["GET"], path: "/isp-service-advanced-configuration" },
            { actions: ["GET"], path: "/isp-allowed-lists/.*" },
          ],
          operator: "OR",
          child: {
            name: "Allowed Lists",
            query: this.$route.query,
            path: "/isp_services/services/forbidden_allowed_lists/ads_free",
            svgicon: 'icon-listasblancas',
          },
          svgicon: 'item-adsfree',
        },
        {
          name: "Auto Notices",
          query: this.$route.query,
          path: "/isp_services/services/auto_notice",
		  visible_for_services: "auto_notice",
          visible_for_permissions: [
            { actions: ["GET"], path: "/isp-service-advanced-configuration" },
            { actions: ["GET"], path: "/isp-allowed-lists/.*" },
          ],
          operator: "OR",
          child: {
            name: "Allowed Lists",
            query: this.$route.query,
            path: "/isp_services/services/forbidden_allowed_lists/auto_notice",
            svgicon: 'icon-listasblancas',
          },
          svgicon: 'item-autonotice',
        },
        {
          name: "Bullying Protection",
          query: this.$route.query,
          path: "/isp_services/services/anti_bullying",
          visible_for_services: "anti_bullying",
          visible_for_permissions: [
            { actions: ["GET"], path: "/isp-service-advanced-configuration" },
          ],
          operator: "",
          svgicon: 'item-bullingprotection',
        },
        {
          name: "Location Management",
          query: this.$route.query,
          path: "/isp_services/services/location_management",
          visible_for_services: "location_management",
          visible_for_permissions: [
            { actions: ["GET"], path: "/isp-service-advanced-configuration" },
          ],
          operator: "",
          svgicon: 'item-locationmanagement',
        },
      ],
    };
  },
};
</script>
