<template>
  <b-card>
    <h5 class="title" style="display: flex; align-items: center;">
      <svgicon class="icon mr-2" icon="item-blockingconfiguration" width="20" height="20" />
      <span style="margin-top: 4px;">{{t('WebBlockingConfiguration')}}</span>
    </h5>
    <b-card-text class='pl-4 pr-4'>
      <div style="display: flex; align-items: center; justify-content: space-between; max-width: 450px;">
        <label class="mr-3">{{t('HTTPResponseCode')}}</label>
        <el-select
          size="large"
          :placeholder="t('HTTPResponseCode')"
          v-model="webBlockingConfiguration.http.responseCode">
          <el-option v-for="(option, index) in reducedResponseCodes"
            :value="option.value"
            :label="option.label"
            :key="`HTTP_code_${index}`">
          </el-option>
        </el-select>
      </div>
    </b-card-text>
    <b-card-text class='pl-4 pr-4'>
      <div style="display: flex; align-items: center; justify-content: space-between; max-width: 450px;">
        <label class="mr-3">{{t('label200OKResponse')}}</label>
        <el-select
          size="large"
          :placeholder="t('label200OKResponse')"
          :disabled="webBlockingConfiguration.http.responseCode !== '200'"
          v-model="webBlockingConfiguration.http.pageContent">
          <el-option v-for="(option, index) in contentTypeOptions"
            :value="option.value"
            :label="option.label"
            :key="`HTTP_content_${index}`">
          </el-option>
        </el-select>
      </div>
    </b-card-text>
    <b-card-text class='pl-4 pr-4 mt-4'>
      <label class="mr-2">{{t('blockingPage')}}</label>
      <div style="display: flex; align-items: center; justify-content: space-between;">
        <el-select class="select-primary mr-3"
          style="min-width: 130px;"
          size="large"
          :disabled="disableBlockingPage"
          v-model="webBlockingConfiguration.blockingPage.pageMode">
          <el-option v-for="(option, index) in blockingPageMods"
            class="select-primary"
            :value="option.value"
            :label="option.label"
            :key="`HTTP_blocking_${index}`">
          </el-option>
        </el-select>
        <fg-input v-model="webBlockingConfiguration.blockingPage.customBlockingPageUrl"
          v-if="webBlockingConfiguration.blockingPage.pageMode === 'custom'"
          style="width: 100%;"
          class="mb-0"
          id="blocking_page"
          :name="t('blockingPage')"
          placeholder="URL"
          autocapitalize="yes"
          v-validate="{ required: customBlockingPageIsRequired, url: { require_protocol: true }, max: 2048 }"
          :disabled="disableBlockingPage"
          :visible_for_permissions='[{actions: ["PATCH"], path: "/subsystems/ns/service-configuration"}]'
          :error="getError(t('blockingPage'))">
        </fg-input>
        <fg-input v-model="defaultBlockingPageText"
          v-if="webBlockingConfiguration.blockingPage.pageMode === 'default'"
          style="width: 100%;"
          class="mb-0"
          id="default_blocking_page"
          name="default_blocking_page"
          autocapitalize="yes"
          :disabled="true">
        </fg-input>
      </div>
    </b-card-text>
    <b-card-text class='pl-4 pr-4 mt-4'>
      <label>{{t('staticText')}}</label>
      <fg-input v-model="webBlockingConfiguration.http.staticText"
        class="br5"
        id="static_text"
        :name="t('staticText')"
        :placeholder="t('text')"
        autocapitalize="yes"
        :showRemainsChar="true"
        :showIcon="false"
        :maxlength="2048"
        :disabled="disableStaticText"
        v-validate="{ required: staticTextIsRequired }"
        :error="getError(t('staticText'))"
        :visible_for_permissions='[{actions: ["PATCH"], path: "/subsystems/ns/service-configuration"}]'>
      </fg-input>
    </b-card-text>
    <b-card-text class='pl-4 pr-4 mt-4'>
      <label>{{t('HTMLCode')}}</label>
      <fg-input v-model="webBlockingConfiguration.http.htmlCode"
        class="br5"
        id="html_code"
        :name="t('HTMLCode')"
        :placeholder="t('HTMLCode')"
        :textarea='true'
        rows='3'
        autocapitalize="yes"
        :showRemainsChar="true"
        :showIcon="false"
        :maxlength="8192"
        :disabled="disableHtmlText"
        v-validate="{ required: htmlCodeIsRequired }"
        :error="getError(t('HTMLCode'))"
        :visible_for_permissions='[{actions: ["PATCH"], path: "/subsystems/ns/service-configuration"}]'>
      </fg-input>
    </b-card-text>
    <b-card-text class='pl-4 pr-4 mt-4'>
      <label class="mr-2">{{t('blockingPasswordOverridePage')}}</label>
      <div style="display: flex; align-items: center; justify-content: space-between;">
        <el-select class="select-primary mr-3"
          style="min-width: 130px;"
          size="large"
          v-model="webBlockingConfiguration.blockingPasswordOverridePage.pageMode">
          <el-option v-for="(option, index) in blockingPageMods"
            class="select-primary"
            :value="option.value"
            :label="option.label"
            :key="`HTTP_blocking_${index}`">
          </el-option>
        </el-select>
        <fg-input v-model="webBlockingConfiguration.blockingPasswordOverridePage.customBlockingPageUrl"
          v-if="webBlockingConfiguration.blockingPasswordOverridePage.pageMode === 'custom'"
          style="width: 100%;"
          class="mb-0"
          id="blocking_password_override_page"
          :name="t('blockingPasswordOverridePage')"
          placeholder="URL"
          autocapitalize="yes"
          v-validate="{ required: customBlockingPasswordPageIsRequired, url: { require_protocol: true }, max: 2048 }"
          :visible_for_permissions='[{actions: ["PATCH"], path: "/subsystems/ns/service-configuration"}]'
          :error="getError(t('blockingPasswordOverridePage'))">
        </fg-input>
        <fg-input v-model="defaultPasswordBlockingPageText"
          v-if="webBlockingConfiguration.blockingPasswordOverridePage.pageMode === 'default'"
          style="width: 100%;"
          class="mb-0"
          id="default_blocking_password_override_page"
          name="default_blocking_password_override_page"
          autocapitalize="yes"
          :disabled="true">
        </fg-input>
      </div>
    </b-card-text>
    <b-card-text class='pl-4 pr-4'>
      <div style="display: flex; align-items: center; justify-content: space-between; max-width: 450px;">
        <label class="mr-3">{{t('HTTPsResponseCode')}}</label>
        <el-select
          size="large"
          :placeholder="t('HTTPsResponseCode')"
          v-model="webBlockingConfiguration.https.responseCode">
          <el-option v-for="(option, index) in responseCodes"
            :value="option.value"
            :label="option.label"
            :key="`HTTPs_code_${index}`">
          </el-option>
        </el-select>
      </div>
    </b-card-text>
  </b-card>
</template>

<script>
import { ToggleList } from '@/components';

export default {
  name: "webFilterWebBlockingConfiguration",
  props: {
    webBlockingConfiguration: {
      type: Object,
      required: true,
    },
    blockingPages: {
      type: Object,
      required: true,
    },
  },
  computed: {
    staticTextIsRequired() {
      return this.webBlockingConfiguration.http.pageContent === 'static';
    },
    htmlCodeIsRequired() {
      return this.webBlockingConfiguration.http.pageContent === 'html';
    },
    customBlockingPageIsRequired() {
      return this.webBlockingConfiguration.blockingPage.pageMode === 'custom';
    },
    customBlockingPasswordPageIsRequired() {
      return this.webBlockingConfiguration.blockingPasswordOverridePage.pageMode === 'custom';
    },
    defaultBlockingPageText() {
      return `${this.blockingPages.defaultBlockingPage.name} - ${this.blockingPages.defaultBlockingPage.url}`;
    },
    defaultPasswordBlockingPageText() {
      return `${this.blockingPages.defaultPasswordBlockingPage.name} - ${this.blockingPages.defaultPasswordBlockingPage.url}`;
    },
    disableBlockingPage() {
      return !(this.webBlockingConfiguration.http.responseCode === '200' &&
        this.webBlockingConfiguration.http.pageContent === 'url') &&
          this.webBlockingConfiguration.http.responseCode !== '302' &&
          this.webBlockingConfiguration.http.responseCode !== '307';
    },
    disableStaticText() {
      return !(this.webBlockingConfiguration.http.responseCode === '200' &&
        this.webBlockingConfiguration.http.pageContent === 'static');
    },
    disableHtmlText() {
      return !(this.webBlockingConfiguration.http.responseCode === '200' &&
        this.webBlockingConfiguration.http.pageContent === 'html');
    },
    responseCodes () {
      return [
        {
          value: '200',
          label: this.t('code200label'),
        },
        {
          value: '302',
          label: this.t('code302label'),
        },
        {
          value: '307',
          label: this.t('code307label'),
        },
        {
          value: '404',
          label: this.t('code404label'),
        },
        {
          value: '500',
          label: this.t('code500label'),
        },
        {
          value: '502',
          label: this.t('code502label'),
        },
        {
          value: 'reset',
          label: this.t('codeResetlabel'),
        },
      ];
    },
    reducedResponseCodes () {
      return this.responseCodes.reduce((accumulator, currentItem) => {
        if (currentItem.value !== '502' && currentItem.value !== 'reset') {
          return [...accumulator, currentItem];
        }

        return accumulator;
      }, []);
    },
    contentTypeOptions () {
      return [
        {
          value: 'url',
          label: this.t('blockWithUrl'),
        },
        {
          value: 'static',
          label: this.t('staticText'),
        },
        {
          value: 'html',
          label: this.t('HTMLCode'),
        },
      ];
    },
    blockingPageMods () {
      return [
        {
          value: 'default',
          label: this.t('default'),
        },
        {
          value: 'custom',
          label: this.t('custom'),
        },
      ];
    },
  },
  methods: {
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
  },
}
</script>
