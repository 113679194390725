<template>
  <span class="label-with-description">
    {{label}}
    <legend>{{legend}}</legend>
  </span>
</template>

<script>
export default {
  name: 'label-legend',
  props: {
    label: {
      type: String,
      default: ''
    },
    legend: {
      type: String,
      default: ''
    }
  },
}
</script>

<style lang="scss">
  span.label-with-description {
    display: flex;
    color: #fff9;
    white-space: nowrap;

    legend {
      font-size: 1em;
      margin-left: 2em;
      color: navajowhite;
    }
  }
</style>