/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'item-status': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path pid="0" d="M26.182 0A5.818 5.818 0 0132 5.818v20.364A5.818 5.818 0 0126.182 32H5.818A5.818 5.818 0 010 26.182V5.818A5.818 5.818 0 015.818 0h20.364zm0 2.91H5.818A2.91 2.91 0 002.91 5.817v20.364a2.91 2.91 0 002.91 2.91h20.363a2.91 2.91 0 002.909-2.91V5.818a2.91 2.91 0 00-2.91-2.909zM21.673 9.93l2.057 2.057-10.285 10.286-6.172-6.171 2.057-2.057 4.113 4.115 8.23-8.23z" _fill="#000" fill-rule="evenodd"/>'
  }
})
