export default {
  data() {
    return {}
  },
  methods: {
    translateHandler(txt, search, replace) {
      return txt.split(search).join(replace);
    },
  }
}
