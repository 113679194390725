<template>
    <div>
        <slot name="header">
            <h4>{{ title }} </h4>
        </slot>
        <div ref="chart" class="svg-container"></div>
    </div>
</template>

<script>
const d3 = require('d3');

export default {
  name: 'grouped-bar-chart',
  props: {
    title:{
        type: String,
        default: ''
    },
    width : {
        type: Number,
        default: 300,
        validator: value => {
            return value > 0;
        }
    },
    height : {
        type: Number,
        default: 450,
        validator: value => {
            return value > 0;
        }
    },
    data : {
        type: Array,
        default: function() {
            return [];
        },
    },
    groups: {
        type: Array,
        default: function() {
            return [];
        },
    },
  },
  data() {
    return {
        mobile: false,
        data2: [ {name: "Porn",    value:  34},
                {name: "Gambling",    value:  18},
                {name: "Hackers",     value: 54},
                {name: "Shopping",   value: 6},
                {name: "Society", value: 2},
                {name: "Chats",     value: 21},
            {name: "Games",    value:  53},
                {name: "Sports",    value:  48},
                {name: "Hackers",     value: 24},
                {name: "Art",   value: 16},
                {name: "Society", value: 5},
                {name: "Telecommunications",     value: 14},
            {name: "Games",    value:  73},
                {name: "Sports",    value:  48},
                {name: "Hackers",     value: 22},
                {name: "Press",   value: 36},
                {name: "Society", value: 5},
                {name: "Leisure",     value: 14},
            {name: "Games",    value:  63},
                {name: "Sports",    value:  41},
                {name: "News",     value: 24},
                {name: "Art",   value: 16},
                {name: "Society", value: 5},
                {name: "Generic",     value: 5},  ],
        groups2: [ "Week 1", "Week 2", "Week 3", "Week 4"]
    };
  },
  computed: {
    dataset() {
        if ( this.$store.getters.isDummy && ( !this.data || this.data.length == 0))
                return this.data2;
            else
                return this.data;
    },
    groupset() {
        if ( this.$store.getters.isDummy && ( !this.groups || this.groups.length == 0))
                return this.groups2;
            else
                return this.groups;
    },
  },
  methods: {
    _changeChartSize: function(isData){
        if ( !isData)
            this.$refs.chart.style.paddingBottom = "100px";
        //else{
        //    var ratio = this.width > 0 ? Math.floor(this.height *100 / this.width) : 0;
        //    this.$refs.chart.style.paddingBottom = "" + ratio + "%";
        //}
    },
    draw(dataset, groups, mobile){
        var noData = !dataset || !groups || dataset.length == 0 || groups.length == 0 ||
                (dataset.length == 1 && dataset[0].name== '') ||
                (groups.length == 1 && groups[0] =='No Data');
        this._changeChartSize(!noData);
        if (!dataset || dataset.length == 0 || !groups || groups.length == 0)
                return;
        if ( (dataset.length % groups.length) > 0){
                console.error("incorrect configuration: " + dataset.length + 
                        " values, " + groups.length + " groups");
                return;
        }
        
        if ( noData){
                var svg = d3.select(this.$.chart)
                    .append("svg")
                    .attr("preserveAspectRatio", "xMinYMin meet")
                    .attr("viewBox", "0 0 " + this.width + " " + this.height)
                    .attr("class", "svg-content-responsive")
                    .append("g");

                svg.selectAll(".bar")
                    .data(this.dataset)
                    .enter()
                    .append("g")
                    .append("text")
                    .attr("class", "noData")
                    .attr("x", this.width/2)
                    .attr("y", 35)
                    .text(this.groups[0]);
                return;
        }

        //var color = d3.scaleOrdinal(d3.schemePaired);
        var color = d3.scaleOrdinal().range(
            ["#13a1cb", "#6dc8bf","#f78d1e", "#ed1d24","#e23d80","#8b8d94",
                "#ff7f00", "#feda00", "#111217", "#01648d"]
        )
        dataset.forEach(function(item){
            if ( item.name != "")
                var s = color(item.name);
        });

        var legendRectSize = 18;
        var legendSpacing = 4;
        var mobile = this.mobile;

        //set up svg using margin conventions - we'll need plenty of room on the left for labels
        var margin = {
            top: 20,
            right: mobile ? 15 : 15 + (this.width/5),
            bottom: mobile ? 20 + 
                Math.ceil((legendRectSize+legendSpacing)*(1+color.domain().length/2)) : 20,
            left: 15,
        };

        var width = this.width - margin.left - margin.right;
        var height = this.height - margin.top - margin.bottom;

        var data = [];
        var group = Math.floor(dataset.length / groups.length);
        for ( var i=0; i<dataset.length; i++){
            var value = { group : groups[Math.floor(i / group)],
                gr : Math.floor(i / group),
                name : dataset[i].name,
                value : dataset[i].value 
            }
            data.push(value);
            if ( (i+1) % group == 0){
                value = { group : groups[Math.floor(i / group)],
                    gr : Math.floor(i / group),
                    name : "",
                    value : 0
                }
                data.push(value);
            }
        }

        var svg = d3.select(this.$refs.chart)
            .append("svg")
            .attr("preserveAspectRatio", "xMinYMin meet")
            .attr("viewBox", "0 0 " + this.width + " " + this.height)
            .attr("class", "svg-content-responsive")
            .append("g")
            .attr("transform", "translate(" + margin.left + "," + margin.top + ")");
        
        var y = d3.scaleLinear()
            .range([0, height])
            .domain([0, d3.max(dataset, function (d) {return d.value;})]);
        
        var x = d3.scaleBand()
            .rangeRound([0, width], .1)
            .domain(data.map(function (d) {
                return d.group + "-" + d.name;
            }));
        var xGroup = d3.scaleBand()
            .rangeRound([0, width], .1)
            .domain(this.groups.map(function (d) {
                return d;
            }));
                        
        var bars = svg.selectAll(".bar")
            .data(data)
            .enter()
            .append("g");

        //append rects
        bars.append("rect")
            .attr("class", "bar")
            .attr("y", function (d) {
                return height;
            })
            .attr("x", function (d) {
                return x(d.group + "-" + d.name);
            })
            .attr("height", function (d) {
                // initial value, see transition
                return 0;
            })
            .attr('fill', function(d, i) {
                return d.name ? color(d.name) : "";
            });

        bars.selectAll('rect')
            .transition().duration(2500)
            .attr('height', function(d) { return y(d.value)})
            .attr("y", function (d) { return height - y(d.value);});

        //add a value label to the top of each bar
        bars.append("text")
            .attr("class", "labely")
            .attr("x", function (d) {
                return x(d.group + "-" + d.name) + x.bandwidth()/2;
            })
            .attr("y", function (d) {
                return height - y(d.value) - 3;
            })
            .text(function (d) {
                return 0;
            });
        bars.selectAll("text")
            .transition()
            .delay(250)
            .duration(2000)
            .tween("text", function(d) { 
                if (d.value > 0){
                    var i = d3.interpolate(0, d.value);
                    return function(t){
                        d3.select(this).text(Math.floor(i(t)));
                    }
                }
                else{
                    return function(t){
                        d3.select(this).text("");
                    }
                }
            })

        svg.selectAll('rect')
                .attr("width", x.bandwidth() - 2)

        var xAxis = d3.axisBottom()
            .scale(xGroup)
            //no tick marks
            .tickSize(0)

        var gx = svg.append("g")
            .attr("class", "gbc_axis")
            .attr("transform", "translate(" + -x.bandwidth()/2 +"," + (height+2) + ")")
            .call(xAxis);

        //legend
        var legend = svg.selectAll('.legend')
            .data(color.domain())
            .enter()
            .append('g')
            .attr('class', 'legend')
            .attr('transform', function(d, i) {
                if ( mobile){
                    var horz = i % 2 ? width / 2 + 20 : 20;
                    var vert = Math.floor(i/2) * (legendRectSize + legendSpacing) + height + 25 ;
                }
                else{
                    var offset = (height - (legendRectSize + legendSpacing) * color.domain().length)/2;
                    var horz = width + 5;
                    var vert = offset + i * (legendRectSize + legendSpacing);      
                }
                return 'translate(' + horz + ',' + vert + ')';
            });
        legend.append('rect')
            .attr('width', legendRectSize)
            .attr('height', legendRectSize)
            .style('fill', color)
            .style('stroke', color);                                                   
            
        legend.append('text')
            .attr('x', legendRectSize + legendSpacing)
            .attr('y', legendRectSize - legendSpacing)
            .text(function(d) { return d; });
    },
    reset: function(){
        this.$refs.chart.innerHTML = "";
    },
    resize: function() {
        this.mobile = (screen.width < 540)
    },
    reload: function(){
        this.resize();
        this.reset();
        this.draw(this.dataset, this.groupset, this.mobile);
    }
  },
  created() {
    window.addEventListener('resize', this.resize);
  },
  destroyed() {
    window.removeEventListener('resize', this.resize);
  },
  mounted() {
      this.reload();
  },
  watch: {
    dataset: function(newValue, oldValue) {
        if (newValue != oldValue)
            this.reload();
    },
    mobile: function(newValue, oldValue){
        if (newValue != oldValue)
            this.reload();
    },
    groupset: function(newValue, oldValue){
        if (newValue != oldValue)
            this.reload();
    }
  }
};
</script>
<style scoped> 
    .gbc_axis {
        text-anchor: middle;
    }

    .legend {
        font-size: 0.75em;
        font-size: $font-size-base;
        font-family: "Museo Sans";
    }

</style>
