/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'report-configuration': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<g _fill="none" fill-rule="evenodd"><path pid="0" d="M10.667 3.556v1.777c0 .982.796 1.778 1.777 1.778h7.112c.983 0 1.777-.796 1.777-1.778V3.556H10.667zm-3.556 0H3.556v24.888h24.888V3.556H24.89v1.777a5.333 5.333 0 01-5.333 5.334h-7.112a5.333 5.333 0 01-5.333-5.334V3.556zM3.556 0A3.557 3.557 0 000 3.556v24.888A3.557 3.557 0 003.556 32h24.888A3.557 3.557 0 0032 28.444V3.556A3.557 3.557 0 0028.444 0H3.556z" _fill="#000"/><path pid="1" d="M10.564 16a.8.8 0 01.8.8v2.764a.8.8 0 01-.8.8H7.8a.8.8 0 01-.8-.8V16.8a.8.8 0 01.8-.8h2.764zm7.272 0a.8.8 0 01.8.8v2.764a.8.8 0 01-.8.8h-2.763a.8.8 0 01-.8-.8V16.8a.8.8 0 01.8-.8h2.763zm6.91 0a.8.8 0 01.8.8v2.764a.8.8 0 01-.8.8h-2.764a.8.8 0 01-.8-.8V16.8a.8.8 0 01.8-.8h2.763z" _fill="#0E1317" fill-rule="nonzero"/></g>'
  }
})
