import axios from "axios";

export default {
    getNetworkSecureLogServers({ commit, state }) {
      return new Promise((resolve, reject) => {
        axios.get('/subsystems/ns/filtering-logs')
          .then(res => {
            if (res && res.data && res.data.items) {
              commit('setLogServers', res.data.items);
              resolve()
            } else {
              reject()
            }
          })
          .catch(error => {
            commit('setError', error, { root: true });
            reject()
          })
      })
    },
  
    updateNetworkSecureLogServer({ commit, state, dispatch }, payload = {}) {
      console.log('updateNetworkSecureLogServer:', payload);
      return new Promise((resolve, reject) => {
        axios.put(`/subsystems/ns/filtering-logs/${payload.log_server}`, payload)
          .then(res => {
            resolve(res);
          })
          .catch(err => {
            commit('setError', err, { root: true });
            reject(err);
          });
      });
    },
  };