import * as queries from './queries';
import router from '../../router';
import { debug_reports } from '@/store/debugData'
import { translateHandler, getDateFormated } from '@/util/util'

export async function getAdminReportList({ dispatch, commit }) {
  try {
    const { items } = await queries.getAdminReportList();
    commit('SET_ADMIN_REPORT_LIST', items);
  } catch (err) {
    dispatch('setError', err, { root: true });
  }
}

export async function getAdminReportDetails({ dispatch, commit }, id) {
  try {
    const adminReportDetails = await queries.getAdminReportById(id);
    commit('SET_ADMIN_REPORT_DETAILS', adminReportDetails);
  } catch (err) {
    dispatch('setError', err, { root: true });
  }
}

export async function createAdminReport({ dispatch }, adminReport) {
  try {
    if (adminReport.hasOwnProperty('predefined')) {
      delete adminReport.predefined;
    }

    await queries.createAdminReport(adminReport);
    await dispatch('getAdminReportList');
  } catch (err) {
    dispatch('setError', err, { root: true });
  }
}

export async function updateAdminReport({ dispatch, getters }, payload) {
  try {
    const id = getters.adminReportDetails.admin_report_id;
    const adminReport = Object.assign({}, getters.adminReportDetails, payload);

    if (!adminReport.programmed) {
      delete adminReport.programming;
    }

    if (adminReport.hasOwnProperty('predefined')) {
      delete adminReport.predefined;
    }

    await queries.updateAdminReport(id, adminReport);
    await dispatch('getAdminReportList');
  } catch (err) {
    dispatch('setError', err, { root: true });
  }
}

export async function scheduleAdminReport({ dispatch, getters }, schedule) {
  try {
    const id = getters.adminReportDetails.admin_report_id;

    await queries.patchAdminReport(id, schedule);
  } catch (err) {
    dispatch('setError', err, { root: true });
  }
}


export async function deleteAdminReport({ dispatch }, id) {
  try {
    await queries.deleteAdminReport(id);
    await dispatch('getAdminReportList');
  } catch (err) {
    dispatch('setError', err, { root: true });
  }
}

export async function downloadReport(
  { dispatch, rootGetters }, { GUIModeId, query, type, name }
) {
  try {
    let response;
    const accessToken = rootGetters['auth/accessToken'];

    switch (type) {
      case 'csv':
        response = await queries.getReportCsv(GUIModeId, query, accessToken)
        break;
      case 'pdf':
        response = await queries.getReportPdf(GUIModeId, query, accessToken)
        break;
      default:
        return;
    }

    const link = document.createElement('a');
    const reportName = `report_${translateHandler(name, " ", "_")}_${getDateFormated(new Date())}.${type}` 
    link.href = window.URL.createObjectURL(new Blob([response]));
    link.setAttribute('download', reportName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (err) {
    if (err.response.status === 401) {
      const token = await dispatch('auth/refreshToken');

      if (token) {
        await dispatch('getReportView', payload);
      }
    }

    dispatch('setError', err, { root: true });
  }
}

export async function downloadReportType({ dispatch, rootGetters }, { GUIModeId, query, name, type }) {
  return new Promise((resolve, reject) => {
    const accessToken = rootGetters['auth/accessToken'];

    queries.getReport(GUIModeId, query, accessToken, type)
      .then(res => {
        const link = document.createElement('a');
        const reportName = `report_${translateHandler(name, " ", "_")}_${getDateFormated(new Date())}.${type}` 
        link.href = window.URL.createObjectURL(new Blob([res]));
        link.setAttribute('download', reportName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        resolve()
      })
      .catch(error => {
        if (error.response) {
          reject(error.response)
        }
      })
  });
}

export async function getReportConfig({ dispatch, commit }) {
  try {
    // commit('SET_REPORT_CONFIG', debug_reports);
    const { report_config } = await queries.getGUIModes();
    commit('SET_REPORT_CONFIG', report_config);

  } catch (err) {
    dispatch('setError', err, { root: true });
  }
}

export async function getReportView({ dispatch, commit, rootGetters }, payload) {
  const { GUIModeId, ...reportSettings } = payload;
  const accessToken = rootGetters['auth/accessToken'];

  try {
    const reportView = await queries.getReportView(
      GUIModeId,
      reportSettings,
      accessToken,
    );

    commit('SET_REPORT_VIEW', reportView);
  } catch (err) {
    if (err.response.status === 401) {
      const token = await dispatch('auth/refreshToken');

      if (token) {
        await dispatch('getReportView', payload);
      }
    }
    if (err.response.data.type === '101') {
      throw new Error("There is no hourly data for the time frame selected. Please refer the dates to 0:00 to get daily data and don't group by hours in the selected time frame.")
    }
    else {
      dispatch('setError', err, { root: true });
    }

  }
}

export function resetReportView({ commit }) {
  commit('RESET_REPORT_VIEW');
}

export async function getAdminReportExecutions(
  { dispatch, commit, getters },
  adminReportId,
) {
  try {
    commit('RESET_ADMIN_REPORT_EXECUTIONS');

    const GUIModeId = getters.adminReportDetails.guimode_id;
    const { next_execution, last_execution, instances } = await queries
      .getAdminReportHistoricList(adminReportId, GUIModeId);

    const adminReportExecutions = {
      nextExecution: next_execution,
      lastExecution: last_execution,
      instances,
    };

    commit('SET_ADMIN_REPORT_EXECUTIONS', adminReportExecutions);
  } catch (err) {
    if (err.response.status === 404) {
      console.log('ERR:', err.response);
    } else {
      dispatch('setError', err, { root: true });
    }
  }
}

export async function getAdminReportExecutionResults(
  { dispatch, commit, getters },
  { adminReportId, reportName },
) {
  try {
    const GUIModeId = getters.adminReportDetails.guimode_id;
    const executionResults = await queries
      .getAdminReportHistoricDetails(adminReportId, reportName, GUIModeId);
    commit('SET_REPORT_VIEW', executionResults);
  } catch (err) {
    dispatch('setError', err, { root: true });
  }
}

export function savePreviewedReportDetails({ commit }, previewedReportDetails) {
  commit('SET_PREVIEWED_REPORT_DETAILS', previewedReportDetails);
}

export function clearPreviewReportDetails({ commit }) {
  commit('RESET_PREVIEWED_REPORT_DETAILS');
}

export async function refreshToken({ dispatch }) {
  const token = await dispatch('auth/requestAccessToken', null, { root: true });

  if (!token) {
    await dispatch('auth/logout', null, { root: true });

    setTimeout(() => {
      router.push({ name: 'Login', query: { out: 'true' } });
    }, 500);
  }

  return token;
}
