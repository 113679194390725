import Content from "@/layout/dashboard/Content.vue";

const FrameLoader = () => import(
    /* webpackChunkName: "account-management" */
    "@/pages/Accounts/FrameLoader.vue"
  );
  
export default {
    path: "/logas",
    name: "Impersonate",
    component: Content,
    children: [
        {
            path: ":entity/:account_type/:account_id",
            name: "Account Management / Accounts / Impersonate",
            component: FrameLoader,
            props: true
        },
        {
            path: ":entity/:account_type/:account_id/:manager_id",
            name: "Account Management / Accounts/ Manager / Impersonate",
            component: FrameLoader,
            props: true
        },
    ]
};