/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'admin': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path pid="0" d="M28.632 14.214v6.838c0 .93-.753 1.684-1.685 1.684H5.053c-.93 0-1.685-.755-1.685-1.684v-6.838l4.581 4.58L16 10.744l8.052 8.052 4.58-4.581zM0 6.1l7.95 7.95L16 6l8.052 8.052L32 6.102v14.951a5.052 5.052 0 01-5.053 5.053H5.053A5.052 5.052 0 010 21.053V6.101z" _fill="#000" fill-rule="evenodd"/>'
  }
})
