export default {
  colors: {
    default: "#344675",
    primary: "#42b883",
    info2: "#1d8cf8",
    info: "#ffffff",
    danger: "#fd5d93",
    teal: "#00d6b4",
    primaryGradient: ["rgba(76, 211, 150, 0.1)", "rgba(53, 183, 125, 0)", "rgba(119,52,169,0)"],
  },
  available_services: ["parental_control", "ContentFiltering", "threat_protection", "ads_free",
    "auto_notice", "antispam_out", "antispam_in", "firewall", "webproxy", "wolf"],
  available_products: ["NetworkSecure", "RouterSecure", "EndpointSecure", "CBEndpoint","DNSecure"],
  available_services_ns: ["AntiVirus", "AntiPhishing", "AntiBotnet", "ContentFilter", "AdsFree", "Firewall",
    "SMTPFilter.In", "SMTPFilter.Out", "MailFilter", "IMAPFilter", "Activation", "WOLF", "WebProxy"],
  available_services_ds: ["content_filtering", "safe_search","quiet_time","anti_phishing","anti_malware","anti_botnet","ads_free"],
  available_packages: ["data_export"],
      dashboard_cards: [
            {
                  title: 'account_management',
                  gui_path: '',
                  subtitle: 'account_management_subtitle',
                  image: 'img/Account_Management.jpg',
                  items: [
                        {
                              title: 'accounts_title',
                              gui_path: '/account_management/accounts',
                              content: 'accounts_content'
                        },
                        {
                              title: 'managers_title',
                              gui_path: '/account_management/managers',
                              content: 'managers_content'
                        },
                        {
                              title: 'users_title',
                              gui_path: '/account_management/users',
                              content: 'users_content',
                              visible_for_services_ns: ["AntiVirus", "AntiPhishing", "AntiBotnet", "ContentFilter", "AdsFree",
                              "SMTPFilter.In", "SMTPFilter.Out", "MailFilter", "IMAPFilter", "Activation", "WOLF", "WebProxy"]
                        },
                        {
                              title: 'devices_title',
                              gui_path: '/account_management/devices',
                              content: 'devices_content',
                              visible_for_services_ns: ["AntiVirus", "AntiPhishing", "AntiBotnet", "ContentFilter", "AdsFree",
                              "SMTPFilter.In", "SMTPFilter.Out", "MailFilter", "IMAPFilter", "Activation", "WOLF", "WebProxy"]
                        }
                  ]
            },
            {
                  title: 'general_management',
                  gui_path: '',
                  subtitle: 'general_management_subtitle',
                  image: 'img/General_Management.jpg',
                  items: [
                        {
                              title: 'administrators_title',
                              gui_path: '/general_management/administrators',
                              content: 'administrators_content'
                        },
                        {
                              title: 'customization_title',
                              gui_path: '/general_management/customization',
                              content: 'customization_content'
                        },
                        {
                              title: 'license_title',
                              gui_path: '/general_management/license',
                              content: 'license_content'
                        },
                        {
                              title: 'authentication_title',
                              gui_path: '/general_management/authentication/isp',
                              content: 'authentication_content'
                        },
                        {
                              title: 'external_servers_title',
                              gui_path: '/general_management/external_servers',
                              content: 'external_servers_content'
                        },
                        {
                              title: 'jobs_title',
                              gui_path: '/general_management/jobs/router_jobs',
                              content: 'jobs_content'
                        },
                        {
                              title: 'data_export_title',
                              gui_path: '/general_management/data_export',
                              content: 'data_export_content',
                              visible_for_packages: 'data_export'
                        }
                  ]
            },
            {
                  title: 'isp_services',
                  gui_path: '',
                  subtitle: 'isp_services_subtitle',
                  image: 'img/ISP.jpg',
                  items: [
                        {
                              title: 'configuration_title',
                              gui_path: '/isp_services/configuration/category-management',
                              content: 'configuration_content'
                        },
                        {
                              title: 'services_title',
                              gui_path: '/isp_services/services/parental_control',
                              content: 'services_content'
                        }
                  ],
                  visible_for_services: ["parental_control", "threat_protection"]
            },
            {
                  title: 'ns',
                  gui_path: '',
                  subtitle: 'ns_subtitle',
                  image: 'img/NetworkSecure.jpg',
                  items: [
                        {
                              title: 'general_title',
                              gui_path: '/ns/General/Status',
                              content: 'general_content'
                        },
                        {
                              title: 'content_filter_title',
                              gui_path: '/ns/ContentFilter/Policies/',
                              content: 'content_filter_content',
                              visible_for_services_ns:'ContentFilter'
                        },
                        {
                              title: 'malware_title',
                              gui_path: '/ns/Malware&Phishing/Policies',
                              content: 'malware_content',
                              visible_for_services_ns:'AntiPhishing'
                        },
                        {
                              title: 'antivirus_title',
                              gui_path: '/ns/AntiVirus/Policies',
                              content: 'antivirus_content',
                              visible_for_services_ns:'AntiVirus'
                        },
                        {
                              title: 'antibotnet_title',
                              gui_path: '/ns/AntiBotnet/Policies',
                              content: 'antibotnet_content',
                              visible_for_services_ns:'AntiBotnet'
                        },
                        {
                              title: 'firewall_title',
                              gui_path: '/ns/Firewall/Policies',
                              content: 'firewall_content',
                              visible_for_services_ns:'Firewall'
                        }
                  ],
                  visible_for_products: 'NetworkSecure'
            },
            {
                  title: 'rs',
                  gui_path: '',
                  subtitle: 'rs_subtitle',
                  image: 'img/RouterSecure.jpg',
                  items: [
                        {
                              title: 'rs_agent_management',
                              gui_path: '/rs/General/GeneralManagement/AgentManagement',
                              content: 'rs_agent_management_content'
                        },
                        {
                              title: 'rs_default_agent_package',
                              gui_path: '/rs/General/GeneralManagement/DefaultAgentVersion',
                              content: 'rs_default_agent_package_content'
                        },
                        {
                              title: 'rs_troubleshooting',
                              gui_path: '/rs/General/GeneralManagement/Troubleshooting',
                              content: 'rs_troubleshooting_content'
                        },
                        {
                              title: 'rs_allowed_lists',
                              gui_path: '/rs/General/GeneralManagement/AllowedLists',
                              content: 'rs_allowed_lists_content'
                        },
                  ],
                  visible_for_products: 'RouterSecure'
            },
            {
                  title: 'eps',
                  gui_path: '/eps/download_details',
                  subtitle: 'eps_subtitle',
                  image: 'img/EndpointSecure.jpg',
                  items: [],
                  visible_for_products: 'EndpointSecure'
            },
            {
                  title: 'ds',
                  gui_path: '',
                  subtitle: 'ds_subtitle',
                  image: 'img/DNSSecure.jpg',
                  items: [
                             /* {
                                    title: 'dsstatus_title',
                                    gui_path: '/ds/general/Status',
                                    content: ''
                              },*/
                              {
                                    title: 'dscontentfiltering_title',
                                    gui_path: '/ds/cf/Policies',
                                    content: 'dnscf_content'
                              }
                  ],
                  visible_for_products: 'DNSecure'
            },
            {
                  title: 'reporter',
                  gui_path: '/reporter/templates',
                  subtitle: 'reporter_subtitle',
                  image: 'img/Reporter.jpg',
                  items: [],
                  visible_for_products: 'NetworkSecure'
            },
            {
                  title: 'profile',
                  gui_path: '/profile/edit',
                  subtitle: 'profile_subtitle',
                  image: 'img/Profile.jpg',
                  items: []
            }
      ],
    gui_paths_required_api_endpoints: {
      '/account_management/accounts': [{
            actions: ["GET"],
            path: "/accounts/.*"
      }],
      '/account_management/managers': [{
            actions: ["GET"],
            path: "/managers/.*"
      }],
      '/account_management/users': [{
            actions: ["GET"],
            path: "/users/.*"
      }],
      '/account_management/devices': [{
            actions: ["GET"],
            path: "/devices/.*"
      }],
      '/general_management/license': [{
            actions: ["GET"],
            path: "/subsystems/ns/license"
      }],
      '/general_management/authentication/isp': [{
            actions: ["GET"],
            path: "/customization"
      }],
      '/general_management/authentication/isp/identity_providers': [{
            actions: ["GET"],
            path: "/identity/session/configuration/.*"
      }],
      '/general_management/authentication/res/identity_providers': [{
            actions: ["GET"],
            path: "/identity/session/configuration/.*"
      }],
      '/general_management/authentication/ent/identity_providers': [{
            actions: ["GET"],
            path: "/identity/session/configuration/.*"
      }],
      '/general_management/external_servers': [{
            actions: ["GET"],
            path: "/external-servers"
      }],
      '/general_management/customization': [{
            actions: ["GET"],
            path: "/customization/.*"
      }],
      '/general_management/administrators': [{
            actions: ["GET"],
            path: "/administrators.*"
      }],
      '/general_management/jobs/router_jobs': [{
            actions: ["GET"],
            path: "/subsystems/rs/routers/jobs/.*"
      }],
      '/general_management/jobs/account_jobs': [{
            actions: ["GET"],
            path: "/jobs/.*"
      }],
      '/general_management/data_export': [{
            actions: ["GET"],
            path: "/data-export/configuration"
      }],
      '/reporter/templates': [{
            actions: ["GET", "POST"],
            path: "/reports/.*"
      }],
      '/profile': [{
            actions: ["GET", "PATCH"],
            path: "/manager-loggedin"
      }],
      '/isp_services/configuration/category-management': [{
            actions: ["GET"],
            path: "/isp-categories"
      }],
      '/isp_services/configuration/notifications': [{
            actions: ["GET"],
            path: "/notifications/advanced-configuration"
      }],
      '/isp_services/configuration/profiles': [{
            actions: ["GET"],
            path: "/isp-categories"
      }],
      '/isp_services/services/parental_control': [{
            actions: ["GET"],
            path: "/isp-service-advanced-configuration"
      }],
      '/isp_services/services/forbidden_allowed_lists/parental_control': [{
            actions: ["GET"],
            path: "/isp-service-advanced-configuration"
      }],
      '/isp_services/services/ads_free': [{
            actions: ["GET"],
            path: "/isp-service-advanced-configuration"
      }],
      '/isp_services/services/forbidden_allowed_lists/ads_free': [{
            actions: ["GET"],
            path: "/isp-allowed-lists/.*"
      }],
      '/isp_services/services/auto_notice': [{
            actions: ["GET"],
            path: "/isp-service-advanced-configuration"
      }],
      '/isp_services/services/forbidden_allowed_lists/auto_notice': [{
            actions: ["GET"],
            path: "/isp-allowed-lists/.*"
      }],
      '/isp_services/services/anti_bullying': [{
            actions: ["GET"],
            path: "/isp-service-advanced-configuration"
      }],
      '/isp_services/services/location_management': [{
            actions: ["GET"],
            path: "/isp-service-advanced-configuration"
      }],
      '/isp_services/services/threat_protection': [{
            actions: ["GET"],
            path: "/isp-service-advanced-configuration"
      }],
      '/isp_services/services/forbidden_allowed_lists/threat_protection': [{
            actions: ["GET"],
            path: "/isp-forbidden-lists/.*"
      }],
      '/eps/download_details': [{
            actions: ["GET"],
            path: "/subsystems/endpoint-secure/settings"
      }],
      '/ds/general/Status': [{
            actions: ["GET"],
            path: "/subsystems/ds/advanced-configuration"
      }],
      '/ds/cf/Policies': [{
            actions: ["GET"],
            path: "/subsystems/ds/content-policy-rules"
      }],
      '/ds/cf/AdvancedConfiguration': [{
            actions: ["GET"],
            path: "/subsystems/ds/advanced-configuration"
      }],
      '/ask-for-revision': [{
            actions: ["GET"],
            path: "/manager-loggedin"
      }],
      '/dashboard/general': [{
            actions: ["GET", "PATCH"],
            path: "/manager-loggedin"
      }],
      '/ns/General/Status': [{
            actions: ["GET", "PATCH"],
            path: "/subsystems/ns/general-status.*"
      }],
      '/ns/General/GeneralManagement': [{
            actions: ["GET"],
            path: "/subsystems/ns/backups"
      }],
      '/ns/General/GeneralManagement/ScheduledTasks': [{
            actions: ["GET"],
            path: "/subsystems/ns/scheduled-tasks.*"
      }],
      '/ns/General/GeneralManagement/Backup': [{
            actions: ["GET"],
            path: "/subsystems/ns/backups"
      }],
      '/ns/General/GeneralManagement/Logs': [{
            actions: ["GET"],
            path: "/subsystems/ns/filtering-logs"
      }],
      '/ns/General/GeneralManagement/Alerts': [{
            actions: ["GET"],
            path: "/subsystems/ns/alerts"
      }],
      '/ns/General/GeneralManagement/ExternalServers': [{
            actions: ["GET"],
            path: "/subsystems/ns/external-servers"
      }],
      '/ns/General/GeneralManagement/ExternalServers/': [{
            actions: ["GET"],
            path: "/subsystems/ns/external-servers"
      }],
      '/ns/General/GeneralManagement/ServicePackages': [{
            actions: ["GET"],
            path: "/subsystems/ns/autonotices/packages"
      }],
      '/ns/ContentFilter/Policies/': [{
            actions: ["GET"],
            path: "/subsystems/ns/isp-strategy"
      }],
      '/ns/ContentFilter/Policies': [{
            actions: ["GET"],
            path: "/subsystems/ns/isp-strategy"
      }],
      '/ns/ContentFilter/Advanced/Filtering': [{
            actions: ["GET"],
            path: "/subsystems/ns/service-configuration"
      }],
      '/ns/ContentFilter/Advanced/Https': [{
            actions: ["GET"],
            path: "/subsystems/ns/service-configuration"
      }],
      '/ns/AdsFree/FilteringPolicies/Policies': [{
            actions: ["GET"],
            path: "/subsystems/ns/isp-strategy"
      }],
      '/ns/AdsFree/AllowList': [{
            actions: ["GET"],
            path: "/subsystems/ns/isp-strategy"
      }],
      '/ns/AutoNotices/Policies': [{
            actions: ["GET"],
		path: "/subsystems/ns/isp-strategy"
      }],
      '/ns/AutoNotices/AdvancedConfiguration/Redirection': [{
            actions: ["GET"],
		path: "/subsystems/ns/autonotices/redirections"
      }],
      '/ns/AutoNotices/AdvancedConfiguration/Insertion': [{
            actions: ["GET"],
            path: "/subsystems/ns/autonotices/banners"
      }],
      '/ns/AutoNotices/AdvancedConfiguration/Smart': [{
            actions: ["GET"],
            path: "/subsystems/ns/autonotices/smartbanners"
      }],
      '/ns/Malware&Phishing/Policies': [{
            actions: ["GET"],
            path: "/subsystems/ns/isp-strategy"
      }],
      '/ns/Malware&Phishing/Policies/': [{
            actions: ["GET"],
            path: "/subsystems/ns/isp-strategy"
      }],
      '/ns/Malware&Phishing/Advanced': [{
            actions: ["GET"],
            path: "/isp-categories"
      }],
      '/ns/AntiVirus/Policies': [{
            actions: ["GET"],
            path: "/subsystems/ns/isp-strategy"
      }],
      '/ns/AntiVirus/Policies/': [{
            actions: ["GET"],
            path: "/subsystems/ns/isp-strategy"
      }],
      '/ns/AntiVirus/Advanced': [{
            actions: ["GET"],
            path: "/subsystems/ns/service-configuration"
      }],
      '/ns/AntiBotnet/Policies': [{
            actions: ["GET"],
            path: "/subsystems/ns/isp-strategy"
      }],
      '/ns/AntiBotnet/AdvancedConfiguration': [{
            actions: ["GET"],
            path: "/subsystems/ns/isp-strategy"
      }],
      '/ns/Firewall/Policies': [{
            actions: ["GET"],
            path: "/subsystems/ns/isp-strategy"
      }],
      '/ns/Firewall/AdvancedConfiguration': [{
            actions: ["GET"],
            path: "/subsystems/ns/firewall/advanced-configuration"
      }],
      '/rs/General/Status': [
            { actions: ["GET"], path: "/subsystems/rs/service-configuration"},
            { actions: ["GET"], path: "/subsystems/rs/service-calculated-status"},
            { actions: ["GET"], path: "/subsystems/rs/lan-protection"},
            { actions: ["GET"], path: "/subsystems/rs/router-protection"}],
      '/rs/General/GeneralManagement/AgentManagement': [{
            actions: ["GET"],
            path: "/subsystems/rs/router-agent-packages"
      }],
      '/rs/General/GeneralManagement/DefaultAgentVersion': [{
            actions: ["GET"],
            path: "/subsystems/rs/router-agent-packages"
      }, {
            actions: ["GET"],
            path: "/subsystems/rs/router-default-agent-packages"
      }],
      '/rs/General/GeneralManagement/Troubleshooting': [{
            actions: ["GET"],
            path: "/subsystems/rs/routers/.*"
      }],
      '/rs/General/GeneralManagement/AllowedLists': [{
            actions: ["GET"],
            path: "/subsystems/rs/router-protection/dns-allowed-list"
      }, {
            actions: ["GET"],
            path: "/subsystems/rs/router-protection/router-allowed-list"
      }],
      '/rs/ContentFilter/Advanced': [{
            actions: ["GET"],
            path: "/subsystems/rs/service-configuration"
      }],
      '/rs/Malware&Phishing/Advanced': [{
            actions: ["GET"],
            path: "/subsystems/rs/service-configuration"
      }],
      '/cyberbulling_endpoint/general': [{
            actions: ["GET"],
            path: "/subsystems/cb/advanced-configuration"
      }],
      '/': [{
            actions: ["GET", "PATCH"],
            path: "/manager-loggedin"
      }],

      // TODO remove provisional legacy NS permissions
      '/ns/legacy/ContentFilter/FilteringPolicies/Policies/Main/': [{
            actions: ["GET", "PATCH"],
            path: "/manager-loggedin"
      }],
      '/ns/legacy/ContentFilter/FilteringPolicies/Policies/Group/Main/': [{
            actions: ["GET", "PATCH"],
            path: "/manager-loggedin"
      }],
      '/ns/legacy/ContentFilter/FilteringPolicies/Policies': [{
            actions: ["GET", "PATCH"],
            path: "/manager-loggedin"
      }],
      '/ns/legacy/ContentFilter/FilteringPolicies/Policies/Profile/': [{
            actions: ["GET", "PATCH"],
            path: "/manager-loggedin"
      }],
      '/ns/legacy/ContentFilter/CategoryManagement/': [{
            actions: ["GET", "PATCH"],
            path: "/manager-loggedin"
      }],
      '/ns/legacy/ContentFilter/WhiteBlackLists/': [{
            actions: ["GET", "PATCH"],
            path: "/manager-loggedin"
      }],
      '/ns/legacy/ContentFilter/BlockConfig/': [{
            actions: ["GET", "PATCH"],
            path: "/manager-loggedin"
      }],
      '/ns/legacy/ContentFilter/Certificates/': [{
            actions: ["GET", "PATCH"],
            path: "/manager-loggedin"
      }],
      '/ns/legacy/AdsFree/FilteringPolicies/Policies/Main/': [{
            actions: ["GET", "PATCH"],
            path: "/manager-loggedin"
      }],
      '/ns/legacy/AdsFree/FilteringPolicies/Policies/Group/Main/': [{
            actions: ["GET", "PATCH"],
            path: "/manager-loggedin"
      }],
      '/ns/legacy/AdsFree/FilteringPolicies/Policies': [{
            actions: ["GET", "PATCH"],
            path: "/manager-loggedin"
      }],
      '/ns/legacy/AdsFree/FilteringPolicies/Policies/Profile/': [{
            actions: ["GET", "PATCH"],
            path: "/manager-loggedin"
      }],
      '/ns/legacy/AdsFree/WhiteList/': [{
            actions: ["GET", "PATCH"],
            path: "/manager-loggedin"
      }],
      '/ns/legacy/Activation/FilteringPolicies/Policies/Main/': [{
            actions: ["GET", "PATCH"],
            path: "/manager-loggedin"
      }],
      '/ns/legacy/Activation/FilteringPolicies/Policies/Group/Main/': [{
            actions: ["GET", "PATCH"],
            path: "/manager-loggedin"
      }],
      '/ns/legacy/Activation/FilteringPolicies/Policies': [{
            actions: ["GET", "PATCH"],
            path: "/manager-loggedin"
      }],
      '/ns/legacy/Activation/FilteringPolicies/Policies/Profile/': [{
            actions: ["GET", "PATCH"],
            path: "/manager-loggedin"
      }],
      '/ns/legacy/Activation/WhiteList/': [{
            actions: ["GET", "PATCH"],
            path: "/manager-loggedin"
      }],
      '/ns/legacy/Activation/Redirection/': [{
            actions: ["GET", "PATCH"],
            path: "/manager-loggedin"
      }],
      '/ns/legacy/Activation/Insertion/': [{
            actions: ["GET", "PATCH"],
            path: "/manager-loggedin"
      }],
      '/ns/legacy/Activation/Smart/': [{
            actions: ["GET", "PATCH"],
            path: "/manager-loggedin"
      }],
      '/ns/legacy/AntiPhishing/FilteringPolicies/Policies/Main/': [{
            actions: ["GET", "PATCH"],
            path: "/manager-loggedin"
      }],
      '/ns/legacy/AntiPhishing/FilteringPolicies/Policies/Group/Main/': [{
            actions: ["GET", "PATCH"],
            path: "/manager-loggedin"
      }],
      '/ns/legacy/AntiPhishing/FilteringPolicies/Policies': [{
            actions: ["GET", "PATCH"],
            path: "/manager-loggedin"
      }],
      '/ns/legacy/AntiPhishing/FilteringPolicies/Policies/Profile/': [{
            actions: ["GET", "PATCH"],
            path: "/manager-loggedin"
      }],
      '/ns/legacy/AntiPhishing/SenderList/': [{
            actions: ["GET", "PATCH"],
            path: "/manager-loggedin"
      }],
      '/ns/legacy/AntiPhishing/Advanced/': [{
            actions: ["GET", "PATCH"],
            path: "/manager-loggedin"
      }],
      '/ns/legacy/AntiPhishing/AdvNotifications/': [{
            actions: ["GET", "PATCH"],
            path: "/manager-loggedin"
      }],
      '/ns/legacy/Antivirus/FilteringPolicies/Policies/Main/': [{
            actions: ["GET", "PATCH"],
            path: "/manager-loggedin"
      }],
      '/ns/legacy/Antivirus/FilteringPolicies/Policies/Group/Main/': [{
            actions: ["GET", "PATCH"],
            path: "/manager-loggedin"
      }],
      '/ns/legacy/Antivirus/FilteringPolicies/Policies': [{
            actions: ["GET", "PATCH"],
            path: "/manager-loggedin"
      }],
      '/ns/legacy/Antivirus/FilteringPolicies/Policies/Profile/': [{
            actions: ["GET", "PATCH"],
            path: "/manager-loggedin"
      }],
      '/ns/legacy/Antivirus/Quarantine/': [{
            actions: ["GET", "PATCH"],
            path: "/manager-loggedin"
      }],
      '/ns/legacy/Antivirus/Advanced/Web/': [{
            actions: ["GET", "PATCH"],
            path: "/manager-loggedin"
      }],
      '/ns/legacy/AntiBotnet/FilteringPolicies/Policies/Main/': [{
            actions: ["GET", "PATCH"],
            path: "/manager-loggedin"
      }],
      '/ns/legacy/AntiBotnet/FilteringPolicies/Policies/Group/Main/': [{
            actions: ["GET", "PATCH"],
            path: "/manager-loggedin"
      }],
      '/ns/legacy/AntiBotnet/FilteringPolicies/Policies': [{
            actions: ["GET", "PATCH"],
            path: "/manager-loggedin"
      }],
      '/ns/legacy/AntiBotnet/WhiteList/': [{
            actions: ["GET", "PATCH"],
            path: "/manager-loggedin"
      }],
      '/ns/legacy/AntiBotnet/Advanced/': [{
            actions: ["GET", "PATCH"],
            path: "/manager-loggedin"
      }],
      '/ns/legacy/Firewall/FilteringPolicies/Policies/Main/': [{
            actions: ["GET", "PATCH"],
            path: "/manager-loggedin"
      }],
      '/ns/legacy/Firewall/FilteringPolicies/Policies/Group/Main/': [{
            actions: ["GET", "PATCH"],
            path: "/manager-loggedin"
      }],
      '/ns/legacy/Firewall/FilteringPolicies/Policies': [{
            actions: ["GET", "PATCH"],
            path: "/manager-loggedin"
      }],
      '/ns/legacy/Firewall/FilteringPolicies/Policies/Profile/': [{
            actions: ["GET", "PATCH"],
            path: "/manager-loggedin"
      }],
      '/ns/legacy/Firewall/CustomServices/': [{
            actions: ["GET", "PATCH"],
            path: "/manager-loggedin"
      }]
      // TODO remove provisional legacy NS permissions
    }
}
