/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'menu': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z"/>'
  }
})
