/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'transfer-buttons-1': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path pid="0" d="M20.708 17.771a1.88 1.88 0 011.882 1.883 1.88 1.88 0 01-1.882 1.882H6.496l2.11 2.112c.015.015.03.032.047.045a1.882 1.882 0 11-2.709 2.616l-5.06-5.06a1.883 1.883 0 011-3.478zm.835-12.196a1.884 1.884 0 012.662-.047l5.057 5.06c.552.344.887.948.885 1.598a1.88 1.88 0 01-1.884 1.88v-.002H9.439a1.882 1.882 0 110-3.764h14.214l-2.11-2.11a1.879 1.879 0 010-2.615z" _fill="#000" fill-rule="evenodd"/>'
  }
})
