/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icon-filteringlogconfig1': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<g _fill="#000" fill-rule="evenodd"><path pid="0" d="M6.71 20.104l1.886 1.886 5.658-5.656-5.656-5.658-1.887 1.887 3.772 3.77zM14.667 21.667H20V19h-5.333z"/><path pid="1" d="M27.2 3.2H4.8a1.6 1.6 0 00-1.6 1.6v22.4a1.6 1.6 0 001.6 1.6h22.4a1.6 1.6 0 001.6-1.6V4.8a1.6 1.6 0 00-1.6-1.6zM4.8 0A4.8 4.8 0 000 4.8v22.4A4.8 4.8 0 004.8 32h22.4a4.8 4.8 0 004.8-4.8V4.8A4.8 4.8 0 0027.2 0H4.8z"/></g>'
  }
})
