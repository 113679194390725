/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'report-view': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path pid="0" d="M28.444 0A3.557 3.557 0 0132 3.556v24.888A3.557 3.557 0 0128.444 32H3.556A3.557 3.557 0 010 28.444V3.556A3.557 3.557 0 013.556 0zM7.111 3.556H3.556v24.888h24.888V3.556H24.89v1.777a5.333 5.333 0 01-5.333 5.334h-7.112a5.333 5.333 0 01-5.333-5.334V3.556zm16 17.894a1.777 1.777 0 110 3.555H8.961a1.777 1.777 0 110-3.555zm-.071-7.228a1.777 1.777 0 110 3.556H8.889a1.778 1.778 0 010-3.556zM21.333 3.556H10.667v1.777c0 .982.796 1.778 1.777 1.778h7.112c.983 0 1.777-.796 1.777-1.778V3.556z" _fill="#000" fill-rule="evenodd"/>'
  }
})
