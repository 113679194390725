<template>
    <toggle-list :visible_for_permissions="visible_for_permissions">
        <template v-slot:header>
            <h5 class="title" style="display: flex;">
              <svgicon class="icon mr-2" icon="item-blocking" width="17" height="17" />
              <span style="margin-top: 2px;">{{t(blockingtitle)}}</span>
            </h5>
            <product-attention-message  :title=title
                                        :showOnlyIfProductsExist="['EndpointSecure', 'RouterSecure']" operator="OR" />
            
            <div class="mt-2">{{t(blockingsubtitle)}}:</div>            
        </template>
        <template v-slot:footer class="form-group">    
            <div class="mt-3"></div>         
            <blocking-page-item ref="pcbp" v-model="pcbp"     :vservice="vservice" v-if="(vservice=='-' || vservice=='parental_control') && availableServices.includes('parental_control')"  v-validate="'required|bpage'"  :vtitle="vservice=='-' ? t('Content Filtering') : t('Default Blocking Page')"                                     :vdefault="allbpvalues.cfdefault"   :vsysdefaultname="systembpname.pcbp"    :vsysdefaulturl="allbpvalues.syscfdefault"     :vurlslist="allbpvalues.cfurlslist" :visible_for_permissions="visible_for_permissions"/>
            <blocking-page-item ref="pcpobp" v-model="pcpobp"   :vservice="vservice" v-if="(vservice=='-' || vservice=='parental_control') && availableServices.includes('parental_control')"  v-validate="'required|bpage'"  :vtitle="vservice=='-' ? t('Content Filtering Password Override') : t('Default Password Override Blocking Page')" :vdefault="allbpvalues.cfpodefault" :vsysdefaultname="systembpname.pcpobp"  :vsysdefaulturl="allbpvalues.syscfpodefault"   :vurlslist="allbpvalues.cfurlslist" :visible_for_permissions="visible_for_permissions"/>
            <blocking-page-item ref="tpmpbp" v-model="tpmpbp"   :vservice="vservice" v-if="(vservice=='-' || vservice=='threat_protection') && availableServices.includes('threat_protection')"  v-validate="'required|bpage'"  :vtitle="vservice=='-' ? t('Threat Protection Malware/Phishing') : t('Default Malware/Phishing Blocking Page')"   :vdefault="allbpvalues.tpmpdefault" :vsysdefaultname="systembpname.tpmpbp"  :vsysdefaulturl="allbpvalues.systpmpdefault"   :vurlslist="allbpvalues.tpurlslist" :visible_for_permissions="visible_for_permissions"/>            
            <blocking-page-item ref="tpvbp" v-model="tpvbp"    :vservice="vservice" v-if="(vservice=='-' || vservice=='threat_protection') && availableServices.includes('threat_protection')"  v-validate="'required|bpage'"  :vtitle="vservice=='-' ? t('Threat Protection Virus') : t('Default Antivirus Blocking Page')"                     :vdefault="allbpvalues.tpvdefault"  :vsysdefaultname="systembpname.tpvbp"  :vsysdefaulturl="allbpvalues.systpvdefault"    :vurlslist="allbpvalues.tpurlslist" :visible_for_permissions="visible_for_permissions"/>
            <blocking-page-item ref="tpbbp" v-model="tpbbp"    :vservice="vservice" v-if="(vservice=='-' || vservice=='threat_protection') && availableServices.includes('threat_protection')"  v-validate="'required|bpage'"  :vtitle="vservice=='-' ? t('Threat Protection Botnet') : t('Default Antibotnet Blocking Page')"                   :vdefault="allbpvalues.tpbdefault"  :vsysdefaultname="systembpname.tpbbp"  :vsysdefaulturl="allbpvalues.systpbdefault"    :vurlslist="allbpvalues.tpurlslist" :visible_for_permissions="visible_for_permissions"/>

            <blocking-page-list v-model="bplist" :service="vservice" :sysnames="systembpname" v-if="vservice!='-'" :visible_for_permissions="visible_for_permissions"/>
        </template>
    </toggle-list>
</template>

<script>
import { ToggleList } from '@/components';
import { EmptyState } from '@/components';
import { validateIpv6Regex, validateIpv4Regex,checkDomain, checkIP }  from "@/util/regexConsts";
import BlockingPageItem from './BlockingPageItem.vue';
import BlockingPageList from './BlockingPageList.vue';
import ProductAttentionMessage from '../ProductAttentionMessage.vue';
import { mapState } from "vuex";

export default {
    name: 'blocking-pages',  
   
    components: {
        [ToggleList.name] : ToggleList,  
        BlockingPageItem,
        BlockingPageList,
        ProductAttentionMessage      
    },
    props: {        
        value: {
            type: Object,
            default: () => { return {} }
        },
        service: {
            type: String,
            default: () => { return "" }
        },
        visible_for_permissions: { 
            type: Array, 
            default: function () { return [] }
        }
    },
    data() {
        return {
            pcbp : {},
            pcpobp : {},
            tpmpbp : {},
            tpvbp : {},
            tpbbp : {},
            bplist : [],
            blockingpages: {},
            allbpvalues: {},
            vservice: '-',
            systembpname: {
                pcbp    : "SystemBlockingPage",
                pcpobp  : "SystemPasswordBlockingPage",
                tpmpbp  : "SystemAntiMalwareBlockingPage",   
                tpvbp   : "SystemAntiVirusBlockingPage", 
                tpbbp   : "SystemAntiBotnetBlockingPage"
            }
        }
    },
    methods: {
        validateAll(){
            if ( this.$refs.pcbp && this.$refs.pcbp.isError())
                return false
            if ( this.$refs.pcpobp && this.$refs.pcpobp.isError())
                return false
            if ( this.$refs.tpmpbp && this.$refs.tpmpbp.isError())   
                return false
            if ( this.$refs.tpvbp && this.$refs.tpvbp.isError())
                return false
            if ( this.$refs.tpbbp && this.$refs.tpbbp.isError())
                return false
            return true
        },
        getObjectValue(objValue){
            if( !!!objValue.page || objValue.page == objValue.system )
                return { page_mode : "default",  external_blocking_page_name : "", custom_blocking_page_url : ""  }            
            return { page_mode : "external", external_blocking_page_name : objValue.page, custom_blocking_page_url : ""  }
        },
        getStringValue(objValue, system){      
            if( objValue.page_mode == "default" ) return system;
            if( objValue.page_mode == "external" ) return objValue.external_blocking_page_name;
            return null;
        },                 
        async getallbpvalues(wait){   
            let _vm = this
            await this.$store.dispatch('blockingpages/getGlobalBlockingPages').then( res => { 
                _vm.allbpvalues = {             
                    cfdefault       : this.getObjectValue({page: res.cfdefault, system: this.systembpname.pcbp}),
                    syscfdefault    : res.syscfdefault,
                    cfpodefault     : this.getObjectValue({page: res.cfpodefault, system: this.systembpname.pcpobp}),
                    syscfpodefault  : res.syscfpodefault,
                    cfurlslist      : res.cfurlslist,                    
                    tpmpdefault     : this.getObjectValue({page: res.tpmpdefault, system: this.systembpname.tpmpbp}),
                    systpmpdefault  : res.systpmpdefault,
                    tpvdefault      : this.getObjectValue({page: res.tpvdefault, system: this.systembpname.tpvbp}),
                    systpvdefault   : res.systpvdefault,
                    tpbdefault      : this.getObjectValue({page: res.tpbdefault, system: this.systembpname.tpbbp}),
                    systpbdefault   : res.systpbdefault,
                    tpurlslist      : res.tpurlslist                
                }
            }) .catch(err => {
                console.log("error:" + err)
            });               
        },
        async updateBlockingPages(){                          
            if(  this.vservice == '-' ) {  
                let tmp = {} 
                
                if(this.availableServices.includes("parental_control"))
                    tmp["parental_control"] = {
                        blocking_pages : { 
                            blocking_page           : this.pcbp,
                            blocking_page_password_override  : this.pcpobp,
                        }
                    }
                
                if(this.availableServices.includes("threat_protection"))
                    tmp["threat_protection"] = {
                        blocking_pages : {
                            antivirus_blocking_page         : this.tpvbp,
                            antimalware_antiphishing_blocking_page       : this.tpmpbp,                            
                            antibotnet_blocking_page        : this.tpbbp
                        }
                    }                    
                this.blockingpages = _.cloneDeep(tmp)
            } else {
                this.blockingpages =  {                   
                    parental_control : {
                        blocking_pages : { 
                            default_blocking_page           : this.getStringValue(this.pcbp, this.systembpname.pcbp),
                            default_password_blocking_page  : this.getStringValue(this.pcpobp, this.systembpname.pcpobp)
                        }
                    },
                    threat_protection : {
                        blocking_pages : {
                            default_antivirus_blocking_page         : this.getStringValue(this.tpvbp, this.systembpname.tpvbp),
                            default_antimalware_blocking_page       : this.getStringValue(this.tpmpbp, this.systembpname.tpmpbp),                           
                            default_antibotnet_blocking_page        : this.getStringValue(this.tpbbp, this.systembpname.tpbbp)
                        }
                    }                                    
                }               
            }
        }       
    },    
    watch: {        
        pcbp(newValue){
            this.updateBlockingPages();                         
        },
        pcpobp(newValue){
            this.updateBlockingPages();            
        },
        tpmpbp(newValue){
            this.updateBlockingPages();                        
        },
        tpvbp(newValue){                  
            this.updateBlockingPages();            
        },
        tpbbp(newValue){            
            this.updateBlockingPages();                        
        },
        value(newValue) {                 
            if( newValue !== null && Object.keys(newValue).length > 0 ){ 
                if( this.availableServices.includes("parental_control")){              
                    this.pcbp =     newValue.parental_control.blocking_pages.blocking_page;
                    this.pcpobp =   newValue.parental_control.blocking_pages.blocking_page_password_override;                    
                }
                if( this.availableServices.includes("threat_protection")){              
                    this.tpmpbp =   newValue.threat_protection.blocking_pages.antimalware_antiphishing_blocking_page;
                    this.tpvbp =    newValue.threat_protection.blocking_pages.antivirus_blocking_page;
                    this.tpbbp =    newValue.threat_protection.blocking_pages.antibotnet_blocking_page; 
                }
            } else {    
                this.pcbp =     { page_mode : "default"}
                this.pcpobp =   { page_mode : "default"}
                this.tpmpbp =   { page_mode : "default"}
                this.tpvbp =    { page_mode : "default"}
                this.tpbbp =    { page_mode : "default"}
                this.updateBlockingPages();                
            }                               
        }, 
        bplist(newValue) {        
            if(this.vservice == 'parental_control')                
                this.allbpvalues.cfurlslist = newValue
            else
                this.allbpvalues.tpurlslist = newValue
        },        
        blockingpages(newValue, oldValue){            
            if( this.vservice == "-" ){                  
                if( (_.isEqual(newValue, oldValue ) === false && Object.keys(oldValue).length > 0 ) || this.value === null ){                   
                   this.$emit('input', newValue);}
            } else {                              
                if( _.isEqual(newValue, oldValue ) === false  && Object.keys(oldValue).length > 0 && 
                    (!!!this.availableServices.includes("parental_control") ||  (this.availableServices.includes("parental_control") && oldValue.parental_control.blocking_pages.default_blocking_page !== null)) &&
                    (!!!this.availableServices.includes("threat_protection") ||  (this.availableServices.includes("threat_protection") && oldValue.threat_protection.blocking_pages.default_blocking_page !== null)) ) {
                    this.$store.dispatch('blockingpages/updateGlobalBlockingPages', { "bplist": newValue, "service" : this.vservice } ).then( res => {                            
                        
                    })
                    .catch( err => {  
                        console.log("error")                                                     
                    } )                              
                }
            }
        }            
    },
    computed: {
        blockingtitle() {            
            if( this.vservice == 'parental_control'  ) return this.$t("Content Filtering Blocking Pages");
            if( this.vservice == 'threat_protection'  ) return this.$t("Threat Protection Blocking Pages");
            return this.$t("Blocking Pages");
        },
        blockingsubtitle() {            
            if( this.vservice == 'parental_control'  ) return this.$t("Manage your Content Filtering Blocking Pages");
            if( this.vservice == 'threat_protection'  ) return this.$t("Manage you Threat Protection Blocking Pages");
            return this.$t("Select the Blocking Pages");
        },
        availableServices() {        
          return this.$store.getters["settings/getServices"];
        },
        title(){
            if( this.products.includes("RouterSecure") && this.products.includes("EndpointSecure") )
                return "* These settings do not apply to blocking pages used by Endpointsecure not RouterSecure"
            else if( this.products.includes("EndpointSecure") )   
                return "* These settings do not apply to blocking pages used by Endpointsecure"
            else if( this.products.includes("RouterSecure") )   
                return "* These settings do not apply to blocking pages used by RouterSecure"    
        },
        ...mapState("settings",["products"])
    },  
    async mounted () {
        this.vservice = this.service         
        await this.getallbpvalues(0) 
        
        this.$nextTick(function(){            
            if( this.vservice == "-" ){
                if( this.value !== null && this.value.parental_control){  
                    if( this.availableServices.includes("parental_control")){                
                        this.pcbp =     this.value.parental_control.blocking_pages.blocking_page;
                        this.pcpobp =   this.value.parental_control.blocking_pages.blocking_page_password_override;
                    }
                    if( this.availableServices.includes("threat_protection")){                
                        this.tpmpbp =   this.value.threat_protection.blocking_pages.antimalware_antiphishing_blocking_page;
                        this.tpvbp =    this.value.threat_protection.blocking_pages.antivirus_blocking_page;
                        this.tpbbp =    this.value.threat_protection.blocking_pages.antibotnet_blocking_page;
                    }
                } else{               
                    this.pcbp =     { page_mode : "default"}
                    this.pcpobp =   { page_mode : "default"}
                    this.tpmpbp =   { page_mode : "default"}
                    this.tpvbp =    { page_mode : "default"}
                    this.tpbbp =    { page_mode : "default"}
                }
            } else {  
                this.pcbp = this.allbpvalues.cfdefault
                this.pcpobp = this.allbpvalues.cfpodefault
                this.tpmpbp = this.allbpvalues.tpmpdefault
                this.tpvbp = this.allbpvalues.tpvdefault
                this.tpbbp = this.allbpvalues.tpbdefault 
                this.bplist = ( this.vservice == "parental_control" ? this.allbpvalues.cfurlslist : this.allbpvalues.tpurlslist)                
            }                       
            this.updateBlockingPages();            
        })
        
    },
    created(){       
        this.$validator.extend(
            'bpage',{
                getMessage: '',//field =>  field + ' ' + this.$t('already exists') + '.',
                validate: (value) => {                                   
                    if( value.page_mode == "custom" && ( value.custom_blocking_page_url == "" || 
                    ( !checkDomain(value.custom_blocking_page_url) && !checkIP(value.custom_blocking_page_url) ) ) ) return false;
                    return true;
                }
        });           
    }
}
</script>
