const translateEvents = (alertEventsTypeList, alertEvents, allEvents) => {
  let eventsSelected = [];
  alertEventsTypeList && alertEventsTypeList.forEach(eventType => {
    eventsSelected.push(eventType);
  });
  
  alertEvents && alertEvents.map(eventCode => {
    const selected = allEvents.find(event => event.code === eventCode)     
    !eventsSelected.includes(selected.severity) && eventsSelected.push(selected.severity)
  })
  
  return eventsSelected.join(', ')
}

const translateActions = (data) => {
  let result = [];
  const actionsTranslate = {
    SNMP: 'SNMP',
    internal_log: 'InternalLog',
    mail: 'Mail',
    system_log: 'SystemLog'
  }
  for (const key in data) {
    if (data.hasOwnProperty(key)) {
      const action = data[key];
      action && result.push(actionsTranslate[key])
    }
  }
  return result.join(', ')
}

  export const prepareAlertTable = (data, events) => {
    let alertTable = []
    data && data.map(element => {
      let alert = {
        name: element.alert_id,
        type: translateActions(element.actions),
        event: translateEvents(element.alert_event_type_list, element.events, events)
      }
      alertTable.push(alert)
    })
    return alertTable
} 

const getIndex = (list ,name) => {
  const index = list.findIndex(element => element.label === name)
  return index
}

export const prepareEventList = (allEvents, selectedEvents, typeSelected) => {
  let source = [ 
    {label: 'Critical', selected: false, children: [] },
    {label: 'Error', selected: false, children: [] },
    {label: 'Warning', selected: false, children: [] },
    {label: 'Information', selected: false, children: []}
  ]

  allEvents.map (element => {
    const index = getIndex(source, element.severity)
    let message = {
      label: element.text,
      code: element.code,
      selected: selectedEvents.includes(element.code) || typeSelected.includes(element.severity)
    }
    source[index].children.push(message)
    source[index].selected = typeSelected.includes(source[index].label)
  })

  return source;
}

export const prepareEventDefaultList = (allEvents, selectedEvents, typeSelected) => {
  let source = [ 
    {label: 'Critical', selected: false, children: [] },
    {label: 'Error', selected: false, children: [] },
    {label: 'Warning', selected: false, children: [] },
    {label: 'Information', selected: false, children: []}
  ]

  allEvents.map (element => {
    const index = getIndex(source, element.severity)
    let message = {
      label: element.text,
      code: element.code,
      selected: selectedEvents.includes(element.code) || typeSelected.includes(element.severity)
    }
    source[index].children.push(message)
    source[index].selected = isAllChildrenSelected(source[index].children)
  })

  return source;
}

const isAllChildrenSelected = (children) => {
  let selected = true
  children && children.map(element => {
    if (element.selected == false) {
      return selected = false
    }
  })
  return selected
}

export const addTypeAlert = (source) => {
  let selectedList = []
  source.map(element => {
    if (element.selected) {
      selectedList.push(element.label)
    }
  })
  return selectedList
}

export const prepareSaveEventList = (source) => {
  const selectedList = []
  source.map(element => {
      if (element.selected === false) {
        element.children.map(child => {
          child.selected && selectedList.push(child.code)
        })
      }
  })
  return selectedList
}

export const prepareSaveDefaultEventList = (source) => {
  const selectedList = []
  source.map(element => {
        element.children.map(child => {
          child.selected && selectedList.push(child.code)
        })
  })
  return selectedList
}
