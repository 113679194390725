import stubs_banners from '../../stubs/autonoticeBanners'
import stubs_smartbanners from '../../stubs/autonoticeSmartBanners'
import stubs_redirections from '../../stubs/autonoticeRedirections'
import axios from "axios";
import _ from 'lodash';
import { strategy as dummyAutoNotices } from '@/util/dummy/autoNoticesRules.json';
import { filterStrategyByService } from "@/util/strategyUtil";
import {
    prepareAutoNoticesData,
    prepareStrategyData,
    prepareStrategyStatusData
} from "@/util/autoNoticesUtils";

import {
    getPartialStrategyFromRule,
    setDeletedElementsToNull
} from "@/util/strategyUtil";

import i18n from '../../../i18n';

function getStubConfig(type) {
    let config = null
    switch (type) {
        case 'redirections':
            config = stubs_redirections()
            break;
        case 'banners':
            config = stubs_banners()
            break;
        case 'smartbanners':
            config = stubs_smartbanners()
            break;
        default:
            console.error("getStubConfig: unknown type: " + type)
    }
    return config
}

export default {
    setSuccess({ commit }, msg) {
        commit("setSuccess", msg);
    },
    setError({ commit }, msg) {
        commit("setError", msg, { root: true });
    },
    async getAutoNotices({ commit, state, getters }) {
        if (getters.isDebug) {
            if (!state.autoNoticeStrategy || Object.keys(state.autoNoticeStrategy).length === 0) {
                commit("setAutoNotices", dummyAutoNotices);
            }
        } else {
            // Get isp strategy from backend
            try {
                const autonoticesStrategy = await axios.get('/subsystems/ns/isp-strategy');
                commit("setAutoNotices", filterStrategyByService(autonoticesStrategy.data, 'autonotices'));
            } catch (err) { console.error('Error getting isp strategy: ', err) }
        }
    },
    getAutoNoticesRulesData({ state, commit, getters }) {
        if (!getters.isDebug || state.rules.length === 0) {
            const strategy = getters.getAutoNotices();
            const rules = prepareAutoNoticesData(strategy);
            const sortedRules = rules.sort((a, b) => {
                return a.order > b.order ? 1 : -1;
            });
            commit("setAutoNoticesRules", sortedRules);
        }
    },
    async createAutoNoticesRule({ state, commit, getters, dispatch }, createdRule) {
        createdRule.order = 0;
        createdRule.id = createdRule.rule_name;

        const partialStrategy = prepareStrategyData([createdRule]);

        if (!getters.isDebug) {
            try {
                await axios.post('/subsystems/ns/isp-strategy/provision', { ...partialStrategy });
                await dispatch("getAutoNotices");
                commit("setAutoNoticesRules", prepareAutoNoticesData(getters.getAutoNotices()));
            } catch (err) {
                console.log('Error updating strategy: ', err);
                if (err.response) {
                    switch(err.response.status){
                        case 409:
                            if (err.response.data.type && err.response.data.type === 'ISP_STRATEGY.LIMIT_EXCEEDED') {
                                commit('setError', i18n.t('MAX_POLICIES_ALLOWED'), {root: true})
                            } else {
                                 commit('setError', i18n.t(err.response.data.type), {root:true});
                            }                            break;
                        default:
                            commit('setError', err, {root:true})
                    }
                }
                throw new Error(err);
            }
        } else {
            commit("addAutoNoticesRule", createdRule);
        }
    },
    async deleteRule({ state, commit, getters, dispatch }, ruleToDelete) {
        if (!getters.isDebug) {
            try {
                await axios.patch("/subsystems/ns/isp-strategy?cascade_delete=true", { rules: { [ruleToDelete.rule_name]: null } });
                await dispatch("getAutoNotices");
                commit("setAutoNoticesRules", prepareAutoNoticesData(getters.getAutoNotices()));
            } catch (err) { console.error('Error deleting isp strategy rule: ', err) }
        } else {
            const deletedOrder = ruleToDelete.order;
            let rules = state.rules;
            const updatedRules = rules.filter(rule => {
                if (rule.id !== ruleToDelete.id) {
                    if (rule.order > deletedOrder) rule.order--;
                    return rule;
                }
            });
            commit("removeAutoNoticesRule", ruleToDelete);
        }
    },
    async updateAutoNoticesRule({ commit, getters, state, dispatch }, updatedRule) {
        if (!getters.isDebug) {
            // PUT the new rules to backend
            let newPartialStrategy = prepareStrategyData([updatedRule]);
            let oldPartialStrategy = getPartialStrategyFromRule(updatedRule, getters.getAutoNotices())
            newPartialStrategy = setDeletedElementsToNull(oldPartialStrategy, newPartialStrategy);
            try {
                await axios.patch('/subsystems/ns/isp-strategy', { ...newPartialStrategy });
                await dispatch("getAutoNotices");
                commit("setAutoNoticesRules", prepareAutoNoticesData(getters.getAutoNotices()));
            } catch (err) {
                console.error('Error updating rule: ', err)
                commit('setError', err, { root: true });
                throw err
            }
        } else {
            const updatedRules = state.rules.map(rule =>
                rule.rule_name === updatedRule.rule_name ? updatedRule : rule
            );
            commit("setAutoNoticesRules", updatedRules);
        }
    },
    async updateAutoNoticesRuleStatus({ commit, getters, state, dispatch }, updatedRule) {
        if (getters.isDebug) {
                const updatedRules = state.rules.map(rule => {
                    if (rule.rule_name === updatedRule.rule_name) {
                        rule.status = updatedRule.status;
                        return rule;
                    } else {
                        return rule;
                    }
                }
            );
            commit("setAutoNoticesRules", updatedRules);
        } else {
            let newPartialStrategy = prepareStrategyStatusData(updatedRule);
            try {
                await axios.patch('/subsystems/ns/isp-strategy', { ...newPartialStrategy });
            } catch (err) {
                console.error('Error updating rule: ', err);
                commit('setError', err, { root: true });
                throw err
            }
        }
    },
    getAutoNoticesAdvancedConfiguration({ commit, state, rootState, dispatch }, { type }) {
        if (!['redirections', 'banners', 'smartbanners'].includes(type)) {
            console.error("getAutoNoticesAdvancedConfiguration: unknown type: " + type)
            return new Promise((resolve, reject) => {
                reject()
            });
        }

        if (rootState.settings.debug) {
            return new Promise((resolve, reject) => {
                if (!!state.configuration[type])
                    resolve(state.configuration[type])
                else {
                    let config = getStubConfig(type)
                    if (config) {
                        commit('setAdvancedConfiguration', { type: type, config: config })
                        resolve(config)
                    }
                    else {
                        reject()
                    }
                }
            });
        }
        else {
            return new Promise((resolve, reject) => {
                axios.get('/subsystems/ns/autonotices/' + type)
                    .then(res => {
                        if (res && res.data) {
                            commit('setAdvancedConfiguration', { type: type, config: res.data.items })
                            resolve(res.data.items)
                        }
                        else {
                            reject()
                        }
                    })
                    .catch(error => {
                        commit('setError', error, { root: true });
                        reject()
                    })
            });
        }
    },
    getConfigurationByName({ commit, state, rootState, dispatch }, { type, id }) {
        if (!['redirections', 'banners', 'smartbanners'].includes(type)) {
            console.error("getConfigurationByName: unknown type: " + type)
            return new Promise((resolve, reject) => {
                reject()
            });
        }

        if (rootState.settings.debug) {
            return new Promise((resolve, reject) => {
                let ret = null
                if (!!state.configuration[type]) {
                    ret = state.configuration[type].filter(element => element.autonotice_list_id === id)
                }
                else {
                    let config = getStubConfig(type)
                    commit('setAdvancedConfiguration', { type: type, config: config })
                    ret = config.filter(element => element.autonotice_list_id === id)
                }

                if (ret && ret.length == 1) {
                    resolve(_.cloneDeep(ret[0]))
                }
                else {
                    reject()
                }
            });
        } else {
            return new Promise((resolve, reject) => {
                axios.get('/subsystems/ns/autonotices/' + type + '/' + id)
                    .then(res => {
                        if (res && res.data) {
                            commit('replaceOrAddAdvancedConfiguration', { type: type, data: res.data })
                            resolve(res.data)
                        }
                        else {
                            reject()
                        }
                    })
                    .catch(error => {
                        commit('setError', error, { root: true });
                        reject()
                    })
            });
        }
    },
    CreateConfiguration({ commit, state, rootState }, { type, data }) {
        if (!['redirections', 'banners', 'smartbanners'].includes(type)) {
            console.error("CreateConfiguration: unknown type: " + type)
            return new Promise((resolve, reject) => {
                reject()
            });
        }

        let prev = _.cloneDeep(state.configuration[type])
        commit('addAdvancedConfiguration', { type: type, data: data })

        if (rootState.settings.debug) {
            return new Promise((resolve, reject) => {
                resolve({ type: type, data: data })
            })
        } else {
            return new Promise((resolve, reject) => {
                axios.post('/subsystems/ns/autonotices/' + type, data)
                    .then(res => {
                        resolve({ type: type, data: data })
                    })
                    .catch(error => {
                        commit('setError', error, { root: true });
                        commit('setAdvancedConfiguration', { type: type, config: prev })
                        reject()
                    })
            });
        }
    },
    UpdateConfiguration({ commit, state, rootState }, { type, data }) {
        if (!['redirections', 'banners', 'smartbanners'].includes(type)) {
            console.error("UpdateConfiguration: unknown type: " + type)
            return new Promise((resolve, reject) => {
                reject()
            });
        }

        let prev = _.cloneDeep(state.configuration[type])
        commit('replaceAdvancedConfiguration', { type: type, data: data })

        if (rootState.settings.debug) {
            return new Promise((resolve, reject) => {
                resolve({ type: type, data: data })
            })
        } else {
            return new Promise((resolve, reject) => {
                axios.patch('/subsystems/ns/autonotices/' + type + '/' + data.autonotice_list_id, data)
                    .then(res => {
                        resolve({ type: type, data: data })
                    })
                    .catch(error => {
                        commit('setError', error, { root: true });
                        commit('setAdvancedConfiguration', { type: type, config: prev })
                        reject()
                    })
            });
        }
    },
    DeleteConfiguration({ commit, state, rootState }, { type, id }) {
        if (!['redirections', 'banners', 'smartbanners'].includes(type)) {
            console.error("DeleteConfiguration: unknown type: " + type)
            return new Promise((resolve, reject) => {
                reject()
            });
        }

        let prev = _.cloneDeep(state.configuration[type])
        commit('RemoveConfiguration', { type: type, id: id })

        if (rootState.settings.debug)
            return new Promise((resolve, reject) => {
                resolve()
            });
        else {
            return new Promise((resolve, reject) => {
                axios.delete('/subsystems/ns/autonotices/' + type + '/' + id)
                    .then(res => {
                        resolve()
                    })
                    .catch(error => {
                        if (error.response) {
                            switch(error.response.status){
                                case 409:
                                    commit('setError', i18n.t(error.response.data.type), {root:true});
                                    break;
                                default:
                                    commit('setError', error, {root:true})
                            }
                        }
                        commit('setAdvancedConfiguration', { type: type, config: prev })
                        reject()
                    })
            });
        }
    },
    getAutoNoticesPackages({ commit, state, rootState }) {
        if (rootState.settings.debug)
            return new Promise((resolve, reject) => {
                resolve(["MyAutoNoticePackage1", "MyAutoNoticePackage2"])
            });
        else {
            return new Promise((resolve, reject) => {
                axios.get('/isp-autonotices-packages')
                    .then(res => {
                        resolve(res.data.items.filter(item => !!item.enabled).map(element => element.isp_notice_id))
                    })
                    .catch(error => {
                        commit('setError', error, { root: true });
                        reject()
                    })
            });
        }
    },
};
