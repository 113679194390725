export default {
    getAlerts(state) {
        return state.alerts;
    },
    getDefaultActions(state) {
        return state.defaultEvents ? state.defaultEvents.default_actions : null;
    },
    getExcluded(state) {
        return state.defaultEvents ? state.defaultEvents.excluded_events : null;
    },
    getAllEvents(state) {
        return state.events;
    },
    getMailData(state) {
        return state.defaultEvents ? state.defaultEvents.mail_action_config : null;
    },
};