/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'firewall': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path pid="0" d="M32 27.636A4.364 4.364 0 0127.636 32H4.364A4.364 4.364 0 010 27.636V4.364A4.364 4.364 0 014.364 0h23.272A4.364 4.364 0 0132 4.364v23.272zm-20.364-2.909H2.91v2.91c0 .803.651 1.454 1.455 1.454h7.272v-4.364zm17.454 0H14.545v4.363h13.091c.804 0 1.455-.65 1.455-1.454l-.001-2.909zm0-7.272H2.91v4.363h26.18v-4.363zm0-7.273H2.91v4.363h26.18v-4.363zM17.455 2.909H4.364c-.804 0-1.455.651-1.455 1.455v2.909l14.545-.001V2.909zm10.182 0h-7.272v4.364h8.727v-2.91c0-.803-.651-1.454-1.455-1.454z" _fill="#000" fill-rule="evenodd"/>'
  }
})
