/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'firewall-configuration': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path pid="0" d="M29.667 29.402H17v-3.936h13v3.608a.33.33 0 01-.333.328zM2 29.074v-3.608h13v3.936H2.333A.33.33 0 012 29.074zm.333-10.168H8v4.592H2v-4.264a.33.33 0 01.333-.328zM10 23.498h12v-4.592H10v4.592zm20-4.264v4.264h-6v-4.592h5.667a.33.33 0 01.333.328zm-.333-2.296c1.286 0 2.333 1.03 2.333 2.296v9.84c0 1.266-1.047 2.296-2.333 2.296H2.333C1.047 31.37 0 30.34 0 29.074v-9.84c0-1.266 1.047-2.296 2.333-2.296zM18.125 1a.983.983 0 01.514.865c0 3.152 1.47 4.63 3.025 6.191 1.477 1.484 3.15 3.166 3.28 6.239a.985.985 0 11-1.968.083c-.097-2.31-1.307-3.527-2.707-4.933-1.325-1.332-2.79-2.804-3.356-5.298-.886 1.486-1.628 4.202.07 8.58a.982.982 0 01-.92 1.34c-1.916 0-3.415-.6-4.454-1.782-.741-.844-1.118-1.856-1.303-2.769-.86 1.166-1.773 2.878-1.773 4.881a.985.985 0 01-1.969 0c0-4.707 3.806-7.904 3.967-8.04a.984.984 0 011.603.886c-.002.022-.275 2.35.96 3.75.386.437.898.746 1.527.924-2.286-7.672 2.29-10.745 2.498-10.879L18.125 1z" _fill="#000" fill-rule="evenodd"/>'
  }
})
