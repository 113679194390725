export default () => { return [
        {
            autonotice_list_id: 'redirect1', 
            items: ['www.aa.es', 'wwww.ews.df'], 
            mode: 'random' 
        },
        {
            autonotice_list_id: 'redirect2', 
            items: ['www.b1.es', 'am.config.com'], 
            mode: 'random_no_repeat' 
        },
        {
            autonotice_list_id: 'redirect3', 
            items: ['www.c2.es', 'am.config3.es'], 
            mode: 'sequential' 
        }
]};