import DashboardLayout from "@/layout/dashboard/DashboardLayout.vue";
import { store } from "@/store";

import { networksecurepath } from '../utils';

const NetworkSecureStatus = () => import(
  /* webpackChunkName: "ns-generalmanagement" */
  '@/pages/NetworkSecure/General/Status.vue'
);
const NetworkSecureBackup = () => import(
  /* webpackChunkName: "ns-generalmanagement" */
  '@/pages/NetworkSecure/General/Backup.vue'
);
const NetworkSecureFilteringLog = () => import(
  /* webpackChunkName: "ns-generalmanagement" */
  '@/pages/NetworkSecure/General/FilteringLog.vue'
);
const HostAndProcessControl = () => import(
  /* webpackChunkName: "ns-generalmanagement" */
  "@/pages/NetworkSecure/General/HostAndProcessControl.vue"
);

const ExternalServers = () => import(
  /* webpackChunkName: "ns-generalmanagement" */
  "@/pages/NetworkSecure/GeneralManagement/ExternalServers/ExternalServers.vue"
);

const ExternalServersEdit = () => import(
  /* webpackChunkName: "ns-generalmanagement" */
  "@/pages/NetworkSecure/GeneralManagement/ExternalServers/ExternalServersEdit.vue"
);

const ScheduledTasksRouter = () => import(
  /* webpackChunkName: "ns-generalmanagement" */
  "@/pages/NetworkSecure/General/GeneralManagment/ScheduledTasks/ScheduledTasksRouter.vue"
  );
const ScheduledTasks = () => import(
  /* webpackChunkName: "ns-generalmanagement" */
  "@/pages/NetworkSecure/General/GeneralManagment/ScheduledTasks/ScheduledTasks.vue"
  );
const ScheduledTasksEdit = () => import(
  /* webpackChunkName: "ns-generalmanagement" */
  "@/pages/NetworkSecure/General/GeneralManagment/ScheduledTasks/ScheduledTasksEdit.vue"
  );

  const Alerts = () =>  import(
    /* webpackChunkName: "ns-generalmanagement" */
    "@/pages/Alerts/Alerts.vue"
  );

  const AlertEdit = () =>  import(
    /* webpackChunkName: "ns-generalmanagement" */
    "@/pages/Alerts/AlertEdit.vue"
  );


const Frame = () => import(
  /* webpackChunkName: "common" */
  "@/components/ReverseProxyFrame.vue"
);

const query = 'h_mode=&h_modify=&h_user=&h_valid=&h_intro=&h_pg='

export default {
  path: '/ns/General',
  component: DashboardLayout,
  name: 'Network Secure / General',
  children: [
    {
      path: 'Status*',
      name: 'Network Secure / General / Status',
      component: NetworkSecureStatus
    },
    {
      path: 'Status/AppsStatus/:id',
      name: 'Network Secure / General / Status / Host & Process Control / Detail',
      component: HostAndProcessControl,
      props: true,
    },
/*  {
 *    path: 'GeneralManagement/SysAdmins*',
      name: 'Network Secure / Administrators',
      component: Frame,
      props: (route) => ({
        path: networksecurepath(route.path),
        url: 'dspSysAdmins.htmopt',
        query: 'h_mode=&h_modify=Administrators&h_user=&h_valid=&h_intro=M_INTR_HADMS&h_pg='
      })
    },
    {
      path: 'GeneralManagement/LDAPAuth*',
      name: 'Network Secure / Delegated Authentication',
      component: Frame,
      props: (route) => ({
        path: networksecurepath(route.path),
        url: 'dspLDAPAuth.htmopt',
        query
      })
    },
*/
    {
      path: 'GeneralManagement/ScheduledTasks',
      name: 'Network Secure / General / General Management / Scheduled Tasks',
      component:  ScheduledTasks,
      props: true,
    },
    {
      path: 'GeneralManagement/ScheduledTasks/edit/:module/:id',
      name: 'Network Secure / General / General Management / Scheduled Tasks / Edit',
      component: ScheduledTasksEdit,
      props: true,
    },
    {
      path: 'GeneralManagement/Backup*',
      name: 'Network Secure / General / General Management / Backups',
      component: NetworkSecureBackup
    },
    {
      path: 'GeneralManagement/AdminHost*',
      name: 'Network Secure / General / General Management / Host Location',
      component: Frame,
      props: (route) => ({
        path: networksecurepath(route.path),
        url: 'dspAdminHost.htmopt',
        query: 'h_mode=&h_modify=&h_user=&h_valid=&h_intro=M_ADMINHOST_INTR&h_pg=',
      })
    },
    {
      path: 'GeneralManagement/Logs*',
      name: 'Network Secure / General / General Management / Filtering Log Configuration',
      component: NetworkSecureFilteringLog,
    },
    // {
    //   path: 'GeneralManagement/EventConfig/Events*',
    //   name: 'Network Secure / General / General Management / Events',
    //   component: Frame,
    //   props: (route) => ({
    //     path: networksecurepath(route.path),
    //     url: 'dspEvents.htmopt',
    //     query,
    //   }),
    // },
    {
      path: 'GeneralManagement/Alerts',
      name: 'Network Secure / General / General Management / Alerts',
      component: Alerts
    },
    {
      path: 'GeneralManagement/Alerts/Edit/:id',
      name: 'Network Secure / General / General Management / Alerts / Edit',
      component: AlertEdit,
      props: true
    },
    {
      path: 'GeneralManagement/Alerts/New',
      name: 'Network Secure / General / General Management / Alerts / New',
      component: AlertEdit,
    },
    {
      path: 'GeneralManagement/ExternalServers',
      name: 'Network Secure / General / General Management / External Servers',
      component: ExternalServers,
    },
    {
      path: "GeneralManagement/ExternalServers/new/:cluster_name/:cluster_type",
      name: "Network Secure / General / General Management / External Servers / New",
      component: ExternalServersEdit,
      props: true
    },
    {
      path: 'GeneralManagement/ExternalServers/edit/:cluster_name/:cluster_type/:server_name',
      name: 'Network Secure / General / General Management / External Servers / Edit',
      component: ExternalServersEdit,
      props: true
    },
/*
    { path: 'GeneralManagement/PasswordControl*',
      name: 'Network Secure / Password Control',
      component: Frame,
      props: (route) => ({
        path: networksecurepath(route.path),
        url: 'dspPwdCtrl.htmopt',
        query: 'h_mode=&h_modify=&h_user=&h_valid=&h_intro=M_GL_GM_EXT_CONN&h_pg=',
        }),
    },
*/
/*

    {
      path: 'AccountManagement/ClientManagement*',
      name: 'Network Secure / Provisioning',
      component: Frame,
      props: (route) => ({
        path: networksecurepath(route.path),
        url: 'dspClientManagement.htmopt',
        query,
      })
    },
    {
      path: 'AccountManagement/SysAdmins*',
      name: 'Network Secure / Client users',
      component: Frame,
      props: (route) => ({
        path: '/General/GeneralManagement/SysAdmins/',
        url: 'dspSysAdmins.htmopt',
        query: 'h_mode=3&h_modify=Managers&h_user=&h_valid=&h_intro=M_INTR_HCMANS&h_pg=',
      }),
    },
    {
      path: 'AccountManagement/MassiveProvisioning*',
      name: 'Network Secure / Mass Provisioning',
      component: Frame,
      props: (route) => ({
        path: networksecurepath(route.path),
        url: 'dspMassiveProvisioning.htmopt',
        query,
      }),
    },
    {
      path: 'CustomerCare*',
      name: 'Network Secure / Customer Care',
      component: Frame,
      props: (route) => ({
        path: networksecurepath(route.path),
        url: 'dspCustomerCare.htmopt',
        query,
      }),
    },
*/
    {
      path: 'GeneralManagement/ServicesISP*',
      name: 'Network Secure / General / General Management / Service Packages',
      component: Frame,
      beforeEnter: (to, from, next) => {
        const isIOT = store.getters['settings/isIOT'];
        next(!isIOT)
      },
      props: (route) => ({
        path: networksecurepath(route.path),
        url: 'dspServices.htmopt',
        query: 'h_mode=&h_modify=&h_user=&h_valid=&h_intro=M_INTR_TSERVICES&h_pg=',
      }),
    },
/*  {
 *    path: 'AccountManagement/ClientExtConnection*',
      name: 'Network Secure / External Servers',
      component: Frame,
      props: (route) => ({
        path: networksecurepath(route.path),
        url: 'dspClientExtConnection.htmopt',
        query: 'h_mode=client&h_modify=Managers&h_user=&h_valid=&h_intro=M_GL_GM_EXT_CONN&h_pg='
      }),
    },
*/
    {
      path: 'FilteringPolicies/Policies/Main*',
      name: 'Network Secure / General / Filtering Policies / Policies',
      component: Frame,
      props: (route) => ({
        path: networksecurepath(route.path),
        url: 'dspPolicies.htmopt',
        query: 'h_mode=&h_modify=General&h_user=&h_valid=&h_intro=&h_pg=',
      }),
    },
    {
      path: 'FilteringPolicies/Policies/Group/Main*',
      name: 'Network Secure / General / Filtering Policies / Source & Destination',
      component: Frame,
      props: (route) => ({
        path: networksecurepath(route.path),
        url: 'dspGroup.htmopt',
        query: 'h_mode=whos&h_modify=&h_user=&h_valid=&h_intro=&h_pg=',
      }),
    },
    { path: 'FilteringPolicies/Policies/Schedule*',
      name: 'Network Secure / General / Filtering Policies / Schedules',
      component: Frame,
      props: (route) => ({
        path: networksecurepath(route.path),
        url: 'dspSchedule.htmopt',
        query: 'h_mode=whens&h_modify=&h_user=&h_valid=&h_intro=&h_pg=',
      }),
    },
/*
    {
      path: 'GeneralManagement/ChangePwd*',
      name: 'Network Secure / Change your password',
      component: Frame,
      props: (route) => ({
        path: networksecurepath(route.path),
        url: 'dspChgPswd.htmopt',
        query,
      }),
    },
*/
    {
      path: 'License*',
      name: 'Network Secure / General / License',
      component: Frame,
      props: (route) => ({
        path: networksecurepath(route.path),
        url: 'dspLicense.htmopt',
        query,
      }),
    },
    {
      path: 'AdminPlugins*',
      name: 'Network Secure / General / Plugins',
      component: Frame,
      props: (route) => ({
        path: networksecurepath(route.path),
        url: 'dspPlugins.htmopt',
        query,
      }),
    },
  ]
};
