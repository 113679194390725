export const external_servers = { "items" : [
    {
        "name": "DNSDefault",
        "type": "DNS",
        "default" : true,
        "servers"	: [
            {
                "name" : "DS1",
                "status"	: true,
                "location"	: "8.8.8.8",
                "mode"	: "primary",
                "port"	: "25",
            },
            {
                "name" : "DS2",
                "status"	: false,
                "location"	: "8.8.4.4",
                "mode"	: "secondary",
                "port"	: "26",
            },

        ]
    },
    {
        "name": "ProxyDefault",
        "type": "Proxy",
        "default" : true,
        "servers"	: [
            {
                "name" : "Proxy11",
                "status"	: true,
                "location"	: "10.10.10.11",
                "mode"	: "primary",
                "port"	: "30",
            },
            {
                "name" : "Proxy2",
                "status"	: false,
                "location"	: "10.10.10.12",
                "mode"	: "secondary",
                "port"	: "31",
            },

        ]
    },
    {
        "name": "SNMPDefault",
        "type": "SNMP",
        "default" : false,
        "servers" : []
    },
    {
        "name": "LDAP_Cluster",
        "type": "ldap",
        "default": false,
        "servers": []
    }
] };

export const sms_servers = { "items" : [
    {
      "host": "api.twilio.com",
      "port": 443,
      "ssl": true,
      "from": "+34983060585",
      "username": "ACf1b48f614b29660c624d0b02dc980def",
      "authorization_header": "ESB Token=\"XXXXXXXXXXXXXXXX\"",
      "mode": "POST",
      "enabled": true,
      "order": 0,
      "additional_fixed_param_values": "O=NameOfClient&SampleParam=SampleValue",
      "path": "/cgi-bin/sendsms",
      "kannel_id": "6194e1691877383eb881d9eb"
    }
  ]
};

export const email_servers = { "items" : [
    {
      "host": "string",
      "port": 25,
      "from": "string",
      "username": "string",
      "starttls": false,
      "ssl": false,
      "enabled": false,
      "order": 0,
      "mta_id": "6194e16e7ee5bb3d1a175234"
    }
  ]
};

