export default {
  serviceStatus(state) {
    return state.service_status;
  },
  lanProtection(state) {
    return state.lan_protection;
  },
  routerProtection(state) {
    return state.router_protection;
  },
  lanServices(state) {
    return state.lan_services;
  },
  routerServices(state) {
    return state.router_services;
  }
};