/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'add-button': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path pid="0" d="M27.2 0A4.8 4.8 0 0132 4.8v22.4a4.8 4.8 0 01-4.8 4.8H4.8A4.8 4.8 0 010 27.2V4.8A4.8 4.8 0 014.8 0h22.4zm0 3.2H4.8a1.6 1.6 0 00-1.6 1.6v22.4a1.6 1.6 0 001.6 1.6h22.4a1.6 1.6 0 001.6-1.6V4.8a1.6 1.6 0 00-1.6-1.6zM16 6.4A1.6 1.6 0 0117.6 8v6.4H24a1.6 1.6 0 110 3.2h-6.4V24a1.6 1.6 0 01-3.2 0v-6.4H8a1.6 1.6 0 010-3.2h6.4V8A1.6 1.6 0 0116 6.4z" _fill="#000" fill-rule="evenodd"/>'
  }
})
