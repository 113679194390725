/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'captcha-refresh': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path pid="0" d="M30.3 12.743H17.554V8.495h5.506a10.621 10.621 0 00-14.128 1.58c-3.896 4.387-3.496 11.1.888 14.995 4.387 3.894 11.101 3.494 14.995-.89l3.44 2.517c-5.158 6.137-14.252 7.08-20.561 2.133C1.232 23.762.102 14.415 5.17 7.955 10.24 1.493 19.585.363 26.047 5.432V0h4.249l.004 12.743z" _fill="#000" fill-rule="evenodd"/>'
  }
})
