<template functional>
    <span style="white-space: nowrap;">
        <el-progress :stroke-width="18" :percentage="props.row[props.column.prop]" color="#f96332" ></el-progress>
    </span>
</template>

<script>
    export default {
        name: 'progress-formatter',
    }
</script>
