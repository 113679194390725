import DashboardLayout from "@/layout/dashboard/DashboardLayout.vue";

const AutoNotices = () => import(
  /* webpackChunkName: "ns-services" */
  "@/pages/NetworkSecure/AutoNotices/AutoNotices.vue"
);

const AutoNoticesEdit = () => import(
  /* webpackChunkName: "ns-services" */
  "@/pages/NetworkSecure/AutoNotices/AutoNoticesEdit.vue"
);

const AutonoticesAdvancedConfiguration = () => import(
 /* webpackChunkName: "ns-services" */
"@/pages/NetworkSecure/AutoNotices/AdvancedConfiguration/AdvancedConfiguration.vue"
);

const AutonoticesAdvancedConfigurationEdit = () => 
import(
  /* webpackChunkName: "ns-services" */
 "@/pages/NetworkSecure/AutoNotices/AdvancedConfiguration/AdvancedConfigurationEdit.vue"
 );

const baseName = 'Network Secure / AutoNotices / Advanced Configuration'

export default {
  path: "/ns/AutoNotices",
  component: DashboardLayout,
  name: 'Network Secure / AutoNotices',
  children: [
    {
      path: 'AdvancedConfiguration',
      name: baseName,
      component: AutonoticesAdvancedConfiguration,
      props: route => ({ type: 'redirections', routename: route.name})
    },
    {
      path: 'AdvancedConfiguration/Redirection', 
      name: baseName + ' / Redirection',
      component: AutonoticesAdvancedConfiguration,
      props: route => ({ type: 'redirections', routename: route.name})
    },
    {
      path: 'AdvancedConfiguration/Insertion', 
      name: baseName + ' / Banners Insertion',
      component: AutonoticesAdvancedConfiguration,
      props: route => ({ type: 'banners', routename: route.name})
    },
    {
      path: 'AdvancedConfiguration/Smart', 
      name: baseName + ' / Smart Banners',
      component: AutonoticesAdvancedConfiguration,
      props: route => ({ type: 'smartbanners', routename: route.name})
    },
    {
      path: 'AdvancedConfiguration/Redirection/Create',
      name: baseName + ' / Redirection / Create',
      component: AutonoticesAdvancedConfigurationEdit,
      props: { type: 'redirections', visible_for_permissions: [{ actions: ['POST'], path: '/subsystems/ns/autonotices/redirections' }]}
    },
    {
      path: 'AdvancedConfiguration/Insertion/Create',
      name: baseName + ' / Banners Insertion / Create',
      component: AutonoticesAdvancedConfigurationEdit,
      props: { type: 'banners', visible_for_permissions: [{ actions: ['POST'], path: '/subsystems/ns/autonotices/banners' }]}
    },
    {
      path: 'AdvancedConfiguration/Smart/Create',
      name: baseName + ' / Smart Banners / Create',
      component: AutonoticesAdvancedConfigurationEdit,
      props: { type: 'smartbanners', visible_for_permissions: [{ actions: ['POST'], path: '/subsystems/ns/autonotices/smartbanners' }]}
    },
    {
      path: 'AdvancedConfiguration/Redirection/Edit/:id',
      name: baseName + ' / Redirection / Edit',
      component: AutonoticesAdvancedConfigurationEdit,
      props: route => ({ type: 'redirections', id: route.params.id, visible_for_permissions: [{ actions: ['PATCH'], path: '/subsystems/ns/autonotices/redirections/.*' }]})
    },
    {
      path: 'AdvancedConfiguration/Insertion/Edit/:id',
      name: baseName + ' / Banners Insertion / Edit',
      component: AutonoticesAdvancedConfigurationEdit,
      props: route => ({ type: 'banners', id: route.params.id, visible_for_permissions: [{ actions: ['PATCH'], path: '/subsystems/ns/autonotices/banners/.*' }]})
    },
    {
      path: 'AdvancedConfiguration/Smart/Edit/:id',
      name: baseName + ' / Smart Banners / Edit',
      component: AutonoticesAdvancedConfigurationEdit,
      props: route => ({ type: 'smartbanners', id: route.params.id, visible_for_permissions: [{ actions: ['PATCH'], path: '/subsystems/ns/autonotices/smartbanners/.*' }]})
    },
    {
      path: "Policies/Create",
      name: "Network Secure / AutoNotices / Policies / Create",
      component: AutoNoticesEdit
    },
    {
      path: "Policies/Edit/:ruleId",
      name: "Network Secure / AutoNotices / Policies / Edit",
      component: AutoNoticesEdit,
      props: true
    },
    {
      path: 'Policies',
      name: 'Network Secure / AutoNotices / Policies',
      component: AutoNotices
    },
  ]}
