/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'server-configuration': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<defs><path pid="0" d="M23.111 21.333c.978 0 1.778.8 1.778 1.778s-.8 1.778-1.778 1.778H16c-.978 0-1.778-.8-1.778-1.778s.8-1.778 1.778-1.778zm-14.222 0c.978 0 1.778.8 1.778 1.778s-.8 1.778-1.778 1.778-1.778-.8-1.778-1.778.8-1.778 1.778-1.778zm-3.556 7.111a1.78 1.78 0 01-1.777-1.777v-8.89h24.888v8.89a1.78 1.78 0 01-1.777 1.777H5.333zM9.497 4.54a1.767 1.767 0 011.59-.983h9.827c.677 0 1.287.376 1.59.983l4.842 9.683H4.654L9.497 4.54zm16.187-1.591A5.304 5.304 0 0020.914 0h-9.828a5.304 5.304 0 00-4.77 2.948L.376 14.83A3.577 3.577 0 000 16.42v10.247A5.34 5.34 0 005.333 32h21.334A5.34 5.34 0 0032 26.667V16.42c0-.548-.13-1.1-.375-1.59L25.684 2.948z" id="svgicon_server-configuration_a"/></defs><use _fill="#000" fill-rule="nonzero" xlink:href="#svgicon_server-configuration_a"/>'
  }
})
