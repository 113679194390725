/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'calendar': {
    width: 38,
    height: 42,
    viewBox: '0 0 38 42',
    data: '<path pid="0" d="M0 35V11h38v24c0 2.2-1.8 4-4 4H4c-2.2 0-4-1.8-4-4z" _fill="#fff"/><path pid="1" d="M38 7v6H0V7c0-2.2 1.8-4 4-4h30c2.2 0 4 1.8 4 4z" _fill="#f44336"/><g transform="translate(-5 -3)" _fill="#b71c1c"><circle pid="2" cx="33" cy="10" r="3"/><circle pid="3" cx="15" cy="10" r="3"/></g><g _fill="#e1e2d2"><path pid="4" d="M28 0c-1.1 0-2 .9-2 2v5c0 1.1.9 2 2 2s2-.9 2-2V2c0-1.1-.9-2-2-2zM10 0C8.9 0 8 .9 8 2v5c0 1.1.9 2 2 2s2-.9 2-2V2c0-1.1-.9-2-2-2z"/></g><text x="11.268" y="34.789" _fill="#556378" font-family="sans-serif" font-size="23.567" stroke-width=".884" style="line-height:1.25"><tspan x="11.268" y="34.789" font-family="\'Arial Black\'">7</tspan></text>'
  }
})
