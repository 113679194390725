/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'block-list-icon': {
    width: 52,
    height: 44,
    viewBox: '0 0 52 44',
    data: '<g data-name="Group 6" transform="translate(-25 -385)"><g data-name="Path 1" _fill="#fff"><path pid="0" d="M67 423H31c-2.206 0-4-1.794-4-4v-28c0-2.206 1.794-4 4-4h36c2.206 0 4 1.794 4 4v28c0 2.206-1.794 4-4 4z"/><path pid="1" d="M31 389c-1.103 0-2 .897-2 2v28c0 1.103.897 2 2 2h36c1.103 0 2-.897 2-2v-28c0-1.103-.897-2-2-2H31m0-4h36a6 6 0 016 6v28a6 6 0 01-6 6H31a6 6 0 01-6-6v-28a6 6 0 016-6z" _fill="#f0433a"/></g><rect pid="2" data-name="Rectangle 5" width="14" height="7" rx="3.5" transform="translate(27 388)" _fill="#f0433a"/><path pid="3" data-name="Line 1" _fill="none" _stroke="#f0433a" stroke-width="4" d="M71.5 396.5h-44"/><circle pid="4" data-name="Ellipse 1" cx="2" cy="2" r="2" transform="translate(29 389.5)" _fill="#fff"/><circle pid="5" data-name="Ellipse 2" cx="2" cy="2" r="2" transform="translate(34 389.5)" _fill="#fff"/></g><path pid="6" data-name="Rectangle 8" _fill="#fff" d="M29 21h20v20H29z"/><g data-name="Group 7" _fill="none" _stroke="#f0433a" stroke-width="2"><g data-name="Ellipse 3" transform="translate(32 24)"><circle pid="7" cx="10" cy="10" r="10" _stroke="none"/><circle pid="8" cx="10" cy="10" r="9"/></g><path pid="9" data-name="Line 2" d="M35.542 27.878l12.917 12.653"/></g>'
  }
})
