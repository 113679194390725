import {
  validateIpv6InBracketsRegex,
  validateUrlWithHost,
} from "@/util/regexConsts";

export default {
  data() {
    return {
      validations: {
        common: {
          required: true,
          numeric: true,
        },
        max99: {
          required: true,
          numeric: true,
          max_value: 99,
          min_value: 0
        },
        max512char: {
          max: 512,
        },
        max80char: {
          max: 80
        },
        max20: {
          required: true,
          numeric: true,
          max_value: 20,
          min_value: 0
        },
        url: 'url',
        email: 'email'
      },
    }
  },
  methods: {
    getError(fieldName) {
      if( fieldName == "urlipdomain" && this.errors.first(fieldName) )
        return this.t('This field is not a valid ip, url or domain')      
      else
        return this.errors.first(fieldName);
    },
    beautify_string(string) {
      if(!string.includes('_')) return string
      return string.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
    },
    validate_url(url, isHTTPRequired = true) {
      //ip allows just ips. ip_or_fqdn allows ips and domains without http://
      let HTTPRule = isHTTPRequired? 'ip' : 'ip_or_fqdn'
      const regex = { required: true, regex: /\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=*]*)$/ };
      let rule = "required|";
      if(!isHTTPRequired && (url.includes("http://") || url.includes("https://"))){
        return { url: {protocols: []}}
      }
      if (url.includes('/')) {
        rule += 'urlWithHost';
      } else {
        rule += url.includes(':') ? 'ipv6InBrackets' : HTTPRule;
      }
      return url.slice(-1) == '*' ? regex : rule;
    },
  },
  created(){
    this.$validator.extend(
      'ipv6InBrackets',{
        getMessage: () => `${this.t('ipv6InBracketsValidationMessage')}`,
        validate: (value) => {
          if (!validateIpv6InBracketsRegex(value)) return false;
          return true;
        }
    });

    this.$validator.extend(
      'urlWithHost',{
        getMessage: () => `${this.t('urlWithHostValidationMessage')}`,
        validate: (value) => {
          if (!validateUrlWithHost(value)) return false;
          return true;
        }
    });
  }
}
