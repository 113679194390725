export default {
    host(state, getters, rootState) {
      return (id) => {
        let obj = {};
        obj = rootState.networksecure.status.hosts.find((item) => {
          return item.hostname === id;
        });
        return obj;
      };
    },
    modules(state, getters) {
      return (id) => {
        const host = getters.host(id);

        return host && host.modules || {};
      };
    },
    hostStatus(state) {
      return state.host_status;
    },
    hostAndProcessStatus(state) {
      return state.host_and_process_status;
    },
    hostAndProcessPerformance(state) {
      return state.host_and_process_performance;
    },
}