import _ from 'lodash';

export default {
    isDebug(rootGetters) {
        return rootGetters['settings/getDebug']
    },
    getAdvancedConfiguration(state) {
        return state.advancedConfiguration !== undefined 
            ? { 
                page_mode: state.advancedConfiguration.content_control_blocking_page_type,
                custom_blocking_page_url: state.advancedConfiguration.content_control_blocking_page_url,
                enable_safe_search: state.advancedConfiguration.enable_safe_search
            } : {};
    },
    getDefaultBlockingUrl(state) {
        return state.defaultBlockingUrl;
    }
}