import axios from "axios";
import i18n from "../../../i18n";

export default {
    getAgents({ commit, state, rootGetters }) {
        if( !rootGetters['settings/getDebug'] ) {
            return new Promise((resolve, reject) => {                
                axios.get('/subsystems/rs/router-agent-packages')
                    .then(res => {
                        if (res && res.data  && res.data.items ) {
                            state.agents_list.clear()
                            res.data.items.forEach(item => {
                                let agent = {
                                    id: item.agent_package_id,
                                    data: {
                                        agent_package_id : item.agent_package_id,
                                        agent_version_id : item.agent_version_id,
                                        router_model : item.router_model,
                                        firmware_versions : item.firmware_versions,                                        
                                        upload_time: item.upload_time,                        
                                        upload_timef: item.upload_time                        
                                    }
                                }
                                commit('addAgent', agent)                                
                            })
                            let ret = state.agents_list
                            resolve(ret)
                        }
                        else {
                            reject()
                        }
                    })
                    .catch(error => {
                        commit('setError', error, { root: true });
                        reject(error)
                    })
            })
        }
        else {
            return new Promise((resolve, reject) => {
                let temp = [                    
                    {   
                        "agent_package_id" : "1234",
                        "agent_version_id" : "agent-100.bin",
                        "router_model" : "Sarcomm SHG3000",
                        "firmware_versions" : ["4.2.4.5.1","4.2.4.5.4"],                        
                        "upload_time" : "2020-02-13T15:25:00Z",
                        "upload_timef" : "2020-02-13T15:25:00Z"
                    },
                    {   
                        "agent_package_id" : "6789",
                        "agent_version_id" : "agent-200.bin",
                        "router_model" : "Sarcomm SHG3000",
                        "firmware_versions" : ["4.2.4.5.1"],                        
                        "upload_time" : "2019-03-22T18:30:00Z",
                        "upload_timef" : "2019-03-22T18:30:00Z"
                    },
                    {   
                        "agent_package_id" : "5689",
                        "agent_version_id" : "agent-300.bin",
                        "router_model" : "TP-link 45",
                        "firmware_versions" : ["1.0.0.15"],                        
                        "upload_time" : "2021-11-13T09:32:00Z",
                        "upload_timef" : "2021-11-13T09:32:00Z"
                    },
                ];
                if (state.agents_list.size <= 0)
                    temp.forEach(item => {
                        let agent = {
                            id: item.agent_package_id,
                            data: {
                                agent_package_id : item.agent_package_id,
                                agent_version_id : item.agent_version_id,
                                router_model : item.router_model,
                                firmware_versions : item.firmware_versions,                                
                                upload_time: item.upload_time,
                                upload_timef: item.upload_time
                                
                            }
                        }
                        commit('addAgent', agent)
                    })
                let ret = state.agents_list 
                resolve(ret)
            });
        }    
    },

    deleteAgent({ commit, state, rootGetters }, id) {
        if( !rootGetters['settings/getDebug'] ) {
            return new Promise((resolve, reject) => {                 
                axios.delete('/subsystems/rs/router-agent-packages/' + encodeURIComponent(id))
                    .then(res => {
                        if (res) {
                            commit('removeAgent', id);
                            resolve(state.agents_list)
                        }
                    })
                    .catch(error => {
                        const errt = ['AGENT_PACKAGE_IN_USE']
                        if (error.response && error.response.data && errt.includes(error.response.data.type.split(".")[1]) )
                            commit('setError', i18n.t(error.response.data.type.split(".")[1]), { root: true })
                        else
                            commit('setError', error, { root: true });
                        reject()
                    })
            });
        }
        else {
            return new Promise((resolve, reject) => {
                commit('removeAgent', id)
                resolve(state.agents_list)
            });
            
        }
    },

    saveDefaultAgent({ commit, state, rootGetters }, data) {        
        if( !rootGetters['settings/getDebug'] ) {
            let ap = {
                router_model : data.router_model,
                agent_package_id : data.agent_package_id,
                firmware_version : ''
            }   

            data.firmware_versions.forEach(item => {
                ap.firmware_version = item
                
                axios.post('/subsystems/rs/router-default-agent-packages', ap )
                .then({                                               
                })
                .catch(error => {
                    commit('setError', error, { root: true });                        
                })
            })
        } 
    },


    checkDefaultRouterModel({ commit, state, rootGetters }, data) {        
        if( !rootGetters['settings/getDebug'] ) {
            return new Promise((resolve, reject) => {                
                axios.get('/subsystems/rs/router-default-agent-packages')
                .then( res => {
                    if (res && res.data  && res.data.items ) {                                                
                        res.data.items.forEach(item => {                             
                            if( item.router_model == data.router_model && data.firmware_versions.indexOf(item.firmware_version) >= 0 ){                              
                                resolve("1")     
                            }
                        })
                    }
                    resolve("0");
                })
                .catch(error => {
                    commit('setError', error, { root: true });                        
                })

            })
        } else { 
            return new Promise((resolve, reject) => {                
                resolve("0")
            })            
        }
    },

    async checkAgentPackageAsDefault({ commit, state, rootGetters }, data) {        
        if( !rootGetters['settings/getDebug'] ) {
            try {
                let def = "0"
                const res = await axios.get('/subsystems/rs/router-default-agent-packages')                
                if (res && res.data  && res.data.items ) {                        
                    res.data.items.forEach(item => {                                                    
                        if( item.agent_package_id == data.agent_package_id )                           
                            def = "1";                             
                    })
                    return def;
                } else                
                    return "0";                                
            } catch (err) {                                
                return "0"; 
            }
        }      
    },

    updateAgent({ commit, state, rootGetters }, data) {
        delete data.upload_timef;
        
        if( !rootGetters['settings/getDebug'] ) {
            return new Promise((resolve, reject) => {                
                let fv = {
                    firmware_versions : data.firmware_versions
                }   
                axios.patch('/subsystems/rs/router-agent-packages/' + encodeURIComponent(data.agent_package_id), fv )
                    .then(res => {                       
                        let agent = {
                            id: data.agent_package_id,
                            data: data
                        }        
                        commit('addAgent', agent)
                        resolve(state.agents_list);
                    })
                    .catch(error => {
                        //commit('setError', error, { root: true });                        
                        commit('setAgentError', i18n.t(error.response.data.type.split(".")[1]))                        
                        reject(error)                        
                    })
            });
        } else {            
            return new Promise((resolve, reject) => {                
                let agent = {
                    id: data.agent_package_id,
                    data: data
                }        
                commit('addAgent', agent)
                resolve(state.agents_list)
            })
            
        }
    },

    cloneAgent({ commit, state, rootGetters }, data) {        
        if(!rootGetters['settings/getDebug'] ) {
            return new Promise((resolve, reject) => {                
                let fv = {
                    router_model : data.router_model,
                    firmware_versions : data.firmware_versions
                }   
                axios.post('/subsystems/rs/router-agent-packages/' + encodeURIComponent(data.agent_package_id) + "/clone", fv )                             
                    .then(res => { 
                        if(res && res.data ){                      
                            let agent = {
                                id: res.data.agent_package_id,
                                data: data
                            }                             
                            agent.data.agent_package_id = res.data.agent_package_id                              
                            commit('addAgent', agent)
                            resolve(state.agents_list);
                        } else                            
                            reject()                                                                        
                    })
                    .catch(error => {
                        const errt = ['DUPLICATED_PACKAGE', 'TOO_MANY_PACKAGES']
                        if (error.response && error.response.data && errt.includes(error.response.data.type.split(".")[1]) ){
                            let lerr = error.response.data.type.split(".")[1];                           
                            if( lerr == 'DUPLICATED_PACKAGE' ) lerr += "_CLONE"                            
                            commit('setAgentError', i18n.t(lerr))
                        } else
                            commit('setError', error, { root: true });
                        reject(error)
                    })
            });
        } else {            
            return new Promise((resolve, reject) => {  
                let iditem = Math.ceil(Math.random()*10000)
                let agent = {
                    id: iditem,
                    data: data
                }  
                agent.data.agent_package_id = iditem      
                commit('addAgent', agent)
                resolve(state.agents_list)
            })
            
        }
    },

    addAgent({ commit, state, rootGetters }, bfile){
        if( !rootGetters['settings/getDebug'] ) {
            return new Promise((resolve, reject) => { 
                let data = {
                    data : bfile
                }                               
                axios.post('/subsystems/rs/load-router-agent-package', data)
                    .then(res => {
                        if( res && res.data ){
                            let agent = {
                                id: res.data.agent_package_id,
                                data: {
                                    agent_package_id : res.data.agent_package_id,
                                    agent_version_id : res.data.agent_version_id,
                                    router_model : res.data.router_model,
                                    firmware_versions : res.data.firmware_versions,                                        
                                    upload_time: res.data.upload_time,
                                    upload_timef: res.data.upload_time,
                                }
                            }
                            commit('addAgent', agent)
                            resolve(agent.data);
                        } else
                            reject()
                    })
                    .catch(error => {
                        const errt = ['BAD_INPUT', 'BAD_TGZ', 'BAD_SIGNATURE', 'BAD_CONTENT', 'TOO_MANY_PACKAGES', 'DUPLICATED_PACKAGE', 'INTERNAL_ERROR', "BAD_ROUTER_MODEL_FIRMWARE_VERSION"]
                        if (error.response && error.response.data && errt.includes(error.response.data.type.split(".")[1]) ){
                            commit('setAgentError', i18n.t(error.response.data.type.split(".")[1]))
                        }else
                            commit('setError', error, { root: true });
                        reject(error)
                    })
            });      
        } else {            
            return new Promise((resolve, reject) => { 
                let id = parseInt(Math.random()*1000)
                let agent = {
                    id: id,
                    data: {
                        agent_package_id : id,
                        agent_version_id : 'agent-100.bin',
                        router_model : 'TP-Link 45',
                        firmware_versions : ['1.0.0.15', '1.2.1.3.3'],                                        
                        //firmware_versions : [],                                        
                        upload_time: "2020-06-12T18:30:00Z",
                        upload_timef: "2020-06-12T18:30:00Z"
                    }
                }
                commit('addAgent', agent)               
                resolve(agent.data)
            })            
        }
    }
    
}