<template>
  <div>
    <page-title-card
      :page-title="t('title')"
      :page-description="t('description')"
    />
    <service global :serviceName="serviceName" :visible_for_permissions='[{actions: ["PATCH"], path: "/isp-service-configuration"}]'/>
    <b-row class="mt-1" v-if="showBlockingPages(configurations[serviceName])">
      <b-col  md="12" lg="12">
        <blocking-pages :service="serviceName" :visible_for_permissions='[{actions: ["PATCH"], path: "/isp-service-configuration"}]'/>
      </b-col>
    </b-row>
    <b-row class="mt-1" v-if="serviceName === 'auto_notice'">
      <b-col  md="12" lg="12">
        <service-packages/>
      </b-col>
    </b-row>
    <b-row  align-h="between">
      <b-col :md="serviceWidth" :lg="serviceWidth" v-if="showService(configurations[serviceName])">
        <service-settings global
          :settings="configurations[serviceName]"
          :disable="disable(serviceName)"
          :jsonschemaName="'ISPServiceConfiguration'"
          :configurationType="'global_configurations'"
          :settingGroup="serviceName"
          :title="'Configurations'"
          :visible_for_permissions='[{actions: ["PATCH"], path: "/isp-service-configuration"}]'
        />
      </b-col>
      <b-col :md="serviceWidth" :lg="serviceWidth" v-if="showService(advancedConfigurations[serviceName])" >
        <service-settings global
          :ProductAttention="ProductAttention"
          :settings="advancedConfigurations[serviceName]"
          :disable="disable(serviceName)"
          :jsonschemaName="'ISPServiceAdvancedConfiguration'"
          :configurationType="'global_advanced_configurations'"
          :settingGroup="serviceName"
          :title="'Advanced configurations'"
          :visible_for_permissions='[{actions: ["PATCH"], path: "/isp-service-configuration"}]'
        />
      </b-col>
    </b-row>
    <b-modal id="confirm-update" @ok="setConfigurations" :ok-title="t('accept')" :cancel-title="t('cancel')" :title="t('Save the changes')">
      {{t('Are you sure want to save?')}}
    </b-modal>
  </div>
</template>

<script>
import Service          from "@/components/AsmConfigurations/general/Service";
import ServiceSettings  from "@/components/AsmConfigurations/general/ServiceSettings";
import ispServices      from "@/mixins/ispServices/ispServices";
import BlockingPages    from '@/components/BlockingPages/BlockingPages.vue';
import ServicePackages  from './ServicePackages/ServicePackages'
import PageTitleCard from "../../../components/AsmConfigurations/PageTitleCard";

export default {
  name: "IspServiceConfigurations",
  mixins: [ ispServices],
  components: {
    Service,
    ServiceSettings,
    BlockingPages,
    ServicePackages,
    PageTitleCard
  },
  props: ["serviceName"],
  computed:{
    serviceWidth() {
      if(this.showService(this.configurations[this.serviceName]) && this.showService(this.advancedConfigurations[this.serviceName])) {
        return 6
      }
      return 12
    },
    ProductAttention() {
      if (this.serviceName === "parental_control") {
        return {
        title: "* Only 'Max numbers of schedules for Quite Time' is applicable for EndpointSecure, supporting a maximum of 5",
          operator: "OR",
          products: ["EndpointSecure"],
        };
      }
      return null;
    },
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.refresh(true);
    })
  }

}
</script>
