import axios from "axios";
import i18n from "../../../i18n";

import { prepareAlertTable } from "../../../pages/Alerts/alertUtils";

export default {
    setSuccess({ commit }, msg) {
        commit('setSuccess', msg, { root: true });
    },
    setError({ commit }, msg) {
        commit('setError', msg, { root: true });
    },
    deleteAlert({ commit, rootState, state }, alertId) {
        if (rootState.settings.debug) {
            const alertToDelete = state.alerts.findIndex(element => element.alert_id == alertId);
            commit('removeAlert', alertToDelete);
        } else {
            // DELETE /subsystems/ns/alerts/ {alert_ns_id}
            return new Promise((resolve, reject) => {
                axios.delete('/subsystems/ns/alerts/' + encodeURI(alertId))
                    .then(res => {
                        const alertToDelete = state.alerts.findIndex(element => element.name == alertId);
                        commit('removeAlert', alertToDelete);
                        resolve(res);
                    })
                    .catch(err => {
                        commit('setError', err, { root: true });
                        reject(err);
                    });
            });
        }
    },
    getAlertsData({ commit, state, getters, rootState }) {
        // GET /subsystems/ns/alerts
        if (rootState.settings.debug) {
            commit('setAlerts', state.alertsDummy);
        } else {
            // GET /subsystems/ns/alerts 
            return new Promise((resolve, reject) => {
                axios.get('/subsystems/ns/alerts', { headers: { 'x-wait-on-loading': 15 } })
                    .then(res => {
                        if (res && res.data && res.data.items) {
                            const preparedData = prepareAlertTable(res.data.items, getters.getAllEvents);
                            commit('setAlerts', preparedData);
                            resolve(preparedData)
                        }
                    })
                    .catch(error => {
                        commit('setError', error, { root: true });
                        reject(error)
                    })
            });
        }
    },
    updateAlerts({ commit, rootState, state, getters }, alert) {
        if (rootState.settings.debug) {
            commit('setAlertDummyDetails', alert)
            const actionsSelected = []
            const realActions = {
                system_log: 'SystemLog',
                internal_log: 'InternalLog',
                mail: 'Mail',
                SNMP: 'SNMP'
            }
            Object.keys(alert.actions).map(element => {
                if (element !== 'undefined') {
                    if (alert.actions[element]) {
                        actionsSelected.push(realActions[element])
                    }
                }
            })

            const eventsSelected = []
            alert.events.map(eventCode => {
                const selected = state.events.find(event => event.code === eventCode)
                !eventsSelected.includes(selected.severity) && eventsSelected.push(selected.severity)
            })

            const row = {
                alert_id: alert.alert_id,
                alert_event_type_list: eventsSelected,
                alert_action_list: actionsSelected
            }
            commit("setAlertDummy", row);
        } else {
            return new Promise((resolve, reject) => {
                axios.patch('/subsystems/ns/alerts/' + encodeURI(alert.alert_id), alert)
                    .then(res => {
                        const alerts = state.alerts.map(
                            row => row.alert_id === alert.alert_id ? alert : row
                        );
                        commit("setAlerts", alerts);
                        resolve(res);
                    })
                    .catch(err => {
                        commit('setError', err, { root: true });
                        reject(err);
                    });
            });
        }

    },
    createAlert({ commit, rootState, state }, alert) {

        const eventsSelected = []
        alert.events.map(eventCode => {
            const selected = state.events.find(event => event.code === eventCode)
            !eventsSelected.includes(selected.severity) && eventsSelected.push(selected.severity)
        })

        if (rootState.settings.debug) {
            commit('addAlertDummyDetails', alert)
            const actionsSelected = []
            const realActions = {
                system_log: 'SystemLog',
                internal_log: 'InternalLog',
                mail: 'Mail',
                SNMP: 'SNMP'
            }
            Object.keys(alert.actions).map(element => {
                if (element !== 'undefined') {
                    if (alert.actions[element]) {
                        actionsSelected.push(realActions[element])
                    }
                }
            })

            const row = {
                alert_id: alert.alert_id,
                alert_event_type_list: eventsSelected,
                alert_action_list: actionsSelected
            }
            commit("addAlertDummy", row);
        } else {
            // POST /subsystems/ns/alerts

            let row = {
                ...alert,
            }

            if (alert.actions.mail === false || alert.mail_action_config.sender === '') {
                delete row['mail_action_config']
            }

            return new Promise((resolve, reject) => {
                axios.post('/subsystems/ns/alerts', row)
                    .then(res => {
                        commit("addAlert", alert);
                        resolve(res);
                    })
                    .catch(err => {
                        commit('setError', err, { root: true });
                        reject(err);
                    });
            });
        }
    },
    getAllEvents({ commit, state, dispatch, rootState }, wait) {
        // check if debug
        if (rootState.settings.debug) {
            let events = [
                { severity: 'Critical', code: 1, text: 'Alert Critical message 1' },
                { severity: 'Critical', code: 2, text: 'Alert Critical message 2' },
                { severity: 'Error', code: 3, text: 'Alert Error message 1' },
                { severity: 'Error', code: 4, text: 'Alert Error message 2' },
                { severity: 'Warning', code: 5, text: 'Alert Warning message' },
                { severity: 'Information', code: 6, text: 'Alert Information message' }
            ];
            commit('setEvents', events);
            return (Promise.resolve(events))
        } else {
            // GET /subsystems/ns/events
            return new Promise((resolve, reject) => {
                axios.get('/subsystems/ns/events', { headers: { 'x-wait-on-loading': 15 } })
                    .then(res => {
                        if (res && res.data) {
                            commit('setEvents', res.data);
                            resolve(res.data)
                        }
                    })
                    .catch(error => {
                        commit('setError', error, { root: true });
                        reject(error)
                    })
            });
        }

    },
    getDefaultEvents({ commit, state, dispatch, rootState }) {
        if (rootState.settings.debug) {
            commit('setDefaultEvents', state.defaultEventsDummy);
            return (Promise.resolve(state.defaultEvents));
        } else {
            //GET /subsystems/ns/events/default
            return new Promise((resolve, reject) => {
                axios.get('/subsystems/ns/events/default', { headers: { 'x-wait-on-loading': 15 } })
                    .then(res => {
                        if (res && res.data) {
                            commit('setDefaultEvents', res.data);
                            resolve(res.data)
                        }
                    })
                    .catch(error => {
                        commit('setError', error, { root: true });
                        reject(error)
                    })
            });
        }
    },
    changeDefaultEvent({ commit, rootState }, event) {
        if (rootState.settings.debug) {
            commit('setDefaultEventsDummy', event)
        } else {
            //PATCH /subsystems/ns/events/default
            let row = {
                ...event
            }
            if (!event.mail_action_config.sender || event.mail_action_config.sender === '') {
                delete row['mail_action_config']
            }

            return new Promise((resolve, reject) => {
                axios.patch('/subsystems/ns/events/default', row)
                    .then(res => {
                        commit("setEvents", row);
                        commit('setSuccess', i18n.t('save_success'), { root: true });
                        resolve(res);
                    })
                    .catch(err => {
                        commit('setError', err, { root: true });
                        reject(err);
                    });
            });
        }

    },
    getAlertById({ state, commit, rootState }, alertId) {
        if (rootState.settings.debug) {
            const alert = state.alertsDetailsDummy.find(element => element.alert_id === alertId)
            return (Promise.resolve(alert))
        } else {
            return new Promise((resolve, reject) => {
                //GET /subsystems/ns/alerts/ {alert_ns_id}
                axios.get('/subsystems/ns/alerts/' + encodeURI(alertId), { headers: { 'x-wait-on-loading': 15 } })
                    .then(res => {
                        if (res && res.data)
                            resolve(res.data)
                    })
                    .catch(error => {
                        commit('setError', error, { root: true });
                        reject(error)
                    })
            });
        }
    }
};