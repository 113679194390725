<template>
    <b-card no-body>
        <template v-slot:header>
            <h5 class="title"><i class="now-ui-icons design_bullet-list-67 mr-2"/>{{t('Autonotice Packages')}}</h5>

            <b-container fuild style="max-width: 2560px;" >
            <b-row no-gutters align-h="between">
                <b-col md="12" lg="8" xl="9" class="pt-2">
                   {{ t('In this section you can create, edit or remove AutoNotice Packages') }}
                </b-col>
                <b-col sm=""></b-col>
                <b-col cols="6" md="4" lg="3" xl="2" class="ml-auto">
                    <n-button type="primary" size="md" round block v-b-modal.modal-new :visible_for_permissions='[{actions: ["POST"], path: "/subsystems/ns/autonotices/packages"}]'>
                        <i><svgicon class="icon" icon="icon-new"/></i>
                       {{ t('New') }}
                    </n-button>   
                </b-col>
            </b-row>
            </b-container>
        </template>

        <b-card-body style="padding: 0px;">
            <paginated-table :rows='packages' 
                            :columns='columns'
                            :actions="actions" 
                            :updating="updating"
                            :search="['service_name']"
                            @status-change='handleStatusChange'
                            @item-remove="handleRemoveService"/>
        </b-card-body>

        <template v-slot:footer>
            <div class="hr-grey mb-2"/>
            <div @click="refresh" v-if="!updating" style="cursor: pointer;display: inline;">
                <i class="now-ui-icons arrows-1_refresh-69" />
                {{ t('Update now') }}
            </div>
            <div v-else>
                <i class="now-ui-icons loader_refresh spin"/>
                {{ t('Updating') }}
            </div>
        </template>

        <b-modal id="modal-new" ref="modal" :title="t('New Service Package')" @ok="handleOkNewService" @hidden="resetNewService" :ok-title="t('accept')" :cancel-title="t('cancel')">
            <form class="authpages" @submit.prevent="handleNewService">  
                <div class="row ">
                    <div class="col-11 mx-auto">
                        <fg-input v-model="model.isp_notice_id"
                            v-validate="{ required: true, max: 256 }"
                            name="Service Name"
                            :error="getError('Service Name')"
                            class="no-border no-height form-control-lg"
                            :placeholder="t('Service Name')"
                            addon-left-icon="allot-icon profile"
                            autocapitalize="none"
                            style="font-size:1rem;"
                        /> 
                    </div>
                </div>  
                <!-- <div class="row">
                    <div class="col-4 mx-auto">
                        <label> {{t('Select service:')}} </label>
                    </div>
                    <div class="col-6 mx-auto">
                        <el-select class="select-primary"  
                            style="width: 100%;"
                            :placeholder="t('Service type')"
                            v-model="model.type"
                            >
                            <el-option v-for="option in serviceOptions"
                                class="select-primary"
                                :value="option.value"
                                :label="option.label"
                                :key="option.value"
                            ></el-option>
                        </el-select>
                    </div>
                </div> -->
                <div class="row">
                    <div class="col-4 mx-auto">
                        <label> {{t('Status')}}: </label>
                    </div>
                    <div class="col-6 mx-auto">
                        <n-switch v-model="model.enabled" />
                    </div>
                </div>
            </form>
        </b-modal>
    </b-card>
</template>

<script>
import { PaginatedTable, Switch } from '@/components';
import { Select, Option} from 'element-ui';
import SwitchFormatter from '@/components/Tables/Formatters/NoFunctionalSwitch';

export default {
    name: 'service-packages',    
    components: {
        PaginatedTable,  
        [Select.name]: Select,
        [Option.name]: Option,
        "n-switch" : Switch
    },    
    data() {
        return {
            model: {
                enabled: true,
                type: 'Autonotice',
                isp_notice_id: null,
            },
            updating: false,
            services: [],        
            columns: [
                {
                    prop: 'service_name',
                    label: this.t("Service Name"),
                    minWidth: 130
                },
                { 
                    prop: 'enabled',
                    label: 'Status',
                    minWidth: 100,
                    formatter: SwitchFormatter,
                    visible_for_permissions: [{actions: ["PUT"], path: "/isp-autonotices-packages/.*"}] 
                },
            ],
            actions: {
                minWidth: 100,
                label: this.t('Actions'),
                fixed: 'right',
                items: [    
                    {
                        type: "danger",
                        size: "sm",
                        icon: "now-ui-icons ui-1_simple-remove",
                        action: this.t('Remove'),
                        confirm: true,
                        event: "item-remove",
                        visible_for_permissions: [{actions: ["DELETE"], path: "/isp-autonotices-packages/.*"}]
                    }
                ]
            },
            serviceOptions: [ {value: this.t('Autonotice'), label: 'Autonotice'}],
        }       
    },
    mounted() {                
        this.refresh(0)       
    },
    computed: {
        packages(){
            let ret = [];
            let vm = this
            if ( !this.services)
                return ret

            this.services.forEach( (value, key) => {  
                ret.push({...value, service_name: value.isp_notice_id})
            })
            let arRet = Array.from(ret).sort( (a,b) => {
                return a.service_name.toUpperCase() > b.service_name.toUpperCase() ? 1: -1
            })
            return arRet;            
        },            
    },
    methods: {
        getError(fieldName) {
            return this.errors.first(fieldName);
        },
      
        resetNewService() {
            this.model.isp_notice_id = ''
            this.model.enabled = true
            this.$validator.reset()
        },
        handleOkNewService( bvModalEvt ) {
            bvModalEvt.preventDefault()
            this.handleNewService()
        },
        async handleNewService() {              
            let isValidForm = await this.$validator.validateAll();
            if (!isValidForm) return false;
            
            let service = {
                isp_notice_id : this.model.isp_notice_id,
                enabled : this.model.enabled
            }

            this.$store.dispatch('servicepackages/createService', service ).then( res => {
                this.$store.commit("setSuccess", this.t("Service") + " " + service.isp_notice_id + " " + this.t("successfully created"))                            
                this.services = res;          
            })
            .catch( err => {this.refresh(0); } )

            this.$nextTick(() => {
                this.$refs.modal.hide()                
            })
        },
        handleStatusChange(updatedRow) {
            let service = {
                isp_notice_id : updatedRow.isp_notice_id,
                enabled : updatedRow.enabled,
            }
            this.$store.dispatch('servicepackages/updateService', service).then( res => {
                this.$store.commit("setSuccess", this.t("Service") + " " + service.isp_notice_id + " " + this.t("successfully updated"))                            
                this.services = res;    
            })
            .catch( err => {this.refresh(0); } )
        },
        handleRemoveService(data){
            this.$store.dispatch('servicepackages/deleteService', data.row.service_name).then( res => {
                this.$store.commit("setSuccess", this.t("Service") + " " + data.row.service_name + " " + this.t("successfully removed"))
                this.services = res
            })        
        },          
        refresh(wait){          
            let _vm = this
            this.updating = true;
            this.$store.dispatch('servicepackages/getServices')
            .then( res => {                              
                _vm.services = _.cloneDeep(res.items)
                this.updating = false
            })
            .catch( err => {
                this.updating = false
            });
        }
    },   
}
</script>

<style lang="scss" scoped>

    .authpages {
        label {
            margin: 1em;
        }
    }

</style>
