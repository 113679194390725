/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icon-users': {
    width: 16,
    height: 16,
    viewBox: '0 0 611.998 611.998',
    data: '<path pid="0" d="M316.343 106.541c12.479 13.989 21.299 31.267 26.218 51.353 4.667 19.067 5.711 40.275 3.107 63.04a319.439 319.439 0 01-4.476 28.046c16.122 15.61 37.242 25.521 64.7 25.52 76.182-.005 103.593-76.283 110.766-138.938C525.5 58.374 489.003.279 405.893.279c-65.217 0-101.728 35.787-110.15 88.501 7.62 5.045 14.529 10.955 20.6 17.761z"/><path pid="1" d="M611.966 456.132c-.829-24.312-3.679-48.739-7.925-72.674-5.135-28.995-11.681-71.505-36.996-90.402-14.639-10.924-33.598-14.509-50.355-21.095-8.157-3.205-15.467-6.389-22.324-10.013-23.148 25.385-53.333 38.664-88.482 38.666-27.351 0-51.678-8.08-72.042-23.615-4.688 14.499-10.963 29.102-19.352 42.443 7.367 4.438 15.339 7.98 25.022 11.784 4.287 1.686 8.992 3.224 13.975 4.851 13.47 4.403 28.737 9.392 42.444 19.623 32.582 24.322 40.603 69.898 46.46 103.178l.631 3.59c3.029 17.066 5.253 33.832 6.684 50.12 26.004-2.665 51.877-7.218 77.246-12.574 20.505-4.328 40.876-9.61 60.602-16.727 15.761-5.681 25.059-8.322 24.412-27.155z"/><path pid="2" d="M430.171 464.752l-.634-3.607c-5.477-31.126-12.98-73.754-41.411-94.977-11.99-8.95-25.564-13.386-38.693-17.676-5.169-1.689-10.051-3.284-14.697-5.111-6.778-2.663-12.885-5.271-18.606-8.154-24.037 32.072-57.348 48.959-96.974 48.962-39.615 0-72.923-16.887-96.959-48.962-5.721 2.883-11.828 5.491-18.607 8.154-4.645 1.825-9.528 3.42-14.696 5.109-13.129 4.29-26.706 8.726-38.694 17.676-28.439 21.226-35.938 63.862-41.413 94.993l-.634 3.593C3.576 490.592.846 515.658.038 539.253c-.949 27.684 16.233 33.854 31.391 39.3l1.633.588c18.229 6.578 38.618 12.21 62.335 17.223 48.428 10.222 87.728 15.101 123.673 15.356h.186c35.95-.255 75.248-5.135 123.67-15.356 23.72-5.011 44.109-10.643 62.332-17.221l1.63-.586c15.162-5.445 32.349-11.616 31.4-39.303-.812-23.654-3.545-48.721-8.117-74.502z"/><path pid="3" d="M220.175 363.91c76.182-.005 103.593-76.28 110.766-138.94 8.839-77.188-27.661-135.28-110.766-135.28-83.094 0-119.616 58.086-110.763 135.28 7.175 62.66 34.581 138.945 110.763 138.94z"/>'
  }
})
