export default {
    setFirewallStrategy(state, firewallStrategy) {
        state.firewallStrategy = firewallStrategy;
    },
    setFirewallRules(state, rules) {
        rules = rules.sort((a, b) => {
            return a.order > b.order ? 1 : -1;
        });
        state.rules = rules;
    },
    addFirewallRule(state, newRule) {
        state.rules.push(newRule);
    },
};