/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'item-windows': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g _fill="#383132"><path pid="0" d="M22.8 3.6c-.1-.1-.2-.1-.3 0-1.3.6-2.5.8-3.8.8-1 0-2.1-.2-3.5-.6-.2 0-.3 0-.4.2l-2 7.1v.2c0 .1.1.1.2.1 1.4.4 2.6.6 3.7.6 1.3 0 2.7-.3 4-.9.1 0 .1-.1.2-.2l2-7.1s0-.1-.1-.2zM3.8 11.2h.1c1.3-.6 2.5-.8 3.8-.8 1 0 2.1.2 3.5.6.2 0 .3 0 .4-.2l2-7.1v-.2c0-.1-.1-.1-.2-.1-1.4-.5-2.6-.7-3.7-.7-1.3 0-2.7.3-4 .9-.1 0-.1.1-.2.2l-2 7.1c0 .1 0 .2.1.3h.2zM11 12.4c0-.1-.1-.1-.2-.1-1.4-.4-2.6-.6-3.7-.6-1.3 0-2.7.3-4 .9-.1 0-.1.1-.1.2l-2 7.1c0 .1 0 .2.1.3.1 0 .1.1.2.1h.1c1.3-.6 2.5-.8 3.8-.8 1 0 2.1.2 3.5.6.2 0 .3 0 .4-.2l2-7.1c-.1-.2-.1-.3-.1-.4zM19.9 12.6c-1.3.6-2.5.8-3.8.8-1 0-2.1-.2-3.5-.6-.2 0-.3 0-.4.2l-2 7.1v.2c0 .1.1.1.2.1 1.4.4 2.6.6 3.7.6 1.3 0 2.7-.3 4-.9.1 0 .1-.1.2-.2l2-7.1c0-.1 0-.2-.1-.3-.1 0-.2 0-.3.1z"/></g>'
  }
})
