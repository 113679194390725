import _ from 'lodash';

export default {
    update_current_service_id(state, service_id) {
        state.current_service_name = service_id
    },
    setBlackList(state, value) {
        state.forbiddenAllowedList.black_list = value;
    },
    setWhiteList(state, value) {
        state.forbiddenAllowedList.white_list = value;
    },
    updateBlackList(state, { action, item }) {
        switch (action) {
            case 'add':
                state.forbiddenAllowedList.black_list.unshift(item)
                break;
            case 'del':
                state.forbiddenAllowedList.black_list = state.forbiddenAllowedList.black_list.filter(i => i !== item)
                break;
        }
    },
    updateWhiteList(state, { action, item }) {
        switch (action) {
            case 'add':
                state.forbiddenAllowedList.white_list.unshift(item)
                break;
            case 'del':
                state.forbiddenAllowedList.white_list = state.forbiddenAllowedList.white_list.filter(i => i !== item)
                break;
        }
    },
    set_self_query(state, { self_query, action }) {
        state.params_self_query[action] = self_query
    },
    set_query(state, { query, action }) {
        state.params_query[action] = query
    },
    setAdvancedConfigurations(state, value) {
        state.advanced_configurations = value;
        state.default_configurations = _.cloneDeep(value);
    },
    setGlobalConfigurations(state, value) {
        state.global_configurations = value;
        state.default_global_configurations = _.cloneDeep(value);
    },
    setGlobalAdvancedConfigurations(state, value) {
        state.global_advanced_configurations = value;
        state.default_global_advanced_configurations = _.cloneDeep(value);
    },
    setConfig(state, { key, value }) {
        state.advanced_configurations[key]["enabled"] = value;
    },
    setGlobalConfig(state, { key, value }) {
        state.global_configurations[key]["enabled"] = value;
    },
    setServiceField(state, { service, field, value, configuration_type, type }) {
        value = type == 'number' ? parseInt(value) : value

        if (type !== 'number' || value < Number.MAX_SAFE_INTEGER) {
            state[configuration_type][service][field] = value;
        }
    },
    setFilterByService(state, value) {
        state.filter_by_service = value;
    },
    setFilterByProduct(state, value) {
        state.filter_by_product = value;
    },
    refresh(state, global) {
        if (global) {
            state.global_configurations = JSON.parse(JSON.stringify(state.default_global_configurations));
            state.global_advanced_configurations = JSON.parse(JSON.stringify(state.default_global_advanced_configurations));
        }
        else {
            state.advanced_configurations = JSON.parse(JSON.stringify(state.default_configurations));
        }
    },
}