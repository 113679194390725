import _ from 'lodash';

export default {
    notificationOptions: (state) => {
        const services = _.cloneDeep(state.profile.services_configuration);
        delete services.ads_free;
        delete services.auto_notice;
        return services;
    },
    disabledServices: (state) => {
        let disabledServices = []
        for (const key in state.profile.services_configuration) {
            if (state.profile.services_configuration[key] &&
              !state.profile.services_configuration[key].enabled) {
              disabledServices.push(key)
            }
        }
        return disabledServices
    },
    threatProtectionStatuses: (state) => {
        const props = _.cloneDeep(state.profile.services_configuration.threat_protection);
        delete props.enabled;
        delete props.allowed_urls;
        delete props.send_notifications;
        delete props.notifications_config;

        return props;
    },
    profile: (state) => state.profile,
    ispCategories: (state) => (lang) => {
        lang == 'es' ? lang = 'es-ES' : lang = 'en-US';
        let localeCategories = [];
        if (state.ispCategories != null) {
            state.ispCategories.forEach(element => {
                let categoryLocale = element.isp_category_i18n.find(obj => {
                    return obj.language_id === lang;
                })
                let el = _.cloneDeep(element);
                if (categoryLocale) {
                    el.name = categoryLocale.isp_category_name;
                }
                localeCategories.push(el);
            });
        }
        return localeCategories;
    }
}
