/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'item-autonotice': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M20.1 3.4h-16c-1.1 0-2 .9-2 2v18l4-4h14c1.1 0 2-.9 2-2v-12c0-1.1-.9-2-2-2zm0 14H5.2L4 18.6V5.4h16v12zm-9-11h2v6h-2v-6zm0 8h2v2h-2v-2z" _fill="#383132"/>'
  }
})
