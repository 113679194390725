/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'third-party-licenses': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path pid="0" d="M15.974 0c4.391 0 8.337 1.622 11.41 4.69C30.404 7.703 32 11.614 32 16c0 4.4-1.58 8.28-4.569 11.222C24.311 30.303 20.241 32 15.974 32 11.75 32 7.746 30.317 4.7 27.26 1.626 24.194 0 20.3 0 16c0-4.221 1.682-8.254 4.736-11.357C7.742 1.605 11.628 0 15.974 0zm.044 3.367c-3.443 0-6.514 1.27-8.88 3.674C4.709 9.513 3.372 12.695 3.372 16c0 3.295 1.337 6.447 3.764 8.873 2.397 2.395 5.552 3.714 8.882 3.714 3.348 0 6.55-1.337 9.02-3.764 2.344-2.307 3.584-5.358 3.584-8.823 0-3.459-1.257-6.54-3.636-8.913-2.44-2.435-5.54-3.721-8.968-3.721zm-4.37 7.495c2.017 0 3.538.829 4.4 2.398a.262.262 0 01-.109.359l-2.093 1.093a.262.262 0 01-.36-.12c-.498-1.068-1.344-1.122-1.508-1.122-1.226 0-1.848.851-1.848 2.53 0 .76.18 2.531 1.848 2.531.833 0 1.339-.358 1.693-1.196a.263.263 0 01.36-.132l1.962.988a.263.263 0 01.11.364c-.924 1.622-2.558 2.591-4.37 2.591-3.011 0-4.957-2.02-4.957-5.146 0-3.025 2.004-5.138 4.872-5.138zm9.088 0c2.053 0 3.536.807 4.406 2.4a.263.263 0 01-.11.358l-2.092 1.092a.262.262 0 01-.359-.12c-.498-1.063-1.346-1.117-1.51-1.117-1.231 0-1.855.85-1.855 2.525 0 .76.18 2.53 1.854 2.53.84 0 1.347-.357 1.696-1.195a.263.263 0 01.36-.133l1.957.989a.262.262 0 01.11.364c-.93 1.623-2.562 2.591-4.367 2.591-3.014 0-4.962-2.02-4.962-5.146 0-3.025 2.003-5.139 4.872-5.139z" _fill="#000" fill-rule="evenodd"/>'
  }
})
