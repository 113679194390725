/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'rule-conditions': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path pid="0" d="M11.182 17.455a4.364 4.364 0 014.363 4.363v5.818A4.364 4.364 0 0111.182 32H5.364A4.364 4.364 0 011 27.636v-5.818a4.364 4.364 0 014.364-4.363h5.818zm0 2.909H5.364c-.804 0-1.455.65-1.455 1.454v5.818c0 .804.651 1.455 1.455 1.455h5.818c.803 0 1.454-.651 1.454-1.455v-5.818c0-.803-.65-1.454-1.454-1.454zm14.545 5.818a1.455 1.455 0 110 2.909H19.91a1.455 1.455 0 010-2.91h5.818zm2.91-5.818a1.455 1.455 0 110 2.909h-8.728a1.455 1.455 0 010-2.91h8.727zM11.181 0a4.364 4.364 0 014.363 4.364v5.818a4.364 4.364 0 01-4.363 4.363H5.364A4.364 4.364 0 011 10.182V4.364A4.364 4.364 0 015.364 0h5.818zm0 2.91H5.364c-.804 0-1.455.65-1.455 1.454v5.818c0 .803.651 1.454 1.455 1.454h5.818c.803 0 1.454-.65 1.454-1.454V4.364c0-.804-.65-1.455-1.454-1.455zm14.545 5.817a1.455 1.455 0 110 2.91H19.91a1.455 1.455 0 110-2.91h5.818zm2.91-5.818a1.455 1.455 0 110 2.91h-8.728a1.455 1.455 0 110-2.91h8.727z" _fill="#000" fill-rule="evenodd"/>'
  }
})
