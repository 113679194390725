/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'top-10-talkers': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path pid="0" d="M16 11.95a4.365 4.365 0 011.455 8.479v7.522h-2.91v-7.52A4.364 4.364 0 0116 11.95zM27.314 5c6.248 6.249 6.248 16.379 0 22.627l-2.056-2.056c5.113-5.113 5.113-13.401 0-18.514zM4.687 5l2.056 2.057c-5.031 5.032-5.111 13.139-.24 18.268l.24.246-2.056 2.057A15.956 15.956 0 010 16.314 15.956 15.956 0 014.687 5zm18.508 4.12c.233.232.455.478.665.732 3.574 4.344 2.95 10.76-1.392 14.332l-2.072-2.077a7.273 7.273 0 00.746-10.937zM8.8 9.113l2.052 2.055a7.272 7.272 0 00.746 10.937l-2.07 2.071c-.255-.21-.498-.43-.731-.663-3.975-3.977-3.974-10.425.003-14.4zm7.2 5.745a1.455 1.455 0 10.001 2.91 1.455 1.455 0 000-2.91z" _fill="#000" fill-rule="evenodd"/>'
  }
})
