import DashboardLayout        from "@/layout/dashboard/DashboardLayout.vue";
import AccountManagementPages from './account-management';
import GeneralPages           from './general';

import ReporterPages          from './reporter';
import TestingPage            from './testing';
import ManagersPages          from './managers';
import UsersPages             from './users';
import DevicesPages           from './devices';
import GeneralManagementPages from './general-management';
import AuthPages from './auth';
import Profile from './profile';
import Dashboard from './dashboard';

// import ExternalServerPages    from './externalservers';
import CyberBulling           from './cyberbulling-endpoint';
import IspGlobal              from './isp-global';
import IspServices            from './isp-services';

import webFilter              from './webFilter';
//import WebProxyPages          from './web-proxy';
import AdsFreePages           from './ads-free';
import AutonoticesPages       from './autonotices';
import AntiPhishingPages      from './anti-phishing';
import AntiVirusPages         from './anti-virus';
import AntiBotnetPages        from './anti-botnet';
import FirewallPages          from './firewall';

import LogAsPage from './logas';
import EndpointSecure from './endpoint-secure';
import DNSSecure from './dns-secure';
import RouterSecure from './router-secure';

export default [
  {
    path: "/",
    component: DashboardLayout,
    redirect: "/account_management"
  },
  AccountManagementPages,
  LogAsPage,
  Profile,
  Dashboard,

  CyberBulling,
  IspGlobal,
  IspServices,
  GeneralPages,
  TestingPage,
  ManagersPages,
  UsersPages,
  DevicesPages,
  GeneralManagementPages,
  ReporterPages,
  AuthPages,
  EndpointSecure,
  DNSSecure,
  RouterSecure,
  //ServicePackagesPages,

  //WebProxyPages,
  AdsFreePages,
  AutonoticesPages,
  webFilter,
  AntiPhishingPages,
  AntiVirusPages,
  AntiBotnetPages,
  FirewallPages,

  { path: "*", redirect: '/account_management' }
];
