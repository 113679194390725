export default {
    setAdsFree(state, adsFree) {
        state.adsFreeStrategy = adsFree;
    },
    setAdsFreeRules(state, rules) {
        rules = rules.sort((a, b) => {
            return a.order > b.order ? 1 : -1;
        });
        state.rules = rules;
    },
    addAdsFreeRule(state, newRule) {
        state.rules.push(newRule);
    },
    setCategories(state, categories) {
        state.categories = categories;
    }
};