export default () => ({
    rules: [],
    configuration: {
        redirections: null,
        banners: null,
        smartbanners: null
    },
    allowed: [],
    currentAutoNotice: {},
    currentConfiguration: {},
    autoNoticeStrategy: undefined
})