import accounts from "./accounts";
import devices from "./devices";
import managers from "./managers";
import profiles from "./profiles";
import users from "./users";

export default {
    namespaced: true,
    modules: {
        accounts,
        devices,
        managers,
        profiles,
        users
    },
}