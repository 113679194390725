import DashboardLayout from "@/layout/dashboard/DashboardLayout.vue";


const AdsFree = () => import(
  /* webpackChunkName: "ns-services" */
  "@/pages/NetworkSecure/AdsFree/AdsFree.vue"
);

const AdsFreeEdit = () => import(
  /* webpackChunkName: "ns-services" */
  "@/pages/NetworkSecure/AdsFree/AdsFreeEdit.vue"
);

const AllowedList = () => import(
  /* webpackChunkName: "ns-services" */
  "@/pages/NetworkSecure/AdsFree/Allowed.vue"
);

export default {
  path: '/ns/AdsFree',
  component: DashboardLayout,
  name: 'Network Secure / AdsFree',
  children: [
    {
      path: 'FilteringPolicies/Policies*',
      name: 'Network Secure / AdsFree / Filtering Policies / Policies',
      component: AdsFree
    },
    {
      path: "Policies/Create",
      name: 'Network Secure / AdsFree / Filtering Policies / Policies / Create',
      component: AdsFreeEdit
    },
    {
      path: "Policies/Edit/*",
      name: 'Network Secure / AdsFree / Filtering Policies / Policies / Edit',
      component: AdsFreeEdit
    },
    {
      path: 'AllowList',
      name: 'Network Secure / AdsFree / Allow List',
      component: AllowedList
    },
  ]
};
