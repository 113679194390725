import DashboardLayout from "@/layout/dashboard/DashboardLayout.vue";

const AccountDeviceEdit = () => import(
  /* webpackChunkName: "account-management" */
  "@/pages/Devices/AccountDeviceEdit.vue"
);

export default {
  path: "/account_management/accounts/edit/:account_id/devices",
  name: "Account Management / Accounts / Devices",
  component: DashboardLayout,
  children: [
    {
      path: "new",
      name: "Account Management / Accounts / Device / New",
      component: AccountDeviceEdit,
      props: true
    },
    {
      path: 'edit/:id',
      name: 'Account Management / Accounts / Device / Edit',
      component: AccountDeviceEdit,
      props: true
    },
  ]
};
