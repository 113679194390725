export default {
    asm_roles(state) {
      return state.roles
    },
    ns_roles(state) {
      return state.rolesNS
    },
        administrators(state) {
      return state.administrators
    },
    administrator: (state) => (id) => {
      return state.administrators.get(id)
    },
}