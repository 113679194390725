export default {
    getAdsFree: (state) => () => {
        return state.adsFreeStrategy !== undefined ? state.adsFreeStrategy : {};
    },
    getAdsFreeRules(state) {
        return state.rules;
    },
    isDebug(state, getters, rootState, rootGetters) {
        return rootGetters['settings/getDebug']
    },
    getAdsFreeStrategy(state, getters, rootState, rootGetters) {
        return getters.getAdsFree;
    },
    getCategories() {
        return state.categories;
    },
    getRuleById: (state, getters) => (ruleId) => {
        let rule = state.rules.filter(rule => "" + rule.rule_name === "" + ruleId);
        if (rule && rule.length > 0)
            return rule[0];
        else
            return null;
    },
};