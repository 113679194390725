import _ from 'lodash';
import { adsFree as dummyAdsFree } from '@/util/dummy/adsFreeRules.json';


export default {
    async getAdsFree({ commit, state, getters }) {
        if (getters.isDebug) {
            if (!state.adsFreeStrategy || state.adsFreeStrategy.rules.length === 0)
                commit("setAdsFree", dummyAdsFree)
        } else {
            //Get ads free from backend
        }
    },
    getAdsFreeRulesData({ state, commit, getters }) {
        if (!getters.isDebug || state.rules.length === 0) {
            let adsFree = getters.getAdsFreeStrategy();
            adsFree = _.cloneDeep(adsFree.rules)
            const sortedRules = adsFree.sort((a, b) => {
                return a.order > b.order ? 1 : -1;
            });

            commit("setAdsFreeRules", sortedRules);
        }
    },
    createAdsFreeRule({ state, commit, getters, dispatch }, createdRule) {
        createdRule.order = 0;
        createdRule.id = createdRule.rule_name;

        if (getters.isDebug) {
            commit("addAdsFreeRule", createdRule);
        } else {
            // Patch to backend
        }
    },
    deleteAdsFreeRule({ state, commit, getters, dispatch }, ruleToDelete) {
        if (getters.isDebug) {
            const deletedOrder = ruleToDelete.order;
            let rules = _.cloneDeep(state.rules);
            const updatedRules = rules.filter(rule => {
                if (rule.rule_name !== ruleToDelete.rule_name) {
                    if (rule.order > deletedOrder) rule.order--;
                    return rule;
                }
            })
            commit("setAdsFreeRules", updatedRules);
        } else {
            // Patch to backend
        }
    },
    updateAdsFreeRule({ commit, getters, state, dispatch }, updatedRule) {
        if (getters.isDebug) {
            const updatedRules = state.rules.map(rule =>
                rule.rule_name === updatedRule.rule_name ? updatedRule : rule
            );
            commit("setAdsFreeRules", updatedRules);
        } else {
            // Patch to backend
        }
    },
    updateAdsFreeRules({ commit, getters, dispatch }, updatedRules) {
        // Only for update rules order without lag, NO call to BE needed
        commit("setAdsFreeRules", updatedRules);
    },
    getCategories({ commit, getters }) {
        if (getters.isDebug) {
            const categories = [
                { value: "Adult games" },
                { value: "Art" },
                { value: "Air Sports" },
                { value: "Basketball" },
                { value: "Blogs" },
                { value: "Bombs" },
                { value: "Chat" }
            ];

            commit("setCategories", categories);
        } else {
            //Get categories from backend
        }
    }
};