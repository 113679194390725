<template>
    <div>
        <slot name="header">
            <div class="statistics info">
                <h4>{{ title }}</h4>
            </div>
        </slot>
                
        <b-container fluid >
            <b-row no-gutters>
                <b-col cols=12 v-for="item in dataset" :key="item.name">
                <b-container fluid class="statistics">
                        <b-row class="info" no-gutters align-self="center">
                            <b-col cols=3 class="p-0">
                                <b-img 
                                    thumbnail fluid :src="imageurl(item.image)" 
                                            :alt="item.name"/>
                            </b-col>
                            <b-col cols=5 class="pt-2">
                                <h6 class="align-middle mx-auto">{{item.name}} </h6>
                            </b-col> 
                            <b-col cols=4 class="pl-2">
                                <h3 class="align-middle" >
                                    <animated-number :value="item.value"/>
                                </h3>
                            </b-col>
                        </b-row>
                    </b-container>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
import AnimatedNumber from '@/components/AnimatedNumber';

export default {
    components: {
        AnimatedNumber
    },
    props: {
        title : {
            type: String,
            default: ''
        },
        data : {
            type: Array,
            default: function() {
                    return [];
            },
        }
    },
    data() {
        return {
            data2: [ 
                { name: 'Devices Protected', type: "icon-primary",
                    icon: "now-ui-icons tech_mobile", value: 12},
                { name: 'Blocked Threats', type: "icon-success",
                    icon: "isp-icon evil", value: 69},
                { name: 'Active Users', type: "icon-info",
                    icon: "isp-icon user-check", value: 5},
                { name: 'Notifications', type: "icon-danger",
                    icon: "isp-icon bubble", value: 9},
            ]
        };
    },
    computed: {
        dataset() {
            if ( this.$store.getters.isDummy && ( !this.data || this.data.length == 0))
                return this.data2;
            else
                return this.data;
        }
    },
    methods: {
        imageurl: function(image){
            if (!image || image =='' || image == 'new')
                return 'img/card_image_.jpg';
            else
                return 'img/card_image_' + image + '.jpg';
        },
    }
};
</script>
<style>
</style>