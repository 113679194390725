/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'item-android': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M8.5.9l1 1.8c-1.8.9-3.1 2.7-3.1 4.8h12c0-2.1-1.3-3.9-3.2-4.8l1-1.8V.8c-.1-.1-.2 0-.2 0l-1.1 1.8c-.6-.4-1.5-.6-2.5-.6s-1.9.2-2.7.6L8.7.7s-.1-.1-.2 0v.2zm1.1 3.5c.3 0 .6.3.6.6s-.3.6-.6.6c-.4-.1-.6-.4-.6-.7 0-.3.2-.5.6-.5zm5.7 0c.3 0 .6.3.6.6s-.3.6-.6.6-.6-.3-.6-.6c0-.4.3-.6.6-.6zM6.3 8.2v9.2c0 .5.6 1.1 1 1.1h1.4v3.1c0 .8.6 1.4 1.3 1.4.7 0 1.3-.6 1.3-1.4v-3.1h2.1v3.1c0 .8.6 1.4 1.3 1.4.7 0 1.3-.6 1.3-1.4v-3.1h1.4c.5 0 1-.6 1-1.1V8.2H6.3zM4.3 8c.7 0 1.3.6 1.3 1.4v5.4c0 .8-.6 1.4-1.3 1.4-.7 0-1.3-.6-1.3-1.4V9.4C3 8.6 3.6 8 4.3 8zM20.5 8c.7 0 1.3.6 1.3 1.4v5.4c0 .8-.6 1.4-1.3 1.4-.7 0-1.3-.6-1.3-1.4V9.4c0-.8.6-1.4 1.3-1.4z" _fill="#383132"/>'
  }
})
