import _ from 'lodash';
import { DateTime as objDateTime } from 'luxon';

export default {
  addAdministrator(state, admin) {
    state.administrators.set(admin.id, admin.data);
    state.administrators = new Map(state.administrators);
  },
  removeAdministrator(state, id) {
    if (id) {
      state.administrators.delete(id);
      state.administrators = new Map(state.administrators);
    }
  },
  setAdministrator(state, admin) {
    let usr = state.administrators.get(admin.id)
    _.merge(usr, admin.data)
    if (!usr.identity)
      usr.identity = {}
    usr.identity.updated_at = objDateTime.utc().toISO()
    state.administrators = new Map(state.administrators);
  },
  setRoles(state, roles) {
    state.roles = roles;
  },
  addRole(state, role) {
    state.roles.set(role.id, role.data);
    state.roles = new Map(state.roles);
    /*if (role.data.ns_admin_type)
      console.error("addRole: trying to add a NS role! " + role.id)
    else{
      state.roles.set(role.id, role.data);
      state.roles = new Map(state.roles);
    }*/
  },
  addRoleNS(state, role) {
    if (!role.data.ns_admin_type)
      console.error("addRoleNS: trying to add a non-NS role! " + role.id)
    else {
      state.rolesNS.set(role.id, role.data);
      state.rolesNS = new Map(state.rolesNS);
    }
  },
  setDefaultAdministratorLanguageId(state, langId) {
    state.defaultAdministratorLanguageId = langId
  },
}