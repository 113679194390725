/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'item-passwordpolicy': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<defs><path pid="0" d="M15.8 22.4a1.6 1.6 0 11.001-3.201A1.6 1.6 0 0115.8 22.4m0-6.4a4.806 4.806 0 00-4.8 4.8c0 2.646 2.154 4.8 4.8 4.8s4.8-2.154 4.8-4.8-2.154-4.8-4.8-4.8m9.6 11.2a1.6 1.6 0 01-1.6 1.6h-16a1.6 1.6 0 01-1.6-1.6V14.4a1.6 1.6 0 011.6-1.6H23.8a1.6 1.6 0 011.6 1.6v12.8zM12.6 6.578c0-1.863 1.435-3.378 3.2-3.378 1.765 0 3.2 1.515 3.2 3.378V9.6h-6.4V6.578zM23.8 9.6h-1.6V6.578C22.2 2.952 19.33 0 15.8 0c-3.53 0-6.4 2.952-6.4 6.578V9.6H7.8A4.806 4.806 0 003 14.4v12.8C3 29.846 5.154 32 7.8 32h16c2.646 0 4.8-2.154 4.8-4.8V14.4c0-2.646-2.154-4.8-4.8-4.8z" id="svgicon_item-passwordpolicy_a"/></defs><use _fill="#000" fill-rule="nonzero" xlink:href="#svgicon_item-passwordpolicy_a"/>'
  }
})
