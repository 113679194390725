export default {    
    addAgent(state, agent) {
        state.agents_list.set(agent.id, agent.data);
        state.agents_list = new Map(state.agents_list);
    },

    removeAgent(state, id) {
        if (id) {
            state.agents_list.delete(id);
            state.agents_list = new Map(state.agents_list);
        }
    },

    setAgentError(state, error) {        
        state.agent_error = error        
    },

}