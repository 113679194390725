/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'operation': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path pid="0" d="M17 0a1.455 1.455 0 010 2.91H9.727c-.803 0-1.454.65-1.454 1.454v18.909c0 .803.65 1.454 1.454 1.454h14.546c.803 0 1.454-.65 1.454-1.454v-7.128a1.455 1.455 0 012.91 0v7.128a4.364 4.364 0 01-4.364 4.363A4.364 4.364 0 0119.909 32H5.364A4.364 4.364 0 011 27.636V7.273a4.364 4.364 0 014.364-4.364h.232A4.364 4.364 0 019.727 0zM5.364 5.818c-.804 0-1.455.651-1.455 1.455v20.363c0 .804.651 1.455 1.455 1.455h14.545c.803 0 1.455-.651 1.455-1.455H9.727a4.364 4.364 0 01-4.363-4.363zM25.589.005a2.91 2.91 0 012.058.853l2.051 2.051a2.91 2.91 0 010 4.117l-9.454 9.454-5.979-.204-.203-5.963L23.53.858a2.91 2.91 0 012.058-.853zm.356 3.253h-.64l-.083.045a.83.83 0 00-.15.115l-8.057 8.058.072 1.993 1.993.073 8.044-8.058a.728.728 0 00-.015-1.106l-1.018-1.032a.77.77 0 00-.146-.088z" _fill="#0E1317" fill-rule="evenodd"/>'
  }
})
