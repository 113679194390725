/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'item-filteringpolicies': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M15 2H4c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V7l-5-5zm3 16H4V4h10v4h4v10zM6 16h7v-2H6v2zm5-10H6v2h5V6zm-5 6h10v-2H6v2z" _fill="#383132"/><circle pid="1" cx="18.4" cy="18.3" r="4.9" _fill="#383132"/><path pid="2" _fill="#ee373e" d="M16.3 17.5l-.9 1 2.4 2.5 4-4-.9-1-3 3z"/>'
  }
})
