/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'item-notification': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M11.3 21.8c1.1 0 2-.9 2-2h-4c0 1.1.9 2 2 2zm6-6v-5c0-3.1-1.6-5.6-4.5-6.3v-.7c0-.8-.7-1.5-1.5-1.5s-1.5.6-1.5 1.5v.7c-2.9.7-4.5 3.2-4.5 6.3v5l-2 2v1h16v-1l-2-2zm-2 1h-8v-6c0-2.5 1.5-4.5 4-4.5s4 2 4 4.5v6z" _fill="#383132"/>'
  }
})
