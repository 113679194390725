export default {
    setScheduledTasks(state, scheduled_tasks) {
      state.scheduled_tasks = scheduled_tasks;
    },
    setScheduledTask(state, scheduled_task) {
      state.scheduled_task = scheduled_task;
    },
    setScheduledTaskFunctions(state, task_functions) {
      state.scheduled_task_functions = task_functions;
    },
}