import { initialState } from './state';

export function SET_DEFAULT(state) {
  Object.assign(state, initialState());
}

export function SET_ADMIN_REPORT_LIST(state, adminReportList) {
  Object.assign(state, { adminReportList } );
}

export function SET_ADMIN_REPORT_DETAILS(state, adminReportDetails) {
  Object.assign(state.adminReportDetails, adminReportDetails);
}

export function SET_REPORT_CONFIG(state, reportConfig) {
  Object.assign(state, { reportConfig });
}

export function SET_REPORT_VIEW(state, reportView) {
  Object.assign(state, { reportView });
}

export function RESET_REPORT_VIEW(state) {
  const { reportView } = initialState();
  Object.assign(state, { reportView });
}

export function SET_ADMIN_REPORT_EXECUTIONS(state, adminReportExecutions) {
  Object.assign(state.adminReportExecutions, adminReportExecutions);
}

export function RESET_ADMIN_REPORT_EXECUTIONS(state) {
  const { adminReportExecutions } = initialState();
  Object.assign(state, { adminReportExecutions });
}

export function SET_ADMIN_REPORT_EXECUTION_RESULTS(state, adminReportExecutionResults) {
  Object.assign(state.adminReportExecutionResults, adminReportExecutionResults);
}

export function SET_PREVIEWED_REPORT_DETAILS(state, previewedReportDetails) {
  Object.assign(state.previewedReportDetails, previewedReportDetails);
}

export function RESET_PREVIEWED_REPORT_DETAILS(state) {
  const { previewedReportDetails } = initialState();

  Object.assign(state, { previewedReportDetails });
}
