import _ from 'lodash';

export default {
  CLEAR_ACCOUNTS(state) {
    state.accounts.clear()
  },
  SET_ACCOUNT(state, account) {
    state.account = account
  },
  SET_TOGGLE_DISABLED(state, isDisabled){
    state.toggleDisabled = isDisabled
  },
  addAccount(state, account) {
    state.accounts.set(account.id, account.data);
    // state.accounts = new Map(state.accounts);
  },
  setAccount(state, account) {
    let usr = state.accounts.get(account.id)
    _.merge(usr, account.data)
    state.accounts = new Map(state.accounts);
  },
  removeAccount(state, id) {
    if (id) {
      state.accounts.delete(id);
      state.accounts = new Map(state.accounts);
    }
  },
  setAccountActiveTab(state, tab) {
    state.account_tab_active = tab
  },
  SET_QUERY(state, query) {
    state.query = query
  },
  SET_SELF_QUERY(state, selfQuery) {
    state.selfQuery = selfQuery
  },
  SET_LOADING(state, loading) {
    state.loading = loading;
  }
}