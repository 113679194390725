/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'schedule-configuration': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path pid="0" d="M32.188 15.188a1.455 1.455 0 00-2.91 0v7.273c0 .803-.65 1.454-1.454 1.454H8.915a1.455 1.455 0 01-1.454-1.454V12.213h8.256l.194 5.71 5.964.203 9.455-9.469a2.91 2.91 0 000-4.116L29.279 2.49a2.91 2.91 0 00-4.117 0l-7.75 7.75v-.828H7.461V7.916c0-.756.576-1.377 1.314-1.448l.14-.006h7.127a1.455 1.455 0 000-2.91h-4.23v.261h-2.8v-.26h-.097a4.364 4.364 0 00-4.359 4.152l-.005.211A4.364 4.364 0 00.188 12.28v14.545a4.364 4.364 0 004.363 4.364h20.364a4.364 4.364 0 004.364-4.364v-.233a4.364 4.364 0 002.909-4.13zM26.37 26.824c0 .804-.652 1.455-1.455 1.455H4.551a1.455 1.455 0 01-1.454-1.455V12.28c0-.804.651-1.455 1.454-1.455v11.637a4.364 4.364 0 004.364 4.363zM7.46 9.413v2.8h-.048v-2.8h.049zm4.352-7.2a1.4 1.4 0 10-2.8 0v1.339h2.8v-1.34zm17.118 4.03v.64l-.045.083a.83.83 0 01-.115.15l-8.059 8.057-1.992-.072-.073-1.993 8.058-8.044a.728.728 0 011.106.015l1.032 1.018a.77.77 0 01.088.145z" _fill="#0E1317" fill-rule="evenodd"/>'
  }
})
