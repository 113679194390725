import axios from 'axios';

export async function getAccountGroups(account_id) {
  const { data } = await axios.get('/accounts/' + encodeURIComponent(account_id) + '/groups');

  return data;
}

export async function getGroups(account_id, query, dispatch, commit) {
  return new Promise((resolve, reject) => {
    let config = { params: query }
    axios.get('/accounts/' + encodeURIComponent(account_id) + '/groups', config)
      .then(res => {
        if (res && res.data && res.data.items) {
          resolve(res.data);
          commit('SET_ACCOUNT_GROUPS', res.data.items);
        }
      })
      .catch(error => {
        dispatch('setError', error, {root: true});
        reject();
      })
    })
}
