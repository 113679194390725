/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icon-reset': {
    width: 22,
    height: 18,
    viewBox: '0 0 22 18',
    data: '<path pid="0" d="M12.5 0a9 9 0 00-9 9h-3l3.89 3.89.07.14L8.5 9h-3a7 7 0 117 7c-1.93 0-3.68-.79-4.94-2.06l-1.42 1.42A8.895 8.895 0 0012.5 18a9 9 0 000-18z" _fill="#000"/>'
  }
})
