import axios from 'axios'
import i18n from '../../i18n'
import { Settings } from 'luxon'
import { subsystemMock } from './mockData';

function isNullOrUndefined(value) {
    return value === null || value === undefined
};

function checkService(rootGetters, service_id) {
    let res = false;
    if (rootGetters['networksecure/status/networkSecureSnifferMode']) {
      res = (service_id == 'firewall' || service_id == 'auto_notice') ? false : true;
    } else {
      res = true;
    }
    return res;
};

function checkSubsystemService(rootGetters, subsystem_service_id) {
    let res = false;
    if (rootGetters['networksecure/status/networkSecureSnifferMode']) {
      res = (subsystem_service_id == 'anti_malware' || subsystem_service_id == 'anti_phishing') ? false : true;
    } else {
      res = true;
    }
    return res;
};

export default {
    setIsFormDirty({ commit }, dirty) {
        commit('setIsFormDirty', dirty);
    },
    retrieveDarkMode({ commit, state, dispatch }) {
        commit('setDarkMode', localStorage.getItem('darkmode') === 'true')
    },
    updateDarkMode({ commit, state, dispatch }, dark) {
        localStorage.setItem('darkmode', !!dark);
        commit('setDarkMode', localStorage.getItem('darkmode') === 'true')
    },
    setLanguage({ commit, dispatch }, lang) {
        // enable lazy loading of locales
        i18n.loadLanguageAsync(lang).then(() => commit('setLanguage', lang))
        Settings.defaultLocale = lang
    },
    async getSubsystemsPackages({commit}) {
      try {
        const res = await axios.get('/subsystems/common/packages');
        if (res && res.data && res.data.items) {
          commit('setSubsystemsPackages', res.data.items);
        }
      }
       catch (err) {
         commit('setError', err, { root: true });
      }
    },
    getSubsystemServices({ commit, state, dispatch, getters, rootGetters }) {
        if (getters['configLoaded'])
          return new Promise((resolve, reject) => {
            resolve()
          });
        if (!state.debug) {
          return new Promise((resolve, reject) => {
            dispatch('networksecure/status/getNetworkSecureSnifferMode', {}, {root:true})
            .then(() => {
              axios.get('/subsystems/common/services', { headers: { 'x-wait-on-loading': 2 } })
                .then(res => {
                  const filteredData = res.data.filter((service) => checkService(rootGetters, service.service_id));
                  commit('subsystemsSetup', filteredData);
                  if (res && res.data) {
                    res.data.forEach(item => {
                      let service_id = item.service_id
                      item.subsystems.forEach(p => {
                        if (!isNullOrUndefined(p.subsystem_id) && !isNullOrUndefined(p.subsystem_services)) {
                          p.subsystem_services.forEach(s => {
                            if (!isNullOrUndefined(s.subsystem_service_id) && !isNullOrUndefined(s.active)) {
                              if (s.active) {
                                if (p.subsystem_id === 'NetworkSecure') {
                                  // Remove this line, only for test purpose
                                  // commit('addProduct', 'RouterSecure')
                                  // ------
                                  commit('addServiceNS', s.subsystem_service_id);
                                }
                                if (p.subsystem_id === 'DNSecure') {
                                  if (checkSubsystemService(rootGetters, s.subsystem_service_id)) {
                                    commit('addServiceDS', s.subsystem_service_id);
                                  }
                                }
                                if (p.subsystem_id === 'EndpointSecure') {
                                  if (s.subsystem_service_id === 'AntiVirus') {
                                    // For now it is just to know if we show the AV options
                                    //in the ISP configuration when NS is in Sniffer mode. 
                                    commit('EShasAV', true);
                                  }
                                }
                                commit('addProduct', p.subsystem_id)
                                if (checkService(rootGetters, service_id)) {
                                  commit('addService', service_id)
                                }
                              }
                            }
                          })
                        }
                      })
                    })
                  }
                  resolve()
                })
                .catch(error => {
                  commit('setError', i18n.t('cant_obtain_services'), {root: true});
    
                  resolve()
                })
            })
            .catch(error => {
              commit('setError', i18n.t('cant_obtain_services'), {root: true});

              resolve()
            })
          })
        }
        else {
          commit('subsystemsSetup', subsystemMock);
          subsystemMock.forEach(item => {
            let service_id = item.service_id
            let services_ns = []
            let products = []
            item.subsystems.forEach(p => {
              if (!isNullOrUndefined(p.subsystem_id) && !isNullOrUndefined(p.subsystem_services)) {
                p.subsystem_services.forEach(s => {
                  if (!isNullOrUndefined(s.subsystem_service_id) && !isNullOrUndefined(s.active)) {
                    if (s.active) {
                      if (p.subsystem_id === 'NetworkSecure') {
                        commit('addServiceNS', s.subsystem_service_id);
                      }
                      if (p.subsystem_id === 'DNSecure') {
                        commit('addServiceDS', s.subsystem_service_id);
                      }
                      commit('addProduct', p.subsystem_id)
                      commit('addService', service_id)
                    }
                  }
                })
              }
            })
            return new Promise((resolve, reject) => {
              resolve()
            });
          })
        }
      },
}