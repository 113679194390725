/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'mobile-reports': {
    width: 34,
    height: 34,
    viewBox: '0 0 34 34',
    data: '<defs><linearGradient x1="0%" y1="50%" x2="100%" y2="50%" id="svgicon_mobile-reports_a"><stop stop-color="#F78D1E" offset="0%"/><stop stop-color="#ED2D42" offset="100%"/></linearGradient></defs><path pid="0" d="M745.02 410.98A16.89 16.89 0 00733 406h-.091a16.887 16.887 0 00-11.93 4.98A16.89 16.89 0 00716 423c0 4.54 1.768 8.81 4.98 12.02A16.89 16.89 0 00733 440c3.109 0 6.15-.847 8.796-2.45a1.328 1.328 0 00-1.376-2.272 14.317 14.317 0 01-7.42 2.066c-7.91 0-14.344-6.435-14.344-14.344 0-7.439 5.692-13.573 12.95-14.276V423c0 .734.594 1.328 1.328 1.328h14.348a14.322 14.322 0 01-2.37 6.633 1.328 1.328 0 102.208 1.476A16.933 16.933 0 00750 423c0-4.54-1.768-8.81-4.98-12.02zm-10.758-2.269a14.25 14.25 0 012.92.567l-2.92 2.92v-3.487zm0 7.243l5.566-5.566c.804.437 1.56.948 2.263 1.525l-7.83 7.829v-3.788zm1.826 5.718l7.892-7.892c.593.706 1.12 1.47 1.57 2.28l-5.61 5.612h-3.852zm7.608 0l2.985-2.986c.302.957.507 1.956.602 2.986h-3.587z" transform="translate(-716 -406)" _fill="url(#svgicon_mobile-reports_a)" fill-rule="nonzero"/>'
  }
})
