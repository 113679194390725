import _ from 'lodash';
import axios from "axios";
import i18n from "../../../i18n";

export default {
    getNotificationsPlatforms({ commit }) {
      return axios.get('/notifications/platforms')
        .then(platforms => {
          commit({
            type: 'setInitialNotificationsPlatforms',
            platforms: platforms.data
          })
          return
        })
        .catch(error => {
          commit('setErrorAll', error.response.data.detail, { root: true });
          throw error
        });
    },
    getNotificationsAdvancedConfiguration({ commit }) {
      return axios.get('/notifications/advanced-configuration')
        .then(defaultAdvancedConfiguration => {
          commit({
            type: 'setInitialNotificationsAdvancedConfiguration',
            defaultAdvancedConfiguration: defaultAdvancedConfiguration.data
          })
          commit({
            type: 'setDefaultLanguage',
            defaultLanguage: defaultAdvancedConfiguration.data.default_notification_language_id
          })
          return
        })
        .catch(error => {
          commit('setErrorAll', error.response.data.detail, { root: true });
          throw error
        });
    },
    getNotificationsAllConfiguration({ commit }) {
      return axios.get('/notifications/languages-configuration')
        .then(allConfigurations => {
          commit({
            type: 'setInitialNotificationsAllConfiguration',
            configurationsItems: allConfigurations.data.items
          })
          return
        })
        .catch(error => {
          commit('setErrorAll', error.response.data.detail, { root: true });
          throw error
        });
    },
    updateNotificationsPlatforms({ state, commit }) {
      return axios.put('/notifications/platforms', state.notifications.platforms)
        .then(() => {
          commit("setSuccess", i18n.t('Notification updated'), { root: true });
        })
        .catch(error => {
          commit('setErrorAll', error.response.data.detail, { root: true });
          throw error
        });
    },
    updateNotificationsConfiguration({ state, commit }) {
      const cfg = _.cloneDeep(state.notifications.allConfigurations[state.selectedLang])
      const lang = state.selectedLang
      cfg['language_id'] = lang
      delete cfg.internal
      return axios.put(`/notifications/languages-configuration/${lang}`, cfg)
        .then(() => commit('setSuccess', i18n.t('Notifications saved'), { root: true }))
        .catch(error => {
          commit('setErrorAll', error.response.data.detail, { root: true });
          throw error
        });
    },
    updateNotificationsAdvancedConfiguration({ state, commit }) {
      commit({
        type: 'updateInitialNotificationsAdvancedConfiguration',
        defaultAdvancedConfiguration: state.advancedConfiguration
      })
      return axios.put('/notifications/advanced-configuration', state.advancedConfiguration)
        .then()
        .catch(error => {
          commit('setErrorAll', error.response.data.detail, { root: true });
          throw error
        });
    },
    createNewNotificationLanguage({ state, commit }, { newLanguage }) {
      return axios.post(`/notifications/languages-configuration`,
        {
          language_id: newLanguage
        })
        .then(() => {
          commit('setSuccess', i18n.t('New language was created'), { root: true })
          commit({ type: "addNewLangToConfiguration", newLanguage })
        })
        .catch(error => {
          commit('setErrorAll', error.response.data.detail, { root: true });
          throw error
        });
    },
    deleteNotificationLanguage({ state, commit }) {
      const lang = state.selectedLang
      return axios.delete(`/notifications/languages-configuration/${lang}`)
        .then(() => {
          commit({ type: "deleteLangFromConfiguration", lang })
          commit('setSuccess', i18n.t('Language deleted'), { root: true })
        })
        .catch(error => {
          commit('setErrorAll', error.response.data.detail, { root: true });
          throw error
        });
    },
    checkIfSmsGatewayEnabled({ state, commit }) {
      return axios.get(`/smsgateway/kannels`)
        .then((items) => {
          const isEnabled = items.data.items.length
          commit({ type: "setGatewayEnabled", isEnabled, gatewayName: 'sms' })
        })
        .catch(error => {
          commit('setErrorAll', error.response.data.detail, { root: true });
          throw error
        });
    },
    checkIfEmailGatewayEnabled({ state, commit }) {
      return axios.get(`/emailgateway/mtas`)
        .then((items) => {
          const isEnabled = items.data.items.length
          commit({ type: "setGatewayEnabled", isEnabled, gatewayName: 'email' })
        })
        .catch(error => {
          commit('setErrorAll', error.response.data.detail, { root: true });
          throw error
        });
    },
    resetLanguage({ state, commit }, { all = false, serviceName, subServiceName }) {
      const lang = state.selectedLang
      let payload = {}, successMsg = 'Reset language was successful';
      if (all) {
        payload = state.notifications.allConfigurations[lang]
        delete payload.internal
      }
      else {
        successMsg = 'Reset specific language notification was successful'
        const conf = state.notifications.allConfigurations[state.selectedLang]
        if (conf[serviceName] && conf[serviceName][subServiceName]) {
          const subService = state.notifications.allConfigurations[state.selectedLang][serviceName][subServiceName]
          payload = { [serviceName]: { [subServiceName]: subService } }
        }
      }
      payload.language_id = lang
      return axios.patch(`/notifications/languages-configuration/${lang}?reset=true`, payload)
        .then(() => {
          commit('setSuccess', i18n.t(successMsg), { root: true })
        })
        .catch(error => {
          commit('setErrorAll', error.response.data.detail, { root: true });
          throw error
        });
    }
  }