/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'item-admin': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path pid="0" d="M16.444 17.778v3.555c-4.9 0-8.888 3.988-8.888 8.89a1.777 1.777 0 11-3.556 0c0-6.863 5.584-12.445 12.444-12.445zM24.802 17a2.036 2.036 0 011.98 1.773 2.033 2.033 0 012.59.059l.125.118.481.482a2.04 2.04 0 01.177 2.684c.989.092 1.759.884 1.838 1.861l.007.156v.68a2.037 2.037 0 01-1.77 2.037 2.04 2.04 0 01-.06 2.593l-.117.125-.482.482a2.033 2.033 0 01-2.714.177 2.037 2.037 0 01-1.874 1.768l-.16.005h-.68a2.036 2.036 0 01-2.034-1.773 2.033 2.033 0 01-2.548-.056l-.132-.12-.482-.483a2.04 2.04 0 01-.176-2.718 2.037 2.037 0 01-1.766-1.876L17 24.812v-.679c0-1.015.747-1.875 1.75-2.017a2.04 2.04 0 01.056-2.552l.12-.132.483-.482a2.033 2.033 0 012.68-.177 2.037 2.037 0 011.873-1.768l.161-.005h.679zm-.302 5a2.5 2.5 0 100 5 2.5 2.5 0 000-5zM16.444 0a7.118 7.118 0 017.112 7.111 7.118 7.118 0 01-7.112 7.111 7.118 7.118 0 01-7.11-7.11A7.118 7.118 0 0116.443 0zm0 3.556A3.56 3.56 0 0012.89 7.11a3.56 3.56 0 003.555 3.556A3.56 3.56 0 0020 7.11a3.56 3.56 0 00-3.556-3.555z" _fill="#000" fill-rule="nonzero"/>'
  }
})
