import DashboardLayout from "@/layout/dashboard/DashboardLayout.vue";

const AccountManagerEdit = () => import(
  /* webpackChunkName: "account-management" */
  "@/pages/Managers/AccountManagerEdit.vue"
);

const FrameLoader = () => import(
  /* webpackChunkName: "account-management" */
  "@/pages/Accounts/FrameLoader.vue"
);

export default {
  path: "/account_management/accounts/edit/:account_id/managers",
  name: "Account Management / Accounts / Managers",
  component: DashboardLayout,
  children: [
    {
      path: "new",
      name: "Account Management / Accounts / Manager / New",
      component: AccountManagerEdit,
      props: true
    },
    {
      path: 'edit/:id',
      name: 'Account Management / Accounts / Manager / Edit',
      component: AccountManagerEdit,
      props: true
    },
  ]
};
