import {
  getSrcOrDest, getSchedule, getAllowedForbidden,
  setSrcOrDest, setSchedule, setAllowedForbidden, setActions
} from "./strategyUtil";

/**
 * Generate array from Back-end to use it in Front-end
 * Function to generate the content filter rules array from the account strategy
 * @param {Object} strategy attribute strategy of an account
 */
export const prepareContentFilterData = strategy => {
  let rules = [];
  for (const ruleName in strategy.rules) {
    if (strategy.rules.hasOwnProperty(ruleName)) {
      const rule = strategy.rules[ruleName];
      let log_activity = false;
      let actionType;

      for (const action of rule.actions) {
        if (action.op === "log") log_activity = true;
        else if (action.op === "block_with_url") actionType = { type: 'block_with_url', value: action.url }
        else actionType = { type: action.op };
      }

      const http_methods = rule.profile === "all"
        ? []
        : strategy.profiles[rule.profile].http_method
          ? strategy.profiles[rule.profile].http_method.items
          : []
      const categories = rule.profile === "all"
        ? []
        : strategy.profiles[rule.profile].category
          ? strategy.profiles[rule.profile].category.items
          : []
      const urls =  rule.profile === "all"
        ? []
        : getUrls(strategy.profiles[rule.profile])
      const time_limit = rule.profile === "all"
        ? {}
        : getTimeLimit(strategy.profiles[rule.profile])
      const advanced =  rule.profile === "all"
        ? []
        : getAdvanced(strategy.profiles[rule.profile])

      rules.push({
        rule_name: ruleName,
        id: ruleName,
        system: !!rule.system,
        status: rule.active === "yes" ? true : false,
        log_activity,
        action: actionType,
        order: rule.order,
        source: getSrcOrDest(rule, strategy.source_and_destinations, "origin"),
        schedule: getSchedule(rule, strategy.schedules),
        categories,
        urls,
        http_methods,
        time_limit,
        advanced,
        allowed_forbidden: getAllowedForbidden(rule, strategy.profiles)
      });
    }
  }
  return rules;
};

/**
 * Function to generate the urls from the rule
 * @param {Object} profile object with the profile of the rule
 */
const getUrls = (profile) => {
  let urls = []
  const profileUrlProps = ['included_url', 'excluded_url', 'included_url_regex', 'excluded_url_regex']
  let type

  profileUrlProps.forEach(prop => {
    if (prop === 'included_url') type = 'included'
    else if (prop === 'excluded_url') type = 'excluded'
    else if (prop === 'included_url_regex') type = 'regex'
    else if (prop === 'excluded_url_regex') type = 'excluded_regex'

    if (profile[prop]) profile[prop].items.forEach(url => urls.push({ type, value: url }))
  })
  return urls
}

/**
 * Function to generate the time limit from the rule
 * @param {Object} profile object with the profile of the rule
 */
const getTimeLimit = (profile) => {
  let timeLimit

  if (profile.week_duration) timeLimit = { type: 'hoursPerWeek', value: profile.week_duration.maximum }
  else if (profile.day_duration) timeLimit = { type: 'minutesPerDay', value: profile.day_duration.maximum }
  else timeLimit = { type: null, value: 0 }
  return timeLimit
}

const translateHandler = (txt, search, replace) => {
  return txt.split(search).join(replace);
};

/**
 * Function to generate the advanced from the rule
 * @param {Object} profile object with the profile of the rule
 */
const getAdvanced = (profile) => {
  const advancedOptions = ['pinning', 'client_certificate_request', 'extended_validation_certificate']
  let advanced = []
  let type, value

  advancedOptions.forEach(option => {
    if (profile[option]) {
      type = profile[option].inverse ? 'excluded' : 'included'
      value = type === 'included' ? option : `not: ${option}`
      value = translateHandler(value, "_", " ")
      advanced.push({ type, value })
    }
  })
  return advanced
}

/**
 * Generate object from Front-end to send it to Back-end
 * Function to generate the strategy of an account from an array of content filter rules
 * @param rules array of rules
 */
export const prepareStrategyData = rules => {
  let strategy = {
    rules: {}
  };
  for (let i = 0; i < rules.length; i++) {
    // Get source items
    const source = setSrcOrDest(rules[i], 'source');
    if (source.hasOwnProperty('value')) {
      if (!strategy.source_and_destinations) strategy.source_and_destinations = {};
      strategy.source_and_destinations[source.origin] = source.value;
    }

    // Get profile items
    const profiles = setProfile(rules[i]);
    if (profiles.hasOwnProperty('profiles')) {
      if (!strategy.profiles) strategy.profiles = {};
      strategy.profiles[profiles.profileName] = profiles.profiles;
    }

    // Get schedule items
    const schedules = setSchedule(rules[i]);
    if (schedules.hasOwnProperty('schedule')) {
      if (!strategy.schedules) strategy.schedules = {};
      strategy.schedules[schedules.scheduleName] = schedules.schedule;
    }

    strategy.rules[`${rules[i].rule_name}`] = {
      active: rules[i].status ? "yes" : "no",
      order: rules[i].order,
      ns_services: ["content_filter"],
      origin: source.origin,
      profile: profiles.profileName,
      when: schedules.scheduleName,
      actions: setActions(rules[i])
    };
  }
  return strategy;
};

export const prepareStrategyStatusData = rule => {
  let strategy = {
    rules: {}
  };
  strategy.rules[`${rule.rule_name}`] = {
    active: rule.status ? "yes" : "no",
    ns_services: ["content_filter"]
  };
  return strategy;
};

/**
 * Function to generate the profiles of the rule if necessary
 * @param {Object} rule object with the description of the rule
 * @returns an object with the profile key (and the profile object if necessary)
 */
const setProfile = rule => {
  let profiles = {
    ns_services: ["content_filter"],
  };

  if (rule.urls.length) {
    rule.urls.forEach(url => {
      switch (url.type) {
        case 'included':
          if (!profiles.included_url) profiles.included_url = { necessary: true, items: [url.value] }
          else profiles.included_url.items.push(url.value)
          break;
        case 'excluded':
          if (!profiles.excluded_url) profiles.excluded_url = { necessary: true, items: [url.value] }
          else profiles.excluded_url.items.push(url.value)
          break;
        case 'regex':
          if (!profiles.included_url_regex) profiles.included_url_regex = { necessary: true, items: [url.value] }
          else profiles.included_url_regex.items.push(url.value)
          break;
        case 'excluded_regex':
          if (!profiles.excluded_url_regex) profiles.excluded_url_regex = { necessary: true, items: [url.value] }
          else profiles.excluded_url_regex.items.push(url.value)
          break;
      }
    })
  }

  if (rule.categories.length) profiles.category = { necessary: true, items: rule.categories }

  if (rule.http_methods.length) profiles.http_method = { necessary: true, items: rule.http_methods }

  if (rule.advanced.length) {
    rule.advanced.forEach(option => {
      const value = option.value.toLowerCase()
      // ! refactor as src and dest
      if (value.includes('pinning')) {
        if (option.type === 'included') profiles.pinning = { necessary: true, inverse: false }
        else profiles.pinning = { necessary: true, inverse: true }
      }
      else if (value.includes('client certificate')) {
        if (option.type === 'included') profiles.client_certificate_request = { necessary: true, inverse: false }
        else profiles.client_certificate_request = { necessary: true, inverse: true }
      }
      else if (value.includes('extended validation certificate')) {
        if (option.type === 'included') profiles.extended_validation_certificate = { necessary: true, inverse: false }
        else profiles.extended_validation_certificate = { necessary: true, inverse: true }
      }
      // * blocked equivalent to "persistent requests" in the gui
      else if (value.includes('persistent requests')) {
        if (option.type === 'included') profiles.blocked = { necessary: true, inverse: false }
        else profiles.blocked = { necessary: true, inverse: true }
      }
    })
  }

  if (rule.time_limit.type && rule.time_limit.value > 0) {
    let timeType
    if (rule.time_limit.type === 'minutesPerDay') timeType = 'day_duration' // * max minutes
    else timeType = 'week_duration' // * max hours

    profiles[timeType] = { necessary: true, maximum: +rule.time_limit.value }
  }

  for (const key in profiles) {
    if (!profiles[key]) delete profiles[key]
  }

  const allowedForbidden = setAllowedForbidden(rule);
  profiles = {
    ...profiles,
    ...allowedForbidden
  }

  if (Object.keys(profiles).length === 1) return { profileName: "all" };
  else return { profileName: `${rule.rule_name}_profile`, profiles };
};
