import { 
  getSrcOrDest, getSchedule, getAllowedForbidden,
  setSrcOrDest, setSchedule, setAllowedForbidden, setActions
} from "./strategyUtil";
import i18n from "@/i18n";

/**
 * Generate array from Back-end to use it in Front-end
 * Function to generate the anti-botnet rules array from the account strategy
 * @param {Object} strategy attribute strategy of an account
 */
export const prepareAntiBotnetData = strategy => {
  let rules = [];
  for (const ruleName in strategy.rules) {
    if (strategy.rules.hasOwnProperty(ruleName)) {
      const rule = strategy.rules[ruleName];
      let log_activity = false;
      let actionType;
      const threats = getThreats(rule, strategy.profiles);
      const allowed_forbidden = getAllowedForbidden(rule, strategy.profiles);
      for (const action of rule.actions) {
        if (action.op === "log") log_activity = true;
        else actionType = { type: action.op };
      }
      rules.push({
        rule_name: ruleName,
        id: ruleName,
        system: !!rule.system,
        status: rule.active === "yes" ? true : false,
        log_activity: log_activity,
        action: actionType,
        order: rule.order,
        source: getSrcOrDest(rule, strategy.source_and_destinations, "origin"),
        threat: threats,
        allowed_forbidden: allowed_forbidden,
        schedule: getSchedule(rule, strategy.schedules)
      });
    }
  }
  return rules;
};

/**
 * Function to generate the threats from the rule
 * @param {Object} rule object with the description of the rule
 * @param {Object} profiles object with the profiles of the strategy account
 */
const getThreats = (rule, profiles) => {
  if (rule.profile === "all") return [];
  const availableThreats = {
    botnet_extlist: 'verified_botnet',
    botnet_dga: 'dga'
  };
  let threats = []
  for (const threat of Object.keys(availableThreats)) {
    profiles[rule.profile].hasOwnProperty(threat) && threats.push({
      option: availableThreats[threat],
      value: i18n.t(`antibotnet-edit.${availableThreats[threat]}`)
    })
  }
  return threats;
};

/**
 * Generate object from Front-end to send it to Back-end
 * Function to generate the strategy of an account from an array of anti-botnet rules
 * @param rules array of rules
 */
export const prepareStrategyData = rules => {
  let strategy = {
    rules: {}
  };
  for (let i = 0; i < rules.length; i++) {
    // Get source items
    const source = setSrcOrDest(rules[i], 'source');
    if (source.hasOwnProperty('value')) {
      if (!strategy.source_and_destinations) strategy.source_and_destinations = {};
      strategy.source_and_destinations[source.origin] = source.value;
    }

    // Get profile items
    const profiles = setProfile(rules[i]);
    if (profiles.hasOwnProperty('profiles')) {
      if (!strategy.profiles) strategy.profiles = {};
      strategy.profiles[profiles.profileName] = profiles.profiles;
    }

    // Get schedule items
    const schedules = setSchedule(rules[i]);
    if (schedules.hasOwnProperty('schedule')) {
      if (!strategy.schedules) strategy.schedules = {};
      strategy.schedules[schedules.scheduleName] = schedules.schedule;
    }

    strategy.rules[`${rules[i].rule_name}`] = {
      active: rules[i].status ? "yes" : "no",
      order: rules[i].order,
      ns_services: ["anti_botnet"],
      origin: source.origin,
      profile: profiles.profileName,
      when: schedules.scheduleName,
      actions: setActions(rules[i])
    };
  }
  return strategy;
};

export const prepareStrategyStatusData = rule => {
  let strategy = {
    rules: {}
  };
  strategy.rules[`${rule.rule_name}`] = {
    active: rule.status ? "yes" : "no",
    ns_services: ["anti_botnet"]
  };
  return strategy;
};

/**
 * Function to generate the profiles of the rule if necessary
 * @param {Object} rule object with the description of the rule
 * @returns an object with the profile key (and the profile object if necessary)
 */
const setProfile = rule => {
  let profiles = {
    ns_services: ["anti_botnet"]
  };

  const availableThreats = {
    verified_botnet: 'botnet_extlist',
    dga: 'botnet_dga'
  };

  rule.threat.forEach(threat => {
    profiles[availableThreats[threat.option || threat]] = { necessary: false, inverse: false }
  });

  const allowedForbidden = setAllowedForbidden(rule);
  profiles = {
    ...profiles,
    ...allowedForbidden
  }
  
  if (Object.keys(profiles).length === 1) return { profileName: "all" };
  else return { profileName: `${rule.rule_name}_profile`, profiles };
};
