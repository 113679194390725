import DashboardLayout from "@/layout/dashboard/DashboardLayout.vue";
import { store } from "@/store";

const AdministratorProfile = () => import(
  "@/pages/Administrators/AdministratorProfile.vue"
);


export default {
    path: '/profile',
    component: DashboardLayout,
    name: 'Profile',
    children: [
      {
        path: 'edit/:id',
        name: 'Profile / Administrators / Edit',
        component: AdministratorProfile,
        props: true,
        beforeEnter: (to, from, next) => {
          const currentManager = encodeURIComponent(store.getters['auth/manager']);
          const goToManager = to.params.id;
          const isLDAP = store.getters['auth/isLdap']();
          isLDAP
            ? goToManager === currentManager
              ? next()
              : next(false)
            : next();
        }
      }
    ]
};

