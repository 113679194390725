import { external_servers, sms_servers, email_servers } from '../../stubs/externalservers';
import axios from "axios";
import i18n from '../../../i18n';
import {mockEmailServer, mockServer, mockSmsServer} from './mockData';
import _ from "lodash";

export default {
    getExternalServers({ commit, state, rootGetters }) {
        if (!rootGetters['settings/getDebug']) {
            return new Promise((resolve, reject) => {
                axios.get('/external-servers')
                    .then(res => {
                        if (res && res.data && res.data.items) {
                            state.external_servers.clear()
                            res.data.items.forEach(item => {
                                let cluster = {
                                    id: item.id,
                                    data: {
                                        name: item.id,
                                        type: item.type,
                                        servers: []
                                    }
                                }
                                commit('addCluster', cluster)

                                item.servers.forEach(server => {
                                    let srv = {
                                        id: server.name,
                                        data: server
                                    }
                                    commit('addServer', { cluster_name: item.id, data: srv })
                                })
                            })
                            let ret = { items: state.external_servers }
                            resolve(ret)
                        }
                        else {
                            reject()
                        }
                    })
                    .catch(error => {
                        commit('setError', error, { root: true });
                        reject(error)
                    })
            })
        }
        else {
            return new Promise((resolve, reject) => {
                if (state.external_servers.size <= 0)
                    external_servers.items.forEach(item => {
                        let cluster = {
                            id: item.name,
                            data: {
                                name: item.name,
                                type: item.type,
                                servers: []
                            }
                        }
                        commit('addCluster', cluster)

                        item.servers.forEach(server => {
                            let srv = {
                                id: server.name,
                                data: server
                            }
                            commit('addServer', { cluster_name: item.name, data: srv })
                        })
                    })
                let ret = { items: state.external_servers }
                resolve(ret)
            });
        }
    },
    async getSmsServers({ commit, state, rootGetters }) {
      if (!rootGetters['settings/getDebug']) {
        try {
          const response = await axios.get('/smsgateway/kannels');
          if (response && response.data && response.data.items) {
            response.data.items.forEach(item => {
              let server = {
                kannel_id: item.kannel_id,
                type: 'sms',
                enabled: item.enabled,
                host: item.host,
                port: item.port,
                mode: item.mode,
              }
              commit('addSmsServer', server)
            })
          } else {
            console.log('problem with data');
          }
        } catch (error) {
          console.log('error: ',error);
        }
      } else {
        if (!state.sms_servers.size) {
          sms_servers.items.forEach(item => {
            let server = {
              kannel_id: item.kannel_id,
              type: 'sms',
              enabled: item.enabled,
              host: item.host,
              port: item.port,
              mode: item.mode,
            }
            commit('addSmsServer', server)
          })
        }
      }
    },
    async getEmailServers({ commit, state, rootGetters }) {
      if (!rootGetters['settings/getDebug']) {
        try {
          const response = await axios.get('emailgateway/mtas');
          if (response && response.data && response.data.items) {
            response.data.items.forEach(item => {
              let server = {
                mta_id: item.mta_id,
                type: 'email',
                enabled: item.enabled,
                host: item.host,
                port: item.port,
              }
              commit('addEmailServer', server)
            })
          } else {
            console.log("problem with data");
          }
        } catch (error) {
          console.log("error: ",error);
        }
      } else {
        if (!state.email_servers.size) {
          email_servers.items.forEach(item => {
            let server = {
              mta_id: item.mta_id,
              type: "email",
              enabled: item.enabled,
              host: item.host,
              port: item.port,
            }
            commit('addEmailServer', server)
          })
        }
      }
    },
    createCluster({ commit, state, rootGetters }, cluster) {

        if (!rootGetters['settings/getDebug']) {
            return new Promise((resolve, reject) => {
                axios.post('/external-servers', cluster)
                    .then(res => {
                        resolve(res);
                    })
                    .catch(error => {
                        commit('setError', error, { root: true });
                        reject(error)
                    })
            });
        } else {
            return new Promise((resolve, reject) => {
                let newCluster = {
                    id: cluster.id,
                    data: cluster
                }
                commit('addCluster', newCluster)
                resolve({ status: 204 })
            })
        }
    },
    deleteCluster({ commit, state, rootGetters }, id) {
        if (rootGetters['settings/getDebug']) {
            return new Promise((resolve, reject) => {
                commit('removeCluster', id)
                resolve(state.external_servers)
            });
        }
        else {
            return new Promise((resolve, reject) => {
                axios.delete('/external-servers/' + encodeURIComponent(id))
                    .then(res => {
                        if (res) {
                            commit('removeCluster', id);
                            resolve(state.external_servers)
                        }
                    })
                    .catch(error => {
                        if (error.response && error.response.data) {
                            reject(error.response.data);
                        }
                        reject(error)
                    })
            });
        }
    },
    deleteServer({ commit, state, rootGetters }, { cluster_name, server_name }) {
        if (rootGetters['settings/getDebug']) {
            return new Promise((resolve, reject) => {
                commit('removeServer', { cluster_name: cluster_name, server_name: server_name })
                resolve(state.external_servers)
            });
        }
        else {
            return new Promise((resolve, reject) => {
                axios.delete('/external-servers/' + encodeURIComponent(cluster_name) + '/servers/' + encodeURIComponent(server_name))
                    .then(res => {
                        commit('removeServer', { cluster_name: cluster_name, server_name: server_name });
                        resolve(state.external_servers)
                    })
                    .catch(error => {
                        if (error.response && error.response.data) {
                            reject(error.response.data);
                        }
                        reject(error)
                    })
            });
        }
    },
    async deleteSmsServer({ commit, state, rootGetters }, serverId) {
      if (rootGetters['settings/getDebug']) {
        commit('removeSmsServer', serverId)
        commit("setSuccess", i18n.t("save_success"), { root: true });
      } else {
        try {
          await axios.delete(`/smsgateway/kannels/${serverId}`);
          commit('removeSmsServer', serverId)
          commit("setSuccess", i18n.t("save_success"), { root: true });
        } catch(error) {
          if (error && error.response && error.response.data) {
            commit('setErrorAll', error.response.data.detail, { root: true });
          }
        }
      }
    },
    async deleteEmailServer({ commit, state, rootGetters }, serverId) {
      if (rootGetters['settings/getDebug']) {
        commit('removeEmailServer', serverId)
        commit("setSuccess", i18n.t("save_success"), { root: true });
      } else {
        try {
          await axios.delete(`/emailgateway/mtas/${serverId}`);
          commit('removeEmailServer', serverId)
          commit("setSuccess", i18n.t("save_success"), { root: true });
        } catch(error) {
          if (error && error.response && error.response.data) {
            commit('setErrorAll', error.response.data.detail, { root: true });
          }
        }
      }
    },
    createServer({ commit, state, rootGetters }, { cluster_name, data }) {
        if (rootGetters['settings/getDebug']) {
            return new Promise((resolve, reject) => {
                let srv = {
                    id: data.name,
                    data: data
                }
                commit('addServer', { cluster_name: cluster_name, data: srv })
                resolve(data)
            })
        } else {
            return new Promise((resolve, reject) => {
                let serverData = { ...data, status: data.status ? 'on' : 'off' }
                axios.post('/external-servers/' + encodeURIComponent(cluster_name) + '/servers', serverData)
                    .then(res => {
                        if (res && res.data) {
                            let srv = {
                                id: res.data.name,
                                data: res.data
                            }
                            commit('addServer', { cluster_name: cluster_name, data: srv })
                            resolve(res.data)
                        } else {
                            resolve(true)
                        }
                    })
                    .catch(error => {
                        if (error.response && error.response.data) {
                            reject(error.response.data);
                        }
                    })
            })
        }
    },
    async createSmsServer({ commit, state, rootGetters }, serverData) {
      if (rootGetters['settings/getDebug']) {
        commit('addSmsServer', serverData)
        commit("setSuccess", i18n.t("save_success"), { root: true });
      } else {
        try {
          const response = await axios.post('/smsgateway/kannels', serverData);
          if (response && response.data) {
            commit('addSmsServer', response.data)
            commit("setSuccess", i18n.t("save_success"), { root: true });
          }
        } catch(error) {
          if (error && error.response && error.response.data) {
            commit('setErrorAll', error.response.data.detail, { root: true });
          }
        }
      }
    },
    async createEmailServer({ commit, state, rootGetters }, serverData) {
      if (rootGetters['settings/getDebug']) {
        commit('addEmailServer', serverData)
        commit("setSuccess", i18n.t("save_success"), { root: true });
      } else {
        try {
          const response = await axios.post('/emailgateway/mtas', serverData);
          if (response && response.data) {
            commit('addEmailServer', response.data)
            commit("setSuccess", i18n.t("save_success"), { root: true });
          }
        } catch(error) {
          if (error && error.response && error.response.data) {
            commit('setErrorAll', error.response.data.detail, { root: true });
          }
        }
      }
    },
    getServer({ commit, state, rootGetters }, { clusterId, serverName }) {
        if (rootGetters['settings/getDebug']) {
            return new Promise((resolve, reject) => {
                const cluster = state.external_servers.get(clusterId);
                let server = {};

                if (cluster) server = cluster.servers.filter(s => { return s.name === serverName })[0];
                else {
                    server = mockServer
                }
                commit('setServer', server);
                resolve(server);
            });
        } else {
            return new Promise((resolve, reject) => {
                axios.get(`/external-servers/${encodeURIComponent(clusterId)}/servers/${serverName}`)
                    .then(res => {
                        commit('setServer', res.data);
                        resolve();
                    })
                    .catch(error => {
                        commit('setError', error, { root: true });
                        reject(error);
                    });
            });
        }
    },
    async getSmsServer({ commit, state, rootGetters }, serverId) {
      let server = {};
      if (rootGetters['settings/getDebug']) {
        return new Promise((resolve, reject) => {
          state.sms_servers && state.sms_servers[serverId] ?
            server = state.sms_servers[serverId] :
            server = mockSmsServer;
          commit('setSmsServer', server);
          resolve(server);
        });
      } else {
        return new Promise(async (resolve, reject) => {
          try {
            const response = await axios.get(`/smsgateway/kannels/${serverId}`);
            commit('setSmsServer', response.data);
            resolve();
          } catch (error) {
            commit('setError', error, {root: true});
            reject(error);
          }
        });
      }
    },
    async getEmailServer({ commit, state, rootGetters }, serverId) {
      let server = {};
      if (rootGetters['settings/getDebug']) {
        return new Promise((resolve, reject) => {
          state.email_servers && state.email_servers[serverId] ?
            server = state.email_servers[serverId] :
            server = mockEmailServer;
          commit('setEmailServer', server);
          resolve(server);
        });
      } else {
        return new Promise(async (resolve, reject) => {
          try {
            const response = await axios.get(`/emailgateway/mtas/${serverId}`);
            commit('setEmailServer', response.data);
            resolve();
          } catch (error) {
            commit('setError', error, {root: true});
            reject(error);
          }
        });
      }
    },
    updateServer({ commit, rootGetters }, { clusterId, serverName, serverData }) {
        if (rootGetters['settings/getDebug']) {
            return new Promise((resolve, reject) => {
                commit('addServer', { cluster_name: clusterId, data: { data: { ...serverData }, id: serverName } });
                resolve(serverData);
            });
        } else {
            return new Promise((resolve, reject) => {
                axios.patch(`/external-servers/${encodeURIComponent(clusterId)}/servers/${serverName}`, serverData)
                    .then(res => {
                        commit('addServer', { cluster_name: clusterId, data: { data: { ...serverData }, id: serverName } });
                        resolve(serverData);
                    })
                    .catch(error => {
                        if (error.response && error.response.data) {
                            reject(error.response.data);
                        }
                    });
            });
        }
    },
    async updateSmsServer({ commit, rootGetters }, {serverId, serverData}) {
      if (rootGetters['settings/getDebug']) {
        commit('addSmsServer', {...serverData, kannel_id: serverId})
        commit("setSuccess", i18n.t("save_success"), { root: true });
      } else {
        try {
          await axios.patch(`/smsgateway/kannels/${serverId}`, serverData);
          commit('addSmsServer', {...serverData, kannel_id: serverId});
          commit("setSuccess", i18n.t("save_success"), { root: true });
        } catch(error) {
          if (error && error.response && error.response.data) {
            commit('setErrorAll', error.response.data.detail, { root: true });
          }
        }
      }
    },
    async updateEmailServer({ commit, rootGetters }, {serverId, serverData}) {
      if (rootGetters['settings/getDebug']) {
        commit('addEmailServer', {...serverData, mta_id: serverId})
        commit("setSuccess", i18n.t("save_success"), { root: true });
      } else {
        try {
          await axios.patch(`/emailgateway/mtas/${serverId}`, serverData);
          commit('addEmailServer', {...serverData, mta_id: serverId});
          commit("setSuccess", i18n.t("save_success"), { root: true });
        } catch(error) {
          if (error && error.response && error.response.data) {
            commit('setErrorAll', error.response.data.detail, { root: true });
          }
        }
      }
    },
    resetLocalServer({ commit }, serverType) {
      commit(`reset${_.capitalize(serverType)}Server`);
    },
    getQueryGroups({ commit, state, rootGetters }, { cluster, filter, force }) {
        if (!rootGetters['settings/getDebug']) {
            return new Promise((resolve, reject) => {
                if (state.isMoreGroups || force) {
                    if (cluster) {
                        let config = { params: { limit: 100, ...filter } }
                        axios.get(`/external-servers/${cluster}/query-groups`, config)
                            .then(res => {
                                if (!!res.data && !!res.data.items) {
                                    commit('setMoreGroups', !!config.params.field_value || config.params.limit <= res.data.items.length)
                                    commit('addQueryGroups', { values: res.data.items.map(item => item.name), cluster: cluster })
                                }
                                else {
                                    commit('setMoreGroups', true)
                                    commit('addQueryGroups', { values: [], cluster: cluster })
                                }
                                resolve(state.queryGroups[cluster])
                            })
                            .catch(error => {
                                if (error.response) {
                                    switch (error.response.status) {
                                        case 404:
                                            commit('setError', `${i18n.t('LDAP_cluster')} ${cluster} ${i18n.t('not_found')}`, { root: true });
                                            break;
                                        case 409:
                                            commit('setError', `${i18n.t('cannot_obtain_groups_from_LDAP_cluster')} ${cluster}`, { root: true });
                                            break;
                                    }
                                }
                                commit('setMoreGroups', true)
                                commit('addQueryGroups', { values: [], cluster: cluster })
                                reject(error)
                            })
                    }
                } else {
                    if (!!state.queryGroups[cluster]) {
                        if (filter.field_value) {
                            resolve(state.queryGroups[cluster].filter(item => {
                                return item.startsWith(filter.field_value)
                            }))
                        } else {
                            resolve(state.queryGroups[cluster])
                        }
                    }
                    resolve([])
                }
            })
        }
    },
    getCluster({ state, commit, rootGetters }, clusterName) {
        if (rootGetters['settings/getDebug']) {
            return state.external_servers.get(clusterName);
        } else {
            return new Promise((resolve, reject) => {
                axios.get(`/external-servers/${clusterName}`).then(
                    res => resolve(res.data)
                ).catch(
                    err => commit('setError', `${i18n.t('error_cluster_data')} (${clusterName}): ${err}`, {root: true})
                );
            });
        }
    }
};
