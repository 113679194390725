/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'device-console': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path pid="0" d="M22.667 7a9.333 9.333 0 110 18.667H9.333A9.333 9.333 0 119.333 7zm0 2.667H9.333a6.667 6.667 0 100 13.333h13.334a6.666 6.666 0 006.666-6.667 6.666 6.666 0 00-6.666-6.666zm.787 8.552a1.333 1.333 0 11-1.883 1.886 1.333 1.333 0 011.883-1.886zm-12.787-5.886V15h2.666v2.667h-2.666v2.666H8v-2.666H5.333V15H8v-2.667h2.667zm8.073 3.058a1.332 1.332 0 011.885 0 1.332 1.332 0 010 1.885 1.332 1.332 0 01-1.885 0 1.332 1.332 0 010-1.885zm5.657 0a1.332 1.332 0 011.886 0 1.332 1.332 0 010 1.885 1.332 1.332 0 01-1.886 0 1.332 1.332 0 010-1.885zm-.943-2.828a1.333 1.333 0 11-1.883 1.886 1.333 1.333 0 011.883-1.886z" _fill="#000" fill-rule="evenodd"/>'
  }
})
