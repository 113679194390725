import * as queries from './queries';
import i18n from '../../i18n'

function getDatabaseProvider(providerList) {
  const databaseProvider = providerList
    .find((provider) => {
      const { identity_provider_type } = provider;

      return identity_provider_type === 'IdentityProviderDatabase';
    });

  return databaseProvider;
}

export async function getProviderList({ dispatch, commit, state, rootGetters }, type) {
  if (!rootGetters['settings/getDebug']) {
    try {
      const providerList = await queries.getProviderList(type);

      if (!providerList) {
        throw new Error('Failed to obtain the list of providers')
      }

      commit(`SET_${type.toUpperCase()}_PROVIDER_LIST`, providerList.items);

    } catch (err) {
      dispatch('setError', err, { root: true });
    }
  } else {
    if (state[type].providerList.length === 0) {
      const providers = [
        { identity_provider_id: 'IdentityProviderDatabase', identity_provider_type: 'IdentityProviderDatabase', enabled: false, system: true },
        { identity_provider_id: `Verizion${type === 'isp' ? 'LDAP' : 'SSO'}1`, identity_provider_type: `IdentityProvider${type === 'isp' ? 'LDAP' : 'SSO'}`, enabled: true, system: type === 'isp' ? false : true },
        { identity_provider_id: `Verizion${type === 'isp' ? 'LDAP' : 'SSO'}2`, identity_provider_type: `IdentityProvider${type === 'isp' ? 'LDAP' : 'SSO'}`, enabled: false, system: false }
      ]
      commit(`SET_${type.toUpperCase()}_PROVIDER_LIST`, providers);
    }
  }
}

export async function getProviderDetails({ dispatch, commit, state, rootGetters }, { type, id }) {
  if (!rootGetters['settings/getDebug']) {
    try {
      return new Promise((resolve, reject) => {
        const providerDetails = queries.getProviderById(id, type)
        .then((provider) => {
          commit(`SET_${type.toUpperCase()}_PROVIDER_DETAILS`, provider);
          resolve()
        })
        .catch( err => {
          reject(id)
        });
      })
    } catch (err) {
      dispatch('setError', err, { root: true });
    }
  } else {
    const providerDetails = state[type].providerList.find(provider => provider.identity_provider_id === id)

    commit(`SET_${type.toUpperCase()}_PROVIDER_DETAILS`, providerDetails);
  }
}

export async function getProviderSsoUrl({ dispatch, commit, state, rootGetters }, { type, id }) {
  if (!rootGetters['settings/getDebug']) {
    try {
      return new Promise((resolve, reject) => {
        const providerDetails = queries.getProviderById(id, type).then((provider) => {
          commit(`SET_${type.toUpperCase()}_PROVIDER_SSO_URL`, provider.configuration.redirect_sso_jwt_url);
          resolve()
        });
      })
    } catch (err) {
      dispatch('setError', err, { root: true });
    }
  } 
}

export function setProviderType({ dispatch, commit, state, rootGetters }, { type, providerType }) {
    try {

      commit(`SET_${type.toUpperCase()}_PROVIDER_TYPE`, providerType);

    } catch (err) {
      dispatch('setError', err, { root: true });
    }
}

export async function getIsSystemSsoProviderEnabled({ dispatch, commit, state, rootGetters }, type) {
  if (!rootGetters['settings/getDebug']) {
    try {
      const providerList = await queries.getProviderList(type);
      let anySsoEnabled = false;

      providerList.items.forEach(element => {
        if (element.identity_provider_type == 'IdentityProviderSSO' && element.system == true && anySsoEnabled == false) {
          anySsoEnabled = element.enabled;
        }
      });

      if (!providerList) {
        throw new Error('Failed to obtain the list of providers')
      }

      if (type != undefined) {
        commit(`SET_${type.toUpperCase()}_SYSTEM_SSO_PROVIDER_ENABLED`, anySsoEnabled);
      }

    } catch (err) {
      dispatch('setError', err, { root: true });
    }
  } else {
    if (state[type].providerList.length === 0) {
      const providers = [
        { identity_provider_id: 'IdentityProviderDatabase', identity_provider_type: 'IdentityProviderDatabase', enabled: false, system: true },
        { identity_provider_id: `Verizion${type === 'isp' ? 'LDAP' : 'SSO'}1`, identity_provider_type: `IdentityProvider${type === 'isp' ? 'LDAP' : 'SSO'}`, enabled: true, system: type === 'isp' ? false : true },
        { identity_provider_id: `Verizion${type === 'isp' ? 'LDAP' : 'SSO'}2`, identity_provider_type: `IdentityProvider${type === 'isp' ? 'LDAP' : 'SSO'}`, enabled: false, system: false }
      ]
      let anySsoEnabled = false;

      providers.forEach(element => {
        if (element.identity_provider_type == 'IdentityProviderSSO' && element.system == true && anySsoEnabled == false) {
          anySsoEnabled = element.enabled;
        }
      });

      if (type != undefined) {
        commit(`SET_${type.toUpperCase()}_SYSTEM_SSO_PROVIDER_ENABLED`, anySsoEnabled);
      }
    }
  }
}

export async function clearProviderDetails({ commit }, type) {
  commit(`CLEAR_PROVIDER_DETAILS`, type);
}

export async function setProviderDetails(
  { dispatch, state },
  { id, env, type, data }
) {
  try {
    let providerDetails = {
      identity_provider_id: data.identity_provider_id,
      identity_provider_type: data.identity_provider_type
    }
    switch (type) {
      case 'IdentityProviderDatabase':
        providerDetails = {
          ...providerDetails,
          enabled: data.enabled,
          password_complexity: data.password_complexity || {},
          password_rotation: data.password_rotation || {}
        }
        break;
      case 'IdentityProviderSSO':
        providerDetails = {
          ...providerDetails,
          enabled: data.enabled,
          redirections: data.redirections || {},
          configuration: data.configuration || {}
        };
        break;
      case 'IdentityProviderSSOMultipleAccount':
        providerDetails = {
          ...providerDetails,
          enabled: data.enabled,
          redirections: data.redirections || {},
          configuration: data.configuration || {}
        };
        break;
      case 'IdentityProviderLDAP':
        providerDetails = {
          ...providerDetails,
          enabled: data.enabled,
          ldap_cluster: data.ldap_cluster,
          local_cache: data.local_cache,
          table_mapping: { ...data.table_mapping }
        };
        break;
      case 'IdentityProviderIDAM':
        providerDetails = {
          ...providerDetails,
          enabled: data.enabled,
          api_url: data.api_url,
          api_key: data.api_key,
        };
        break;        
      default:
        console.error("Unknown provider type")
        return;
    };
    await queries.updateProvider(id, env, providerDetails);
  } catch (err) {
    if (err.response && err.response.data && err.response.data.title) {
      if (err.response.data.title.includes('E11000')) {
        dispatch('setError', i18n.t('duplicate_issuer_audience'), { root: true });
      } else if (err.response.data.title.includes('LDAP group is duplicated')) {
        dispatch('setError', i18n.t('LDAP_group_is_duplicated'), { root: true });
      }
    } else if (err.response && err.response.data && err.response.data.type) {
      dispatch('setError', i18n.t(err.response.data.type), {root: true});
    } else {
      if (err.title && err.title.includes('E11000')) {
        dispatch('setError', i18n.t('duplicate_issuer_audience'), { root: true });
      } else {
        dispatch('setError', err, { root: true });
      }
    }
    return Promise.reject(err);
  }
}

export async function getPasswordStrengthSettings(
  { dispatch, state },
  type,
) {
  await dispatch('getProviderList', type);

  const databaseProvider = getDatabaseProvider(state[type].providerList);

  if (!databaseProvider) {
    const errMsg = i18n.t('identity_provider_not_found');
    dispatch('setError', errMsg, { root: true });
    throw new Error(errMsg);
  }

  await dispatch('getProviderDetails', {
    id: databaseProvider.identity_provider_id,
    type,
  });
}

export function createProvider({ commit, rootGetters },{ type, provider }) {
  if( !rootGetters['getDebug'] ) {
    return new Promise((resolve, reject) => {
      queries.createProvider(type, provider)
      .then(res => {
        commit('CREATE_PROVIDER', { type, provider: res });
        resolve(res)
      })
      .catch(error => {
        const errorTitle = _.get(error, 'response.data.title');
        const errorType = _.get(error, 'response.data.type');

        if (errorTitle && errorTitle.includes("LDAP group is duplicated")) {
          error = i18n.t('LDAP_group_is_duplicated');
        }
        else if (errorType) {
          error = i18n.t(errorType);
        }
        commit('setError', error, { root: true });
        reject();
      })
    });	
  } else {
    commit('CREATE_PROVIDER', { type, provider });
  }
}

export async function checkEnableds(
  { state },
  { type, updatedProvider }
) {
  let count = 0;
  for (const provider of state[type].providerList) {
    provider.identity_provider_id === updatedProvider.identity_provider_id
      ? updatedProvider.enabled && count++
      : provider.enabled && count++
  }
  return count;
}

export async function disableOthers(
  { state, dispatch },
  { type, updatedProvider }
) {
  for (const provider of state[type].providerList) {
    if (provider.identity_provider_id !== updatedProvider.identity_provider_id && provider.enabled) {
      const providerDetails = {
        ...provider,
        enabled: false
      }
      await dispatch('updateProviderStatus', { type, provider: providerDetails, force: false })
    }
  }
}

export async function updateProviderStatus(
  { dispatch, commit, rootGetters },
  { type, provider, force, simulation = false }
) {
  return new Promise(async (resolve, reject) => {
    const countEnableds = await dispatch('checkEnableds', {
      type,
      updatedProvider: provider
    });
    if (type === 'isp') {
      if (countEnableds > 1 && !force) {
        reject({ enableds: true, cause: 'twoOrMoreEnableds' })
        return;
      }
    }
    if (countEnableds === 0 && !force) {
      reject({ enableds: true, cause: 'zeroEnableds' })
      return;
    }
    const providerDetails = {
      identity_provider_id: provider.identity_provider_id,
      identity_provider_type: provider.identity_provider_type,
      enabled: provider.enabled
    }
    if (!rootGetters['settings/getDebug']) {
      try {
        // simulate change on Edit because Edit has its own save call
        if (!simulation) {
          await queries.updateProvider(provider.identity_provider_id, type, providerDetails);
        }
        // even if it's a simulation, we need to commit the change so that disableOthers can work properly
        commit('UPDATE_PROVIDER', { type, provider });

        if (countEnableds > 1 && force) {
          await dispatch('disableOthers', {
            type,
            updatedProvider: provider
          });
        }
        resolve()
      } catch (err) {
        if (err.response.data.title.includes('Unknown cluster')){ 
          dispatch('setError', i18n.t('unknown_cluster_data'), { root: true });
          reject({ enableds: false, cause: 'twoOrMoreEnableds' })
        }
        else{
          dispatch('setError', err, { root: true });
          reject()
        }  
      }
    } else {
      commit('UPDATE_PROVIDER', { type, provider });
      resolve()
    }
  })
}

export async function deleteProvider(
  { dispatch, commit, rootGetters },
  { type, provider }
) {
  if (!rootGetters['settings/getDebug']) {
    try {
      await queries.deleteProvider(provider.identity_provider_id, type);

      commit('REMOVE_PROVIDER', { type, provider });
    } catch (err) {
      dispatch('setError', err, { root: true });
    }
  } else {
    commit('REMOVE_PROVIDER', { type, provider });
  }
}

export async function getActiveProviders({ dispatch }) {
  const type = 'isp';
  try {
    const activeProviders = await queries.getActiveProviders(type);
    // Only for testing mode:
    // const activeProviders = { items: [
    //   {
    //     identity_provider_id: "My Provider",
    //     identity_provider_type: "IdentityProviderDatabase"
    //   },
    //   {
    //     identity_provider_id: "My Provider 2",
    //     identity_provider_type: "MyProvider"
    //   }
    // ] };
    dispatch('setActiveProvider', activeProviders.items[0]);

    return activeProviders.items;
  } catch (err) {
    dispatch('setError', err, { root: true });
    return [];
  }
}

export async function setActiveProvider({ commit }, activeProvider) {
  commit('SET_ACTIVE_PROVIDER', activeProvider);
  commit('SET_ISP_PROVIDER_DATABASE_ACTIVE', activeProvider ? activeProvider.identity_provider_type == 'IdentityProviderDatabase' : false);
}
