export default {
    setCurrentInternalCategoryId(state, id) {
        state.currentInternalCategoryId = id
    },
    setBlackList(state, value) {
        state.forbiddenAllowedList.black_list = value;
    },
    setWhiteList(state, value) {
        state.forbiddenAllowedList.white_list = value;
    },
    updateBlackList(state, { action, item }) {
        switch (action) {
            case 'add':
                state.forbiddenAllowedList.black_list.unshift(item)
                break;
            case 'del':
                state.forbiddenAllowedList.black_list = state.forbiddenAllowedList.black_list.filter(i => i !== item)
                break;
        }
    },
    updateWhiteList(state, { action, item }) {
        switch (action) {
            case 'add':
                state.forbiddenAllowedList.white_list.unshift(item)
                break;
            case 'del':
                state.forbiddenAllowedList.white_list = state.forbiddenAllowedList.white_list.filter(i => i !== item)
                break;
        }
    },
    set_self_query(state, { self_query, action }) {
        state.params_self_query[action] = self_query
    },
    set_query(state, { query, action }) {
        state.params_query[action] = query
    },
    update_current_service_id(state, service_id) {
        state.current_service_name = service_id
    }
}