/**
 * Generate array from Back-end to use it in Front-end
 * Function to generate the antivirus rules array from the account strategy
 * @param {Object} strategy attribute strategy of an account
 */
export const prepareDnscfData = strategy => {  
  let rules = [];  
    for (const ruleName in strategy.items){
      if (strategy.items.hasOwnProperty(ruleName)) {
        
        const rule = strategy.items[ruleName]
        rules.push({
          rule_name: rule.rule_id,
          id: rule.rule_id,        
          status: rule.status,       
          action: { type: rule.action },
          order: rule.priority,
          source: getDNSSrc(rule.source),
          categories : rule.categories,
          urls : rule.domains
        });
      }
    }  
    return rules;  
  
};

/**
 * Function to generate dns source input from the rule,
 * @param {Object} source object with the description of the source 
 */
const getDNSSrc = (source) => {  
  let src = [];
  if( source.type === "ips"){
    source.items.forEach((item) => {
      src.push({"type": "ip", "value" : item})
    })
  }
  return src;
};

/**
 * Function to generate dns source input from the rule,
 * @param {Object} source object with the description of the source 
 */
 const setDNSSrc = (source) => { 
  let dnssrc;

  if( source.length <= 0 )
    dnssrc = {"type" : "any"}
  else{
    let ips = [];
    source.forEach((ip) => {     
      ips.push(ip.value)
    })
    dnssrc = {
      type : "ips",
      items : ips
    }
  }
  return dnssrc;
};


/**
 * Generate object from Front-end to send it to Back-end
 * Function to generate the strategy of an account from an array of rules
 * @param rules array of rules
 */
export const prepareStrategyData = rule => {  
  let dnsrule = {
    rule_id : rule.rule_name,
    status : rule.status,
    priority : rule.order,
    source : setDNSSrc(rule.source),
    categories : rule.categories,
    domains : rule.urls,
    action : rule.action.type
  
  }; 
  return dnsrule;
};

