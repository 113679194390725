import DashboardLayout from "@/layout/dashboard/DashboardLayout.vue";
import { store } from "@/store";

const Accounts = () => import(
  /* webpackChunkName: "account-management" */
  "@/pages/Accounts/Accounts.vue"
);

const AccountEdit = () => import(
  /* webpackChunkName: "account-management" */
  "@/pages/Accounts/AccountEdit.vue"
);

const ManagersPage  = () => import(
  /* webpackChunkName: "account-management" */
  "@/pages/Managers/ManagersPage.vue"
);

const Users  = () => import(
  /* webpackChunkName: "account-management" */
  "@/pages/Users/Users.vue"
);

const Devices  = () => import(
  /* webpackChunkName: "account-management" */
  "@/pages/Devices/Devices.vue"
);

const FrameLoader = () => import(
  /* webpackChunkName: "account-management" */
  "@/pages/Accounts/FrameLoader.vue"
);

export default  {
  path: "/account_management",
  component: DashboardLayout,
  redirect: "/account_management/accounts",
  children: [
    {
      path: "accounts",
      name: "Account Management / Accounts",
      component: Accounts
    },
    {
      path: "accounts/new",
      name: "Account Management / Accounts / New",
      component: AccountEdit
    },
    {
      path: 'accounts/edit/:id',
      name: 'Account Management / Accounts / Edit',
      component: AccountEdit,
      props: true
    },
    {
      path: "managers",
      name: "Account Management / Managers",
      component: ManagersPage,
    },
    {
      path: "users",
      name: "Account Management / Users",
      component: Users,
      beforeEnter: (to, from, next) => {
        const isIOT = store.getters['settings/isIOT'];
        next(!isIOT)
      }
    },
    {
      path: "devices",
      name: "Account Management / Devices",
      component: Devices,
      beforeEnter: (to, from, next) => {
        const isIOT = store.getters['settings/isIOT'];
        next(!isIOT)
      }
    },
  ]
};
