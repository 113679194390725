export default {
    advanced_configurations(state) {
        return state.advanced_configurations;
    },
    global_configurations(state) {
        return state.global_configurations;
    },
    global_advanced_configurations(state) {
        return state.global_advanced_configurations;
    },
    filter_by_service(state) {
        return state.filter_by_service;
    },
    filter_by_product(state) {
        return state.filter_by_product;
    },
    black_list(state) {
        return state.forbiddenAllowedList.black_list;
    },
    white_list(state) {
        return state.forbiddenAllowedList.white_list;
    },   
    params_query: (state) => (action) => {
        return state.params_query[action]
    },
    params_self_query: (state) => (action) => {
        return state.params_self_query[action]
    }, 
}