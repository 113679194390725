/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'item-endpointsecure': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M13 15.9c.1-.6.3-1.3.6-1.8l-9.2.1V6h13v4.6c.5-.2 1.1-.3 1.6-.3V6c0-.9-.7-1.6-1.6-1.6h-13c-.9 0-1.7.7-1.7 1.6v8.2c0 .9.7 1.6 1.6 1.6H1.1v1.6h12.1c-.2-.4-.2-1-.2-1.5z" _stroke="#000" stroke-width=".5" stroke-miterlimit="10"/><path pid="1" d="M19.2 11.9c-2.3 0-4.2 1.9-4.2 4.2s1.9 4.2 4.2 4.2 4.2-1.9 4.2-4.2c0-2.3-1.9-4.2-4.2-4.2zm0 7.2c-1.3-.4-2.3-1.7-2.3-3.1v-1.8l2.4-.9 2.4.9V16c-.1 1.2-1.1 2.8-2.5 3.1z" _stroke="#000" stroke-width=".5" stroke-miterlimit="10"/>'
  }
})
