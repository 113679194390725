import axios from "axios";

export default {
    getDNSState({commit, state, dispatch, rootState}) {        
        if(rootState.settings.debug){        
          return new Promise((resolve, reject) => {
            let dnsst = {                            
                "enable_content_filtering": true,
                "enable_safe_search": true,
                "enable_quiet_time": true,
                "enable_anti_phishing": true,
                "enable_anti_malware": true,
                "enable_anti_botnet": true,
                "enable_ads_free": true,
                "dnsecure_health_check_interval_sec": 5,
                "dnsecure_inactive_max_time_sec": 300              
            }                                    
            commit('setDNSStatus', dnsst);   
            resolve(dnsst)     
          })
        } else {
          return new Promise((resolve, reject) => {            
            axios.get('/subsystems/ds/advanced-configuration')
              .then(res => {
                if ( res && res.data ){
                 
                  commit('setDNSStatus', res.data);
                  resolve(res.data)
                }
                else{
                  reject()
                }
              })
              .catch(error => {
                commit('setError', error, {root:true});
                reject()
              })
          });  
        }
      },

    updateDNSState({commit, state, dispatch}, data) {  
      return new Promise((reject) => {
                        
        axios.put('/subsystems/ds/advanced-configuration', data)
          .then(res => {
            if ( res && res.data ){                                              
              commit('setDNSStatus', res.data);                            
            }
            else{
              reject()
            }
          })
          .catch(error => {
            commit('setError', error, {root:true});
            reject()
          })
      });  
    }
}