<template>
  <b-card class="card-user pl-3 pr-3" no-body>
    <b-card-body style="min-height: auto;" class="pb-0 mt-1">
      <div style="display: flex;max-width: 2560px;" >
        <h3 class="mt-1 mb-2">{{t('headerText')}}</h3>
        <n-button class="mt-1 mb-2" style="margin-left: auto; min-width: 120px; width: 15%; max-height: 40px; z-index: 1;"
          type="primary"
          size="md" round block
          :disabled='disableSubmit'
          :visible_for_permissions='[{actions: ["PATCH"], path: "/subsystems/ns/service-configuration"}]'
          @click.native="saveFilteringConfig">
          {{t('save')}}
        </n-button>
      </div>
    </b-card-body>

    <template v-slot:footer>
      <div class="hr-grey mb-2" style="margin-left: -30px; margin-right: -30px;"/>
      <div @click="refresh" v-if="!updating" style="cursor: pointer;display: inline;">
        <i class="now-ui-icons arrows-1_refresh-69" />
        {{ t('refresh') }}
      </div>
      <div v-else>
        <i class="now-ui-icons loader_refresh spin"/>
        {{ t('refreshing') }}
      </div>
    </template>
  </b-card>
</template>

<script>
export default {
  name: "webFilterFilteringTitle",
  props: {
    loading: {
      type: Boolean,
      required: true,
    },
    updating: {
      type: Boolean,
      required: true,
    },
    disableSubmit: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    saveFilteringConfig() {
      this.$emit('save');
    },
    refresh() {
      this.$emit('refresh');
    },
  }
}
</script>
