<template>
  <div >
    <fg-input v-model.lazy="model.password"
              autocomplete="current-password"
              id="password"
              v-validate="'required'"
              type="password"
              name="password"
              :error="getError('password')"
              class="no-border no-height form-control-lg"
              :placeholder="t('administrator-form.password')"
              addon-left-icon="tim-icons icon-lock-circle"
              autocapitalize="none"
              style="font-size:1rem;">
    </fg-input>

    <template v-if="!selectedProvider">
      <el-select
        id="identity-providers-selector"
        v-if="activeProviders.length > 1"
        class="select-primary"
        :placeholder="t('provider')"
        v-model="activeProvider"
        style="width:100%;"
        v-validate="'required'"
        name="provider"
        :error="getError(t('provider'))"
      >
        <el-option
          v-for="option in activeProviders"
          :id="`identity-providers-option-${option.identity_provider_id}`"
          class="select-primary"
          :value="option.identity_provider_id"
          :label="option.identity_provider_id"
          :key="option.identity_provider_id"
        >
        </el-option>
      </el-select>
      <div
        class="text-danger mt-1"
        style="font-size: 13px;"
        v-show="getError(t('provider'))"
      >
        {{ getError(t('provider')) }}
      </div>
    </template>

    <b-row v-if="isLoginCaptchaEnabled" class="p-0">
      <b-col>
        <fg-input v-model.lazy="model.captcha" autocomplete="one-time-code"
                  id="captcha"
                  v-validate="'required'"
                  type="text"
                  name="captcha"
                  :error="getError('captcha')"
                  class="no-border no-height form-control-lg"
                  :placeholder="t('captcha')"
                  addon-left-icon="tim-icons icon-lock-circle"
                  autocapitalize="none"
                  style="font-size:1rem;">
        </fg-input>
      </b-col>
      <b-col >
        <b-row class="captcha-image">
          <img :src="captcha.captcha_img" :alt="captcha.captcha_hash" />
        </b-row>
        <b-row class="captcha-buttons">
          <n-button @click.native="handleRefresh" round type="primary">
            <i class="now-ui-icons arrows-1_refresh-69"></i>
          </n-button>
          <n-button @click.native="handleSound" round type="primary" v-if="isAudioCaptchaAvailable">
            <i class="now-ui-icons media-2_note-03"></i>
          </n-button>
          <audio ref="sound" :src="captcha.captcha_audio" v-if="isAudioCaptchaAvailable">
            {{ t('Your browser does not support the') }} <code>audio</code> {{ t('element') }}.
          </audio>
        </b-row>
      </b-col>
    </b-row>

  </div>
</template>
<script>

import _ from "lodash";

export default {
  name: 'login-password',
  props:{
    selectedProvider: {
      type: String,
      default: null
    },
    privileges: {
      type: Array,
      default: () => []
    },
    value:{
      type: Object,
      default: {
        password: '',
        captcha: '',
        provider: '',
        recoveryLinksDisabled: false,
        valid: false
      }
    }
  },
  data() {
    return {
      model: {
        password: '',
        captcha: '',
        provider: ''
      },
      activeProviders: []
    };
  },
  computed: {
    captcha() {
      return this.$store.getters["auth/captcha"];
    },
    isLoginCaptchaEnabled() {
      const captcha = this.captcha;

      return ('login_captcha_enabled' in captcha) && (captcha.login_captcha_enabled === true);
    },
    isAudioCaptchaAvailable(){
      return !!this.captcha && !!this.captcha.captcha_audio
    },
    defaultProvider() {
      return this.$store.getters['providers/defaultProvider'];
    },
    activeProvider: {
      get() {
        return this.selectedProvider ? this.selectedProvider : this.model.provider
      },
      set(updatedProvider) {
        if (!this.selectedProvider){
          this.model.provider = updatedProvider;
          const active = this.activeProviders.find(provider => provider.identity_provider_id === this.model.provider);
          this.$store.dispatch('providers/setActiveProvider', active)
        }
      }
    },
    recoveryLinksDisabled() {
      const active = this.activeProviders.find(provider => provider.identity_provider_id === this.model.provider);
      return active ? active.identity_provider_type !== this.defaultProvider : false;
    },
  },
  watch: {
    model: {
      deep: true,
      async handler(){
        let isValid = await this.$validator.validateAll();
        this.$emit('input', { 
          ...this.model,
          captcha_info: this.captcha,
          valid: isValid, 
          recoveryLinksDisabled: this.recoveryLinksDisabled})
      }
    },
    value: {
      deep: true,
      immediate: true,
      handler(){
        if ( !_.isEqual(this.model, this.value))
          this.model = this.value
      }
    }
  },
  methods: {
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    handleSound() {
      this.$refs['sound'].play();
    },
    handleRefresh() {
      this.$store.dispatch('auth/getCaptcha');
    },
    async getActiveProviders() {
      this.activeProviders = await this.$store.dispatch('providers/getActiveProviders');
      this.model.provider = this.activeProviders && this.activeProviders[0] ? this.activeProviders[0].identity_provider_id : '';
    }
  },
  mounted() {
    this.getActiveProviders();
    this.$store.dispatch('auth/getCaptcha', {});
  }
};
</script>
<style>
audio {
  display: none;
}
.captcha-image {
  display: flex;
  justify-content: center;
}
.captcha-buttons {
  display: flex;
  justify-content: space-evenly;
}
</style>
