/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'item-homesecure': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g _fill="#383132"><path pid="0" d="M13.3 19.5c-.1-.7-.1-1.3.1-2H5.8V8.6l6-4.6 6 4.6V13c.4-.1.8-.1 1.2-.1.3 0 .6 0 .8.1v-2.7l1.8 1.4 1.2-1.6-11-8.4-11 8.4L2 11.5l1.8-1.4v9.4h9.5z"/><path pid="1" d="M19 14.1c-2.3 0-4.2 1.9-4.2 4.2s1.9 4.2 4.2 4.2 4.2-1.9 4.2-4.2-1.9-4.2-4.2-4.2zm0 7.2c-1.3-.4-2.3-1.7-2.3-3.1v-1.8l2.4-.9 2.4.9v1.8c-.1 1.2-1.1 2.8-2.5 3.1z" _stroke="#383132" stroke-width=".5" stroke-miterlimit="10"/></g>'
  }
})
