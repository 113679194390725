/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'device-macbook': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path pid="0" d="M28.654 21.116a4.18 4.18 0 001.115-2.787V7.181A4.18 4.18 0 0025.589 3H6.077a4.18 4.18 0 00-4.18 4.18v11.15a4.18 4.18 0 001.267 2.982L.462 24.684a2.09 2.09 0 001.575 3.4h27.871a2.09 2.09 0 001.63-3.4l-2.884-3.568zM4.684 7.181c0-.77.624-1.394 1.394-1.394h19.51c.77 0 1.394.624 1.394 1.394v11.148c0 .77-.624 1.394-1.394 1.394H6.078c-.77 0-1.394-.624-1.394-1.394V7.181zM3.542 25.297l2.23-2.787h20.401l2.23 2.787H3.542z" _fill="#0E1317" fill-rule="nonzero"/>'
  }
})
