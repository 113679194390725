export default () => ({
    service_status: {},
    service_configuration: {
        blocked_categories: {value: false, enabled: false},
        safe_search: {value: false, enabled: false},
        quiet_time: {value: false, enabled: false},
        malware: {value: false, enabled: false}
    },
    lan_protection: {
        enabled: true,
        telnet_credentials: true,
        device_access: true,
        device_scan: true,
        default_remote_shell: true
    },
    router_protection: {
        enabled: true,
        default_remote_shell: true,
        dns_settings: true,
        allow_enforcement: true
    },
})