/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'autonotices-banners': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" _fill="none" _stroke="#383132" stroke-width="1.5" stroke-miterlimit="10" d="M2.2 7h19.6v10.1H2.2zM21.2 7.8l-19 8.3M21.2 16.2L2.3 7.7"/>'
  }
})
