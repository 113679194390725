<template>
    <b-row style="width: 100%;">
        <b-col cols="12">
            <b-row v-if="typeof(model)=='boolean'" no-gutters class="mb-3 px-3">
                <b-col cols="12" class="form-group has-label pt-2 mb-0" align-self="center">
                    <h5 class="mx-auto my-auto text-capitalize" style="white-space: nowrap; ">{{ t(beautify_string(setting.name)) }}</h5>
                </b-col>
                <b-col align-self="center">
                    <n-switch :disabled="disable" v-model="model" :visible_for_permissions="visible_for_permissions"/>
                </b-col>
            </b-row>
            <div class="form-group col-12 pt-2" v-else-if="select_options">
                <label> {{ t(beautify_string(setting.name)) }} </label>
                <el-select class="select-primary" size="large" v-model="model">
                    <el-option
                        v-for="(option, index) in select_options"
                        class="select-primary"
                        :value="option"
                        :label="t(option)"
                        :disabled="disable"
                        :key="`${index}`">
                    </el-option>
                </el-select>
            </div>
            <fg-input v-else
                class="col-12 text-capitalize pt-2"
                :label="t(beautify_string(setting.name))"
                :name="setting.name"
                :ref="setting.name"
                :error="error_message"
                :inputType="typeof(model)"
                :disabled="disable"
                v-model="model" />
        </b-col>
    </b-row>
</template>

<script>
import   GeneralMixin               from './GeneralMixin';
import   schemaValidation           from '@/mixins/schemaValidation';
import { Switch }                   from '@/components';
import {mapActions, mapGetters, mapMutations} from 'vuex';
import { Select, Option }           from 'element-ui';
import _ from 'lodash';

export default
{
    components: {
        [Switch.name]: Switch,
        [Select.name]: Select,
        [Option.name]: Option
    },
    mixins: [ GeneralMixin, schemaValidation ],
    props: {
        disable: {
            type: Boolean,
            default: false
        },
        setting: {
            type: Object,
            default: () => {}
        },
        configurationType: {
            type: String,
            default: ''
        },
        setting_group: {
            type: String,
            default: ''
        },
		global: {
			type: Boolean,
			default: false
		},
        visible_for_permissions: { 
            type: Array, 
            default: function () { return [] }
        }         
    },
    data() {
        return {
            data_type: 'string',
        }
    },
    created() {
        this.data_type = typeof(this.model);
    },
    computed: {
        ...mapGetters('ispServices/services', ['advanced_configurations', 'global_configurations','global_advanced_configurations']),

        configurations() {
            return this.global ? this.configurationType ? this[this.configurationType] : this.global_configurations : this.advanced_configurations;
        },
        select_options() {
            return _.get(this.schema, `properties.${[this.setting_group]}.properties.${[this.setting.name]}.enum`)
        },
        model: {
            get() {
                return this.configurations[this.setting_group][this.setting.name];
            },
            set(value) {
     	          this.setIsFormDirty(true);
                this.setServiceField({ service: this.setting_group, field: this.setting.name, configuration_type: this.configurationType, type: this.data_type , value });
                this.validation(this[this.configurationType][this.setting_group], this.schema.properties[this.setting_group], this.setting.name);
            }
        }
    },
    methods: {
        ...mapMutations('ispServices/services', ['setServiceField']),
        ...mapActions('settings', ['setIsFormDirty']),
    }
}
</script>
