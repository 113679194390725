import DashboardLayout from "@/layout/dashboard/DashboardLayout.vue";
import Filtering from "@/pages/NetworkSecure/WebFilter/Advanced/Filtering";
import SslInspection from "@/pages/NetworkSecure/WebFilter/Advanced/SslInspection";

const ContentFilter = () => import(
  /* webpackChunkName: "ns-services" */
  "@/pages/NetworkSecure/WebFilter/ContentFilter.vue"
);

const ContentFilterEdit = () => import(
  /* webpackChunkName: "ns-services" */
  "@/pages/NetworkSecure/WebFilter/ContentFilterEdit.vue"
);

export default {
  path: '/ns/ContentFilter',
  component: DashboardLayout,
  name: 'Network Secure /  Content Filter',
  children: [
    {
      path: "Policies/Create",
      name: 'Network Secure /  Content Filter / Filtering Policies / Create',
      component: ContentFilterEdit
    },
    {
      path: "Policies/Edit/*",
      name: 'Network Secure /  Content Filter / Filtering Policies / Edit',
      component: ContentFilterEdit
    },
    {
      path: 'Policies*',
      name: 'Network Secure / Content Filter / Filtering Policies',
      component: ContentFilter
    },
    {
      path: 'Advanced/Filtering*',
      name: 'Network Secure / Content Filter / Advanced Configuration / Filtering Configuration',
      component: Filtering
    },
    {
      path: 'Advanced/Https',
      name: 'Network Secure / Content Filter / Advanced Configuration / HTTPs',
      component: SslInspection
    },
  ]
};
