export function networksecurepath(path){
    return path.substring(path.indexOf('/ns') + 3);
}

export function resolveString2Bool(value) {
  if (typeof value === 'string') {
    return value === 'true';
  } else {
    return value;
  }
}
