<template functional>
    <div>
        <div v-for="item in props.row[props.column.prop]" :key="item.name">
            <template v-if="item.state">
                <i class="now-ui-icons ui-1_check icon icon-success"/>
                <span class="capitalize">{{ item.name }}</span> 
            </template>
            <template v-else>
                <i class="now-ui-icons ui-1_simple-remove icon icon-danger"/> 
                <span class="capitalize">{{ item.name }}</span>
            </template>
        </div>
    </div>
</template>

<script>
  export default {
    name: 'yesnolist-formatter',
  }
</script>