import axios from "axios";
import i18n from '../../i18n';
import { mockBlockingPages, createBlockingPages } from "./mockData";

export default {
    updateGlobalBlockingPages({commit, state, rootState, dispatch},data) {
      if (rootState.settings.debug){
        return new Promise((resolve, reject) => {
          let tmp = mockBlockingPages

          if( data.service == "parental_control" ){
            tmp.parental_control.blocking_pages.default_blocking_page = data.bplist.parental_control.blocking_pages.default_blocking_page
            tmp.parental_control.blocking_pages.default_password_blocking_page = data.bplist.parental_control.blocking_pages.default_password_blocking_page
          } else{
            tmp.threat_protection.blocking_pages.default_antivirus_blocking_page = data.bplist.threat_protection.blocking_pages.default_antivirus_blocking_page
            tmp.threat_protection.blocking_pages.default_antimalware_blocking_page =  data.bplist.threat_protection.blocking_pages.default_antimalware_blocking_page
            tmp.threat_protection.blocking_pages.default_antibotnet_blocking_page = data.bplist.threat_protection.blocking_pages.default_antibotnet_blocking_page
          }

          commit('setBlockingPages', tmp);
          resolve(tmp)
        })
      } else {
          let bps = {};
          if( data.service == "parental_control" ){
            bps = {
              "parental_control" : {
                "blocking_pages" : {
                  "default_blocking_page"           : data.bplist.parental_control.blocking_pages.default_blocking_page,
                  "default_password_blocking_page"  : data.bplist.parental_control.blocking_pages.default_password_blocking_page
                }
              }
            }
          } else {
            bps = {
              "threat_protection" : {
                "blocking_pages" : {
                  "default_antivirus_blocking_page"   : data.bplist.threat_protection.blocking_pages.default_antivirus_blocking_page,
                  "default_antimalware_blocking_page" : data.bplist.threat_protection.blocking_pages.default_antimalware_blocking_page,
                  "default_antibotnet_blocking_page"  : data.bplist.threat_protection.blocking_pages.default_antibotnet_blocking_page
                }
              }
            }
          }

          return new Promise((resolve, reject) => {
          axios.patch('/isp-service-configuration', bps)
            .then(res => {
              if ( res && res.data ){
                let tmp = createBlockingPages(res.data)
                commit('setBlockingPages', tmp);
                resolve(tmp)
              }
              else{
                reject(error)
              }
            })
            .catch(error => {
              //console.log("error:"+error)
              commit('setError', error, {root:true});
              reject("error")
            })
        });
      }
    },

    getGlobalBlockingPages({ commit, state, rootState, dispatch }) {
        if (rootState.settings.debug) {
            return new Promise((resolve, reject) => {
                let bps = mockBlockingPages
                commit('setBlockingPages', bps);
                resolve(bps)
            })
        } else {
            return new Promise((resolve, reject) => {
                axios.get('/isp-service-configuration')
                    .then(res => {
                        if (res && res.data) {
                            let bps = createBlockingPages(res.data)
                            commit('setBlockingPages', bps);
                            resolve(bps)
                        }
                        else {
                            reject()
                        }
                    })
                    .catch(error => {
                        commit('setError', error, { root: true });
                        reject()
                    })
            });
        }
    },

    getMaxExternalPages({ commit, state, rootState, dispatch }) {
      return new Promise((resolve, reject) => {
        axios.get('/isp-service-advanced-configuration')
          .then(res => {
            if ( res && res.data ){
              let maxnum = res.data.blocking_pages.max_number_of_external_blocking_pages
              commit('setMaxExternalPages', maxnum);
              resolve(maxnum)
            }
          })
          .catch(error => {
            commit('setError', error, {root:true});
            reject()
          })
      })
    },

    updateBlockingPageList({commit, state, rootState, dispatch},data) {
      let bps = {  [data.service] : { "blocking_pages" : { "blocking_pages_info" : { "external_blocking_pages" : data.bplist } } } }

      if (rootState.settings.debug){
        return new Promise((resolve, reject) => {
          let tmp = bps[data.service].blocking_pages.blocking_pages_info.external_blocking_pages
          commit('setExtBlockingPages', tmp);
          resolve(tmp)
        })
      } else {
          return new Promise((resolve, reject) => {
          axios.patch('/isp-service-configuration', bps)
            .then(res => {
              if ( res && res.data ){
                let tmp = res.data[data.service].blocking_pages.blocking_pages_info.external_blocking_pages;
                commit('setExtBlockingPages', tmp);
                resolve(tmp)
              }
              else{
                reject()
              }
            })
            .catch(error => {
              if (error.response && error.response.data) {
                if (error.response.data.type && error.response.data.type === 'ISP_SERVICE_CONFIGURATION.LIMIT_EXCEEDED') {
                  commit('setError', i18n.t('MAX_BLOCKING_PAGES_ALLOWED'), {root: true});
                }
                else {
                  commit('setError', error, { root: true });
                }
              }
              reject()
            })
        });
      }
    }
}
