import axios from 'axios';
import { ENTERPRISE, IDENTITY, PROVIDERS, ACTIVE_PROVIDERS } from '../endpoints';

export async function getProviderList(type) {
  const params = { type };
  let { data } = await axios.get(`/${IDENTITY}/${PROVIDERS}`, { params });
  if ( !!data && !!data.items )
    data.items = data.items.map( item => {
      return Object.assign( { enabled: false, system: false, configuration: {log_as_in_frame: false}, redirections: {}} , item)
    })

  return data;
}

export async function getProviderById(id, type) {
  const params = { type };
  const { data } = await axios.get(`/${IDENTITY}/${PROVIDERS}/${id}`, { params });
  return Object.assign( { enabled: false, system: false, local_cache: false, configuration: {log_as_in_frame: false}, redirections: {}} , data)
}

export function createProvider(type, provider) {
  const params = { type };

  var query = axios
    .post(`/${IDENTITY}/${PROVIDERS}`, provider, { params });

  return query;
}

export async function updateProvider(id, type, provider) {
  const params = { type };

  const { data } = await axios
    .patch(`/${IDENTITY}/${PROVIDERS}/${id}`, provider, { params });

  return data;
}

export async function deleteProvider(id, type) {
  const params = { type };

  const { data } = await axios
    .delete(`/${IDENTITY}/${PROVIDERS}/${id}`, { params });

  return data;
}

export async function getActiveProviders(type) {
  const params = { type };
  const { data } = await axios.get(`/${IDENTITY}/${ACTIVE_PROVIDERS}`, { params });
  return data;
}