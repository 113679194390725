import DashboardLayout from "@/layout/dashboard/DashboardLayout.vue";
import { store } from "@/store";

const Administrators = () => import(
  /* webpackChunkName: "general-management" */
  "@/pages/Administrators/Administrators.vue"
);

/* const PasswordPolicy = () => import(
  webpackChunkName: "general-management"
  "@/pages/PasswordPolicy.vue"
); */

const AdministratorEdit = () => import(
  /* webpackChunkName: "general-management" */
  "@/pages/Administrators/AdministratorEdit.vue"
);

const License  = () => import(
  /* webpackChunkName: "general-management" */
  "@/pages/GeneralManagement/License/License.vue"
)
;
const DataExport  = () => import(
  /* webpackChunkName: "general-management" */
  "@/pages/GeneralManagement/DataExport/DataExport.vue"
);
const DataExportEdit  = () => import(
  /* webpackChunkName: "general-management" */
  "@/pages/GeneralManagement/DataExport/DataExportEdit.vue"
);
const Customization  = () => import(
  /* webpackChunkName: "general-management" */
  "@/pages/GeneralManagement/Customization/Customization.vue"
);

// const SsoConfiguration  = () => import(
//    /* webpackChunkName: "general-management" */
//   "@/pages/Login/SsoConfiguration.vue"
// );

const Authentication  = () => import(
  /* webpackChunkName: "general-management" */
  "@/pages/GeneralManagement/Authentication/Authentication.vue"
);

const IdentityProvidersEdit = () => import(
  /* webpackChunkName: "general-management" */
  "@/pages/GeneralManagement/Authentication/IdentityProvidersEdit.vue"
);

const ExternalServers  = () => import(
   /* webpackChunkName: "general-management" */
  "@/pages/GeneralManagement/ExternalServers/ExternalServers.vue"
);

const ExternalServersEdit = () => import(
  /* webpackChunkName: "general-management" */
  "@/pages/GeneralManagement/ExternalServers/ExternalServersEdit.vue"
);

const Notifications  = () => import(
   /* webpackChunkName: "general-management" */
  "@/pages/GeneralManagement/Notifications/Notifications.vue"
);


const PageAboutInfo = () => import(/* webpackChunkName: "general-management" */
'@/pages/PageAbout.vue'
);

const RouterJobs  = () => import(
   /* webpackChunkName: "general-management" */
  "@/pages/GeneralManagement/Jobs/RouterJobs.vue"
);

const AccountJobs  = () => import(
   /* webpackChunkName: "general-management" */
  "@/pages/GeneralManagement/Jobs/AccountJobs.vue"
);

export default {
    path: '/general_management',
    component: DashboardLayout,
    name: 'General Management',
    children: [
      {
        path: "administrators",
        name: "General Management / Administrators",
        component: Administrators,
        beforeEnter: (to, from, next) => {
          const isLDAP = store.getters['auth/isLdap']();
          next(!isLDAP)
        }
      },
      {
        path: "administrators/new",
        name: "General Management / Administrators / New",
        component: AdministratorEdit,
        beforeEnter: (to, from, next) => {
          const isLDAP = store.getters['auth/isLdap']();
          next(!isLDAP)
        }
      },
      {
        path: 'administrators/edit/:id',
        name: 'General Management / Administrators / Edit',
        component: AdministratorEdit,
        props: true,
        beforeEnter: (to, from, next) => {
          const currentManager = encodeURIComponent(store.getters['auth/manager']);
          const goToManager = to.params.id;
          const isLDAP = store.getters['auth/isLdap']();
          isLDAP
            ? goToManager === currentManager
              ? next()
              : next(false)
            : next();
        }
      },
      {
        path: 'license',
        name: 'General Management / License',
        component: License
      },
      {
        path: 'customization',
        name: 'General Management / Customization',
        component: Customization,
        props: true
      },
      // {
      //   path: 'ssoConfiguration',
      //   name: 'General Management / Single Sign On',
      //   component: SsoConfiguration
      // },
      {
        path: "authentication",
        component: Authentication,
        redirect: "authentication/isp",
      },
      {
        path: 'authentication/:guiStore',
        name: 'General Management / Authentication',
        component: Authentication,
        props: true,
        beforeEnter: (to, from, next) => {
          const toGUI = to.params.guiStore;
          const isIOT = store.getters['settings/isIOT'];
          isIOT
            ? next(toGUI !== 'res')
            : next(toGUI !== 'ent')
        }
      },
      {
        path: 'authentication/:guiStore/identity_providers/edit/:type/:id',
        name: 'General Management / Authentication / Identity Providers / Edit',
        component: IdentityProvidersEdit,
        props: true,
        beforeEnter: (to, from, next) => {
          const toGUI = to.params.guiStore;
          const isIOT = store.getters['settings/isIOT'];
          isIOT
            ? next(toGUI !== 'res')
            : next(toGUI !== 'ent')
        }
      },
      {
        path: 'authentication/:guiStore/identity_providers/new',
        name: 'General Management / Authentication / Identity Providers / New',
        component: IdentityProvidersEdit,
        props: true,
        beforeEnter: (to, from, next) => {
          const toGUI = to.params.guiStore;
          const isIOT = store.getters['settings/isIOT'];
          isIOT
            ? next(toGUI !== 'res')
            : next(toGUI !== 'ent')
        }
      },
      {
        path: 'external_servers',
        name: 'General Management / External Servers',
        component: ExternalServers
      },
      {
        path: "external_servers/Create/:cluster_name/:cluster_type",
        name: "General Management / Cluster External Servers / Create",
        component: ExternalServersEdit,
        props: true
      },
      {
        path: "external_servers/Create/:server_type",
        name: "General Management / External Servers / Create",
        component: ExternalServersEdit,
        props: true
      },
      {
        path: 'external_servers/Edit/:cluster_name/:cluster_type/:server_name',
        name: 'General Management / Cluster External Servers / Edit',
        component: ExternalServersEdit,
        props: true
      },
      {
        path: 'external_servers/Edit/:server_type/:server_id',
        name: 'General Management / External Servers / SMS / Edit',
        component: ExternalServersEdit,
        props: (route) => ({
          isEdit: route.params.isEdit
        })
      },
      {
        path: 'external_servers/Edit/:cluster_or_server_type/:server_id',
        name: 'General Management / External Servers / Email / Edit',
        component: ExternalServersEdit,
        props: (route) => ({
          isEdit: route.params.isEdit
        })
      },
      {
        path: 'About',
        name: 'General Management / About',
        component:  PageAboutInfo,
        props: true
      },
      {
        path: 'jobs/router_jobs',
        name: 'General Management / Jobs / Router Jobs',
        component: RouterJobs
      },
      {
        path: 'jobs/account_jobs',
        name: 'General Management / Jobs / Account Jobs',
        component: AccountJobs
      },
      {
        path: 'data_export',
        name: 'General Management / Data Export',
        component: DataExport,
        beforeEnter: (to, from, next) => {
          const dataExportInstalled = store.getters['settings/subsystemsPackages'].includes('data_export');
          if (dataExportInstalled) {
            next();
          };
        }
      },
      {
        path: 'data_export/edit/:id',
        name: 'General Management / Data Export / Edit',
        component: DataExportEdit,
        props: true
      }
    ]
};

