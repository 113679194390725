/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'user-tie': {
    width: 97.126,
    height: 97.126,
    viewBox: '0 0 97.126 97.126',
    data: '<path pid="0" d="M78.683 56.08c-3.735-6.055-13.433-10.234-25.939-11.183a1.97 1.97 0 00-1.507.522c-.272.252-.581.602-.924.998-.567.661-1.154 1.345-1.736 1.515h-.001c-.58-.169-1.168-.854-1.772-1.557-.315-.364-.621-.712-.895-.962a1.995 1.995 0 00-1.5-.52c-12.5.937-22.209 5.138-26.012 11.306-.591 1.046-14.342 25.777-2.999 40.164.379.482.958.763 1.57.763h63.215c.612 0 1.191-.28 1.57-.763 11.484-14.564-2.892-39.974-3.07-40.283zM52.241 81.893a1.49 1.49 0 01-.179.67l-2.179 4.053c-.262.486-.771.791-1.321.791s-1.06-.305-1.32-.791l-2.18-4.053a1.515 1.515 0 01-.178-.67l-.772-29.066a1.504 1.504 0 01.614-1.252l2.95-2.153a1.5 1.5 0 011.769 0l2.952 2.153a1.5 1.5 0 01.615 1.252l-.771 29.066zM48.214 42.398c11.688 0 21.198-9.511 21.198-21.2C69.412 9.51 59.901 0 48.214 0c-11.689 0-21.2 9.51-21.2 21.198 0 11.689 9.51 21.2 21.2 21.2z"/>'
  }
})
