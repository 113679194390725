<template>
	<div>
		<top-card :help_text="help_text" :global="global">
			<b-col cols="6" md="4" lg="3" xl="2" class="ml-auto">				
				<n-button type="primary" size="md" round block :disabled="validation_errors.length > 0" v-b-modal.confirm-update :visible_for_permissions='[{actions: ["PUT"], path: "/subsystems/cb/advanced-configuration"}]'>
                {{ t("Save") }}
				</n-button>
			</b-col>
		</top-card>
		<services :global="global"/>
		<filters :services="Object.keys(filterConfigurations)" :global="global"/>
		<div class="grid-container">
			<template v-for="service in sorted_configurations">
				<service-settings
					v-if="showService(configurations[service])"
					:global="global"
					:settings="configurations[service]"
					:jsonschemaName="jsonSchema"
					:disable="disable(service)"
					:title="service"
					:settingGroup="service"
					:configurationType="'advanced_configurations'"
					:key="service"/>
			</template>
		</div>

		<b-modal id="confirm-update" @ok="setConfigurations()" :ok-title="t('accept')" :cancel-title="t('cancel')" :title="t('Save the changes')">
			{{t('Are you sure want to save?')}}
        </b-modal>
	</div>
</template>

<script>
import Services 		    from '@/components/AsmConfigurations/general/Services';
import ServiceSettings  from '@/components/AsmConfigurations/general/ServiceSettings';
import TopCard    		  from '@/components/AsmConfigurations/TopCard';
import Filters    		  from '@/components/AsmConfigurations/filters';
import schemaValidation from '@/mixins/schemaValidation';

import { mapState, mapGetters, mapActions, mapMutations }   from 'vuex';
import _ from 'lodash';

export default
{
	mixins: [schemaValidation],
	components: {
		Services,
		Filters,
		TopCard,
		ServiceSettings,
    },
    props: {
        help_text: {
            type: String,
            default: ''
        },
		global: {
			type: Boolean,
			default: false
		}
	},
	data(){
		return {
			jsonSchema:null,
			configurationType:null
		}
	},
	created() {
		this.jsonSchema = this.global ? 'ISPServiceConfiguration' : 'service_subsystem_advanced_configuration_CB';
		this.configurationType = this.global ? 'global_configurations' : 'advanced_configurations';
		this.refresh(this.global);
	},
	beforeDestroy(){
		this.refresh(this.global)
	},
	computed: {
		...mapState('settings',['subsystems', 'subsystemsDict']),
		...mapGetters('ispServices/services', ['filter_by_service', 'filter_by_product', 'global_configurations', 'advanced_configurations']),

        configurations() {
			return this.global ? this.global_configurations : this.advanced_configurations;
        },
        filterConfigurations() {
			let config = this.global ? this.global_configurations : this.advanced_configurations;
			try {
				let filtConfig = Object.fromEntries(Object.entries(config).filter((con) => Object.keys(con[1]).length > 1));
				return filtConfig;
			} catch (error) {
				return config;
			}
        },

		configs() {
			if(this.filter_by_service) {
				return { [this.filter_by_service] : this.configurations[this.filter_by_service] };
			}

			const filter_by_product = _.get(this, 'filter_by_product')

			if(filter_by_product) {
				const subsystems = this.subsystems;

				return _.pickBy(this.configurations, function(value, key) {
					let answer = false;

					subsystems.forEach(element => {
						if(element.service_id == key && element.subsystems[0].subsystem_id == filter_by_product) {
							answer = true;
						}
					});

					return answer;
				});
			}

			return this.configurations;
		},
		sorted_configurations(){
			return Object.keys(this.configs).sort(( keyA, keyB ) => Object.keys(this.configs[keyB]).length - Object.keys(this.configs[keyA]).length)
		}
	},
	methods: {
		...mapActions('ispServices/services', ['setGlobalConfigurations', 'setAdvancedConfigurations']),
    ...mapActions('settings', ['setIsFormDirty']),
		...mapMutations('ispServices/services', ['refresh']),

		setConfigurations() {
      this.setIsFormDirty(false);
			this.global ? this.setGlobalConfigurations(this.global_configurations) : this.setAdvancedConfigurations(this.advanced_configurations);
		},

		disable(service) {
			const disabled = ! _.get(this.global_configurations, `${this.subsystemsDict[service]}.enabled`, true);
			return disabled ? disabled : _.has(this.configurations[service], 'enabled') ? ! this.configurations[service].enabled : false;
		},

		showService(service) {
			return (Object.keys(service).length > 1 && _.has(service, 'enabled')) || (Object.keys(service).length > 0 && !_.has(service, 'enabled'));
		}
	}
}
</script>

<style scoped lang="scss">
  .grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    column-gap: 5px;
    row-gap: 10px;
  }
</style>
