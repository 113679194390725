<template functional>
    <span>
        <template v-if="props.row[props.column.prop].tooltip">
            <el-tooltip :content="props.row[props.column.prop].tooltip" :open-delay="250" >
                <i v-if="props.row[props.column.prop].icon" :class="props.row[props.column.prop].icon"></i> 
                <span :style="{ whiteSpace: 'nowrap', color: `${props.row[props.column.prop].textColor}` }">
                  {{ props.row[props.column.prop].name }}
                </span>
                <ion-icon class="iconify"
                    :data-icon="props.row[props.column.prop].icon"
                    :data-width="props.row[props.column.prop].size || '46'" 
                    :data-height="props.row[props.column.prop].size || '46'"   
                    :name="props.row[props.column.prop].icon"
                />
            </el-tooltip>
        </template>
        <template v-else>
            <i v-if="props.row[props.column.prop].icon" :class="props.row[props.column.prop].icon"></i> 
            <ion-icon class="iconify"
              :data-icon="props.row[props.column.prop].icon"
              :data-width="props.row[props.column.prop].size || '46'" 
              :data-height="props.row[props.column.prop].size || '46'"   
              :name="props.row[props.column.prop].icon"
             />
            <span :style="{ whiteSpace: 'nowrap', color: `${props.row[props.column.prop].textColor}` }">
              {{ props.row[props.column.prop].name }}
            </span>
        </template>
    </span>
</template>

<script>
import   {Iconify} from '@/components'
export default {
    name: 'namewith-iconify-formatter',
     components: {
       [Iconify.name]: Iconify
    },
}
</script>
<style lang="scss" scoped>


 .iconify {
    vertical-align: middle !important;
    margin-right: 1em;
    color: #4cb18e;
 }


</style> 
