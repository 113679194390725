import _ from 'lodash';

export const createAdministratorFunction = (item) => {
  const identity_instance_type = _.get(item, 'identity.identity_instances[0].identity_instance_type', "IdentityInstanceDatabase");
  const identity_provider_type = _.get(item, 'identity.identity_instances[0].identity_provider_type', "IdentityProviderDatabase");

  try {
    const user_id = item.identity.identity_instances[0].user_id

    if (!item.manager_metadata)
      item.manager_metadata = {};
    if (!item.manager_metadata.picture)
      item.manager_metadata.picture = {};

    const admin = {
      id: item.manager_id,
      data: {
        manager_id: item.manager_id,
        account_id: item.account_id,
        identity: {
          phone_number: item.identity.phone_number,
          blocked: item.identity.blocked || false,
          created_at: item.identity.created_at,
          email: item.identity.email,
          identity_instances: {
            identity_instance_type: identity_instance_type,
            identity_provider_type: identity_provider_type,
            user_id: user_id,
          },
          name: item.identity.name || item.identity.identity_instances[0].user_id,
          last_ip: item.identity.last_ip,
          last_login: item.identity.last_login,
          updated_at: item.identity.updated_at
        },
        manager_metadata: {
          language_id: item.manager_metadata.language_id || 'en-US',
          time_zone: item.manager_metadata.time_zone || 'America/New_York',
          picture: {
            format: item.manager_metadata.picture.format || 'no_picture',
            storage_method: item.manager_metadata.picture.storage_method || 'no_picture',
            picture_base64: item.manager_metadata.picture.picture_base64 || '',
            picture_url: item.manager_metadata.picture.picture_url || ''
          }
        },
        roles: item.roles ? item.roles : []
      }
    }
    return admin
  } catch {
    console.error("user_id not found for manager " + item.manager_id)
  }
}

export const mockAdministrator = {
  identity: {
    email: "user_sys_jp@domain.com",
    name: "User Sys JP",
    identity_instances: {
      identity_instance_type: "IdentityInstanceDatabase",
      user_id: "user_sys_jp"
    },
    manager_metadata: {
      language_id: 'en-US',
      picture: {
        format: 'no_picture',
        storage_method: 'no_picture'
      }
    }
  },
  manager_id: "5e56332d8736dc0bd18f7645",
}