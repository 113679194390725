<template>
  <n-switch id='switchFormater'
            v-model="row[column.prop]"
            @input='handleChange()'
            :visible_for_permissions='column.visible_for_permissions ? column.visible_for_permissions : []'
            :disabled="disabled"
  />
</template>
<script>
  import Switch from "../../Switch";
export default {
  name: 'switch-formatter',
  components:{
    'n-switch': Switch
  },
  props: {
    row: {
      type: Object,
      default: () => {}
    },
    column: {
      type: Object,
      default: () => {}
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    handleChange() {
      this.$emit('input', this.row);
    }
  }
}
</script>
