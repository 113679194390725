import SideBar from "@/components/SidebarPlugin";
import Notify from "@/components/NotificationPlugin";
import GlobalComponents from "./globalComponents";
import GlobalDirectives from "./globalDirectives";
import RTLPlugin from "./RTLPlugin";

export const SCSS_BASE_ROUTE = process.env.VUE_APP_CUSTOMIZATION || 'allot'

//css assets
import "@/assets/sass/globalStyles.scss";
//import("@/assets/sass/" + SCSS_BASE_ROUTE + ".scss");
import "@/assets/css/nucleo-icons.css";
import "@/assets/css/now-ui-icons.css";
import "@/assets/css/isp-icons.css";

export default {
  install(Vue) {
    Vue.use(GlobalComponents);
    Vue.use(GlobalDirectives);
    Vue.use(SideBar);
    Vue.use(Notify);
    Vue.use(RTLPlugin);
  }
}
