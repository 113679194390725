/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'item-configuration': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M15.9 14.7l-.7-.3-.9-.4-.8.6c-.4.3-.7.5-1.1.6l-.9.4-.1 1-.2 1.2H10l-.2-1.2-.1-1-.9-.4c-.4-.2-.7-.4-1.1-.6l-.7-.5-.9.4-1.1.4-.6-1 .9-.7.8-.6-.1-1V11v-.6l.1-1-.8-.7-.9-.7.6-1 1.1.4.9.3.8-.6c.4-.3.7-.5 1.1-.6l.9-.4.1-1 .2-1.1h1.2l.2 1.2.1 1 .9.4c.4.2.7.4 1.1.6l.8.6.9-.4 1.1-.4.6 1-1 .7-.8.6.1 1v1.2l-.1 1 .8.6.5.4c.4-.5.8-.9 1.4-1.1l-.8-.6c0-.3.1-.6.1-.8 0-.3 0-.6-.1-.8l1.8-1.4c.2-.1.2-.4.1-.6l-1.7-3c-.1-.1-.2-.2-.4-.2h-.1l-2.2.9c-.4-.3-.9-.6-1.5-.8l-.3-2.3c0-.2-.2-.4-.4-.4H9c-.2 0-.4.2-.4.4l-.4 2.1c-.5.2-1 .5-1.5.8l-2.1-.8h-.2c-.1 0-.3.1-.3.2l-1.7 3c-.2.2-.1.4 0 .5L4.2 10c0 .3-.1.6-.1.8 0 .3 0 .6.1.8L2.4 13c-.2.1-.2.4-.1.6l1.7 3c.1.3.3.4.4.4h.1l2.2-.9c.4.3.9.6 1.5.8l.3 2.3c0 .2.2.4.4.4h3.5c.2 0 .4-.2.4-.4l.3-2.3c.5-.2 1-.5 1.5-.8l1.1.4c0-.6 0-1.2.2-1.8z" _fill="#383132"/><path pid="1" d="M10.7 7.5C8.8 7.5 7.2 9 7.2 11c0 1.9 1.5 3.5 3.5 3.5 1.9 0 3.5-1.5 3.5-3.5-.1-2-1.6-3.5-3.5-3.5zm0 5.1c-1 0-1.7-.8-1.7-1.7s.8-1.7 1.7-1.7 1.7.8 1.7 1.7-.8 1.7-1.7 1.7zM12.5 21l4.2-4.2c-.4-1.1-.2-2.3.7-3.2.9-.9 2.3-1.1 3.4-.6l-2 2 1.4 1.4 2-2c.6 1.1.3 2.5-.6 3.4s-2.1 1.1-3.2.7l-4.2 4.2c-.2.2-.5.2-.6 0l-1.1-1.1c-.2-.1-.2-.4 0-.6z" _fill="#383132"/>'
  }
})
