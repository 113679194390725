/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'item-blocking': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g _fill="#383132"><path pid="0" d="M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10 10-4.5 10-10S17.5 2 12 2zM4 12c0-4.4 3.6-8 8-8 1.9 0 3.6.6 4.9 1.7L5.7 16.9C4.6 15.6 4 13.9 4 12zm8 8c-1.9 0-3.6-.6-4.9-1.7L18.3 7.1c1.1 1.3 1.7 3 1.7 4.9 0 4.4-3.6 8-8 8z"/><path pid="1" d="M12 22.5C6.2 22.5 1.5 17.8 1.5 12S6.2 1.5 12 1.5 22.5 6.2 22.5 12 17.8 22.5 12 22.5zm0-20c-5.2 0-9.5 4.3-9.5 9.5s4.3 9.5 9.5 9.5 9.5-4.3 9.5-9.5-4.3-9.5-9.5-9.5zm0 18c-1.9 0-3.7-.6-5.2-1.8l-.4-.3 12-12 .3.4c1.2 1.5 1.8 3.3 1.8 5.2 0 4.7-3.8 8.5-8.5 8.5zm-4.1-2.2c1.2.8 2.6 1.2 4.1 1.2 4.1 0 7.5-3.4 7.5-7.5 0-1.5-.4-2.9-1.2-4.1L7.9 18.3zm-2.3-.6l-.3-.4c-1.2-1.5-1.8-3.3-1.8-5.2 0-4.7 3.8-8.5 8.5-8.5 1.9 0 3.7.6 5.2 1.8l.4.3-12 12zM12 4.5c-4.1 0-7.5 3.4-7.5 7.5 0 1.5.4 2.9 1.2 4.1L16.1 5.8c-1.2-.9-2.6-1.3-4.1-1.3z"/></g>'
  }
})
