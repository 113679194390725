import _ from 'lodash';
import axios from "axios";
import i18n from '../../../i18n';
import { createAdmin, mockAccount, mockAccount2 } from './mockData';

export default {
  getAccounts( {commit, state, rootState, dispatch, getters}, { wait: wait, query: query}) {
    if (!rootState.settings.debug){
      let config = { params: query }
      if ( wait)
        config.headers = {'x-wait-on-loading': wait}

      return new Promise((resolve, reject) => {
        axios.get('/accounts', config )
          .then(res => {
            if ( res && res.data && res.data.items){
              state.accounts.clear()
              res.data.items.forEach( item => {
                if ( !item.products){
                  item.products = {}
                }
                const admin = createAdmin(item)
                commit('addAccount', admin)
              })
              let ret = { items: state.accounts, query: res.data.query, self: query}
              resolve(ret)
            }
            else{
              reject()
            }
          })
          .catch(error => {
            commit('setError', error, {root: true});
            reject()
          })
      })
    }
    else{
      return new Promise((resolve, reject) => {
        let account = mockAccount
        commit('addAccount', { id: account.account_id, data: account})
        resolve( { query: null, items: [account]})
      })
    }
  },
  removeAccount ({commit, state, rootState, dispatch}, id) {
    if (rootState.settings.debug){
      return new Promise((resolve, reject) => {
        commit('removeAccount', id)
        resolve()
      });
    }
    else{
      return new Promise((resolve, reject) => {
        axios.delete('/accounts/' + encodeURIComponent(id))
          .then(res => {
            commit('removeAccount', id);
            commit("setSuccess", i18n.t('Account') + " " + id  + " " +  i18n.t('removed') + "!", {root: true});
            resolve(state.accounts);
          })
          .catch(error => {
            commit('setError', error, {root: true});
            reject()
          })
      });
    }
  },
  getAccount ({commit, state, rootState, dispatch}, id) {
    if( rootState.settings.debug ){
      return new Promise((resolve, reject) => {
        let account = mockAccount2
        commit('addAccount', { id: account.account_id, data: account})
        resolve(account)
      })
    }
    else{
      return new Promise((resolve, reject) => {
        axios.get('/accounts/' + encodeURIComponent(id))
          .then(res => {
            if ( res && res.data){
              let item = res.data
              if ( !item.account_type)
                item.account_type = 'residential'
              if ( !item.products){
                item.products = {}
              }
              const admin = createAdmin(item)  
              commit('addAccount', admin)
              resolve(admin.data)
            }
            else{
              reject()
            }
          })
          .catch(error => {
            commit('setError', error, {root: true});
            reject()
          })
      });
    }
  },

  createAccount( {commit, state, dispatch}, { account_id, account_type, products, services, profiles, blockingpages }) {
    let prof = {};
    if (account_type == "enterprise_groups") {
      prof = { profiles_definition: [],}
    } else {
      prof = profiles
    }
    let data = {
      account_id,
      account_type,
      profiles : prof,
      services_provisioned: services,
      products      
    };

    if( blockingpages !== null ) 
      data["service_configuration"] = blockingpages;
    else
      data["service_configuration"] = {};

    return new Promise((resolve, reject) => {
      axios.post('/accounts', data)
        .then(res => {
          if ( res && res.data){
            let item = res.data

            if ( !item.profiles)
              item.profiles = { profile_id_default: '', profiles_definition: []}

            let account = {
              id: item.account_id,
              data: item
            }
            commit('addAccount', account);
            resolve(account.id);
          }
        })
        .catch(error => {
          if (error.response && error.response.data) {
          reject(error.response.data);
      }
        })
    });
  },
  updateAccount( {commit, state, dispatch}, update) {
    commit("SET_TOGGLE_DISABLED", true)
    return new Promise((resolve, reject) => {
      let prev = null
      let account = state.accounts.get(update.id)
      if ( account){
        prev = _.cloneDeep(account)
      }
      commit('setAccount', {id: update.id, data: update.data});

      axios.patch('/accounts/' + encodeURIComponent(update.id), update.data)
        .then(() => {
          commit("SET_TOGGLE_DISABLED", false)
          commit("setSuccess", i18n.t('Account') + " " + update.id  + " " +  i18n.t('updated') + "!", {root: true});
          resolve()
        })
        .catch(error => {
          commit("SET_TOGGLE_DISABLED", false)
          const errorType = _.get(error, "response.data.type")
          if(errorType === "ACCOUNT.PROFILE_IN_USE"){
            error = i18n.t(errorType)
          }          
          commit('setError', error, {root: true});
          commit('setAccount', {id: update.id, data: update.data});
          reject()
        })
    });
  },
}