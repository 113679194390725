<template>
  <div class="lettercheckbox"
       :style="style"
       @click="updateValue">
    <input 
          type="checkbox"
          :disabled="disabled"
          :checked="checked"
          >
    <table>
      <tbody>
        <tr>
          <td >{{initials}}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
export default {
  name: 'letter-checkbox',
  model: {
    prop: 'checked'
  },
  props: {
    checked: { type: Boolean, default: false},
    title: String,
    disabled: Boolean,
    size: { type: Number, default: 48 },
    color: { type: String, default: '#d3d3d3' },
    bgcolor: String,
  },
  computed: {
      // compute initials from full title
      initials () {
        var words = this.title.split(/[\s-]+/)
        var intls = ''
        for (var i = 0; i < words.length; i++) {
          intls += words[i].substr(0, 1).toUpperCase()
        }
        if (intls.length > 3) {
          intls = intls.substr(0, 3)
        }
        return intls
      },
      style () {
        var fontSize = this.initials.length > 2 ? this.size / 3 : this.size / 2
        let bg = this.bgcolor
        if (!bg || this.color === bg )
          bg = this.invertHex(this.color);

        return {
          'width': this.size + 'px',
          'height': this.size + 'px',
          'font-size': fontSize + 'px',
          'background-color': this.checked ? this.color : bg,
          'color': this.checked ? bg : this.color
        }
      }
  },
  methods: {
    updateValue() {
      this.$emit('input', !this.checked);
    },
    invertHex(hex) {
      if (hex.indexOf('#') === 0)
        hex = hex.slice(1);
    
      return '#' + (Number(`0x1${hex}`) ^ 0xFFFFFF).toString(16).substr(1).toUpperCase()
    }
  },
};
</script>
<style scoped>
input[type="checkbox"]{
    position: absolute;
    left: -10000px;
    z-index: -1;
}

.lettercheckbox {
    display: inline-block;
    background-color: black;
    color: white;
    width: 48px;
    height: 48px;
    font-size: 12px;
    border-radius: 50%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-image: none;
    cursor: pointer;
  }
  .lettercheckbox table {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
  }
  .lettercheckbox table td{
    text-align: center;
    vertical-align: middle;
      -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
  }
</style>
