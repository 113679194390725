import * as queries from './queries';

export async function getAccountGroups({ dispatch, commit }, account_id) {
    try {
      const { items } = await queries.getAccountGroups(account_id);
      commit('SET_ACCOUNT_GROUPS', items);
    } catch (err) {
      dispatch('setError', err, { root: true });
    }
}
export function getGroups({ dispatch, commit }, {account_id: account_id, query: query}) {
  return queries.getGroups(account_id, query, dispatch, commit);
}
