/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'protocols': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path pid="0" d="M14.42.476a1.737 1.737 0 012.388 0c.75.708 1.543 1.37 2.374 1.982a11.274 11.274 0 003.328 1.824 1.448 1.448 0 01-.767 2.793c-1.57-.501-3.04-1.275-4.341-2.287-.724-.492-1.36-1.027-1.853-1.447-.492.39-1.129.912-1.852 1.447a14.472 14.472 0 01-4.255 2.287 14.168 14.168 0 01-4.342.39c-.723-.028-1.404-.144-1.953-.144-.087.883-.218 2.07-.218 3.343-.106 1.868 0 3.742.319 5.586a16.903 16.903 0 005.05 7.482 46.658 46.658 0 006.614 5.196c.388.269.901.269 1.288 0a47.758 47.758 0 006.252-5.24 17.468 17.468 0 004.762-7.409 1.447 1.447 0 011.736-1.07 1.447 1.447 0 011.086 1.736 20.174 20.174 0 01-5.529 8.77A51.694 51.694 0 0117.88 31.3a4.067 4.067 0 01-4.559 0 49.654 49.654 0 01-7.019-5.514 19.726 19.726 0 01-5.86-8.871 25.529 25.529 0 01-.391-6.31c.057-2.127.246-4.023.318-4.747a1.737 1.737 0 011.94-1.548c.959.138 1.925.225 2.894.26 1.165.09 2.336-.003 3.473-.275a11.81 11.81 0 003.357-1.838A28.785 28.785 0 0014.42.476zm14.458 4.153a1.449 1.449 0 011.983 2.113l-10.594 9.942a1.722 1.722 0 01-2.402-.159l-4.342-3.864a1.456 1.456 0 011.925-2.185l3.763 3.27z" _fill="#0E1317" fill-rule="evenodd"/>'
  }
})
