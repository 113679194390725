<template>
  <div>
    <b-alert
      class="floating"
      :variant="type"
      dismissible
      fade
      :show="dismissCountDown"
      @dismissed="dismissCountDown = 0"
      @dismiss-count-down="countDownChanged"
    >
      {{ t(message) }}
      <b-progress
        :variant="type"
        :max="dismissSecs"
        striped
        :animated="true"
        :value="dismissCountDown"
        height="0.5rem"
      />
    </b-alert>

    <b-modal
      id="formAlertModal"
      ref="formAlertModal"
      @close="onCancel"
      @ok="onCancel"
      @cancel="onDiscard"
      :title="t('discard_changes_title')"
      :ok-title="t('keep_editing')"
      :cancel-title="t('discard')"
    >
      {{ t('discard_changes_content') }}
    </b-modal>

    <b-modal
      id="formReauthenticationModal"
      ref="formReauthenticationModal"
      :no-close-on-backdrop="true"
      @close="onDiscardReauth"
      @ok="onOkReauth"
      @cancel="onDiscardReauth"
      :title="t('reauthentication_needed_title')"
      :ok-title="t('confirm_password')"
      :cancel-title="t('cancel')"
    >
      <card class="card-login card-plain">
        <div slot="header">
          <p>{{ t('Please confirm your password before continuing') }}</p>
        </div>

        <form ref="form" @submit.stop.prevent="onSubmitReauth">
          <login-password v-model="model_auth" :selectedProvider="providerAccessType" />
        </form>
      </card>
    </b-modal>

    <loading :loading="loading" />
    <iframe id="topFrame" style="display: none" />
    <notifications></notifications>

    <router-view :key="$route.fullPath" class="absolute"></router-view>
  </div>
</template>

<script>

import Loading from "@/components/Loading";
import { mapGetters, mapState } from "vuex";
import "@/assets/sass/common.scss";
import es from "./locales-validation/es";
import en from "./locales-validation/en";
import GeneralMixin from "@/components/AsmConfigurations/general/GeneralMixin";
import LoginPassword from "./pages/Login/LoginPassword.vue";

export default {
  mixins: [GeneralMixin],
  components: {
    Loading,
    'login-password': LoginPassword
  },
  name: 'app',
  data() {
    return {
      htmopt_frame:
        '<!DOCTYPE html><html lang="en"><head></head><body><form id="frmCommon"><input type="hidden" id="h_email" name="h_email"/>' +
        '<input type="hidden" id="h_mailbox" name="h_mailbox"/></form></body></html>',
      dismissSecs: 5,
      dismissCountDown: 0,
      type: "danger",
      message: "",
      es: es,
      en: en,
      model_auth : {
        password: '',
        captcha: '',
        captcha_info: '',
        provider: '',
        recoveryLinksDisabled: false,
        valid: false
      }
    };
  },
  methods: {
    changeRoute() {
      if (!this.$rtl.isRTL) {
        this.$rtl.disableRTL();
      }
    },
    toggleNavOpen() {
      let root = document.getElementsByTagName("html")[0];
      root.classList.toggle("nav-open");
    },
    toggleDarkmode() {
      let root = document.getElementsByTagName("html")[0];
      root.classList.toggle("white-content");
    },
    setLanguage(language) {
      try {
        this.$validator.localize(language, eval("this." + language));
      } catch (error) {
        this.$validator.localize("en", en);
      }
      this.$store.dispatch("settings/setLanguage", language);
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
      if (this.dismissCountDown == 0) {
        if (this.type == "success") this.$store.commit("removeSuccess");
        else if (this.type == "danger") this.$store.commit("removeError");
      }
    },
    setLayout(event) {
      this.$store.commit("settings/setMobile", screen.width < 768);
      this.$store.commit("settings/setDesktop", screen.width >= 1200);
    },
    checkIfPreventUserFromRefreshing(currRoute) {
      const showAlertFormModal = this.$store.state.settings.isFormDirty;
      if (showAlertFormModal) {
        window.onbeforeunload = function () {
          return "you can not refresh the page";
        };
      } else {
        window.onbeforeunload = null;
      }
    },
    onCancel() {
      this.$store.commit({ type: "settings/toggleFormAlertModal", toggle: false });
    },
    onDiscard() {
      this.$store.commit({
        type: "settings/toggleFormAlertModal",
        toggle: false,
        discard: true,
      });
      this.$router.push({ path: this.nextRoutAfterFormAlertModal.path });
    },
    onDiscardReauth() {
      this.$store.commit({ type: "auth/toggleReauthenticationModal", toggle: false});
    },
    onOkReauth(event){
      event.preventDefault()
      this.onSubmitReauth()
    },
    async onSubmitReauth(){
      if (this.model_auth.valid){
        const payload = {
          username: this.id,
          password: this.model_auth.password,
          captcha: {
            captcha_response: this.model_auth.captcha,
            captcha_hash: this.model_auth.captcha_info.captcha_hash,
          },
          identity_provider_id: this.model_auth.provider,
          privileges: this.privileges_requested
        };
        this.$store.dispatch('auth/login', payload)
          .then( () => {
            this.$store.commit({ type: "auth/toggleReauthenticationModal", toggle: false});
          })
          .catch( err => {
            if( err && err.refreshCaptcha){
              this.$store.dispatch('auth/getCaptcha', {});
              this.model_auth.captcha = ''
            }
          });        
      }
    }

  },
  computed: {
    ...mapState("settings",["showFormAlertModal", "nextRoutAfterFormAlertModal"]),
    ...mapGetters('auth', ['id', 'providerAccessType', 'showReauthenticationModal', 'privileges_requested']),
    error: function () {
      return this.$store.getters.error;
    },
    success: function () {
      return this.$store.getters.success;
    },
    loading: function () {
      return this.$store.getters['settings/loading'];
    },
  },
  watch: {
    "$store.state.settings.isFormDirty": function (val) {
      this.checkIfPreventUserFromRefreshing(this.$route);
    },
    showFormAlertModal (newVal, oldVal) {
      if (newVal) {
        this.$refs.formAlertModal.show();
      }
    },
    showReauthenticationModal(newVal, oldVal) {
      if (newVal){
        this.model_auth.password = ''
        this.model_auth.captcha = ''
        this.$refs.formReauthenticationModal.show()
      }
      else{
        this.$refs.formReauthenticationModal.hide()
      }
    },
    error(newError, oldError) {
      if (newError != null) {
        this.type = "danger";
        this.message = newError;
        if (newError) this.dismissCountDown = this.dismissSecs;
      }
    },
    success(newSuccess, oldSuccess) {
      if (newSuccess != null) {
        this.type = "success";
        this.message = newSuccess;
        this.dismissCountDown = this.dismissSecs;
      }
    },
  },
  created() {
    /*let language = navigator.language || navigator.userLanguage;
    if (language.indexOf("-") > 0) language = language.split("-")[0];
    //this.setLanguage(language)
    this.setLanguage("en");*/

    this.setLanguage(this.$store.getters['settings/getLanguage']);

    this.$store.dispatch("customProvider/getConfiguration");
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.setLayout);
  },
  mounted() {
    this.$watch("$route", this.changeRoute, { immediate: true });
    this.$watch("$sidebar.showSidebar", this.toggleNavOpen);
    this.$watch("$store.state.settings.darkmode", this.toggleDarkmode);

    this.$store.dispatch("settings/retrieveDarkMode");

    this.setLayout();

    this.$nextTick(() => {
      window.addEventListener("resize", this.setLayout);
    });
  },
};
</script>
