import status from './status';
import contentFilter from './contentFilter';
import antiPhishing from './antiPhishing';
import troubleshooting from './troubleshooting';
import allowedLists from './allowedLists';
import agentManagement from './agentManagement'
import defaultAgentVersion from './defaultAgentVersion';
import devices from './devices';

export default {
  namespaced: true,
  modules: {
    status,
    contentFilter,
    antiPhishing,
    troubleshooting,
    allowedLists,
    agentManagement,
    defaultAgentVersion,
    devices
  }
}
