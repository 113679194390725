import _ from 'lodash';

export default {
    addAccountUser(state, user){
        state.account_users.set(user.id, user.data);
        state.account_users = new Map(state.account_users);
      },    
      setAccountUser(state, user){
        let usr = state.account_users.get(user.id)
        _.merge(usr, user.data)
        state.account_users = new Map(state.account_users);
      },
      removeAccountUser(state, id){
        if (id){
          state.account_users.delete(id);
          state.account_users = new Map(state.account_users);
        }
      },  

      addUser(state, user){
        state.users.set(user.id, user.data);
        state.users = new Map(state.users);
      },    
      removeUser(state, id){
        if (id){
          state.users.delete(id);
          state.users = new Map(state.users);
        }
      }, 
}