import VueRouter from "vue-router";
import routes from "./routes";
import { store } from '@/store/index';

const router = new VueRouter({
  routes,
  linkExactActiveClass: "active",
  scrollBehavior: (to) => {
    if (to.hash) {
      return { selector: to.hash }
    }
    else {
      return { x: 0, y: 0 }
    }
  }
})

router.beforeEach((to, from, next) => {
  const showAlertFormModal =  store.state.settings.isFormDirty

  if (showAlertFormModal) {
    store.commit({ type: 'settings/toggleFormAlertModal', toggle: true });
    store.commit({ type: 'settings/setNextRouteAfterFormAlertModal', route: to });
  }
  else {
    next();
  }
})

export default router;