/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'schedule-button': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path pid="0" d="M24.727 1c.804 0 1.455.651 1.455 1.455v1.454h1.454A4.364 4.364 0 0132 8.273v17.454a4.364 4.364 0 01-4.364 4.364H4.364A4.364 4.364 0 010 25.727V8.273a4.364 4.364 0 014.364-4.364h1.454V2.455a1.455 1.455 0 112.91 0v1.454h14.545V2.455c0-.804.65-1.455 1.454-1.455zm4.364 11.636H2.909v13.091c0 .804.651 1.455 1.455 1.455h23.272c.804 0 1.455-.651 1.455-1.455v-13.09zm-19.71 2.91a.8.8 0 01.8.8v2.763a.8.8 0 01-.8.8H6.619a.8.8 0 01-.8-.8v-2.764a.8.8 0 01.8-.8h2.764zm7.274 0a.8.8 0 01.8.8v2.763a.8.8 0 01-.8.8H13.89a.8.8 0 01-.8-.8v-2.764a.8.8 0 01.8-.8h2.764zm10.981-8.728H4.364c-.804 0-1.455.651-1.455 1.455v1.454h26.182V8.273c0-.804-.651-1.455-1.455-1.455z" _fill="#0E1317" fill-rule="evenodd"/>'
  }
})
