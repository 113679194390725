import axios from 'axios';

import {
  REPORTS,
  GUI_MODES,
  ADMIN_REPORTS,
  HISTORIC,
} from '../endpoints';

export async function getAdminReportList() {
  const { data } = await axios
    .get(`/${REPORTS}/${ADMIN_REPORTS}`);

  return data;
}

export async function updateAdminReport(adminReportId, adminReport) {
  const { data } = await axios
    .put(
      `/${REPORTS}/${ADMIN_REPORTS}/${adminReportId}`,
      adminReport,
    );

  return data;
}

export async function patchAdminReport(adminReportId, adminReport) {
  const { data } = await axios
    .patch(
      `/${REPORTS}/${ADMIN_REPORTS}/${adminReportId}`,
      adminReport,
    );

  return data;
}

export async function deleteAdminReport(adminReportId) {
  const { data } = await axios
    .delete(`/${REPORTS}/${ADMIN_REPORTS}/${adminReportId}`);

  return data;
}

export async function getAdminReportById(adminReportId) {
  const { data } = await axios
    .get(`/${REPORTS}/${ADMIN_REPORTS}/${adminReportId}`);

  return data;
}

export async function createAdminReport(adminReport) {
  const { data } = await axios
    .post(`/${REPORTS}/${ADMIN_REPORTS}`, adminReport);

  return data;
}

export async function getGUIModes() {
  const { data } = await axios.get(`/${REPORTS}/${GUI_MODES}`);

  return data;
}

export async function getReportView(GUIModeId, reportSettings, token) {
  const { data } = await axios.create({
    headers: {
      post: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`,
      }
    },
  }).post(`/${REPORTS}/${GUI_MODES}/${GUIModeId}`, reportSettings);

  return data;
};

export async function getReportPdf(GUIModeId, reportSettings, token) {
  const { data } = await axios.create({
    headers: {
      post: {
        'Accept': 'application/pdf',
        'Authorization': `Bearer ${token}`,
      }
    },
    responseType: 'blob',
  }).post(`/${REPORTS}/${GUI_MODES}/${GUIModeId}`, reportSettings);

  return data;
};

export async function getReportCsv(GUIModeId, reportSettings, token) {
  const { data } = await axios.create({
    headers: {
      post: {
        'Accept': 'text/csv',
        'Authorization': `Bearer ${token}`,
      }
    },
  }).post(`/${REPORTS}/${GUI_MODES}/${GUIModeId}`, reportSettings);

  return data;
};

export async function getReport(GUIModeId, reportSettings, token, type) {
  var requestHeaders = {};
  requestHeaders.headers = {};
  if (type == 'pdf') {
    requestHeaders.headers.post = {
      'Accept': 'application/pdf',
      'Authorization': `Bearer ${token}`
    };
    requestHeaders.responseType = 'blob';
  } else if (type == 'csv') {
    requestHeaders.headers.post = {
      'Accept': 'text/csv',
      'Authorization': `Bearer ${token}`
    };
  }
  const { data } = await axios.create(requestHeaders).post(`/${REPORTS}/${GUI_MODES}/${GUIModeId}`, reportSettings);
  return data;
};

export async function getAdminReportHistoricList(adminReportId, GUIModeId) {
   const { data } = await axios
     .get(`/${REPORTS}/${ADMIN_REPORTS}/${GUIModeId}-${adminReportId}/${HISTORIC}`);

  return data;
}

export async function getAdminReportHistoricDetails(adminReportId, reportName, GUIModeId) {
  const { data } = await axios
    .get(`/${REPORTS}/${ADMIN_REPORTS}/${GUIModeId}-${adminReportId}/${HISTORIC}/${reportName}`);
  
  return data;
}
