export default () => ({
    current_service_name: null,
    params_self_query: {
        allow: null,
        block: null,
    },
    params_query: {
        allow: {
            sort: 'asc',
            limit: 10
        },
        block: {
            sort: 'asc',
            limit: 10
        }
    },
    forbiddenAllowedList: {
        black_list: [],
        white_list: [],
    },
    currentInternalCategoryId: null
})