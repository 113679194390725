import DashboardLayout from "@/layout/dashboard/DashboardLayout.vue";
import { store } from "@/store";

const DownloadDetails = () => import(
  /* webpackChunkName: "download-details" */
  "@/pages/EndpointSecure/DownloadDetails/DownloadDetails.vue"
);


export default  {
  path: "/eps",
  component: DashboardLayout,
  redirect: "/eps/download_details",
  children: [
    {
      path: "download_details",
      name: "EndpointSecure / Download Details",
      component: DownloadDetails,
    },
  ]
};
