import axios from "axios";

export default {
    getScheduledTasks({ commit, state, dispatch }, { wait: wait, query: query }) {
      let config = { params: query }
      if (wait)
        config.headers = { 'x-wait-on-loading': wait }

      return new Promise((resolve, reject) => {
        axios.get(`/subsystems/ns/scheduled-tasks`, config)
          .then(res => {
            const data = res.data;
            commit('setScheduledTasks', data);
            resolve(data);
          })
          .catch(err => {
            commit('setError', err, { root: true });
            reject(err);
          });
      });
    },
    getScheduledTask({ commit, state, dispatch }, payload = {}) {
      return new Promise((resolve, reject) => {
        axios.get(`/subsystems/ns/scheduled-tasks/${payload.module}/${payload.name}`, payload)
          .then(res => {
            const data = res.data;
            commit('setScheduledTask', data);
            resolve(data);
          })
          .catch(err => {
            commit('setError', err, { root: true });
            reject(err);
          });
      });
    },
    createScheduledTask({ commit, state, dispatch }, payload = {}) {
      return new Promise((resolve, reject) => {
        axios.post(`/subsystems/ns/scheduled-tasks`, payload)
          .then(res => {
            resolve(res);
          })
          .catch(err => {
            commit('setError', err, { root: true });
            reject(err);
          });
      });
    },
    updateScheduledTask({ commit, state, dispatch }, payload = {}) {
      return new Promise((resolve, reject) => {
        axios.put(`/subsystems/ns/scheduled-tasks/${payload.module}/${payload.name}`, payload)
          .then(res => {
            resolve(res);
          })
          .catch(err => {
            commit('setError', err, { root: true });
            reject(err);
          });
      });
    },
    removeScheduledTask({ commit, state, dispatch }, payload = {}) {
      return new Promise((resolve, reject) => {
        axios.delete(`/subsystems/ns/scheduled-tasks/${payload.module}/${payload.name}`, payload)
          .then(res => {
            resolve(res);
          })
          .catch(err => {
            commit('setError', err, { root: true });
            reject(err);
          });
      });
    },
    getScheduledTaskFunctions({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get('/subsystems/ns/available-tasks')
          .then(res => {
            const data = res.data.items;
            commit('setScheduledTaskFunctions', data);
            resolve(data);
          })
          .catch(err => {
            commit('setError', err, { root: true });
            reject(err);
          });
      });
    }
}