import axios from "axios";

export default {
  getServiceStatus({ commit, state, rootGetters }) {
    if (rootGetters.isDummy) { // dummy data until BE is implemented
      setTimeout(() => {
        return new Promise((resolve, reject) => {
            let res = {
              content_filter: {
                enable_content_filtering: parental_control.enable_content_filtering,
                enable_safe_search: parental_control.enable_safe_search,
                enable_quiet_time: parental_control.enable_quiet_time,
                global: true 
              },
              malware_phishing: {
                status: false,
                global: false,
              }
            }
            commit('setServiceStatus', res);
          resolve()
        })
      }, 1000);
    } else {
      return new Promise((resolve, reject) => {
        axios.get('/subsystems/rs/service-calculated-status')
          .then(res => {
            if (res && res.data && res.data.parental_control && res.data.threat_protection) {
              const parental_control = res.data.parental_control;
              const threat_protection = res.data.threat_protection;
              const item = {
                 content_filter: {
                   content_filtering: parental_control.enable_category_filtering, // change to enable_category_filtering
                   safe_search: parental_control.enable_safe_search,
                   quiet_time: parental_control.enable_quiet_time,  
                   global: true,   
                 },
                 malware: {
                   status:  threat_protection.enable_anti_malware,  
                   global: true
                 }
              }
              commit('setServiceStatus', item);
              resolve(item)
            } else {
              reject()
            }
          })
          .catch(error => {
            commit('setError', error, { root: true });
            reject()
          })
      })
    }
  },
  setServiceStatus({ commit, dispatch, rootGetters, state }, payload = {}) {
    if (rootGetters.isDummy) { // dummy data until BE is implemented
      dispatch('setLocalServiceStatus', payload);
    } else {
      return new Promise((resolve, reject) => {
  
      let statusPayload = {
        [payload.name.id]: {
          [payload.name.service]: payload.status
        }
      }

        axios.patch(`/subsystems/rs/service-configuration`, statusPayload)
          .then(res => {
            resolve(res);
          })
          .catch(err => {
            commit('setError', err, { root: true });
            reject(err);
          });
      });
    }
  },
  setLocalServiceStatus({ commit }, data) {
    commit('setLocalServiceStatus', data)
  },

  // LAN Protection

  getLanProtection({ commit, state, rootGetters }) {
    if (rootGetters.isDummy) { // dummy data until BE is implemented
      setTimeout(() => {
        return new Promise((resolve, reject) => {
            if (!state.lan_protection.mounted) {
              let res = {
                mounted: true,
                enabled: true,
                telnet_credentials: true,
                device_scan: true,
                default_remote_shell: true
              }
              commit('setLanProtection', res);
           }
          resolve()
        })
      }, 1000);
    } else {
      return new Promise((resolve, reject) => {
        axios.get('/subsystems/rs/lan-protection')
          .then(res => {
            if (res && res.data) {
              const result = {
                 enabled: res.data.enabled,
                 // default_allow_remote_shell_access should show the opposite of what it receives from BE. 
                 default_remote_shell: !!!res.data.default_allow_remote_shell_access,
                 access_rule: res.data.default_device_access_rule,
                 device_access: res.data.enable_device_access_detection
                 
                 // Hidden because HomeSecure does not support enable/disable of this subservices in ASM 30.1
                  //  device_scan: res.data.enable_device_scan_detection,
                 //  telnet_credentials: res.data.enable_telnet_credentials_scan,
              }
              commit('setLanProtection', result);
              resolve()
            } else {
              reject()
            }
          })
          .catch(error => {
            commit('setError', error, { root: true });
            reject()
          })
      })
    }

  },  

  updateLanProtection({ commit, dispatch, rootGetters, state }, payload = {}) {
    if (rootGetters.isDummy) { // dummy data until BE is implemented
      commit('setLocalLanProtection', payload);
    } else {
      return new Promise((resolve, reject) => {
        const lanPayload = {
          // default_allow_remote_shell_access should send the opposite of what it shows 
          default_allow_remote_shell_access: !!!state.lan_protection.default_remote_shell,
          default_device_access_rule: state.lan_protection.access_rule,
          enable_device_access_detection: state.lan_protection.device_access,
          enabled: state.lan_protection.enabled

          // Hidden because HomeSecure does not support enable/disable of this subservices in ASM 30.1
          // enable_device_scan_detection: state.lan_protection.device_scan,
          // enable_telnet_credentials_scan: state.lan_protection.telnet_credentials,
        }

        axios.put(`/subsystems/rs/lan-protection`, lanPayload)
          .then(res => {
            resolve(res);
          })
          .catch(err => {
            commit('setError', err, { root: true });
            reject(err);
          });
      });
    }
  },
  setLocalServiceStatus({ commit }, data) {
    commit('setLocalServiceStatus', data)
  },

  // Router Protection

  getRouterProtection({ commit, state, rootGetters }) {
    if (rootGetters.isDummy) { // dummy data until BE is implemented
      setTimeout(() => {
        return new Promise((resolve, reject) => {
            if (!state.lan_protection.mounted) {
              let res = {
                mounted: true,
                enabled: true,
                dns_settings: true,
                allow_enforcement: true,
                default_remote_shell: true
              }
              commit('setRouterProtection', res);
           }
          resolve()
        })
      }, 1000);
    } else {
      return new Promise((resolve, reject) => {
        axios.get('/subsystems/rs/router-protection')
          .then(res => {
            if (res && res.data) {
              const result = {
                 "enabled": res.data.enabled,
                 "dns_settings": res.data.enable_router_dns_settings_enforcement,
                 "allow_enforcement": res.data.enable_router_allowed_list_enforcement,
                 "default_remote_shell": res.data.default_allow_remote_shell_access,
                 "primary_dns": res.data.primary_dns,
                 "secondary_dns": res.data.secondary_dns
              }
              commit('setRouterProtection', result);
              resolve()
            } else {
              reject()
            }
          })
          .catch(error => {
            commit('setError', error, { root: true });
            reject()
          })
      })
    }

  },  

  updateRouterProtection({ commit, dispatch, rootGetters, state }, payload = {}) {
    if (rootGetters.isDummy) { // dummy data until BE is implemented
      commit('setLocalRouterProtection', payload);
    } else {
      // Hidden because RouterSecure does not support to enable/disable Router Protection Service in ASM 30.1
      if (false) {
        const routerPayload = {
          "default_allow_remote_shell_access": state.router_protection.default_remote_shell,
          "enable_router_dns_settings_enforcement": state.router_protection.dns_settings,
          "enable_router_allowed_list_enforcement": state.router_protection.allow_enforcement,
          "enabled": state.router_protection.enabled,
          "primary_dns": state.router_protection.primary_dns,
          "secondary_dns": state.router_protection.secondary_dns
        }
      
        return new Promise((resolve, reject) => {
          axios.put(`/subsystems/rs/router-protection`, routerPayload)
            .then(res => {
              resolve(res);
            })
            .catch(err => {
              commit('setError', err, { root: true });
              reject(err);
            });
        });
      }
    }
  },
};
