import _ from 'lodash';
import { SLIDER_OPTIONS } from "@/configs/generalManagement";

export default {
    setInitialNotificationsPlatforms(state, { platforms }) {
      state.defaultsNotifications.platforms = _.cloneDeep(platforms)
      state.notifications.platforms = _.cloneDeep(platforms)
    },
    setInitialNotificationsAdvancedConfiguration(state, { defaultAdvancedConfiguration }) {
      // * adjusting slider minutes to be on of the correct options by the PRD, for example: value of 3 will be 5, value of 27 will be 30
      let service, subService, subServiceMinInterval, nextValue, distanceFromValue, distanceFromNextValue
      const ignoredProps = ['b2b_notifications_enabled', 'default_notification_language_id', "max_notification_languages"]
      const sliderMinutes = Object.keys(SLIDER_OPTIONS)
      const minimumValue = +sliderMinutes[0]
      const maximumValue = +sliderMinutes[sliderMinutes.length - 1]
      for (const key in defaultAdvancedConfiguration) {
        service = defaultAdvancedConfiguration[key]
        for (const subServiceName in service) {
          if (!ignoredProps.includes(subServiceName)) {
            subService = service[subServiceName]
            if (subService.min_interval < minimumValue) subService.min_interval = minimumValue
            else if (subService.min_interval > maximumValue) subService.min_interval = maximumValue
            else {
              sliderMinutes.forEach((value, idx) => {
                subServiceMinInterval = subService.min_interval
                if (!sliderMinutes.includes(`${subServiceMinInterval}`)) {
                  if (idx <= sliderMinutes.length - 2) {
                    nextValue = sliderMinutes[idx + 1]
                    if (+value < subServiceMinInterval && subServiceMinInterval < +nextValue) {
                      distanceFromValue = Math.abs(subServiceMinInterval - +value)
                      distanceFromNextValue = Math.abs(subServiceMinInterval - +nextValue)
                      subService.min_interval = distanceFromValue <= distanceFromNextValue ? +value : +nextValue
                    }
                  }
                }
              })
            }
          }
        }
      }
      state.advancedConfiguration = { ...defaultAdvancedConfiguration }
      state.defaultAdvancedConfiguration = { ...defaultAdvancedConfiguration }
    },
    setGatewayEnabled(state, { isEnabled, gatewayName }) {
      state.gatewayState[gatewayName] = isEnabled
    },
    setInitialNotificationsAllConfiguration(state, { configurationsItems }) {
      // * preparing configurations data for the gui
      let lang, configurationsItemsObj = {}
      configurationsItems = configurationsItems.forEach(item => {
        lang = item.language_id
        delete item.language_id
        configurationsItemsObj[lang] = item
      })
      state.defaultsNotifications = {
        ...state.defaultsNotifications,
        ['allConfigurations']: _.cloneDeep(configurationsItemsObj)
      }
      state.notifications = {
        ...state.notifications,
        ['allConfigurations']: _.cloneDeep(configurationsItemsObj)
      }
    },
    setNotificationsPlatforms(state, { platforms }) {
      state.notifications.platforms = platforms
    },
    setDefaultLanguage(state, { defaultLanguage }) {
      state.defaultLang = defaultLanguage
      state.selectedLang = defaultLanguage
    },
    updateNotificationsConfiguration(state, { serviceName, service, subServiceName, subService }) {
      let cfg = state.notifications.allConfigurations[state.selectedLang]
      if (subServiceName && subService) {
        if (cfg[serviceName]) {
          cfg[serviceName][subServiceName] = subService
        } else cfg[serviceName] = { [subServiceName]: subService }
      } else if (serviceName && service) {
        cfg[serviceName] = service
      }
      state.notifications.allConfigurations[state.selectedLang] = cfg
    },
    updateNotificationsAdvancedConfiguration(state, { serviceName, subServiceName, subServiceAdvancedConf }) {
      if (subServiceName && subServiceName) {
        state.advancedConfiguration[serviceName][subServiceName] = subServiceAdvancedConf
      } else state.advancedConfiguration[serviceName] = subServiceAdvancedConf
    },
    updateInitialNotificationsAdvancedConfiguration(state, { defaultAdvancedConfiguration }) {
      state.defaultAdvancedConfiguration = defaultAdvancedConfiguration
    },
    updateSelectedLang(state, { lang }) {
      state.selectedLang = lang
    },
    addNewLangToConfiguration(state, { newLanguage }) {
      state.defaultsNotifications.allConfigurations[newLanguage] = {}
      state.notifications.allConfigurations[newLanguage] = {}
      state.defaultsNotifications = {
        ...state.defaultsNotifications,
        ['allConfigurations']: _.cloneDeep(state.defaultsNotifications.allConfigurations)
      }
      state.notifications = {
        ...state.notifications,
        ['allConfigurations']: _.cloneDeep(state.notifications.allConfigurations)
      }
    },
    deleteLangFromConfiguration(state, { lang }) {
      delete state.defaultsNotifications.allConfigurations[lang]
      delete state.notifications.allConfigurations[lang]
      state.defaultsNotifications = {
        ...state.defaultsNotifications,
        ['allConfigurations']: _.cloneDeep(state.defaultsNotifications.allConfigurations)
      }
      state.notifications = {
        ...state.notifications,
        ['allConfigurations']: _.cloneDeep(state.notifications.allConfigurations)
      }
    },
    resetAllNotificationsConfigurations(state) {
      state.notifications.platforms = _.cloneDeep(state.defaultsNotifications.platforms)
      state.notifications.allConfigurations[state.selectedLang] = _.cloneDeep(state.defaultsNotifications.allConfigurations[state.selectedLang])
      state.advancedConfiguration = _.cloneDeep(state.defaultAdvancedConfiguration)
    },
    resetSubServiceNotificationConfiguration(state, { serviceName, subServiceName }) {
      const defaultConfig = state.defaultsNotifications.allConfigurations[state.selectedLang]
      const currentConfig = state.notifications.allConfigurations[state.selectedLang];

      if (defaultConfig[serviceName] && defaultConfig[serviceName][subServiceName]) {
        if (!currentConfig.internal) {
          delete currentConfig[serviceName][subServiceName]
          delete defaultConfig[serviceName][subServiceName]
        }
        currentConfig[serviceName][subServiceName] =
          _.cloneDeep(defaultConfig[serviceName][subServiceName]);
      } else if (
        currentConfig[serviceName] &&
        currentConfig[serviceName][subServiceName] &&
        defaultConfig[serviceName] &&
        defaultConfig[serviceName][subServiceName]
      ) {
        currentConfig[serviceName][subServiceName] =
          _.cloneDeep(defaultConfig[serviceName][subServiceName]);
      } else if (
        currentConfig[serviceName] &&
        !defaultConfig[serviceName]
      ) {
        delete currentConfig[serviceName];
      } else if (
        currentConfig[serviceName][subServiceName] &&
        !defaultConfig[serviceName][subServiceName]
      ) {
        delete currentConfig[serviceName][subServiceName];
        if(!currentConfig[serviceName]) {
          delete currentConfig[serviceName];
        }
      }
    },
  }
