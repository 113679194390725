<template>
    <div>
        <div id="wrapper">
            <div v-if="!updating">
                <b-img center src="img/empty-state.png" fluid rounded="circle" class="inactive"></b-img>
                <h3><span>{{ t(value) }} </span></h3>
            </div>
        </div> 
    </div>
</template>

<script>
export default {
    name: 'empty-state',
    props: {
        value: {
            type: String,
            default: 'No results found!'
        },
        updating: {
            type: Boolean,
            default: false
        }
    },
};
</script>

<style scoped>
#wrapper {
    text-align: center;
    height: 300px;
}

</style>
