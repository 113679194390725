/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'key': {
    width: 16,
    height: 16,
    viewBox: '0 0 292.146 292.146',
    data: '<path pid="0" d="M265.818 26.328c-35.103-35.104-92.017-35.104-127.12 0-23.7 23.7-31.374 57.337-23.073 87.496L7.057 222.391a14.274 14.274 0 00-4.152 9.19L.029 276.967a14.275 14.275 0 0015.149 15.149l45.386-2.876a14.272 14.272 0 009.19-4.153l7.651-7.65a16.565 16.565 0 004.473-15.237l-3.859-17.735a3.582 3.582 0 014.261-4.261l17.735 3.86a16.564 16.564 0 0019.709-19.709l-3.859-17.734a3.582 3.582 0 014.261-4.261l17.735 3.86a16.564 16.564 0 0015.236-4.473l25.224-25.224c30.16 8.303 63.797.628 87.497-23.072 35.104-35.105 35.104-92.02 0-127.123zM119.566 166.925l-71.771 71.771c-1.953 1.952-4.512 2.929-7.071 2.929s-5.118-.977-7.071-2.929c-3.905-3.905-3.905-10.237 0-14.142l71.771-71.771c3.906-3.904 10.236-3.904 14.143 0 3.904 3.904 3.904 10.236-.001 14.142zm108.556-51.173c-14.284 14.284-37.445 14.284-51.729 0-14.283-14.282-14.283-37.443.002-51.728 14.282-14.283 37.443-14.283 51.726 0 14.284 14.284 14.284 37.446.001 51.728z"/>'
  }
})
