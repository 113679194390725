/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'item-accounts': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M4.7 11.8c1 0 1.8-.8 1.8-1.8s-.8-1.9-1.8-1.9-1.8.8-1.8 1.8.8 1.9 1.8 1.9zm1 1c-.3-.1-.7-.1-1-.1-.9 0-1.8.2-2.5.5s-1.1.9-1.1 1.7v1.4h4.1v-1.5c0-.7.2-1.4.5-2zm13.6-1c1 0 1.8-.8 1.8-1.8s-.8-1.8-1.8-1.8-1.8.8-1.8 1.8.8 1.8 1.8 1.8zm3.7 3.1c0-.7-.4-1.4-1.1-1.7-.8-.3-1.6-.5-2.5-.5-.4 0-.7 0-1 .1.4.6.6 1.3.6 2.1v1.5h4v-1.5zm-7.1-2.6c-1.1-.5-2.4-.8-3.9-.8s-2.8.4-3.9.8c-1 .4-1.6 1.4-1.6 2.5v1.5h11v-1.5c0-1-.6-2-1.6-2.5zm-7.5 2.2c.1-.2.1-.4.8-.6.9-.3 1.8-.5 2.8-.5s1.9.2 2.8.5c.7.3.7.4.8.6H8.4zM12 7.2c.5 0 .9.4.9.9s-.4.9-.9.9-.9-.4-.9-.9.4-.9.9-.9m0-1.8c-1.5 0-2.7 1.2-2.7 2.7s1.2 2.7 2.7 2.7 2.7-1.2 2.7-2.7-1.2-2.7-2.7-2.7z" _fill="#383132"/>'
  }
})
