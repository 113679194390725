/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'sun': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M6.8 4.8L5 3.1 3.6 4.5l1.8 1.8 1.4-1.5zM1 10.5h3v2H1v-2zM11 .6h2v3h-2v-3zM19 3l1.4 1.4-1.8 1.8-1.4-1.4L19 3zm-1.8 15.2L19 20l1.4-1.4-1.8-1.8-1.4 1.4zm2.8-7.7h3v2h-3v-2zm-8-5c-3.3 0-6 2.7-6 6s2.7 6 6 6 6-2.7 6-6-2.7-6-6-6zm0 10c-2.2 0-4-1.8-4-4s1.8-4 4-4 4 1.8 4 4-1.8 4-4 4zm-1 4h2v3h-2v-3zm-7.4-1L5 20l1.8-1.8-1.4-1.4-1.8 1.7z" _fill="#626262"/>'
  }
})
