import adsFree from './adsFree';
import alerts from './alerts';
import antiBotnet from './antiBotnet';
import antiPhishing from './antiPhishing';
import antiVirus from './antiVirus';
import autoNotices from './autoNotices';
import backups from './backups';
import contentFilter from './contentFilter';
import externalservers from './externalservers';
import filtering_log from './filtering-log';
import firewall from './firewall';
import hosts_and_processes from './hosts-and-processes';
import scheduled_tasks from './scheduled-tasks';
import status from './status';

export default {
  namespaced: true,
  modules: {
    adsFree,
    alerts,
    antiBotnet,
    antiPhishing,
    antiVirus,
    autoNotices,
    backups,
    contentFilter,
    externalservers,
    filtering_log,
    firewall,
    hosts_and_processes,
    scheduled_tasks,
    status,
    backups
  },
  state: {
    eventsList: [],
    servicesModules: [],
  },
  mutations: {
    setEventsList(state, list) {
      state.eventsList = list;
    },
    setServicesModules(state, list) {
      state.servicesModules = list;
    },
  },
  actions: {
    async getEventsList({ commit, state, rootState }) {
      if (rootState.settings.debug) {
        let list = [{ value: "event1", label: "Event 1" }, { value: "event2", label: "Event 2" }, { value: "event3", label: "Event 3" }]
        commit("setEventsList", list);
      } else {
        // Get list from backend
      }
    },
    async getServicesModules({ commit, state, rootState }) {
      if (rootState.settings.debug) {
        let list = ['Basic', 'Premium', 'Enterprise'];
        commit('setServicesModules', list);
      } else {
        // Get list from backend
      }
    },
  },
  getters: {
    getServicesModules: (state) => (query) => {
      return state.servicesModules.filter(a => a.includes(query.query));
    },
    getEventsList(state) {
      return state.eventsList;
    },
  }
}
