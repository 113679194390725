/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'item-generalmanagement': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M20.227 12.083c0-.476 0-.951-.165-1.269l2.477-2.061-2.147-3.647-3.138.951a10.65 10.65 0 00-2.312-1.268l-.66-3.172H9.988l-.66 3.172c-.826.317-1.652.792-2.312 1.268l-3.138-.951L1.73 8.753l2.477 2.061c0 .476-.165.793-.165 1.269 0 .476 0 .951.165 1.269l-2.477 2.22 2.147 3.647 3.138-.952c.66.635 1.486.952 2.312 1.269l.66 3.171h4.294l.66-3.171a10.648 10.648 0 002.312-1.269l3.138.952 2.147-3.647-2.477-2.062c.165-.476.165-.951.165-1.427zm-8.092 4.281c-2.312 0-4.294-1.903-4.294-4.123s1.817-4.28 4.294-4.28c2.477 0 4.294 1.902 4.294 4.122 0 2.22-1.982 4.281-4.294 4.281z"/>'
  }
})
