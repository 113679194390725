export default {
    isp_category: (state) => (id) => {
        return state.isp_categories.find(category => category.isp_category_id == id);
    },
    isp_categories: (state) => {
        return state.isp_categories;
    },
    categories: (state) => {
        return state.categories;
    },
}