<template>
  <b-overlay :show="updating" rounded opacity="0" spinner-small spinner-variant="primary" class="paginated-table">
		<b-card card-body-classes="table-full-width" class="paginated-table-card" :class="{'paginated-table-card_updating':updating}" no-footer-line>

        <b-container  slot="header" class="col-12">
          <b-row align-h="between">
            <b-col style="padding-left: 0;" class="text-left col-12" align-self="center">
              <fg-input class="my-2" v-if="searchable && (search && search.length > 0) && (tableData.length > 0 || serverpagination)">
                <template v-slot:addonLeft v-if="serverpagination && search.length>1">
                  <el-select class="select-primary" style="max-width: 175px;"
                    size="large"
                    :placeholder="t('Search by')"
                    v-model="searchField"
                    @change="search_change">
                    <el-option v-for="option in search"
                        class="select-primary"
                        :value="option.value  || option"
                        :label="option.label  || option"
                        :key="option.key || option">
                    </el-option>
                  </el-select>
                </template>
				<template v-if="hasExtendedSearch">
					<el-select class="select-primary option_extended" style="max-width: 175px;"
                    	size="large"
                    	:placeholder="t('Search by')"
                    	v-model="searchExtendedOption"
                    	@change="searchByExtendedOption">
						<el-option v-for="option in getExtendedOptions"
                        	class="select-primary"
                        	:value="option.value  || option"
                        	:label="option.label  || option"
                        	:key="option.key || option">
                    	</el-option>
                  	</el-select>
				</template>	
                <el-input v-if="hasTextInputSearch"
					type="search"
                	clearable
                	@input="search_keypress"
                	prefix-icon="el-icon-search"
                	style="max-width: 350px; padding-left: 7px;"
                	:placeholder="t('Search records')"
                	v-model="searchQuery"
                	aria-controls="datatables"
                >
              </el-input>
            </fg-input>
            </b-col>
            <b-col class="text-right" align-self="center">
              <n-button
                v-if="withCreate"
                type="primary"
                size="md"
                style="min-width: 120px; max-width: 20%;float:right;"
                round
                block
                @click.native="$emit('handle-new')"
                :visible_for_permissions="createButtonPermissions"
              >
                <i><svgicon class="icon" icon="icon-new" /></i>
                {{t('New')}}
              </n-button>
            </b-col>
          </b-row>
          <b-row v-if="(search && search.length > 0) && (tableData.length > 0 || serverpagination)">
            <b-col class="partial_matches_alert" align-self="center">
              <p>* {{ t('search_fields') }}: 
				<template v-if="searchText != ''">  
					{{ t(searchText) }}
				</template>	
                <template v-else v-for="(field, index) in search">
                  {{ field.label || field }}
                  <template v-if="index < (search.length - 1)">, </template>
                </template>
              </p>
            </b-col>
          </b-row>
          <b-row v-if="(search && search.length > 0) && (tableData.length > 0 || serverpagination)">
            <b-col class="partial_matches_alert" align-self="center">
              <span><i>{{t('partial_match_warning')}}</i></span>
            </b-col>
          </b-row>
        </b-container>
			<b-modal v-if="itemForAction" id="confirm" ref="modal" @ok="handleConfirm" @hidden='handleCancel' :title="t(itemForAction.action)" :ok-title="t('accept')" :cancel-title="t('cancel')">
				<div v-if="itemForAction.text">{{ t(itemForAction.text)}}.</div>
				<!-- {{ itemForAction.message === undefined ? t('changes_are_local') + '.' : t(itemForAction.message) }} -->
				{{ itemForAction.message === undefined ? t('Are you sure that you want to delete?') : t(itemForAction.message) }}
			</b-modal>


			<b-card-body style="padding: 0px;margin: 0px">
				<div ref="wrapper" v-if="tableData.length > 0 && total > 0">
					<div :key="tableKey">
						<el-table :show-header="showHeader" ref="myTable" style="width: 100%;" :data="queriedData" row-key="row_id">
							<el-table-column v-if="draggable" :render-header="(h) => { return h('i', {class: 'now-ui-icons design_bullet-list-67'}) }">
								<div class="click-drag">
									<i class="now-ui-icons arrows-1_minimal-up"></i>
									<i class="now-ui-icons arrows-1_minimal-down"></i>
								</div>
							</el-table-column>
							<el-table-column v-for="column in tableColumns"
                             				:type="column.type || ''"
											:key="t(column.label)"
											:min-width="column.minWidth"
											:width="column.width"
											:align="column.align || 'left'"
											:prop="column.prop"
											:label="t(column.label)"
											:sortable ="typeof column.sortable === 'boolean' ? column.sortable : false"
											:sort-method ="column.sortMethod"
											:anyText="column.anyText"
											:formatter="typeof column.formatter === 'function' ? column.formatter : null">
								<template #default="{ row }" v-if="typeof column.formatter !== 'function'">
									<template v-if="column.type === 'expand'" >
										<slot name="expand" v-bind:row="row" />
									</template>
									<template v-else-if="row[column.prop] === undefined">{{ column.default }}</template>
									<div v-else-if="column.formatter && column.formatter.name === 'switch-formatter'"
										:is="column.formatter"
										:row="row"
										:column="column"
										@input='handleStatusChange($event)'
										:disabled="row.switchDisabled"
									/>
									<div v-else-if="column.formatter" :is="column.formatter" :row="row" :column="column" @input='handleStatusChange($event)' />
									<template v-else-if="editingRow && editingRow.row.id === row.id" >
										<input type="text" v-model="row[column.prop]">
									</template>
									<template v-else-if="column.translateCells">{{ t(row[column.prop]) }}</template>
									<template v-else>{{ row[column.prop] }}</template>
								</template>
							</el-table-column>
							<el-table-column v-if="hasExpandSlot && !hasExpandColumn" type="expand" width="1" align="left">
								<template slot-scope="scope">
									<slot name="expand" v-bind:row="scope.row" />
								</template>
							</el-table-column>
							<el-table-column v-if="actions" :min-width="actions.minWidth || 335" :fixed="actions.fixed || 'right'" :label="t(actions.label) || 'Actions'">
								<div slot-scope="props" class="table-actions">
									<template v-if="editingRow && editingRow.row.id === props.row.id">
										<template v-for="item in actions.editItems">
											<el-tooltip :content="t(item.action)" :open-delay="250" :hide-after="1500" :key="item.event">
												<n-button v-if="!item.confirm"
													:id="'paginatedTable-action-' + item.action" @click.native="$emit(item.event, {index: props.$index, row: props.row})"
													:type="item.type || 'primary'"
													:size="item.size || 'sm'" round icon
													:disabled="item.disabledIf || buttonDisabled(editingRow, item.event)"
                          							:visible_for_permissions="item.hasOwnProperty('visible_for_permissions') ? item.visible_for_permissions : []"
													v-b-modal.confirm>
													<svgicon v-if="item.isSVGIcon"
														:original="item.isIconOriginal"
														:icon="item.icon"
														:class="item.iconClass || ''"
														:width="item.iconWidth || '14'"
														:height="item.iconHeight || '14'"
														/>
													<ion-icon v-else-if="item.isIconify"
														class="iconify action-iconify"
														:data-icon="item.icon"
														:name="item.icon"
														:data-width="item.iconSize || '14'"
														:data-height="item.iconSize || '14'" />
													<i v-else :class="item.icon" />
												</n-button>
												<n-button v-if="item.confirm" :id="'paginatedTable-action-' + item.action"
													@click.native="itemForAction={event: item.event, action: item.action, data: {index: props.$index, row: props.row}}"
													:type="item.type || 'primary'"
													:size="item.size || 'sm'" round icon
													:disabled="item.disabledIf || buttonDisabled(editingRow, item.event)"
                          							:visible_for_permissions="item.hasOwnProperty('visible_for_permissions') ? item.visible_for_permissions : []"
													v-b-modal.confirm>
													<svgicon v-if="item.isSVGIcon"
														:original="item.isIconOriginal"
														:icon="item.icon"
														:class="item.iconClass || ''"
														:width="item.iconWidth || '14'"
														:height="item.iconHeight || '14'" />
													<ion-icon v-else-if="item.isIconify"
														class="iconify action-iconify"
														:data-icon="item.icon"
														:name="item.icon"
														:data-width="item.iconSize || '14'"
														:data-height="item.iconSize || '14'" />
													<i v-else :class="item.icon" />
												</n-button>
											</el-tooltip>
										</template>
									</template>
									<template v-else v-for="item in actions.items">
										<el-tooltip v-if="item.visibleIf === undefined || item.visibleIf(props.row)" :content="t(item.action)" :open-delay="250" :hide-after="1500" :key="item.event">
											<n-button v-if="!item.confirm" :id="'paginatedTable-action-' + item.action"
												@click.native="emitClick(item, props)"
												:type="item.type || 'primary'"
												:size="item.size || 'sm'" round icon
												:disabled="typeof item.disabledIf === 'function' ? item.disabledIf(props.row) : item.disabledIf || buttonDisabled(props.row, item.event)"
                        						:visible_for_permissions="item.hasOwnProperty('visible_for_permissions') ? item.visible_for_permissions : []"
												v-b-modal.confirm>
												<svgicon v-if="item.isSVGIcon"
													:original="item.isIconOriginal"
													:icon="item.icon"
													:class="item.iconClass || ''"
													:width="item.iconWidth || '14'"
													:height="item.iconHeight || '14'"/>
												<ion-icon v-else-if="item.isIconify"
													class="iconify action-iconify"
													:data-icon="item.icon"
													:name="item.icon"
													:data-width="item.iconSize || '14'"
													:data-height="item.iconSize || '14'" />
												<i v-else :class="item.icon" />
											</n-button>
											<n-button v-if="item.confirm" :id="'paginatedTable-action-' + item.action"
												@click.native="itemForAction={event: item.event, action: item.action, message: item.message, data: {index: props.$index, row: props.row}}"
												:type="item.type || 'primary'"
												:size="item.size || 'sm'" round icon
												:disabled="typeof item.disabledIf === 'function' ? item.disabledIf(props.row) : item.disabledIf || buttonDisabled(props.row, item.event)"
                        						:visible_for_permissions="item.hasOwnProperty('visible_for_permissions') ? item.visible_for_permissions : []"
												v-b-modal.confirm>
												<svgicon v-if="item.isSVGIcon"
													:original="item.isIconOriginal"
													:icon="item.icon"
													:class="item.iconClass || ''"
													:width="item.iconWidth || '14'"
													:height="item.iconHeight || '14'"/>
												<ion-icon v-else-if="item.isIconify"
													class="iconify action-iconify"
													:data-icon="item.icon"
													:name="item.icon"
													:data-width="item.iconSize || '14'"
													:data-height="item.iconSize || '14'" />
												<i v-else :class="item.icon"></i>
											</n-button>
										</el-tooltip>
									</template>
								</div>
							</el-table-column>
						</el-table>
					</div>
				</div>
				<div v-else>
					<empty-state v-if="showEmptyState" :updating="updating"/>


					<el-table  style="width: 100%;" slot="empty" empty-text="-" v-if="showHeadOnEmpty" >						
						<el-table-column v-for="column in tableColumns"										
							:key="t(column.label)"
							:min-width="column.minWidth"
							:width="column.width"
							:align="column.align || 'left'"
							:label="t(column.label)"
						/>						
					</el-table>
				</div>
				
			</b-card-body>


			<div v-if="tableData.length > 0 && total > 0 && showPagination" slot="footer" class="col-12 paginated-table-card__pagination-wrapper">
				<div class="paginated-table-card__pagination-item mb-4 mb-sm-0">
					<p id="paginatedTable-server-pag-summary" class="card-category" v-if="serverpagination">{{ serverPaginationEntriesLabel }}</p>
					<p id="paginatedTable-pag-summary" class="card-category" v-else>{{t('Showing')}} {{from + 1}} {{t('to')}} {{to}} {{t('of')}} {{total}} {{t('entries')}}</p>
				</div>
				<div class="paginated-table-card__pagination-item order-3 order-sm-1">
					<span class="card-category pr-2">{{t('Results per page')}}:</span>
					<el-select id="paginatedTable-pag-selector"
						class="select-primary paginated-table-card__perpage-select"
						v-model="perPage"
						placeholder="Per page">
						<el-option class="select-default" v-for="(item, i) in pagination.perPageOptions" :id="'paginatedTable-pag-selector-opt-' + i" :key="item" :label="item" :value="item" />
					</el-select>
				</div>
				<div class="paginated-table-card__pagination-item order-2 order-sm-1 mb-4 mb-sm-0">
					<n-pagination v-if="!serverpagination" class="pagination-no-border mb-0"
								v-model="pagination.currentPage"
								ref="pagination" :per-page="pagination.perPage"
								:total="total" />
					<n-pagination-server v-else class="pagination-no-border mb-0"
								v-model="currentPage"
								:updating="updating"
								:per-page="perPage"
								:prev="serverpagination.prev" :next="serverpagination.next" />
				</div>
			</div>
		</b-card>
		<span v-if="secsToRefresh > 0" class="countdown-timer">{{t('refresh-on')}} {{ countDownMinutes }}:{{ countDownSeconds }}</span>
	</b-overlay>
</template>

<script>
import { Table, TableColumn, Select, Option, Input, Tag, Button } from 'element-ui';
import Fuse 			from 'fuse.js';
import sortable 		from "sortablejs";
import EmptyState 		from '../EmptyState';
import Pagination 		from '../Pagination';
import PaginationServer from '../PaginationServer';
import Switch 			from '../Switch';
import GeneralMixin     from '@/components/AsmConfigurations/general/GeneralMixin'

export default
{
	name: 'paginated-table',
	components: {
		'el-select': Select,
		'el-option': Option,
		'el-table': Table,
		'el-table-column': TableColumn,
		'el-input' : Input,
		'el-tag': Tag,
		'el-button': Button,
		'empty-state' : EmptyState,
		'n-pagination' : Pagination,
		'n-pagination-server' : PaginationServer,
		'n-switch' : Switch
	},
	mixins: [GeneralMixin],
	props: {
		rows: {
			type: Array,
			default: () => []
		},
		columns:  {
			type: Array,
			default: () => [],
			required: true
		},
		actions: {
			type: Object,
			default: null
		},
		search: {
			type: Array,
			default: () => []
		},
		searchable: {
			type: Boolean,
			default: true
		},
		serverpagination: {
			type: Object,
			default: null
		},
		value: {
			type: Object,
			default: () => {}
		},
		editingRow: {
			type: Object,
			default: () => null
		},
		draggable: {
			type: Boolean,
			default: false
		},
		updating: {
			type: Boolean,
			default: false
		},
		handleDrag: {
			type: String,
			default: ".click-drag"
		},
		animateDrag: {
			type: Number,
			default: 100
		},
		withCreate: {
			type: Boolean,
			default: false
		},
		itemsPerPage: {
			type: Number,
			default: 10
		},
		showHeader: {
			type: Boolean,
			default: true
		},
    showPagination: {
      type: Boolean,
      default: true
    },
		searchRestrictions: {
			type: Object,
			default: () => {}
		},
		translateCells: {
			type: Boolean,
			default: false
		},
		createButtonPermissions: {
			type: Array,
			default: () => []
		},
		buttonDisabled: {
			type: Function,
			default: () => false
		},
		secsToRefresh: {
			type: Number,
			default: 0
		},
		showEmptyState: {
			type: Boolean,
			default: true
		},
		showHeadOnEmpty: {
			type: Boolean,
			default: false		
		},
		searchText: {
			type: String,
			default: ''
		},
		fuzzySearchParams : {
			type: Object,
			default() {
				return {
					threshold : 0.3
				}
			}
		}
	},
	data() {
		return {
			pagination: {
				perPage: null,
				currentPage: 1,
				perPageOptions: [5, 10, 20, 50],
				total: 0
			},
			searchField: '',
			searchQuery: '',
			searchExtendedOption: '',
			tableData: [],
			tableColumns: [],
			searchedData: [],
			fuseSearch: null,
			itemForAction: null,
			tableKey: 0,   				// For draggable
			sortabled: false,    	// For draggable
			countDown: 0
		};
	},
	mounted() {
		if(this.value && this.value.field) {
			this.searchField = this.value.field
		}

		if(!this.serverpagination) {				
			this.fuseSearch = new Fuse(this.tableData, { keys: this.computedSearch, ...this.fuzzySearchParams });
		}

		this.draggable && this.makeTableSortAble();
		if (this.secsToRefresh > 0) {
			this.countDown = this.secsToRefresh;
    	this.countDownTimer();
		}
	},
	updated() {
		this.draggable && !this.sortabled && this.makeTableSortAble();
	},
	created() {
		this.pagination.perPage = this.itemsPerPage;
		this.tableData = this.rows;
		this.tableColumns = this.columns;
	},
	watch: {
		rows(newValue, oldValue) {
			this.tableData = newValue;
			this.searchedData = newValue;
			if(!this.serverpagination) {				
				this.fuseSearch = new Fuse(this.tableData, { keys: this.computedSearch, ...this.fuzzySearchParams });

				if(this.searchQuery) {
					this.searchedData = this.fuseSearch.search(this.searchQuery);
				}
			}else{
				this.searchedData = newValue
				if( this.searchedData.length == 0 && this.serverpagination.currentPage>1){
					// empty page after removing an item, maybe there are results on previous pages
					if ( !this.serverpagination.prev && !this.serverpagination.next){
						// backend can't retrieve records after the (new) last one, reset the query to display first page
						this.$emit('input', {
							field: this.hasExtendedSearch 
								? this.hasTextInputSearch ? this.searchExtendedOption : this.searchField
								: this.searchField,
							value: this.hasExtendedSearch 
								? this.hasTextInputSearch ? this.searchQuery : searchExtendedOption
								: this.searchQuery
						});
					}
				}
			}
		},

		columns(newValue, oldValue) {
			this.tableColumns = this.columns
		},

		/**
		 * Searches through the table data by a given query.
		 * NOTE: If you have a lot of data, it's recommended to do the search on the Server Side and only display the results here.
		 * @param value of the query
		 */
		searchQuery(value) {
			this.$emit('input', { 
				field: this.hasExtendedSearch 
					? this.hasTextInputSearch ? this.searchExtendedOption : this.searchField
					: this.searchField,
				value 
			});
			if(!this.serverpagination) {
            let result = this.tableData;
            this.pagination.currentPage = 1
            if (value !== '') {
              result = this.fuseSearch.search(value);
            }
            this.searchedData = result;
			}
		},

		searchField(value) {
			this.$emit('input', { field: value, value: this.searchQuery });
			if(!this.serverpagination) {
				this.searchedData = this.fuseSearch.search(this.searchQuery);
			}
		},

		// For draggable
		tableKey() {
			this.$nextTick(() => {
				this.makeTableSortAble();
				this.keepWrapperHeight(false);
			});
		},

		itemsPerPage(value) {
		  	this.pagination.perPage = value;
		}
	},
  computed: {
		/***
		 * Returns a page from the searched data or the whole data. Search is performed in the watch section below
		 */
		computedSearch() {
			return this.search.map(field => field.value || field)
		},

		queriedData() {
			if(this.serverpagination) {
				return this.tableData;
			}

			const result = this.searchedData.length > 0 || this.searchQuery ? this.searchedData : this.tableData;
			return result.slice(this.from, this.to);
		},
    to() {
      if ( this.serverpagination){
        if (this.serverpagination.next_id)
          return this.from + this.pagination.perPage;
        else
          return this.from + this.tableData.length - 1
      }
      else{
        let highBound = this.from + this.pagination.perPage;
        if (this.total < highBound) {
          highBound = this.total;
        }
        return highBound;
      }
    },
    from() {
      if ( this.serverpagination)
        return this.pagination.perPage * (this.currentPage - 1) +1;
      else
        return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      if (this.serverpagination) {
        return this.tableData.length;
      }

      return this.searchQuery !== ''
        ? this.searchedData.length
        : this.tableData.length;
    },
    sort: function(a,b){
      if (a > b) {
        return 1;
      }
      if (a < b) {
        return -1;
      }
      return 0;
    },
    currentPage: {
        get () {
          return this.serverpagination.currentPage
        },
        set(newValue) {
          if ( newValue < this.serverpagination.currentPage)
            this.$emit('prev')
          if ( newValue > this.serverpagination.currentPage)
            this.$emit('next')
        }
    },
    perPage: {
      get() {
        return this.pagination.perPage
      },
      set(newValue){
        this.pagination.perPage = newValue
        this.pagination.currentPage = 1;
        this.$emit('pagination', newValue)
      }
    },
    serverPaginationEntriesLabel(){
      let lessLength = (this.rows.length < this.perPage && !this.updating && this.currentPage === 1)
        ? `${this.t('of')} ${this.rows.length}`
        : '';
      return `${this.t('Showing')} ${this.t('entries')} ${this.from} ${this.t('to')} ${this.to} ${lessLength}`
    },
    hasExpandSlot() {
      return !!this.$slots.expand || !!this.$scopedSlots.expand;
    },
    hasExpandColumn() {
	  return this.columns.findIndex(column => column.type === 'expand') >= 0;
    },
	countDownMinutes() {
      return Math.floor(this.countDown % 3600 / 60).toLocaleString('en-US', {
        minimumIntegerDigits: 2,
      });
    },
    countDownSeconds() {
      return Math.floor(this.countDown % 3600 % 60).toLocaleString('en-US', {
        minimumIntegerDigits: 2,
      });
	},
	hasExtendedSearch() { 
	 const filterElement = this.search.find(element => element.value === this.searchField)
	 return filterElement && filterElement.isExtended
	},
	hasTextInputSearch() { 
	 const filterElement = this.search.find(element => element.value === this.searchField)
	 if (filterElement)
	 	return !filterElement.isExtended || (filterElement.isExtended && !filterElement.hideTextInput)
	 else
		return true
	},
	
	getExtendedOptions() {
		const filterElement = this.search.find(element => element.value === this.searchField)
		return filterElement && filterElement.extendedOptions
	}
	
  },
	methods: {
			emitClick(item, props) {
				this.$emit(item.event, {index: props.$index, row: props.row})
			},
      search_change: function(value) {
    	this.searchQuery = "";
		this.searchExtendedOption = "";
	  },
      search_keypress: function(value) {
        if( this.searchRestrictions ){
          if( this.searchField in this.searchRestrictions ){
            let regx = new RegExp(this.searchRestrictions[this.searchField]);
            if( !regx.test(value) ) {
                this.searchQuery = this.searchQuery.substring(0, this.searchQuery.length-1)
            }
          }
        }
      },
      handleConfirm() {
        this.$emit(this.itemForAction.event, this.itemForAction.data);
        this.itemForAction = null;
      },
      handleCancel() {
        this.itemForAction = null;
      },
      toggleSwitch(data) {
        console.log(data);
      },
      handleStatusChange(event) {
        this.$emit('status-change', event);
      },
      makeTableSortAble() {
        if (this.$refs.myTable) {
          const myTable = this.$refs.myTable.bodyWrapper.querySelector(
            "tbody"
          );
          sortable.create(myTable, {
            handle: this.handleDrag,
            animation: this.animateDrag,
            onEnd: ({ newIndex, oldIndex }) => {
              this.keepWrapperHeight(true);
              this.tableKey = Math.random();
              let search = this.searchQuery !== '';
              let newIndexInPage = (
                (this.pagination.currentPage - 1) * this.pagination.perPage)
                  + newIndex;
              let oldIndexInPage = (
                (this.pagination.currentPage - 1) * this.pagination.perPage)
                  + oldIndex;
              this.$emit("handle-move",
                { newIndex: newIndexInPage, oldIndex: oldIndexInPage, search, reset: true }
              );
            },
            onChange: ({ newIndex, oldIndex }) => {
              let newIndexInPage = (
                (this.pagination.currentPage - 1) * this.pagination.perPage)
                  + newIndex;
              let oldIndexInPage = (
                (this.pagination.currentPage - 1) * this.pagination.perPage)
                  + oldIndex;
              let search = this.searchQuery !== '';
              this.$emit("handle-while-move",
                { newIndex: newIndexInPage, oldIndex: oldIndexInPage, search }
              );
              if (newIndex == this.pagination.perPage - 1 && newIndexInPage < (this.total - 1)) {
								// If item is dragged to last position on current page and there are more pages
                this.$emit("handle-dragged-to-last-in-page",
									{ perPage: this.pagination.perPage }
								);
              } else if (newIndex == 0 && newIndexInPage > 0) {
                // If item is dragged to first position on current page and current page is not the first one
								this.$emit("handle-dragged-to-first-in-page",
									{ perPage: this.pagination.perPage }
								);
              }
            },
            onStart: ({ oldIndex }) => {
              let oldIndexInPage = (
                (this.pagination.currentPage - 1) * this.pagination.perPage)
                  + oldIndex;

							// Identify if the dragged element is the last one and it is unique on the page
							let isLastElement = oldIndexInPage === this.total - 1;
							let isUniqueOnPage = this.total % this.pagination.perPage === 1;

              let search = this.searchQuery !== '';
              let itemsPerPage = this.pagination.perPage;
              this.$emit("handle-start-drag", { oldIndex: oldIndexInPage, search, perPage: itemsPerPage});
							if (isLastElement && isUniqueOnPage) {
								// If the dragged element is the last one and it is unique on the page
								this.$emit("handle-dragged-to-first-in-page",
									{ lastAndUnique: true, perPage: this.pagination.perPage }
								);
							} else if (oldIndex === this.pagination.perPage - 1 && oldIndexInPage < (this.total - 1)) {
								// If the dragged element is the last one on current page and there are more pages
                this.$emit("handle-dragged-to-last-in-page",
									{ perPage: this.pagination.perPage }
								);
							} else if (oldIndex === 0 && oldIndexInPage > 0) {
								// If the dragged element is the first one on current page and current page is not the first one
                this.$emit("handle-dragged-to-first-in-page",
									{ perPage: this.pagination.perPage }
								);
							}
            }
          });
          this.sortabled = true;
        }
      },
      keepWrapperHeight(keep) {
        // eslint-disable-next-line prefer-destructuring
        const wrapper = this.$refs.wrapper;
        if (keep) {
          wrapper.style.minHeight = `${wrapper.clientHeight}px`;
        } else {
          wrapper.style.minHeight = "auto";
        }
      },
      buildSearcher() {
        let searcher = new Fuse(this.tableData, {
          keys: this.computedSearch,
          threshold: 0.1,
          ignoreLocation: true,
        });

        return searcher;
      },
      loadNextPage() {
        this.$refs.pagination.nextPage()
      },
      loadPrevPage() {
        this.$refs.pagination.prevPage()
      },
      countDownTimer() {
        setInterval(() => {
          this.countDown -= 1;
          if (this.countDown === 0) {
            this.countDown = this.secsToRefresh;
					this.$emit('reload');
          }
        }, 1000);
      },
	  searchByExtendedOption() {
		this.searchQuery = "";
		if (!this.hasTextInputSearch) {
			this.searchQuery = this.searchExtendedOption
		}
	  }
	}

};
</script>

<style lang="scss">
  .paginated-table {
    .el-table__fixed-right::before, .el-table::before{
      background-color: rgb(210,212,217);
    }
    .click-drag {
      cursor: grabbing;
      display: flex;
      flex-direction: column;
    }
    .new-button {
      color: white;
      border: none;
      background-color: #f96332;
      margin: .25em 0;
    }
    .new-button:hover {
      color: black;
      border: none;
      background-color: #fdb096;
      margin: .25em 0;
    }
  }
  .paginated-table-card {
    transition: opacity .2s ease;

    &_updating {
      opacity: 0.6;
    }

    &__perpage-select {
      width: 70px;
      input{
        margin: 0 !important;
      }
    }
    &__pagination {
      &-wrapper {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        @media (min-width: 576px) {
          justify-content: space-between;
        }
      }
      &-item {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        @media (min-width: 576px) {
          display: block;
          width: auto;
        }
      }
    }
    .partial_matches_alert {
      font-size: 0.6875rem;
    }
    .el-table .cell {
      word-break: normal;
      padding-top: 1px;
      padding-bottom: 1px;
    }
  }
	span.countdown-timer{
		margin-left: 1em;
	}
	.el-table__empty-block{
		display:none !important;
	}
  .el-table td.el-table__cell {
    border: none;
  }
	.tminh{
		min-height : 50px !important;
	}
	svg.action-iconify {
		color: #fff;
		margin: auto;
	}
 .option_extended {
	 margin-right: 15px;
  margin-left: 10px;
 }
</style>
