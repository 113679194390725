export default {
    recoveryInfo(state) {
        return state.recoveryInfo;
    },
    getUsername(state) {
        return state.username;
    },
    getRecoveryMethod(state) {
        return state.recovery_method;
    },
    getIdentityData(state) {
        return state.identity_data;
    },
    getRecoveryData(state) {
        return state.recovery_data;
    },
    getNewPassword(state) {
        return state.new_password;
    },
    getRecoveryErrorMessage(state) {
        return state.recoveryErrorMessage;
    },
    getPrevPage(state) {
        return state.prevPage;
    }
};