/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'info': {
    width: 20,
    height: 20,
    viewBox: '0 0 48 48',
    data: '<circle pid="0" _fill="#2196F3" cx="24" cy="24" r="21" _stroke="none"/><path pid="1" _fill="#fff" _stroke="none" d="M22 22h4v11h-4z"/><circle pid="2" _fill="#fff" cx="24" cy="16.5" r="2.5" _stroke="none"/>'
  }
})
