/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'new': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<defs><path pid="0" d="M16 23.481c.254 0 .509.06.74.18l6.032 3.153-1.148-6.652a1.598 1.598 0 01.464-1.422l4.858-4.698-6.725-.98c-.52-.074-.97-.4-1.203-.87L16 6.123l-3.018 6.07c-.233.47-.683.796-1.203.87l-6.725.98 4.858 4.698c.38.37.555.9.464 1.422l-1.148 6.652 6.031-3.152c.232-.12.487-.181.741-.181m8.9 7.848c-.255 0-.51-.059-.741-.18L16 26.886l-8.159 4.263a1.6 1.6 0 01-2.317-1.691l1.556-9.006-6.593-6.376a1.597 1.597 0 01-.41-1.642 1.596 1.596 0 011.292-1.091l9.122-1.325 4.075-8.202c.541-1.088 2.327-1.088 2.868 0l4.075 8.202 9.122 1.325c.603.088 1.106.51 1.291 1.091.188.58.03 1.218-.41 1.642l-6.592 6.376 1.556 9.005A1.6 1.6 0 0124.9 31.33" id="svgicon_new_a"/></defs><use _fill="#000" fill-rule="nonzero" xlink:href="#svgicon_new_a"/>'
  }
})
