import DashboardLayout from "@/layout/dashboard/DashboardLayout.vue";

import { networksecurepath } from '../utils';

const AntiVirus = () => import(
  /* webpackChunkName: "ns-services" */
  "@/pages/NetworkSecure/AntiVirus/AntiVirus.vue"
);

const AntiVirusEdit = () => import(
  /* webpackChunkName: "ns-services" */
  "@/pages/NetworkSecure/AntiVirus/AntiVirusEdit.vue"
);

const AdvancedConfiguration = () => import(
  /* webpackChunkName: "ns-services" */
  "@/pages/NetworkSecure/AntiVirus/AdvancedConfiguration/AdvancedConfiguration.vue"
);

export default {
  path: '/ns/AntiVirus',
  component: DashboardLayout,
  name: 'Network Secure / AntiVirus',
  children: [
    {
      path: "Policies/Create",
      name: 'Network Secure / AntiVirus / Filtering Policies / Create',
      component: AntiVirusEdit
    },
    {
      path: "Policies/Edit/*",
      name: 'Network Secure / AntiVirus / Filtering Policies / Edit',
      component: AntiVirusEdit
    },
    {
      path: 'Policies*',
      name: 'Network Secure / AntiVirus / Filtering Policies',
      component: AntiVirus
    },
    {
      path: 'Advanced',
      name: 'Network Secure / AntiVirus / Advanced',
      component: AdvancedConfiguration
    }
  ]
};
