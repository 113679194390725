import axios from "axios";
import i18n from '../../i18n';

function localStorageAvailable(){
	const varname = "VUE_APP_ENABLE_REMEMBER_ME_LOGIN";
	return !!process.env[varname] && process.env[varname].toLowerCase() === 'true'
};

function localStorage_setItem(name, value){
  if (localStorageAvailable()){
    localStorage.setItem(name, value)
  }
};

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
};


export default {
  getCaptcha({ commit }, payload = {}) {
    return new Promise((resolve, reject) => {
      axios.get('/captcha', payload)
        .then(res => {
          const data = res.data;
          commit('setCaptcha', data);
          resolve(data);
        })
        .catch(err => {
          commit('setError', err, { root: true });
          reject(err);
        });
    });
  },
  checkCaptcha({ commit }, payload = {}) {
    return new Promise((resolve, reject) => {
      axios.post('/captcha', payload)
        .then(res => {
          const data = res.data;
          resolve(data);
        })
        .catch(err => {
          commit('setError', err.response ? err.response.data : err, { root: true });
          reject(err);
        });
    });
  },
  renewPassword({ commit, state, dispatch }, authData) {

    return new Promise((resolve, reject) => {
      axios.post('/identity/changepasswordexpired', authData, {
        headers: {
          'x-wait-on-loading': 0
        }
      })
        .then(() => {
          commit('resetExpiredPassword')
          resolve()
        })
        .catch(error => {
          /* 					if ( error.response.status === 400 || error.response.status === 404){
                  let err = { status: 400,
                              type: 'IDENTITY.PASSWORD_LOWER_MIN',
                              "check_errors": {
                                  "password_complexity": {
                                      "check_keyboard_pattern": false,
                                      "check_mirrored_sequence": false,
                                      "check_repeated_sequence": false,
                                      "check_sequential_letters": false,
                                      "check_sequential_numbers": false,
                                      "minimum_lowercase_required": 1,
                                      "minimum_middle_number_digits_required": 0,
                                      "minimum_middle_symbols_required": 0,
                                      "minimum_number_digits_required": 0,
                                      "minimum_password_length": 6,
                                      "minimum_symbols_required": 1,
                                      "minimum_uppercase_required": 1
                                  },
                                  "password_policy": {
                                      "lock_time": 60,
                                      "max_incorrect_password_attempts": 7
                                  },
                                  "password_rotation": {
                                      "history_check_depth": 0,
                                      "maximum_elapsed_time_without_change": 90,
                                      "minimum_elapsed_time_to_change": 130,
                                      "rotate_on_first_login": false
                                  }
                              }
                          }
                  reject(err)
              }
*/
          if (error.response.status === 400) {
            if (error.response.data.type == 'IDENTITY.USER_OR_PREV_PASS_INVALID') {
              commit('setErrorAll', i18n.t('incorrect_user_pass'), { root: true });
            }
            reject(error.response.data)
          }
          else if (error.response.status === 401) {
            commit('setErrorAll', i18n.t(data.type), { root: true });
            /*switch(error.response.data.type){
              case 'CAPTCHA.MISSING':
                commit('setErrorAll', i18n.t('missing_captcha'));
                break;
              case 'CAPTCHA.INCORRECT':
                commit('setErrorAll', i18n.t('incorrect_captcha'));
                break;
              case 'CAPTCHA.EXPIRED':
                commit('setErrorAll', i18n.t('captcha_expired'));
                break;
              default:
                commit('setErrorAll', i18n.t('error_captcha'));
            }*/
            reject({ refreshCaptcha: true })
          }
          else {
            commit('setError', error, { root: true });
            reject({ refreshCaptcha: true })
          }
        })
    });
  },
  async requestAccessToken({ commit, state }) {
    if (!state.refreshToken)
      return new Promise((resolve, reject) => {
        reject(false)
      });
    if (state.refreshing) {
      while (state.refreshing)
        await sleep(500)
      return new Promise((resolve, reject) => {
        resolve(state.accessToken)
      });
    }

    commit('setRefreshing', true)
    return new Promise((resolve, reject) => {
      axios.post('/identity/refreshtoken', { refresh_token: state.refreshToken })
        .then(res => {
          if (res && res.data) {
            let info = res.data.access_token.split('.')
            info = JSON.parse(atob(info[1]))

            // Testing purpose - JWT roles property not implemented yet
            //info.roles = ['Operations Administrator'];
            // Testing purpose
              
            commit('setUserRoles', info.roles);
            commit('setAccessToken', { 
                  token: res.data.access_token, 
                  expires: state.expiresAccess, 
                  privileges: info.privileges ? info.privileges : []
            })
            commit('setRefreshToken', { token: res.data.refresh_token, expires: state.expiresRefresh})
            localStorage_setItem('tk', res.data.refresh_token)
            /*commit('setRefreshing', false)
            resolve(res.data.access_token)*/
          }
          else {

            localStorage.removeItem('tk')
            localStorage.removeItem('localRoles')
            commit('clearAuthData')
            commit('reset', null, { root: true })
            commit('setRefreshing', false)
            reject(false)
          }
        }).then(() => {
          let roles = state.userRoles
          let vm = this;
          if (roles.length > 0) {
            axios.get('/roles/' + encodeURIComponent(roles[0]))
              .then(res => {
                if (res && res.data && res.data.permissions) {
                  // Testing purpose - JWT roles property not implemented yet
                  /*try {
                    let user = vm.getters.id;
                    commit('setRolePermissions', rolePermissions[user].permissions)
                    //localStorage.setItem('userPermissions',JSON.stringify(rolePermissions[user].permissions))
                  } catch (error) {
                    let user = 'admin';
                    commit('setRolePermissions', rolePermissions[user].permissions)
                    //localStorage.setItem('userPermissions',JSON.stringify(rolePermissions[user].permissions))
                  }*/
                  // Testing purpose
    
                  commit('setRolePermissions', {
                      userPermissions: res.data.permissions,
                      userForbiddens: !!res.data.forbiddens ? res.data.forbiddens : null
                  });
                  commit('setRefreshing', false)
                  resolve(state.accessToken)
                } else {
                  console.log('Administrator error:','no permissions assigned to role ' + roles[0])
                }
              })
          } else {
            console.log('Administrator error:', 'no roles assigned')
          }
        }).catch(err => {
          console.log(err)
          localStorage.removeItem('tk')
          localStorage.removeItem('localRoles')
          commit('clearAuthData')
          commit('reset', null, { root: true })
          commit('setRefreshing', false)
          reject(err)
        })
    })
  },

  refreshRefreshToken({ commit }, token) {
    let info = token.split('.')
    if (info.length != 3) {
      console.error('Invalid token - ' + token)
      return false
    }
    info = JSON.parse(atob(info[1]))
    if (!info) {
      console.error('Invalid token - ' + token)
      return false
    }

    // {"typ":"JWT","alg":"HS512"}{"user_payload":
    //		{"manager_id":"5e38011d8dfded46faab124d","username":"identity_1@domain.com","name":"David Dom\u00ednguez",
    //			"sub":"5e38011d8dfded46faab124d"},"iss":"AllotSecure-Identity","iat":1581405257.2029982,"nbf":1581405257.2029982,"exp":1582010057}

    try {
      localStorage.setItem('userId', info.user_payload.username)
      localStorage.setItem('managerId', info.user_payload.manager_id)
      localStorage_setItem('tk', token)
      commit('authUser', {
        id: info.user_payload.username,
        user: info.user_payload.name,
        manager_id: info.user_payload.manager_id,
        type: info.user_payload.additional_payload ? info.user_payload.additional_payload : info.user_payload.identity_provider_type
      })

      let expires = Math.floor(info.exp - info.iat - 1)
      if (expires <= 0)
        expires = 1800

      commit('setRefreshToken', { token: token, expires: expires })
    }
    catch (err) {
      console.error('Invalid token - ' + token)
      return false
    }
  },

  refreshAccessToken({ commit }, token) {
    let info = token.split('.')
    if (info.length != 3) {
      console.error('Invalid token - ' + token)
      return false
    }
    info = JSON.parse(atob(info[1]))
    if (!info) {
      console.error('Invalid token - ' + token)
      return false
    }
    // {"typ":"JWT","alg":"HS512"}{
    // 		"manager_id":"5e38011d8dfded46faab124d","username":"identity_1@domain.com","name":"David Dom\u00ednguez","roles":"",
    //			"sub":"5e38011d8dfded46faab124d","iss":"AllotSecure-Identity","iat":1581405257.2051897,"nbf":1581405257.2051897,"exp":1581405557}

    
    if (!localStorage.getItem('localRoles') || localStorage.getItem('localRoles') && localStorage.getItem('localRoles') === JSON.stringify(info.roles)) {
      try {
        // Testing purpose - JWT roles property not implemented yet
        //info.roles = ['Operations Administrator'];
        // Testing purpose
          
        commit('setUserRoles', info.roles);
        localStorage.setItem('userId', info.username)
        localStorage.setItem('managerId', info.manager_id)
        localStorage.setItem('localRoles', JSON.stringify(info.roles))
        commit('authUser', {
          id: info.username,
          user: info.name,
          manager_id: info.manager_id,
          type: info.additional_payload ? info.additional_payload : info.identity_provider_type
        })

        let expires = Math.floor(info.exp - info.iat)
        if (expires <= 0)
          expires = 300

        commit('setAccessToken', { token: token, expires: expires, privileges: info.privileges ? info.privileges : [] })
        //const now = new Date()
        //const expirationDate = new Date(now.getTime() + expires * 1000)
      }
      catch (err) {
        console.error('Invalid token - ' + token)
        return false
      } 
    } else {
      dispatch('logout');
      return false;
    }
  },

  login({ commit, state, rootState, dispatch, rootGetters, getters }, authData) {
    if (! /^[\000-\177]*$/.test(authData.username + authData.password)) {
      return new Promise((resolve, reject) => {
        commit('setErrorAll', i18n.t('non-ASCII characters in login or password'), {root: true});
        reject()
      })
    }

    let privileges = authData.privileges
    if (!Array.isArray(privileges) || privileges.length === 0){
      localStorage.removeItem('tk')
      commit('clearAuthData')
      localStorage.setItem('userId', authData.username)
      commit('storeUser', { userId: authData.username, managerId: null })
    }

    // demo access on GUI
    if (rootState.settings.debug) {
      localStorage_setItem('tk', 'fake')
      localStorage.setItem('managerId', authData.username)

      //commit('setSingleAdmin', authData.username === 'basic')
      commit('authUser', {
        id: authData.username,
        user: authData.username,
        manager_id: authData.username,
        type: authData.additional_payload ? authData.additional_payload : authData.identity_provider_type,
      })
      commit('setRefreshToken', { token: 'fake', expires: 10000000 })
      commit('setAccessToken', { token: 'fake', expires: 10000000, privileges: [] })
      let rolePermissions = {
        permissions: [
          { actions: ['GET', 'POST', 'PUT', 'PATCH', 'DELETE'], path: '/*' }
        ],
        protected: true,
        role_id: 'Operations Administrator'
      }
      commit('setRolePermissions', {
        userPermissions: rolePermissions,
        userForbiddens: null
      });

      return new Promise((resolve, reject) => {
        resolve()
      })
    }

    return new Promise((resolve, reject) => {
      axios.post('/identity/login',
        {
          captcha: authData.captcha,
          identity_provider_id: authData.identity_provider_id,
          privileges: authData.privileges
        },
        {
        auth: {
          username: unescape(encodeURIComponent(authData.username)),
          password: unescape(encodeURIComponent(authData.password))
        },
        headers: {
          'x-wait-on-loading': 0
        }
      })
        .then(res => {
          // closes re-authentication dialog, if any
          commit('toggleReauthenticationModal', { toggle: false});
          
          let data = res.data

          if (typeof res.data === 'string')
            data = JSON.parse(res.data)

          let acs_tk = data.access_token
          if (acs_tk.indexOf('Bearer ') == 0)
            acs_tk = acs_tk.split(' ')[1]
          dispatch('refreshAccessToken', acs_tk)

          let rfr_tk = data.refresh_token
          if (rfr_tk.indexOf('Bearer ') == 0)
            rfr_tk = rfr_tk.split(' ')[1]
          dispatch('refreshRefreshToken', rfr_tk)

          //resolve()
        })
        .then(() => {
          axios.get('/manager-loggedin')
            .then((res) => {
              if (res.data && res.data.manager_metadata && res.data.manager_metadata.language_id) {
                let defLanguage = res.data.manager_metadata.language_id.includes("-") ? res.data.manager_metadata.language_id.slice(0, (res.data.manager_metadata.language_id.indexOf('-'))) : 'en';
                if (rootGetters['settings/getLanguage'] != defLanguage) dispatch('settings/setLanguage', defLanguage, { root: true });
              }
            })
        })
        .then(() => {
          let roles = rootGetters['auth/userRoles'];
          if (roles.length > 0) {
            axios.get('/roles/' + encodeURIComponent(roles[0]))
              .then(res => {
                if (res && res.data && res.data.permissions) {
							// Testing purpose - JWT roles property not implemented yet
              /*try {
                let user = rootGetters['auth/id'];
                commit('setRolePermissions', rolePermissions[user].permissions)
                // localStorage.setItem('userPermissions',JSON.stringify(rolePermissions[user].permissions))
              } catch (error) {
                let user = 'admin';
                commit('setRolePermissions', rolePermissions[user].permissions)
                // localStorage.setItem('userPermissions',JSON.stringify(rolePermissions[user].permissions))
              }*/

							// Testing purpose

							/*commit('setRolePermissions', res.data.permissions)
							localStorage.setItem('userPermissions',JSON.stringify(res.data.permissions))*/
                  
                  commit('setRolePermissions', {
                    userPermissions: res.data.permissions,
                    userForbiddens: !!res.data.forbiddens ? res.data.forbiddens : null
                  });
                  
                  resolve()
                } else {
                  console.log('Administrator error:', 'no permissions assigned to role ' + roles[0])
                  reject()
                }
              })
              .catch(error => {
                commit('setError', error, { root: true })
                reject()
              })
          } else {
            console.log('Administrator error:', 'no roles assigned')
            reject()
          }

          dispatch('settings/getSubsystemServices', null, {root: true}).then(() => {
            if (rootGetters['settings/getProductState']('NetworkSecure')){
              dispatch('generalManagement/license/getLicenseAlert', null, {root: true})
            }
          })
          dispatch('settings/getSubsystemsPackages', null, {root: true});
        })
        .catch(error => {
          let { data } = error.response;

          switch(error.response.status){
            case 401:
              if (!!error.response.data && 
                      !!error.response.data.type && 
                      ['IDENTITY.EXPIRED_PASSWORD', 'IDENTITY.PASSWORD_MUST_CHANGE'].includes(error.response.data.type)){
                commit('setExpiredPassword')
                reject({ expired: true })
              }
              else {
                commit('setErrorAll', i18n.t('incorrect_user_pass'), { root: true });
              }
              break;
            case 419:
              commit('setErrorAll', msg ? msg : i18n.t('password_expired'), { root: true });
              break;
            default:
              commit('setErrorAll', i18n.t('incorrect_user_pass'), { root: true });
          }

          reject({ refreshCaptcha: true });
        })
    })
  },
  logout({ commit, state, rootState }) {
    if (!rootState.settings.debug && state.refreshToken) {
      return new Promise((resolve, reject) => {
        axios.post('/identity/logout', { refresh_token: state.refreshToken })
          .then(res => {
            localStorage.removeItem('tk')
            localStorage.removeItem('localRoles')
            commit('generalManagement/license/clearLicenseInterval', null, { root: true })
            commit('clearAuthData')
            commit('reset', null, { root: true })

            resolve()
          }).catch(err => {
            localStorage.removeItem('tk')
            localStorage.removeItem('localRoles')
            commit('generalManagement/license/clearLicenseInterval', null, { root: true })
            commit('clearAuthData')
            commit('reset', null, { root: true })

            resolve()
          })
      })
    }
    else {
      return new Promise((resolve, reject) => {
        localStorage.removeItem('tk')
        localStorage.removeItem('localRoles')
        commit('generalManagement/license/clearLicenseInterval', null, { root: true })
        commit('clearAuthData')
        commit('reset', null, { root: true })

        resolve()
      })
    }
  },
  autoLogin({ commit, state, dispatch, rootState }) {
    const userId = localStorage.getItem('userId')
    const managerId = localStorage.getItem('managerId')
    commit('storeUser', { userId: userId, managerId: managerId })

    const token = localStorage.getItem('tk')
    if (!token) {
      return
    }

    if (!rootState.settings.debug) {
      dispatch('refreshRefreshToken', token);
      dispatch('requestAccessToken').catch((err) => { }).then((res) => {
        if (res) dispatch('refreshAccessToken', res)
      })
    }
  },
  getSsoConfiguration({ commit, state, dispatch, rootState }, enterprise) {
    if (rootState.settings.debug) {
      return new Promise((resolve, reject) => {
        commit('setError', i18n.t('Option not available'), { root: true });
        reject()
      })
    } else {
      const url = `/customer-support/log-as/sso/config?type=${enterprise? 'ent' : 'res'}`
      return new Promise((resolve, reject) => {
        axios.get(url)
          .then(res => {
            if (res) {
              resolve(res)
            }
            else {
              reject()
            }
          })
          .catch(error => {
            commit('setError', error, { root: true });
            reject()
          })
      });
    }
  },
  submitSsoConfiguration({ commit, state, rootState, dispatch }, { data, enterprise }) {
    if (rootState.settings.debug) {
      return new Promise((resolve, reject) => {
        let res = {
          "data": data,
          "enterprise": enterprise,
          "response": "OK"
        };
        resolve(res);
      })
    } else {
      const url = `/customer-support/log-as/sso/config?type=${enterprise? 'ent' : 'res'}`
      return new Promise((resolve, reject) => {
        axios.put(url, data)
          .then(res => {
            if (res) {
              resolve({ "res": res, "data": data, "url": url });
            }
          })
          .catch(error => {
            commit('setError', error, { root: true });
            reject()
          })
      });
    }
  },
}