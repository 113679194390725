import DashboardLayout from "@/layout/dashboard/DashboardLayout.vue";

const Frame = () => import(
  /* webpackChunkName: "common" */
  "@/components/ReverseProxyFrame.vue"
);

const Icons = () => import(
  /* webpackChunkName: "common" */
  "@/pages/Icons.vue"
);

export default {
  path: '/testing',
  component: DashboardLayout,
  name: 'Testing',
  children: [
    {
      path: "icons",
      name: "icons",
      component: Icons
    },  
    { path: 'legacy_gui', 
      name: 'Testing / Legacy GUI',
      component: Frame,
      props: (route) => ({
        all: route,
        path: '',
        prefix: '',
        url: 'default2.htmopt',
      }),
    },
  ]
};
