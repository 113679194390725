<template>
  <div id="mapid">
    <l-map :zoom="zoom" 
        :bounds="bounds"
        @update:center="centerUpdate"
        @update:zoom="zoomUpdate"
        @update:bounds="boundsUpdate"
        ref="map">
      <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
      <l-marker v-for="device in devices" 
            :key="device.id"
            :lat-lng="device.location">
            <l-tooltip>{{ device.description }}</l-tooltip>
            <l-popup>{{ getTimestamp(device) }}</l-popup>
            <l-icon title="kk">
                <svgicon :original="isOriginal(device.type)" class="icon" :icon="iconClass(device.type)" 
                                                                        width="36" height="36" style="fill: none;"/>
                <!--<i :class="['isp-icon', getIconType(device.type)]"></i>-->
            </l-icon>
        </l-marker>
    </l-map>
  </div>
</template>

<script>
import { LMap, LTileLayer, LMarker, LTooltip, LPopup, LIcon } from 'vue2-leaflet';
import { DateTime as objDateTime} from 'luxon'

export default {
    name: 'devices-map',
    components: {
        objDateTime,
        LMap,
        LTileLayer,
        LMarker,
        LTooltip,
        LPopup,
        LIcon,
    },
    props: {
        devices: {
            type: Array,
            default: () => []
        },
        selected: {
            type: Object
        }
    },
    data() {
        return {
            zoom: 5,
            //center: L.latLng(0,0),
            url:'https://{s}.tile.osm.org/{z}/{x}/{y}.png',
            attribution:'&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors',
        };
    },
    computed: {
        bounds() {
            const bounds = L.latLngBounds(this.devices.map((o) => o.location));
            return bounds;
        },
    },
    watch: {
        selected: function(newValue, oldValue){
            if ( newValue == oldValue)
                return
            if (!newValue || !newValue.location || Object.keys(newValue.location).length == 0)
                this.$refs.map.mapObject.flyToBounds(this.bounds)
            else{
                this.$refs.map.mapObject.flyTo(L.latLng(newValue.location.lat, newValue.location.lng))
            }
        },
    },
    methods: {
        refresh: function() {
            this.$refs.map.mapObject.invalidateSize();
            try{
                this.$refs.map.mapObject.flyTo(L.latLng(this.selected.location.lat, this.selected.location.lng))
            }
            catch(err){}
        },
        zoomUpdate (zoom) {
            //console.log(`Zoom ${zoom}`);
        },
        centerUpdate (center) {
            //console.log(`Center ${center}`);
        },
        boundsUpdate (bounds) {
            //console.log(bounds);
        },
        isOriginal(type){
            if (type == 'pc' || type == 'mobile')
                return false;
            else
                return true;
        },
        iconClass(type){
            switch (type){
                case 'pc': return 'device-macbook';
                case 'mobile': return 'device-iphone';
                case 'tablet': return 'device-tablet';
                case 'mediacenter': return 'device-cast';
                case 'printer': return 'device-printer';
                case 'console': return 'device-console';
                default: return 'device-other';
            }
        },
        getTimestamp(device) {
            if ( device.location && device.location.timestamp){
                let time = this.datetimeFromISO(device.location.timestamp)
                if ( time)
                    return this.t('last seen') + ' ' +this.stringToISOTime(time)
            }
            return this.t('last seen') + ' ' + this.t('never')
        },
        datetimeFromISO (string) {
            const datetime = objDateTime.fromISO(string) //.toUTC()
            return datetime.isValid ? datetime : null
        },
        stringToISODate(string){
            return this.datetimeFromISO(string).toISO()
        },
        stringToISOTime(date){
            return date.toLocaleString(objDateTime.DATETIME_SHORT_WITH_SECONDS)
        }

    },
}
</script>

<style scoped>
    #mapid { 
        overflow: hidden;
        height: 300px;
    }
    .icon {
        text-align: center;
    }
</style>