<template>
  <b-container fuild style="max-width: 2560px;">
    <b-row>
      <b-col lg="12" class="row-eq-height">
          <b-card class="card-user pl-3 pr-3" no-body>
        <b-card-body style="min-height: auto;" class="pb-0 mt-1">
        <div style="display: flex;max-width: 2560px;" >
            <h3 class="mt-1 mb-2">{{t('headerText')}}</h3>
            <n-button class="mt-1 mb-2" style="margin-left: auto; min-width: 120px; width: 15%; max-height: 40px; z-index: 1;"
            type="primary"
            size="md" round block
            :disabled='disableSubmit'
            :visible_for_permissions='[{actions: ["PATCH"], path: "/subsystems/ns/service-configuration"}]'
            @click.native="saveFilteringConfig">
            {{t('save')}}
            </n-button>
        </div>
        </b-card-body>

        <template v-slot:footer>
        <div class="hr-grey mb-2" style="margin-left: -30px; margin-right: -30px;"/>
        <div @click="refresh" v-if="!updating" style="cursor: pointer;display: inline;">
            <i class="now-ui-icons arrows-1_refresh-69" />
            {{ t('refresh') }}
        </div>
        <div v-else>
            <i class="now-ui-icons loader_refresh spin"/>
            {{ t('refreshing') }}
        </div>
        </template>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="12" style="flex-direction: column;">
        <b-card  class="entranceFromLeft row-eq-height">
            <h5 class="title" style="display: flex; align-items: center;">
            <svgicon class="icon mr-2" icon="item-blockingconfiguration" width="20" height="20" />
            <span style="margin-top: 4px;">{{t('HTTPS Configuration')}}</span>
            </h5>
            <div><n-checkbox :title="t('Enable SSL-Inspection')"
            :checked="this.https_configuration.enable_ssl_inspection"
            @input="statusChanged($event, 'enable_ssl_inspection')"
            :visible_for_permissions='[{actions: ["PATCH"], path: "/subsystems/ns/service-configuration"}]'
            /></div>
        </b-card>
      </b-col>
      <b-col lg="12">
        <b-card class="entranceFromRight row-eq-height">
            <h5 class="title" style="display: flex; align-items: center;">
            <svgicon class="icon mr-2" icon="item-blockingconfiguration" width="20" height="20" />
            <span style="margin-top: 4px;">{{t('Certificate validation')}}</span>
            </h5>
            <b-card-text class='pl-4 pr-4 mt-4'>
            <div style="display: flex; align-items: center; justify-content: space-between;">
                <label class="mr-2" style="min-width: 150px;">{{t('ssl_inspection_blocking_page')}}</label>
                <el-select class="select-primary mr-3"
                style="min-width: 130px;"
                size="large"
                :disabled="!this.https_configuration.enable_ssl_inspection"
                v-model="https_configuration.certificate_validation.ssl_inspection_blocking_page.page_mode">
                <el-option v-for="(option, index) in blockingPageMods"
                    class="select-primary"
                    :value="option.value"
                    :label="option.label"
                    :key="`HTTP_blocking_${index}`">
                </el-option>
                </el-select>
                <fg-input v-model="https_configuration.certificate_validation.ssl_inspection_blocking_page.custom_ssl_inspection_blocking_page_url"
                v-if="https_configuration.certificate_validation.ssl_inspection_blocking_page.page_mode === 'custom'"
                style="width: 100%;"
                class="mb-0"
                id="ssl_inspection_blocking_page"
                :name="t('ssl_inspection_blocking_page')"
                placeholder="URL"
                autocapitalize="yes"
                v-validate="{ required: customBlockingPageIsRequired, url: { require_protocol: true }, max: 2048 }"
                :visible_for_permissions='[{actions: ["PATCH"], path: "/subsystems/ns/service-configuration"}]'
                :disabled="!this.https_configuration.enable_ssl_inspection"
                :error="getError(t('ssl_inspection_blocking_page'))">
                </fg-input>
                <fg-input v-model="defaultBlockingPageText"
                v-if="https_configuration.certificate_validation.ssl_inspection_blocking_page.page_mode === 'default'"
                style="width: 100%;"
                class="mb-0"
                id="default_ssl_inspection_blocking_page"
                name="default_ssl_inspection_blocking_page"
                autocapitalize="yes"
                :disabled="true">
                </fg-input>
            </div>
            </b-card-text>
            <b-card-text class='pl-4 pr-4 mt-4'>
            <div style="display: flex; align-items: center; justify-content: space-between;">
                <label class="mr-2" style="min-width: 150px;">{{t('ssl_ask_user_blocking_page')}}</label>
                <el-select class="select-primary mr-3"
                style="min-width: 130px;"
                size="large"
                :disabled="!this.https_configuration.enable_ssl_inspection"
                v-model="https_configuration.certificate_validation.ssl_ask_user_blocking_page.page_mode">
                <el-option v-for="(option, index) in blockingPageMods"
                    class="select-primary"
                    :value="option.value"
                    :label="option.label"
                    :key="`HTTP_blocking_${index}`">
                </el-option>
                </el-select>
                <fg-input v-model="https_configuration.certificate_validation.ssl_ask_user_blocking_page.custom_ssl_ask_user_blocking_page_url"
                v-if="https_configuration.certificate_validation.ssl_ask_user_blocking_page.page_mode === 'custom'"
                style="width: 100%;"
                class="mb-0"
                id="blocking_page"
                :name="t('blockingPage')"
                placeholder="URL"
                autocapitalize="yes"
                v-validate="{ required: customAskUserBlockingPageIsRequired, url: { require_protocol: true }, max: 2048 }"
                :visible_for_permissions='[{actions: ["PATCH"], path: "/subsystems/ns/service-configuration"}]'
                :disabled="!this.https_configuration.enable_ssl_inspection"
                :error="getError(t('blockingPage'))">
                </fg-input>
                <fg-input v-model="defaultAskUserBlockingPageText"
                v-if="https_configuration.certificate_validation.ssl_ask_user_blocking_page.page_mode === 'default'"
                style="width: 100%;"
                class="mb-0"
                id="default_blocking_page"
                name="default_blocking_page"
                autocapitalize="yes"
                :disabled="true">
                </fg-input>
            </div>
            </b-card-text>
            <b-row>
              <b-col class="row-eq-height col-xs-12 col-sm-12 col-md-12 col-lg-6" style="flex-direction: column;">
                <div>
                  <b-card-text>
                  <label class="mr-2 mt-4">{{t('Digital Certificates')}}</label>
                  <div style="display: flex; align-items: center; justify-content: space-between;">
                    <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                      <div><n-checkbox :title="t('Enable Digital Certificates Validation')"
                      :checked="this.https_configuration.certificate_validation.digital_certificates.enable_digital_certificate_validation"
                      @input="statusChanged($event, 'certificate_validation.digital_certificates.enable_digital_certificate_validation')"
                      :disabled="!this.https_configuration.enable_ssl_inspection"
                      :visible_for_permissions='[{actions: ["PATCH"], path: "/subsystems/ns/service-configuration"}]'/></div>
                      <div class="pl-2 pr-2 mt-3">
                        <div><span>{{t('Conditions')}}:</span></div>
                        <div class="ml-2 mr-2">
                          <div><n-checkbox :title="t('Valid')"
                          :checked="isChecked(this.https_configuration.certificate_validation.digital_certificates.conditions, 'valid')"
                          :disabled="!this.https_configuration.enable_ssl_inspection || !this.https_configuration.certificate_validation.digital_certificates.enable_digital_certificate_validation"
                          @input="conditionChanged($event, 'digital_certificates', 'valid')"
                          :visible_for_permissions='[{actions: ["PATCH"], path: "/subsystems/ns/service-configuration"}]'/></div>
                          <div><n-checkbox :title="t('Invalid common name')"
                          :checked="isChecked(this.https_configuration.certificate_validation.digital_certificates.conditions, 'invalid_common_name')"
                          :disabled="!this.https_configuration.enable_ssl_inspection || !this.https_configuration.certificate_validation.digital_certificates.enable_digital_certificate_validation"
                          @input="conditionChanged($event, 'digital_certificates', 'invalid_common_name')"
                          :visible_for_permissions='[{actions: ["PATCH"], path: "/subsystems/ns/service-configuration"}]'/></div>
                          <div><n-checkbox :title="t('Invalid authority')"
                          :checked="isChecked(this.https_configuration.certificate_validation.digital_certificates.conditions, 'invalid_authority')"
                          :disabled="!this.https_configuration.enable_ssl_inspection || !this.https_configuration.certificate_validation.digital_certificates.enable_digital_certificate_validation"
                          @input="conditionChanged($event, 'digital_certificates', 'invalid_authority')"
                          :visible_for_permissions='[{actions: ["PATCH"], path: "/subsystems/ns/service-configuration"}]'/></div>
                        </div>
                      </div>
                    </div>
                    <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                      <div><n-checkbox :title="t('Apply only to inspected connections')"
                      :checked="this.https_configuration.certificate_validation.digital_certificates.apply_only_to_inspected_connections"
                      :disabled="!this.https_configuration.enable_ssl_inspection || !this.https_configuration.certificate_validation.digital_certificates.enable_digital_certificate_validation"
                      @input="statusChanged($event, 'certificate_validation.digital_certificates.apply_only_to_inspected_connections')"
                      :visible_for_permissions='[{actions: ["PATCH"], path: "/subsystems/ns/service-configuration"}]'/></div>
                      <div class="pl-2 pr-2 mt-3">
                      <div class="mt-1"><span>&zwnj;</span></div>
                        <div class="ml-2 mr-2">
                          <div><n-checkbox :title="t('Self signed')"
                          :checked="isChecked(this.https_configuration.certificate_validation.digital_certificates.conditions, 'self_signed')"
                          :disabled="!this.https_configuration.enable_ssl_inspection || !this.https_configuration.certificate_validation.digital_certificates.enable_digital_certificate_validation"
                          @input="conditionChanged($event, 'digital_certificates', 'self_signed')"
                          :visible_for_permissions='[{actions: ["PATCH"], path: "/subsystems/ns/service-configuration"}]'/></div>
                          <div><n-checkbox :title="t('Expired')"
                          :checked="isChecked(this.https_configuration.certificate_validation.digital_certificates.conditions, 'expired')"
                          :disabled="!this.https_configuration.enable_ssl_inspection || !this.https_configuration.certificate_validation.digital_certificates.enable_digital_certificate_validation"
                          @input="conditionChanged($event, 'digital_certificates', 'expired')"
                          :visible_for_permissions='[{actions: ["PATCH"], path: "/subsystems/ns/service-configuration"}]'/></div>
                          <div><n-checkbox :title="t('Error')"
                          :checked="isChecked(this.https_configuration.certificate_validation.digital_certificates.conditions, 'error')"
                          :disabled="!this.https_configuration.enable_ssl_inspection || !this.https_configuration.certificate_validation.digital_certificates.enable_digital_certificate_validation"
                          @input="conditionChanged($event, 'digital_certificates', 'error')"
                          :visible_for_permissions='[{actions: ["PATCH"], path: "/subsystems/ns/service-configuration"}]'/></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  </b-card-text>
                </div>
              </b-col>
              <b-col class="row-eq-height col-xs-12 col-sm-12 col-md-12 col-lg-6" style="flex-direction: column;">
                <div>
                  <b-card-text>
                  <label class="mr-2 mt-4">{{t('Revocation State')}}</label>
                  <div style="display: flex; align-items: center; justify-content: space-between;">
                    <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                      <div><n-checkbox :title="t('Enable Revocation State Validation')"
                      :checked="this.https_configuration.certificate_validation.revocation_state.enable_revocation_state_validation"
                      :disabled="!this.https_configuration.enable_ssl_inspection"
                      @input="statusChanged($event, 'certificate_validation.revocation_state.enable_revocation_state_validation')"
                      :visible_for_permissions='[{actions: ["PATCH"], path: "/subsystems/ns/service-configuration"}]'/></div>
                      <div class="pl-2 pr-2 mt-3">
                        <div><span>{{t('Conditions')}}:</span></div>
                        <div class="ml-2 mr-2">
                          <div><n-checkbox :title="t('Valid')"
                          :checked="isChecked(this.https_configuration.certificate_validation.revocation_state.conditions, 'valid')"
                          :disabled="!this.https_configuration.enable_ssl_inspection || !this.https_configuration.certificate_validation.revocation_state.enable_revocation_state_validation"
                          @input="conditionChanged($event, 'revocation_state', 'valid')"
                          :visible_for_permissions='[{actions: ["PATCH"], path: "/subsystems/ns/service-configuration"}]'/></div>
                          <div><n-checkbox :title="t('Revoked')"
                          :checked="isChecked(this.https_configuration.certificate_validation.revocation_state.conditions, 'revoked')"
                          :disabled="!this.https_configuration.enable_ssl_inspection || !this.https_configuration.certificate_validation.revocation_state.enable_revocation_state_validation"
                          @input="conditionChanged($event, 'revocation_state', 'revoked')"
                          :visible_for_permissions='[{actions: ["PATCH"], path: "/subsystems/ns/service-configuration"}]'/></div>
                          <div><n-checkbox :title="t('Invalid authority')"
                          :checked="isChecked(this.https_configuration.certificate_validation.revocation_state.conditions, 'invalid_authority')"
                          :disabled="!this.https_configuration.enable_ssl_inspection || !this.https_configuration.certificate_validation.revocation_state.enable_revocation_state_validation"
                          @input="conditionChanged($event, 'revocation_state', 'invalid_authority')"
                          :visible_for_permissions='[{actions: ["PATCH"], path: "/subsystems/ns/service-configuration"}]'/></div>
                        </div>
                      </div>
                    </div>
                    <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                      <div><n-checkbox :title="t('Apply only to inspected connections')"
                      :checked="this.https_configuration.certificate_validation.revocation_state.apply_only_to_inspected_connections"
                      :disabled="!this.https_configuration.enable_ssl_inspection || !this.https_configuration.certificate_validation.revocation_state.enable_revocation_state_validation"
                      @input="statusChanged($event, 'certificate_validation.revocation_state.apply_only_to_inspected_connections')"
                      :visible_for_permissions='[{actions: ["PATCH"], path: "/subsystems/ns/service-configuration"}]'/></div>
                      <div class="pl-2 pr-2 mt-3">
                      <div class="mt-1"><span>&zwnj;</span></div>
                        <div class="ml-2 mr-2">
                          <div><n-checkbox :title="t('No extension')"
                          :checked="isChecked(this.https_configuration.certificate_validation.revocation_state.conditions, 'no_extension')"
                          :disabled="!this.https_configuration.enable_ssl_inspection || !this.https_configuration.certificate_validation.revocation_state.enable_revocation_state_validation"
                          @input="conditionChanged($event, 'revocation_state', 'no_extension')"
                          :visible_for_permissions='[{actions: ["PATCH"], path: "/subsystems/ns/service-configuration"}]'/></div>
                          <div><n-checkbox :title="t('Error')"
                          :checked="isChecked(this.https_configuration.certificate_validation.revocation_state.conditions, 'error')"
                          :disabled="!this.https_configuration.enable_ssl_inspection || !this.https_configuration.certificate_validation.revocation_state.enable_revocation_state_validation"
                          @input="conditionChanged($event, 'revocation_state', 'error')"
                          :visible_for_permissions='[{actions: ["PATCH"], path: "/subsystems/ns/service-configuration"}]'/></div>
                          <div><span>&zwnj;</span></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  </b-card-text>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="row-eq-height col-xs-6 col-sm-6 col-md-6 col-lg-6" style="flex-direction: column;">
                <b-card-text class='pl-4 pr-4 mt-4'>
                      <label class="mr-2" style="min-width: 120px;">{{t('action')}}</label>
                      <el-select class="select-primary mr-3"
                          :disabled="!this.https_configuration.enable_ssl_inspection || !this.https_configuration.certificate_validation.digital_certificates.enable_digital_certificate_validation"
                          v-model="https_configuration.certificate_validation.digital_certificates.action">
                        <el-option v-for="(option, index) in actionsMods"
                          class="select-primary"
                          :value="option.value"
                          :label="option.label"
                          :key="`HTTPS_digital_certificates_${index}`">
                        </el-option>
                      </el-select>
                </b-card-text>
              </b-col>
              <b-col class="row-eq-height col-xs-6 col-sm-6 col-md-6 col-lg-6" style="flex-direction: column;">
                <b-card-text class='pl-4 pr-4 mt-4'>
                      <label class="mr-2" style="min-width: 120px;">{{t('action')}}</label>
                      <el-select class="select-primary mr-3"
                          :disabled="!this.https_configuration.enable_ssl_inspection || !this.https_configuration.certificate_validation.revocation_state.enable_revocation_state_validation"
                          v-model="https_configuration.certificate_validation.revocation_state.action">
                        <el-option v-for="(option, index) in actionsMods"
                          class="select-primary"
                          :value="option.value"
                          :label="option.label"
                          :key="`HTTPS_revocation_state_${index}`">
                        </el-option>
                      </el-select>
                </b-card-text>
              </b-col>
            </b-row>
        </b-card>
      </b-col>
      <b-col lg="12" class="row-eq-height" style="flex-direction: column;">
        <b-card class="entranceFromLeft">
          <div style="display: flex;max-width: 2560px;" >
            <h5 class="title" style="align-items: center;">
            <svgicon class="icon mr-2" icon="item-blockingconfiguration" width="20" height="20" />
            <span style="margin-top: 4px;">{{t('Trusted Certificates')}}</span>
            </h5>
            <div style="margin-left: auto; min-width: 120px; width: 15%;">
              <div>
                <n-button class="mt-1 mb-2" style="max-height: 40px; z-index: 1;"
                type="primary"
                size="md" round block
                :disabled="!this.https_configuration.enable_ssl_inspection"
                :visible_for_permissions='[{actions: ["PATCH"], path: "/subsystems/ns/service-configuration"}]'
                @click.native="newCertificate">
                {{t('New')}}
                </n-button>
              </div>
              <div>
                <n-button class="mt-1 mb-2" style="max-height: 40px; z-index: 1;"
                type="primary"
                size="md" round block
                :disabled="!this.https_configuration.enable_ssl_inspection"
                :visible_for_permissions='[{actions: ["PATCH"], path: "/subsystems/ns/service-configuration"}]'
                @click.native="importCertificate">
                {{t('Import')}}
                </n-button>
              </div>
              <form ref="certificateForm" style="display: none">
                <input type="file" ref="certificateFile" accept=".crt, .cer, .cert, .pem" tabindex="-1" @change="loadfile"/>
              </form>
            </div>
          </div>
            <paginated-table
              :rows="rows"
              :columns="columns"
              :actions="actions"
              :itemsPerPage="query.limit"
              @item-remove="handleRemove"
              :buttonDisabled="buttonDisabled.bind(this)"
            />
        </b-card>
      </b-col>
    </b-row>
    <confirmation-modal ref='confirmation-modal'/>
  </b-container>
</template>

<script>
import Checkbox from '@/components/Inputs/Checkbox.vue';
import { PaginatedTable } from "@/components";
import { parseStringCertificate } from "@/util/certParseUtils"
import ConfirmationModal from "@/components/Modals/ConfirmationModal.vue";

export default {
  name: "sslInspection",
  components: {
    'n-checkbox': Checkbox,
    PaginatedTable,
    ConfirmationModal
  },
  mimetypes: ['application/x-x509-ca-cert', 'application/pkix-cert'],
  data() {
    return {
      https_configuration: {
        certificate_validation: {
          digital_certificates: {
            action: 'block',
            apply_only_to_inspected_connections: false,
            conditions: [],
            enable_digital_certificate_validation: false
          },
          revocation_state: {
            action: 'block',
            apply_only_to_inspected_connections: false,
            conditions: [],
            enable_digital_certificate_validation: false
          },
          ssl_ask_user_blocking_page: {
            custom_ssl_ask_user_blocking_page_url: '',
            page_mode: 'default'
          },
          ssl_inspection_blocking_page: {
            custom_ssl_inspection_blocking_page_url: '',
            page_mode: 'default'
          },
        },
        enable_ssl_inspection: false,
        trusted_certificates: []
      },
      importCert: false,
      blocking_pages: {
        ssl_inspection_blocking_page: {
          name: '',
          url: ''
        },
        ssl_ask_user_blocking_page: {
          name: '',
          url: ''
        },
      },
      loading: false,
      updating: false,
      columns: [
        {
          prop: "common_name",
          label: this.t("Common Name"),
          minWidth: 120,
        },
        {
          prop: "valid_from",
          label: this.t("Valid From"),
          minWidth: 120,
        },
        {
          prop: "valid_to",
          label: this.t("Valid To"),
          minWidth: 120
        },
        {
          prop: "serial_number",
          label: this.t("Serial Number")
        },
        {
          prop: "issuer",
          label: this.t("Issuer")
        },
        {
          prop: "fingerprint",
          label: this.t("Fingerprint")
        },
      ],
      actions: {
        minWidth: 80,
        label: this.t("Actions"),
        fixed: "right",
        items: [
          {
            type: "danger",
            size: "sm",
            icon: "now-ui-icons ui-1_simple-remove",
            event: "item-remove",
            action: this.t("Remove"),
            confirm: true,
            visible_for_permissions: [
              { actions: ["PATCH"], path: "/subsystems/ns/service-configuration" },
            ]
          },
        ],
      },
      query: {
        limit: 10
      },
      https_configuration_orig: null
    };
  },
  computed: {
    disableSubmit() {
      return !!this.errors.items.length || this.loading;
    },
    customBlockingPageIsRequired() {
      return this.https_configuration.certificate_validation.ssl_inspection_blocking_page.page_mode === 'custom';
    },
    customAskUserBlockingPageIsRequired() {
      return this.https_configuration.certificate_validation.ssl_ask_user_blocking_page.page_mode === 'custom';
    },
    defaultBlockingPageText() {
      return `${this.blocking_pages.ssl_inspection_blocking_page.name} - ${this.blocking_pages.ssl_inspection_blocking_page.url}`;
    },
    defaultAskUserBlockingPageText() {
      return `${this.blocking_pages.ssl_ask_user_blocking_page.name} - ${this.blocking_pages.ssl_ask_user_blocking_page.url}`;
    },
    blockingPageMods () {
      return [
        {
          value: 'default',
          label: this.t('default'),
        },
        {
          value: 'custom',
          label: this.t('custom'),
        },
      ];
    },
    actionsMods () {
      return [
        {
          value: 'block',
          label: this.t('block'),
        },
        {
          value: 'ask_user',
          label: this.t('ask_user'),
        },
        {
          value: 'block_with_reset',
          label: this.t('block_with_reset'),
        }
      ];
    },
    rows() {
      let ret = [];
      this.https_configuration.trusted_certificates.forEach((value, key) => {
        try {
          ret.push(parseStringCertificate(value.certificate));
        } catch (error) {
          if (error) console.log("Certificate error", error);
          this.$store.dispatch('setError', this.t('invalidCertificate'), { root: true });
          this.https_configuration.trusted_certificates.splice(key, 1);
          return ret;
        }
      });
      return ret;
    }
  },
  methods: {
    async saveFilteringConfig() {
      this.loading = true;
      if (this.https_configuration.certificate_validation.ssl_ask_user_blocking_page && this.https_configuration.certificate_validation.ssl_ask_user_blocking_page.page_mode && this.https_configuration.certificate_validation.ssl_ask_user_blocking_page.page_mode == 'default') delete this.https_configuration.certificate_validation.ssl_ask_user_blocking_page.custom_ssl_ask_user_blocking_page_url;
      if (this.https_configuration.certificate_validation.ssl_inspection_blocking_page && this.https_configuration.certificate_validation.ssl_inspection_blocking_page.page_mode && this.https_configuration.certificate_validation.ssl_inspection_blocking_page.page_mode == 'default') delete this.https_configuration.certificate_validation.ssl_inspection_blocking_page.custom_ssl_inspection_blocking_page_url;
      const data = {
        webfilter: {
          https_configuration: this.https_configuration
        }
      }

      await this.$store.dispatch('networksecure/contentFilter/updateServiceConfiguration', data);
      this.https_configuration_orig = _.cloneDeep(this.https_configuration);

      this.loading = false;
    },
    newCertificate() {
      this.$refs.certificateFile.click();
    },
    async importCertificate() {
      const isConfirmed = await this.$refs['confirmation-modal'].open({
        title: this.t('Import'),
        message: this.t('importAlert'),
        ok_title: this.$t('accept'),
        cancel_title: this.$t('cancel')
      });

      if (isConfirmed) {
        this.importCert = true;
        this.$refs.certificateFile.click();
      }
    },
    loadfile(){
      let file = this.$refs.certificateFile.files[0];
      if ( !file)
        return;
      if ( !!file.type && !this.$options.mimetypes.includes(file.type))
         this.$store.commit('setError', this.t('Unknown file type'))
      else{
        let reader = new FileReader();
        reader.readAsText(file, "UTF-8");
        reader.onload =  evt => {
        if ( evt.target.result.length > 8192)
          this.$store.commit('setError', this.t('File exceeds maximum size'))
        else if (evt.target.result.length < 8192 && evt.target.result.length > 0) {
          if (this.importCert) {
            this.https_configuration.trusted_certificates = [];
          }
          let cert = evt.target.result.replace(/\r/g, '');
          this.https_configuration.trusted_certificates.push({certificate: cert});
        } else {
          this.$store.dispatch('setError', this.t('invalidCertificate'), { root: true });
        }
          this.importCert = false;
        }
        reader.onerror = evt => {
          console.log(evt)
          this.importCert = false;
          this.$store.dispatch('setError', this.t('invalidCertificate'), { root: true });
        }
      }
    },
    statusChanged(event, id){
        this.setValue(id, event, this.https_configuration);
    },
    conditionChanged(event, id, condition){
        if (event) {
          id == 'digital_certificates' ?
          !this.isChecked(this.https_configuration.certificate_validation.digital_certificates.conditions, condition) &&
          this.https_configuration.certificate_validation.digital_certificates.conditions.push(condition):
          !this.isChecked(this.https_configuration.certificate_validation.revocation_state.conditions, condition) &&
          this.https_configuration.certificate_validation.revocation_state.conditions.push(condition);
        } else {
          id == 'digital_certificates' ?
          this.isChecked(this.https_configuration.certificate_validation.digital_certificates.conditions, condition) &&
          this.https_configuration.certificate_validation.digital_certificates.conditions.splice(this.https_configuration.certificate_validation.digital_certificates.conditions.indexOf(condition), 1):
          this.isChecked(this.https_configuration.certificate_validation.revocation_state.conditions, condition) &&
          this.https_configuration.certificate_validation.revocation_state.conditions.splice(this.https_configuration.certificate_validation.revocation_state.conditions.indexOf(condition), 1);
        }
    },
    setValue(propertyPath, value, obj) {
      let properties = Array.isArray(propertyPath) ? propertyPath : propertyPath.split(".");
      if (properties.length > 1) {
        if (!obj.hasOwnProperty(properties[0]) || typeof obj[properties[0]] !== "object") obj[properties[0]] = {}
        return this.setValue(properties.slice(1), value, obj[properties[0]])
      } else {
        obj[properties[0]] = value
        return true;
      }
    },
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    isChecked(conditions, condition) {
      return conditions.includes(condition);
    },
    async setDefaultBlockingPages() {
      await this.$store.dispatch('networksecure/contentFilter/getBlockingPages');

      const blockingPages = await this.$store.getters['networksecure/contentFilter/blockingPages'];
      const blockingPageName = blockingPages.default_ssl_inspection_blocking_page;
      const askUserBlockingPageName = blockingPages.default_ssl_ask_user_blocking_page;

      let blockingPageUrl = '';
      let askUserBlockingPageUrl = '';

      if (blockingPageName) {
        if (blockingPageName === 'SystemSSLInspectionBlockingPage' && blockingPages.blocking_pages_info) {
          blockingPageUrl = blockingPages.blocking_pages_info.system_ssl_inspection_blocking_page;
        } else {
          const blockingPage = blockingPages.blocking_pages_info.external_blocking_pages
          .find(page => page.name === blockingPageName);

          blockingPageUrl = blockingPage ? blockingPage.blocking_page_url : '';
        }

        Object.assign(this.blocking_pages.ssl_inspection_blocking_page, {
          name: blockingPageName,
          url: blockingPageUrl
        });
      }

      if (askUserBlockingPageName) {
        if (askUserBlockingPageName === 'SystemSSLAskUserBlockingPage' && blockingPages.blocking_pages_info) {
          askUserBlockingPageUrl = blockingPages.blocking_pages_info.system_ssl_ask_user_blocking_page;
        } else {
          const passwordBlockingPage = blockingPages.blocking_pages_info.external_blocking_pages
            .find(page => page.name === askUserBlockingPageName);

          askUserBlockingPageUrl = passwordBlockingPage ? passwordBlockingPage.blocking_page_url : '';
        }

        Object.assign(this.blocking_pages.ssl_ask_user_blocking_page, {
          name: askUserBlockingPageName,
          url: askUserBlockingPageUrl
        });
      }
    },
    async refresh() {
      const isConfirmed = await this.$refs['confirmation-modal'].open({
        title: this.t('refreshTitle'),
        message: this.t('refreshAlert'),
        ok_title: this.$t('accept'),
        cancel_title: this.$t('cancel')
      });

      if (isConfirmed) {
        this.setServiceConfig();
      }
    },
    handleRemove(data) {
      this.https_configuration.trusted_certificates.splice(data.index, 1);
    },
    async setServiceConfig() {
      this.updating = true;

      await this.$store.dispatch('networksecure/contentFilter/getServiceConfiguration');

      const { webfilter } = await this.$store.getters['networksecure/contentFilter/serviceConfiguration'];

      this.setDefaultBlockingPages();

      if (!webfilter) { return; }

      this.https_configuration = _.cloneDeep(webfilter.https_configuration);

      if (!this.https_configuration.certificate_validation.digital_certificates.conditions) this.https_configuration.certificate_validation.digital_certificates.conditions = [];
      if (!this.https_configuration.certificate_validation.revocation_state.conditions) this.https_configuration.certificate_validation.revocation_state.conditions = [];

      this.https_configuration_orig = _.cloneDeep(this.https_configuration);

      this.updating = false;
    },
    buttonDisabled(row, event) {
      return (!this.https_configuration.enable_ssl_inspection && event == 'item-remove') ? true : false;
    }
  },
  created() {
    this.setServiceConfig();
  },
  beforeRouteLeave: async function(to, from, next) {
    if (!_.isEqual(this.https_configuration_orig, this.https_configuration)) {
      const isConfirmed = await this.$refs['confirmation-modal'].open({
        title: ' ',
        message: this.t('confirmation_text'),
        ok_title: this.t('keep_editing'),
        cancel_title: this.t('discard')
      });
      if (!isConfirmed) {
        next();
      }
    } else {
      next();
    }
  }
}
</script>
