/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'item-locationmanagement': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M19.5 17.6c1 0 1.9-.8 1.9-1.9V6.3c0-1-.8-1.9-1.9-1.9h-15c-1 0-1.9.8-1.9 1.9v9.4c0 1 .8 1.9 1.9 1.9H.7v1.9h22.5v-1.9h-3.7zM4.5 6.4h15v9.4h-15V6.4z" _fill="#383132"/><path pid="1" d="M14.4 11.1c0-.1 0-.3-.1-.4l.7-.7-.6-1-1 .3c-.2-.2-.4-.3-.7-.4l-.1-1h-1.3l-.2 1c-.3.1-.5.3-.7.4l-.8-.4-.7 1.1.7.6c0 .1-.1.3-.1.4s0 .3.1.4l-.7.6.6 1.1.9-.3c.2.2.4.3.7.4l.2 1.1h1.3l.2-1c.3-.1.5-.3.7-.4l1 .3.6-1.1-.7-.6c-.1-.2 0-.3 0-.4zM12 12.3c-.7 0-1.3-.6-1.3-1.3s.6-1.3 1.3-1.3 1.3.6 1.3 1.3-.6 1.3-1.3 1.3z" _fill="#383132"/>'
  }
})
