export default {
    setCaptcha(state, captcha) {
        state.captcha = captcha;
    },
    setExpiredPassword(state){
        state.expiredpwd = true
      },
      resetExpiredPassword(state){
        state.expiredpwd = false
      },
      storeUser (state, user) {
        state.userId = user.userId
        state.managerId = user.managerId
      },
      clearAuthData (state) {
        if (state.logoutTimer){
          clearTimeout(state.logoutTimer)
          state.logoutTimer = null
        }
        if (state.accessTimer){
          clearTimeout(state.accessTimer)
          state.accessTimer = null
        }
  
        state.accessToken = null
        state.refreshToken = null
        state.providerAccessType = null
        state.userRoles = null
        state.userPermissions = null
        state.privileges_granted = []
      },
      authUser (state, userData) {
        state.userId = userData.id
        state.user = userData.user
        state.managerId = userData.manager_id
        if (userData.type) {
           userData.type.identity_provider_type ?
          state.providerAccessType = userData.type.identity_provider_type : state.providerAccessType = userData.type
        }
      },
      setAccessToken(state, {token: token, expires: expires, privileges: privileges}){
        state.accessToken = token;
        state.expiresAccess = expires
        state.privileges_granted = privileges
        if (state.accessTimer){
          clearTimeout(state.accessTimer)
          state.accessTimer = null
        }
        state.accessTimer = setTimeout( () => {
          if (state.accessTimer){
            clearTimeout(state.accessTimer)
            state.accessTimer = null
          }
          state.accessToken = null
        }, expires * 1000);
      },
      resetRefreshToken(state){
        state.refreshToken = null
      },    
      setRefreshToken(state, {token: token, expires: expires}){
        state.refreshToken = token;
        state.expiresRefresh = expires
        if (state.logoutTimer){
          clearTimeout(state.logoutTimer)
          state.logoutTimer = null
        }
        state.logoutTimer = setTimeout( () => {
          state.refreshToken = null
          localStorage.removeItem('tk')
        }, expires * 1000);
  
        let info = token.split('.')
        if ( info.length != 3){
          console.error('Invalid token - ' + token)
          return false
        }
        info = JSON.parse(atob(info[1]))
        if (!info){
          console.error('Invalid token - ' + token)
          return false
        }
      },    
      setRolePermissions(state, { userPermissions, userForbiddens}){
        state.userPermissions = userPermissions;
        state.userForbiddens = userForbiddens
      },    
      setUserRoles(state, userRoles){
        state.userRoles = userRoles;
      },
      clearAccessToken(state){
        if (state.accessTimer){
          clearTimeout(state.accessTimer)
          state.accessTimer = null
        }
        state.accessToken = null
      },   
      setRefreshing(state, refresh){
        state.refreshing = refresh
      },


      toggleReauthenticationModal( state, { toggle, privileges_requested, retry_data}){
        state.privileges_requested = toggle ? privileges_requested :[]
        if (toggle)
          state.retry_data = retry_data
        state.showReauthenticationModal = toggle
      }
}