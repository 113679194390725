import * as queries from './queries';
import i18n from '../../i18n';

export async function getEndpointSecureSettings({ dispatch, commit }) {
  try {
    const settings = await queries.getEndpointSecureSettings();
    commit('SET_ENDPOINT_SECURE_SETTINGS', settings);
  } catch (err) {
    dispatch('setError', err, { root: true });
  }
}

export async function updateEndpointSecureSettings({ dispatch }, settings) {
  try {
    await queries.updateEndpointSecureSettings(settings);
    await dispatch('getEndpointSecureSettings');
    const message = i18n.t('save_success')
    dispatch('setSuccess', message, { root: true });
  } catch (err) {
    dispatch('setError', err, { root: true });
  }
}
