import axios from 'axios';

export default {
    getConfiguration({ commit, state, dispatch, getters, rootGetters }) {
        if (rootGetters.isDummy) {
            return new Promise((resolve, reject) => {
                const cfg = {
                    web: "http://www.allot.com",
                    companyName: 'Allot',
                    appName: 'Secure Management',
                    footerLogin: true,
                    footerLoginImage: true
                }
                commit('addConfiguration', cfg)
                resolve(cfg)
            });
        }
        else {
            return new Promise((resolve, reject) => {
                axios.get('/customization')
                    .then(res => {
                        if (res && res.data) {
                            const cfg = {
                                web: !!res.data.company_url ? res.data.company_url : "http://www.allot.com",
                                companyName: !!res.data.company_name ? res.data.company_name : 'Allot',
                                appName: !!res.data.app_name ? res.data.app_name : 'Secure Management',
                                footerLogin: !!res.data.show_footer_text,
                                footerLoginImage: !!res.data.show_footer_image
                            }
                            commit('addConfiguration', cfg)
                            resolve(cfg)
                        }
                        else {
                            console.log('Customization retrieve error, using defaults')
                            const cfg = {
                                web: "http://www.allot.com",
                                companyName: 'Allot',
                                appName: 'Secure Management',
                                footerLogin: true,
                                footerLoginImage: true
                            }
                            commit('addConfiguration', cfg)
                            resolve(state.configuration)
                        }
                    })
                    .catch(error => {
                        commit('setError', error, { root: true });
                        resolve(state.configuration)
                    })
            })
        }
    }
};