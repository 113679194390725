import {
  getSrcOrDest, getSchedule,
  setSrcOrDest, setSchedule, setActions
} from "./strategyUtil";

/**
 * Generate array from Back-end to use it in Front-end
 * Function to generate the firewall rules array from the account strategy
 * @param {Object} strategy attribute strategy of an account
 */
export const prepareFirewallData = strategy => {
  let rules = [];
  for (const ruleName in strategy.rules) {
    if (strategy.rules.hasOwnProperty(ruleName)) {
      const rule = strategy.rules[ruleName];
      let log_activity = false;
      let actionType;
      const protocols = getProtocols(
        rule,
        strategy.profiles,
        strategy.services
      );
      for (const action of rule.actions) {
        if (action.op === "log") log_activity = true;
        else if (action.op === 'set_timeout') actionType = {type: 'timeout', value: action.duration}
        else actionType = { type: action.op };
      }
      rules.push({
        rule_name: ruleName,
        id: ruleName,
        status: rule.active === "yes" ? true : false,
        log_activity: log_activity,
        action: actionType,
        order: rule.order,
        source: getSrcOrDest(rule, strategy.source_and_destinations, "origin"),
        destination: getSrcOrDest(
          rule,
          strategy.source_and_destinations,
          "target"
        ),
        protocol: protocols.app,
        ip_protocol: protocols.ip,
        hosts: getHosts(rule, strategy.profiles),
        schedule: getSchedule(rule, strategy.schedules)
      });
    }
  }
  return rules;
};

/**
 * Function to generate the app protocols and the ip protocols from the rule,
 * profiles and services
 * @param {Object} rule object with the description of the rule
 * @param {Object} profiles object with the profiles of the strategy account
 * @param {Object} services object with the services of the strategy account
 */
const getProtocols = (rule, profiles, services) => {
  if (rule.profile === "all") return { app: [], ip: [] };
  const availableAppProtocols = [
    "WAP", "POP", "FTP", "DNS", "BGP", "HTTP",
    "IMAP", "SMTP", "MQTT", "AMQP", "COAP",
    "FTPS", "TFTP", "HTTPS", "DNSTCP", "WEBSOCKET",
    "QUIC", "DHCP"
  ];
  let appProtocols = [];
  let ipProtocols = [];
  for (const item of profiles[rule.profile].service.items) {
    // If the item is contained in the availableAppProtocols we know that it should be
    // an app protocol, if not it would be an ip protocol
    if (availableAppProtocols.indexOf(item) !== -1) appProtocols.push(item);
    else ipProtocols = getIpProtocols(item, services);
  }
  return { app: appProtocols, ip: ipProtocols };
};

/**
 * Function to obtain the ip protocols from the services if necessary
 * @param {String} serviceName the key of the service into the services object
 * @param {Object} services
 */
const getIpProtocols = (serviceName, services) => {
  let ipProtocols = [];
  for (const protocol of services[serviceName]) {
    if (!protocol.hasOwnProperty("port"))
      ipProtocols.push(`${protocol.transport_protocol}`);
    else {
      for (const item of protocol.port) {
        // If it is not a number then we have a range(begin and end)
        if (isNaN(item))
          ipProtocols.push(`${protocol.transport_protocol}: ${item.begin}-${item.end}`);
        // If it is a number then whe use it directly as a port
        else ipProtocols.push(`${protocol.transport_protocol}: ${item}`);
      }
    }
  }
  return ipProtocols;
};

/**
 * Function to generate the hosts array from the strategy profiles
 * @param {Object} rule object with the description of the rule
 * @param {Object} profiles object with the profiles of the strategy account
 */
const getHosts = (rule, profiles) => {
  if (rule.profile === "all") return [];
  let hosts = [];
  if (profiles[rule.profile] && profiles[rule.profile].url_host)
    for (const item of profiles[rule.profile].url_host.items) {
      hosts.push(item);
    }
  return hosts;
};

/**
 * Generate object from Front-end to send it to Back-end
 * Function to generate the strategy of an account from an array of firewall rules
 * @param firewallRules array of rules
 */
export const prepareStrategyData = firewallRules => {
  let strategy = {
    rules: {}
  };
  for (let i = 0; i < firewallRules.length; i++) {
    // Get source items
    const source = setSrcOrDest(firewallRules[i], 'source');
    if (source.hasOwnProperty('value')) {
      if (!strategy.source_and_destinations) strategy.source_and_destinations = {};
      strategy.source_and_destinations[source.origin] = source.value;
    }

    // Get destination items
    const destination = setSrcOrDest(firewallRules[i], 'destination');
    if (destination.hasOwnProperty('value')) {
      if (!strategy.source_and_destinations) strategy.source_and_destinations = {};
      strategy.source_and_destinations[destination.target] = destination.value;
    }

    // Get profile and service items
    const profiles = setProfilesAndServices(firewallRules[i]);
    if (profiles.hasOwnProperty('profiles')) {
      if (!strategy.profiles) strategy.profiles = {};
      strategy.profiles[profiles.profileName] = profiles.profiles;
    }
    if (profiles.services !== undefined) {
      if (!strategy.services) strategy.services = {};
      strategy.services[profiles.serviceName] = profiles.services[profiles.serviceName];
    }

    // Get schedule items
    const schedules = setSchedule(firewallRules[i]);
    if (schedules.hasOwnProperty('schedule')) {
      if (!strategy.schedules) strategy.schedules = {};
      strategy.schedules[schedules.scheduleName] = schedules.schedule;
    }

    strategy.rules[`${firewallRules[i].rule_name}`] = {
      active: firewallRules[i].status ? "yes" : "no",
      order: firewallRules[i].order,
      ns_services: ["firewall"],
      origin: source.origin,
      target: destination.target,
      profile: profiles.profileName,
      when: schedules.scheduleName,
      actions: setActions(firewallRules[i])
    };
  }
  return strategy;
};

export const prepareStrategyStatusData = rule => {
  let strategy = {
    rules: {}
  };
  strategy.rules[`${rule.rule_name}`] = {
    active: rule.status ? "yes" : "no",
    ns_services: ["firewall"]
  };
  return strategy;
};

/**
 * Function to generate the profiles and services of the rule if necessary
 * @param {Object} rule object with the description of the rule
 * @returns an object with the profile key (and the profile and service object if necessary)
 */
const setProfilesAndServices = rule => {
  if (
    rule.protocol.length === 0 &&
    rule.ip_protocol.length === 0 &&
    rule.hosts.length === 0
  )
    return { profileName: "all" };

  let profiles = {
    ns_services: ["firewall"],
    service: { items: [] }
  };
  let services = undefined;
  if (rule.hosts.length > 0) profiles.url_host = { necessary: true, items: rule.hosts };
  if (rule.protocol.length > 0) profiles.service.items = [...rule.protocol];
  if (rule.ip_protocol.length > 0) {
    services = {};
    // Introduce the reference to the service into the profile items
    profiles.service.items.push(`${rule.rule_name}_service`);
    services[`${rule.rule_name}_service`] = [];
    for (const item of rule.ip_protocol) {
      const splitted = ("" + item).split(":");
      // ip protocol number o name (169 or "gre", for example)
      if (splitted.length === 1) {
        services[`${rule.rule_name}_service`].push({
          transport_protocol: !isNaN(item) ? +item : item
        });
        // ip protocol with a port number (tcp: 10, for example)
      } else if (splitted.length === 2 && splitted[1].split("-").length === 1) {
        services[`${rule.rule_name}_service`].push({
          transport_protocol: splitted[0].toUpperCase(),
          port: [+item.trim().split(":")[1]]
        });
        // ip protocol with port range (tcp/upd: 1-10, for example)
      } else if (splitted.length === 2 && splitted[1].split("-").length === 2) {
        services[`${rule.rule_name}_service`].push({
          transport_protocol: splitted[0].toUpperCase(),
          port: [
            {
              begin: +item.trim().split(":")[1].split("-")[0],
              end: +item.trim().split(":")[1].split("-")[1]
            }
          ]
        });
      }
    }
  }
  if (profiles.service.items.length > 0) profiles.service.necessary = true;
  return {
    profileName: `${rule.rule_name}_profile`,
    serviceName: `${rule.rule_name}_service`,
    profiles,
    services
  };
};
