export default {
  setDarkMode(state, mode) {
    state.darkmode = mode;
  },
  setLanguage(state, lang) {
    state.language = lang
    localStorage.setItem('language', lang)
  },
  setMobile(state, mobile) {
    state.mobile = mobile
  },
  setDesktop(state, desktop) {
    state.desktop = desktop
  },
  addServiceDS(state, service){
    if ( state.services_ds.indexOf(service) < 0 )
      state.services_ds.push(service)
  },
  addService(state, service) {
    if (state.services.indexOf(service) < 0)
      state.services.push(service)
  },
  addServiceNS(state, service) {
    if (state.services_ns.indexOf(service) < 0)
      state.services_ns.push(service)
  },
  addProduct(state, product) {
    if (state.products.indexOf(product) < 0)
      state.products.push(product)
  },
  setSubsystemsPackages(state, packages) {
    if (packages.length) {
      state.packages = packages;
    }
  },
  subsystemsSetup(state, value) {
    const product_service_dict = {};
    const subsystemsDict = {};

    value.forEach(item => {
      item.subsystems.forEach(subsystem => {
        product_service_dict[subsystem.subsystem_id] ? product_service_dict[subsystem.subsystem_id].push(item.service_id) : product_service_dict[subsystem.subsystem_id] = [item.service_id];

        subsystem.subsystem_services.forEach(service => {
          subsystemsDict[service.subsystem_service_id] = item.service_id
        })
      })
    })

    state.product_service_dict = product_service_dict;
    state.subsystemsDict = subsystemsDict;
    state.subsystems = value;
  },
  setCheckedProduct(state, { name, event }) {
    state.checkedProducts[name] = event;
  },
  startLoading(state, isHigh) {
    if (isHigh)
      state.loadingHigh++
    else
      state.loadingLow++
  },
  finishLoading(state, isHigh) {
    if (isHigh)
      state.loadingHigh--
    else
      state.loadingLow--
  },
  EShasAV(state, hasAV) {
    state.EShasAV = hasAV
  },

  // * --- EITAN ---
  setIsFormDirty(state, dirty) {
    state.isFormDirty = dirty
  },
  setNextRouteAfterFormAlertModal(state, { route }) {
    state.nextRoutAfterFormAlertModal = route
  },
  toggleFormAlertModal(state, { toggle, discard }) {
    state.showFormAlertModal = toggle
    if (discard) {
      state.isFormDirty = false
    }
  },

}