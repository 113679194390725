/* eslint-disable */
require('./add-button')
require('./admin')
require('./alerts-configuration')
require('./alerts-list')
require('./allot')
require('./allow-list-icon')
require('./autonotices-banners')
require('./autonotices-redirection')
require('./autonotices-smartbanners')
require('./block-list-icon')
require('./calendar')
require('./captcha-audio')
require('./captcha-refresh')
require('./checkstatus-button')
require('./connection')
require('./data-export')
require('./device-cast')
require('./device-console')
require('./device-iphone')
require('./device-macbook')
require('./device-other')
require('./device-printer')
require('./device-tablet')
require('./devices')
require('./download')
require('./edit-button')
require('./enter')
require('./executed-instances')
require('./filtering-log-configuration')
require('./firewall-activity')
require('./firewall-configuration')
require('./firewall')
require('./general-management')
require('./groups')
require('./icon-check')
require('./icon-download-details')
require('./icon-filteringlogconfig')
require('./icon-filteringlogconfig1')
require('./icon-groups')
require('./icon-listasblancas')
require('./icon-listasnegras')
require('./icon-new')
require('./icon-operation')
require('./icon-passwordstrength')
require('./icon-reset')
require('./icon-triggers')
require('./icon-users')
require('./info')
require('./item-accountmanagement')
require('./item-accounts')
require('./item-admin')
require('./item-adsfree')
require('./item-advancedconfiguration')
require('./item-android')
require('./item-antiphishing')
require('./item-antivirus')
require('./item-apple')
require('./item-autonotice')
require('./item-backups')
require('./item-blocking')
require('./item-blockingconfiguration')
require('./item-bullingprotection-menu')
require('./item-bullingprotection')
require('./item-categories')
require('./item-checkurls')
require('./item-configuration')
require('./item-contentfilter')
require('./item-dashboard')
require('./item-devices')
require('./item-dnssecure')
require('./item-endpointsecure')
require('./item-filteringlogconfig')
require('./item-filteringpolicies')
require('./item-fwadvancedconfig')
require('./item-general')
require('./item-generalmanagement')
require('./item-homesecure')
require('./item-hostandprocesscontrol')
require('./item-hostlocation')
require('./item-ios')
require('./item-ispservices')
require('./item-languages')
require('./item-locationmanagement')
require('./item-management')
require('./item-networksecure')
require('./item-notification')
require('./item-passwordpolicy')
require('./item-platforms')
require('./item-profile')
require('./item-reporter')
require('./item-scheduledtasks')
require('./item-searchurl')
require('./item-services')
require('./item-sso')
require('./item-status')
require('./item-threatprotection')
require('./item-user')
require('./item-windows')
require('./key')
require('./license-alert')
require('./license-information')
require('./location-button')
require('./log-server')
require('./menu')
require('./mobile-reports')
require('./moon')
require('./networksecure')
require('./new-alert')
require('./new')
require('./ok')
require('./omentel')
require('./operation')
require('./password-strength')
require('./pause')
require('./points')
require('./programmed-report')
require('./protocols')
require('./remove-button')
require('./report-configuration')
require('./report-view')
require('./reporter')
require('./rule-actions')
require('./rule-conditions')
require('./schedule-button')
require('./schedule-configuration')
require('./selection-criteria')
require('./server-configuration')
require('./setup-button')
require('./star')
require('./stop')
require('./sun')
require('./third-party-licenses')
require('./tools')
require('./top-10-talkers')
require('./transfer-buttons-1')
require('./transfer-buttons-2')
require('./triggers')
require('./upload')
require('./url-allow')
require('./url-forbid')
require('./user-tie')
require('./user')
require('./users')
require('./view-button')
require('./warning')
