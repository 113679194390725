export const subsystemMock = [{
    "service_id": "threat_protection", "subsystems":
      [{
        "subsystem_id": "EndpointSecure", "subsystem_services":
          [{ "subsystem_service_id": "AntiVirus", "active": true }, { "subsystem_service_id": "AntiPhishing", "active": true }]
      },
      {
        "subsystem_id": "NetworkSecure", "subsystem_services":
          [{ "subsystem_service_id": "AntiBotnet", "active": true }, { "subsystem_service_id": "AntiVirus", "active": true },
          { "subsystem_service_id": "AntiPhishing", "active": true }]
      },
      {
        "subsystem_id": "RouterSecure", "subsystem_services":
          [{ "subsystem_service_id": "AntiPhishing", "active": true }]
      },
      {"subsystem_id": "DNSecure", "subsystem_services":
      [{"subsystem_service_id": "anti_botnet", "active": true}, {"subsystem_service_id": "anti_malware", "active": true},
        {"subsystem_service_id": "anti_phishing", "active": true}]} 
      ]
  },
  {
    "service_id": "parental_control", "subsystems":
      [{
        "subsystem_id": "EndpointSecure", "subsystem_services":
          [{ "subsystem_service_id": "ParentalControl", "active": true }]
      },
      {
        "subsystem_id": "NetworkSecure", "subsystem_services":
          [{ "subsystem_service_id": "ContentFilter", "active": true }]
      },
      {
        "subsystem_id": "RouterSecure", "subsystem_services":
          [{ "subsystem_service_id": "ContentFilter", "active": true }]
      },
      {"subsystem_id": "DNSecure", "subsystem_services":
      [ {"subsystem_service_id": "safe_search", "active": true},{"subsystem_service_id": "content_filtering", "active": true},
       {"subsystem_service_id": "ads_free", "active": true},{"subsystem_service_id": "quiet_time", "active": true}]}   
      ]
  },
  {
    "service_id": "ads_free", "subsystems":
      [{
        "subsystem_id": "NetworkSecure", "subsystem_services":
          [{ "subsystem_service_id": "AdsFree", "active": true }]
      }
      ]
  },
  {
    "service_id": "auto_notice", "subsystems":
      [{
        "subsystem_id": "NetworkSecure", "subsystem_services":
          [{ "subsystem_service_id": "Activation", "active": true }]
      }
      ]
  },
  {
    "service_id": "firewall", "subsystems":
      [{
        "subsystem_id": "NetworkSecure", "subsystem_services":
          [{ "subsystem_service_id": "Firewall", "active": true }]
      }
      ]
  },
  {
    "service_id": "webproxy", "subsystems":
      [{
        "subsystem_id": "NetworkSecure", "subsystem_services":
          [{ "subsystem_service_id": "WebProxy", "active": true }]
      }
      ]
  },
  {
    "service_id": "antispam_in", "subsystems":
      [{
        "subsystem_id": "NetworkSecure", "subsystem_services":
          [{ "subsystem_service_id": "SMTPFilter.In", "active": true }, { "subsystem_service_id": "IMAPFilter", "active": true },
          { "subsystem_service_id": "MailFilter", "active": true }]
      }
      ]
  },
  {
    "service_id": "antispam_out", "subsystems":
      [{
        "subsystem_id": "NetworkSecure", "subsystem_services":
          [{ "subsystem_service_id": "SMTPFilter.Out", "active": true }]
      }]
  }]