export default {
  data() {
    return {
      updating: false,
      // * self_query is set in the local comp as computed
      // * query is set in the local comp as computed
      changingPage: false,
      currentPage: 1,
      nextPage: 0,
      // * set in the comp for example -for "account page" - set search_field_name = account_id
      search_field_name: null,

    }
  },
  methods: {
    reload() {
      if (this.self_query) {
        this.changingPage = false;
        this.query = this.self_query
        this.nextPage = 0;
        // * set in the comp the specific refresh method
        this.refresh()
      }
    },
    handlePrevPage() {
      if (this.query) {
        ['next_id', 'next_name'].forEach(item => {
          if (this.query[item])
            delete this.query[item]
        });
        if (this.query.prev_id) {
          this.nextPage = -1
          this.changingPage = true;
          this.refresh()
        }
      }
    },
    handleNextPage() {
      if (this.query) {
        ['prev_id', 'prev_name'].forEach(item => {
          if (this.query[item])
            delete this.query[item]
        });
        if (this.query.next_id) {
          this.nextPage = 1
          this.changingPage = true;
          this.refresh()
        }
      }
    },
    HandlePagination(value) {
      this.currentPage = 1;
      this.nextPage = 0;
      this.changingPage = false;
      this.self_query.limit = value;
      ['next_id', 'next_name', 'prev_id', 'prev_name'].forEach(item => {
        if (this.self_query[item])
          delete this.self_query[item]
      });
      this.reload()
    },
    handleSearch: _.debounce(function (value) {
      this.currentPage = 1
      this.nextPage = 0;
      if (value) {
        this.query = {}
        this.query.field_operator = 'sw'
        this.query.field_name = value.field
        this.query.field_value = value.value;
      }
      else {
        ['field_operator', 'field_name', 'field_value'].forEach(item => {
          if (this.query[item]) delete this.query[item]
        });
      };

      ['next_id', 'next_name', 'prev_id', 'prev_name'].forEach(item => {
        if (this.query[item]) delete this.query[item]
      });
      this.refresh();
    }, 500),

  },
  computed: {
    search_pagination: {
      get() {
        return {
          prev: !!this.query && !!this.query.prev_id,
          next: !!this.query && !!this.query.next_id,
          currentPage: this.currentPage
        }
      }
    },
    search_field_value: {
      get() {
        if (this.query && this.query.field_value)
          return { field: this.search_field_name, value: this.query.field_value }
        else
          return { field: this.search_field_name, value: '' }
      },
      set(newValue) {
        this.handleSearch(newValue)
      }
    },
  }
}
