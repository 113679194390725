export const DEFAULT_PROFILE = {
    "profile_definition_id": "",
    "profile_i18n": [
        {
            "language_id": "en-US",
            "profile_name": "profile name",
            "profile_description": "profile description"
        }
    ],
    "services_configuration": {
        "parental_control": {
            "enabled": true,
            "schedule": [],
            "quiet_time": {
                "enabled": false,
                "end_date_time": "2008-09-15T15:53:00Z"
            },
            "quiet_time_allowed_categories": [],
            "allowed_urls": [],
            "blocked_urls": [],
            "blocked_categories": [],
            "safe_search": false,
            "password_override": false,
            "send_notifications": false
        },
        "threat_protection": {
            "enabled": true,
            "send_notifications": false,
            "antiphishing_enabled": true,
            "antimalware_category_enabled": true,
            "antivirus_enabled": true,
            "antibotnet_enabled": true
        },
        "auto_notice": {
            "enabled": true,
        },
        // ! to show on 17.2
        // "ads_free": {
        //     "enabled": true,
        //     "send_notifications": true,
        //     "allowed_urls": []
        // },
        "location_management": {
            "enabled": true,
            "send_notifications": false,
            "geo_fence_areas": [],
            "notifications_config": {}
        },
        "anti_bullying": {
            "enabled": true,
            "send_notifications": false,
            "notifications_config": {}
        },
        "lan_protection": {
            "send_notifications": false
        },
        "router_protection": {
            "send_notifications": false
        }
    }
}