/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icon-listasnegras': {
    width: 43,
    height: 41,
    viewBox: '0 0 43 41',
    data: '<path pid="0" d="M32.942 20.999c5.546 0 10.058 4.477 10.058 9.98 0 5.504-4.512 9.981-10.058 9.981-5.547 0-10.059-4.477-10.059-9.98 0-5.504 4.512-9.981 10.059-9.981zm-6.706 9.98c0 1.172.308 2.273.845 3.23l9.115-9.044a6.704 6.704 0 00-3.254-.84c-3.698 0-6.706 2.986-6.706 6.655zm6.706 6.654c3.697 0 6.705-2.985 6.705-6.653 0-1.293-.375-2.5-1.02-3.522l-9.235 9.163a6.702 6.702 0 003.55 1.012zM19.195 35.97a1.67 1.67 0 011.676 1.663 1.67 1.67 0 01-1.676 1.664H6.705C3.009 39.297 0 36.312 0 32.643V5.03C0 2.28 2.256.04 5.03.04h32.857c2.773 0 5.03 2.239 5.03 4.99v13.557a1.67 1.67 0 01-1.677 1.663 1.67 1.67 0 01-1.677-1.663v-8.483H3.353v22.539c0 1.834 1.504 3.327 3.353 3.327h12.489zM37.803 3.367c-.924 0-1.676.746-1.676 1.663s.752 1.664 1.676 1.664c.925 0 1.677-.747 1.677-1.664 0-.917-.752-1.663-1.677-1.663zm-6.706 0c-.924 0-1.676.746-1.676 1.663s.752 1.664 1.676 1.664c.925 0 1.677-.747 1.677-1.664 0-.917-.752-1.663-1.677-1.663zM3.353 5.03v1.747h23.034a4.929 4.929 0 01-.03-3.41H5.029c-.924 0-1.676.746-1.676 1.663z" _fill="#000" fill-rule="nonzero"/>'
  }
})
