/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'selection-criteria': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path pid="0" d="M22.4 3.2H16v8.538l3.2-4.282 3.2 4.28V3.2zm6.4 24a1.6 1.6 0 01-1.6 1.6H4.8a1.6 1.6 0 01-1.6-1.6V4.8a1.6 1.6 0 011.6-1.6h8v12.818h3.84l2.56-3.436 2.56 3.436h3.84V3.2h1.6a1.6 1.6 0 011.6 1.6v22.4zM0 27.2A4.8 4.8 0 004.8 32h22.4a4.8 4.8 0 004.8-4.8V4.8A4.8 4.8 0 0027.2 0H4.8A4.8 4.8 0 000 4.8v22.4z" _fill="#000" fill-rule="evenodd"/>'
  }
})
