/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'enter': {
    width: 512,
    height: 512,
    viewBox: '0 0 512 512',
    data: '<path pid="0" d="M465.535.32C465.004.277 464.555 0 464 0H234.668c-35.285 0-64 28.715-64 64v21.332c0 11.777 9.555 21.336 21.332 21.336s21.332-9.559 21.332-21.336V64c0-11.754 9.578-21.332 21.336-21.332h99.39l-6.507 2.176c-17.278 5.972-28.883 22.25-28.883 40.488v320h-64c-11.758 0-21.336-9.578-21.336-21.332v-42.668c0-11.773-9.555-21.332-21.332-21.332s-21.332 9.559-21.332 21.332V384c0 35.285 28.715 64 64 64h64v21.332c0 23.531 19.133 42.668 42.664 42.668 4.566 0 8.898-.66 13.59-2.113l128.172-42.73c17.3-5.973 28.906-22.25 28.906-40.489v-384C512 17.793 490.559-1.707 465.535.32zm0 0"/><path pid="1" d="M228.414 198.25l-85.332-85.332a21.327 21.327 0 00-23.254-4.629A21.364 21.364 0 00106.668 128v64H21.332C9.559 192 0 201.559 0 213.332c0 11.777 9.559 21.336 21.332 21.336h85.336v64a21.368 21.368 0 0013.16 19.71 21.334 21.334 0 0023.254-4.628l85.332-85.336c8.344-8.34 8.344-21.82 0-30.164zm0 0"/>'
  }
})
