/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'item-antiphishing': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path pid="0" d="M21.073 6.032a2.011 2.011 0 10-4.023.001 2.011 2.011 0 004.023-.001zm0 5.687v16.174S21.84 32 19.063 32h-2.011A12.064 12.064 0 017 20.105h4.02a8.042 8.042 0 006.032 7.788V11.719a6.034 6.034 0 01-3.677-7.698 6.033 6.033 0 0111.719 2.01 6.033 6.033 0 01-4.021 5.688z" _fill="#000" fill-rule="evenodd"/>'
  }
})
