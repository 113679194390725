<template>
  <div class="wrapper authpages">
    <nested-sidebar :title="title" :web="web">
      <template slot="links">
        <nested-sidebar-item class="svg-icon-24" :link="{name: 'Dashboard', path: '/dashboard/general', svgicon: 'item-dashboard'}"
          id='menu_Dashboard' :visible_for_permissions='[{actions: ["GET", "PATCH"], path: "/manager-loggedin"}]'>
        </nested-sidebar-item>
        <!-- <nested-sidebar-item :link="{name: 'Dashboard', query: $route.query, path: '/dashboard', icon: 'tim-icons icon-chart-pie-36'}"/> -->
        <nested-sidebar-item class="svg-icon-24" :link="{name: 'Account Management', path: '/account_management', svgicon: 'item-accountmanagement'}" menu id="menu_AccountManagement" :visible_for_permissions='[{actions: ["GET"], path: "/accounts/.*"}, {actions: ["GET"], path: "/managers/.*"}, {actions: ["GET"], path: "/users/.*"}, {actions: ["GET"], path: "/devices/.*"}]' operator='OR'>
            <nested-sidebar-item :link="{name: 'Accounts', query: $route.query, path: '/account_management/accounts', svgicon: 'item-accounts'}" :visible_for_permissions='[{actions: ["GET"], path: "/accounts/.*"}]'/>
            <nested-sidebar-item id="menu_Managers" :link="{name: 'Managers', query: $route.query, path: '/account_management/managers', svgicon: 'groups'}" :visible_for_permissions='[{actions: ["GET"], path: "/managers/.*"}]'/>
            <nested-sidebar-item v-if="!$store.getters['settings/isIOT']" :link="{name: 'Users', query: $route.query, path: '/account_management/users', svgicon: 'item-user'}"
                            id='menu_Users' :visible_for_permissions='[{actions: ["GET"], path: "/users/.*"}]'/>
            <nested-sidebar-item :link="{name: 'Devices', query: $route.query, path: '/account_management/devices', svgicon: 'item-devices'}"
                            v-if="!$store.getters['settings/isIOT']" id='menu_Devices' :visible_for_permissions='[{actions: ["GET"], path: "/devices/.*"}]'/>
        </nested-sidebar-item>

        <nested-sidebar-item class="svg-icon-24" :link="{name: 'General Management', path: '/general_management', svgicon: 'item-generalmanagement'}" menu id="menu_GeneralManagement" :visible_for_permissions='[{actions: ["GET"], path: "/administrators.*"}, {actions: ["GET"], path: "/customization"}, {actions: ["GET"], path: "/subsystems/ns/license"}, {actions: ["GET"], path: "/external-servers"}, {actions: ["GET"], path: "/data-export/configuration"}]' operator='OR'>
            <nested-sidebar-item id="menu_Administrators" v-if="!$store.getters['auth/isLdap']()" 
                        :link="{name: 'Administrators', query: $route.query, path: '/general_management/administrators', svgicon: 'item-admin'}" :visible_for_permissions='[{actions: ["GET"], path: "/administrators.*"}]'/>
            <nested-sidebar-item :link="{name: 'Customization', query: $route.query, path: '/general_management/customization', svgicon: 'item-locationmanagement'}" :visible_for_permissions='[{actions: ["GET"], path: "/customization"}]'/>
            <nested-sidebar-item :link="{name: 'License', query: $route.query, path: '/general_management/license', svgicon: 'license-information'}" :visible_for_permissions='[{actions: ["GET"], path: "/subsystems/ns/license"}]'/>
            <!--<nested-sidebar-item :link="{name: 'Notifications', query: $route.query, path: '/general_management/notifications', svgicon: 'devices'}"/>-->
            <nested-sidebar-item :link="{name: 'Authentication', query: $route.query, path: '/general_management/authentication', svgicon: 'item-sso'}" id="menu_Authentication" :visible_for_permissions='[{actions: ["GET"], path: "/customization"}]'/>
            <nested-sidebar-item :link="{name: 'External Servers', query: $route.query, path: '/general_management/external_servers', svgicon: 'server-configuration'}" id="gm-external-servers-menu" :visible_for_permissions='[{actions: ["GET"], path: "/external-servers"}]'/>
            <nested-sidebar-item :link="{name: 'Jobs', path: '/general_management/jobs', svgicon: 'item-configuration'}" menu id="menu_GM_jobs" visible_for_products='RouterSecure' :visible_for_permissions='[{actions: ["GET"], path: "/subsystems/rs/routers/jobs/.*"}, {actions: ["GET"], path: "/jobs/.*"}]' operator='OR' >
              <nested-sidebar-item v-if="!$store.getters['settings/isIOT']" :link="{name: 'Router Jobs', query: $route.query, path: '/general_management/jobs/router_jobs', iconify: 'mdi:router-wireless'}" id="menu_GM_RouterJobs" :visible_for_permissions='[{actions: ["GET"], path: "/subsystems/rs/routers/jobs/.*"}]'/>
              <nested-sidebar-item v-if="$store.getters['settings/isIOT']" :link="{name: 'Account Jobs', query: $route.query, path: '/general_management/jobs/account_jobs', svgicon: 'groups'}" id="menu_GM_AccountJobs" :visible_for_permissions='[{actions: ["GET"], path: "/jobs/.*"}]'/>
            </nested-sidebar-item>
            <nested-sidebar-item v-if="showDataExport" :link="{name: 'Data Export', query: $route.query, path: '/general_management/data_export', svgicon: 'data-export'}" id="data-export-menu" :visible_for_permissions='[{actions: ["GET"], path: "/data-export/configuration"}]'/>
        </nested-sidebar-item>
        <isp-services-navbar />
        <nested-sidebar-item class="svg-icon-24" :link="{name: 'NetworkSecure', path: '/ns', svgicon: 'item-networksecure'}" menu id='menu_NetworkSecure' visible_for_products='NetworkSecure'
        :visible_for_permissions='[{actions: ["GET", "PATCH"], path: "/subsystems/ns/general-status.*"}, {actions: ["GET"], path: "/subsystems/ns/scheduled-tasks.*"}, {actions: ["GET"], path: "/subsystems/ns/backups"}, 
        {actions: ["GET"], path: "/subsystems/ns/filtering-logs"}, {actions: ["GET"], path: "/subsystems/ns/alerts"}, {actions: ["GET"], path: "/subsystems/ns/external-servers"}, 
        {actions: ["GET"], path: "/subsystems/ns/autonotices/packages"}, {actions: ["GET"], path: "/subsystems/ns/isp-strategy"}, 
        {actions: ["GET"], path: "/subsystems/ns/autonotices/redirections"}, {actions: ["GET"], path: "/subsystems/ns/autonotices/banners"}, 
        {actions: ["GET"], path: "/subsystems/ns/autonotices/smartbanners"}, {actions: ["GET"], path: "/isp-categories"}, 
        {actions: ["GET"], path: "/subsystems/ns/service-configuration"}, {actions: ["GET"], path: "/subsystems/ns/firewall/advanced-configuration"}]' operator='OR'>
          <nested-sidebar-item :link="{name: 'General', path: '/ns/General', svgicon: 'item-general'}" menu id='menu_General' visible_for_products='NetworkSecure' :visible_for_permissions='[{actions: ["GET", "PATCH"], path: "/subsystems/ns/general-status.*"}, {actions: ["GET"], path: "/subsystems/ns/scheduled-tasks.*"}, {actions: ["GET"], path: "/subsystems/ns/backups"}, {actions: ["GET"], path: "/subsystems/ns/filtering-logs"}, {actions: ["GET"], path: "/subsystems/ns/alerts"}, {actions: ["GET"], path: "/subsystems/ns/external-servers"}, {actions: ["GET"], path: "/subsystems/ns/autonotices/packages"}]' operator='OR'>
            <nested-sidebar-item :link="{name: 'Status', path: '/ns/General/Status', svgicon: 'item-status', query: $route.query}" :visible_for_permissions='[{actions: ["GET", "PATCH"], path: "/subsystems/ns/general-status.*"}]'/>
            <nested-sidebar-item id='menu_General_Management' :link="{name: 'General Management', path: '/ns/General/GeneralManagement', svgicon: 'item-generalmanagement'}" menu :visible_for_permissions='[{actions: ["GET"], path: "/subsystems/ns/scheduled-tasks.*"}, {actions: ["GET"], path: "/subsystems/ns/backups"}, {actions: ["GET"], path: "/subsystems/ns/filtering-logs"}, {actions: ["GET"], path: "/subsystems/ns/alerts"}, {actions: ["GET"], path: "/subsystems/ns/external-servers"}, {actions: ["GET"], path: "/subsystems/ns/autonotices/packages"}]' operator='OR'>
<!--                <nested-sidebar-item :link="{name: 'Administrators', path: '/ns/General/GeneralManagement/SysAdmins/', query: $route.query}"/>
                <nested-sidebar-item :link="{name: 'Delegated Authentication', path: '/ns/General/GeneralManagement/LDAPAuth/', query: $route.query}"/>
-->
                <nested-sidebar-item :link="{name: 'Scheduled Tasks', path: '/ns/General/GeneralManagement/ScheduledTasks', svgicon: 'item-scheduledtasks', query: $route.query}" :visible_for_permissions='[{actions: ["GET"], path: "/subsystems/ns/scheduled-tasks.*"}]'/>
                <nested-sidebar-item :link="{name: 'Backups', path: '/ns/General/GeneralManagement/Backup', svgicon: 'item-backups', query: $route.query}" :visible_for_permissions='[{actions: ["GET"], path: "/subsystems/ns/backups"}]'/>
                <nested-sidebar-item :link="{name: 'Filtering Log Configuration', path: '/ns/General/GeneralManagement/Logs', svgicon: 'item-filteringlogconfig', query: $route.query}" :visible_for_permissions='[{actions: ["GET"], path: "/subsystems/ns/filtering-logs"}]'/>
<!--                <nested-sidebar-item :link="{name: 'Events', path: '/ns/General/GeneralManagement/EventConfig/Events/', query: $route.query}"/>-->
                <nested-sidebar-item id='menu_Alerts' :link="{name: 'Alerts', query: $route.query, path: '/ns/General/GeneralManagement/Alerts', svgicon: 'alerts-configuration'}" :visible_for_permissions='[{actions: ["GET"], path: "/subsystems/ns/alerts"}]'/>
                <nested-sidebar-item :link="{name: 'External Servers', query: $route.query, path: '/ns/General/GeneralManagement/ExternalServers', svgicon: 'server-configuration'}" :visible_for_permissions='[{actions: ["GET"], path: "/subsystems/ns/external-servers"}]'/>
                <!-- <nested-sidebar-item :link="{name: 'Service Packages', query: $route.query, path: '/ns/General/GeneralManagement/ServicePackages', svgicon: 'server-configuration'}" :visible_for_permissions='[{actions: ["GET"], path: "/subsystems/ns/autonotices/packages"}]'/> -->
                <!-- <nested-sidebar-item :link="{name: 'External Servers', path: '/ns/General/GeneralManagement/ClientExtConnection/', query: $route.query}"/>-->
<!--                <nested-sidebar-item :link="{name: 'Password Control', path: '/ns/General/GeneralManagement/PasswordControl/', query: $route.query}"/>
-->
                <!-- <nested-sidebar-item v-if="!$store.getters.isIOT" :link="{name: 'Service Packages', path: '/ns/General/GeneralManagement/ServicesISP', query: $route.query}"/> -->
            </nested-sidebar-item>
          </nested-sidebar-item>


          <!-- <nested-sidebar-item :link="{name: 'WebProxy', path: '/ns/WebProxy', icon: 'tim-icons icon-double-right'}" menu id='menu_WebProxy' visible_for_services_ns='WebProxy'>
            <nested-sidebar-item :link="{name: 'Filtering Policies', path: '/ns/WebProxy/FilteringPolicies/Policies'}" menu id='menu_WebProxyFP'>
              <nested-sidebar-item :link="{name: 'Policies', path: '/ns/WebProxy/FilteringPolicies/Policies/Main/', query: $route.query}" id='menu_WebProxy_Policies'/>
              <nested-sidebar-item :link="{name: 'Source & Destination', path: '/ns/WebProxy/FilteringPolicies/Policies/Group/Main/', query: $route.query}"/>
              <nested-sidebar-item :link="{name: 'Schedules', path: '/ns/WebProxy/FilteringPolicies/Policies/Schedule/', query: $route.query}"/>
            </nested-sidebar-item>
            <nested-sidebar-item :link="{name: 'Profiles', path: '/ns/WebProxy/FilteringPolicies/Policies/Profile/', query: $route.query}"/>
            <nested-sidebar-item :link="{name: 'Advanced Configuration', path: '/ns/WebProxy/'}">
              <nested-sidebar-item :link="{name: 'Authentication', path: '/ns/WebProxy/SSL/', query: $route.query}"/>
              <nested-sidebar-item :link="{name: 'PAC file', path: '/ns/WebProxy/PACFile/', query: $route.query}"/>
            </nested-sidebar-item>
          </nested-sidebar-item> -->

          <nested-sidebar-item :link="{name: 'ContentFilter', path: '/ns/ContentFilter', svgicon: 'item-contentfilter'}" menu 
                                            id='menu_ContentFilter' visible_for_services_ns='ContentFilter'  :visible_for_permissions='[{actions: ["GET"], path: "/subsystems/ns/isp-strategy"}]'>
            <nested-sidebar-item :link="{name: 'Filtering Policies', path: '/ns/ContentFilter/Policies', svgicon: 'item-filteringpolicies'}"  id='menu_ContentFilterFP'/>
            <nested-sidebar-item :link="{name: 'Advanced Configuration', path: '/ns/ContentFilter', svgicon: 'item-advancedconfiguration'}"  menu id='menu_ContentFilterAC' :visible_for_permissions='[{actions: ["GET"], path: "/subsystems/ns/service-configuration"}]'>
                <nested-sidebar-item :link="{name: 'Filtering Configuration', path: '/ns/ContentFilter/Advanced/Filtering', query: $route.query, svgicon: 'item-blockingconfiguration'}" id='menu_ContentFilterAC_FC' :visible_for_permissions='[{actions: ["GET"], path: "/subsystems/ns/service-configuration"}]'/>
                <nested-sidebar-item :link="{name: 'HTTPs', path: '/ns/ContentFilter/Advanced/Https', query: $route.query, svgicon: 'item-blockingconfiguration'}" id='menu_ContentFilterAC_HTTPS' :visible_for_permissions='[{actions: ["GET"], path: "/subsystems/ns/service-configuration"}]' :check_sniffer_mode='true'/>
            </nested-sidebar-item>
          </nested-sidebar-item>

          <nested-sidebar-item :link="{name: 'AdsFree', path: '/ns/AdsFree', svgicon: 'item-adsfree'}" menu
                                                          id='menu_AdsFree' visible_for_services_ns='AdsFree' :visible_for_permissions='[{actions: ["GET"], path: "/subsystems/ns/isp-strategy"}]'>
              <nested-sidebar-item :link="{name: 'Filtering Policies', path: '/ns/AdsFree/FilteringPolicies/Policies', svgicon: 'item-filteringpolicies'}" id='adsFreeFP' :visible_for_permissions='[{actions: ["GET"], path: "/subsystems/ns/isp-strategy"}]'>
                  <!-- <nested-sidebar-item :link="{name: 'Policies', path: '/ns/AdsFree/FilteringPolicies/Policies/Main/', query: $route.query}"
                                                                      id='menu_AdsFree_Policies'/>
                  <nested-sidebar-item :link="{name: 'Source & Destination', path: '/ns/AdsFree/FilteringPolicies/Policies/Group/Main/', query: $route.query}"/>
                  <nested-sidebar-item :link="{name: 'Schedules', path: '/ns/AdsFree/FilteringPolicies/Policies/Schedule/', query: $route.query}"/> -->
              </nested-sidebar-item>
              <!-- <nested-sidebar-item :link="{name: 'Profiles', path: '/ns/AdsFree/FilteringPolicies/Policies/Profile/', query: $route.query}"/> -->
              <nested-sidebar-item :link="{name: 'Allowed List', path: '/ns/AdsFree/AllowList', query: $route.query, svgicon: 'icon-listasblancas'}" :visible_for_permissions='[{actions: ["GET"], path: "/subsystems/ns/isp-strategy"}]'/>
          </nested-sidebar-item>

       <nested-sidebar-item :link="{name: 'AutoNotices', path: '/ns/AutoNotices', svgicon: 'item-autonotice'}"
                          menu id='menu_Autonotices' visible_for_services_ns='Activation' :visible_for_permissions='[{actions: ["GET"], path: "/subsystems/ns/isp-strategy"}, {actions: ["GET"], path: "/subsystems/ns/autonotices/redirections"}, {actions: ["GET"], path: "/subsystems/ns/autonotices/banners"}, {actions: ["GET"], path: "/subsystems/ns/autonotices/smartbanners"}]' operator='OR'>
            <nested-sidebar-item :link="{name: 'Filtering Policies', path: '/ns/AutoNotices/Policies', 
                                    query: $route.query, svgicon: 'item-filteringpolicies'}" id="menu_AutonoticesFP" :visible_for_permissions='[{actions: ["GET"], path: "/subsystems/ns/isp-strategy"}]'/>
             <nested-sidebar-item :link="{name: 'Advanced Configuration', path: '/ns/AutoNotices/AdvancedConfiguration', 
                                            svgicon: 'item-advancedconfiguration'}"  menu :visible_for_permissions='[{actions: ["GET"], path: "/subsystems/ns/autonotices/redirections"}, {actions: ["GET"], path: "/subsystems/ns/autonotices/banners"}, {actions: ["GET"], path: "/subsystems/ns/autonotices/smartbanners"}]' operator='OR'>
                <nested-sidebar-item :link="{name: 'Redirection', path: '/ns/AutoNotices/AdvancedConfiguration/Redirection', 
                                            svgicon: 'autonotices-redirection', svgicon_nofill: true, query: $route.query}" :visible_for_permissions='[{actions: ["GET"], path: "/subsystems/ns/autonotices/redirections"}]'/>
                <nested-sidebar-item :link="{name: 'Banners Insertion', path: '/ns/AutoNotices/AdvancedConfiguration/Insertion', 
                                            svgicon: 'autonotices-banners', svgicon_nofill: true, query: $route.query}" :visible_for_permissions='[{actions: ["GET"], path: "/subsystems/ns/autonotices/banners"}]'/>
                <nested-sidebar-item :link="{name: 'Smart Banners', path: '/ns/AutoNotices/AdvancedConfiguration/Smart', 
                                            svgicon: 'autonotices-smartbanners', svgicon_nofill: true, query: $route.query}" :visible_for_permissions='[{actions: ["GET"], path: "/subsystems/ns/autonotices/smartbanners"}]'/>
            </nested-sidebar-item>
        </nested-sidebar-item>

        <nested-sidebar-item :link="{name: 'Malware & Phishing', path: '/ns/Malware&Phishing', svgicon: 'item-antiphishing'}" menu
                                                        id='menu_AntiMalware' visible_for_services_ns='AntiPhishing' :visible_for_permissions='[{actions: ["GET"], path: "/subsystems/ns/isp-strategy"}, {actions: ["GET"], path: "/isp-categories"}]' operator='OR'>
            <nested-sidebar-item :link="{name: 'Filtering Policies', path: '/ns/Malware&Phishing/Policies', svgicon: 'item-filteringpolicies'}" id='antiPhishingFP' :visible_for_permissions='[{actions: ["GET"], path: "/subsystems/ns/isp-strategy"}]'/>
            <nested-sidebar-item :link="{name: 'Advanced Configuration', path: '/ns/Malware&Phishing/Advanced', query: $route.query, svgicon: 'item-advancedconfiguration'}" :visible_for_permissions='[{actions: ["GET"], path: "/isp-categories"}]'/>
        </nested-sidebar-item>

        <nested-sidebar-item :link="{name: 'Antivirus', path: '/ns/AntiVirus', svgicon: 'item-antivirus'}" menu
                                                        id='menu_Antivirus' visible_for_services_ns='AntiVirus' :visible_for_permissions='[{actions: ["GET"], path: "/subsystems/ns/isp-strategy"}, {actions: ["GET"], path: "/subsystems/ns/service-configuration"}]' operator='OR'>
            <nested-sidebar-item :link="{name: 'Filtering Policies', path: '/ns/AntiVirus/Policies', query: $route.query, svgicon: 'item-filteringpolicies'}" id='antivirusFP' :visible_for_permissions='[{actions: ["GET"], path: "/subsystems/ns/isp-strategy"}]'/>
            <nested-sidebar-item :link="{name: 'Advanced Configuration', path: '/ns/AntiVirus/Advanced', query: $route.query, svgicon: 'item-advancedconfiguration'}" id="advanced-anti-virus" :visible_for_permissions='[{actions: ["GET"], path: "/subsystems/ns/service-configuration"}]'/>
        </nested-sidebar-item>

        <nested-sidebar-item :link="{name: 'AntiBotnet', path: '/ns/AntiBotnet', icon: 'tim-icons icon-bulb-63'}" menu
                                                        id='menu_AntiBotnet' visible_for_services_ns='AntiBotnet' :visible_for_permissions='[{actions: ["GET"], path: "/subsystems/ns/isp-strategy"}]'>
            <nested-sidebar-item :link="{name: 'Filtering Policies', path: '/ns/AntiBotnet/Policies', query: $route.query, svgicon: 'item-filteringpolicies'}" id="menu_AntiBotnetFP" :visible_for_permissions='[{actions: ["GET"], path: "/subsystems/ns/isp-strategy"}]'/>
            <nested-sidebar-item :link="{name: 'Advanced Configuration', path: '/ns/AntiBotnet/AdvancedConfiguration', query: $route.query, svgicon: 'item-advancedconfiguration'}" :visible_for_permissions='[{actions: ["GET"], path: "/subsystems/ns/isp-strategy"}]'/>
        </nested-sidebar-item>

        <nested-sidebar-item 
                          :link="{name: 'Firewall', path: '/ns/Firewall', svgicon: 'firewall'}"
                          menu id='menu_Firewall' visible_for_services_ns='Firewall' :visible_for_permissions='[{actions: ["GET"], path: "/subsystems/ns/isp-strategy"}, {actions: ["GET"], path: "/subsystems/ns/firewall/advanced-configuration"}]' operator='OR'>
            <nested-sidebar-item :link="{name: 'Filtering Policies', path: '/ns/Firewall/Policies', query: $route.query, svgicon: 'item-filteringpolicies'}" 
                          id='menu_FirewallFP' :visible_for_permissions='[{actions: ["GET"], path: "/subsystems/ns/isp-strategy"}]'/>
            <nested-sidebar-item :link="{name: 'Advanced Configuration', path: '/ns/Firewall/AdvancedConfiguration', svgicon: 'item-advancedconfiguration', query: $route.query}" :visible_for_permissions='[{actions: ["GET"], path: "/subsystems/ns/firewall/advanced-configuration"}]'/>
        </nested-sidebar-item>
      </nested-sidebar-item>

      <!-- RouterSecure -->
      <nested-sidebar-item id='menu_RouterSecure' class="svg-icon-24" :link="{name: 'RouterSecure', path: '/rs', svgicon: 'item-homesecure'}" menu visible_for_products='RouterSecure'
        :visible_for_permissions='[{actions: ["GET", "PATCH"], path: "/subsystems/rs/general-status.*"}, {actions: ["GET"], path: "/isp-categories"},
        {actions: ["GET"], path: "/subsystems/rs/router-agent-packages"}, {actions: ["GET"], path: "/subsystems/rs/router-default-agent-packages"}, {actions: ["GET"], path: "/subsystems/rs/routers/fields/router_model"}, {actions: ["GET"], path: "/subsystems/rs/routers/fields/firmware_version"}, {actions: ["GET"], path: "/subsystems/rs/routers/fields/attributes"}, {actions: ["GET"], path: "/subsystems/rs/router-protection/router-allowed-list"}, {actions: ["GET"], path: "/subsystems/ns/router-protection/dns-allowed-list"}]' operator='OR'>
          <nested-sidebar-item id='menu_RS_General' :link="{name: 'General', path: '/rs/General', svgicon: 'item-general'}" menu visible_for_products='RouterSecure' :visible_for_permissions='[{actions: ["GET", "PATCH"], path: "/subsystems/rs/general-status.*"}, {actions: ["GET"], path: "/subsystems/rs/router-agent-packages"}, {actions: ["GET"], path: "/subsystems/rs/router-default-agent-packages"}, {actions: ["GET"], path: "/subsystems/rs/routers/fields/router_model"}, {actions: ["GET"], path: "/subsystems/rs/routers/fields/firmware_version"}, {actions: ["GET"], path: "/subsystems/rs/routers/fields/attributes"}, {actions: ["GET"], path: "/subsystems/rs/router-protection/router-allowed-list"}, {actions: ["GET"], path: "/subsystems/ns/router-protection/dns-allowed-list"}]' operator='OR'>
            <nested-sidebar-item id='menu_RS_G_Status' :link="{name: 'Status', path: '/rs/General/Status', svgicon: 'item-status', query: $route.query}" :visible_for_permissions='[{actions: ["GET"], path: "/subsystems/rs/service-calculated-status"}]'/>
            <nested-sidebar-item id='menu_RS_GeneralManagement' :link="{name: 'General Management', path: '/rs/General/GeneralManagement', svgicon: 'item-generalmanagement'}" menu :visible_for_permissions='[{actions: ["GET"], path: "/subsystems/rs/router-agent-packages"}, {actions: ["GET"], path: "/subsystems/rs/router-default-agent-packages"}, {actions: ["GET"], path: "/subsystems/rs/routers/fields/router_model"}, {actions: ["GET"], path: "/subsystems/rs/routers/fields/firmware_version"}, {actions: ["GET"], path: "/subsystems/rs/routers/fields/attributes"}, {actions: ["GET"], path: "/subsystems/rs/router-protection/router-allowed-list"}, {actions: ["GET"], path: "/subsystems/ns/router-protection/dns-allowed-list"}]' operator='OR'>
                <nested-sidebar-item id='menu_RS_GM_AgentManagement' :link="{name: 'Agent Management', path: '/rs/General/GeneralManagement/AgentManagement', ionicon: 'terminal-outline', query: $route.query}" :visible_for_permissions='[{actions: ["GET"], path: "/subsystems/rs/router-agent-packages"}]'/>
                <nested-sidebar-item id='menu_RS_GM_DefaultAgentPackage' :link="{name: 'Default Agent Version', path: '/rs/General/GeneralManagement/DefaultAgentVersion', icon: 'now-ui-icons design_app', query: $route.query}" :visible_for_permissions='[{actions: ["GET"], path: "/subsystems/rs/router-agent-packages"}, {actions: ["GET"], path: "/subsystems/rs/router-default-agent-packages"}]'/>
                <nested-sidebar-item id='menu_RS_GM_Troubleshooting' :link="{name: 'Troubleshooting', path: '/rs/General/GeneralManagement/Troubleshooting', icon: 'now-ui-icons ui-2_settings-90', query: $route.query}" :visible_for_permissions='[{actions: ["GET"], path: "/subsystems/rs/routers/fields/router_model"}, {actions: ["GET"], path: "/subsystems/rs/routers/fields/firmware_version"}, {actions: ["GET"], path: "/subsystems/rs/routers/fields/attributes"}]'/>
                <nested-sidebar-item id='menu_RS_GM_AllowedLists' :link="{name: 'Allowed Lists', path: '/rs/General/GeneralManagement/AllowedLists', svgicon: 'icon-listasblancas', query: $route.query}" :visible_for_permissions='[{actions: ["GET"], path: "/subsystems/rs/router-protection/router-allowed-list"}, {actions: ["GET"], path: "/subsystems/ns/router-protection/dns-allowed-list"}]'/>
                <!-- <nested-sidebar-item id='menu_RS_GM_Authentication' :link="{name: 'Authentication', path: '/rs/General/GeneralManagement/Authentication', svgicon: 'item-sso', query: $route.query}" :visible_for_permissions='[{actions: ["GET"], path: "/subsystems/ns/TODO_AUTH"}]'/> will be implemented in 30.2 -->
            </nested-sidebar-item>
          </nested-sidebar-item>
          <!-- 
              <nested-sidebar-item :link="{name: 'ContentFilter', path: '/rs/ContentFilter', svgicon: 'item-contentfilter'}" menu 
                                                id='menu_RS_ContentFilter' :visible_for_permissions='[{actions: ["GET"], path: "/subsystems/rs/TODO_contentfilter"}]'>
                <nested-sidebar-item id='menu_RS_CF_Advanced' :link="{name: 'Advanced Configuration', path: '/rs/ContentFilter/Advanced', query: $route.query, svgicon: 'item-advancedconfiguration'}" :visible_for_permissions='[{actions: ["GET"], path: "/TODO_cf_advanced"}]'/>
              </nested-sidebar-item>

              <nested-sidebar-item :link="{name: 'Malware & Phishing', path: '/rs/Malware&Phishing', svgicon: 'item-antiphishing'}" menu 
                                                id='menu_RS_AntiMalware' :visible_for_permissions='[{actions: ["GET"], path: "/subsystems/rs/TODO_malwarephishing"}]'>
                <nested-sidebar-item id='menu_RS_MP_Advanced' :link="{name: 'Advanced Configuration', path: '/rs/Malware&Phishing/Advanced', query: $route.query, svgicon: 'item-advancedconfiguration'}" :visible_for_permissions='[{actions: ["GET"], path: "/TODO_mp_advanced"}]'/>
              </nested-sidebar-item>
           -->    
      </nested-sidebar-item> <!-- End of RouterSecure -->

      <nested-sidebar-item class="svg-icon-24" v-if="!$store.getters['settings/isIOT']" :link="{name: 'EndpointSecure', path: '/eps', svgicon: 'item-endpointsecure'}" menu id="menu_EndpointSecure" visible_for_products='EndpointSecure' :visible_for_permissions='[{actions: ["GET"], path: "/subsystems/endpoint-secure/settings"}]'>
          <nested-sidebar-item :link="{name: 'Download Details', query: $route.query, path: '/eps/download_details', svgicon: 'icon-download-details'}" :visible_for_permissions='[{actions: ["GET"], path: "/subsystems/endpoint-secure/settings"}]'/>
      </nested-sidebar-item>

      
      <nested-sidebar-item class="svg-icon-24" :link="{ name: 'DNSecure', query: $route.query, path: '/ds', svgicon: 'item-dnssecure' }" menu id="DNSecure"  visible_for_products='DNSecure' :visible_for_permissions='[{actions: ["GET"], path: "/subsystems/ds/advanced-configuration"}, {actions: ["GET"], path: "/subsystems/ds/content-policy-rules"}]' operator='OR' >
        <nested-sidebar-item :link="{ name: 'General', query: $route.query, path: '/ds/general', svgicon: 'item-general' }" menu id="menu_General"  :visible_for_permissions='[{actions: ["GET"], path: "/subsystems/ds/advanced-configuration"}]'>
          <nested-sidebar-item :link="{name: 'Status', query: $route.query, path: '/ds/general/Status', svgicon: 'item-status'}" />
        </nested-sidebar-item>

        <nested-sidebar-item :link="{ name: 'Content Filtering', query: $route.query, path: '/ds/cf', svgicon: 'item-contentfilter' }" menu id="menu_ContentFiltering" visible_for_services_ds='content_filtering' :visible_for_permissions='[{actions: ["GET"], path: "/subsystems/ds/content-policy-rules"}]'>
          <nested-sidebar-item :link="{name: 'Filtering Policies', path: '/ds/cf/Policies', query: $route.query, svgicon: 'item-filteringpolicies'}" id='cf-fp'/>

          <nested-sidebar-item :link="{name: 'Advanced Configuration', path: '/ds/cf/AdvancedConfiguration', svgicon: 'item-advancedconfiguration', query: $route.query}" :visible_for_permissions='[{actions: ["GET"], path: "/subsystems/ds/advanced-configuration"}]'/>
        </nested-sidebar-item>
      </nested-sidebar-item>

      

      <nested-sidebar-item class="svg-icon-24" :link="{name: 'cyberbullying', path: '/cyberbulling_endpoint', svgicon: 'item-bullingprotection-menu'}" menu visible_for_services='anti_bullying' :visible_for_permissions='[{actions: ["GET"], path: "/subsystems/cb/advanced-configuration"}]'>
          <nested-sidebar-item v-for="(link, index) in links.cyberbulling" :key="index" :link="link"/>
        </nested-sidebar-item>

      <nested-sidebar-item class="svg-icon-24" :link="{name: 'Reporter', path: '/reporter/templates', svgicon: 'item-reporter'}"
        id='menu_Reporter' visible_for_products='NetworkSecure' :visible_for_permissions='[{actions: ["GET", "POST"], path: "/reports/.*"}]'>
      </nested-sidebar-item>



<!--
        <sidebar-link :to="{ name: 'icons', query: { debug: debug } }" :name="$t('sidebar.icons')" icon="tim-icons icon-atom"/>
        -->

      </template>
    </nested-sidebar>
    <div class="main-panel">
      <top-navbar/>

      <dashboard-content @click.native="toggleSidebar" />

      <content-footer v-if="footerLogin" />
    </div>
  </div>
</template>

<script>
  import TopNavbar             from "./TopNavbar.vue";
  import ContentFooter         from "./ContentFooter.vue";
  import DashboardContent      from "./Content.vue";
  import MobileMenu            from "./MobileMenu";
  import IspServicesNavbar     from "../navbar/IspServicesNavbar";

  export default {
    components: {
      TopNavbar,
      ContentFooter,
      DashboardContent,
      MobileMenu,
      IspServicesNavbar,      
    },
    data() {
      return {
        links: {
          cyberbulling: [
            { name: 'General', query: this.$route.query, path: '/cyberbulling_endpoint/general', svgicon: 'item-general' },
          ],
          account_management: [
            { name: 'Accounts', query: this.$route.query, path: '/account_management/accounts', icon: 'allot-icon users' },
            { name: 'Managers', query: this.$route.query, path: '/account_management/managers', icon: 'now-ui-icons users_circle-08' },
            { name: 'Users',    query: this.$route.query, path: '/account_management/users',    icon: 'now-ui-icons users_single-02' },
            { name: 'Devices',  query: this.$route.query, path: '/account_management/devices',  svgicon: 'item-devices' }
          ],
          general_management: [
            { name: 'Administrators',  query: this.$route.query, path: '/general_management/administrators',  svgicon: 'item-admin' },
            { name: 'Customization',   query: this.$route.query, path: '/general_management/customization',   icon: 'tim-icons icon-gift-2'},
            { name: 'Password Policy', query: this.$route.query, path: '/general_management/password_policy', svgicon: 'item-passwordpolicy' },
            { name: 'License',         query: this.$route.query, path: '/general_management/license',         svgicon: 'license-information' },
            { name: 'SSO',             query: this.$route.query, path: '/general_management/ssoConfiguration',svgicon: 'item-sso' },
          ]
        }
      }
    },
    methods: {
      toggleSidebar() {
        if(this.$sidebar.showSidebar) {
          this.$sidebar.displaySidebar(false);
        }
      }
    },
    computed: {
      title() {
        return this.$store.getters['customProvider/appName'];
      },
      web() {
        return this.$store.getters['customProvider/web'];
      },
      footerLogin() {
        return this.$store.getters['customProvider/footerLogin'];
      },
      showDataExport() {
        const isIoT = this.$store.getters['settings/isIOT'];
        const dataExportInstalled = this.$store.getters['settings/subsystemsPackages'].includes('data_export');
        return !isIoT && dataExportInstalled;
      }
    },
};
</script>
