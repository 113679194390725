export const initialState = () => ({
  adminReportList: [],
  adminReportDetails: {
    admin_report_id: '',
    name: '',
    type: '',
    guimode_id: '',
    description: '',
    predefined: false,
    programmed: false,
    programming: {
      first_execution: '',
      frequency: '',
      week_day: '',
      month_day: '',
      send_info_mail: {
        sender: '',
        addresses: '',
      },
      send_info: '',
    },
    query: {
      type: '',
      group: [],
      limit: 0,
      order: false,
      date_interval: '',
      lang: 'eng',
      style: 'bar',
      where: [],
    },
  },
  previewedReportDetails: {
    classifyDescending: false,
    dataComputing: false,
    description: '',
    end: '',
    groupedBy: '',
    name: '',
    recordsToShow: 10,
    reportFrom: '',
    selectionCriteria: [],
    start: '',
    subgroupedBy: '',
    timeFrame: '',
    type: '',
  },
  reportConfig: [],
  reportView: {
    data: [],
    report_type: '',
  },
  adminReportExecutions: {
    nextExecution: '',
    lastExecution: '',
    instances: [],
  },
});

export default initialState();
