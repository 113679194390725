<template>
    <b-card>
        <b-col md="12" class="row-eq-height">
            <b-card >
                <slot name="header">
                    <h5 class="title py-2"><i class="now-ui-icons files_box mr-2"/>{{t('Services')}}</h5>
                    {{ t(help_text) }}
                </slot>
                <b-container class="mt-2" fluid style="padding: 0px;" >
                    <b-row no-gutters >
                        <template v-for="(value, name) in services">
                            <b-col :sm="6" :md="6" :xl="4" :key="name" v-if="is_service(value)">
                                <b-container fluid style="padding: 0px;">
                                    <b-row no-gutters class="mb-1">
                                        <b-col cols="8" class="form-group has-label pt-2" align-self="center">
                                            <h5 class="mx-auto my-auto text-capitalize" style="white-space: nowrap;">{{ t(beautify_string(name)) }}</h5>
                                            <span v-if="is_disabled(name)" class="isp-global">{{t('Overwriten by ISP global')}}</span>
                                        </b-col> 
                                        <b-col cols="2" align-self="center">
                                            <n-switch v-model="value.enabled" :service="name" @input="toggle($event, name)" :disabled="is_disabled(name)" color="allot"/>
                                        </b-col>
                                    </b-row>
                                    <div v-if="is_disabled(name)" class='disabled' />
                                </b-container>
                            </b-col>
                        </template>
                    </b-row>
                </b-container>
            </b-card>
        </b-col>
    </b-card>
</template>

<script>
import { ToggleList, Switch } from '@/components';
import GeneralMixin           from './GeneralMixin'

import {mapState, mapGetters, mapMutations, mapActions} from 'vuex';
import _            from 'lodash';

export default {
    components: {
        [ToggleList.name] : ToggleList,
        [Switch.name]: Switch,
    },
    mixins: [GeneralMixin],
    props: {
        store_module: {
            type: String,
            default: ''
        },
		global: {
			type: Boolean,
			default: false
		}
    },
    data() {
        return {
            help_text: "Select services that will be globally enabled for the product:",
        }
    },
	computed: {
        ...mapGetters('ispServices/services', ['global_configurations', 'advanced_configurations']),
        ...mapState('settings',['subsystems', 'subsystemsDict']),

        configurations() {
            return this.global ? this.global_configurations : this.advanced_configurations;
        },        
        services() {
            return _.cloneDeep(this.configurations);
        },
	}, 
    methods: {    
        ...mapMutations('ispServices/services', ['setConfig', 'setGlobalConfig']),
        ...mapActions('settings', ['setIsFormDirty']),
        is_service(value) {
            return _.has(value, 'enabled')
        },

        toggle(value, key) {
     	    this.setIsFormDirty(true);
            this.global ? this.setGlobalConfig({ key, value }) : this.setConfig({ key, value });
        },

        is_disabled(name) {
            return ! _.get(this.global_configurations, `${this.subsystemsDict[name]}.enabled`, true);
        }
    }
}
</script>

<style lang="scss" scoped>
    .isp-global {
        position: absolute;
        font-size: 11px;
        color: red;
        margin-top: -3px;
    }
    .disabled {
        top: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 2;
        background-color: transparent;
        cursor: not-allowed;
    }
</style>
