export default () => ({
    external_servers: new Map(),
    server: {},
    cluster_options: [{ value: "ldap", label: "LDAP" }],
    queryGroups: {},
    isMoreGroups: true,
    sms_servers: {},
    sms_server: {},
    email_servers: {},
    email_server: {}
})
