export const service_packages = { "items" : [
    {
        "isp_notice_id": "campaign_1",
        "enabled": true,
        "type": "Autonotice",
        "ns_id": "T1",
        "own_profile" :true,      							
    },
    {
        "isp_notice_id": "icon_injection",
        "enabled": false,
        "type": "Autonotice",
        "ns_id": "T2",
        "own_profile" :true,      				
    },
    {
        "isp_notice_id": "landing_page",
        "enabled": true,
        "type": "Autonotice",
        "ns_id": "T3",
        "own_profile" :true,      								
    },
    {
        "isp_notice_id": "periodic_report",
        "enabled": true,
        "type": "Autonotice",
        "ns_id": "T4",
        "own_profile" :true,      								
    },
   
] };
