<template>
  <div
    class="form-group"
    :class="[
      { 'input-group': hasIcon },
      { 'input-group-focus': focused },
      { 'has-label': label || $slots.label },
    ]"
  >
    <slot name="label">
      <label v-if="label" :class="labelClasses">
        {{ label }}
        <span v-if="required">*</span>
      </label>
    </slot>

    <slot name="addonLeft">
      <div v-if="addonLeftIcon" class="input-group-addon input-group-prepend">
        <i :class="addonLeftIcon"></i>
      </div>
    </slot>
    <slot>
      <div style="position: relative; flex: 1">
        <textarea
          :style="{ 'border-color': borderColor }"
          v-if="textarea"
          :value="value"
          :disabled="disabled || !permission_granted"
          v-on="listeners"
          v-bind="$attrs"
          :rows="rows"
          :maxlength="maxlength"
          class="form-control"
          :class="[{ valid: value && !error }, inputClasses]"
          aria-describedby="addon-right addon-left"
          :dir="direction"
        />
        <el-tooltip v-else :disabled="!disabled" :content="value" placement="top" effect="light" :tabindex="-1">
          <span>
            <input
              :style="{
                'border-color': borderColor,
                'border-radius': addonLeftIcon ? '0 30px 30px 0' : '',
              }"
              :value="value"
              :disabled="disabled || !permission_granted"
              v-on="listeners"
              v-bind="$attrs"
              :maxlength="maxlength"
              class="form-control"
              :tabindex="noTab ? -1 : 0"
              :class="[{ valid: value && !error }, inputClasses]"
              aria-describedby="addon-right addon-left"
              :type="type ? type : inputType"
              :dir="direction"
            />
          </span>
        </el-tooltip>
        <i
          style="color: #2dce89"
          v-if="showIcon && !error && touched"
          class="icon tim-icons icon-check-2"
        ></i>
        <i
          v-if="error"
          style="color: #f5365c"
          class="icon tim-icons icon-simple-remove"
        ></i>
        
      </div>
    </slot>
    <slot name="addonRight">
      <span v-if="addonRightIcon" class="input-group-addon input-group-append">
        <i :class="addonRightIcon"></i>
      </span>
    </slot>
    <slot name="helpBlock">
          <div
            class="text-danger invalid-feedback"
            style="display: block; white-space: normal; line-height: normal"
            :class="{ 'mt-2': hasIcon }"
            v-if="error"
          >
            {{ error }}
          </div>
    </slot>
    <slot name="infoBlock"></slot>

    <div
      v-if="value && showRemainsChar && inputType === 'text'"
      class="remain-chars"
    >
      {{ value.length }} / {{ maxlength }}
    </div>
  </div>
</template>
<script>
import permissions from '@/mixins/permissions'
export default {
  inheritAttrs: false,
  name: 'fg-input',
  mixins: [permissions],
  props: {
    textarea: {
      type: Boolean,
      default: false,
    },
    rows: {
      type: String,
      default: "1",
    },
    type: String,
    required: Boolean,
    label: String,
    error: String,
    labelClasses: String,
    inputClasses: String,
    value: [String, Number, Array],
    addonRightIcon: String,
    addonLeftIcon: String,
    noTab: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    maxlength: {
      type: [Number, Boolean],
      default: true,
    },
    showIcon: {
      type: Boolean,
      default: () => true,
    },
    showRemainsChar: {
      type: Boolean,
    },
    borderColor: {
      type: String,
    },
    inputType: {
      type: String,
      default: () => "text",
    },
    isRtl: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      touched: false,
      focused: false,
    };
  },
  computed: {
    listeners() {
      return {
        ...this.$listeners,
        input: this.updateValue,
        focus: this.onFocus,
        blur: this.onBlur,
      };
    },
    hasIcon() {
      const { addonRight, addonLeft } = this.$slots;
      return (
        addonRight !== undefined ||
        addonLeft !== undefined ||
        this.addonRightIcon !== undefined ||
        this.addonLeftIcon !== undefined
      );
    },
    direction() {
      return this.isRtl ? 'rtl' : 'ltr'
    }
  },
  methods: {
    updateValue(evt) {
      let value = evt.target.value;
      if (!this.touched && value) {
        this.touched = true;
      }
      this.$emit("input", value)
    },
    onFocus(value) {
      this.focused = true;
      this.$emit("focus", value);
    },
    onBlur(value) {
      this.focused = false;
      this.$emit("blur", value);
    },
  },
};
</script>
<style lang="scss" scoped>
.hide-scrollbar textarea {
  height: 200px !important;

  &::-webkit-scrollbar {
    display: none;
  }
}
.br5 {
  input,
  textarea {
    position: relative;
    max-height: unset !important;
    border-radius: 5px !important;
  }
}

.text-danger {
  color: #ec250d !important;
}

.form-control {
  margin-bottom: 0 !important;
}

.remain-chars {
  position: absolute;
  top: 0;
  right: 6px;
  font-size: 10px;
  color: #8e929d;
}
.icon {
  color: rgb(45, 206, 137);
  position: absolute;
  right: 16px;
  bottom: 14px;
}
</style>
