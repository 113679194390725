/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'device-cast': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path pid="0" d="M28.8 3C30.566 3 32 4.434 32 6.2v19.2c0 1.767-1.434 3.2-3.2 3.2h-8v-3.2h8V6.2H3.2v3.2H0V6.2C0 4.434 1.434 3 3.2 3h25.6zM0 11c9.72 0 17.6 7.88 17.6 17.6h-3.2c0-7.953-6.446-14.4-14.4-14.4V11zm0 12.8a4.8 4.8 0 014.8 4.8H0v-4.8zm0-6.4c6.186 0 11.2 5.015 11.2 11.2H8a8 8 0 00-8-8v-3.2z" _fill="#000" fill-rule="evenodd"/>'
  }
})
