/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'item-management': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g _fill="#383132"><path pid="0" d="M4 18v-.6c0-.3.2-.7.4-.8C6.1 15.5 8 15 10 15h.1c.1-.7.3-1.4.6-2H10c-2.4 0-4.7.7-6.6 1.8-.9.5-1.4 1.5-1.4 2.6V20h9.3c-.4-.6-.8-1.3-1-2H4zM10 12c2.2 0 4-1.8 4-4s-1.8-4-4-4-4 1.8-4 4 1.8 4 4 4zm0-6c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2zM20.8 16c0-.2 0-.4-.1-.6l1.1-1-1-1.7-1.5.5c-.3-.3-.7-.5-1.1-.6L18 11h-2l-.3 1.5c-.4.1-.8.4-1.1.6l-1.4-.5-1 1.7 1.1 1c0 .2-.1.4-.1.6 0 .2 0 .4.1.6l-1.1 1 1 1.7 1.4-.5c.3.3.7.5 1.1.6L16 21h2l.3-1.5c.4-.1.8-.4 1.1-.6l1.5.5 1-1.7-1.1-1c-.1-.3 0-.5 0-.7zM17 18c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z"/></g>'
  }
})
