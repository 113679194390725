/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'item-contentfilter': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M6.6 6h10.8L12 12.8 6.6 6zm-2.9-.4c2.2 2.8 6.2 8 6.2 8V20c0 .6.5 1.1 1.1 1.1h2.2c.6 0 1.1-.5 1.1-1.1v-6.5s4-5.2 6.2-8c.5-.7 0-1.7-.9-1.7h-15c-1 0-1.5 1-.9 1.8z" _fill="#383132"/>'
  }
})
