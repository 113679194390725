import axios from "axios";
import i18n from '../../../i18n';
import { filterStrategyByService } from "@/util/strategyUtil";
import { prepareAntiBotnetData, prepareStrategyData, prepareStrategyStatusData } from "@/util/antibotnetUtils";
import { antiBotnet as dummyAntiBotnet } from '@/util/dummy/antibotnetRules.json';
import { getPartialStrategyFromRule, setDeletedElementsToNull } from "@/util/strategyUtil";

export default {
    async getAntiBotnet({ commit, state, getters }) {
        if (getters.isDebug) {
            if (!state.antiBotnetStrategy || state.antiBotnetStrategy.rules.length === 0)
                commit("setAntiBotnet", dummyAntiBotnet)
        } else {
            // Get anti botnet from backend
            try {
                const antiBotnetStrategy = await axios.get('/subsystems/ns/isp-strategy');
                commit("setAntiBotnet", filterStrategyByService(antiBotnetStrategy.data, 'anti_botnet'));
            } catch (err) { console.error('Error getting isp strategy: ', err) }
        }
    },
    getAntiBotnetRulesData({ state, commit, getters }) {
        if (!getters.isDebug || state.rules.length === 0) {
            let antiBotnet = getters.getAntiBotnetStrategy();
            antiBotnet = prepareAntiBotnetData(antiBotnet);
            const sortedRules = antiBotnet.sort((a, b) => {
                return a.order > b.order ? 1 : -1;
            });

            commit("setAntiBotnetRules", sortedRules);
        }
    },
    async createAntiBotnetRule({ state, commit, getters, dispatch }, createdRule) {
        createdRule.order = 0;
        createdRule.id = createdRule.rule_name;

        if (getters.isDebug) {
            commit("addAntiBotnetRule", createdRule);
        } else {
            const partialStrategy = prepareStrategyData([createdRule]);
            try {
                await axios.post('/subsystems/ns/isp-strategy/provision', { ...partialStrategy });
                await dispatch("getAntiBotnet");
                commit("setAntiBotnetRules", prepareAntiBotnetData(getters.getAntiBotnetStrategy()));
            } catch (err) {
                console.error('Error updating strategy: ', err);
                if (err.response) {
                    switch(err.response.status){
                        case 409:
                            if (err.response.data.type && err.response.data.type === 'ISP_STRATEGY.LIMIT_EXCEEDED') {
                               commit('setError', i18n.t('MAX_POLICIES_ALLOWED'), {root: true})
                            } else {
                               commit('setError', i18n.t(err.response.data.type), {root:true});
                            }
                            break;
                        default:
                            commit('setError', err, {root:true})
                    }
                }
                throw new Error(err);
            }
        }
    },
    async deleteRule({ state, commit, getters, dispatch }, ruleToDelete) {
        if (getters.isDebug) {
            const deletedOrder = ruleToDelete.order;
            let rules = _.cloneDeep(state.rules);
            const updatedRules = rules.filter(rule => {
                if (rule.rule_name !== ruleToDelete.rule_name) {
                    if (rule.order > deletedOrder) rule.order--;
                    return rule;
                }
            })
            commit("setAntiBotnetRules", updatedRules);
        } else {
            // Patch to backend
            try {
                await axios.patch("/subsystems/ns/isp-strategy?cascade_delete=true", { rules: { [ruleToDelete.rule_name]: null } });
                await dispatch("getAntiBotnet");
                commit("setAntiBotnetRules", prepareAntiBotnetData(getters.getAntiBotnetStrategy()));
            } catch (err) {
                console.error('Error deleting isp strategy rule: ', err);
                commit('setError', err, { root: true });
            }
        }
    },
    async updateAntiBotnetRule({ commit, getters, state, dispatch }, updatedRule) {
        if (getters.isDebug) {
            const updatedRules = state.rules.map(rule =>
                rule.rule_name === updatedRule.rule_name ? updatedRule : rule
            );
            commit("setAntiBotnetRules", updatedRules);
        } else {
            // Patch to backend
            let newPartialStrategy = prepareStrategyData([updatedRule]);
            let oldPartialStrategy = getPartialStrategyFromRule(updatedRule, getters.getAntiBotnetStrategy())
            newPartialStrategy = setDeletedElementsToNull(oldPartialStrategy, newPartialStrategy);
            try {
                await axios.patch('/subsystems/ns/isp-strategy', { ...newPartialStrategy });
                await dispatch("getAntiBotnet");
                commit("setAntiBotnetRules", prepareAntiBotnetData(getters.getAntiBotnetStrategy()));
            } catch (err) {
                console.error('Error updating rule: ', err);
                commit('setError', err, { root: true });
                throw err
            }
        }
    },
    async updateAntiBotnetRuleStatus({ commit, getters, state, dispatch }, updatedRule) {
        if (getters.isDebug) {
                const updatedRules = state.rules.map(rule => {
                    if (rule.rule_name === updatedRule.rule_name) {
                        rule.status = updatedRule.status;
                        return rule;
                    } else {
                        return rule;
                    }
                }
            );
            commit("setAntiBotnetRules", updatedRules);
        } else {
            let newPartialStrategy = prepareStrategyStatusData(updatedRule);
            try {
                await axios.patch('/subsystems/ns/isp-strategy', { ...newPartialStrategy });
            } catch (err) {
                console.error('Error updating rule: ', err);
                commit('setError', err, { root: true });
                throw err
            }
        }
    },
    async getAdvancedConfiguration({ commit, getters, state }) {
        if (getters.isDebug) {
            if (!state.advancedConfiguration || Object.keys(state.advancedConfiguration).length === 0) {
                let config = {
                    blocking_page: {
                        page_mode: 'default',
                        custom_blocking_page_url: ''
                    }
                };
                commit("setAdvancedConfiguration", config);
            }
        } else {
            //Get anti botnet advanced configuration from backend
            try {
                const advancedConfiguration = await axios.get('subsystems/ns/service-configuration');
                const config = advancedConfiguration.data.anti_botnet;
                commit("setAdvancedConfiguration", config);
            } catch (err) {
                console.error('Error getting AntiBotnet Advanced Configuration: ', err);
                commit('setError', err, { root: true });
            }
        }
    },
    async updateAntiBotnetAdvancedConfiguration({ commit, state, dispatch, getters }, config) {
        const advanced = {
            blocking_page: {
                page_mode: config.page_mode,
            }
        };
        if(config.page_mode !== 'default'){
            advanced.blocking_page.custom_blocking_page_url = config.custom_blocking_page_url
        }
        if (getters.isDebug) {
            commit('setSuccess', i18n.t('save_success'), { root: true });
            commit("setAdvancedConfiguration", advanced);
        } else {
            // PATCH to backend
            const advancedConfiguration = {
                anti_botnet: advanced
            };
            try {
                await axios.patch('subsystems/ns/service-configuration', advancedConfiguration);
                commit('setSuccess', i18n.t('save_success'), { root: true });
                commit("setAdvancedConfiguration", config);
            } catch (err) {
                commit('setError', err, { root: true });
            }
        }
    },
    async getDefaultBlockingUrl({ commit, rootState, getters, dispatch }) {
        const systemOption = 'SystemAntiBotnetBlockingPage';
        if (getters.isDebug) {
            // Random between get default System and default External
            const chosenValue = Math.random() < 0.5 ? systemOption : 'DefaultExternalPage';
            let global_configurations = {
                threat_protection: {
                    blocking_pages: {
                        default_antibotnet_blocking_page: chosenValue,
                        blocking_pages_info: {
                            system_antibotnet_blocking_page_url: 'http://default.page.com',
                            external_blocking_pages: [
                                {
                                    name: 'DefaultExternalPage',
                                    blocking_page_url: 'http://default-external.page.com'
                                },
                                {
                                    name: 'OtherExternalPage',
                                    blocking_page_url: 'http://other-external.page.com'
                                }
                            ]
                        }
                    }
                }
            };
            let default_blocking_page = global_configurations.threat_protection.blocking_pages.default_antibotnet_blocking_page;
            let defaultPage = default_blocking_page === systemOption
                ? {
                    name: systemOption,
                    blocking_page_url: global_configurations.threat_protection.blocking_pages.blocking_pages_info.system_antibotnet_blocking_page_url
                }
                : global_configurations.threat_protection.blocking_pages.blocking_pages_info.external_blocking_pages.find(page => page.name === default_blocking_page)
            commit("setDefaultBlockingUrl", defaultPage)
        } else {
            // Get default URL from backend
            await dispatch('ispServices/services/getGlobalConfigurations', {}, { root: true })
            let global_configurations = rootState.ispServices.services.global_configurations;
            let default_blocking_page = global_configurations.threat_protection.blocking_pages.default_antibotnet_blocking_page;
            let defaultPage = default_blocking_page === systemOption
                ? {
                    name: systemOption,
                    blocking_page_url: global_configurations.threat_protection.blocking_pages.blocking_pages_info.system_antibotnet_blocking_page_url
                }
                : global_configurations.threat_protection.blocking_pages.blocking_pages_info.external_blocking_pages.find(page => page.name === default_blocking_page)

            commit("setDefaultBlockingUrl", defaultPage)
        }
    }
}
