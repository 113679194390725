/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'autonotices-smartbanners': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" _fill="none" _stroke="#383132" stroke-width="1.5" stroke-miterlimit="10" d="M4.7 11.2V4.7h14.6v14.6h-7"/><path pid="1" _fill="none" _stroke="#383132" stroke-width="1.5" stroke-miterlimit="10" d="M4.7 14.7h4.7v4.7H4.7zM11 12.8l5-4.9M16 11.9V7.5M12.3 8l4.4-.1"/>'
  }
})
