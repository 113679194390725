import _ from 'lodash';
import { state, ADVANCED_CONFIGURATION } from './state';

export default {
  clear(state) {
    state._routers = []
  },

  addRouter(state, routers) {   
    routers.forEach(router => {
      state._routers.push(router);
    });
  },

  removeRouter(state, router) {
    const filter = state._routers.filter(element => element.router_id !== router.router_id)
    state._routers = filter;
  },

  saveFieldOptions(state, data) {
    const option = []
    data.res && data.res.map(element => {
      option.push({ value: element, label: element})
    })
    switch(data.name){
      case 'router_model':
        state.router_model_options = option
      break
      case 'firmware_version':
       state.router_firmware_options = option
      break
      case 'current_agent_version_id' :
        state.active_version_options = option
      break
      case 'target_agent_package_id' :
       state.target_version_options = option
      break    
    } 
  },
  saveAttributeOptions(state, data) {
    state.attribute_options = data
  },

  saveDebugFieldOptions(state, data) {
    state = {
      ...state, 
      data
    }
  }  ,

  setJob(state, job) {
    state.job_id = job
  },

  setAdvancedConfiguration(state, advanced_configuration) {
    state.advanced_configuration = {
      ...ADVANCED_CONFIGURATION,
      ...advanced_configuration
    }
  },

  addAgentPackages(state, packages) {
    state.agent_packages = packages
  },
}