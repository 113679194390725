import _ from 'lodash';
import axios from "axios";
import i18n from '../../../i18n';
import { createAdministratorFunction, mockAdministrator } from './mockData'

export default {
  createAdministrator({ commit, state, dispatch }, data) {
    return new Promise((resolve, reject) => {
      let config = { params: { type: 'isp' } }
      data.account_id = 'ISPADMINS'
      axios.post('/managers', data, config)
        .then(res => {
          if (res && res.data) {
            let item = res.data

            const admin = createAdministratorFunction(item)
            commit('addAdministrator', admin)
            commit("setSuccess", `Administrator ${admin.data.identity.name} created!`, { root: true });
            resolve(item.manager_id)
          }
        })
        .catch(error => {
          if (error.response && error.response.data) {
            reject(error.response.data);
          }
        })
    });
  },
  getAdministrators({ commit, state, rootState, dispatch, getters }, { wait: wait, query: query }) {
    if (!rootState.debug) {
      let config = { params: query }
      config.params.type = 'isp'
      if (wait)
        config = { headers: { 'x-wait-on-loading': wait } }

      return new Promise((resolve, reject) => {
        axios.get('/managers', config)
          .then(res => {
            if (res && res.data && res.data.items) {
              state.administrators.clear()
              res.data.items.forEach(item => {
                  const admin = createAdministratorFunction(item)
                  if (admin) {
                    commit('addAdministrator', admin)
                  }
              })

              let ret = { items: state.administrators, query: res.data.query, self: query }
              resolve(ret)
            }
          })
          .catch(error => {
            commit('setError', error, { root: true });
            reject()
          })
      })
    }
    else {
      return new Promise((resolve, reject) => {
        commit('addAdministrator', { id: mockAdministrator.manager_id, data: mockAdministrator })
        resolve(state.administrators)
      });
    }
  },
  removeAdministrator({ commit, state, rootState, dispatch }, id) {
    if (rootState.debug) {
      commit('removeAdministrator', id)
    }
    else {
      let config = { params: { type: 'isp' } }
      return new Promise((resolve, reject) => {
        axios.delete('/managers/' + encodeURIComponent(id), config)
          .then(res => {
            commit('removeAdministrator', id);
            resolve(state.administrators)
          })
          .catch(error => {
            commit('setError', error, { root: true });
            reject()
          })
      });
    }
  },
  getAdministrator({ commit, state, rootState, dispatch }, id) {
    if (rootState.debug) {
      return new Promise((resolve, reject) => {
        commit('addAdministrator', { id: mockAdministrator.manager_id, data: mockAdministrator })
        resolve(mockAdministrator)
      })
    }
    else {
      return new Promise((resolve, reject) => {
        let config = { params: { type: 'isp' } }
        axios.get('/managers/' + encodeURIComponent(id), config)
          .then(res => {
            if (res && res.data) {
              let item = res.data
              const admin = createAdministratorFunction(item)
              if (admin) {
                commit('addAdministrator', admin)
                const langId = admin.data.manager_metadata.language_id
                commit('setDefaultAdministratorLanguageId', langId)
                resolve(admin.data)
              }else {
                console.error("user_id not found for manager " + item.manager_id)
                commit('setError', i18n.t('administrator_not_found'), { root: true });
              }
            }
            else {
              reject()
            }
          })
          .catch(error => {
            commit('setError', error, { root: true });
            reject()
          })
      });
    }
  },
  getAdministratorProfile({ commit, state, rootState, dispatch }) {
    if (rootState.debug) {
      return new Promise((resolve, reject) => {
        commit('addAdministrator', { id: mockAdministrator.manager_id, data: mockAdministrator })
        resolve(mockAdministrator)
      })
    }
    else {
      return new Promise((resolve, reject) => {
        axios.get('/manager-loggedin')
          .then(res => {
            if (res && res.data) {
              let item = res.data
              const admin = createAdministratorFunction(item)
              if (admin) {
                commit('addAdministrator', admin)
                resolve(admin.data)
              }else {
                console.error("user_id not found for manager " + item.manager_id)
                commit('setError', i18n.t('administrator_not_found'), { root: true });
              }
            }
            else {
              reject()
            }
          })
          .catch(error => {
            commit('setError', error, { root: true });
            reject()
          })
      });
    }
  },
  getRoles({ commit, state, rootState, dispatch }, wait) {
    if (rootState.debug) {
      return new Promise((resolve, reject) => {
        let roles = {
          "permissions": [{ "action": "GET", "path": "/*" },
          { "action": "POST", "path": "/*" },
          { "action": "PUT", "path": "/*" },
          { "action": "PATCH", "path": "/*" },
          { "action": "DELETE", "path": "/*" }],
          "protected": true,
          "role_id": "super_role"
        }
        commit('setRoles', { id: roles.role_id, data: roles })
        resolve();
      })
    }
    else {
      let headers = null
      if (wait)
        headers = { headers: { 'x-wait-on-loading': wait } }
      return new Promise((resolve, reject) => {
        axios.get('/roles', headers)
          .then(res => {
            if (res && res.data && res.data.items) {
              res.data.items.forEach(item => {
                commit('addRole', { id: item.role_id, data: item })
              });
            }
            resolve();
          })
          .catch(error => {
            commit('setError', error, { root: true });
            reject();
          })
      });
    }
  },
  updateAdministrator({ commit, state, dispatch }, update) {
    let admin_data = _.cloneDeep(update.data)
    if (admin_data.identity) {
      if (admin_data.identity.identity_instances) {
        admin_data.identity.identity_instances = [admin_data.identity.identity_instances]
      }
    }
    let config = { params: { type: 'isp' } }
    return new Promise((resolve, reject) => {
      axios.patch('/managers/' + encodeURIComponent(update.id), admin_data, config)
        .then(res => {
          commit('setAdministrator', { id: update.id, data: update.data });
          resolve(res);
        })
        .catch(error => {
          reject(error)
        })
    });
  },
  updateAdministratorProfile({ commit, state, dispatch }, update) {
    let admin_data = _.cloneDeep(update.data)
    if (admin_data.identity) {
      if (admin_data.identity.identity_instances && !Array.isArray(admin_data.identity.identity_instances)) {
        admin_data.identity.identity_instances = [admin_data.identity.identity_instances]
      }
    }
    return new Promise((resolve, reject) => {
      axios.patch('/manager-loggedin', admin_data)
        .then(res => {
          commit('setAdministrator', { id: update.id, data: update.data });
          resolve(res)
        })
        .catch(error => {
          reject(error)
        })
    });
  },
}