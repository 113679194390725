/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'filtering-log-configuration': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path pid="0" d="M20.8 22.502a4.8 4.8 0 014.32 2.708h5.28a1.6 1.6 0 110 3.2h-4.96a4.8 4.8 0 01-9.28 0H1.6a1.6 1.6 0 110-3.2h14.88a4.8 4.8 0 014.32-2.708zm0 3.156a1.6 1.6 0 100 3.2 1.6 1.6 0 000-3.2zM9.576 11.252a4.8 4.8 0 014.344 2.758H30.4a1.6 1.6 0 110 3.2H14.24a4.8 4.8 0 01-9.296 0H1.6a1.6 1.6 0 110-3.2h3.2c.144-.015.288-.015.432 0a4.8 4.8 0 014.344-2.758zm.024 3.206a1.6 1.6 0 100 3.2 1.6 1.6 0 000-3.2zM19.2 0a4.8 4.8 0 014.368 2.81c.144-.015.288-.015.432 0h6.4a1.6 1.6 0 110 3.2h-6.544a4.8 4.8 0 01-9.312 0H1.6a1.6 1.6 0 110-3.2h12.8c.144-.015.288-.015.432 0A4.8 4.8 0 0119.2 0zm0 3.258a1.6 1.6 0 100 3.2 1.6 1.6 0 000-3.2z" _fill="#000" fill-rule="evenodd"/>'
  }
})
