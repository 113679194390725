import axios from 'axios';
import router from '../../router/index';
import { stat } from 'fs';
import i18n from '../../i18n';

export default {
    setUsernameReset({ commit, dispatch }, username) {
        commit('setUsernameReset', username)
    },
    setRecoveryMethod({ commit, dispatch }, recovery_method) {
        commit('setRecoveryMethod', recovery_method)
    },
    setRecoveryInfo({ commit, dispatch }, recoveryInfo) {
        commit('setRecoveryInfo', recoveryInfo)
    },
    setIdentityData({ commit, dispatch }, identity_data) {
        commit('setIdentityData', identity_data)
    },
    setRecoveryData({ commit, dispatch }, recovery_data) {
        commit('setRecoveryData', recovery_data)
    },
    setNewPassword({ commit, dispatch }, new_password) {
        commit('setNewPassword', new_password)
    },
    setRecoveryErrorMessage({ commit, dispatch }, recoveryErrorMessage) {
        commit('setRecoveryErrorMessage', recoveryErrorMessage)
    },
    setPrevPage({ commit, dispatch }, prevPage) {
        commit('setPrevPage', prevPage);
    },

    goToRecoverPassword({ commit, state, dispatch }, username) {
        commit('setUsernameReset', username);
        router.push('/recoverpassword')
    },

    recoverUsernameRequest({ commit, dispatch }, payload) {
        console.log("recoverUsernameRequest -> payload")
        let loc = window.location;
        payload['FQDN'] = loc.hostname + (loc.port ? ":" + loc.port : "");

        return new Promise((resolve, reject) => {
            axios.post('/identity/reset', payload, { headers: { 'x-wait-on-loading': 0 } })
                .then(res => {
                    let message = ''
                    switch (payload.restablish.method_name) {
                        case 'sms':
                            message = i18n.t("Recovery SMS sent, check your mobile phone")
                            break
                        case 'email':
                            message = i18n.t("Recovery E-mail sent, check your inbox")
                            break
                        default:
                            message = i18n.t("Unknown recovery method, please try again")
                    }
                    dispatch('setSuccess', message, { root: true });
                    resolve()
                })
                .catch(error => {
                    let msg = '';
                    let { data } = error.response;
                    if (data.status === 400) {
                        msg = i18n.t("It has not been possible to recover");
                    } else if (data && data.title) {
                        msg = i18n.t("It has not been possible to process your request");
                    } else {
                        switch (error.response.status) {
                            case 404:
                                msg = i18n.t("The data you entered doesn't exist");
                                break;
                            case 410:
                                msg = i18n.t("The data you entered no longer exists");
                                break;
                            case 500:
                                msg = i18n.t("It has not been possible to recover the username");
                                break;
                        }
                    }

                    dispatch('setError', msg, { root: true })
                    commit('setRecoveryErrorMessage', msg);
                    reject()
                })
        });
    },

    resetPasswordRequest({ commit, dispatch }, payload) {
        let loc = window.location;
        payload['FQDN'] = loc.hostname + (loc.port ? ":" + loc.port : "");
        // axios.post('http://localhost:5000/identity/reset', payload)
        axios.post('/identity/reset', payload, { headers: { 'x-wait-on-loading': 0 } })
            .then(res => {
                // commit('setUsername', res.data.username); // TODO
                //dispatch('setRecoveryMethod', payload.restablish.method_name)
                if (payload.restablish.method_name == 'sms') {
                    if (payload.restablish["username"]) {
                        dispatch('setRecoveryMethod', 'username');
                    }
                    else {
                        dispatch('setRecoveryMethod', 'sms');
                    }
                    dispatch('setSuccess', i18n.t('sms_sent'), { root: true });
                    router.push('/smscode');
                }
                else if (payload.restablish.method_name == 'email') {
                    commit('setRecoveryMethod', 'email');
                    dispatch('setSuccess', i18n.t('email_sent'), { root: true });
                    commit('setRecoveryErrorMessage', i18n.t('email_sent'));
                    router.push('/'); // TODO revisar el destino antes: /reset404
                }
            })
            .catch(error => {
                let msg = '';
                let { data } = error.response;
                if (data.status === 400) {
                    msg = i18n.t("It has not been possible to recover");
                } else
                    if (data && data.title) {
                        msg = data.title;
                    } else {
                        switch (error.response.status) {
                            case 404:
                                msg = i18n.t("The data you entered doesn't exist. Please check it and try again.");
                                break;
                            case 410:
                                msg = i18n.t("The data you entered no longer exists. Please check it and try again.");
                                break;
                            case 500:
                                msg = i18n.t("It has not been possible to recover the password");
                                break;
                        }
                    }

                dispatch('setError', msg, { root: true })
                commit('setRecoveryErrorMessage', msg);
                router.push('/reset404')
            });

    },


    resetPasswordChange({ state, commit, dispatch }, payload) {
        const headers = {
            'Identity': btoa(state.username + ':' + payload.new_password),
            'x-wait-on-loading': 0,
        };
        // axios.post('http://localhost:5000/identity/reset/' + payload.type + '/' + payload.identity_data + '/' + payload.recovery_data, null, {headers})
        axios.post('/identity/reset/' + payload.type + '/' + encodeURIComponent(payload.identity_data) + '/' + payload.recovery_data, null, { headers })
            .then(res => {
                dispatch('setSuccess', i18n.t('password_changed'), { root: true })
                router.push('/');
            })
            .catch(error => {
                let { data } = error.response;
                let msg = data ? i18n.t(data.type) : i18n.t('password_change_error');

                dispatch('setError', msg, { root: true });
                commit('setRecoveryErrorMessage', msg);
                router.push('/reset404')
            });
    },

    checkIdentityRecovery({ commit, dispatch }, payload) {
        // axios.get('http://localhost:5000/identity/reset/' + payload.type + '/' + payload.identity_data + '/' + payload.recovery_data)
        axios.get('/identity/reset/' + payload.type + '/' + encodeURIComponent(payload.identity_data) + '/' + payload.recovery_data,
            { headers: { 'x-wait-on-loading': 0 } })
            .then(res => {
                // commit('setUsername', res.data.username);
                router.push('/changepassword');
            })
            .catch(error => {
                if (payload.type == 'sms') {
                    commit('setRecoveryErrorMessage', i18n.t('invalid_code'));
                    dispatch('setError', i18n.t('invalid_code'), { root: true });
                } else if (payload.type == 'email') {
                    commit('setRecoveryErrorMessage', i18n.t('invalid_link'));
                    dispatch('setError', i18n.t('invalid_link'), { root: true });
                } else {
                    commit('setRecoveryErrorMessage', i18n.t('invalid_information'));
                    dispatch('setError', i18n.t('invalid_information'), { root: true });
                };
                router.push('/reset404');

                let msg = '';
                switch (error.response.status) {
                    case 404:
                        msg = i18n.t("The data you entered doesn't exist. Please check it and try again.");
                        break;
                    case 410:
                        msg = i18n.t("The data you entered no longer exists. Please check it and try again.");
                        break;
                }
                dispatch('setError', msg, { root: true });
                commit('setRecoveryErrorMessage', msg);
                router.push('/reset404');
            });
    },


};