export const mockDevice = (data = { account_id: "allot_secure_demo", user_id: "a961ebfee0254de2bbdad04b3c2d0723" }) => ({
    "account_id": data.account_id,
    "description": "Allot Secure Phone",
    "device_id": "db4522c29a5341269d4bf5138fa905b6",
    "external_data": {},
    "platform": "android",
    "product_data": {
        "EndpointSecure": {
            "provisioned": true
        },
        "HomeSecure": {
            "provisioned": true
        },
        "NetworkSecure": {
            "ips": [
                {
                    "ip_begin": "10.203.2.1",
                    "ip_end": "10.203.2.1"
                }
            ],
            "ns_id": "34666555444",
            "provisioned": true
        }
    },
    "type": "mobile",
    "user_id": data.user_id
})

export const mockDeviceRS = (data = { account_id: "allot_secure_demo", user_id: "a961ebfee0254de2bbdad04b3c2d0723" }) => ({
    "user_id": data.user_id,
    "device_id": "db4522c29a5341269d4bf5138fa905b6",    
    "account_id": data.account_id,
    "description": "Allot Secure Phone",
    "type": "mng_router",
    "platform": "android",
    "external_data": {},
    "mac_addresses": ["00:18:44:11:3A:B7","00:18:44:11:3A:B9", "00:18:44:11:3A:BA"],
    "product_data": {        
        "RouterSecure": {
            "provisioned": true,            
            "is_router" : true,
            "data" : {
                "status": {
                    "active" : true,
                    "mac_addresses": ["00:18:44:11:3A:B7","00:18:44:11:3A:B9", "00:18:44:11:3A:BA"],
                    "router_model" : "OpenWrt x64",
                    "firmware_version" : "4.0.0.29",
                    "serial_number" : "123-968-897",
                    "current_agent_package_id" : "agent current id",
                    "current_agent_version_id" : "agent version id",
                    "safe_mode" : true,
                    "safe_mode_reset_time" : "2021-07-10T12:15:15Z",
                    "reboot_loop" : true,
                    "reboot_loop_reset_time": "2021-07-10T08:25:25Z",
                },
                "configuration" : {
                    "identifiers" : [
                        {"type": "serial_number",   "value" : "69874-8965-985"},
                        {"type": "mac_address",     "value" : "00:18:44:12:3C:B9"}],
                    "attributes" : [
                        {"name" : "typ1", "value" : "aaaaaa"},
                        {"name" : "typ1", "value" : "bbbbb"},
                        {"name" : "typ1", "value" : "ccccc"},
                        {"name" : "typ2", "value" : "ddddd"},
                        {"name" : "typ2", "value" : "eeeee"},
                        {"name" : "typ2", "value" : "fffff"}],
                    "enabled" : false,
                    "target_agent_package_id" : "1234",
                    "enable_dns_settings_enforcement" : true,
                    "enable_allowed_list_enforcement" : false
                }
            },
        }
    }
    
    
})


export const createDevice = (item) => {
    if (!item.product_data) {
        item.product_data = {};
    }

    ['EndpointSecure', 'HomeSecure', 'NetworkSecure', 'DNSecure'].forEach(product => {
        if (!item.product_data[product]) {
            item.product_data[product] = {
                provisioned: false
            };
        }
    });
    const device = {
        id: item.device_id,
        data: item
    };

    return device
}
