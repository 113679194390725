/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'license-information': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path pid="0" d="M13.001 28.412l3.2-3.2 3.2 3.2v-6.476c-2.088.62-4.312.62-6.4 0v6.476zm11.2-17.212a8 8 0 11-8-8c4.42 0 8 3.581 8 8zm3.2 0c.003 3.666-1.79 7.1-4.8 9.192V32h-4.138l-2.262-2.262L13.939 32H9.8V20.392C4.724 16.858 3.475 9.877 7.009 4.802 10.543-.275 17.524-1.524 22.601 2.01a11.198 11.198 0 014.8 9.19z" _fill="#000" fill-rule="evenodd"/>'
  }
})
