import Vue from "vue";
import Vuex from 'vuex'
import VueRouter from "vue-router";
import RouterPrefetch from 'vue-router-prefetch'
import App from "./App";
import router from "./router/index";
import ElementUI from 'element-ui';

import ispDashboard from "./plugins/ispDashboard";

import './registerServiceWorker'
import { store } from './store';

import axios from 'axios';

// SVG icons
import VueSVGIcon from 'vue-svgicon'
Vue.use(VueSVGIcon)
import './compiled-icons';

import VueCookie from 'vue-cookie'

import BootstrapVue from 'bootstrap-vue'
import VeeValidate from 'vee-validate'

//Needed by Vue2Leaflet
import 'leaflet/dist/leaflet.css';
import 'leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.webpack.css'; // Re-uses images from ~leaflet package
import * as L from 'leaflet';
import 'leaflet-defaulticon-compatibility';


import i18n from './i18n'

Vue.use(ElementUI, { i18n: (key, value) => i18n.t(key, value) })

//global mixin, helper functions
import './helpers'
import _ from 'lodash';

Vue.use(ispDashboard);
Vue.use(VueRouter);
Vue.use(RouterPrefetch);
Vue.use(BootstrapVue)
Vue.use(VeeValidate, { events: 'blur|change', fieldsBagName: 'veefields' });
Vue.use(VueCookie);

// error handling: general
Vue.config.errorHandler = err => {
  console.error('Exception: ' , err);
}

import Config from '@/config'


// global config axios
const backend_version = "v1";
axios.defaults.baseURL = '/api-isp/' + backend_version;
axios.defaults.headers.get['Accepts'] = 'application/json';

store.dispatch('auth/autoLogin');

router.onError(error => {
  if (/loading chunk \d* failed./i.test(error.message)) {
    window.location.reload()
  }
})

function dispatchServices(to, next){
  return store.dispatch('settings/getSubsystemServices')
  .then( () => {
    if (checkRolePermissions(to)) {
      // authorization by services / products
      let route_init = to.path.split('/')[1]
      if ( route_init.toLowerCase() === 'general' && !store.getters['settings/getProductState']('NetworkSecure'))
       checkRolePermissions({path: "/account_management/accounts"}) ? next({name: "Account Management / Accounts"}) : (next({name: "Basic Dashboard"}), displayUnathorizedRouteMessage());

      let services_ns_regex = new RegExp( Config.available_services_ns.join( "|" ), "i");
      if ( services_ns_regex.test(route_init) && (!store.getters['settings/getProductState']('NetworkSecure') || !store.getters['settings/getServiceStateNS'](route_init))){
        checkRolePermissions({path: "/account_management/accounts"}) ? next({name: "Account Management / Accounts"}) : (next({name: "Basic Dashboard"}), displayUnathorizedRouteMessage());
      }
      else{
        next()
      }
    } else {
      if (store.getters['auth/userRoles'].length < 1)  { 
        console.log("Administrator Roles:", "No roles assigned");
        store.dispatch('auth/logout');
      } else {
        (next({name: "Basic Dashboard"}), displayUnathorizedRouteMessage())
      }
    }
  }, next)
  .catch( (error) => {
    console.log("Error", error);
    store.dispatch('auth/logout')
    next({ name: "Login"})
  })

}

//routes guard
router.beforeEach ((to, from, next) => {
  if (to.name == 'Login'){
    if (store.getters["auth/isAuthenticated"])
      if ( to.query && to.query['out'] == 'true'){
        store.dispatch('auth/logout')
       next()
     }
     else {
     checkRolePermissions({path: "/account_management/accounts"}) ? next({name: "Account Management / Accounts"}) : (next({name: "Basic Dashboard"}), displayUnathorizedRouteMessage());
    }
   else
     next()
 }
  else {
    if ([
        'ForgotMyPassword',
        'ForgotMyUsername',
        'SMSCode',
        'RecoverPassword',
        'ChangePassword',
        'MagicLink',
        'ResetPassword404',
        'ForceChangePassword',
        'stop-pc',
        'stop-ph'
      ].includes(to.name)){
        next()
    }
    else if (to.name === 'ExpiredPassword'){
      if ( store.getters["auth/isExpiredPassword"])
        next()
      else
        next({ name: "Login"})
    }
    else if (store.getters["auth/isAuthenticated"]){
      if ( store.getters['auth/refreshingAccessToken']()){
        const watcher = store.watch(store.getters['auth/refreshingAccessToken'], refreshing => {
          watcher(); // stop watching
          if ( !refreshing && store.getters["auth/refreshToken"]){
            store.dispatch('providers/getActiveProviders')
            store.dispatch('settings/getSubsystemsPackages');
            dispatchServices(to, next)
          } else {
            next({ name: "Login"})
          }
        });
      }
      else{
        store.dispatch('providers/getActiveProviders')
        store.dispatch('settings/getSubsystemsPackages');
        dispatchServices(to, next)
      }
    }
    else
      next({ name: "Login"})

  }

})

// error handling: XMLHTTPRequests
axios.interceptors.response.use(
  response => {
    let isHigh = parseInt(response.config.headers['x-wait-on-loading']) === 0
    store.commit('settings/finishLoading', isHigh)
    return response
  },
  error => {
    let isHigh = parseInt(error.config.headers['x-wait-on-loading']) === 0
    store.commit('settings/finishLoading', isHigh)
    try{
      if ( ! (error.config.url.endsWith('login') || error.config.url.endsWith('changepasswordexpired'))
                                                               && [401, 403].includes(error.response.status)){
        if ( error.response.status == 401 && error.response.data.type.endsWith('NEEDS_AUTHORIZATION')){
          return Promise.reject(error)
        } 

        if ( !error.config.url.endsWith('identity/refreshtoken')){
          return store.dispatch('auth/requestAccessToken')
            .then( (token) => {
              const config = error.config;
              config.headers['Authorization'] = 'Bearer ' + token;

              return new Promise((resolve, reject) => {
                axios.request(config).then(response => {
                  resolve(response);
                }).catch((error) => {
                  reject(error);
                })
              });
            })
            .catch((err) => {
              if(err &&  err.response &&  err.response.config && err.response.config.url.endsWith('identity/refreshtoken')) {
                store.dispatch('auth/logout')
                setTimeout(function(){
                    router.push({ name: 'Login'})
                }, 500);
              } else {
                if (err && err.response && !!err.response.data) {
                  return new Promise((resolve, reject) => reject(err.response.data))
                }else {
                  return new Promise((resolve, reject) => reject(err))
                }
              } 
            });
        }
        else if (404 === error.response.status){
          console.log(error.response)
        }
        else{
          store.dispatch('auth/logout')
          setTimeout(function(){
              router.push({ name: 'Login'})
          }, 500);
        }
      }

    }
    catch(err){
      store.commit('setError', error, {root: true});
    }
    return Promise.reject(error);
  }
);

// auth bearer token update on every call
axios.interceptors.request.use(
  config => {
    let wait = parseInt(config.headers['x-wait-on-loading'])
    if ( isNaN(wait))
      wait = 1

    if ( wait){
      setTimeout(() => {
        store.commit('settings/startLoading', false)
      }, wait*1000);
    }
    else
      store.commit('settings/startLoading', true)

    if (config.baseURL === axios.defaults.baseURL) {
      if ( !['/identity/refreshtoken', '/identity/login'].includes(config.url)){
        if (store.getters['auth/accessToken']){
          config.headers.Authorization = 'Bearer ' + store.getters['auth/accessToken'];
        }
      }
    }

    return config;
  },
  error => Promise.reject(error)
);

function checkRolePermissions(to) {
  let arr, obj, path, res, permissionGranted;
  arr = store.getters['auth/userPermissions'];
  path = to.path.toLowerCase().includes("edit") ? to.path.slice(0,(to.path.toLowerCase().indexOf('edit') - 1)) : to.path.toLowerCase().includes("new") ? to.path.slice(0,(to.path.toLowerCase().indexOf('new') - 1)) : to.path.toLowerCase().includes("create") ? to.path.slice(0,(to.path.toLowerCase().indexOf('create') - 1)) : to.path.toLowerCase().includes("view") ? to.path.slice(0,(to.path.toLowerCase().indexOf('view') - 1)) : to.path.toLowerCase().includes("appsstatus") ? to.path.slice(0,(to.path.toLowerCase().indexOf('appsstatus') - 1)) : to.path.toLowerCase().includes("schedule") ? to.path.slice(0,(to.path.toLowerCase().indexOf('schedule') - 1)) : to.path;
  obj = Config.gui_paths_required_api_endpoints[path];
  permissionGranted = false;
  if (typeof obj != 'undefined') {
    res = true;
    for (let index = 0; index < obj.length && res == true; index++) {
      res = typeof obj[index] != 'undefined' ? _.some(arr, v => ((new RegExp(v.path).test(obj[index].path)) && _.isEqual(_.intersection(v.actions, obj[index].actions).sort(), obj[index].actions) ? true : false)) : false;
    }
  } else {
    res = false;
  }
  if (path == '/general_management/About') res = true;
  res ? permissionGranted = true : console.log("Access Control:", "Administrator does not have permissions to access -> " + path);
  return permissionGranted;
}

function displayUnathorizedRouteMessage() {
  store.commit('setErrorAll', i18n.t('unathorized_route'), { root: true });
}


/* eslint-disable no-new */
new Vue({
  router,
  i18n,
  store,
  render: h => h(App)
}).$mount("#app");
