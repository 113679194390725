export default {
    companyName(state) {
        return state.configuration.companyName;
    },
    appName(state) {
        return state.configuration.appName;
    },
    footerLogin(state) {
        return state.configuration.footerLogin;
    },
    web(state) {
        return state.configuration.web;
    },
    footerLoginImage(state) {
        return state.configuration.footerLoginImage;
    }
};