<template>
  <card class="schedule-config-card padding-1">
    <el-row>
      <el-col :span="12">
        <h4 class="policy-card-title">
          <svgicon class="icon" icon="schedule-configuration" width="16" height="16" />
          <span style="padding-left:1em">{{t('Schedule configuration')}}</span>
        </h4>
      </el-col>
      <el-col class="any-text" :span="12">
        <span>{{t("Leave empty for 'Any'")}}</span>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <span class="label-text">{{t('select days of the week (optional)')}}</span>
      </el-col>
    </el-row>
    <el-form-item class="flex-space-around">
        <el-col :span="3" v-for="(value, key) in schedule.weekdays" :key=key>
          <span
            class="weekday-circle"
            :class="value && 'active-day'"
            @click="handleWeekdayPick(key)"
          >
            <!--{{t(key[0]).toUpperCase()}}-->
            {{t(key)}}
          </span>
        </el-col>
      </el-form-item>
      <el-row>
        <el-col>
          <span class="label-text">{{t('select date slot (optional)')}}</span>
        </el-col>
      </el-row>
      <el-form-item class="flex-space-around" >
        <el-col :span="11">
          <el-form-item prop='schedule.startDateSlot'>
          <el-date-picker
            :value="schedule.startDateSlot"
            :clearable="true"
            :editable="false"
            value-format="yyyy[-]MM[-]dd[T]HH:mm:ss[Z]"
            :picker-options="{disabledDate: (date) => disabledDate(date, 'start')}"
            @input="handleSlotsChange($event, 'startDateSlot')"
            type="date"
            :placeholder="t('Pick a day')">
          </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="11">
          <el-form-item prop='schedule.endDateSlot'>
          <el-date-picker
            :value="schedule.endDateSlot"
            :editable="false"
            :clearable="true"
            value-format="yyyy[-]MM[-]dd[T]HH:mm:ss[Z]"
            :picker-options="{disabledDate: (date) => disabledDate(date, 'end')}"
            @input="handleSlotsChange($event, 'endDateSlot')"
            type="date"
            :placeholder="t('Pick a day')">
          </el-date-picker>
          </el-form-item>
        </el-col>
      </el-form-item>
      <el-row>
        <el-col>
          <span class="label-text">{{t('select time slot (optional)')}}</span>
        </el-col>
      </el-row>
      <el-form-item class="flex-space-around" >
        <el-col :span="11">
          <el-form-item prop='schedule.startTimeSlot'>
            <el-time-select
              popperClass="time-select__dropdown"
              @input="handleSlotsChange($event, 'startTimeSlot')"
              :value="schedule.startTimeSlot"
              :clearable="true"
              :editable="false"
              :picker-options="{ start: '00:00', step: '00:30', end: getTimeStartSlotLastOption() }"
              :placeholder="t('Start')">
            </el-time-select>
          </el-form-item>
        </el-col>
        <el-col :span="11">
          <el-form-item prop='schedule.endTimeSlot'>
            <el-time-select
              popperClass="time-select__dropdown"
              @input="handleSlotsChange($event, 'endTimeSlot')"
              :value="schedule.endTimeSlot"
              :clearable="true"
              :editable="false"
              :picker-options="{ start: getTimeEndSlotFirstOption(), step: '00:30', end: '24:00', minTime: schedule.startTimeSlot  }"
              :placeholder="t('End')">
            </el-time-select>
          </el-form-item>
        </el-col>
      </el-form-item>
  </card>
</template>

<script>
  import { DatePicker, TimeSelect, FormItem, Col, Row } from 'element-ui'
  export default {
    name: 'schedule-config-card',
    components: {
      'el-date-picker': DatePicker,
      'el-time-select': TimeSelect,
      'el-form-item': FormItem,
      'el-col': Col,
      'el-row': Row
    },
    props: {
      scheduleProp: {
        type: Object,
        default: () => {}
      }
    },
    computed: {
      schedule: function() {
        return {
          startTimeSlot: '',
          endTimeSlot: '',
          startDateSlot: '',
          endDateSlot: '',
          weekdays: {
            monday: false,
            tuesday: false,
            wednesday: false,
            thursday: false,
            friday: false,
            saturday: false,
            sunday: false
          },
          ...this.scheduleProp
        }
      }
    },
    methods: {
      handleWeekdayPick(weekday) {
        let updatedShedule = JSON.parse(JSON.stringify(this.schedule));
        updatedShedule.weekdays[weekday] = !this.schedule.weekdays[weekday];
        this.$emit('schedule-change', updatedShedule);
      },
      handleSlotsChange(event, key) {
        if (event === null) event = '';
        if (event === '24:00') event = '23:59';
        let updatedShedule = JSON.parse(JSON.stringify(this.schedule));
        updatedShedule[key] = event;
        this.$emit('schedule-change', updatedShedule);
      },
      getTimeStartSlotLastOption() {
        return this.schedule.endTimeSlot === '' || this.schedule.endTimeSlot == undefined
          ? '23:30'
          : this.schedule.endTimeSlot;
      },
      getTimeEndSlotFirstOption() {
        return this.schedule.startTimeSlot === '' || this.schedule.startTimeSlot == undefined
          ? '00:00'
          : this.schedule.startTimeSlot;
      },
      disabledDate(date, input) {
        if (input === 'start') {
          if (this.schedule.endDateSlot === '') return false;
          if (date > new Date(this.schedule.endDateSlot)) return true;
          return false;
        } else {
          if (this.schedule.startDateSlot === '') return false;
          if (date < new Date(this.schedule.startDateSlot)) return true;
          return false;
        }
      }
    }
  }
</script>

<style lang="scss">
  .schedule-config-card {
    .weekday-circle {
      border: 1px solid black;
      border-radius: 50%;
      width: 2.5em;
      height: 2.5em;
      background: #fff;
      color: #606060;
      margin: 0em 0.8em;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      &:first-of-type{
        margin-left: unset;
      }
    }
    .active-day {
      background: #f96332 !important;
      color: white !important;
    }
    @media (max-width: 1700px) {
      .schedule-label-position {
        label {
          width: auto!important;
          float: none;
          display: inline-block;
          text-align: left;
          padding: 0;
          margin: 0;
        }
        div { margin-left: 0!important; }
      }
    }
    .label-text {
      display: flex;
      justify-content: flex-start;
      color: #9A9A9A;
      font-size: 1em;
      padding-bottom: 1em;
    }
    .any-text {
      display: flex;
      justify-content: flex-end;
      color: #9A9A9A;
      font-size: 1em;
    }
  }
  .time-select__dropdown {
    width: auto !important;
  }
</style>
