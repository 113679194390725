export const SLIDER_OPTIONS = {
    1: "1 min",
    5: "5 min",
    10: "10 min",
    30: "30 min",
    60: "1 hour",
    120: "2 hour",
    240: "4 hour",
    360: "6 hour",
    720: "12 hour",
    1440: "1 day",
    2880: "2 days",
    7200: "5 days",
    10080: "7 days",
}