
export const translateHandler = (txt, search, replace) => {
    return txt.split(search).join(replace);
};

/*
date: a Date object
Returns a  AAAAMMDD-HHMMSS string date format
*/
export function getDateFormated(date_object) {
    const date = ("0" + date_object. getDate()).slice(-2);
    const month = ("0" + (date_object. getMonth() + 1)).slice(-2);
    const year = date_object.getFullYear();
    const hours = date_object.getHours();
    const minutes = date_object.getMinutes();
    const seconds = date_object.getSeconds();
    return(`${year}${month}${date}_${hours}${minutes}${seconds}`)
};

export const filterPayloadByRequiredProps = (payload, requiredProps) => {
  const filteredPayload = Object.keys(payload)
    .filter(propName => requiredProps.includes(propName))
    .reduce((obj, propName) => {
      obj[propName] = payload[propName];
      return obj;
    }, {});
  return filteredPayload;
};

export default function isIspAccount(account_id) {
  return account_id == 'ISPADMINS';
};
