<template>
  <div :class="{ 'hover-zoom-effect' : zoomOnHover, 'hover-shine-effect': shineOnHover}" @click="$emit('click', $event)">
    <div class="avatar-img" :class="{ 'effect' : zoomOnHover || shineOnHover}" :style="style" >
      <table >
        <tbody>
          <tr>
            <td v-if="!hasImage" style="color: white !important;">{{initials}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'avatar',
    props: {
      fullname: { type: String, default: '##' },
      usewords: { type: Boolean, default: false},
      size: { type: Number, default: 124 },
      radius: { type: Number, default: 50, validator: (value) => value >= 0 && value <= 50 },
      color: { type: String, default: '' },
      image: { type: String, default: '' },
      zoomOnHover: { type: Boolean, default: false},
      shineOnHover: { type: Boolean, default: false}
    },
    computed: {
      // compute initials from fullname
      initials () {
        if (!this.fullname)
          return ''

        var words = null;
        if ( this.usewords)
          words = this.fullname.split(/[\s-]+/)
        else
          words = this.fullname
        var intls = ''
        for (var i = 0; i < words.length; i++) {
          intls += words[i].substr(0, 1).toUpperCase()
        }
        if (intls.length > 3) {
          intls = intls.substr(0, 3)
        }
        return intls
      },
      // compute style from props
      style () {
        var fontSize = this.initials.length > 2 ? this.size / 3 - 3 : this.size / 2 - 2
        return {
          'width': this.size + 'px',
          'height': this.size + 'px',
          'border-radius': this.radius + '%',
          'font-size': fontSize + 'px',
          'background-color': this.color === '' ? this.toColor(this.fullname) : this.color,
          'background-image': this.hasImage ? 'url(' + this.image + ')' : 'none'
        }
      },
      hasImage () {
        return (!!this.image && this.image !== '')
      }
    },
    methods: {
      toColor (str) {
        if(!str)
          return 'black'
          
        var hash = 0
        var len = str.length
        if (len === 0) return 'black'
        for (var i = 0; i < len; i++) {
          hash = ((hash << 8) - hash) + str.charCodeAt(i)
          hash |= 0
        }
        hash = Math.abs(hash)
        return '#' + hash.toString(16).substr(0, 6)
      }
    }
  }
</script>

<style lang="scss" scoped>
.avatar-img {
    display: inline-block;
    background-color: black;
    color: white ! important;
    width: 124px;
    height: 124px;
    font-size: 1em;
    border-radius: 50%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-image: none;
}
.avatar-img table {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
}
.avatar-img table td{
    text-align: center;
    vertical-align: middle;
}
.avatar-img img {
    width: 100%;
    overflow: hidden;
}
</style>
