/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'remove-button': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path pid="0" d="M26.182 0A5.818 5.818 0 0132 5.818v20.364A5.818 5.818 0 0126.182 32H5.818A5.818 5.818 0 010 26.182V5.818A5.818 5.818 0 015.818 0h20.364zm0 2.91H5.818A2.91 2.91 0 002.91 5.817v20.364a2.91 2.91 0 002.91 2.91h20.363a2.91 2.91 0 002.909-2.91V5.818a2.91 2.91 0 00-2.91-2.909zm-4.364 11.636a1.454 1.454 0 110 2.909H10.182a1.455 1.455 0 010-2.91z" _fill="#000" fill-rule="evenodd"/>'
  }
})
