/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'connection': {
    width: 16,
    height: 16,
    viewBox: '0 0 512.001 512.001',
    data: '<circle pid="0" cx="255.001" cy="433.5" r="33"/><path pid="1" d="M506.632 154.864C440.902 84.341 351.895 45.501 255.997 45.5 160.107 45.501 71.1 84.341 5.369 154.864c-7.53 8.08-7.085 20.736.995 28.267 8.081 7.53 20.736 7.086 28.267-.995C92.711 119.82 171.327 85.501 256.004 85.5c84.67.001 163.286 34.32 221.366 96.636a19.947 19.947 0 0014.635 6.364c4.883 0 9.778-1.778 13.632-5.369 8.08-7.532 8.526-20.187.995-28.267z"/><path pid="2" d="M433.194 237.458c-48.587-48.954-111.516-75.913-177.196-75.911-65.695.001-128.625 26.961-177.197 75.913-7.78 7.841-7.731 20.504.11 28.284 7.841 7.78 20.504 7.731 28.284-.11 41.005-41.326 93.851-64.086 148.803-64.087 54.938-.002 107.784 22.759 148.804 64.089a19.94 19.94 0 0014.196 5.911 19.947 19.947 0 0014.09-5.805c7.839-7.781 7.887-20.444.106-28.284zM353.151 320.292c-26.02-25.779-63.317-41.792-97.339-41.792H254.341c-34.023 0-71.323 16.014-97.342 41.792-7.846 7.774-7.905 20.437-.131 28.284a19.94 19.94 0 0014.208 5.924 19.936 19.936 0 0014.075-5.792c18.523-18.352 45.68-30.208 69.192-30.208h1.466c23.509 0 50.668 11.857 69.189 30.208 7.846 7.773 20.511 7.717 28.284-.132 7.775-7.846 7.716-20.51-.131-28.284z"/>'
  }
})
