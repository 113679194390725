import DashboardLayout from "@/layout/dashboard/DashboardLayout";
import ServiceConfigurations from "@/pages/IspServices/Services/ServiceConfigurations";
import { store } from "@/store";

const ProfileEdit = () => import("@/pages/IspServices/Configuration/ProfileEdit");

export default {
  path: '/isp_services',
  component: DashboardLayout,
  name: 'ISP Services / Configuration',
  children: [
    {
      path: 'configuration/profiles',
      name: 'ISP Services / Configuration / Profiles',
      component: () => import("@/pages/IspServices/Configuration/Profiles")
    },
    {
      path: 'configuration/profiles/edit/:id?',
      name: 'ISP Services / Configuration / Profiles / Edit',
      component: ProfileEdit,
      beforeEnter: async (to, from, next) => {
        const profileId = to.params.id;
        if (profileId) {
          await store.dispatch('ispServices/profiles/getProfile', profileId);
        }
        next();
      },
    },
    {
      path: 'configuration/profiles/new',
      name: 'ISP Services / Configuration / Profiles / New',
      component: ProfileEdit,
    },
    {
      path: 'configuration/category-management*',
      name: 'ISP Services / Configuration / Category Management',
      component: () => import("@/pages/IspServices/Configuration/CategoryManagement")
    },
    {
      path: 'configuration/notifications*',
      name: 'ISP Services / Configuration / Notifications',
      component: () => import('@/pages/GeneralManagement/Notifications')
    },
    {
      path: 'services/:serviceName',
			name: (serviceName) => `ISP Services / Services / ${serviceName} / Service Configuration`,
      component: ServiceConfigurations,
      props: true
    },
    {
      path: '/isp_services/services/forbidden_allowed_lists/:serviceName',
			name: (serviceName) => `ISP Services / Services / ${serviceName}  / Forbidden & Allowed lists`,
      component: () => import('@/pages/IspServices/Services/ForbiddenAllowedLists')
    }
  ]
}
