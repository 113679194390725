/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'view-button': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path pid="0" d="M16.036 6a17.543 17.543 0 0111.628 4.709c1.086.96 2.08 2.016 2.972 3.158.33.43.589.79.775 1.048l.201.302v.086l.388.79-.46.746v.086l-.2.302c-.187.258-.445.617-.776 1.048a23.573 23.573 0 01-2.971 3.158 17.543 17.543 0 01-11.557 4.666A17.543 17.543 0 014.55 21.433a23.573 23.573 0 01-3.115-3.173c-.33-.43-.589-.79-.776-1.048l-.2-.301v-.086L0 16.049l.603-.79v-.085l.2-.302c.188-.258.446-.617.776-1.048a23.573 23.573 0 012.972-3.158A17.543 17.543 0 0116.036 6zm0 2.871a14.643 14.643 0 00-9.662 3.948 21.865 21.865 0 00-2.613 2.771l-.344.46.344.459a21.865 21.865 0 002.613 2.77 14.643 14.643 0 009.662 3.948 14.643 14.643 0 009.662-3.948c.95-.841 1.82-1.769 2.598-2.77.144-.173.46-.316.359-.46a6.941 6.941 0 00-.359-.459 20.616 20.616 0 00-2.598-2.77 14.643 14.643 0 00-9.662-3.949zm0 2.871a4.307 4.307 0 110 8.614 4.307 4.307 0 010-8.614z" _fill="#0E1317" fill-rule="nonzero"/>'
  }
})
