export default () => { return [
    {
        autonotice_list_id: 'banner1', 
        mode: 'random', 
        items: [
            {
                position:'top', 
                width: 23, 
                unit:'pixels', 
                url:'http://www.banner1.es'
            }, 
            {
                position:'right', 
                width: 50,
                unit:'percentage', 
                url:'http://www.banner3.es'
            }
        ]
    },
    {
        autonotice_list_id: 'banner2', 
        mode: 'sequential', 
        items: [
            {
                position:'left', 
                width: 240,
                unit: 'percentage', 
                url:'http://www.banner2.es'
            },
            {
                position:'bottom', 
                width:66,
                unit: 'pixels',
                url:'http://www.banner4.es'
            }
        ]
    },
    {
        autonotice_list_id: 'banner3-test-tesbed-78TY5RW', 
        mode: 'random_no_repeat', 
        items: [
            {
                position: 'left', 
                width: 240,
                unit: 'percentage', 
                url:'http://www.banner2.es'
            },
            {
                position:'bottom', 
                width:66,
                unit: 'pixels',
                url:'http://www.banner4.es'
            },
            {
                position:'top', 
                width: 23, 
                unit:'pixels', 
                url:'http://www.banner1.es'
            }, 
            {
                position:'right', 
                width: 50,
                unit:'percentage', 
                url:'http://www.banner3.es'
            }
        ]
    }
    ]
};
