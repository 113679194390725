const deepEqual = require('deep-equal');

/**
 * Filter Strategy Object by ns_service
 * @param {Object} strategy attribute strategy of an account
 * @param {Object} service service to be filtered by 
 */
export const filterStrategyByService = (strategy, service) => {
  let tempStrategy = _.cloneDeep(strategy);
  for (const [key, value] of Object.entries(tempStrategy.rules)) {
    if (!value.ns_services.includes(service)) {
      value.hasOwnProperty('origin')
        && value.origin !== 'all' && delete tempStrategy.source_and_destinations[value.origin];
      value.hasOwnProperty('target')
        && value.target !== 'all' && delete tempStrategy.source_and_destinations[value.target];
      value.hasOwnProperty('profile')
        && value.profile !== 'all' && delete tempStrategy.profiles[value.profile];
      value.hasOwnProperty('when')
        && value.when !== 'always' && delete tempStrategy.schedules[value.when];
      delete tempStrategy.rules[key];
    }
  }
  return tempStrategy;
}

/**
 * Function to generate the source and destination from a rule and a source and destinations object
 * @param {Object} rule object with the description of the rule
 * @param {Object} src_and_dest object with the source and destination of the rule if necessary
 * @param {String} type string to determinate if we are generating source or destination elements
 * type param should be "origin" or "target"
 */
export const getSrcOrDest = (rule, src_and_dest, type) => {
  if (rule[type] === "internal") return ["internal"];
  if (rule[type] === "external") return ["external"];
  if (rule[type] === "all") return [];
  let src_dst = [];
  for (const reg in src_and_dest[rule[type]]) {
    if (src_and_dest[rule[type]].hasOwnProperty(reg)) {
      const element = src_and_dest[rule[type]][reg];
      for (const item of element.items) {
        // Object with the type of the element(ip, device or group) and its value
        src_dst.push({ type: reg, value: item });
      }
    }
  }
  return src_dst;
};

/**
 * Function to generate the rule schedule from the strategy schedules
 * @param {Object} rule object with the description of the rule
 * @param {Object} schedules object with the schedules of the strategy account
 */
export const getSchedule = (rule, schedules) => {
  if (rule.when === "always") return "always";
  let schedule = {};
  const date = schedules[rule.when][0].date;
  const hour = schedules[rule.when][0].hour;
  schedule.startDateSlot = date !== undefined ? date[0].begin : "";
  schedule.endDateSlot = date !== undefined ? date[0].end : "";
  schedule.startTimeSlot = hour !== undefined ? hour[0].begin : "";
  schedule.endTimeSlot = hour !== undefined ? hour[0].end : "";
  if (schedules[rule.when][0].day) {
    schedule.weekdays = {
      monday: schedules[rule.when][0].day.includes("monday"),
      tuesday: schedules[rule.when][0].day.includes("tuesday"),
      wednesday: schedules[rule.when][0].day.includes("wednesday"),
      thursday: schedules[rule.when][0].day.includes("thursday"),
      friday: schedules[rule.when][0].day.includes("friday"),
      saturday: schedules[rule.when][0].day.includes("saturday"),
      sunday: schedules[rule.when][0].day.includes("sunday")
    };
  }
  return schedule;
};

/**
 * Function to generate the allowed_forbidden from the rule
 * @param {Object} rule object with the description of the rule
 * @param {Object} profiles object with the profiles of the strategy account
 */
export const getAllowedForbidden = (rule, profiles) => {
  if (rule.profile === "all") return 'none';
  const allowed_list = 'allowed_list';
  const forbidden_list= 'forbidden_list';

  let advanced = 'none';
  if (profiles[rule.profile].hasOwnProperty(allowed_list))
    advanced = allowed_list
  else if (profiles[rule.profile].hasOwnProperty(forbidden_list))
    advanced = forbidden_list
  return advanced;
};

/**
 * Function to generate origin, target and source and destination strategy values if necessary
 * @param {Object} rule object with the description of the rule
 * @param {string} type string to determinate if we are generating source or destination,
 * should be "source" or "destination"
 * @returns an object with the key origin/target and its value (and an entry with the source and
 * destinations value if necessary)
 */
export const setSrcOrDest = (rule, type) => {
  const targetKey = type === "source" ? "origin" : "target";
  if (rule[type][0] === "internal") return { [targetKey]: "internal" };
  if (rule[type][0] === "external") return { [targetKey]: "external" };
  if (rule[type].length === 0) return { [targetKey]: "all" };
  let result = {};
  result.value = {};
  // targetKey is the reference to the source/destination we will create in source_and_destinations object
  result[targetKey] = `${rule.rule_name}_${type}`;
  for (const item of rule[type]) {
    result.value.hasOwnProperty(item.type)
      ? result.value[item.type].items.push(item.value)
      : (result.value[item.type] = { items: [item.value] });
  }
  return result;
};

/**
 * Function to generate the schedule of the strategy rule
 * @param {Object} rule object with the description of the rule
 * @returns and object with the content of the rule schedule (and the schedules entry if necessary)
 */
export const setSchedule = rule => {
  let alwaysSchedule = {
    weekdays: {
      monday: false,
      tuesday: false,
      wednesday: false,
      thursday: false,
      friday: false,
      saturday: false,
      sunday: false
    },
    startTimeSlot: "",
    endTimeSlot: "",
    startDateSlot: "",
    endDateSlot: ""
  };
  if (deepEqual(alwaysSchedule, rule.schedule) || rule.schedule === 'always')
    return { scheduleName: "always" };
  let schedule = [{}];
  for (const dayName in rule.schedule.weekdays) {
    if (rule.schedule.weekdays.hasOwnProperty(dayName)) {
      const day = rule.schedule.weekdays[dayName];
      if (day) {
        if (!schedule[0].day) schedule[0].day = [];
        schedule[0].day.push(dayName.toLocaleLowerCase());
      }
    }
  }
  // If date or hour are empty string then we omit them
  if (rule.schedule.startDateSlot !== "" && rule.schedule.endDateSlot !== "")
    schedule[0].date = [{ begin: rule.schedule.startDateSlot, end: rule.schedule.endDateSlot }];
  if (rule.schedule.startTimeSlot !== "" && rule.schedule.endTimeSlot !== "")
    schedule[0].hour = [{ begin: rule.schedule.startTimeSlot, end: rule.schedule.endTimeSlot }];

  return { scheduleName: `${rule.rule_name}_schedule`, schedule };
};

/**
 * Function to generate the rule actions
 * @param {Object} rule object with the information of the rule
 * @returns an array with the actions of the rule
 */
export const setActions = rule => {
  let actions = [];
  const specialOptions = ['timeout', 'blockWithThisURL', 'block_with_url']
  if (specialOptions.includes(rule.action.type)) {
    rule.action.type === 'timeout' && actions.push({ op: 'set_timeout', duration: rule.action.value });
    ['blockWithThisURL', 'block_with_url'].includes(rule.action.type) && actions.push({ op: 'block_with_url', url: rule.action.value });
  } else {
    actions = [{ op: rule.action.type }];
  }
  rule.log_activity && actions.push({ op: "log" });
  return actions;
};

/**
 * Function to generate the rule allowed_forbidden profiles
 * @param {Object} rule object with the information of the rule
 * @returns an object with the allowed_forbidden profiles of the rule
 */
export const setAllowedForbidden = rule => {
  if (!!!rule.allowed_forbidden || rule.allowed_forbidden === 'none') return {}

  let allowed_forbidden = {
    [rule.allowed_forbidden]: { necessary: true, inverse: false }
  };

  return allowed_forbidden;
};

/**
 * Function to generate the partial strategy from the edited rule in order to send a PATCH
 * @param {Object} rule updated rule
 * @param {Object} strategy object with the strategy before update
 */
export const getPartialStrategyFromRule = (rule, strategy) => {
  let partialStrategy = {};
  if (strategy.schedules && strategy.schedules[`${rule.rule_name}_schedule`])
    partialStrategy.schedules = {[`${rule.rule_name}_schedule`]: strategy.schedules[`${rule.rule_name}_schedule`]};

  if (strategy.profiles && strategy.profiles[`${rule.rule_name}_profile`])
    partialStrategy.profiles = {[`${rule.rule_name}_profile`]: strategy.profiles[`${rule.rule_name}_profile`]};

  if (strategy.services && strategy.services[`${rule.rule_name}_service`])
    partialStrategy.services = {[`${rule.rule_name}_service`]: strategy.services[`${rule.rule_name}_service`]};

  if (strategy.source_and_destinations) {
    partialStrategy.source_and_destinations = {};
    if (strategy.source_and_destinations[`${rule.rule_name}_source`]) {
      partialStrategy.source_and_destinations[`${rule.rule_name}_source`] = strategy.source_and_destinations[`${rule.rule_name}_source`];
    }
    if (strategy.source_and_destinations[`${rule.rule_name}_destination`]) {
      partialStrategy.source_and_destinations[`${rule.rule_name}_destination`] = strategy.source_and_destinations[`${rule.rule_name}_destination`];
    }
  }

  return partialStrategy;
}

/**
 * Function to set those strategy elements that have been removed to null,
 * in order to inform backend that they have been deleted
 * @param {Object} oldStrategy object with the account's strategy after edition
 * @param {Object} newStrategy object with the new account's strategy
 */
export const setDeletedElementsToNull = (oldStrategy, newStrategy) => {
  const oldSrcAndDst = Object.keys(oldStrategy.source_and_destinations || {});
  const newSrcAndDst = Object.keys(newStrategy.source_and_destinations || {});
  const srcAndDstToDelete = oldSrcAndDst.filter(x => !newSrcAndDst.includes(x));
  for (const item of srcAndDstToDelete) {
    if (newStrategy.source_and_destinations === undefined) newStrategy.source_and_destinations = {};
    newStrategy.source_and_destinations[item] = null;
  }

  // Remove those labels that were removed by adding them to the patch
  oldSrcAndDst.map(resource => {
    Object.keys(oldStrategy.source_and_destinations[resource]).forEach(element => {
      if (!newStrategy.source_and_destinations[resource] || !newStrategy.source_and_destinations[resource][element]) {
       newStrategy.source_and_destinations[resource] ? newStrategy.source_and_destinations[resource][element] = null  :  newStrategy.source_and_destinations[resource] = null
      }
    })
  })

  const oldProfiles = Object.keys(oldStrategy.profiles || {});
  const newProfiles = Object.keys(newStrategy.profiles || {});
  const profilesToDelete = oldProfiles.filter(x => !newProfiles.includes(x));
  for (const item of profilesToDelete) {
    if (newStrategy.profiles === undefined) newStrategy.profiles = {};
    newStrategy.profiles[item] = null;
  }
  oldProfiles.map(source => {
    Object.keys(oldStrategy.profiles[source]).map(element => {
      if (!newStrategy.profiles[source]) {
        newStrategy.profiles[source] = null
      } else {
        // Remove elements
        if (!newStrategy.profiles[source][element]) {
          newStrategy.profiles[source][element] = null
        }
      }
    })
  })

  const oldServices = Object.keys(oldStrategy.services || {});
  const newServices = Object.keys(newStrategy.services || {});
  const servicesToDelete = oldServices.filter(x => !newServices.includes(x));
  for (const item of servicesToDelete) {
    if (newStrategy.services === undefined) newStrategy.services = {};
    newStrategy.services[item] = null;
  }

  const oldSchedules = Object.keys(oldStrategy.schedules || {});
  const newSchedules = Object.keys(newStrategy.schedules || {});
  const schedulesToDelete = oldSchedules.filter(x => !newSchedules.includes(x));
  for (const item of schedulesToDelete) {
    if (newStrategy.schedules === undefined) newStrategy.schedules = {};
    newStrategy.schedules[item] = null;
  }

  return newStrategy;
}