/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'item-languages': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10 10-4.5 10-10S17.5 2 12 2zM4 12c0-.6.1-1.2.2-1.8L9 15v1c0 1.1.9 2 2 2v1.9c-3.9-.5-7-3.8-7-7.9zm13.9 5.4c-.3-.8-1-1.4-1.9-1.4h-1v-3c0-.6-.4-1-1-1H8v-2h2c.6 0 1-.4 1-1V7h2c1.1 0 2-.9 2-2v-.4c2.9 1.2 5 4.1 5 7.4 0 2.1-.8 4-2.1 5.4z" _fill="#383132"/>'
  }
})
