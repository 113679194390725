export const SUPPORTED_LANGUAGES = [
  {
    "language_id": "sq-AL",
    "language_name": "Albanian"
  },
  {
    "language_id": "am-ET",
    "language_name": "Amharic"
  },
  {
    "language_id": "ar-OM",
    "language_name": "Arabic"
  },
  {
    "language_id": "hy-AM",
    "language_name": "Armenian"
  },
  {
    "language_id": "az-AZ",
    "language_name": "Azerbaijani"
  },
  {
    "language_id": "be-BY",
    "language_name": "Belarusian"
  },
  {
    "language_id": "bs-BA",
    "language_name": "Bosnian"
  },
  {
    "language_id": "bg-BG",
    "language_name": "Bulgarian"
  },
  {
    "language_id": "my-MM",
    "language_name": "Burmese"
  },
  {
    "language_id": "yue-CN",
    "language_name": "Cantonese"
  },
  {
    "language_id": "ca-AD",
    "language_name": "Catalan"
  },
  {
    "language_id": "km-KH",
    "language_name": "Central Khmer"
  },
  {
    "language_id": "zh-TW",
    "language_name": "Chinese"
  },
  {
    "language_id": "zh-CN",
    "language_name": "Chinese"
  },
  {
    "language_id": "hr-HR",
    "language_name": "Croatian"
  },
  {
    "language_id": "cs-CZ",
    "language_name": "Czech"
  },
  {
    "language_id": "da-DK",
    "language_name": "Danish"
  },
  {
    "language_id": "dv-MV",
    "language_name": "Divehi"
  },
  {
    "language_id": "nl-NL",
    "language_name": "Dutch"
  },
  {
    "language_id": "dz-BT",
    "language_name": "Dzongkha"
  },
  {
    "language_id": "en-US",
    "language_name": "English"
  },
  {
    "language_id": "et-EE",
    "language_name": "Estonian"
  },
  {
    "language_id": "fi-FI",
    "language_name": "Finnish"
  },
  {
    "language_id": "fr-FR",
    "language_name": "French"
  },
  {
    "language_id": "ka-GE",
    "language_name": "Georgian"
  },
  {
    "language_id": "de-DE",
    "language_name": "German"
  },
  {
    "language_id": "el-GR",
    "language_name": "Greek"
  },
  {
    "language_id": "he-IL",
    "language_name": "Hebrew"
  },
  {
    "language_id": "hi-IN",
    "language_name": "Hindi"
  },
  {
    "language_id": "hu-HU",
    "language_name": "Hungarian"
  },
  {
    "language_id": "is-IS",
    "language_name": "Icelandic"
  },
  {
    "language_id": "id-ID",
    "language_name": "Indonesian"
  },
  {
    "language_id": "he-IL",
    "language_name": "Hebrew"
  },
  {
    "language_id": "it-IT",
    "language_name": "Italian"
  },
  {
    "language_id": "ja-JP",
    "language_name": "Japanese"
  },
  {
    "language_id": "kl-GL",
    "language_name": "Kalaallisut"
  },
  {
    "language_id": "kk-KZ",
    "language_name": "Kazakh"
  },
  {
    "language_id": "rw-RW",
    "language_name": "Kinyarwanda"
  },
  {
    "language_id": "ky-KG",
    "language_name": "Kirghiz"
  },
  {
    "language_id": "ko-KR",
    "language_name": "Korean"
  },
  {
    "language_id": "lo-LA",
    "language_name": "Lao"
  },
  {
    "language_id": "lv-LV",
    "language_name": "Latvian"
  },
  {
    "language_id": "lt-LT",
    "language_name": "Lithuanian"
  },
  {
    "language_id": "lb-LU",
    "language_name": "Luxembourgish"
  },
  {
    "language_id": "mk-MK",
    "language_name": "Macedonian"
  },
  {
    "language_id": "mg-MG",
    "language_name": "Malagasy"
  },
  {
    "language_id": "ms-BN",
    "language_name": "Malay"
  },
  {
    "language_id": "mt-MT",
    "language_name": "Maltese"
  },
  {
    "language_id": "mi-NZ",
    "language_name": "Maori"
  },
  {
    "language_id": "mn-MN",
    "language_name": "Mongolian"
  },
  {
    "language_id": "na-NR",
    "language_name": "Nauru"
  },
  {
    "language_id": "ne-NP",
    "language_name": "Nepali"
  },
  {
    "language_id": "nb-NO",
    "language_name": "Norwegian"
  },
  {
    "language_id": "fa-AF",
    "language_name": "Persian"
  },
  {
    "language_id": "pl-PL",
    "language_name": "Polish"
  },
  {
    "language_id": "pt-PT",
    "language_name": "Portuguese"
  },
  {
    "language_id": "ro-RO",
    "language_name": "Romanian"
  },
  {
    "language_id": "ru-RU",
    "language_name": "Russian"
  },
  {
    "language_id": "sm-WS",
    "language_name": "Samoan"
  },
  {
    "language_id": "sr-RS",
    "language_name": "Serbian"
  },
  {
    "language_id": "si-LK",
    "language_name": "Sinhala"
  },
  {
    "language_id": "sk-SK",
    "language_name": "Slovak"
  },
  {
    "language_id": "sl-SI",
    "language_name": "Slovenian"
  },
  {
    "language_id": "so-SO",
    "language_name": "Somali"
  },
  {
    "language_id": "es-ES",
    "language_name": "Spanish"
  },
  {
    "language_id": "sw-TZ",
    "language_name": "Swahili"
  },
  {
    "language_id": "sv-SE",
    "language_name": "Swedish"
  },
  {
    "language_id": "tg-TJ",
    "language_name": "Tajik"
  },
  {
    "language_id": "th-TH",
    "language_name": "Thai"
  },
  {
    "language_id": "ti-ER",
    "language_name": "Tigrinya"
  },
  {
    "language_id": "tr-TR",
    "language_name": "Turkish"
  },
  {
    "language_id": "tk-TM",
    "language_name": "Turkmen"
  },
  {
    "language_id": "uk-UA",
    "language_name": "Ukrainian"
  },
  {
    "language_id": "uz-UZ",
    "language_name": "Uzbek"
  },
  {
    "language_id": "vi-VN",
    "language_name": "Vietnamese"
  }
];

export const PREVIOUSLY_SUPPORTED_LANGUAGES = [
  {
    "language_id": "en-AU",
    "language_name": "English"
  }
]

export const DEFAULT_BLOCKING_PAGE_TEXTS = {
  "en-US": {
    default: {
      title: "Blocked!",
      description: "Your request has been blocked to ensure your protection."
    },
    content_filter: {
      title: "Blocked!",
      description: "This website is not allowed by the filtering policies assigned to you.",
      show_blocking_reason: true
    },
    threat_protection: {
      title: "Blocked!",
      description: "This website is not allowed because it is deemed to be malicious.",
      show_blocking_reason: true
    },
    password_override: {
      title: "Blocked!",
      description: "Your request has been blocked to ensure your protection. Enter your password to unblock it.",
      show_blocking_reason: true
    }
  },
  "fr-FR": {
    default: {
      title: "Bloqué!",
      description: "Votre demande a été bloquée pour assurer votre protection."
    },
    content_filter: {
      title: "Bloqué!",
      description: "Ce site Web n'est pas autorisé par les politiques de filtrage qui vous sont attribuées.",
      show_blocking_reason: true
    },
    threat_protection: {
      title: "Bloqué!",
      description: "Ce site n'est pas autorisé car il est considéré comme malveillant.",
      show_blocking_reason: true
    },
    password_override: {
      title: "Bloqué!",
      description: "Votre demande a été bloquée pour assurer votre protection. Entrez votre mot de passe pour le débloquer.",
      show_blocking_reason: true
    }
  },
  "de-DE": {
    default: {
      title: "Gesperrt!",
      description: "Ihre Anfrage wurde zu Ihrem Schutz blockiert."
    },
    content_filter: {
      title: "Gesperrt!",
      description: "Diese Website ist gemäß den Ihnen zugewiesenen Filtereinstellungen nicht zulässig.",
      show_blocking_reason: true
    },
    threat_protection: {
      title: "Gesperrt!",
      description: "Diese Website ist nicht erlaubt, da sie als gefährlich eingestuft wurde.",
      show_blocking_reason: true
    },
    password_override: {
      title: "Gesperrt!",
      description: "Ihre Anfrage wurde zu Ihrem Schutz gesperrt. Geben Sie Ihr Passwort ein, um es zu entsperren.",
      show_blocking_reason: true
    }
  },
  "es-ES": {
    default: {
      title: "Bloqueado!",
      description: "Su petición ha sido bloqueada para garantizar su protección."
    },
    content_filter: {
      title: "Bloqueado!",
      description: "Este sitio está bloqueado según las políticas de filtrado que tienes asignadas.",
      show_blocking_reason: true
    },
    threat_protection: {
      title: "Bloqueado!",
      description: "Este sitio está bloqueado por ser considerado como malicioso.",
      show_blocking_reason: true
    },
    password_override: {
      title: "Bloqueado!",
      description: "Su petición ha sido bloqueada para garantizar su protección. Introduzca su contraseña para desbloquear.",
      show_blocking_reason: true
    }
  },
  "ar-OM": {
    default: {
      title: "محظور!",
      description: "تم حظر طلبك لضمان حمايتك."
    },
    content_filter: {
      title: "محظور!",
      description: "هذا الموقع غير مسموح به من خلال سياسات التصفية المخصصة لك.",
      show_blocking_reason: true
    },
    threat_protection: {
      title: "محظور!",
      description: "هذا الموقع غير مسموح به لأنه يعتبر ضارًا.",
      show_blocking_reason: true
    },
    password_override: {
      title: "محظور!",
      description: "تم حظر طلبك لضمان حمايتك. أدخل كلمة المرور الخاصة بك لإلغاء حظره.",
      show_blocking_reason: true
    }
  },
  "ru-RU": {
    default: {
      title: "Заблокировано!",
      description: "Ваш запрос был заблокирован для обеспечения вашей защиты."
    },
    content_filter: {
      title: "Заблокировано!",
      description: "Данный сайт заблокирован назначенными Вами политиками фильтрации.",
      show_blocking_reason: true
    },
    threat_protection: {
      title: "Заблокировано!",
      description: "Данный сайт заблокирован, поскольку считается вредоносным.",
      show_blocking_reason: true
    },
    password_override: {
      title: "Заблокировано!",
      description: "Ваш запрос был заблокирован в целях обеспечения вашей безопасности. Введите пароль для разблокировки.",
      show_blocking_reason: true
    }
  },
  "uk-UA": {
    default: {
      title: "Заблоковано!",
      description: "Ваш запит заблоковано, щоб забезпечити ваш захист."
    },
    content_filter: {
      title: "Заблоковано!",
      description: "Даний сайт заблокований призначеними Вами політиками фільтрації.",
      show_blocking_reason: true
    },
    threat_protection: {
      title: "Заблоковано!",
      description: "Даний сайт заблокований, оскільки вважається шкідливим.",
      show_blocking_reason: true
    },
    password_override: {
      title: "Заблоковано!",
      description: "Ваш запит заблоковано, щоб забезпечити ваш захист. Введіть пароль для розблокування.",
      show_blocking_reason: true
    }
  },
  "pt-PT": {
    default: {
      title: "Bloqueado!",
      description: "Sua solicitação foi bloqueada para garantir sua proteção."
    },
    content_filter: {
      title: "Bloqueado!",
      description: "Este website não é permitido pelas políticas de filtragem que lhe são atribuídas.",
      show_blocking_reason: true
    },
    threat_protection: {
      title: "Bloqueado!",
      description: "Este website não é permitido porque é considerado malicioso.",
      show_blocking_reason: true
    },
    password_override: {
      title: "Bloqueado!",
      description: "Sua solicitação foi bloqueada para garantir sua proteção. Digite sua senha para desbloqueá-la.",
      show_blocking_reason: true
    }
  },
  "he-IL": {
    default: {
      title: "חסום!",
      description: "בקשתך נחסמה כדי להבטיח את הגנתך."
    },
    content_filter: {
      title: "חסום!",
      description: "אתר זה חסום על פי מדיניות הסינון שהוקצתה לך.",
      show_blocking_reason: true
    },
    threat_protection: {
      title: "חסום!",
      description: "אתר זה חסום מכיוון שהוא נחשב זדוני.",
      show_blocking_reason: true
    },
    password_override: {
      title: "חסום!",
      description: "בקשתך נחסמה כדי להבטיח את הגנתך. הזן סיסמה כדי לבטל את החסימה.",
      show_blocking_reason: true
    }
  }
};

export const getLanguageCountryCodeList = () => {
  return SUPPORTED_LANGUAGES.sort((a,b) => a.language_name.toLowerCase() > b.language_name.toLowerCase() ? 1 : -1);
}

export const getPreviouslySupportedLanguages = () => {
  return PREVIOUSLY_SUPPORTED_LANGUAGES;
}
