import axios from 'axios';
import { IDENTITY, SESSION, CONFIGURATION } from '../endpoints';

export async function getConfiguration(type) {
  const params = { type };
  const { data } = await axios
    .get(`/${IDENTITY}/${SESSION}/${CONFIGURATION}`, { params });

  return data;
}

export async function setConfiguration(type, sessionsManagement) {
  const params = { type };

  const { data } = await axios.patch(
    `/${IDENTITY}/${SESSION}/${CONFIGURATION}`,
    sessionsManagement,
    { params },
  );

  return data;
}
