export default {
    updating(state, value) {
        state.updating = value;
    },

    // * --------------------- isp check url  ---------------------

    inspectedUrl(state, value) {
        state.inspected_url = value;
    },
    inspectedInternalCategories(state, value) {
        state.inspected_internal_categories = value;
    },
    inspectedGlobalCategories(state, value) {
        state.inspected_global_categories = value;
    },

    // * --------------------- isp categories  ---------------------

    updateIspCategory(state, value) {
        value.isp_category_i18n = value.isp_category_i18n.map(lang => {
            const { language_id, isp_category_name } = lang;
            return { language_id, isp_category_name }
        })

        state.isp_categories = state.isp_categories.map(category =>
            category.isp_category_id == value.isp_category_id ? value : category);
    },
    setIspCategories(state, value) {
        state.isp_categories = value;
    },
    setStatus(state, id) {
        const category = state.isp_categories.find(category => category.isp_category_id == id);
        category.enabled = !category.enabled;
    },
    deleteIspCategory(state, id) {
        state.isp_categories = state.isp_categories.filter(category => category.isp_category_id != id);
    },
    createIspCategory(state, value) {
        state.isp_categories.unshift(value);
    },

    // * --------------------- isp internal categories ---------------------

    removeInspectedInternalCategories(state, { removedCategories }) {
        let inspectedInternalCategories = state.inspected_internal_categories
        state.inspected_internal_categories = inspectedInternalCategories.filter(cat => !removedCategories.includes(cat.value));
    },
    removeInspectedGlobalCategories(state, { service, type }) {
        let inspectedGlobalCategories = state.inspected_global_categories
        state.inspected_global_categories = inspectedGlobalCategories.filter(cat => (cat.value != service || cat.type != type));
    },
    updateInternalCategory(state, value) {
        const { isp_internal_category_id, category_lists } = value;
        state.categories.find(category => category.isp_internal_category_id == isp_internal_category_id).category_lists = category_lists;
    },
    setCategories(state, value) {
        state.categories = value;
    },
    deleteInternalCategory(state, id) {
        state.categories = state.categories.filter(category => category.isp_internal_category_id != id);
    },
    createCategory(state, value) {
        state.categories.unshift(value);
    },
}