export default {    
    addDefaultAgent(state, agent) {
        state.agents_default_list.set(agent.id, agent.data);
        state.agents_default_list = new Map(state.agents_default_list);
    },

    removeDefaultAgent(state, id) {
        if (id) {
            state.agents_default_list.delete(id);
            state.agents_default_list = new Map(state.agents_default_list);
        }
    },

    addAgentByRouter(state, agent) {
        state.agents_list.set(agent.id, agent.data);
        state.agents_list = new Map(state.agents_list);
    },
}