/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'star': {
    width: 512,
    height: 512,
    viewBox: '0 0 448.941 448.941',
    data: '<path pid="0" d="M448.941 168.353H287.603L224.471 0l-63.132 168.353H0l121.478 106.293-65.36 174.295 168.353-84.176 168.353 84.176-65.361-174.296z"/>'
  }
})
