/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'firewall-activity': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path pid="0" d="M30.545 26.274h-1.454V14.928c0-.964-.782-1.745-1.746-1.745h-3.781c-.964 0-1.746.781-1.746 1.745v11.346h-1.454V4.746a1.745 1.745 0 00-1.804-1.745h-3.782a1.745 1.745 0 00-1.687 1.745v21.528h-1.455v-15.71A1.745 1.745 0 009.833 8.82H6.05a1.745 1.745 0 00-1.687 1.746v15.709h-2.91a1.455 1.455 0 000 2.909h29.091a1.455 1.455 0 100-2.91zm-23.272 0V11.728h1.454v14.546H7.273zm8.727 0V5.91h1.455v20.364H16zm8.727 0V16.092h1.455v10.182h-1.455z" _fill="#0E1317" fill-rule="evenodd"/>'
  }
})
