import _ from "lodash";

export const createBlockingPages = (data) => ({
    cfdefault: data.parental_control.blocking_pages.default_blocking_page,
    syscfdefault: data.parental_control.blocking_pages.blocking_pages_info.system_blocking_page_url,
    cfpodefault: data.parental_control.blocking_pages.default_password_blocking_page,
    syscfpodefault: data.parental_control.blocking_pages.blocking_pages_info.system_blocking_page_url,
    cfurlslist: data.parental_control.blocking_pages.blocking_pages_info.external_blocking_pages,

    tpvdefault: data.threat_protection.blocking_pages.default_antivirus_blocking_page,
    systpvdefault: data.threat_protection.blocking_pages.blocking_pages_info.system_antivirus_blocking_page_url,

    tpmpdefault: data.threat_protection.blocking_pages.default_antimalware_blocking_page,
    systpmpdefault: data.threat_protection.blocking_pages.blocking_pages_info.system_antimalware_blocking_page_url,

    tpbdefault: data.threat_protection.blocking_pages.default_antibotnet_blocking_page,
    systpbdefault: data.threat_protection.blocking_pages.blocking_pages_info.system_antibotnet_blocking_page_url,

    tpurlslist: data.threat_protection.blocking_pages.blocking_pages_info.external_blocking_pages
})

export const mockBlockingPages = {
    cfdefault: "urlcf1",
    syscfdefault: "http://www.systemdefaultcontentfilter.com",
    cfpodefault: "SystemPasswordBlockingPage",
    syscfpodefault: "http://www.systemdefaultcontentfilterpassword.com",
    cfurlslist: [{ "name": "urlcf1", "blocking_page_url": "http://www.cfpage1.com" },
    { "name": "urlcf2", "blocking_page_url": "http://www.cfpage2.com" },
    { "name": "urlcf3", "blocking_page_url": "http://www.cfpage3.com" }],

    tpmpdefault: "SystemAntiMalwareBlockingPage",
    systpmpdefault: "http://www.systemdefaultthreatprotectionantimalware.com",

    tpvdefault: "SystemAntiVirusBlockingPage",
    systpvdefault: "http://www.systemdefaultthreatprotectionantivirus.com",

    tpbdefault: "SystemAntiBotnetBlockingPage",
    systpbdefault: "http://www.systemdefaultthreatprotectionantibotnet.com",

    tpurlslist: [{ "name": "urltp1", "blocking_page_url": "http://www.tppage1.com" },
    { "name": "urltp2", "blocking_page_url": "http://www.tppage2.com" },
    { "name": "urltp3", "blocking_page_url": "http://www.tppage3.com" }]
}