import axios from "axios";
import _ from 'lodash';
import i18n from "../../../i18n";

export default {
    getProfiles({ commit }) {
        return axios.get('profiles')
            .then(profiles => {
                commit({ type: 'setProfiles', profiles: profiles.data.items })
            })
            .catch(error => {
                commit('setErrorAll', error.response.data.description, { root: true });
                throw error
            });
    },
    getProfile({ commit }, profileId) {
        return axios.get(`profiles/${profileId}`)
            .then(profiles => {
                commit({ type: 'setProfile', profile: profiles.data })
                return _.cloneDeep(profiles.data)
            })
            .catch(error => {
                commit('setErrorAll', error.response.data.description, { root: true });
                throw error
            });
    },
    createProfile({ state, commit }) {
        return axios.post(`profiles`, { ...state.profile })
            .then(() => {
                commit("setSuccess", i18n.t('Profile created') + "!", { root: true });
            })
            .catch(error => {
                if (error.response.data.type && error.response.data.type === 'PROFILE.LIMIT_EXCEEDED') {
                  commit('setError', i18n.t('MAX_PROFILES_ALLOWED'), {root: true})
                }
                commit('setErrorAll', error.response.data.detail, { root: true });
                throw error
            });
    },
    updateProfile({ state, commit }) {
        return axios.put(`profiles/${state.profile.profile_definition_id}`, { ...state.profile })
            .then(() => {
                commit("setSuccess", i18n.t('Profile updated') + "!", { root: true });
            })
            .catch(error => {
                commit('setErrorAll', error.response.data.detail, { root: true });
                throw error
            });
    },
    deleteProfile({ state, commit }, profileId) {
        return axios.delete(`profiles/${profileId}`)
            .then(() => {
                let profiles = _.cloneDeep(state.profiles)
                profiles = profiles.filter(profile => profile.profile_definition_id !== profileId)
                commit("setProfiles", { profiles })
                commit("setSuccess", i18n.t('Profile deleted') + "!", { root: true });
            })
            .catch(error => {
                commit('setErrorAll', error.response.data.description, { root: true });
                throw error
            });
    },
    async getIspCategories({ commit, rootState }) {
        const categories = await axios.get('/isp-categories');
        commit({ type: 'setIspCategories', categories: categories.data, defaultLanguage: rootState.defaultAdministratorLanguageId })
    },
    async getIspAutoNoticePackages({ commit }) {
        const autoNoticePackages = await axios.get('/isp-autonotices-packages');
        commit({ type: 'addIspAutoNoticePackagesToProfile', autoNoticePackages: autoNoticePackages.data })
    },
}
