export const mockUser = (data = { account_id: 'allot_secure_demo', user_id: "a961ebfee0254de2bbdad04b3c2d0723" }) => ({
    "account_id": data.account_id,
    "default_user": true,
    "external_data": {},
    "external_user_id": "49847395734897aebd9e8932432",
    "name": "Default",
    "profile": {
        "profile_configuration": {},
        "profile_definition_id": "adult"
    },
    "user_id": data.user_id,
    "user_metadata": {
        "language_id": "en-US",
        "picture": {
            "format": "jpg",
            "picture_base64": "iVBORw0KGgoAAAANSUhEUgAAAAEAAAABAQMAAAAl21bKAAAAA1BMVEX/TQBcNTh/AAAAAXRSTlPM0jRW/QAAAApJREFUeJxjYgAAAAYAAzY3fKgAAAAASUVORK5CYII=",
            "storage_method": "embedded"
        }
    }
})

export const createUser = (item) => {
    if (!item.user_metadata) {
        item.user_metadata = { language_id: 'en-US', time_zone: 'America/New_York' };
    }
    if (!item.user_metadata.picture) {
        item.user_metadata.picture = { format: 'no_picture', storage_method: 'no_picture' };
    }
    const user = {
        id: item.user_id,
        data: item
    };
    return user
}