/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'item-threatprotection': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 2.4l-8 3v6.1c0 5.1 3.4 9.8 8 10.9 4.6-1.1 8-5.9 8-10.9V5.4l-8-3zm6 9.1c0 4-2.6 7.7-6 8.8-3.4-1.1-6-4.8-6-8.8V6.7l6-2.1 6 2.1v4.8zM8.8 11l-1.4 1.4 3.5 3.5 5.7-5.7-1.4-1.4-4.2 4.3L8.8 11z" _fill="#383132"/>'
  }
})
