<template>
  <div >
    <navbar :show-navbar="showMenu">
      <div class="navbar-wrapper">
        <div class="navbar-toggle" :class="{toggled: showMenu}">
          <navbar-toggle-button @click.native="toggleNavbar">
          </navbar-toggle-button>
        </div>
        <div class="navbar-brand">{{appName}}</div>
      </div>

      <template slot="navbar-menu">  

      <b-nav-item-dropdown right class="justify-content-end dark-bg" >
          <template slot="button-content">
            <i class="now-ui-icons location_world" type="neutral" style="font-size:24px;opacity: 1;"></i>
            <b class="caret d-none d-lg-block d-xl-block"></b>
          </template>

          <b-dropdown-item v-for="lang in languages" :active="$store.getters['settings/getLanguage'] == lang.id" :key="lang.id" 
                      @click="setLanguage(lang.id)">{{ lang.name}} </b-dropdown-item>
      </b-nav-item-dropdown>

        <router-link id="forgot-password-button" v-if="!areRecoveryLinksDisabled" class="nav-item" tag="li" to="/forgotmypassword">
          <a class="nav-link">
            <i class="now-ui-icons ui-1_lock-circle-open"></i>
            {{ t('Forgot my Password') }}
          </a>
        </router-link>
        <router-link id="forgot-username-button" v-if="!areRecoveryLinksDisabled" class="nav-item" tag="li" to="/forgotmyusername">
          <a class="nav-link">
            <i class="now-ui-icons users_single-02" style="font-size: 16px;"></i>
              {{ t('Forgot my Username') }}
          </a>
        </router-link>

        <router-link class="nav-item" tag="li" to="/login">
          <a class="nav-link">
            <i class="now-ui-icons users_circle-08"></i>
            {{ t('Login') }}
          </a>
        </router-link>

      </template> 

    </navbar>


    <div class="wrapper wrapper-full-page">
      <div class="full-page section-image" :class="pageClass" filter-color="black" data-image="/img/bg.jpg">
        <div class="content">
          <div class="container">
            <zoom-center-transition :duration="pageTransitionDuration" mode="out-in">
              <router-view></router-view>
            </zoom-center-transition>
          </div>
        </div>
       
        <content-footer v-if="footerLogin"/>

        <div class="full-page-background" style="backgroundImage: url('img/bg.jpg')"></div>
      </div>
    </div>
  </div>
</template>
<script>
import { Navbar, NavbarToggleButton } from '@/components';
import { ZoomCenterTransition } from 'vue2-transitions';
import ContentFooter from "./ContentFooter.vue";
import { mapGetters } from 'vuex';
import es from '../../locales-validation/es'
import en from '../../locales-validation/en'


export default {
  name: 'auth-layout',
  components: {
    Navbar,
    NavbarToggleButton,
    ZoomCenterTransition,
    ContentFooter
  },
  props: {
    backgroundColor: {
      type: String,
      default: 'black'
    }
  },
  data() {
    return {
      showMenu: false,
      menuTransitionDuration: 250,
      pageTransitionDuration: 200,
      year: new Date().getFullYear(),
      pageClass: 'login-page',
      areRecoveryLinksDisabled: false,
      es: es,
      en: en
    };
  },
  computed: {
    ...mapGetters({ 
      activeProvider: 'providers/activeProvider',
      defaultProvider: 'providers/defaultProvider'
    }),
    languages(){
      return this.$store.getters['settings/getLanguages']
    },
    appName() {
      return this.$store.getters['customProvider/appName'];
    },
      footerLogin() {
        return this.$store.getters['customProvider/footerLogin'];
    },
   
    footerLoginImage() {
      return this.$store.getters['customProvider/footerLoginImage'];
    },

  },
  methods: {
    setLanguage(language){
      try {
        this.$validator.localize(language, eval('this.' + language));
      } catch (error) {
        this.$validator.localize("en", en);
      }      
      this.$store.dispatch('settings/setLanguage', language)
    },    
    toggleNavbar() {
      document.body.classList.toggle('nav-open');
      this.showMenu = !this.showMenu;
    },
    closeMenu() {
      document.body.classList.remove('nav-open');
      this.showMenu = false;
    },
    setPageClass() {
      this.pageClass = `${this.$route.name}-page`.toLowerCase();
    }
  },
  created() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'providers/SET_ACTIVE_PROVIDER') {
        this.areRecoveryLinksDisabled = this.activeProvider ? this.activeProvider.identity_provider_type !== this.defaultProvider : false;
      }
    });
  },
  beforeDestroy() {
    this.closeMenu();
    this.unsubscribe();
  },
  beforeRouteUpdate(to, from, next) {
    // Close the mobile menu first then transition to next page
    if (this.showMenu) {
      this.closeMenu();
      setTimeout(() => {
        next();
      }, this.menuTransitionDuration);
    } else {
      next();
    }
  },
  watch: {
    $route() {
      this.setPageClass();
    }
  }
};
</script>
<style lang="scss">
$scaleSize: 0.8;
@keyframes zoomIn8 {
  from {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
  100% {
    opacity: 1;
  }
}
.wrapper-full-page .zoomIn {
  animation-name: zoomIn8;
}
@keyframes zoomOut8 {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
}
.wrapper-full-page .zoomOut {
  animation-name: zoomOut8;
}
.navbar-nav > li.dark-bg > .dropdown-menu { background-color: rgba(255, 255, 255, 0.1);}
.navbar-nav > li.dark-bg > .dropdown-menu::before { color: rgba(255, 255, 255, 0.1);}
</style>
