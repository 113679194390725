import DashboardLayout from "@/layout/dashboard/DashboardLayout.vue";

export default  {
    path: "/cyberbulling_endpoint",
    component: DashboardLayout,
    name: 'CyberBullying',
    children: [
        {
            path: "general",
            name: "CyberBullying / General",
            component: () => import('../../pages/CyberBulling/general')
        },
    ]
};