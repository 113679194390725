/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'edit-button': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path pid="0" d="M6.369 22.223l4.768-.434 8.97-8.978-4.318-4.32-8.998 8.997-.422 4.735zM17.935 6.346l4.317 4.317 3.119-3.124-4.316-4.315-3.12 3.122zM3.47 25.126a1.607 1.607 0 01-.463-1.28l.607-6.679c.068-.732.391-1.422.914-1.944L18.937.813C20.06-.316 22.1-.26 23.286.923l4.385 4.386.002.002c1.225 1.227 1.275 3.178.109 4.347L13.372 24.07c-.521.52-1.211.844-1.945.911l-6.68.607a1.607 1.607 0 01-1.278-.463zm25.16 5.272c0 .881-.721 1.602-1.602 1.602H4.602C3.722 32 3 31.28 3 30.398c0-.88.722-1.602 1.602-1.602h22.425c.88 0 1.602.723 1.602 1.602z" _fill="#000" fill-rule="nonzero"/>'
  }
})
