import { mapActions, mapMutations, mapState, mapGetters } from 'vuex';

export default {
    created() {
        this.getGlobalConfigurations();
        this.getGlobalAdvancedConfigurations();
    },
    beforeDestroy() {
        this.refresh(true)
    },
    computed: {
        ...mapState('settings',['subsystems', 'subsystemsDict']),
        ...mapGetters('ispServices/services', ['filter_by_service', 'filter_by_product', 'global_configurations','global_advanced_configurations']),
        configurations() {
            return this.global_configurations
        },
        advancedConfigurations() {
            return this.global_advanced_configurations
        },
        configs() {
            if (this.filter_by_service) {
                return { [this.filter_by_service]: this.configurations[this.filter_by_service] };
            }

            const filter_by_product = _.get(this, 'filter_by_product')

            if (filter_by_product) {
                const subsystems = this.subsystems;

                return _.pickBy(this.configurations, function (value, key) {
                    let answer = false;

                    subsystems.forEach(element => {
                        if (element.service_id == key && element.subsystems[0].subsystem_id == filter_by_product) {
                            answer = true;
                        }
                    });

                    return answer;
                });
            }

            return this.configurations;
        },
        sorted_configurations() {

            return Object.keys(this.configs).sort((keyA, keyB) => Object.keys(this.configs[keyB]).length - Object.keys(this.configs[keyA]).length)
        }
    },
    methods: {
        ...mapActions('ispServices/services', ['setGlobalConfigurations', 'setGlobalAdvancedConfigurations', 'getGlobalConfigurations','getGlobalAdvancedConfigurations']),
        ...mapMutations('ispServices/services', ['refresh']),
        ...mapActions('settings', ['setIsFormDirty']),


        setConfigurations() {
            const newConfig = _.omit(this.global_configurations, ["parental_control.blocking_pages", "threat_protection.blocking_pages"])
            this.setGlobalConfigurations(newConfig);
            this.setGlobalAdvancedConfigurations(this.global_advanced_configurations);
            this.setIsFormDirty(false);
        },

        disable(service) {
            const disabled = !_.get(this.global_configurations, `${this.subsystemsDict[service]}.enabled`, true);
            return disabled ? disabled : _.has(this.configurations[service], 'enabled') ? !this.configurations[service].enabled : false;
        },

        showService(service) {
            //console.log('service::::')
            //console.log(service)
            if (service)
                return (Object.keys(service).length > 1 && _.has(service, 'enabled')) || (Object.keys(service).length > 0 && !_.has(service, 'enabled'));
        },


        showBlockingPages(service) {
            //console.log('service::::')
            //console.log(service)
            if (service)
                return (Object.keys(service).length > 1 && _.has(service, 'blocking_pages'))
        }
    }
}