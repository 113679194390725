export default () => ({
    accounts: new Map(),
    account: null,
    account_tab_active: 'Managers',
    loading: false,
    query: {
        sort: "asc",
        limit: 10,
    },
    selfQuery: {},
    toggleDisabled: false
})