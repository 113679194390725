<template>
  <div>
    <slot name="header">
      <h5 v-if="show" slot="header" class="danger title">{{ t(title) }}</h5>
    </slot>
  </div>
</template>

<script>
import { Switch } from "@/components";
import { mapState, mapMutations } from "vuex";
import General from "@/mixins/General";

export default {
  components: {},
  name:"product-attention-message",
  props: {
    title: { type: String },
    showOnlyIfProductsExist: {
      type: Array,
      default: [],
    },
    operator: {
      type: String,
    },
  },
  data() {
    return {
      show: false,
    };
  },
  created() {
    switch (this.operator) {
      case "OR":
        this.show = this.showOnlyIfProductsExist.some((product) =>
          this.products.includes(product)
        );
        break;
      case "AND":
        this.show = this.showOnlyIfProductsExist.every((product) =>
          this.products.includes(product)
        );
        break;
    }
  },
  computed: {
    ...mapState("settings",["products"]),
  },
  methods: {},
};
</script>
