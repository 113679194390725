var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.$store.getters['settings/isIOT'])?_c('nested-sidebar-item',{staticClass:"svg-icon-24",attrs:{"link":{
    name: 'ISP Services',
    query: _vm.$route.query,
    path: '/isp_services',
    svgicon: 'item-ispservices',
  },"menu":"","id":"IspServices","visible_for_permissions":[
    { actions: ['GET'], path: '/isp-categories' },
    { actions: ['GET'], path: '/notifications/advanced-configuration' },
    { actions: ['GET'], path: '/isp-service-advanced-configuration' },
    { actions: ['GET'], path: '/isp-forbidden-lists/.*' },
    { actions: ['GET'], path: '/isp-allowed-lists/.*' } ],"operator":"OR"}},[_c('nested-sidebar-item',{attrs:{"link":{
      name: 'Configuration',
      query: _vm.$route.query,
      path: '/isp_services/configuration',
      svgicon: 'item-configuration',
    },"menu":"","id":"menu_Configuration","visible_for_permissions":[
      { actions: ['GET'], path: '/isp-categories' },
      { actions: ['GET'], path: '/notifications/advanced-configuration' } ],"operator":"OR"}},_vm._l((_vm.configurations),function(link,key){return ((link.name != 'Notifications' && link.name != 'Category Management') ? true : !_vm.$store.getters['settings/isIOT'] ? true : false)?_c('nested-sidebar-item',{key:key,attrs:{"link":link,"visible_for_permissions":link.visible_for_permissions}}):_vm._e()}),1),(!_vm.$store.getters['settings/isIOT'])?_c('nested-sidebar-item',{attrs:{"link":{
      name: 'Services',
      query: _vm.$route.query,
      path: '/isp_services/services',
      svgicon: 'item-services',
    },"menu":"","id":"menu_Services","visible_for_permissions":[
      { actions: ['GET'], path: '/isp-service-advanced-configuration' },
      { actions: ['GET'], path: '/isp-forbidden-lists/.*' },
      { actions: ['GET'], path: '/isp-allowed-lists/.*' } ],"operator":"OR"}},_vm._l((_vm.services),function(service,key){return _c('nested-sidebar-item',{key:key,attrs:{"link":{
        name: service.name,
        query: _vm.$route.query,
        path: '/isp_services/services',
        svgicon: service.svgicon,
      },"menu":"","visible_for_services":service.visible_for_services,"visible_for_permissions":service.visible_for_permissions,"operator":service.operator}},[_c('nested-sidebar-item',{attrs:{"link":Object.assign({}, service, {name: 'Service Configuration', svgicon: 'item-configuration'})}}),(service.child)?_c('nested-sidebar-item',{attrs:{"link":service.child}}):_vm._e()],1)}),1):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }