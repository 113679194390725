/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'moon': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M8.6 5c.3-.2.6-.4.9-.5-.4 2.9.3 5.8 2 8.3s4.2 4.2 6.9 4.9c-.2.2-.5.4-.8.6C14 20.8 9 19.8 6.5 16.2S4.9 7.5 8.6 5M7.5 3.3c-4.6 3.1-5.8 9.3-2.7 13.9s9.3 5.8 13.9 2.7c1.5-1 2.6-2.4 3.4-3.9-3.4.2-6.9-1.3-9-4.3s-2.2-6.9-.7-10c-1.7.1-3.4.6-4.9 1.6z" _fill="#626262"/>'
  }
})
