<template functional>
    <div>
      <div v-if="props.row[props.column.prop].length <= 0" >
        {{props.column.anyText}}                   
      </div>
      <div 
        v-for="item in props.row[props.column.prop]" 
        :key="item" 
        style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
      >
          <i class="now-ui-icons arrows-1_minimal-right icon icon-primary"/> 
          <el-tooltip 
            :content="item" 
            placement="top" 
            effect="light"
            :disabled="item.length < 10"
          >
            <span>{{ item }}</span>
          </el-tooltip>
      </div>
    </div>
</template>

<script>
  export default {
    name: 'list-formatter',
  }
</script>