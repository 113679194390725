export default {
    getAntiBotnet: (state) => () => {
        return state.antiBotnetStrategy !== undefined ? state.antiBotnetStrategy : {};
    },
    getAntiBotnetRules(state) {
        return state.rules;
    },
    getAntiBotnetStrategy(state, getters, rootState, rootGetters) {
        return getters.getAntiBotnet;
    },
    isDebug(state, getters, rootState, rootGetters) {
        return rootGetters['settings/getDebug']
    },
    getAdvancedConfiguration(state) {
        return state.advancedConfiguration !== undefined ? state.advancedConfiguration : {};
    },
    getDefaultBlockingUrl(state) {
        return state.defaultBlockingUrl;
    },
    getRuleById: (state, getters) => (ruleId) => {
        let rule = state.rules.filter(rule => "" + rule.rule_name === "" + ruleId);
        if (rule && rule.length > 0)
            return _.cloneDeep(rule[0]);
        else
            return null;
    },
};