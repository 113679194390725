export default {
    accounts(state) {
        return state.accounts
    },
    account: (state) => (id) => {
        return state.accounts.get(id)
    },
    accountDefaultProfile: (state) => (account)  => {
        if ( account && account.profiles)
          return account.profiles.profile_id_default
        else
          return ''
    },
    accountActiveTab(state) {
        return state.account_tab_active
    },
}
