<template functional>
    <div>
        <template v-if="props.row[props.column.prop].deleted">
            <span style="text-decoration: line-through; white-space: nowrap;"> {{ props.row[props.column.prop].name }} </span>
        </template>
        <template v-else>
            <template v-if="props.row[props.column.prop].modified">
                <span style="white-space: nowrap;">{{ props.row[props.column.prop].name}}*</span>
            </template>
            <template v-else>
                <span style="white-space: nowrap;">{{ props.row[props.column.prop].name}}</span>
            </template>
        </template>
    </div>
</template>

<script>
  export default {
    name: 'profile-formatter',
  }
</script>