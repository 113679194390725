import { BaseInput, Card, BaseDropdown, BaseButton, BaseCheckbox } from "../components/index";
import fgInput from '../components/Inputs/formGroupInput.vue';
import DropDown from '../components/Dropdown.vue';
import Pagination from '../components/Pagination.vue';
//import Card from '../components/Cards/Card.vue';
import Button from '../components/Button.vue';
import Avatar from '../components/Avatar.vue';

import { Tooltip } from 'element-ui';

/**
 * You can register global components here and use them as a plugin in your main Vue instance
 */

const GlobalComponents = {
  install(Vue) {
    Vue.component(BaseInput.name, BaseInput);
    Vue.component(Card.name, Card);
    Vue.component(BaseDropdown.name, BaseDropdown);
    Vue.component(BaseButton.name, BaseButton);
    Vue.component(BaseCheckbox.name, BaseCheckbox);

    Vue.use(Tooltip);
    
    Vue.component(Pagination.name, Pagination);
    Vue.component(Avatar.name, Avatar)

    Vue.component('fg-input', fgInput);
    Vue.component('drop-down', DropDown);
    Vue.component('n-button', Button);
  }
};

export default GlobalComponents;
