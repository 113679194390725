export default {
    scheduledTasks(state) {
      return state.scheduled_tasks;
    },
    scheduledTask(state) {
      return state.scheduled_task;
    },
    scheduledTaskModules(state) {
      return state.scheduled_task_functions.map(item => {
        return {
          value: item.module,
          label: item.module.split(/(?=[A-Z][a-z]{2,})/).join(' ')
        }
      })
    },
    scheduledTaskFunctions(state) {
      return state.scheduled_task_functions.reduce((accumulator, item) => {
        accumulator[item.module] = item.functions.map(func => {
          return {
            value: func,
            label: func.split(/(?=[A-Z][a-z]{2,})/).join(' ')
          }
        })

        return accumulator
      }, {})
    },
}