import DashboardLayout       from "@/layout/dashboard/DashboardLayout.vue";
import ServiceConfigurations from "@/pages/IspGlobal/ServiceConfigurations";

export default {
    path: '/isp_global',
    component: DashboardLayout,
    name: 'ISP Global',
    children: [
      {
        path: 'service_configurations',
        name: 'ISP Global / Service Configurations',
        component: ServiceConfigurations
      },
      {
        path: 'forbidden_allowed_lists',
        name: 'ISP Global / Forbidden & Allowed lists',
        component: () => import('@/pages/IspServices/Services/ForbiddenAllowedLists')
      }
    ]
};
