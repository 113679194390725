export const initialState = () => ({
  activeProvider: {},
  defaultProvider: 'IdentityProviderDatabase',
  isp: {
    providerList: [],
    providerDetails: {
      enabled: false,
      system: false,
      identity_provider_id: '',
      identity_provider_type: '',
      password_complexity: {
        check_keyboard_pattern: false,
        check_mirrored_sequence: false,
        check_repeated_sequence: false,
        check_sequential_letters: false,
        check_sequential_numbers: false,
        minimum_lowercase_required: 0,
        minimum_middle_number_digits_required: 0,
        minimum_middle_symbols_required: 0,
        minimum_number_digits_required: 0,
        minimum_password_length: 0,
        minimum_symbols_required: 0,
        minimum_uppercase_required: 0,
      },
    },
    password_rotation: {
      maximum_elapsed_time_without_change: 90,
      history_check_depth: 5,
      minimum_elapsed_time_to_change: 30,
      rotate_on_first_login: false
    },
    local_cache: false,
    ldap_cluster: null,
    providerDBActive: false,
  },
  ent: {
    providerList: [],
    providerDetails: {
      enabled: false,
      system: false,
      identity_provider_id: '',
      identity_provider_type: '',
      password_complexity: {
        check_keyboard_pattern: false,
        check_mirrored_sequence: false,
        check_repeated_sequence: false,
        check_sequential_letters: false,
        check_sequential_numbers: false,
        minimum_lowercase_required: 0,
        minimum_middle_number_digits_required: 0,
        minimum_middle_symbols_required: 0,
        minimum_number_digits_required: 0,
        minimum_password_length: 0,
        minimum_symbols_required: 0,
        minimum_uppercase_required: 0,
      },
      password_rotation: {
        maximum_elapsed_time_without_change: 90,
        history_check_depth: 5,
        minimum_elapsed_time_to_change: 30,
        rotate_on_first_login: false
      },
      configuration: {
        log_as_in_frame: false
      }, 
      redirections: {
      }

    },
    systemSsoProviderEnabled: false
  },
  res: {
    providerList: [],
    providerDetails: {
      enabled: false,
      system: false,
      identity_provider_id: '',
      identity_provider_type: '',
      password_complexity: {
        check_keyboard_pattern: false,
        check_mirrored_sequence: false,
        check_repeated_sequence: false,
        check_sequential_letters: false,
        check_sequential_numbers: false,
        minimum_lowercase_required: 0,
        minimum_middle_number_digits_required: 0,
        minimum_middle_symbols_required: 0,
        minimum_number_digits_required: 0,
        minimum_password_length: 0,
        minimum_symbols_required: 0,
        minimum_uppercase_required: 0,
      },
      password_rotation: {
        maximum_elapsed_time_without_change: 90,
        history_check_depth: 5,
        minimum_elapsed_time_to_change: 30,
        rotate_on_first_login: false
      },
      configuration: {
        log_as_in_frame: false
      }, 
      redirections: {
      },
      api_key: '',
      api_secret: '',
    },
    systemSsoProviderEnabled: false
  }
});

export default initialState();
