<template>
  <component
    :is="baseComponent"
    v-if="check_sniffer_mode ? make_visible && permission_granted && !sniffer_mode_enabled : make_visible && permission_granted"
    :to="{ path: link.path ? link.path : '/', query: link.query }"
    :class="{ active: isActive }"
    tag="li"
  >
    <a
      v-if="isMenu"
      href="#"
      class="nav-link sidebar-menu-item"
      :aria-expanded="!collapsed"
      data-toggle="collapse"
      @click.prevent="collapseMenu"
      style="width: 90%"
    >
      <template v-if="link.icon">
        <i :class="link.icon"></i>
      </template>
      <template v-else-if="link.ionicon">
        <ion-icon :name="link.ionicon"></ion-icon>
      </template>
      <template v-else-if="link.iconify">
        <ion-icon class="iconify-menu iconify" :name="link.iconify" :data-icon="link.iconify"></ion-icon>
      </template>
      <template v-else-if="link.svgicon">
        <i
          ><svgicon class="icon" :class="{'svg_icon__nofill': link.svgicon_nofill}" :icon="link.svgicon" width="17" height="17"
        /></i>
      </template>
      <template v-else>
        <span class="sidebar-mini-icon">{{ linkPrefix }}</span>
      </template>

      <p>
        <span class="link-name" :title="t(link.name)">{{ t(link.name) }}</span>
        <b class="caret"></b>
      </p>
    </a>

    <collapse-transition>
      <div v-if="$slots.default || this.isMenu" v-show="!collapsed">
        <ul class="nav links__nav" :class="{minimized: isMinimized}">
          <slot></slot>
        </ul>
      </div>
    </collapse-transition>

    <slot
      name="title"
      v-if="children.length === 0 && !$slots.default && link.path"
    >
      <component
        :to="{ path: link.path, query: link.query }"
        @click.native="linkClick"
        :is="elementType(link, false)"
        :class="{ active: isActive }"
        class="nav-link"
        :target="link.target"
        :href="link.path"
      >
        <template v-if="addLink">
          <template v-if="link.icon">
            <i :class="link.icon"></i>
          </template>
          <template v-else-if="link.ionicon">
            <ion-icon :name="link.ionicon"></ion-icon>
          </template>
          <template v-else-if="link.iconify">
            <ion-icon class="iconify-menu iconify" :name="link.iconify" :data-icon="link.iconify"></ion-icon>
          </template>
          <template v-else-if="link.svgicon">
            <i>
              <svgicon
                class="icon"
                :icon="link.svgicon"
                :class="{'svg_icon__nofill': link.svgicon_nofill}"
                width="17"
                height="17"
              />
            </i>
          </template>
          <template v-else>
            <span class="sidebar-mini-icon">{{ linkPrefix }}</span>
          </template>
          <span class="sidebar-normal sublink-name" :title="t(link.name)">{{ t(link.name) }}</span>
        </template>
        <template v-else>
          <template v-if="link.icon">
            <i :class="link.icon"></i>
          </template>
          <template v-else-if="link.ionicon">
            <ion-icon :name="link.ionicon"></ion-icon>
          </template>
          <template v-else-if="link.iconify">
            <ion-icon class="iconify-menu iconify" :name="link.iconify" :data-icon="link.iconify"></ion-icon>
          </template>
          <template v-else-if="link.svgicon">
            <i>
              <svgicon
                class="icon"
                :icon="link.svgicon"
                :class="{'svg_icon__nofill': link.svgicon_nofill}"
                width="17"
                height="17"
              />
            </i>
          </template>
          <p>{{ t(link.name) }}</p>
        </template>
      </component>
    </slot>
  </component>
</template>
<script>
import { CollapseTransition } from "vue2-transitions";
import authorization from "@/mixins/authorization";
import permissions from "@/mixins/permissions";
import checkSnifferMode from "@/mixins/checkSnifferMode";

export default {
  name: "nested-sidebar-item",
  components: {
    CollapseTransition,
  },
  mixins: [authorization, permissions, checkSnifferMode],
  props: {
    menu: {
      type: Boolean,
      default: false,
    },
    link: {
      type: Object,
      default: () => {
        return {
          name: "",
          path: "",
          query: "",
          children: [],
        };
      },
    },
  },
  provide() {
    return {
      addLink: this.addChild,
      removeLink: this.removeChild,
    };
  },
  inject: {
    addLink: { default: null },
    removeLink: { default: null },
    autoClose: {
      default: true,
    },
  },
  data() {
    return {
      children: [],
      collapsed: true,
    };
  },
  computed: {
    isMinimized() {
      return this.$sidebar.isMinimized
    },
    baseComponent() {
      return this.isMenu || this.link.isRoute ? "li" : "router-link";
    },
    linkPrefix() {
      if (this.link.name) {
        let words = this.link.name.split(" ");
        return words.map((word) => word.substring(0, 1)).join("");
      }
    },
    isMenu() {
      return this.children.length > 0 || this.menu === true;
    },
    isActive() {
      if (this.$route && this.$route.path) {
        if (this.isMenu) {
          let matchingRoute = this.children.find((c) =>
            this.$route.path.startsWith(c.link.path)
          );
          if (matchingRoute !== undefined) {
            return true;
          }
        } else if (this.link.path) {
          return this.$route.path.startsWith(this.link.path);
        }
      }
      return false;
    },
  },
  methods: {
    addChild(item) {
      const index = this.$slots.default.indexOf(item.$vnode);
      this.children.splice(index, 0, item);
    },
    removeChild(item) {
      const tabs = this.children;
      const index = tabs.indexOf(item);
      tabs.splice(index, 1);
    },
    elementType(link, isParent = true) {
      if (link.isRoute === false) {
        return isParent ? "li" : "a";
      } else {
        return "router-link";
      }
    },
    linkAbbreviation(name) {
      const matches = name.match(/\b(\w)/g);
      return matches.join("");
    },
    linkClick() {
      if (
        this.autoClose &&
        this.$sidebar &&
        this.$sidebar.showSidebar === true
      ) {
        this.$sidebar.displaySidebar(false);
      }
    },
    collapseMenu() {
      this.collapsed = !this.collapsed;
    },
    collapseSubMenu(link) {
      link.collapsed = !link.collapsed;
    },
  },
  mounted() {
    if (this.addLink) {
      this.addLink(this);
    }
    if (this.link.collapsed !== undefined) {
      this.collapsed = this.link.collapsed;
    }
    if (this.isActive && this.isMenu) {
      this.collapsed = false;
    }
  },
  destroyed() {
    if (this.$el && this.$el.parentNode) {
      this.$el.parentNode.removeChild(this.$el);
    }
    if (this.removeLink) {
      this.removeLink(this);
    }
  },
};
</script>
<style>
.sidebar-menu-item {
  cursor: pointer;
}
.sidebar ul.links__nav {
  margin-top: 0;
  padding-top: 0px;
  padding-left: 15px;
}
.link-name {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 130px;
}
.sublink-name {
  overflow: hidden;
  text-overflow: ellipsis;
}

.svg_icon__nofill{
  fill: none ! important;
  stroke: currentColor ! important;
}

.sidebar .nav ion-icon, .off-canvas-sidebar .nav ion-icon, svg.iconify-menu {
  font-size: 17px;
  float: left;
  margin-right: 12px;
  line-height: 20px;
  width: 26px;
  text-align: center;
  color: rgba(255, 255, 255, 0.8);
  position: relative;
}
</style>
