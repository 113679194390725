/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icon-new': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path pid="0" d="M16 0c8.837 0 16 7.163 16 16v10.667A5.333 5.333 0 0126.667 32H16C7.163 32 0 24.837 0 16S7.163 0 16 0zm0 3.556C9.127 3.556 3.556 9.127 3.556 16c0 6.873 5.571 12.444 12.444 12.444 6.873 0 12.444-5.571 12.444-12.444 0-6.873-5.571-12.444-12.444-12.444zm1.778 5.333v5.333h5.333v3.556h-5.333v5.333h-3.556v-5.333H8.89v-3.556h5.333V8.89h3.556z" _fill="#000" fill-rule="evenodd"/>'
  }
})
