import _ from 'lodash';
import axios from "axios";
import { mockProfiles } from './mockData';

export default {
    getProfiles({ commit, state, rootState, dispatch }) {
        if (rootState.settings.debug) {
            return new Promise((resolve, reject) => {
                mockProfiles.forEach(profile => {
                    commit('setProfile', profile)
                })
                resolve(state.profiles);
            });
        }
        else {
            return new Promise((resolve, reject) => {
                axios.get('/profiles')
                    .then(res => {
                        if (res && res.data && res.data.items) {
                            state.profiles.clear()
                            res.data.items.forEach(item => {
                                commit('setProfile', _.merge({ id: item.profile_definition_id }, item))
                            })
                            resolve(state.profiles)
                        }
                        else {
                            reject()
                        }
                    })
                    .catch(error => {
                        commit('setError', error, { root: true });
                        reject()
                    })
            });
        }
    },
}