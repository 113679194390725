/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icon-passwordstrength': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path pid="0" d="M7.53 17.412v-3.765a1.883 1.883 0 00-3.765 0v3.765a1.883 1.883 0 003.764 0M5.647 8A5.647 5.647 0 000 13.647v3.765a5.648 5.648 0 0011.294 0h11.294v3.765h3.765v-3.765h1.882v3.765H32v-7.53H11.294A5.647 5.647 0 005.647 8" _fill="#000" fill-rule="evenodd"/>'
  }
})
