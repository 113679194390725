import axios from "axios";
import { filterStrategyByService } from "@/util/strategyUtil";
import { strategy as dummyFirewall } from '@/util/dummy/firewallRules.json';
import {
    prepareFirewallData,
    prepareStrategyData,
    prepareStrategyStatusData
} from "@/util/firewallUtils";

import {
    getPartialStrategyFromRule,
    setDeletedElementsToNull
} from "@/util/strategyUtil";
import _ from 'lodash';

import i18n from '../../../i18n';


export default {
    async getFirewall({ commit, state, getters }) {
        if (getters.isDebug) {
            if (!state.firewallStrategy || Object.keys(state.firewallStrategy).length === 0)
                commit("setFirewallStrategy", filterStrategyByService(dummyFirewall, 'firewall'));
        } else {
            // Get isp strategy from backend
            try {
                const firewallStrategy = await axios.get('/subsystems/ns/isp-strategy');
                commit("setFirewallStrategy", filterStrategyByService(firewallStrategy.data, 'firewall'));
            } catch (err) { console.error('Error getting isp strategy: ', err) }
        }
    },
    getFirewallAdvancedConfiguration({ commit, state, rootState, dispatch }) {
        if (rootState.settings.debug) {
            return new Promise((resolve, reject) => {
                let config = {
                    "tcp_traffic": 1800,
                    "udp_traffic": 60,
                    "icmp_traffic": 5,
                    "other_traffic": 30
                }
                resolve(config)
            })
        } else {
            return new Promise((resolve, reject) => {
                axios.get('/subsystems/ns/service-configuration')
                    .then(res => {
                        if (res && res.data && res.data.firewall) {
                            resolve(res.data.firewall)
                        }
                        else {
                            reject()
                        }
                    })
                    .catch(error => {
                        commit('setError', error, { root: true });
                        reject()
                    })
            });
        }
    },
    submitFirewallAdvancedConfiguration({ commit, state, rootState, dispatch }, data) {
        if (rootState.settings.debug) {
            return new Promise((resolve, reject) => {
                let res = {
                    "data": data,
                    "response": "OK"
                };
                resolve(res);
            })
        } else {
            return new Promise((resolve, reject) => {
                axios.put('/subsystems/ns/service-configuration', data)
                    .then(res => {
                        if (res) {
                            resolve(res);
                        }
                    })
                    .catch(error => {
                        commit('setError', error, { root: true });
                        reject()
                    })
            });
        }
    },

    async deleteRule({ state, commit, getters, dispatch }, ruleToDelete) {
        if (!getters.isDebug) {
            try {
                await axios.patch("/subsystems/ns/isp-strategy?cascade_delete=true", { rules: { [ruleToDelete.rule_name]: null } });
                await dispatch("getFirewall");
                commit("setFirewallRules", prepareFirewallData(getters.getFirewall()));
            } catch (err) { console.error('Error deleting isp strategy rule: ', err) }
        } else {
            const deletedOrder = ruleToDelete.order;
            let rules = state.rules;
            const updatedRules = rules.filter(rule => {
                if (rule.id !== ruleToDelete.id) {
                    if (rule.order > deletedOrder) rule.order--;
                    return rule;
                }
            });
            commit("setFirewallRules", updatedRules);
        }
    },

    async createFirewallRule({ state, commit, getters, dispatch }, createdRule) {
        createdRule.order = 0;
        createdRule.id = createdRule.rule_name;

        if (!getters.isDebug) {
            const partialStrategy = prepareStrategyData([createdRule]);
            try {
                await axios.post('/subsystems/ns/isp-strategy/provision', { ...partialStrategy });
                await dispatch("getFirewall");
                commit("setFirewallRules", prepareFirewallData(getters.getFirewall()));
            } catch (err) {
                console.error('Error updating strategy: ', err);
                if (err.response) {
                    switch(err.response.status){
                        case 409:
                            if (err.response.data.type && err.response.data.type === 'ISP_STRATEGY.LIMIT_EXCEEDED') {
                                commit('setError', i18n.t('MAX_POLICIES_ALLOWED'), {root: true})
                            } else {
                                 commit('setError', i18n.t(err.response.data.type), {root:true});
                            }
                            break;
                        default:
                            commit('setError', err, {root:true})
                    }
                }
                throw new Error(err);
            }
            /** This piece of code is temp, only for use case tests */
            // Download json result
            // generateJson(partialStrategy, createdRule.rule_name);
            /** End of temp code */
        } else {
            commit("addFirewallRule", createdRule);
        }
    },
    getFirewallRulesData({ commit, getters, state }) {
        if (!getters.isDebug || state.rules.length === 0) {
            const ispStrategy = getters.getFirewall();
            const rules = prepareFirewallData(ispStrategy);
            const sortedRules = rules.sort((a, b) => {
                return a.order > b.order ? 1 : -1;
            });

            commit("setFirewallRules", sortedRules);
        }
    },
    async updateFirewallRule({ commit, getters, state, dispatch }, updatedRule) {
        if (!getters.isDebug) {
            // PUT the new rules to backend
            let newPartialStrategy = prepareStrategyData([updatedRule]);
            let oldPartialStrategy = getPartialStrategyFromRule(updatedRule, getters.getFirewall())
            newPartialStrategy = setDeletedElementsToNull(oldPartialStrategy, newPartialStrategy);
            try {
                await axios.patch('/subsystems/ns/isp-strategy', { ...newPartialStrategy });
                await dispatch("getFirewall");
                commit("setFirewallRules", prepareFirewallData(getters.getFirewall()));
            } catch (err) {
                console.error('Error updating rule: ', err)
                commit('setError', err, { root: true });
                throw err
            }
            /** This piece of code is temp, only for use case tests */
            // Download json result
            // generateJson(strategy, updatedRule.rule_name);
            /** End of temp code */
        } else {
            const updatedRules = state.rules.map(rule =>
                rule.id === updatedRule.id ? updatedRule : rule
            );
            commit("setFirewallRules", updatedRules);
        }
    },
    async updateFirewallRuleStatus({ commit, getters, state, dispatch }, updatedRule) {
        if (getters.isDebug) {
                const updatedRules = state.rules.map(rule => {
                    if (rule.rule_name === updatedRule.rule_name) {
                        rule.status = updatedRule.status;
                        return rule;
                    } else {
                        return rule;
                    }
                }
            );
            commit("setFirewallRules", updatedRules);
        } else {
            let newPartialStrategy = prepareStrategyStatusData(updatedRule);
            try {
                await axios.patch('/subsystems/ns/isp-strategy', { ...newPartialStrategy });
            } catch (err) {
                console.error('Error updating rule: ', err);
                commit('setError', err, { root: true });
                throw err
            }
        }
    },
};
