import categoryManagement from './categoryManagement';
import forbiddenAllowedList from './forbiddenAllowedList';
import notifications from './notifications';
import profiles from './profiles';
import services from './services';

export default {
    namespaced: true,
    modules: {
        categoryManagement,
        forbiddenAllowedList,
        notifications,
        profiles,
        services
    }
};
