<template>
  <div class="container">
        <b-col  md="5" lg="5" xl="5" class="ml-auto">   
     <div class="panel" >
        <el-tree
            :data="source"
            :props="defaultProps"
            ref="tree"
            show-checkbox
            @check-change="handleCheckChange">
          </el-tree>
    </div>
    </b-col>
      <b-col class="button-container"  md="2" lg="2" xl="2">
     <n-button @click.native="handleImport"
                            type="primary"
                            :disabled="isLeftDisabled"
                            size="md" round block>
                        >> 
      </n-button>
      <n-button @click.native="handleExport"
                            type="primary"
                            :disabled="isRightDisabled"
                            size="md" round block>
                        <<  
      </n-button>
      </b-col>
     <b-col  md="5" lg="5" xl="5" class="ml-auto">
     <div class="panel" >
        <el-tree
            :data="target"
            :props="defaultProps"
            ref="treeTarget"
            show-checkbox
            @check-change="handleCheckChange">
          </el-tree> 
    </div>
     </b-col>
  </div> 
</template>
<script>

import {Tree } from "element-ui";

  export default {
    name: 'transfer-tree',
    components: {
      'el-tree': Tree
    },
    props: {
      sourceProps: {
        type: Array,
      },
      targetProps: {
        type: Array,
      }
    },
    data() {
      return {
          isLeftDisabled: false,
          isRightDisabled: true,
          source: [],
          target: [],
          defaultProps: {
            children: 'children',
            label: 'label'
          },
        }
    },
    methods: {
        handleCheckChange(data, checked, indeterminate) {
        console.log(data, checked, indeterminate);
      },
      handleImport() {
        console.log('import:',  this.$refs.tree.getCheckedNodes())
        const nodesSelected = this.$refs.tree.getCheckedNodes();
        const rootNodes = nodesSelected.filter(element => element.children.length > 0);
        const leafNodes = nodesSelected.filter(element => element.children.length == 0);
        // leafnodes comprobar si está en el children de los rootnodes
        let included = [];
    
        leafNodes.map(element =>  { 
          const isIncluded = this.isInRootNodesChildren(rootNodes, element.label)
          console.log('return:', isIncluded, element.label)
          if(!isIncluded) {
            const parentWithChild = this.checkTreeParents(this.$refs.tree.getHalfCheckedNodes(), element)
            const nodes = included;
            let includedParent = true
            included.map(node => {
              if (node.label === parentWithChild.label)
                node.children.push(element)
                // TODO: remove one from left
                return (includedParent = false)
            })
            if (includedParent) {
              included.push(parentWithChild)
              // TODO: remove one from left

            }  
          }
        })
        console.log('included:', included)
        this.target = this.target.concat(rootNodes.concat(included));
        if(this.target.length > 0) {
          this.isRightDisabled = false;
        }
        // remove rootNodes
        this.source = this.source.filter(node => !rootNodes.includes(node));
        // TODO: remove nodes leaf that inserted in the right side
         if(this.source.length === 0) {
          this.isLeftDisabled = true;
        }

      },
       handleExport() {
        console.log('export:',  this.$refs.treeTarget.getCheckedNodes())
          const nodesSelected = this.$refs.treeTarget.getCheckedNodes();
        const rootNodes = nodesSelected.filter(element => element.children.length > 0);
        const leafNodes = nodesSelected.filter(element => element.children.length == 0);
        // par los leafnodes comprobar si está en el children de los rootnodes
        let included = [];
    
        leafNodes.map(element =>  { 
          const isIncluded = this.isInRootNodesChildren(rootNodes, element.label)
          console.log('return:', isIncluded, element.label)
          if(!isIncluded) {
            const parentWithChild = this.checkTreeParents(this.$refs.tree.getHalfCheckedNodes(), element)
            const nodes = included;
            let includedParent = true
            included.map(node => {
              if (node.label === parentWithChild.label)
                node.children.push(element)
                // TODO: remove one from right
                console.log('should delete right  child of', node.label, element.label)
                return (includedParent = false)
            })
            if (includedParent) {
              included.push(parentWithChild)
              console.log('should', element.label, "of", parentWithChild.label)
              // TODO: remove one from right

            }  
          }
        })
        console.log('included:', included)
        this.source = this.source.concat(rootNodes.concat(included));
        if(this.source.length > 0) {
          this.isLeftDisabled = false;
        }
        // remove rootNodes
        this.target = this.target.filter(node => !rootNodes.includes(node));
        // TODO: remove nodes leaf that inserted in the right side
         if(this.target.length === 0) {
          this.isRightDisabled = true;
        }
      },
      isInRootNodesChildren(nodes, name) {
        let isRoot = false
        for (let index in nodes) {
            if (name === nodes[index].label) {
              isRoot = true;
              break;
          } else {
            if (nodes[index].children.length > 0) {
              let isInchildren = this.isInRootNodesChildren(nodes[index].children, name)
              if (isInchildren) {return(true)}
            }
          }
        }
       return isRoot;   
      },
      checkTreeParents(nodes, element) {
        let NodeElement = []
        for (let index in nodes) {
            if (name === nodes[index].label) {
              return(nodes[index])
              break;
          } else {
            if (nodes[index].children.length > 0) {
              let isInchildren = this.isInRootNodesChildren(nodes[index].children, element.label)
              if (isInchildren) {
                  let parent = {
                    ...nodes[index],
                  } 
                  parent.children =[]
                  parent.children.push(element); 
                  return(parent)
              }
            }
          }
        }
      },
     
    },
    computed: {
  
    },
    mounted () {
      this.source = this.sourceProps;
      this.target = this.targetProps;   
    },
  }
</script>
<style scoped lang="scss" >
.container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.panel_container {
  padding: 10px;
}

.panel {
  margin: 0;
  padding: 10px;
  height: 246px;
  overflow: auto;
  box-sizing: border-box;
  border: 1px solid #E3E3E3;
  border-radius: 30px;
  line-height: normal;

}

.button-container {
  width: 8em;
}
</style>
