import DashboardLayout from '@/layout/dashboard/DashboardLayout.vue'
const Dashboard = () => import('@/pages/Dashboard/Dashboard.vue')

export default {
  path: '/dashboard',
  name: 'Basic Dashboard',
  component: DashboardLayout,
  children: [
    {
      path: 'general',
      name: 'Basic Dashboard',
      component: Dashboard,
      props: true
    }
  ]
}
