import i18n from '../../i18n'
import { translateHandler } from '@/util/util'

const errorStatusCode = {
    400: i18n.t('It was not possible to process your request, please try again'),
    404: i18n.t('Your request has semantic errors. Please verify and resubmit'),
    409: i18n.t('Entity already exists'),
    422: i18n.t('Your request has semantic errors. Please verify and resubmit'),
    500: i18n.t('It was not possible to process your request, please try again'),
    501: i18n.t('It was not possible to process your request, please try again'),
    502: i18n.t('It was not possible to process your request, please try again')
  }
  
  const errorStrings = {
    'about:blank': i18n.t('about:blank_error_message')
  }

export default {
    setError(state, error) {
        try {
            console.log(error);
            if (!error.response || !error.response.status) {
                if (!error.status || !errorStatusCode[error.status]) {
                    if (errorStrings[error]) {
                        state.lastError = errorStrings[error]
                    } else {
                        state.lastError = error
                    }
                } else {
                    state.lastError = errorStatusCode[error.status]
                }
            }
            else if (![401, 403].includes(error.response.status)) {
                if (!!errorStatusCode[error.response.status]) {
                    state.lastError = errorStatusCode[error.response.status]
                } else
                    if (error.response.data) {
                        if (error.response.data.error)
                            state.lastError = error.response.data.error
                        else if (error.response.data.title)
                            state.lastError = error.response.data.title
                        else if (error.response.statusText)
                            state.lastError = error.response.statusText
                        else
                            state.lastError = error.response.data
                    }
                    else
                        state.lastError = error               
            } 
            else
                state.lastError = null
        } catch (err) {
            state.lastError = error
        }
    },   
    setErrorAll(state, error) {
        try {
            if (error.response.data && error.response.data.error)
                state.lastError = translateHandler(error.response.data.error, "_", " ")
            else
                state.lastError = translateHandler(error, "_", " ")
        }
        catch (err) {
            state.lastError = translateHandler(error, "_", " ")
        }
    },
    removeError(state) {
        state.lastError = null
    },
    setSuccess(state, success) {
        state.success = success;
    },
    removeSuccess(state) {
        state.success = null;
    },
}