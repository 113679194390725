import { Certificate } from '@fidm/x509'

export const parseStringCertificate = stringCert => {
    const ed25519Cert = Certificate.fromPEM('-----BEGIN CERTIFICATE-----' + stringCert.split('-----BEGIN CERTIFICATE-----')[1].split('-----END CERTIFICATE-----')[0].split(' ').join('\n') + '-----END CERTIFICATE-----');
    
    let cert = {
        common_name: ed25519Cert.issuer && ed25519Cert.issuer.attributes ? formatCommonName(ed25519Cert.issuer.attributes) : '',
        valid_from: ed25519Cert.validFrom ? ed25519Cert.validFrom.toUTCString() : '',
        valid_to: ed25519Cert.validTo ? ed25519Cert.validTo.toUTCString() : '',
        serial_number: ed25519Cert.serialNumber ? ed25519Cert.serialNumber : '',
        issuer: ed25519Cert.issuer && ed25519Cert.issuer.attributes ? formatDistinguishedName(ed25519Cert.issuer.attributes) : '',
        fingerprint: ed25519Cert.subjectKeyIdentifier ? ed25519Cert.subjectKeyIdentifier : ''
    };

    return cert;
}


const formatDistinguishedName = function (components) {
    try {
        var nameMap = {
            '2.5.4.6': 'C',
            '2.5.4.10': 'O',
            '2.5.4.11': 'OU',
            '2.5.4.3': 'CN',
            '2.5.4.7': 'L',
            '2.5.4.8': 'S',
            '2.5.4.12': 'T',
            '2.5.4.42': 'GN',
            '2.5.4.43': 'I',
            '2.5.4.4': 'SN',
            '1.2.840.113549.1.9.1': 'E-mail'
        };
        return components.filter(component => nameMap[component.oid]).map(function (component) { return nameMap[component.oid] + "=" + component.value; }).join(',');
    } catch (error) {
        console.log(error);
        return '';
    }
};

const formatCommonName = function (components) {
    try {
        return components.filter(component => component.oid == '2.5.4.3')[0].value;
    } catch (error) {
        console.log(error);
        return '';
    }
};