/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'autonotices-redirection': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g _fill="none" _stroke="#383132" stroke-width="2" stroke-miterlimit="10"><path pid="0" d="M18.6 9.9c-1.3-3.7-5.4-5.6-9-4.3-2 .7-3.5 2.3-4.2 4.1"/><path pid="1" d="M20.1 6l-1.2 4.4-4.3-1.2M5.4 14.1c1.3 3.7 5.4 5.6 9 4.3 2-.7 3.5-2.3 4.2-4.1"/><path pid="2" d="M3.9 18l1.2-4.4 4.3 1.2"/></g>'
  }
})
