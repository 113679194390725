export default {
    setHostStatus(state, host_status) {
      state.host_status = host_status;
    },
    setHostAndProcessStatus(state, host_and_process_status) {
      state.host_and_process_status = host_and_process_status;
    },
    setHostAndProcessPerformance(state, host_and_process_performance) {
      state.host_and_process_performance = host_and_process_performance;
    },
}