export const ispProviderDBActive = (state) => state.isp.providerDBActive;

export const entProviderList = (state) => state.ent.providerList;

export const ispProviderList = (state) => state.isp.providerList;

export const resProviderList = (state) => state.res.providerList;

export const entProviderDetails = (state) => state.ent.providerDetails;

export const ispProviderDetails = (state) => state.isp.providerDetails;

export const resProviderDetails = (state) => state.res.providerDetails;

export const activeProvider = (state) => state.activeProvider;

export const defaultProvider = (state) => state.defaultProvider;

export const resSystemSsoProviderEnabled = (state) => state.res.systemSsoProviderEnabled;

export const entSystemSsoProviderEnabled = (state) => state.ent.systemSsoProviderEnabled;

export const entPasswordStrengthSettings = (state, getters) => {
  const { password_complexity } = getters.entProviderDetails;

  return {
    enabled: getters.entProviderDetails.enabled || false,
    minLength: password_complexity.minimum_password_length,
    minLowercase: password_complexity.minimum_lowercase_required || 0,
    minUppercase: password_complexity.minimum_uppercase_required || 0,
    minNumeric: password_complexity.minimum_number_digits_required || 0,
    minSymbol: password_complexity.minimum_symbols_required || 0,
    minSymbolCharsInMiddle: password_complexity.minimum_middle_symbols_required || 0,
    minNumCharsInMiddle: password_complexity.minimum_middle_number_digits_required || 0,
    checkSeqNumbers: password_complexity.check_sequential_numbers || false,
    checkSeqLetters: password_complexity.check_sequential_letters || false,
    checkKeyboardPattern: password_complexity.check_keyboard_pattern || false,
    checkSeqRepeated: password_complexity.check_repeated_sequence || false,
    checkSeqMirrored: password_complexity.check_mirrored_sequence || false,
  }
};

export const ispPasswordStrengthSettings = (state, getters) => {
  const { password_complexity } = getters.ispProviderDetails;

  return {
    enabled: getters.ispProviderDetails.enabled || false,
    minLength: password_complexity.minimum_password_length || 0,
    minLowercase: password_complexity.minimum_lowercase_required || 0,
    minUppercase: password_complexity.minimum_uppercase_required || 0,
    minNumeric: password_complexity.minimum_number_digits_required || 0,
    minSymbol: password_complexity.minimum_symbols_required || 0,
    minNumCharsInMiddle: password_complexity.minimum_middle_symbols_required || 0,
    minSymbolCharsInMiddle: password_complexity.minimum_middle_number_digits_required || 0,
    checkSeqNumbers: password_complexity.check_sequential_numbers || false,
    checkSeqLetters: password_complexity.check_sequential_letters || false,
    checkKeyboardPattern: password_complexity.check_keyboard_pattern || false,
    checkSeqRepeated: password_complexity.check_repeated_sequence || false,
    checkSeqMirrored: password_complexity.check_mirrored_sequence || false,
  }
};

export const resPasswordStrengthSettings = (state, getters) => {
  const { password_complexity } = getters.resProviderDetails;

  return {
    enabled: getters.resProviderDetails.enabled || false,
    minLength: password_complexity.minimum_password_length,
    minLowercase: password_complexity.minimum_lowercase_required || 0,
    minUppercase: password_complexity.minimum_uppercase_required || 0,
    minNumeric: password_complexity.minimum_number_digits_required || 0,
    minSymbol: password_complexity.minimum_symbols_required || 0,
    minSymbolCharsInMiddle: password_complexity.minimum_middle_symbols_required || 0,
    minNumCharsInMiddle: password_complexity.minimum_middle_number_digits_required || 0,
    checkSeqNumbers: password_complexity.check_sequential_numbers || false,
    checkSeqLetters: password_complexity.check_sequential_letters || false,
    checkKeyboardPattern: password_complexity.check_keyboard_pattern || false,
    checkSeqRepeated: password_complexity.check_repeated_sequence || false,
    checkSeqMirrored: password_complexity.check_mirrored_sequence || false,
  }
};

