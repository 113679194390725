import _ from 'lodash';

export default {
    getContentFilter: (state) => () => {
      return state.contentFilterStrategy !== undefined ? state.contentFilterStrategy : {};
    },
    getContentFilterRules(state) {
      return state.rules;
    },
    getContentFilterStrategy(state, getters, rootState, rootGetters) {
      return getters.getContentFilter;
    },
    isDebug(state, getters, rootState, rootGetters) {
      return rootGetters['settings/getDebug']
    },
    getRuleById: (state, getters) => (ruleId) => {
      let rule = state.rules.filter(rule => "" + rule.rule_name === "" + ruleId);
      if (rule && rule.length > 0)
        return _.cloneDeep(rule[0]);
      else
        return null;
    },
    serviceConfiguration: (state) => state.serviceConfiguration,
    blockingPages: (state) => state.blockingPages,
  };