export default {
    setUsernameReset(state, username) {
        state.username = username
    },
    setRecoveryInfo(state, recoveryInfo) {
        state.recoveryInfo = recoveryInfo
    },
    setRecoveryMethod(state, recovery_method) {
        state.recovery_method = recovery_method
    },
    setIdentityData(state, identity_data) {
        state.identity_data = identity_data
    },
    setRecoveryData(state, recovery_data) {
        state.recovery_data = recovery_data
    },
    setNewPassword(state, new_password) {
        state.new_password = new_password
    },

    setRecoveryErrorMessage(state, recoveryErrorMessage) {
        state.recoveryErrorMessage = recoveryErrorMessage
    },
    setPrevPage(state, prevPage) {
        state.prevPage = prevPage
    },
};