/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'alerts-configuration': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path pid="0" d="M18.545 29.09v-1.454h-5.818v1.455a2.91 2.91 0 005.818 0zM8.364 23.274h14.545V13.09a7.273 7.273 0 10-14.545 0v10.182zM18.545 2.909v.422a10.18 10.18 0 017.273 9.76v10.182h1.455v2.909H4v-2.91h1.455V13.092a10.18 10.18 0 017.272-9.76v-.422a2.91 2.91 0 015.818 0z" _fill="#000" fill-rule="evenodd"/>'
  }
})
