/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'item-dnssecure': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12.4 17.9c0-1.7.8-3.3 2.1-4.3 0 0 1.5-1.1 3.2-1.1.6 0 1.1.1 1.7.3.3-.9.4-1.8.4-2.8 0-4.9-3.9-8.8-8.8-8.8S2.2 5.1 2.2 10s3.9 8.8 8.8 8.8c.5 0 1 0 1.4-.1v-.8zm5.5-9.7c.1.6.2 1.2.2 1.8s-.1 1.2-.2 1.8h-3c.1-.6.1-1.2.1-1.8s0-1.2-.1-1.8h3zm-.7-1.7h-2.6c-.3-1.1-.7-2.2-1.2-3.1 1.6.5 2.9 1.6 3.8 3.1zM11.1 3c.7 1.1 1.3 2.2 1.7 3.5H9.4c.4-1.3 1-2.5 1.7-3.5zm-6.8 8.8c-.2-.6-.3-1.2-.3-1.8s.1-1.2.2-1.8h3c0 .6-.1 1.2-.1 1.8s.1 1.2.1 1.8H4.3zm.7 1.7h2.6c.3 1.1.7 2.2 1.2 3.1-1.6-.5-3-1.6-3.8-3.1zm2.6-7H5C5.8 5 7.2 3.9 8.8 3.3c-.5 1-.9 2.1-1.2 3.2zM8.9 10c0-.6.1-1.2.1-1.8h4.1c.1.6.1 1.2.1 1.8s-.1 1.2-.1 1.8H9c-.1-.6-.1-1.2-.1-1.8zm.5 3.5h3.4c-.4 1.3-1 2.4-1.7 3.5-.7-1-1.3-2.2-1.7-3.5z"/><path pid="1" d="M18.3 13.7c-2.5 0-4.6 2-4.6 4.6s2 4.6 4.6 4.6 4.6-2 4.6-4.6-2-4.6-4.6-4.6zm1.7 7.1h-3.2c-.4 0-.6-.3-.6-.6v-2.6c0-.4.3-.6.6-.6v-.3c0-.9.7-1.6 1.6-1.6.9 0 1.6.7 1.6 1.6v.3c.4 0 .6.3.6.6v2.6s.1.6-.6.6z"/><path pid="2" d="M18.3 15.7c-.5 0-.9.5-.9.9v.4h1.9v-.3c0-.5-.4-1-1-1z"/>'
  }
})
