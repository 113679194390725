/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'item-backups': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path pid="0" d="M27.636 3A4.363 4.363 0 0132 7.364v17.454a4.364 4.364 0 01-4.364 4.364H4.364A4.364 4.364 0 010 24.818V7.364A4.363 4.363 0 014.364 3h23.272zm1.455 14.546H2.909v7.272c0 .803.652 1.455 1.455 1.455h23.272c.805 0 1.455-.652 1.455-1.455v-7.272zM8.727 20.455v2.909H5.818v-2.91h2.91zm17.455 0v2.909H11.636v-2.91h14.546zm1.454-14.546H4.364c-.803 0-1.455.652-1.455 1.455v7.273h26.182V7.364c0-.803-.65-1.455-1.455-1.455zM8.727 8.82v2.908H5.818V8.818h2.91zm17.455 0v2.908H11.636V8.818h14.546z" _fill="#000" fill-rule="evenodd"/>'
  }
})
