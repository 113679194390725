<template>
  <div class="bootstrap-switch bootstrap-switch-wrapper bootstrap-switch-animate" :class="switchClass">
    <!--<div class="bootstrap-switch-container" :class="{ disabled }" @click="triggerToggle()">-->
    <div class="bootstrap-switch-container" @click="triggerToggle()">
      <span class="bootstrap-switch-handle-on" :class="{ [`bootstrap-switch-${color}`]: color }">
        <slot name="on">
            {{onText}}
        </slot>
      </span>
      <span class="bootstrap-switch-label"></span>
      <span class="bootstrap-switch-handle-off bootstrap-switch-default">
        <slot name="off">
            {{offText}}
        </slot>
      </span>
    </div>
  </div>
</template>

<script>
import permissions from '@/mixins/permissions'
import { mapState, mapMutations } from 'vuex';

export default {
  name: 'n-switch',
  mixins: [permissions],
  props: {
    value: [Array, Boolean],
    onText: String,
    offText: String,
    color: String,
    service: {
      type: String, 
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState('settings',['checkedProducts']),

    switchClass() {
      //return this.permission_granted ? `bootstrap-switch-${!this.model ? 'off' : 'on'}` : `bootstrap-switch-${!this.model ? 'off' : 'on'} bootstrap-switch-disabled`;
      return this.permission_granted && !this.disabled ? `bootstrap-switch-${!this.model ? 'off' : 'on'}` : `bootstrap-switch-${!this.model ? 'off' : 'on'} bootstrap-switch-disabled`;
    },

    model: {
      get() {
      // TODO: VLAD temporary solution
      //return !this.checkedProducts.CyberBullyingEndpoint && (this.service === 'anti_bullying' || this.service === 'location_management');
        return this.value;
      },
      set(value) {
        if(! this.disabled && this.permission_granted) {
          this.$emit('input', value);
        }
      }
    },
  },
  methods: {
    triggerToggle() {
      if(! this.disabled && this.permission_granted) {
        this.model = !this.model;
      }
    }
  }
};
</script>

<style lang="scss">
  .bootstrap-switch-container.disabled {
    span.bootstrap-switch-label {
      background-color: #a4a4a4;
    }
    span.bootstrap-switch-handle-on {
      background-color: #d7d7d7;
    }
  }
</style>
