<template>
  <div class="tags-container">
    <template v-if="Array.isArray(row[column.prop])">
      <template v-if="row[column.prop].length === 0">
        <el-tag class="default-tag">{{ t('any') }}</el-tag>
      </template>
      <template v-else v-for="(tag, index) in row[column.prop]">
        <el-tag
          :class="(tag.type || 'default') + (tag.type === 'ip' ? ( ipv4RegExp.test(tag.value) ? '-4' : '-6' ) : '') + '-tag'"
          :key="index" v-if="index < 4">
          {{ tag.hasOwnProperty('value') ? t(tag.value) : t(tag) }}
        </el-tag>
      </template>
      <template v-if="row[column.prop].length > 4">
        <br>
        <el-tooltip
          class="item" effect="dark" placement="top"
          :content="row[column.prop].map(tag => tag.hasOwnProperty('value') ? t(tag.value) : t(tag)).join(', ')"
        >
          <el-button class="more-tags">...</el-button>
        </el-tooltip>
      </template>
    </template>
    <template v-else-if="column.prop === 'triggers'">
      <div v-if="row[column.prop] !== null">
        <div class="d-flex flex-column justify-content-center">
          <span class="text-primary">
            {{row[column.prop].frequency.title}}
          </span>
          <span>
            {{row[column.prop].intervals.first}}
          </span>
          <span>
            {{row[column.prop].intervals.other && row[column.prop].intervals.other.match(/(\d+)/) ? t(row[column.prop].intervals.other.slice(0,(row[column.prop].intervals.other.indexOf(row[column.prop].intervals.other.match(/(\d+)/)[0]) - 1))) + " " + row[column.prop].intervals.other.match(/(\d+)/)[0] + " " + t(row[column.prop].intervals.other.slice((row[column.prop].intervals.other.indexOf(row[column.prop].intervals.other.match(/(\d+)/)[0]) + 2), row[column.prop].intervals.other.length)) : t(row[column.prop].intervals.other)}}
          </span>
        </div>
        </div>
      <div v-else-if="row[column.prop] === null">
          {{t('All')}}
      </div>
    </template>    
    <el-tag class="default-tag" v-else>
      <template v-if="column.prop === 'time_limit'">
       {{!row[column.prop].type ? t('any') : row[column.prop].value + " " + t(row[column.prop].type) }}
      </template>
      <template v-else-if="typeof row[column.prop] === 'object'">
        <span class="nowrap-tag">{{ t(row[column.prop].type) }} </span>
        <div class="nowrap-tag"><span v-if="row[column.prop].hasOwnProperty('value')">{{ row[column.prop].value }}</span></div>
      </template>
      <template v-else>
       {{ t(row[column.prop]) }}
      </template>
    </el-tag>
  </div>
</template>

<script>
  import { Tag, Button } from "element-ui";

  export default {
    name: 'tags-formatter',
    components: {
      'el-tag': Tag,
      'el-button': Button
    },
    props: {
      row: {
        type: Object,
        default: () => {}
      },
      column: {
        type: Object,
        default: () => {}
      }
    },
    data() {
      return {
        ipv4RegExp: new RegExp('^([0-9]{1,3}\.){3}[0-9]{1,3}(\/([0-9]|[1-2][0-9]|3[0-2]))?$'),
        ipv6RegExp: new RegExp('^s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3}))|:)))(%.+)?s*(\/([0-9]|[1-9][0-9]|1[0-1][0-9]|12[0-8]))?$'),
      }
    }
  }
</script>

<style scoped lang=scss>
  .more-tags {
    padding: 0.2em 0.5em;
  }
  .tags-container {
    text-align: center;
  }
  .default-tag {
    background-color: #cfcfcf;
    border-color: #a7a7a8;
    color: #444444;
    white-space: normal;
    overflow-wrap: anywhere;
    height: auto;
  }
  .internal-tag {
    font-size: 14px !important;
    border-width: 4px;
    background-color: #ffffff;
    border-color: #f96332;
    color: #444444;
    white-space: normal;
    overflow-wrap: anywhere;
    height: auto;
  }
  .custom-tag {
    font-size: 14px !important;
    border-width: 4px;
    background-color: #ffffff;
    border-color: #f9c432;
    color: #444444;
    white-space: normal;
    overflow-wrap: anywhere;
    height: auto;
  }
  .regex-tag,.account-tag {
    background-color: #cee5ff;
    color: #2c6fb4;
    border: 1px solid #85b6e7;
    white-space: normal;
    overflow-wrap: anywhere;
    height: auto;
  }
  .included-tag,.mac-tag {
    background-color: #dceed2;
    border-color: #a1c191;
    color: #51a427;
    white-space: normal;
    overflow-wrap: anywhere;
    height: auto;
  }
  .excluded-tag,.ip-4-tag {
    background-color: #f0d9d9;
    border-color: #d38181;
    color: #c15050;
    white-space: normal;
    overflow-wrap: anywhere;
    height: auto;
  }
  .ip-6-tag {
    background-color: #fae6c8;
    border-color: #d1b080;
    color: #c7892c;
    white-space: normal;
    overflow-wrap: anywhere;
    height: auto;
  }
  .service-tag {
    color: #5b136d;
    background-color: #d3aedb;
    border-color: #7e4a8b;
    white-space: normal;
    overflow-wrap: anywhere;
    height: auto;
  }
  .device-tag {
    color: #2c6fb4;
    background-color: #cee5ff;
    border-color: #85b6e7;
    white-space: normal;
    overflow-wrap: anywhere;
    height: auto;
  }
  .nowrap-tag {
    white-space: nowrap;
    overflow: hidden;
  }
</style>