import DashboardLayout from "@/layout/dashboard/DashboardLayout.vue";

import { resolveString2Bool } from '../utils';

const ReporterTemplates = () => import(
  /* webpackChunkName: "reporter" */
  "@/pages/Reporter/ReporterTemplates.vue"
);

const ReporterTemplatesEdit  = () => import(
  /* webpackChunkName: "reporter" */
  "@/pages/Reporter/ReporterTemplatesEdit.vue"
);

const ReporterTemplatesView = () => import(
  /* webpackChunkName: "reporter" */
  "@/pages/Reporter/ReporterTemplatesView.vue"
);

const ReporterSchedule = () => import(
  /* webpackChunkName: "reporter" */
  "@/pages/Reporter/ReporterSchedule.vue"
);

export default {
  path: '/reporter',
  redirect: 'reporter/templates',
  name: 'Reporter',
  component: DashboardLayout,
  children: [
    {
      path: 'templates',
      name: 'Reporter / Report Templates',
      component: ReporterTemplates,
    },
    {
      path: 'templates/new',
      name: 'Reporter / Report Templates / New',
      component: ReporterTemplatesEdit,
    },
    {
      path: 'templates/edit/:id',
      name: 'Reporter / Report Templates / Edit',
      component: ReporterTemplatesEdit,
      props: (route) => ({ templateId: route.params.id }),
    },
    {
      path: 'templates/view',
      name: 'Reporter / Reporter Templates / View',
      component: ReporterTemplatesView ,
      props: (route) => ({
        name: route.query.name,
        GUIModeId: route.query.GUIModeId,
        type: route.query.type,
        group: typeof route.query.group === 'string'
          ? [route.query.group] : route.query.group,
        limit: typeof route.query.limit === 'string'
          ? parseInt(route.query.limit, 10) : route.query.limit,
        order: resolveString2Bool(route.query.order),
        lang: route.query.lang,
        where: route.query.where,
        date_interval: route.query.date_interval,
        ...(route.query.isHistoric && {
          isHistoric: resolveString2Bool(route.query.isHistoric),
        }),
        ...(route.query.adminReportId && {
          adminReportId: route.query.adminReportId,
        }),
        ...(route.query.reportName && {
          reportName: route.query.reportName,
        }),
        percentage: resolveString2Bool(route.query.percentage),
        graph_style: route.query.style
      }),
    },
    {
      path: 'templates/schedule/:id',
      name: 'Reporter / Report Templates / Schedule',
      component: ReporterSchedule,
      props: (route) => ({ templateId: route.params.id }),
    },
  ]
};
