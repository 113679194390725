import _ from 'lodash';
import axios from "axios";
import i18n from '../../../i18n';
import { createUser, mockUser } from './mockData';

export default {
  getAccountUsers({ commit, state, rootState, dispatch }, id) {
    if (rootState.settings.debug) {
      return new Promise((resolve, reject) => {
        let user = mockUser()
        resolve([user])
      })
    }
    else {
      return new Promise((resolve, reject) => {
        axios.get('/accounts/' + encodeURIComponent(id) + '/users')
          .then(res => {
            if (res && res.data && res.data.items) {
              state.account_users.clear()
              res.data.items.forEach(item => {
                const user = createUser(item)
                commit('addAccountUser', user)
              });
              resolve(state.account_users)
            }
            else {
              reject()
            }
          })
          .catch(error => {
            commit('setError', error, { root: true });
            reject()
          })
      });
    }
  },
  getAccountUsersByQuery({ commit, state, rootState, dispatch }, {id: id, query: query}) {
    if (rootState.settings.debug) {
      return new Promise((resolve, reject) => {
        let user = mockUser()
        resolve([user])
      })
    }
    else {
      let config = { params: query }
      return new Promise((resolve, reject) => {
        axios.get('/accounts/' + encodeURIComponent(id) + '/users', config)
          .then(res => {
            if (res && res.data && res.data.items) {
              state.account_users.clear()
              res.data.items.forEach(item => {
                const user = createUser(item)
                commit('addAccountUser', user)
              });
              let ret = { items: state.account_users, query: res.data.query, self: query };
              resolve(ret);
            }
            else {
              reject()
            }
          })
          .catch(error => {
            commit('setError', error, { root: true });
            reject()
          })
      });
    }
  },
  removeAccountUser({ commit, state, rootState, dispatch }, data) {
    if (rootState.settings.debug) {
      return new Promise((resolve, reject) => {
        commit('removeAccountUser', data.id)
        resolve([])
      });
    }
    else {
      return new Promise((resolve, reject) => {
        axios.delete('/accounts/' + encodeURIComponent(data.account_id) + '/users/' + encodeURIComponent(data.id))
          .then(res => {
            commit('removeAccountUser', data.id);
            resolve(state.account_users)
          })
          .catch(error => {
            commit('setError', error, { root: true });
            reject()
          })
      });
    }
  },
  removeUser({ commit, state, rootState, dispatch }, data) {
    if (rootState.settings.debug) {
      return new Promise((resolve, reject) => {
        commit('removeUser', data.id)
        resolve([])
      });
    }
    else {
      return new Promise((resolve, reject) => {
        axios.delete('/accounts/' + encodeURIComponent(data.account_id) + '/users/' + encodeURIComponent(data.id))
          .then(res => {
            commit('removeUser', data.id);
            resolve(state.users)
          })
          .catch(error => {
            commit('setError', error, { root: true });
            reject()
          })
      });
    }
  },
  getUsers({ commit, state, rootState, dispatch }, { wait: wait, query: query }) {
    if (rootState.settings.debug) {
      return new Promise((resolve, reject) => {
        let user = mockUser()
        resolve([user])
      })
    }
    else {
      let config = { params: query }
      if (wait)
        config.headers = { 'x-wait-on-loading': wait }

      return new Promise((resolve, reject) => {
        axios.get('/users', config)
          .then(res => {
            if (res && res.data && res.data.items) {
              state.users.clear()
              res.data.items.forEach(item => {
                const user = createUser(item)
                commit('addUser', user)
              });
              let ret = { items: state.users, query: res.data.query, self: query }
              resolve(ret)
            }
            else {
              reject()
            }
          })
          .catch(error => {
            commit('setError', error, { root: true });
            reject()
          })
      });

    }
  },
  updateAccountUser({ commit, state, dispatch }, update) {
    let prev = null
    let user = state.account_users.get(update.id)
    if (user) {
      prev = _.cloneDeep(user)
    }
    if (update.data.user_id)
      delete update.data.user_id
    commit('setAccountUser', { id: update.id, data: update.data });

    axios.patch('/accounts/' + encodeURIComponent(update.account_id) + '/users/' + encodeURIComponent(update.id), update.data)
      .then(() => {
        commit('setSuccess', i18n.t('save_success'), { root: true });
      })
      .catch(error => {
        commit('setError', error, { root: true });
        commit('setAccountUser', { id: update.id, data: prev });
      })
  },
  createAccountUser({ commit, state, dispatch }, data) {
    return new Promise((resolve, reject) => {
      data.data.account_id = data.account_id
      axios.post('/accounts/' + encodeURIComponent(data.account_id) + '/users', data.data)
        .then(res => {
          if (res && res.data) {
            const item = res.data
            const user = createUser(item)

            commit('addAccountUser', user)
            commit('setSuccess', i18n.t('save_success'), { root: true });
            resolve(item.user_id)
          }
        })
        .catch(error => {
          if (error.response && error.response.data) {
            if (error.response.data.type && error.response.data.type === 'USER.LIMIT_EXCEEDED') {
              commit('setError', i18n.t('MAX_USERS_ALLOWED'), {root: true});
            }
            else {
              commit('setError', error, { root: true });
            }
          }
        })
    });
  },
  getAccountUser({ commit, state, rootState, dispatch }, data) {
    if (rootState.settings.debug) {
      return new Promise((resolve, reject) => {
        let user = mockUser(data)
        resolve(user)
      })
    }
    else {
      return new Promise((resolve, reject) => {
        axios.get('/accounts/' + encodeURIComponent(data.account_id) + '/users/' + encodeURIComponent(data.user_id))
          .then(res => {
            if (res && res.data) {
              const item = res.data;
              const user = createUser(item);

              commit('addAccountUser', user)
              resolve(user.data)
            }
            else {
              reject()
            }
          })
          .catch(error => {
            commit('setError', error, { root: true });
            reject()
          })
      });
    }
  },
}
