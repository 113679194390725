export const initialState = () => ({
  isp: {
    sessions_management: {
      access_token_timeout: 0,
      max_sessions: 0,
      password_policy: {
        lock_time: 0,
        max_incorrect_password_attempts: 0
      },
      password_rotation: {
        history_check_depth: 0,
        maximum_elapsed_time_without_change: 0,
        minimum_elapsed_time_to_change: 0,
        rotate_on_first_login: false
      },
      refresh_token_timeout: 0,
    },
  },
  ent: {
    sessions_management: {
      access_token_timeout: 0,
      max_sessions: 0,
      password_policy: {
        lock_time: 0,
        max_incorrect_password_attempts: 0
      },
      password_rotation: {
        history_check_depth: 0,
        maximum_elapsed_time_without_change: 0,
        minimum_elapsed_time_to_change: 0,
        rotate_on_first_login: false
      },
      refresh_token_timeout: 0,
    },
  },
  res: {
    sessions_management: {
      access_token_timeout: 0,
      max_sessions: 0,
      password_policy: {
        lock_time: 0,
        max_incorrect_password_attempts: 0
      },
      password_rotation: {
        history_check_depth: 0,
        maximum_elapsed_time_without_change: 0,
        minimum_elapsed_time_to_change: 0,
        rotate_on_first_login: false
      },
      refresh_token_timeout: 0,
    },
  },
});

export default initialState();
