<template functional>
    <div>
        <template v-if="props.row[props.column.prop]">
            <i class="table-icon now-ui-icons ui-1_check icon icon-success"/>
        </template>
        <template v-else>
            <i class="table-icon now-ui-icons ui-1_simple-remove icon icon-danger"/>
        </template>
    </div>
</template>

<script>
  export default {
    name: 'yesnoicon-formatter',
  }
</script>

<style scoped lang=scss>
.table-icon{
    font-size: x-large;
    margin-left: 1em;
}
</style>