import _ from 'lodash'


// mixin which enables visibility of the container component by checking role permissions
export default {
  props: {
    visible_for_permissions: {
      type: Array,
      default: function () {
        return []
      }
    },
    operator: {
      type: String,
      default: function () {
        return 'AND'
      }
    },
  },
  computed: {
    permission_granted () {
      return this.checkRolePermissions(this.visible_for_permissions, this.operator)
    }
  },
  methods: {
    checkRolePermissions (visible_for_permissions, operator) {
      let arr_permissions, arr_forbiddens, obj, res, op;
      obj = visible_for_permissions
      arr_permissions = this.$store.getters['auth/userPermissions']
      arr_forbiddens = this.$store.getters['auth/userForbiddens']

      switch (operator) {
        case 'AND':
          op = true 
          break;
        case 'OR':
          op = false 
          break;
        default:
          op = true
          break;
      }
      res = op
      if (_.isEmpty(obj)) return true

      for (let index = 0; index < obj.length && res == op; index++) {
        res = _.some(arr_permissions, v => (
            (new RegExp("^" + v.path + "$").test(obj[index].path)) &&
            _.isEqual(_.intersection(v.actions, obj[index].actions).sort(), obj[index].actions) ? true : false)
        )
        if (res){
          res = !!!_.some(arr_forbiddens, v => (
            (new RegExp("^" + v.path + "$").test(obj[index].path)) &&
            _.isEqual(_.intersection(v.actions, obj[index].actions).sort(), obj[index].actions) ? true : false)
          )
        }
      }

      return res
    }
  }
}
