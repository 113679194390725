import Vue from 'vue';

import Config from '@/config'

// mixin which enables visibility of the container component by checking services and products for the current identity and deployment
export default {
    props: {
        visible_for_services: {
                type: [String, Array],
                default: function() { return []}
        },
        visible_for_products: {
                type: [String, Array],
                default: function() { return []}
        },
        visible_for_services_ns: {
            type: [String, Array],
            default: function() { return []}
        },
        visible_for_services_ds: {
            type: [String, Array],
            default: function() { return []}
        },
        visible_for_packages: {
            type: [String, Array],
            default: function() { return []}
        },
    },
    data () {
        return {
            available_services: Config.available_services,
            available_products: Config.available_products,
            available_services_ns: Config.available_services_ns,
            available_packages: Config.available_packages,
            available_services_ds: Config.available_services_ds
        }
    },
    computed: {
        make_visible() {
            return this.checkServices(this.visible_for_services) &&
                            this.checkProducts(this.visible_for_products) &&
                            this.checkServicesNS(this.visible_for_services_ns) &&
                            this.checkPackages(this.visible_for_packages) &&
                            this.checkServicesDS(this.visible_for_services_ds);
        },
        asm_s_parental_control(){
            return this.getService('parental_control')
        },
        asm_s_threat_protection(){
            return this.getService('threat_protection')
        },
        asm_s_ads_free(){
            return this.getService('ads_free')
        },
        asm_s_auto_notice(){
            return this.getService('auto_notice')
        },
        asm_s_antispam_out(){
            return this.getService('antispam_out')
        },
        asm_s_antispam_in(){
            return this.getService('antispam_in')
        },
        asm_s_firewall(){
            return this.getService('firewall')
        },
        asm_s_webproxy(){
            return this.getService('webproxy')
        },
        asm_s_wolf(){
            return this.getService('wolf')
        },

        asm_p_NetworkSecure(){
            return this.getProduct('NetworkSecure')
        },
        asm_p_RouterSecure(){
            return this.getProduct('RouterSecure')
        },
        asm_p_EndpointSecure(){
            return this.getProduct('EndpointSecure')
        },

        ns_s_AntiVirus(){
            return this.getService('AntiVirus')
        },
        ns_s_AntiPhishing(){
            return this.getService('AntiPhishing')
        },
        ns_s_AntiBotnet(){
            return this.getService('AntiBotnet')
        },
        ns_s_ContentFilter(){
            return this.getService('ContentFilter')
        },
        ns_s_AdsFree(){
            return this.getService('AdsFree')
        },
        ns_s_Firewall(){
            return this.getService('Firewall')
        },
        ns_s_SMTPFilterIn(){
            return this.getService('SMTPFilter.In')
        },
        ns_s_SMTPFilterOut(){
            return this.getService('SMTPFilter.Out')
        },
        ns_s_MailFilter(){
            return this.getService('MailFilter')
        },
        ns_s_IMAPFilter(){
            return this.getService('IMAPFilter')
        },
        ns_s_Activation(){
            return this.getService('Activation')
        },
        ns_s_WOLF(){
            return this.getService('WOLF')
        },
        ns_s_WebProxy(){
            return this.getService('WebProxy')
        },

    },
    methods: {
        getService(name){
            return this.$store.getters['settings/getServiceState'](name)
        },
        getServiceNS(name){
            if (this.$store.getters['networksecure/status/networkSecureSnifferMode']) {
                return (name == 'Firewall' || name == 'Activation' || name == 'AntiVirus')
                  ? false
                  : this.$store.getters['settings/getProductState']('NetworkSecure') && this.$store.getters['settings/getServiceStateNS'](name);
            } else {
                return this.$store.getters['settings/getProductState']('NetworkSecure') && this.$store.getters['settings/getServiceStateNS'](name)
            }
        },
        getPackage(name){
            return this.$store.getters['settings/getSubsystemsPackageState'](name)
        },
        getServiceDS(name){
            return this.$store.getters['settings/getProductState']('DNSecure') && this.$store.getters['settings/getServiceStateDS'](name)
        },
        getProduct(name){
            return this.$store.getters['settings/getProductState'](name)
        },

        isString (value) {
            return typeof value === 'string' || value instanceof String;
        },
        isArray (value) {
            return value && typeof value === 'object' && value.constructor === Array;
        },

        checkServices(visible_services){
            let vm = this;
            if (!!!visible_services)
                return true;

            let services = []
            if (this.isString(visible_services)){
                if (!!!visible_services)
                    services = this.available_services
                else if (vm.getService(visible_services))
                    services.push(visible_services)
            }
            else if (this.isArray(visible_services)){
                if ( visible_services.length == 0)
                    services = this.available_services
                else
                    //check if visible with given services
                    services = visible_services.filter(item => {
                                return vm.getService(item)
                    });
            }
            else{
                let tp = typeof visible_services
                console.error('checkServices(): incorrect type for \'visible_services\': found ' + tp +
                                ', expected [String, Array]')
            }

            return services.length > 0;
        },
        checkServicesNS(visible_services_ns){
            let vm = this;
            if (!!!visible_services_ns)
                return true;

            let services = []
            if (this.isString(visible_services_ns)){
                if (!!!visible_services_ns)
                    services = this.available_services_ns
                else if (vm.getServiceNS(visible_services_ns))
                    services.push(visible_services_ns)
            }
            else if (this.isArray(visible_services_ns)){
                if ( visible_services_ns.length == 0)
                    services = this.available_services_ns
                else
                    //check if visible with given services
                    services = visible_services_ns.filter(item => {
                                return vm.getServiceNS(item)
                    });
            }
            else{
                let tp = typeof visible_services_ns
                console.error('checkServicesNS(): incorrect type for \'visible_services_ns\': found ' + tp +
                                ', expected [String, Array]')
            }

            return services.length > 0;
        },
        checkServicesDS(visible_services_ds){
            let vm = this;
            if (!!!visible_services_ds)
                return true;

            let services = []
            if (this.isString(visible_services_ds)){
                if (!!!visible_services_ds)
                    services = this.available_services_ds
                else if (vm.getServiceDS(visible_services_ds))
                    services.push(visible_services_ds)
            }
            else if (this.isArray(visible_services_ds)){
                if ( visible_services_ds.length == 0)
                    services = this.available_services_ds
                else
                    //check if visible with given services
                    services = visible_services_ds.filter(item => {
                                return vm.getServiceDS(item)
                    });
            }
            else{
                let tp = typeof visible_services_ds
                console.error('checkServicesDS(): incorrect type for \'visible_services_ds\': found ' + tp +
                                ', expected [String, Array]')
            }

            return services.length > 0;
        },
        checkProducts(visible_products){
            let vm = this;
            if (!!!visible_products)
                return true;

            let products = []
            if (this.isString(visible_products)){
                if (!!!visible_products)
                    products = this.available_products
                else if (vm.getProduct(visible_products))
                    products.push(visible_products)
            }
            else if (this.isArray(visible_products)){
                if ( visible_products.length == 0)
                    products = this.available_products
                else
                    //check if visible with given services
                    products = visible_products.filter(item => {
                                return vm.getProduct(item)
                    });
            }
            else{
                let tp = typeof visible_products
                console.error('checkProducts(): incorrect type for \'visible_products\': found ' + tp +
                                ', expected [String, Array]')
            }

            return products.length > 0;
        },
        checkPackages(visible_packages){
            let vm = this;
            if (!!!visible_packages)
                return true;

            let packages = []
            if (this.isString(visible_packages)){
                if (!!!visible_packages)
                    packages = this.available_packages
                else if (vm.getPackage(visible_packages))
                    packages.push(visible_packages)
            }
            else if (this.isArray(visible_packages)){
                if ( visible_packages.length == 0)
                    packages = this.available_packages
                else
                    //check if visible with given packages
                    packages = visible_packages.filter(item => {
                                return vm.getPackage(item)
                    });
            }
            else{
                let tp = typeof visible_packages
                console.error('checkPackages(): incorrect type for \'visible_packages\': found ' + tp +
                                ', expected [String, Array]')
            }

            return packages.length > 0;
        }
    }
}

