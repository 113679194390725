/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'password-strength': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path pid="0" d="M15.666 7.112a1.778 1.778 0 100-3.557 1.778 1.778 0 000 3.557M21 5.334a5.335 5.335 0 01-3.556 5.029v14.302a7.111 7.111 0 005.333-6.887h3.556c0 5.205-3.755 9.651-8.889 10.519v1.925a1.778 1.778 0 01-3.555 0v-1.925A10.668 10.668 0 015 17.778h3.555a7.111 7.111 0 005.334 6.887V10.363a5.335 5.335 0 01-3.252-6.807A5.335 5.335 0 0121 5.334" _fill="#000" fill-rule="evenodd"/>'
  }
})
