export default {
    props: {
        check_sniffer_mode: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {}
    },
    computed: {
        sniffer_mode_enabled() {
            if (this.check_sniffer_mode) {
                return this.check();
            } else {
                return false
            }
        }
    },
    methods: {
        check(){
            return this.$store.getters['networksecure/status/networkSecureSnifferMode']
        }
    }
}

