export default {
  setDnscfRules(state, rules) {
    rules = rules.sort((a, b) => {
      return a.order > b.order ? 1 : -1;
    });
    state.rules = rules;
  },
  addDnscfRule(state, newRule) {
    state.rules.push(newRule);
  },
  setDnscf(state, dnscf) {
    state.dnscfStrategy = dnscf;
  },
}