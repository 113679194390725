<template functional>
  <span style="white-space: nowrap;">
    <i class="now-ui-icons ui-1_calendar-60"></i> {{ props.row[props.column.prop] }}
  </span>
</template>

<script>
export default {
    name: 'datetime-formatter',
}
</script>