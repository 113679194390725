/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'item-searchurl': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M16.3 14.6h-.9l-.3-.3c1.1-1.3 1.8-3 1.8-4.9 0-4.1-3.4-7.5-7.5-7.5S1.9 5.3 1.9 9.4s3.4 7.5 7.5 7.5c1.9 0 3.6-.7 4.9-1.8l.3.3v.9l5.8 5.8 1.7-1.7-5.8-5.8zm-6.9 0c-2.9 0-5.2-2.3-5.2-5.2s2.3-5.2 5.2-5.2 5.2 2.3 5.2 5.2-2.3 5.2-5.2 5.2z" _fill="#383132"/>'
  }
})
