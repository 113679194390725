/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'executed-instances': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path pid="0" d="M27.2 0A4.8 4.8 0 0132 4.8v22.4a4.8 4.8 0 01-4.8 4.8H4.8A4.8 4.8 0 010 27.2V4.8A4.8 4.8 0 014.8 0zm0 3.2H4.8a1.6 1.6 0 00-1.6 1.6v22.4a1.6 1.6 0 001.6 1.6h22.4a1.6 1.6 0 001.6-1.6V4.8a1.6 1.6 0 00-1.6-1.6zM24 16a1.6 1.6 0 011.6 1.6V24a1.6 1.6 0 01-1.6 1.6h-6.4A1.6 1.6 0 0116 24v-1.6h6.4V16zm-9.6-9.6A1.6 1.6 0 0116 8v1.6H9.6V16H8a1.6 1.6 0 01-1.6-1.6V8A1.6 1.6 0 018 6.4h6.4z" _fill="#000" fill-rule="evenodd"/>'
  }
})
