/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'allot': {
    width: 16,
    height: 16,
    viewBox: '0 0 40 20',
    data: '<g _fill="#fff"><path pid="0" d="M13.829 1.883h2.608v15.703H13.83zM17.842 1.883h2.609v15.703h-2.609zM23.862 12.15c0-1.811 1.203-3.02 2.809-3.02s2.809 1.209 2.809 3.02-1.204 3.02-2.81 3.02-2.808-1.207-2.808-3.02m-1.004-3.825c-1.003 1.007-1.605 2.416-1.605 4.027s.602 3.02 1.605 4.026 2.408 1.611 3.813 1.611c1.605 0 3.01-.604 4.013-1.61s1.605-2.416 1.605-4.027-.602-3.02-1.605-4.027-2.408-1.61-4.013-1.61c-1.405 0-2.81.604-3.813 1.61M7.208 14.97c-1.606 0-2.81-1.209-2.81-3.02s1.204-3.02 2.81-3.02 2.809 1.207 2.809 3.02-1.204 3.02-2.81 3.02m5.418 2.415V11.95c0-1.41-.602-2.818-1.605-3.825s-2.408-1.61-4.013-1.61-2.81.603-3.812 1.61-1.606 2.416-1.606 3.825.602 2.819 1.606 3.826 2.407 1.61 3.812 1.61c1.003 0 2.006-.201 2.81-.604h.2v.805h2.608zM32.69 9.533h1.003v7.852h2.609V9.533h2.006V7.117h-2.006V1.883h-2.609v5.234H32.69z"/></g>'
  }
})
