<template>
  <nav
    class="navbar navbar-expand-lg navbar-absolute"
    :class="{ 'bg-white': showMenu, 'navbar-transparent': !showMenu }"
  >
    <div class="container-fluid">
      <div class="navbar-wrapper">
        <div
          class="navbar-toggle d-inline"
          :class="{ toggled: $sidebar.showSidebar }"
        >
          <button
            type="button"
            class="navbar-toggler"
            aria-label="Navbar toggle button"
            @click="toggleSidebar"
          >
            <span class="navbar-toggler-bar bar1"></span>
            <span class="navbar-toggler-bar bar2"></span>
            <span class="navbar-toggler-bar bar3"></span>
          </button>
        </div>
        <a id="breadcrumb-path" class="navbar-brand">{{ t(routeName) }}</a>
      </div>
      <button
        class="navbar-toggler"
        type="button"
        @click="toggleMenu"
        data-toggle="collapse"
        data-target="#navigation"
        aria-controls="navigation-index"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-bar navbar-kebab"></span>
        <span class="navbar-toggler-bar navbar-kebab"></span>
        <span class="navbar-toggler-bar navbar-kebab"></span>
      </button>

      <collapse-transition>
        <div class="collapse navbar-collapse show" v-show="showMenu">
          <ul class="navbar-nav" :class="$rtl.isRTL ? 'mr-auto' : 'ml-auto'">
            <!--            <div class="search-bar input-group" @click="searchModalVisible = true">
              <input type="text" class="form-control" placeholder="Search...">
              <div class="input-group-addon"><i class="tim-icons icon-zoom-split"></i></div> 
              <button class="btn btn-link" id="search-button" data-toggle="modal" data-target="#searchModal">
                <i class="tim-icons icon-zoom-split"></i>
              </button>
            </div>
            <modal :show.sync="searchModalVisible"
                   class="modal-search"
                   id="searchModal"
                   :centered="false"
                   :show-close="true">
              <input slot="header" v-model="searchQuery" type="text" class="form-control" id="inlineFormInputGroup" placeholder="SEARCH">
            </modal>
            <base-dropdown tag="li"
                           :menu-on-right="!$rtl.isRTL"
                           title-tag="a" class="nav-item">
              <a slot="title" href="#" class="dropdown-toggle nav-link" data-toggle="dropdown" aria-expanded="true">
                <div class="notification d-none d-lg-block d-xl-block"></div>
                <i class="tim-icons icon-sound-wave"></i>
                <p class="d-lg-none">
                  New Notifications
                </p>
              </a>
              <li class="nav-link">
                <a href="#" class="nav-item dropdown-item">Mike John responded to your email</a>
              </li>
              <li class="nav-link">
                <a href="#" class="nav-item dropdown-item">You have 5 more tasks</a>
              </li>
              <li class="nav-link">
                <a href="#" class="nav-item dropdown-item">Your friend Michael is in town</a>
              </li>
              <li class="nav-link">
                <a href="#" class="nav-item dropdown-item">Another notification</a>
              </li>
              <li class="nav-link">
                <a href="#" class="nav-item dropdown-item">Another one</a>
              </li>
            </base-dropdown>
-->
            <li class="nav-item">
              <b-nav-item-dropdown right>
                <template slot="button-content">
                  <i
                    class="now-ui-icons location_world"
                    type="neutral"
                    style="font-size: 24px; opacity: 1"
                  ></i>
                  <b class="caret d-none d-lg-block d-xl-block"></b>
                </template>

                <b-dropdown-item
                  v-for="lang in languages"
                  :active="$store.getters['settings/getLanguage'] == lang.id"
                  :key="lang.id"
                  @click="setLanguage(lang.id)"
                  >{{ lang.name }}
                </b-dropdown-item>
              </b-nav-item-dropdown>
            </li>
            <li class="nav-item">
              <svgicon
                original
                class="icon mr-1"
                icon="sun"
                width="24"
                height="24"
              />
              <n-switch key="darkmode" v-model="darkmode" />
              <svgicon
                original
                class="icon ml-1"
                icon="moon"
                width="24"
                height="24"
              />
            </li>
            <base-dropdown
              tag="li"
              :menu-on-right="!$rtl.isRTL"
              title-tag="a"
              class="nav-item"
              menu-classes="dropdown-navbar"
            >
              <a
                slot="title"
                class="dropdown-toggle nav-link"
                data-toggle="dropdown"
                aria-expanded="true"
                id="Admin-icon"
                @click.prevent
              >
                <div class="photo">
                  <avatar
                    :fullname="this.$store.getters['auth/name']"
                    zoom-on-hover
                    shine-on-hover
                    :size="30"
                  />
                </div>
                <b class="caret d-none d-lg-block d-xl-block"></b>
                <p class="d-lg-none">
                  {{ this.$store.getters["auth/name"] }}
                </p>
              </a>
              <li class="nav-link" id="profile-menu">
                <!--<router-link 
                  :to="'/general_management/administrators/edit/' + encodeURIComponent(this.$store.getters.manager)" 
                  class="nav-item dropdown-item">
                  Profile
                </router-link>-->
                <router-link
                  :to="
                    '/profile/edit/' +
                    encodeURIComponent(this.$store.getters['auth/manager'])
                  "
                  class="nav-item dropdown-item"
                >
                  {{ t("Profile") }}
                </router-link>
              </li>
              <li class="nav-link" id="about-menu">
                <router-link
                  :to="'/general_management/About'"
                  class="nav-item dropdown-item"
                >
                  {{t("About")}}
                </router-link>
              </li>
              <!-- <div class="dropdown-divider"></div> -->
              <li class="nav-link">
                <a href="#/login?out=true" class="nav-item dropdown-item">{{
                  t("Logout")
                }}</a>
              </li>
            </base-dropdown>
          </ul>
        </div>
      </collapse-transition>
    </div>
  </nav>
</template>
<script>
import { CollapseTransition } from "vue2-transitions";
import Modal from "@/components/Modal";
import { Switch } from "@/components";
import { mapFields } from "vuex-map-fields";
import es from "../../locales-validation/es";
import en from "../../locales-validation/en";

export default {
  components: {
    CollapseTransition,
    Modal,
    [Switch.name]: Switch,
  },
  computed: {
    darkmode: {
      get() {
        return this.$store.state.settings.darkmode;
      },
      set(value) {
        this.$store.dispatch("settings/updateDarkMode", value);
      },
    },
    routeName() {
      let { name } = this.$route;
      if(typeof name === 'function'){
        const serviceName = this.$route.params.serviceName.split("_").map(word => this.capitalizeFirstLetter(word)).join(" ")
        name = name(serviceName)
      }
      return this.capitalizeFirstLetter(name);
    },
    isRTL() {
      return this.$rtl.isRTL;
    },
    languages() {
      return this.$store.getters["settings/getLanguages"];
    },
  },
  data() {
    return {
      activeNotifications: false,
      showMenu: false,
      searchModalVisible: false,
      searchQuery: "",
      es: es,
      en: en,
    };
  },
  methods: {
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications;
    },
    closeDropDown() {
      this.activeNotifications = false;
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
    setLanguage(language) {
      try {
        this.$validator.localize(language, eval("this." + language));
      } catch (error) {
        this.$validator.localize("en", en);
      }
      this.$store.dispatch("settings/setLanguage", language);
    },
  },
};
</script>
<style>
</style>
