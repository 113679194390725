import axios from 'axios';

export default {
    namespaced: true,
    state: {
        schema: null,
        schemas: {},
        validation_errors: []
    },
    mutations: {
        setSchema(state, schema) {
            state.schemas = {
                ...state.schemas,
                [schema.key]: _.cloneDeep(schema.value)
            }
        },
        currentSchema(state, value) {
            state.schema = value;
        },
        set_error(state, value) {
            if (!state.validation_errors.includes(value)) {
                state.validation_errors.push(value);
            }
        },
        reset_error(state, value) {
            state.validation_errors = state.validation_errors.filter(error => error !== value);
        },
        reset_errors(state) {
            state.validation_errors.length = 0;
        },
    },
    actions: {
        async setSchema({ commit, state }, value) {
            commit('currentSchema', value);

            if (!state.schemas || !state.schemas[value]) {
                try {
                    let schema = await axios.get(`/schemas/${value}`);

                    if ([200, 204].includes(schema.status)) {
                        commit('setSchema', { key: value, value: schema.data });
                    }
                } catch (error) {
                    schema = error.response.data.detail
                    commit('setErrorAll', schema, { root: true })
                }
            }
        },
    },
    getters: {
        schema(state) {
            return state.schemas[state.schema];
        }
    }
}