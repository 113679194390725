<template>
    <b-container fluid >
        <b-row>
            <b-col class="statistics info">
                <h4>{{ title }}</h4>
            </b-col>
        </b-row>

        <b-row>
            <b-col md=6 xl=4 v-for="item in dataset" :key="item.name">
                <b-container fluid class="statistics">
                    <b-row class="info" no-gutters align-self="center">
                        <b-col cols=4>
                            <h2 class="info-title">
                                <animated-number :value="item.value"/>
                            </h2>
                        </b-col>
                        <b-col cols=8 pt=4>
                            <b-container>
                                <b-row align-h="end" align-content="end">
                                    <b-col cols=3 class="icon float-right" :class="item.type">
                                        <i :class="item.icon"/>
                                    </b-col>
                                    <b-col cols=9>
                                        <h6 :class="item.type" 
                                                class="stats-title icon float-right">{{ item.name }}</h6>
                                    </b-col>
                                </b-row>
                            </b-container>
                        </b-col>
                    </b-row>
                </b-container>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import AnimatedNumber from '@/components/AnimatedNumber';

export default {
    components: {
        AnimatedNumber
    },
    props: {
        title : {
            type: String,
            default: ''
        },
        data : {
            type: Array,
            default: function() {
                    return [];
            },
        }
    },
    data() {
        return {
            data2: [ 
                { name: 'Devices Protected', type: "icon-primary",
                    icon: "now-ui-icons tech_mobile", value: 12},
                { name: 'Blocked Threats', type: "icon-success",
                    icon: "isp-icon evil", value: 69},
                { name: 'Active Users', type: "icon-info",
                    icon: "isp-icon user-check", value: 5},
                { name: 'Notifications', type: "icon-danger",
                    icon: "isp-icon bubble", value: 9},
            ]
        };
    },
    computed: {
        dataset() {
            if ( this.$store.getters.isDummy && ( !this.data || this.data.length == 0))
                return this.data2;
            else
                return this.data;
        }
    }
};
</script>
<style>
</style>