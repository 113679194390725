import axios from "axios";
import i18n from '../../../i18n';
import _ from 'lodash';

export default {
    async getAdvancedConfiguration({ commit, getters, state }) {
        if (!getters.isDebug) {
            if (!state.advancedConfiguration || Object.keys(state.advancedConfiguration).length === 0) {
                let config = {
                    malware_blocking_page_type: 'default',
                    malware_blocking_page_url: '',
                    enable_safe_search: true
                };
                commit("setAdvancedConfiguration", config);
            }
        } else {
            try {
                // TODO: right endpoint?
                const advancedConfiguration = await axios.get('subsystems/rs/advanced-configuration');
                commit("setAdvancedConfiguration", advancedConfiguration.data);
            } catch (err) {
                console.error('Error getting RS Malware&Phishing Advanced Configuration: ', err);
                commit('setError', err, { root: true });
            }
        }
    },
    async updateAdvancedConfiguration({ commit, state, getters }, config) {
        const advanced = {
            malware_blocking_page_type: config.page_mode
        };
        if(config.page_mode !== 'default'){
            advanced.malware_blocking_page_url = config.custom_blocking_page_url
        }
        if (getters.isDebug) {
            commit('setSuccess', i18n.t('save_success'), { root: true });
            commit("setAdvancedConfiguration", advanced);
        } else {
            // PUT to backend
            const advancedConfiguration = {
                ...state.advancedConfiguration,
                ...advanced
            }
            try {
                // TODO: right endpoint?
                await axios.put('subsystems/rs/advanced-configuration', advancedConfiguration);
                commit('setSuccess', i18n.t('save_success'), { root: true });
                commit("setAdvancedConfiguration", advancedConfiguration);
            } catch (err) {
                console.error('Error updating advanced configuration: ', err);
                commit('setError', err, { root: true });
            }
        }
    },
    async getDefaultBlockingUrl({ commit, rootState, getters, dispatch }) {
        if (getters.isDebug) {
            let global_configurations = {
                threat_protection: {
                    "blocking_pages": {
                        "default_antimalware_blocking_page": "SystemAntiMalwareBlockingPage",
                        "blocking_pages_info": {
                            "external_blocking_pages": [],
                            "system_antimalware_blocking_page_url": "http://anti-malware.com/stopap",
                        }
                    }
                }
            };
            let defaultPage = {
                name: global_configurations.threat_protection.blocking_pages.default_antimalware_blocking_page,
                blocking_page_url: global_configurations.threat_protection.blocking_pages.blocking_pages_info.system_antimalware_blocking_page_url
            }
            commit("setDefaultBlockingUrl", defaultPage)
        } else {
            // Get default URL from backend
            await dispatch('ispServices/services/getGlobalConfigurations', {}, { root: true })
            let global_configurations = rootState.ispServices.services.global_configurations;
            let default_blocking_page = global_configurations.threat_protection.blocking_pages.default_antimalware_blocking_page;
            let defaultPage = default_blocking_page === 'SystemAntiMalwareBlockingPage'
                ? {
                    name: 'SystemBlockingPage',
                    blocking_page_url: global_configurations.threat_protection.blocking_pages.blocking_pages_info.system_antimalware_blocking_page_url
                }
                : global_configurations.threat_protection.blocking_pages.blocking_pages_info.external_blocking_pages.find(page => page.name === default_blocking_page)

            commit("setDefaultBlockingUrl", defaultPage)
        }
    }
};