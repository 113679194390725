<template>
    <span :class="selected ? 'iconify selected': 'iconify'" :data-icon="icon"
        :data-width="width" :data-height="height" data-inline="false" />
</template>

<script>
export default {
    name: "iconify",
    props: {
        icon: {type: String, required: true},
        selected: {type: Boolean, default: false},
        width: {type: Number, required: false, default: 30},
        height: {type: Number, required: false, default: 30}
    }
}
</script>

<style lang="scss">
    .iconify {
        margin-right: 1em;
        color: #333333;
    }
    .iconify.selected {
        margin: 3px;
    }
    .selected {
        .iconify {
            color: #f78d1e;
        }
    }
</style>