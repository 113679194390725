export default {
    externalServers(state) {
        return state.external_servers;
    },
    clusterOptions(state) {
        return state.cluster_options.map(item => {
            return {
                value: item.value,
                label: item.label
            }
        })
    },
    getServer(state) {
        return state.server;
    },
    getSmsServer(state) {
      return state.sms_server;
    },
    getEmailServer(state) {
      return state.email_server;
    },
    smsServers(state) {
      return state.sms_servers;
    },
    emailServers(state) {
      return state.email_servers;
    }
}
