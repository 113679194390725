/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'url-forbid': {
    width: 512,
    height: 512,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm0 2a9.949 9.949 0 016.324 2.262L4.262 18.324A9.992 9.992 0 0112 2zm0 20a9.949 9.949 0 01-6.324-2.262L19.738 5.676A9.992 9.992 0 0112 22z"/>'
  }
})
