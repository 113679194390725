import { DateTime as objDateTime} from 'luxon'
import i18n from '../i18n'

export const ROLE_TAG = {
  router: 'router_model',
  firmware: 'firmware',
  agent: 'agent_version',
  status: 'status',
  target: 'target_version',
  target_tag: 'target_version_tag',
  att_name: 'attributes',
  more: 'more',
  att: 'attribute',
  reboot: 'reboot',
  safe: 'safe'
}

export const DEFAULT_FILTER = {
  router_model: [],
  firmware: [],
  agent_version: [],
  target_version: [],
  reboot: "All",
  safe: "All",
  status: "All",
  attributes: [],
  more: {
  apply: false,
      upgradedVersion: {
        apply: false,
        frequency: {
          time: "1",
          period: "hours"
        },
      },
      releaseSafe: {
        apply: false,
        frequency: {
          time: "1",
          period: "hours"
        },
      },
      releaseBoot: {
        apply: false,
        frequency: {
          time: "1",
          period: "hours"
        },
      },
  },
  router: "None"
}

export const filterTroubleshootTags = (filterOptions) => {
  let mergeResult = []
  const result = Object.entries(filterOptions)
  result && result.length > 0 && result.map((attribute) => {
    switch(attribute[0]) {
      case ROLE_TAG.router:
        attribute[1].length > 0 && attribute[1].forEach(value => {
          mergeResult.push({
            role: 'router',
            type: 'filter',
            label: value
          })
        })   
      break;  
      case ROLE_TAG.firmware:
        attribute[1].length > 0 && attribute[1].forEach(value => {
          mergeResult.push({
            role: 'firmware',
            type: 'filter',
            value: value,
            label: i18n.t('Firmware') + ': ' + value
          })
        })   
      break;  
      case ROLE_TAG.agent:
        attribute[1].length > 0 && attribute[1].forEach(value => {
          mergeResult.push({
            role: 'agent',
            type: 'filter',
            value: value,
            label: i18n.t('Active version') + ': ' + value
          })
        })   
      break; 
      case ROLE_TAG.target_tag:
        attribute[1].length > 0 && attribute[1].forEach(value => {
          mergeResult.push({
            role: 'target_tag',
            type: 'filter',
            value: value,
            label: i18n.t('Target version') + ': ' + (value ? value : i18n.t('default'))
          })
        })   
      break;   
      case ROLE_TAG.att_name:
        attribute[1].length > 0 && attribute[1].forEach(value => {
          mergeResult.push({
            role: 'att_name',
            type: 'filter',
            label: value
          })
        })   
      break;
      case ROLE_TAG.more:
        const data = attribute[1];
        const option = filterOptions.router.toLowerCase()
        if (['show', 'hide'].includes(option)) {
          let text = 'select-table_' + option + "_"
          if (data.releaseBoot.apply){
            mergeResult.push({ 
              role: 'more', 
              type: 'filter', 
              label: i18n.t(text + 'released_routers_from_Reboot_Loop, last') + 
                      data.releaseBoot.frequency.time + ' ' + i18n.t('select-table_' + data.releaseBoot.frequency.period) 
            });
          }
          if (data.releaseSafe.apply){
            mergeResult.push({ 
              role: 'more', 
              type: 'filter', 
              label: i18n.t(text + 'released_routers_from_Safe_Mode, last') + 
                      data.releaseSafe.frequency.time + ' ' + i18n.t('select-table_' + data.releaseSafe.frequency.period) 
            });
          }
          if (data.upgradedVersion.apply){
            mergeResult.push({ 
              role: 'more', 
              type: 'filter', 
              label: i18n.t(text + 'upgraded_routers, last') +
                      data.upgradedVersion.frequency.time + ' ' + i18n.t('select-table_' + data.upgradedVersion.frequency.period) 
            });        
          }  
        }
      break; 
      case ROLE_TAG.safe:
      case ROLE_TAG.status:
      case ROLE_TAG.reboot:
        if (attribute[1].toLowerCase() == "active")
          mergeResult.push({ role: attribute[0] ,type: 'filter', label: i18n.t('select-table_' + attribute[0] + '_yes')})
        else if (attribute[1].toLowerCase() == "inactive")
          mergeResult.push({ role: attribute[0] ,type: 'filter', label: i18n.t('select-table_' + attribute[0] + '_no')})
        
        break;
    }
  })
  return mergeResult;
}

export const closeFilterTag = (filter, tag) => {

let filterResult = {
  ...filter
}

  switch(ROLE_TAG[tag.role]) {
    case 'router_model':
      filterResult.router_model = filterResult.router_model.filter(element =>  element !== tag.label)
    break;
    case 'firmware':
      filterResult.firmware = filterResult.firmware.filter(element =>  element !== tag.value)
    break;
    case 'agent_version':
      filterResult.agent_version = filterResult.agent_version.filter(element =>  element !== tag.value)
    break;
    case 'target_version_tag':
      let idx = filterResult.target_version_tag.indexOf(tag.value)
      filterResult.target_version_tag.splice(idx, 1)
      filterResult.target_version.splice(idx, 1)
    break;  
    case 'attributes':
      filterResult.attributes = filterResult.attributes.filter(element =>  element !== tag.label)
    break;
    case 'status':
      filterResult.status = ""
    break;
    case 'reboot':
      filterResult.reboot = ""
    break; 
    case 'safe':
      filterResult.safe = ""
    break;   
    case 'more':
      if(tag.label.includes('Reboot Loop')) {
        if (!filterResult.more)
          filterResult.more = {}
        filterResult.more.releaseBoot = {
          apply: false,
          frequency: {
            time: "1",
            period: "hours"
          },
        }
        break;
      }
      if(tag.label.includes('Safe mode')) {
        if (!filterResult.more)
          filterResult.more = {}
        filterResult.more.releaseSafe = {
          apply: false,
          frequency: {
            time: "1",
            period: "hours"
          },
        }
        break;
      }
      
      if(tag.label.includes('upgraded routers')) {
        if (!filterResult.more)
          filterResult.more = {}
        filterResult.more.upgradedVersion = {
          apply: false,
          frequency: {
            time: "1",
            period: "hours"
          },
        } 
      }
       break;    
    }  
  return filterResult
}

export const clearMoreTagFilterValues = (moreData, label) => {
  const resetValues = { 
    apply: false,
    frequency: { 
      period: "hours",
      time: 1
   }
  }

    if (label.includes('Hide')) {
     return (label.includes('Reboot Loop') ? 
      {...moreData, hide: { ...moreData.hide, releaseBoot: resetValues}}
     : label.includes('mode') ? {...moreData, hide: { ...moreData.hide, releaseSafe: resetValues}}
     : {...moreData, hide: { ...moreData.hide, upgradedVersion: resetValues}})
    } else {
      return (label.includes('Reboot Loop') ? 
      {...moreData, show: { ...moreData.hide, releaseBoot: resetValues}}
     : label.includes('mode') ? {...moreData, show: { ...moreData.hide, releaseSafe: resetValues}}
     : {...moreData, show: { ...moreData.hide, upgradedVersion: resetValues}})
    }

}


export const searchBackDataToGUI = (router) => {

  return {
    router_name: router.router_id, /*children: [{'attributes':  getAttributesString(router.attributes)}]*/ // TODO: nested_table design
    router_id: router.router_id,
    router_model: router.router_model,
    account: router.account_id,
    agent_version: router.current_agent_version_id,
    status: router.active,
    state: getState(router.safe_mode, router.reboot_loop),
    safe_mode: router.safe_mode,
    reboot_loop: router.reboot_loop,
    attributes: router.attributes,
    package_id: router.current_package_id,
    firmware: router.firmware_version,
    target_agent_package_id: router.target_agent_package_id,
    rs_id: router.identifiers &&  router.identifiers.length > 0 ? getIdentifiers(router.identifiers) : []
  }
}

export const getState = (safe_mode, reboot_loop) => {
  let state = '-'
  if (safe_mode !== "") {
    reboot_loop !== "" ? state = [`Reboot Loop: ${reboot_loop}`, `Safe Mode: ${safe_mode}`] : state = [`Safe Mode: ${safe_mode}`]
  } else if (reboot_loop !== "") state = [`Reboot Loop: ${reboot_loop}`]

  return state;
}


export const transformFilterToBackQuery = (filter) => {

  let query = {}
  Object.entries(filter).map(element => {
    switch(element[0]) {
      case 'router_model':
        if (element[1].length > 0) {
          query['router_model'] = element[1]
        }
      break;
      case 'firmware':
        if (element[1].length > 0) {
          query['firmware_version'] = element[1]
        }
      break;
      case 'agent_version':
        if (element[1].length > 0) {
          query['current_agent_version_id'] = element[1]
        }
      break;
      case 'target_version':
        if (element[1].length > 0) {
          query['target_agent_package_id'] = element[1]
        }
      break;  
      case 'attributes':
        if (element[1].length > 0) {
          let attributes = []
          element[1].map(values => {
            if (typeof(values) === 'string') {
              let attribute = values.split('-')
              if (attribute.length  === 2) {
                attributes.push({
                  "name": attribute[0],
                  "value": attribute[1]
                })
              }
            } else {
              if (typeof(values) === 'object') {
                attributes.push({
                  "name": values.name,
                  "value": values.value
                })
              }
            }
          })
          query['attributes'] = attributes
        }
      break;
      case 'status':
          if (element[1] !== "" && element[1] !== "All") {
            query['active'] = element[1] === 'Active' ? true : false
          }
      break;
      case 'reboot':
        if (element[1] !== "" && element[1] !== "All") {
          query['reboot_loop'] = element[1] === 'Active' ? true : false
        }
      break;
      case 'safe':
        if (element[1] !== "" && element[1] !== "All") {
          query['safe_mode'] = element[1] === 'Active' ? true : false
        }
      break;
      case 'more':
        const more = element[1]
        switch(filter.router){
        case 'None':
          break;
        case 'Show':
        case 'Hide':
          if (!!more.releaseBoot && more.releaseBoot.apply) {
            query['last_time_reset_reboot_loop'] = {
              "operator": filter.router === 'Show' ? 'lte' : 'gte',
              "delta": getTimesInMinutes(element[1].releaseBoot.frequency.period, element[1].releaseBoot.frequency.time)
            }
          }
          if (!!more.releaseSafe && more.releaseSafe.apply) {
            query['last_time_reset_safe_mode'] =
            {
              "operator": filter.router === 'Show' ? 'lte' : 'gte',
              "delta": getTimesInMinutes(element[1].releaseSafe.frequency.period, element[1].releaseSafe.frequency.time)
            }
          }
        
          if (!!more.upgradedVersion && more.upgradedVersion.apply) {
            query['last_time_update_target_agent_package'] =
            {
              "operator": filter.router === 'Show' ? 'lte' : 'gte',
              "delta": getTimesInMinutes(element[1].upgradedVersion.frequency.period, element[1].upgradedVersion.frequency.time)
            }
          }
          break;
        }
      break;
      case 'router_id':
        query['router_id'] = [element[1]]
      break;
      case 'account_id':
        query['account_id'] = element[1]
      break;
      case 'mac_address':
        query['mac_address'] = element[1]
      break;
      case 'serial_number':
        query['serial_number'] = element[1]
      break; 
    }
  })

  return query

}


export const getTimesInMinutes = (period, time) => {
  let minutesTime = time;
  switch (period) {
    case 'hours':
      minutesTime = time * 60;
    break;
    case 'days':
      minutesTime = time * 24 * 60;
    break;
    case 'weeks':
      minutesTime = time * 7 *  24 * 60;
    break;
    case 'months':
      minutesTime = time * 30 * 7 *  24 * 60;
    break;

    default:
      break;
  }
  return (minutesTime)

}

export const getStatus = (status) => {
  if (status === 'Active') {
    return true;
  }
  if (status === 'Inactive') {
    return false;
  }
  return ("All")
}


export const getQuery = (query, search) => {
  let resultQuery = {...query}
  const field = search.field
  if (search.value !== '') {
    if (!!!resultQuery.filter) {
      resultQuery.filter = {}
    }
    
    if (!!field && ['mac_address', 'account_id'].includes(field)){
      resultQuery  = {
        ...resultQuery,
        filter: {
          ...resultQuery.filter,
          [field] : [search.value]
        }
      } 
    }
    else{
      resultQuery  = {
        ...resultQuery,
        filter: {
          ...resultQuery.filter,
          [field] : search.value
        }
      } 
    }
  } else {
    if (resultQuery.filter && resultQuery.filter[search]) {
      delete resultQuery.filter[search]
    }
  }
  return resultQuery
}

export const getIdentifiers = (identifiers) => {
  let serial;
  let mac;
  identifiers.map(element => {
    if (element.type === 'serial_number') {
      serial && serial.length ? serial.push(element.value) : serial = [element.value]
    } else if (element.type === 'mac_address') {
     mac && mac.length ? mac.push(element.value) : mac = [element.value]

    } else {
      console.log('identifier no supported', element.type)
    }
  })
  return ({'serial': serial, 'mac': mac})

}

export const getAttributesString = (attributes) => {
  if (attributes && attributes.length > 0) {
    let values = []
    attributes.map(attribute => {
      values.push(`${attribute.name}-${attribute.value}`)
    })
    return values

  } else {
    return ['none']
  }
}

function datetimeFromISO (string) {
  const datetime = objDateTime.fromISO(string).toUTC()
  return datetime.isValid ? datetime : null
};

function toLocaleString(dt){
  if ( dt)
      return dt.toLocal().toLocaleString(objDateTime.DATETIME_MED)
  else
      return ' - ' 
}

export const getAgentOptionsFiltered = (agents, filter) => {

  function getMaxRouterModel(router){
    if( router.length > 35 )
      return router.substr(0,34) + "...";
    return router; 
  }

  let options = [{ value: '', router: i18n.t('default')}]

  if (agents){
    agents.map(agent => {
      if (filter.router_model && filter.router_model.length > 0){
        if (filter.router_model.includes(agent.router_model)) {
          if(isFirmwareIncluded(agent, filter)) {
            options.push({ 
              value: agent.agent_package_id, 
              label: getMaxRouterModel( agent.router_model ) + ' - ' + agent.agent_version_id + 
                    (agent.upload_time ? " - " + toLocaleString(datetimeFromISO(agent.upload_time)) : ''),
              agent: agent.agent_version_id,
              datef: (agent.upload_time ? toLocaleString(datetimeFromISO(agent.upload_time)) : ''),
              router: agent.router_model 
            })
          }
        }
      }
      else {
        if(isFirmwareIncluded(agent, filter)) {
          options.push({ 
            value: agent.agent_package_id,
            label: getMaxRouterModel( agent.router_model ) + ' - ' + agent.agent_version_id + 
                (agent.upload_time ? " - " + toLocaleString(datetimeFromISO(agent.upload_time)) : ''), 
            agent: agent.agent_version_id,
            datef : (agent.upload_time ? toLocaleString(datetimeFromISO(agent.upload_time)) : ''),
            router: agent.router_model
          })
        }
      }
    })
  }

  return options
}

export const isFirmwareIncluded = (agent, filter) => {
  let included = false
  if (filter && agent && filter.firmware && filter.firmware.length > 0 && agent.firmware_versions && agent.firmware_versions.length > 0) {
    agent.firmware_versions.map(firmware => {
     if (filter.firmware.includes(firmware))
       included = true
    })
  }
  else
    included = !!agent

  return included
}  
