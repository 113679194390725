import Vue from 'vue';

export default {
  setServiceStatus(state, service_status) {
    state.service_status = service_status;
  },
  setLocalServiceStatus(state, { subsystem_service_id, status }) {
   // let index = state.service_status.findIndex(service => service.subsystem_service_id === subsystem_service_id)
   // state.service_status[index].calculated_status = status;
  },
  setLanProtection(state, data) {
    state.lan_protection = data;
  },
  setLocalLanProtection(state, service) {
    state.lan_protection[service.name] = service.status
  },

  setRouterProtection(state, data) {
    state.router_protection = data;
  },
  setLocalRouterProtection(state, service) {
    state.router_protection[service.name] = service.status
  }
}