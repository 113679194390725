/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'transfer-buttons-2': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path pid="0" d="M30 17.58a1.996 1.996 0 011.06 3.692l-5.368 5.37a2 2 0 01-2.825-.047 1.997 1.997 0 010-2.777l2.24-2.24H10.02a1.997 1.997 0 110-3.996H30v-.002zM9.135 4.586c.781.78.781 2.046 0 2.825l-2.24 2.24H21.98a1.997 1.997 0 110 3.996H2A1.997 1.997 0 01.94 9.955l5.37-5.37a1.998 1.998 0 012.825.001z" _fill="#000" fill-rule="evenodd"/>'
  }
})
