import { formatFileSize, isDefinedGlobally } from './utils';

const messages = {
  _default: (field) => `Este campo no es válido`,
  after: (field, [target, inclusion]) => `Este campo debe ser posterior ${inclusion ? 'o igual ' : ''}a ${target}`,
  alpha: (field) => `Este campo solo debe contener letras`,
  alpha_dash: (field) => `Este campo solo debe contener letras, números y guiones`,
  alpha_num: (field) => `Este campo solo debe contener letras y números`,
  alpha_spaces: (field) => `Este campo solo debe contener letras y espacios`,
  before: (field, [target, inclusion]) => `Este campo debe ser anterior ${inclusion ? 'o igual ' : ''}a ${target}`,
  between: (field, [min, max]) => `Este campo debe estar entre ${min} y ${max}`,
  confirmed: (field) => `Este campo no coincide`,
  credit_card: (field) => `Este campo es inválido`,
  date_between: (field, [min, max]) => `Este campo debe estar entre ${min} y ${max}`,
  date_format: (field, [format]) => `Este campo debe tener un formato ${format}`,
  decimal: (field, [decimals = '*'] = []) => `Este campo debe ser numérico y contener${!decimals || decimals === '*' ? '' : ' ' + decimals} puntos decimales`,
  digits: (field, [length]) => `Este campo debe ser numérico y contener exactamente ${length} dígitos`,
  dimensions: (field, [width, height]) => `Este campo debe ser de ${width} píxeles por ${height} píxeles`,
  email: (field) => `Este campo debe ser un correo electrónico válido`,
  excluded: (field) => `Este campo debe ser un valor válido`,
  ext: (field) => `Este campo debe ser un archivo válido`,
  image: (field) => `Este campo debe ser una imagen`,
  included: (field) => `Este campo debe ser un valor válido`,
  integer: (field) => `Este campo debe ser un entero`,
  ip: (field) => `Este campo debe ser una dirección ip válida`,
  ip_or_fqdn: (field) => `Este campo debe ser una dirección ip válida o FQDN`,
  length: (field, [length, max]) => {
    if (max) {
      return `La longitud del campo debe estar entre ${length} y ${max}`;
    }

    return `La longitud del campo debe ser ${length}`;
  },
  max: (field, [length]) => `La longitud del campo no puede ser mayor que ${length} caracteres`,
  max_value: (field, [max]) => `Este campo debe de ser ${max} o menor`,
  mimes: (field) => `Este campo debe ser un tipo de archivo válido`,
  min: (field, [length]) => `Este campo debe tener al menos ${length} caracteres`,
  min_value: (field, [min]) => `Este campo debe ser ${min} o superior`,
  numeric: (field) => `Este campo debe contener solo caracteres numéricos`,
  regex: (field) => `El formato del campo no es válido`,
  required: (field) => `Este campo es obligatorio`,
  required_if: (field, [target]) => `Este campo es obligatorio cuando el campo ${target} tiene este valor`,
  size: (field, [size]) => `Este campo debe ser menor a ${formatFileSize(size)}`,
  url: (field) => `Este campo no es una URL válida`
};

const locale = {
  name: 'es',
  messages,
  attributes: {}
};

if (isDefinedGlobally()) {
  // eslint-disable-next-line
  VeeValidate.Validator.localize({ [locale.name]: locale });
}

export default locale;
