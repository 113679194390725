export default {
    getDarkMode(state) {
        return state.darkmode;
    },
    getDebug(state) {
        return false;
    },
    getLanguage(state) {
        if (localStorage.getItem('language') != null && localStorage.getItem('language') != '' && localStorage.getItem('language') != state.language) {
            return localStorage.getItem('language');
        } else {
            return state.language;
        }
    },
    getRegionCode(state) {
        if (localStorage.getItem('language') != null && localStorage.getItem('language') != '' && localStorage.getItem('language') != state.language) {
            return state.languages.filter(lang => lang.id == localStorage.getItem('language'))[0].regionCode;
        } else {
            return state.languages.filter(lang => lang.id == state.language)[0].regionCode;
        }
    },
    getLanguages(state) {
        return state.languages
    },
    layout(state) {
        if (state.desktop)
          return 'desktop'
        else if (state.mobile)
          return 'mobile'
        else
          return 'tablet'
    },
    subsystemsPackages: (state) => {
      return state.packages;
    },
    getSubsystemsPackageState: (state) => (id) => {
        return state.packages.includes(id);
    },
    getServices: (state) => {
        return state.services;
    },
    getServiceState: (state) => (id) => {
        return state.services.includes(id);
    },
    getProducts: (state) => {
        return state.products;
    },
    getProductState: (state) => (id) => {
        return state.products.includes(id);
    },
    isIOT(state, getters) {
        return state.products.length == 1 && getters["getProductState"]('NetworkSecure') &&
                state.services.length == 1 && getters["getServiceState"]('firewall')
    },
    appName(state, getters) {
        return getters['isIOT'] ? 'IoTSecure' : 'SecureManagement'
    },
    getServicesNS: (state)  => {
        return state.services_ns;
    },
    getServiceStateNS: (state) => (id) => {
        let services_ns_regex = new RegExp(state.services_ns.join("|"), "i");
        return services_ns_regex.test(id);
    },
    checkSubService: (state) => (id) => {
        return Object.keys(state.subsystemsDict).includes(id)
    },
    configLoaded(state) {
        return (state.services.length > 0 || state.services_ns.length > 0 || state.products.length > 0)
    },   
    getSubSystemsServicesId(state) {
        return state.subsystems.map(system => system.service_id)
    },  
    loading(state) {
        return state.loadingHigh > 0 || state.loadingLow > 0
    },    
    getServicesDS: ( state) => {
        return state.services_ds;
    },  
    getServiceStateDS: (state) => (id) => {
        let services_ds_regex = new RegExp( state.services_ds.join( "|" ), "i");
          return services_ds_regex.test(id);
    },
    EShasAV(state) {
        return state.EShasAV;
    },
    getProductServiceDict(state){
        return state.product_service_dict
    }
}