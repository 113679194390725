import state from "./state";

export default {
  modelOptions(state) {
    return state.router_model_options;
  },
  firmwareOptions(state) {
    return state.router_firmware_options;
  },
  activeVersionOptions(state) {
    return state.active_version_options;
  },
  targetVersionOptions(state) {
    return state.target_version_options;
  },
  attributeOptions(state) {
    return state.attribute_options;
  },
  bulk_size(state) {
    return state.bulk_size
  },
  job_id(state) {
    return state.job_id
  },
  agent_packages(state) {
    return state.agent_packages
  },
  timeConfiguration(state) {
    return {
      reboot:  state.advanced_configuration.reset_reboot_loop_min_time_between_actions_sec,
      safe: state.advanced_configuration.reset_safe_mode_min_time_between_actions_sec,
      agent: state.advanced_configuration.set_agent_package_min_time_between_actions_sec
    }
  },
  advancedConfiguration(state){
    return state.advanced_configuration
  },
  advancedConfigValue: (state) => (field) => {
    if (state.advanced_configuration.hasOwnProperty(field))
      return state.advanced_configuration[field]
    else{
      console.error("advancedConfigValue: unknown field: " + field)
      return null
    }

},

};