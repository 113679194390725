/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'users': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path pid="0" d="M28.889 30.222a1.777 1.777 0 11-3.556 0c0-4.901-3.987-8.889-8.889-8.889-4.9 0-8.888 3.988-8.888 8.89a1.777 1.777 0 11-3.556 0c0-6.863 5.584-12.445 12.444-12.445 6.86 0 12.445 5.582 12.445 12.444M16.444 3.556A3.56 3.56 0 0120 7.11a3.56 3.56 0 01-3.556 3.556A3.56 3.56 0 0112.89 7.11a3.56 3.56 0 013.555-3.555m0 10.666a7.118 7.118 0 007.112-7.11A7.118 7.118 0 0016.444 0a7.118 7.118 0 00-7.11 7.111 7.118 7.118 0 007.11 7.111" _fill="#000" fill-rule="nonzero"/>'
  }
})
