export default {
    setAntiVirus(state, antiVirus) {
        state.antiVirusStrategy = antiVirus;
    },
    setAntiVirusRules(state, rules) {
        rules = rules.sort((a, b) => {
            return a.order > b.order ? 1 : -1;
        });
        state.rules = rules;
    },
    addAntiVirusRule(state, newRule) {
        state.rules.push(newRule);
    },
    setAdvancedConfiguration(state, config) {
        state.advancedConfiguration = config;
    },
    setDefaultBlockingUrl(state, url) {
        state.defaultBlockingUrl = url;
    }
};