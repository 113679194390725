<template functional>
    <div>
        <template v-if="props.row[props.column.prop].tooltip">
            <el-tooltip :content="props.row[props.column.prop].tooltip" :open-delay="250" >
                <avatar :fullname="props.row[props.column.prop].name" :image="props.row[props.column.prop].image" shine-on-hover usewords :size="50" />
                <i v-if="props.row[props.column.prop].icon" class="overlay-icon" :class="props.row[props.column.prop].icon">
                  <svgicon v-if="props.row[props.column.prop].svgicon" :icon="props.row[props.column.prop].svgicon" />
                </i> 
            </el-tooltip>
        </template>
        <template v-else>
            <avatar :fullname="props.row[props.column.prop].name" :image="props.row[props.column.prop].image" shine-on-hover usewords :size="50" />
            <i v-if="props.row[props.column.prop].icon" class="overlay-icon" :class="props.row[props.column.prop].icon">
              <svgicon v-if="props.row[props.column.prop].svgicon" :icon="props.row[props.column.prop].svgicon" />
            </i> 
        </template>
    </div>
</template>

<script>
  export default {
    name: 'avatarimage-formatter',
  }
</script>
