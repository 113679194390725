<template functional>
  <span style="white-space: nowrap;">
    <i class="now-ui-icons arrows-1_minimal-right icon icon-primary"></i> {{ props.row[props.column.prop] + (props.column.postfix ? props.column.postfix : '') }}
  </span>
</template>

<script>
export default {
    name: 'arrow-formatter',
}
</script>
