export const mockManager = (id) => ({
    id: "5e56332d8736dc0bd18f7615",
    data: {
        identity: {
            email: "example@domain.com",
            name: "example testing",
            identity_instances: {
                identity_instance_type: "IdentityInstanceDatabase",
                user_id: "example@domain.com"
            },
            manager_metadata: {
                language_id: 'en-US',
                picture: {
                    format: 'no_picture',
                    storage_method: 'no_picture'
                }
            }
        },
        manager_id: "5e56332d8736dc0bd18f7615",
        account_id: id
    }
})

export const mockManager2 = (data) => ({
    identity: {
        email: "user_sys_jp@domain.com",
        name: "User Sys JP",
        identity_instances: {
            identity_instance_type: "IdentityInstanceDatabase",
            user_id: "user_sys_jp"
        },
        manager_metadata: {
            language_id: 'en-US',
            picture: {
                format: 'no_picture',
                storage_method: 'no_picture'
            }
        }
    },
    manager_id: data.manager_id,
    account_id: data.account_id,
})

export const createManager = (item) => {
    const identity_instances = item.identity && item.identity.identity_instances && item.identity.identity_instances[0]
    let identity_instance_type = (identity_instances && identity_instances.identity_instance_type) || "IdentityInstanceDatabase";
    let identity_provider_type = (identity_instances && identity_instances.identity_provider_type) || "IdentityProviderDatabase";
    try {
        let user_id = identity_instances ? identity_instances.user_id : null
        if (!item.manager_metadata) {
            item.manager_metadata = {};
        }
        if (!item.manager_metadata.picture) {
            item.manager_metadata.picture = {};
        }

        let identity = {}
        if (item.identity){
            identity = { 
                phone_number: item.identity.phone_number,
                blocked: item.identity.blocked || false,
                created_at: item.identity.created_at,
                email: item.identity.email,
                identity_instances: {
                    identity_instance_type: user_id ? identity_instance_type : null,
                    identity_provider_type: user_id ? identity_provider_type : null,
                    user_id: user_id ? user_id : null,
                },
                name: item.identity.name,
                last_ip: item.identity.last_ip,
                last_login: item.identity.last_login,
                updated_at: item.identity.updated_at
            }
        }
        else{
            identity = {
                phone_number: null,
                blocked: false,
                created_at: "",
                email: null,
                identity_instances: { identity_instance_type: user_id ? identity_instance_type : null,
                    identity_provider_type: user_id ? identity_provider_type : null,
                    user_id: user_id ? user_id : null},
                name: "",
                last_ip: "",
                last_login: "",
                updated_at: ""
            }
        };

        const manager_metadata = item.manager_metadata ? item.manager_metadata : {
            language_id: 'en-US',
                    time_zone: 'America/New_York',
                    picture: {
                        format: 'no_picture',
                        storage_method:  'no_picture',
                        picture_base64:  '',
                        picture_url:  ''
                    }
        };

        let manager = {
            id: item.manager_id,
            data: {
                account_id: item.account_id,
                manager_id: item.manager_id,
                identity: identity,
                manager_metadata: manager_metadata
            }
        };
        return manager
    }
    catch (e) {
        console.log('createManager catch...', e)
        return null
    }
}