<template>
  <button type="button"
          class="navbar-togglerr collapsed"
          data-toggle="collapse"
          data-target="#navbar"
          aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"
          style="border: 1px solid transparent;background-color: transparent;">
    <span class="navbar-toggler-bar bar1"></span>
    <span class="navbar-toggler-bar bar2"></span>
    <span class="navbar-toggler-bar bar3"></span>
  </button>
</template>
<script>
export default {
  name: 'navbar-toggle-button'
};
</script>
<style>
</style>
