import axios from 'axios';
import i18n from '../../../i18n';

let routerList = [ 'example1.com', 'example2.com', 'example3.com' ];
let dnsList = [ 'example4.com', 'example5.com', 'example6.com' ];

export default {
  async getRouterAllowed({ commit, rootGetters }, { query }) {
    return new Promise((resolve, reject) => {
      if(rootGetters['settings/getDebug']){
        resolve({
          items: routerList,
          query: query,
          self: query
        })
      } else {
        const routerQuery = {
          ip_operator: "sw",
          ip_value: query.field_value ? query.field_value : undefined,
          sort: 'asc',
          limit: query.limit,
          next_id: query.next_id,
          prev_id: query.prev_id,
          next_name: query.next_name,
          prev_name: query.prev_name
        };
        axios.get(`/subsystems/rs/router-protection/router-allowed-list`, { params: routerQuery })
        .then(res => {
          if ( res && res.data && res.data.items){
            commit('setRouterAllowed', res.data.items)
            resolve(res.data)
          }
          else{
            reject()
          }
        })
        .catch(error => {
          commit('setError', error, {root: true});
          reject()
        })
      }
    })
  },
  async getDNSAllowed({ commit, rootGetters }, { query }) {
    return new Promise((resolve, reject) => {
      if(rootGetters['settings/getDebug']){
        resolve({
          items: dnsList,
          query: query,
          self: query
        })
      } else {

        const dnsQuery = {
          ip_operator: "sw",
          ip_value: query.field_value ? query.field_value : undefined,
          sort: 'asc',
          limit: query.limit,
          next_id: query.next_id,
          prev_id: query.prev_id,
          next_name: query.next_name,
          prev_name: query.prev_name
        };
        
        axios.get(`/subsystems/rs/router-protection/dns-allowed-list`, { params: dnsQuery })
        .then(res => {
          if ( res && res.data && res.data.items){
            commit('setDNSAllowed', res.data.items)
            resolve(res.data)
          }
          else{
            reject()
          }
        })
        .catch(error => {
          commit('setError', error, {root: true});
          reject()
        })
      }
    })
  },
  async setRouterAllowed({ commit, dispatch, rootGetters }, { action, item }) {
    try {
      if(rootGetters['settings/getDebug']){
        action === 'add' && routerList.push(item);
        if (action === 'del')
          routerList = routerList.filter(e => e !== item);
      } else {
        try {
          await axios.post(`/subsystems/rs/router-protection/router-allowed-list/modify`, {[action]: [item] })
          commit('setSuccess', i18n.t('save_success'), { root: true });
        } catch(error) {
          console.error('Error post router Allowed: ', error);
          if( error.response && error.response.data && error.response.data.type && error.response.data.type == "RSCLIENT.MAX_LIST_SIZE_EXCEEDED")
            commit('setErrorAll', i18n.t("Limit in 100 will be reached"), {root: true});
          else
            commit('setError', error, { root: true });
        }
      }
    }
    catch (error) {
      let err = error.response.data.detail.includes('is not valid under any of the given schemas') ? 'data provided is not valid' : error.response.data.detail;
      commit('setErrorAll', i18n.t(err), { root: true })
    }
  },
  async setDNSAllowed({ commit, dispatch, rootGetters }, { action, item }) {
    try {
      if(rootGetters['settings/getDebug']){
        action === 'add' && dnsList.push(item);
        if (action === 'del')
          dnsList = dnsList.filter(e => e !== item);
      } else {
        try { 
          await axios.post(`/subsystems/rs/router-protection/dns-allowed-list/modify`, { [action]: [item] })
          commit('setSuccess', i18n.t('save_success'), { root: true });
        } catch(error) {
          console.error('Error post dns Allowed: ', error);
      
          if( error.response && error.response.data && error.response.data.type && error.response.data.type == "RSCLIENT.MAX_LIST_SIZE_EXCEEDED")
            commit('setErrorAll', i18n.t("Limit in 100 will be reached"), {root: true});
          else
            commit('setError', error, { root: true });
        }
      }
    }
    catch (error) {
      let err = error.response.data.detail.includes('is not valid under any of the given schemas') ? 'data provided is not valid' : error.response.data.detail;
      commit('setErrorAll', i18n.t(err), { root: true })
    }
  }
}
