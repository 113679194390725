/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icon-listasblancas': {
    width: 44,
    height: 44,
    viewBox: '0 0 44 44',
    data: '<g _fill="#000" fill-rule="nonzero"><path pid="0" d="M19.195 35.93H6.705c-1.848 0-3.352-1.493-3.352-3.327v-22.54h36.21v8.484c0 .919.75 1.663 1.677 1.663a1.67 1.67 0 001.676-1.663V4.99c0-2.751-2.256-4.99-5.03-4.99H5.03C2.256 0 0 2.239 0 4.99v27.613c0 3.669 3.008 6.654 6.706 6.654h12.489a1.67 1.67 0 001.676-1.664 1.67 1.67 0 00-1.676-1.663zM37.803 3.327c.925 0 1.677.746 1.677 1.663s-.752 1.664-1.677 1.664a1.672 1.672 0 01-1.676-1.664c0-.917.752-1.663 1.676-1.663zm-6.706 0c.925 0 1.677.746 1.677 1.663s-.752 1.664-1.677 1.664a1.672 1.672 0 01-1.676-1.664c0-.917.752-1.663 1.676-1.663zM3.353 4.99c0-.917.752-1.663 1.676-1.663h21.328a4.932 4.932 0 00.03 3.41H3.353V4.99z"/><path pid="1" d="M42.493 25.76a.82.82 0 01.507.752v6.48c0 1.806-.531 3.624-1.536 5.26a.865.865 0 01-1.162.285.805.805 0 01-.296-1.124c.849-1.382 1.298-2.91 1.298-4.42v-5.946l-7.845-3.33-7.763 3.328v5.947c0 4.38 3.72 8.367 7.804 8.367 1.444 0 2.855-.456 4.081-1.32a.867.867 0 011.185.181.803.803 0 01-.187 1.146C37.061 42.436 35.305 43 33.5 43a8.55 8.55 0 01-3.747-.872 9.882 9.882 0 01-3.007-2.267C25 37.945 24 35.441 24 32.992v-6.48a.82.82 0 01.504-.75l8.61-3.692a.875.875 0 01.685 0l8.694 3.69zM36.78 29.26a.68.68 0 01.962-.108.693.693 0 01.107.97l-4.292 5.403A1.316 1.316 0 0132.54 36c-.342 0-.665-.13-.915-.37l-.003-.002-2.412-2.338a.693.693 0 01-.019-.975.68.68 0 01.968-.019l2.379 2.306 4.243-5.343z" _stroke="#000" stroke-width="1.2"/></g>'
  }
})
