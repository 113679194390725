export const license = {"data" : {    
    "additional_engines": [
        "BotnetFeed"
    ],
    "antivirus_engines": [
        "Kaspersky",
        "Sophos",
        "Bitdefender",
        "McAfee"
    ],
    "expiration_date": "2020-12-31T00:00:00Z",
    "installed_services": [
        "Firewall",
        "Reporter"
    ],
    "license_code": "XXXX-XXXX-XXXX-XXXX",
    "license_services": [
        "ContentFilter",
        "AdsFree",
        "AntiPhishing",
        "Firewall",
        "Reporter",
        "SMTPFilter.In",
        "SMTPFilter.Out",
        "Activation",
        "AntiVirus",
        "WebProxy",
        "AntiBotnet"
    ],
    "license_warnings": ["WARNING_LICENSE","WARNING_SERVICES","WARNING_DATE","WARNING_INSTALL","ERROR_LICENSE","ERROR_SUSPLIC","ERROR_LCHECK"],
    "subscribers_current": 0,
    "subscribers_total": null 
} }