/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'item-adsfree': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18 11.1v2h4v-2h-4zM16 17.7c1 .7 2.2 1.6 3.2 2.4.4-.5.8-1.1 1.2-1.6-1-.7-2.2-1.7-3.2-2.4-.4.5-.8 1.1-1.2 1.6zM20.4 5.7c-.4-.5-.8-1.1-1.2-1.6-1 .7-2.2 1.7-3.2 2.4.4.5.8 1.1 1.2 1.6 1-.7 2.2-1.7 3.2-2.4zM4 9.1c-1.1 0-2 .9-2 2v2c0 1.1.9 2 2 2h1v4h2v-4h1l5 3v-12l-5 3H4zm5 1.7l2-1.2v4.9l-2-1.2-.5-.3H4v-2h4.6l.4-.2zM15.5 12.1c0-1.3-.6-2.5-1.5-3.4v6.7c.9-.8 1.5-2 1.5-3.3z" _fill="#383132"/>'
  }
})
