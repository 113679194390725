import _ from 'lodash';

export default {
    setAutoNotices(state, data) {
        state.autoNoticeStrategy = data;
    },
    setAutoNoticesRules(state, rules) {
        rules = rules.sort((a, b) => {
            return a.order > b.order ? 1 : -1;
        });
        state.rules = rules;
    },
    setAdvancedConfiguration(state, data) {
        if (['redirections', 'banners', 'smartbanners'].includes(data.type))
            state.configuration[data.type] = data.config
        else
            console.error("setAdvancedConfiguration: unknown type: " + data.type)
    },
    replaceOrAddAdvancedConfiguration(state, item) {
        if (!['redirections', 'banners', 'smartbanners'].includes(item.type)) {
            console.error("replaceOrAddAdvancedConfiguration: unknown type: " + item.type)
            return;
        }

        if (!!state.configuration[item.type]) {
            let ret = state.configuration[item.type].filter(element => element.autonotice_list_id === item.data.autonotice_list_id)
            if (!!ret && ret.length > 0) {
                state.configuration[item.type] = state.configuration[item.type].map(element => {
                    if (element.autonotice_list_id === item.data.autonotice_list_id) {
                        return { ...item.data }
                    }
                    else
                        return element
                })
            }
            else {
                state.configuration[item.type].push(item.data)
            }
        }
        else {
            state.configuration[item.type] = []
            state.configuration[item.type].push(item.data)
        }
    },
    replaceAdvancedConfiguration(state, item) {
        if (!['redirections', 'banners', 'smartbanners'].includes(item.type)) {
            console.error("replaceAdvancedConfiguration: unknown type: " + item.type)
            return;
        }

        if (!!state.configuration[item.type]) {
            let ret = state.configuration[item.type].filter(element => element.autonotice_list_id === item.data.autonotice_list_id)
            if (!!ret && ret.length > 0) {
                state.configuration[item.type] = state.configuration[item.type].map(element => {
                    if (element.autonotice_list_id === item.data.autonotice_list_id) {
                        return { ...item.data }
                    }
                    else
                        return element
                })
            }
            else {
                console.error("replaceAdvancedConfiguration: " + item.type + " with id " + item.data.autonotice_list_id + " does not exist")
            }
        }
        else {
            state.configuration[item.type] = []
            state.configuration[item.type].push(item.data)
        }
    },
    addAdvancedConfiguration(state, item) {
        if (!['redirections', 'banners', 'smartbanners'].includes(item.type)) {
            console.error("addAdvancedConfiguration: unknown type: " + item.type)
            return;
        }

        if (!!state.configuration[item.type]) {
            let ret = state.configuration[item.type].filter(element => element.autonotice_list_id === item.data.autonotice_list_id)
            if (!!ret && ret.length > 0) {
                console.error("addAdvancedConfiguration: " + item.type + " with id " + item.data.autonotice_list_id + " already exists")
            }
            else {
                state.configuration[item.type].push(item.data)
            }
        }
        else {
            state.configuration[item.type] = []
            state.configuration[item.type].push(item.data)
        }
    },
    setCurrentConfiguration(state, conf) {
        state.currentConfiguration = conf
    },
    RemoveConfiguration(state, data) {
        if (['redirections', 'banners', 'smartbanners'].includes(data.type))
            state.configuration[data.type] = state.configuration[data.type].filter(element => element.autonotice_list_id !== data.id)
        else
            console.error("RemoveConfiguration: unknown type: " + data.type)
    },
    addAutoNoticesRule(state, rule) {
        state.rules.push(rule)

    },
    removeAutoNoticesRule(state, rule) {
        state.rules = state.rules.filter(element => element.rule_name !== rule.rule_name)
    },
    setAllowed(state, allowed) {
        state.allowed = allowed
    }
};