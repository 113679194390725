export default () => ({
    darkmode: true,
    debug: false,
    language: 'en',
    languages: [{ id: 'en', name: 'English', regionCode: 'US' }, { id: 'es', name: 'Español', regionCode: 'ES' }],
    mobile: false,
    desktop: true, 
    // available services ASM
    services: [],
    // sub-products available
    products: [],
    //available services on NS
    services_ns: [],
    services_ds: [],
    dnscfStrategy: undefined,
    product_service_dict: {},
    subsystems: [],
    subsystemsDict: {},  
    checkedProducts: {
        CBEndpoint: true,
        EndpointSecure: true,
        NetworkSecure: true,
        HomeSecure: true
    },
    packages: [],
    loadingHigh: 0,
    loadingLow: 0, 
    // * ---  form alert modal state (prevent from loose form changes) ---
    isFormDirty: false,
    showFormAlertModal: false,
    nextRoutAfterFormAlertModal: null,
    EShasAV: false,

})