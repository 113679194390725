import axios from 'axios'
import i18n from '../../../i18n';
import dataExportConfig from '@/util/dummy/dataExportConfig.json';
import { prepareDataExportData } from "@/util/dataExportUtils";

export default {
    getDataExport({ commit, state, rootGetters }) {
        let productConfig = {blocked_traffic: !(rootGetters['settings/getProducts'].length == 1 && rootGetters['settings/getProductState']('NetworkSecure')), allowed_traffic: !(rootGetters['settings/getProducts'].length == 1 && rootGetters['settings/getProductState']('NetworkSecure')), device_deployed: true, router_deployed: rootGetters['settings/getProductState']('RouterSecure')};
        if (rootGetters['settings/getDebug']) {
            return new Promise((resolve, reject) => {
                let parsedData = prepareDataExportData(dataExportConfig, productConfig);
                commit('setDataSet', parsedData.items);
                resolve(parsedData);
            })
        }
        else {
            return new Promise((resolve, reject) => {
                axios.get('/data-export/configuration')
                    .then(res => {
                        if (res && res.data) {
                            let parsedData = prepareDataExportData(res.data, productConfig);
                            commit('setDataSet', parsedData.items)
                            resolve(parsedData)
                        }
                        else {
                            reject()
                        }
                    })
                    .catch(error => {
                        commit('setError', error, { root: true });
                        reject()
                    })
            });
        }
    },
    getDataExportById({ getters }, id) {
        return new Promise((resolve, reject) => {
            resolve(getters.dataSetById(id))
        })
    },
    updateDataExport({ commit }, update) {
        return new Promise((resolve, reject) => {
            axios.patch('/data-export/configuration', update)
                .then(res => {
                    resolve(res);
                    commit('setSuccess', i18n.t('save_success'), { root: true });
                })
                .catch(error => {
                    commit('setErrorAll', i18n.t('It was not possible to process your request, please try again'), { root: true });
                    reject();
                })
        });
    }
}