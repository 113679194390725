import _ from 'lodash';

export default {
    profiles(state) {
        return state.profiles
    },
    profilesAccount: (state, getters, rootGetters) => (account) => {
        let ret = new Map()
        if (account && account.profiles && account.profiles.profiles_definition) {
            account.profiles.profiles_definition.forEach(item => {
                let prof = getters.profiles.get(item)
                if (prof) {
                    prof = _.cloneDeep(prof)
                    let profileInfo = prof.profile_i18n.filter(profile => profile.language_id.split("-")[0] === rootGetters['settings'].language)
                    if (!profileInfo || profileInfo.length == 0)
                        profileInfo = prof.profile_i18n.filter(profile => profile.language_id === 'en')
                    if (!profileInfo || profileInfo.length == 0)
                        profileInfo = [prof.profile_i18n[0]]
                    prof.profile_i18n = profileInfo[0]

                    ret.set(item, prof)
                }
                //ret.set(item, state.profiles.get(item))
            })
        }
        return ret
    },
    profilesAccountName: (state, getters, rootGetters) => (account) => {
        let ret = []
        if (account && account.profiles && account.profiles.profiles_definition) {
            account.profiles.profiles_definition.forEach(item => {
                let prof = getters.profiles.get(item)
                if (prof) {
                    

                    let profileInfo = prof.profile_i18n.filter(profile => profile.language_id.split("-")[0] === rootGetters['settings'].language)
                    
                    if (!profileInfo || profileInfo.length == 0)
                        profileInfo = prof.profile_i18n.filter(profile => profile.language_id === 'en')
                    
                    if (!profileInfo || profileInfo.length == 0)
                        ret.push(prof.id)                        
                    else                    
                        ret.push( profileInfo[0].profile_name  )
                }
                //ret.set(item, state.profiles.get(item))
            })
        }
        return ret
    },
    isProfileDefined: (state) => (profile_id) => {
        return !!state.profiles.get(profile_id)
    },
    isProfileDefinedforAccount: (state, getters) => (account, profile_id) => {
        return getters.isProfileDefined(profile_id) && getters.profilesAccount(account).get(profile_id)
    },

    profileConfiguration: (state, getters, rootGetters) => (id, account) => {
        if (!id) {
            let defProf = rootGetters["accountManagement/accounts/accountDefaultProfile"](account)
            if (defProf)
                return getters.getProfileConfiguration(defProf, account)
            else
                return {}
        }
        let profile = getters.profiles.get(id)
        if (!profile)
            return {};
        return profile.services_configuration;
    },
    userProfile: (state, getters, rootGetters) => (user, account) => {
        try {
            let profile = user.profile ? user.profile.profile_definition_id : undefined
            if (!!profile)
                return profile
            else {
                if ( account && account.profiles)
                    return account.profiles.profile_id_default
                else
                return ''
            }
        }
        catch (err) {
            console.log('catch userProfile error:', account, err)
            return ''
        }
    },
    userProfileName: (state, getters, rootGetters) => (user, account) => {
        let profile_id = getters.userProfile(user, account)
        let prof = getters.profiles.get(profile_id)
        if (prof) {            
            let profileInfo = prof.profile_i18n.filter(profile => profile.language_id.split("-")[0] === rootGetters['settings'].language)
           
            if (!profileInfo || profileInfo.length == 0)
                profileInfo = prof.profile_i18n.filter(profile => profile.language_id === 'en')
           
            if (!profileInfo || profileInfo.length == 0)
                return prof.id

            return profileInfo[0].profile_name
        }
        else
            return profile_id
    },

    userProfileConfiguration: (state, getters) => (user) => {
        if (!user)
            return null;
        return user.profile.profile_configuration
    },

    userProfileConfigurationFinal: (state, getters) => (user, account) => {
        if (!user)
            return null;
        let profile = getters.userProfile(user, account)
        let profileConfig = getters.profileConfiguration(profile, account)
        let userConfig = getters.userProfileConfiguration(user)
        let ret = _.mergeWith({}, profileConfig, userConfig, (objValue, srcValue) => {
            if (_.isArray(objValue) && _.isArray(srcValue))
                return srcValue
        })
        return ret
    },

    userProfileIsModified: (state, getters) => (user, account) => {
        if (!user)
            return false
        let profile = getters.userProfile(user, account)
        let profileConfig = getters.profileConfiguration(profile, account)
        let userConfig = getters.userProfileConfigurationFinal(user)
        let ret = _.isEqual(profileConfig, userConfig)
        return !ret
    },
}