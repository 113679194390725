/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'item-categories': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M11.5 1.5l-5.5 9h11l-5.5-9zm0 3.8l1.9 3.2H9.6l1.9-3.2zm5.5 7.2c-2.5 0-4.5 2-4.5 4.5s2 4.5 4.5 4.5 4.5-2 4.5-4.5-2-4.5-4.5-4.5zm0 7c-1.4 0-2.5-1.1-2.5-2.5s1.1-2.5 2.5-2.5 2.5 1.1 2.5 2.5-1.1 2.5-2.5 2.5zM2.5 21h8v-8h-8v8zm2-6h4v4h-4v-4z" _fill="#383132"/>'
  }
})
