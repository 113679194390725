import _ from 'lodash';

export default {
    addDevice(state, user){
        state._devices.set(user.id, user.data);
        state._devices = new Map(state._devices);
    },   
    removeAccountDevice(state, id){
        if (id){
          state.account_devices.delete(id);
          state.account_devices = new Map(state.account_devices);
        }
    },
    addAccountDevice(state, device){
        state.account_devices.set(device.id, device.data);
        state.account_devices = new Map(state.account_devices);
    },   
    setAccountDevice(state, device){
        let usr = state.account_devices.get(device.id)
        _.merge(usr, device.data)
        state.account_devices = new Map(state.account_devices);
    },   
    removeDevice(state, id){
        if (id){
          state._devices.delete(id);
          state._devices = new Map(state._devices);
        }
    }, 
}