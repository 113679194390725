/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'captcha-audio': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path pid="0" d="M25.818 11.636c.804 0 1.455.652 1.455 1.455v1.454A11.636 11.636 0 0117.09 26.08v4.465a1.455 1.455 0 11-2.91 0V26.08A11.636 11.636 0 014 14.545v-1.454a1.455 1.455 0 112.91 0v1.454a8.727 8.727 0 1017.454 0v-1.454c0-.803.65-1.455 1.454-1.455zM15.636 0a7.273 7.273 0 017.273 7.273v5.818a7.273 7.273 0 11-14.545 0V7.273A7.273 7.273 0 0115.636 0zm0 2.91a4.364 4.364 0 00-4.363 4.363v5.818a4.364 4.364 0 108.727 0V7.273a4.364 4.364 0 00-4.364-4.364z" _fill="#000" fill-rule="evenodd"/>'
  }
})
