<template>
  <b-overlay
    :show="updating"
    rounded
    opacity="0"
    spinner-small
    spinner-variant="primary"
  >
    <b-card card-body-classes="table-full-width" no-footer-line>
        <div v-if="tableData.length > 0 || serverpagination" slot="header" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
          <fg-input class="my-2">
            <template v-slot:addonLeft v-if="serverpagination && search.length>1">
                <el-select class="select-primary" style="max-width: 175px;display: flex;"
                    size="large"
                    :placeholder="this.t('Search by')"
                    v-model="searchField">
                    <el-option v-for="option in search"
                        class="select-primary"
                        :value="option"
                        :label="option"
                        :key="option">
                    </el-option>
                </el-select>
              </template>
              <el-input type="search"
                      clearable
                      prefix-icon="el-icon-search"
                      style="max-width: 350px; padding-left: 7px;"
                      :placeholder="this.t('Search records')"
                      v-model="searchQuery"
                      aria-controls="datatables">
              </el-input>
          </fg-input>
        </div>

        <b-card-body style="padding: 0px;">
          <el-table v-if="queriedData.length > 0"
                    style="width: 100%;"
                    :data="queriedData"
                    id="nestedtable"
                    >
            <el-table-column v-for="column in tableColumns"
                             :type="column.type || ''"
                             :key="column.label"
                             :min-width="column.minWidth"
                             :width="column.width"
                             :prop="column.prop"
                             :label="column.label"
                             :formatter="typeof column.formatter === 'function' ? column.formatter : null">

                  <template #default="{ row }" v-if="typeof column.formatter !== 'function'">
                    <div v-if="column.type === 'expand'" style="width:100%">
                      <template style="width:100%">
                      <el-table v-if="row[column.prop].length > 0"
                        :id="`table-expand-${queriedData[0][tableColumns[0].prop]}`"
                        :data="row[column.prop]"
                        :show-header="false"
                        >

                        <el-table-column v-for="column in tableNestedColumns"
                          :key="column.label"
                          :min-width="column.minWidth"
                          :width="column.width"
                          :prop="column.prop"
                          :label="column.label">

                          <template #default="{ row }" v-if="typeof column.formatter !== 'function'" >
                            <div v-if="column.formatter"
                              :is="column.formatter"
                              :row="row"
                              :column="column">
                            </div>
                            <template v-else>
                              <span :id="`nested-${column.label}`">
                              {{ row[column.prop] }} </span>
                            </template>
                          </template>
                        </el-table-column>

                        <el-table-column v-if="nestedActions"
                          :min-width="nestedActions.minWidth || 135"
                          :fixed="nestedActions.fixed || 'right'"
                          :label="nestedActions.label || 'Actions'">
                              <div  slot-scope="props" class="table-actions">
                                <template v-for="item in nestedActions.items">
                                  <el-tooltip :content="item.action" :open-delay="250" :key="item.event">
                                      <n-button v-if="!item.confirm"
                                            :id="'paginatedTable-nested-action-' + item.action"
                                            @click.native="$emit(item.event, {index: props.$index, row: props.row})"
                                            :type="item.type || 'primary'"
                                            :size="item.size || 'sm'" round icon
                                            :visible_for_permissions="item.hasOwnProperty('visible_for_permissions') ? item.visible_for_permissions : []"
                                            v-b-modal.confirm>
                                          <i :class="item.icon"></i>
                                      </n-button>
                                      <n-button v-if="item.confirm"
                                            :id="'paginatedTable-nested-action-' + item.action"
                                            @click.native="itemForAction={event: item.event, action: item.action, text: item.confirmtext,
                                                                                                  data: {index: props.$index, row: props.row}}"
                                            :type="item.type || 'primary'"
                                            :size="item.size || 'sm'" round icon
                                            :visible_for_permissions="item.hasOwnProperty('visible_for_permissions') ? item.visible_for_permissions : []"
                                            v-b-modal.confirm>
                                          <i :class="item.icon"></i>
                                      </n-button>
                                    </el-tooltip>
                                </template>
                              </div>
                        </el-table-column>
                      </el-table>
                      </template>
                    </div>

                    <div v-else-if="column.formatter"
                      :is="column.formatter"
                      :row="row"
                      :column="column">
                    </div>

                    <template v-else>
                      {{ row[column.prop] }}
                    </template>
                  </template>
            </el-table-column>

            <el-table-column v-if="actions"
              :min-width="actions.minWidth || 135"
              :fixed="actions.fixed || 'right'"
              :label="actions.label || 'Actions'">
              <div slot-scope="props" class="table-actions">
                <template v-for="item in actions.items">
                    <el-tooltip :content="item.action" :open-delay="250" :key="item.event">
                      <n-button v-if="!item.confirm"
                            :id="'paginatedTable-action-' + item.action"
                            @click.native="$emit(item.event, {index: props.$index, row: props.row})"
                            :type="item.type || 'primary'"
                            :size="item.size || 'sm'" round icon
                            :visible_for_permissions="item.hasOwnProperty('visible_for_permissions') ? item.visible_for_permissions : []"
                            v-b-modal.confirm>
                          <i :class="item.icon"></i>
                      </n-button>
                      <n-button v-if="item.confirm"
                            :id="'paginatedTable-action-' + item.action"
                            @click.native="itemForAction={event: item.event, action: item.action, text: item.confirmtext,
                                                                                  data: {index: props.$index, row: props.row}}"
                            :type="item.type || 'primary'"
                            :size="item.size || 'sm'" round icon
                            :visible_for_permissions="item.hasOwnProperty('visible_for_permissions') ? item.visible_for_permissions : []"
                            v-b-modal.confirm>
                          <i :class="item.icon"></i>
                      </n-button>
                    </el-tooltip>
                </template>
              </div>
            </el-table-column>
          </el-table>

          <template v-else>
              <empty-state v-if="showEmptyState" :updating="updating"/>
          </template>
        </b-card-body>

        <b-modal v-if="itemForAction" id="confirm" ref="modal"
                      @ok="handleConfirm" :title="t(itemForAction.action)">
                      <div v-if="itemForAction.text">{{ t(itemForAction.text)}}.</div>
                      {{ t('changes_are_local') }}.
        </b-modal>


        <div v-if="queriedData.length > 0" slot="footer" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
          <div class="mb-4 mb-sm-0">
            <p class="card-category" v-if="serverpagination">{{t('Showing')}} {{t('entries')}} {{from}} {{t('to')}} {{ to}}</p>
            <p class="card-category" v-else>{{t('Showing')}} {{from + 1}} {{t('to')}} {{to}} {{t('of')}} {{total}} {{t('entries')}}</p>
          </div>
          <div class="mb-4 mb-sm-0">
            <span class="card-category pr-2">{{t('Results per page')}}:</span>
            <el-select
                class="select-primary"
                style="width: 75px; margin-top: -20px;"
                v-model="perPage"
                placeholder="Per page">
                <el-option
                  class="select-default"
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item">
                </el-option>
            </el-select>
          </div>

          <n-pagination v-if="!serverpagination" class="pagination-no-border mb-4 mb-sm-0"
                        v-model="pagination.currentPage"
                        :per-page="pagination.perPage"
                        :total="total" />
          <n-pagination-server v-else class="pagination-no-border mb-4 mb-sm-0"
                        v-model="currentPage"
                        :per-page="perPage"
                        :prev="serverpagination.prev" :next="serverpagination.next" />

        </div>
    </b-card>
  </b-overlay>
</template>
<script>
import { Table, TableColumn, Select, Option, Input } from 'element-ui';
import EmptyState from '../EmptyState';
import Fuse from 'fuse.js';
import Pagination from '../Pagination'
import PaginationServer from '../PaginationServer'

export default {
    name: 'nested-paginated-table',
    components: {
        [Select.name]: Select,
        [Option.name]: Option,
        [Table.name]: Table,
        [TableColumn.name]: TableColumn,
        [Input.name] : Input,
        [EmptyState.name] : EmptyState,
        [Pagination.name] : Pagination,
        [PaginationServer.name] : PaginationServer
    },
    props: {
        rows: {
            type: Array,
            default: () => []
        },
        columns:  {
            type: Array,
            default: () => [],
            required: true
        },
        nestedColumns:  {
            type: Array,
            default: () => [],
            required: false
        },
        actions: {
            type: Object,
            default: null
        },
        nestedActions: {
            type: Object,
            default: null
        },
        search: {
            type: Array,
            required: true
        },
        serverpagination: {
          type: Object,
          default: null
        },
        value: {
          type: Object,
          default: () => {}
        },
        updating: {
          type: Boolean,
          default: false
        },
        itemsPerPage: {
          type: Number,
          default: 10
        },
        showEmptyState: {
          type: Boolean,
          default: true
        },

  },
  computed: {
    /***
     * Returns a page from the searched data or the whole data. Search is performed in the watch section below
     */
    queriedData() {
      let result = this.tableData;

      if (this.serverpagination) {
        return result;
      }

      if (this.searchQuery !== '') {
        result = this.searchedData;
      }
      
      result.length <= this.from ? result = result.slice(0, this.to) : result = result.slice(this.from, this.to);
      return result;
    },
    to() {
      if ( this.serverpagination){
        if (this.serverpagination.next_id)
          return this.from + this.pagination.perPage;
        else
          return this.from + this.tableData.length - 1
      }
      else{
        let highBound = this.from + this.pagination.perPage;
        if (this.total < highBound) {
          highBound = this.total;
        }
        return highBound;
      }
    },
    from() {
      if ( this.serverpagination)
        return this.perPage * (this.currentPage - 1) +1;
      else
        return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      if (this.serverpagination) {
        return this.tableData.length;
      }

      return this.searchQuery !== ''
        ? this.searchedData.length
        : this.tableData.length;
    },
    sort: function(a,b){
      if (a > b) {
        return 1;
      }
      if (a < b) {
        return -1;
      }
      return 0;
    },
    currentPage: {
        get () {
          return this.serverpagination.currentPage
        },
        set(newValue) {
          if ( newValue < this.serverpagination.currentPage)
            this.$emit('prev')
          if ( newValue > this.serverpagination.currentPage)
            this.$emit('next')
        }
    },
    perPage: {
      get() {
        return this.pagination.perPage
      },
      set(newValue){
        this.pagination.perPage = newValue
        this.pagination.currentPage = 1;
        this.$emit('pagination', newValue)
      }
    }
  },
  data() {
    return {
      pagination: {
        perPage: null,
        currentPage: 1,
        perPageOptions: [5, 10, 20, 50],
        total: 0
      },
      searchField: '',
      searchQuery: '',
      tableData: [],
      tableColumns: [],
      tableNestedColumns : [],
      searchedData: [],
      fuseSearch: null,
      itemForAction: null
    };
  },
    methods: {
      handleConfirm(){
          this.$emit(this.itemForAction.event, this.itemForAction.data)
          this.itemForAction = null
      },
      toggleSwitch(data){
        console.log(data)
      },
      buildSearcher() {
        let searcher = new Fuse(this.tableData, {
          keys: this.search,
          threshold: 0.2,
          ignoreLocation: true,
        });

        return searcher;
      },
    },
    mounted() {
      // Fuse search initialization.
      this.tableData = this.rows
      this.tableColumns = this.columns
      this.tableNestedColumns = this.nestedColumns

      if ( this.value && this.value.field)
        this.searchField = this.value.field
      if (!this.serverpagination) {
        this.fuseSearch = this.buildSearcher();
      }
    },
    created(){
        this.pagination.perPage = this.itemsPerPage
    },
    watch: {
        rows(newValue, oldValue){
            this.tableData = newValue
            if (!this.serverpagination)
                this.fuseSearch = this.buildSearcher();
                if (this.searchQuery !== ''){
                  this.searchedData = this.fuseSearch.search(this.searchQuery);
                }
                else{
                  this.searchedData = newValue
                }
        },
        columns(newValue, oldValue){
            this.tableColumns = this.columns
        },
        nestedColumns(newValue, oldValue){
            this.tableNestedColumns = this.nestedColumns
        },
        /**
         * Searches through the table data by a given query.
         * NOTE: If you have a lot of data, it's recommended to do the search on the Server Side and only display the results here.
         * @param value of the query
         */
        searchQuery(value) {
            this.$emit('input', {
              field: this.searchField,
              value: value
            });
            if (!this.serverpagination) {
                let result = this.tableData;
                if (value !== '') {
                    result = this.fuseSearch.search(value);
                }
                this.searchedData = result;
            }
        },
        itemsPerPage(value){
          this.pagination.perPage = value
        }
    },

};
</script>
<style>
#nestedtable .el-table__expanded-cell{
    background-color: initial !important;
    padding: 0px 0px !important;
}


#nestedtable .el-table__body tr.hover-row > td{
  background-color: transparent !important;
}

#nestedtable .el-table{
  border-bottom: 0px !important;
}

.el-table__expand-icon {
    display: inline-table;
}

</style>
