/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'item-devices': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M4.8 7.4H21V5.6H4.8c-1 0-1.8.8-1.8 1.8v9.9H1.2V20h12.6v-2.7h-9V7.4zm17.1 1.8h-5.4c-.5 0-.9.4-.9.9v9c0 .5.4.9.9.9h5.4c.5 0 .9-.4.9-.9v-9c0-.5-.4-.9-.9-.9zm-.9 8.1h-3.6V11H21v6.3z" _fill="#383132"/>'
  }
})
