/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'networksecure': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path pid="0" d="M25.81 17.503l-1.526-5.054a5.809 5.809 0 10-7.77-7.639L8.528 3.097a4.357 4.357 0 10-4.763 5.576l2.527 9.513a4.357 4.357 0 105.591 6.593l5.664 1.147a7.261 7.261 0 108.263-8.365v-.058zM21.785 4.317a2.904 2.904 0 110 5.809 2.904 2.904 0 010-5.81zm-18.879 0a1.452 1.452 0 112.905 0 1.452 1.452 0 01-2.905 0zm5.81 18.879a1.452 1.452 0 110-2.905 1.452 1.452 0 010 2.905zm.406-5.81L6.61 8.035A4.444 4.444 0 008.368 6.03l7.61 1.656a5.809 5.809 0 005.445 5.344l1.453 4.676a7.261 7.261 0 00-5.156 5.257l-4.647-.958a1.597 1.597 0 000-.261 4.357 4.357 0 00-3.95-4.357zM24.69 29.006a4.357 4.357 0 110-8.714 4.357 4.357 0 010 8.714z" _fill="#0E1317" fill-rule="evenodd"/>'
  }
})
