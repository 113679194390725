/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'item-hostandprocesscontrol': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path pid="0" d="M27.2 0A4.8 4.8 0 0132 4.8v22.4a4.8 4.8 0 01-4.8 4.8H4.8A4.8 4.8 0 010 27.2V4.8A4.8 4.8 0 014.8 0zm0 3.2H4.8a1.6 1.6 0 00-1.6 1.6v22.4a1.6 1.6 0 001.6 1.6h22.4a1.6 1.6 0 001.6-1.6V4.8a1.6 1.6 0 00-1.6-1.6zM8.598 10.676l5.656 5.658-5.658 5.656-1.885-1.886 3.772-3.77-3.772-3.771 1.887-1.887zM20 19v2.667h-5.333V19H20z" _fill="#000" fill-rule="evenodd"/>'
  }
})
