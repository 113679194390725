export default {
    captcha(state) {
        return state.captcha;
    },
    isExpiredPassword(state){
        return state.expiredpwd
      },
      refreshToken(state){
        return state.refreshToken;
      },
      isAuthenticated(state){
        if (state.refreshToken)
          return true;
        return localStorage.getItem('tk') != null;
      },
      id(state) {
        if (state.userId)
          return state.userId;
        else
          return localStorage.getItem('userId')
      },
      manager(state) {
        return state.managerId
      },
      name(state) {
        if (state.user)
          return state.user;
        else if (state.userId)
          return state.userId.slice(0, state.userId.indexOf('@'));
        else
          return null;
      },
      userRoles(state) {
        if (state.userRoles !== null) 
          return state.userRoles;
        else
          return []
      },
      userPermissions(state) {
        if (state.userPermissions !== null) 
          return state.userPermissions;
        else
          return []
      },
      userForbiddens(state) {
        if (state.userForbiddens !== null) 
          return state.userForbiddens;
        else
          return []
      },
      accessToken(state){
        return state.accessToken;
      },
      refreshingAccessToken: (state) => () => {
        return state.refreshing
      }, 
      cookie(state){
        return state.cookie
      },  
      isLdap: state => () => {
        return state.providerAccessType === 'IdentityProviderLDAP'
      },
      providerAccessType(state){
        return state.providerAccessType
      },

      showReauthenticationModal(state){
        return state.showReauthenticationModal
      },
      privileges_requested(state){
        return state.privileges_requested
      },
      privileges(state){
        return state.privileges_granted
      },
      retry_data(state){
        return state.retry_data
      }
}