import _ from 'lodash';

export default {
    getAntiVirus: (state) => () => {
        return state.antiVirusStrategy !== undefined ? state.antiVirusStrategy : {};
    },
    getAntiVirusRules(state) {
        return state.rules;
    },
    isDebug(state, getters, rootState, rootGetters) {
        return rootGetters['settings/getDebug']
    },
    getAntiVirusStrategy(state, getters, rootState, rootGetters) {
        return getters.getAntiVirus;
    },
    getAdvancedConfiguration(state) {
        return state.advancedConfiguration !== undefined ? state.advancedConfiguration : {};
    },
    getDefaultBlockingUrl(state) {
        return state.defaultBlockingUrl;
    },
    getRuleById: (state, getters) => (ruleId) => {
        let rule = state.rules.filter(rule => "" + rule.rule_name === "" + ruleId);
        if (rule && rule.length > 0)
            return _.cloneDeep(rule[0]);
        else
            return null;
    },
}