import DashboardLayout from "@/layout/dashboard/DashboardLayout.vue";

const RouterSecureStatus = () => import(
  /* webpackChunkName: "rs-generalmanagement" */
  '@/pages/RouterSecure/General/Status.vue'
);

const AgentManagement = () => import(
  /* webpackChunkName: "rs-generalmanagement" */
  "@/pages/RouterSecure/General/GeneralManagement/AgentManagement.vue"
);

const DefaultAgentVersion = () => import(
  /* webpackChunkName: "rs-generalmanagement" */
  "@/pages/RouterSecure/General/GeneralManagement/DefaultAgentPackage.vue"
);

const DefaultAgentVersionEdit = () => import(
  /* webpackChunkName: "rs-generalmanagement" */
  "@/pages/RouterSecure/General/GeneralManagement/DefaultAgentPackageEdit.vue"
);
const Troubleshooting = () => import(
  /* webpackChunkName: "rs-generalmanagement" */
  "@/pages/RouterSecure/General/GeneralManagement/Troubleshooting.vue"
);

const AllowedLists = () => import(
  /* webpackChunkName: "rs-generalmanagement" */
  "@/pages/RouterSecure/General/GeneralManagement/AllowedLists.vue"
);

const Authentication = () => import(
  /* webpackChunkName: "rs-generalmanagement" */
  "@/pages/RouterSecure/General/GeneralManagement/Authentication.vue"
);


export default {
  path: '/rs',
  component: DashboardLayout,
  name: 'RouterSecure / General',
  children: [
    {
      path: 'General/Status*',
      name: 'RouterSecure / General / Status',
      component: RouterSecureStatus
    },
    {
      path: 'General/GeneralManagement/AgentManagement',
      name: 'RouterSecure / General / General Management / Agent Management',
      component:  AgentManagement
    },
    {
      path: 'General/GeneralManagement/DefaultAgentVersion',
      name: 'RouterSecure / General / General Management / Default Agent Version',
      component:  DefaultAgentVersion
    },
    {
      path: "General/GeneralManagement/DefaultAgentVersion/new",
      name: "RouterSecure / General / General Management / Default Agent Version / New",
      component: DefaultAgentVersionEdit,
      props: true
    },
    {
      path: 'General/GeneralManagement/DefaultAgentVersion/edit/:agent_id',
      name: 'RouterSecure / General / General Management / Default Agent Version / Edit',
      component: DefaultAgentVersionEdit,
      props: true
    },
    {
      path: 'General/GeneralManagement/Troubleshooting',
      name: 'RouterSecure / General / General Management / Troubleshooting',
      component: Troubleshooting
    },
    {
      path: 'General/GeneralManagement/AllowedLists',
      name: 'RouterSecure / General / General Management / Allowed Lists',
      component: AllowedLists
    },
    {
      path: 'General/GeneralManagement/Authentication',
      name: 'RouterSecure / General / General Management / Authentication',
      component: Authentication
    },
  ]
};
