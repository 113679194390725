export function SET_ISP_PROVIDER_DATABASE_ACTIVE(state, data) {
  state.isp.providerDBActive = data
}

export function SET_ISP_PROVIDER_LIST(state, providerList) {
  Object.assign(state.isp, { providerList });
  var filtered = providerList.filter((provider) => provider.identity_provider_type == 'IdentityProviderDatabase' );
  SET_ISP_PROVIDER_DATABASE_ACTIVE(state, filtered.length > 0);
}

export function SET_ENT_PROVIDER_LIST(state, providerList) {
  Object.assign(state.ent, { providerList });
}

export function SET_RES_PROVIDER_LIST(state, providerList) {
  Object.assign(state.res, { providerList });
}

export function CLEAR_PROVIDER_DETAILS(state, type) {
  let emptyProvider = {
    enabled: false,
    system: false,
    identity_provider_id: '',
    identity_provider_type: '',
    local_cache: false,
    ldap_cluster: '',
    table_mapping: { items: [] },
    password_complexity: {
      check_keyboard_pattern: false,
      check_mirrored_sequence: false,
      check_repeated_sequence: false,
      check_sequential_letters: false,
      check_sequential_numbers: false,
      minimum_lowercase_required: 0,
      minimum_middle_number_digits_required: 0,
      minimum_middle_symbols_required: 0,
      minimum_number_digits_required: 0,
      minimum_password_length: 0,
      minimum_symbols_required: 0,
      minimum_uppercase_required: 0,
    },
  };
  if (type === 'ent'|| type === 'res') emptyProvider.redirections = {};
  if (type === 'res') {
    emptyProvider.api_key = '';
    emptyProvider.api_secret = '';
  }
  if (type === 'isp') {
    emptyProvider.local_cache = false;
    emptyProvider.ldap_cluster = null;
    emptyProvider.providerDBActive = false;
  }
  Object.assign(state[type].providerDetails, emptyProvider);
}

export function SET_ISP_PROVIDER_DETAILS(state, providerDetails) {
  Object.assign(state.isp.providerDetails, providerDetails );
}

export function SET_ENT_PROVIDER_DETAILS(state, providerDetails) {
  Object.assign(state.ent.providerDetails, providerDetails );
}

export function SET_RES_PROVIDER_DETAILS(state, providerDetails) {
  Object.assign(state.res.providerDetails, providerDetails );
}

export function SET_ISP_PROVIDER_TYPE(state, providerType) {
  state.isp.providerDetails.identity_provider_type = providerType;
}

export function SET_ENT_PROVIDER_TYPE(state, providerType) {
  state.ent.providerDetails.identity_provider_type = providerType;
}

export function SET_RES_PROVIDER_TYPE(state, providerType) {
  state.res.providerDetails.identity_provider_type = providerType;
}

export function SET_ENT_PROVIDER_SSO_URL(state, ssoUrl) {
  state.ent.providerDetails.configuration.redirect_sso_jwt_url = ssoUrl;
}

export function SET_RES_PROVIDER_SSO_URL(state, ssoUrl) {
  state.res.providerDetails.configuration.redirect_sso_jwt_url = ssoUrl;
}

export function SET_ACTIVE_PROVIDER(state, activeProvider) {
  Object.assign(state.activeProvider, activeProvider );
}

export function CREATE_PROVIDER(state, data) {
  state[data.type].providerList.push(data.provider);
}

export function UPDATE_PROVIDER(state, data) {
  let foundIndex = state[data.type].providerList.findIndex(provider => provider.identity_provider_id === data.provider.identity_provider_id);
  state[data.type].providerList.splice(foundIndex, 1, data.provider);
}

export function REMOVE_PROVIDER(state, data) {
  let foundIndex = state[data.type].providerList.findIndex(provider => provider.identity_provider_id === data.provider.identity_provider_id);
  foundIndex > -1
    && state[data.type].providerList.splice(foundIndex, 1);
}

export function SET_RES_SYSTEM_SSO_PROVIDER_ENABLED(state, data) {
  state.res.systemSsoProviderEnabled = data
}

export function SET_ENT_SYSTEM_SSO_PROVIDER_ENABLED(state, data) {
  state.ent.systemSsoProviderEnabled = data
}

