import _ from 'lodash';
import { getLanguageCountryCodeList, getPreviouslySupportedLanguages } from "@/util/languageUtils";

export default {
    maxLanguages: state => {
      return state.advancedConfiguration.max_notification_languages
    },
    services: (state, getters, rootState) => {
      let advancedData = _.cloneDeep(state.advancedConfiguration)
      delete advancedData.max_notification_languages
      delete advancedData.default_notification_language_id
      delete advancedData.b2b_notifications_enabled
      const products = _.get(rootState, "settings.products", [])
      if(products.includes("EndpointSecure") && products.length === 1){
        advancedData.general = {
          internet_availability_long: _.get(advancedData, "general.internet_availability_long")
        }
      }
      return advancedData
    },
    langOptions(state) {
      let existingLanguages = {};
      Object.keys(state.notifications.allConfigurations).forEach(existingLanguage => {
        let languageDetails = getLanguageCountryCodeList().find(option => option.language_id === existingLanguage)
          || getPreviouslySupportedLanguages().find(option => option.language_id === existingLanguage);
        if (languageDetails) {
          existingLanguages[languageDetails.language_id] = {
            name: languageDetails.language_name,
            value: languageDetails.language_id,
            internal: state.notifications.allConfigurations[languageDetails.language_id].internal
          };
        };
      });
      return existingLanguages;
    },
  }