/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'item-blockingconfiguration': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g _fill="#383132"><path pid="0" d="M15.6 15.2l-.7-.3-.9-.4-.8.6c-.4.3-.7.5-1.1.6l-.9.4-.1 1-.2 1.2H9.7l-.2-1.2-.1-1-.9-.4c-.4-.2-.7-.4-1.1-.6l-.7-.5-.9.4-1.1.4-.6-1 .9-.7.8-.6-.1-1v-.6-.6l.1-1-.8-.7-.9-.7.6-1 1.1.4.9.3.8-.6c.4-.3.7-.5 1.1-.6l.9-.4.1-1 .2-1.1H11l.2 1.2.1 1 .9.4c.4.2.7.4 1.1.6l.8.6.9-.4 1.1-.4.6 1-1 .7-.8.6.1 1V12l-.1 1 .8.6.5.4c.4-.5.8-.9 1.4-1.1l-.8-.6c0-.3.1-.6.1-.8 0-.3 0-.6-.1-.8l1.8-1.4c.2-.1.2-.4.1-.6l-1.7-3c-.1-.1-.2-.2-.4-.2h-.1l-2.2.9c-.4-.3-.9-.6-1.5-.8l-.3-2.3c0-.2-.2-.4-.4-.4H8.7c-.2 0-.4.2-.4.4l-.4 2.1c-.5.2-1 .5-1.5.8l-2.1-.8h-.2c-.1 0-.3.1-.3.2l-1.7 3c-.2.2-.1.4 0 .5l1.8 1.4c0 .3-.1.6-.1.8 0 .3 0 .6.1.8l-1.8 1.4c-.2.1-.2.4-.1.6l1.7 3c.1.3.3.4.4.4h.1l2.2-.9c.4.3.9.6 1.5.8l.3 2.3c0 .2.2.4.4.4h3.5c.2 0 .4-.2.4-.4l.3-2.3c.5-.2 1-.5 1.5-.8l1.1.4c0-.6 0-1.2.2-1.8z"/><path pid="1" d="M10.4 8c-1.9 0-3.5 1.5-3.5 3.5 0 1.9 1.5 3.5 3.5 3.5 1.9 0 3.5-1.5 3.5-3.5-.1-2-1.6-3.5-3.5-3.5zm0 5.1c-1 0-1.7-.8-1.7-1.7s.8-1.7 1.7-1.7 1.7.8 1.7 1.7-.8 1.7-1.7 1.7z"/></g><path pid="2" d="M19.5 13.6c-1.6 0-2.9 1.3-2.9 2.9s1.3 2.9 2.9 2.9 2.9-1.3 2.9-2.9-1.3-2.9-2.9-2.9zm-2.4 2.9c0-1.3 1-2.3 2.3-2.3.5 0 1 .2 1.4.5L17.5 18c-.2-.4-.4-.9-.4-1.5zm2.4 2.4c-.5 0-1-.2-1.4-.5l3.3-3.3c.3.4.5.9.5 1.4-.1 1.3-1.2 2.4-2.4 2.4z" _fill="#383132" _stroke="#383132" stroke-width=".8" stroke-miterlimit="10"/>'
  }
})
